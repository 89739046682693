import React from 'react';
import {QuestionAnswersQueryResult, QuestionRating} from 'mushin-redux-store';
import MuIcon from '../../../Components/MuIcon';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import {roundWithPrecision} from '../../../Helpers/math';

type Props = {
    question: QuestionRating;
    answers: QuestionAnswersQueryResult<QuestionRating>;
}

const QuestionResultRating: React.FC<Props> = ({question, answers}) => {
    const values = [];
    for (let i = question.maxRatingValue; i > 0; i--) {
        const stars = [];
        const item = answers.stats.find((stat) => stat.value === i);
        const count = item?.count || 0;
        const ratio = item?.ratio || 0;
        for (let j = 0; j < i; j++) {
            stars.push(<MuIcon key={j} className="mu-question-result__rating-star" svgName="Star" />);
        }
        values.push(
            <React.Fragment key={`rate-${i}`}>
                <div className="mu-question-result__item-value">{stars}</div>
                <ProgressBar className="mu-question-result__item-progress" value={ratio} maxValue={1} />
                <div className="mu-question-result__item-count">
                    {`${roundWithPrecision(ratio * 100, 1)}% (${count})`}
                </div>
            </React.Fragment>
        );
    }

    return (
        <div className="mu-question-result__rating">
            <div className="mu-question-result__average mu-question-result__average--rating">
                {`${roundWithPrecision(answers.average, 2)} / ${question.maxRatingValue}`}
            </div>
            <div className="mu-question-result__items">{values}</div>
        </div>
    );
};

export default QuestionResultRating;
