import React from 'react';
import {useDispatch} from 'react-redux';
import {Project} from 'mushin-redux-store';
import i18n from 'i18next';
import {openModal} from '../../../../../Redux/reducers/modalsSlice';
import ActionCard from '../../../../../Components/ActionCard/ActionCard';
import InsightCard from './InsighCard';
import {isProjectAdmin} from '../../../../../Helpers/users';

type Props = {
    project: Project;
};

const InsightsList: React.FC<Props> = ({
    project,
}) => {
    const dispatch = useDispatch();

    return (
        <section className="mu-insights__section-insights">
            {isProjectAdmin(project) && (
                <div className="mu-insights__create">
                    <ActionCard
                        activeMode="Insights"
                        onClick={() => dispatch(openModal('CreateAlbum', {projectId: project.id}))}
                        create
                        createText={i18n.t<string>('insights.create')}
                    />
                </div>
            )}
            <div className="mu-insights__section-list">
                <p className="mu-synthesis-header__header--text ">
                    {i18n.t<string>('SynthesisInsights.textList')}
                </p>
                <InsightCard
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut l
                abore et dolore magna aliqua..."
                    span="Publié par Maxime, hier à 22:32"
                    numberOpportunities={3}
                    numberSolutions={5}
                />
                <InsightCard
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut l
                abore et dolore magna aliqua..."
                    span="Publié par Maxime, hier à 22:32"
                    numberOpportunities={3}
                    numberSolutions={10}
                />
                <InsightCard
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut l
                abore et dolore magna aliqua..."
                    span="Publié par Maxime, hier à 22:32"
                    numberOpportunities={8}
                    numberSolutions={4}
                />
                <InsightCard
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut l
                abore et dolore magna aliqua..."
                    span="Publié par Maxime, hier à 22:32"
                    numberOpportunities={12}
                    numberSolutions={3}
                />
            </div>
        </section>
    );
};

export default InsightsList;
