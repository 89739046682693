import React from 'react';
import {Route, Routes} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import QuestionnaireEdition from '../Scenes/QuestionnaireEditor/QuestionnaireEdition';
import QuestionnaireRespondents from '../Scenes/QuestionnaireRespondents/QuestionnaireRespondents';
import QuestionnaireAnswerPrivate from '../Scenes/QuestionnaireAnswer/QuestionnaireAnswerPrivate';
import QuestionnaireResult from '../Scenes/QuestionnaireResult/QuestionnaireResult';
import QuestionnaireAnswerSuccess from '../Scenes/QuestionnaireAnswerSuccess/QuestionnaireAnswerSuccess';

/*
Routes with a sidebar
 */

/*
Mushin Home
 */

export const QuestionnaireRoutes: React.FC = () => (
    <Routes>
        <Route path=":questionnaireId/questions/:questionId/note/:note_id" element={<PrivateRoute><QuestionnaireResult /></PrivateRoute>} />
        <Route path=":questionnaireId/questions/:questionId/answers" element={<PrivateRoute><QuestionnaireResult /></PrivateRoute>} />
        <Route path=":questionnaireId/questions/:questionId" element={<PrivateRoute><QuestionnaireResult /></PrivateRoute>} />
        <Route path=":questionnaireId/answers" element={<PrivateRoute><QuestionnaireResult /></PrivateRoute>} />
        <Route path=":questionnaireId/edit" element={<PrivateRoute><QuestionnaireEdition /></PrivateRoute>} />
        <Route path=":questionnaireId/respondents" element={<PrivateRoute><QuestionnaireRespondents /></PrivateRoute>} />
        <Route path=":questionnaireId/answer-success" element={<PrivateRoute><QuestionnaireAnswerSuccess /></PrivateRoute>} />
        <Route path=":questionnaireId" element={<PrivateRoute><QuestionnaireAnswerPrivate /></PrivateRoute>} />
    </Routes>
);

export default QuestionnaireRoutes;
