/*
React-Quill
https://github.com/zenoamaro/react-quill
*/

import React, {useCallback, useEffect, useState, useImperativeHandle} from 'react';

import Quill, {
    QuillOptionsStatic,
    DeltaStatic,
    RangeStatic,
    BoundsStatic,
    StringMap,
    Sources,
} from 'quill';
import {isDeltasSame} from 'mushin-node-commons';

export type Range = RangeStatic | null;

export interface QuillOptions extends QuillOptionsStatic {
    tabIndex?: number,
}

export interface ReactQuillProps {
    bounds?: string | HTMLElement,
    children?: React.ReactElement,
    className?: string,
    formats?: string[],
    id?: string,
    modules?: StringMap,
    onChange?(
        value: string,
        delta: DeltaStatic,
        source: Sources,
        editor: UnprivilegedEditor,
    ): void,
    onChangeSelection?(
        selection: Range,
        source: Sources,
        editor: UnprivilegedEditor,
    ): void,
    onFocus?(
        selection: Range,
        source: Sources,
        editor: UnprivilegedEditor,
    ): void,
    onBlur?(
        previousSelection: Range,
        source: Sources,
        editor: UnprivilegedEditor,
    ): void,
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>,
    onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>,
    onKeyUp?: React.KeyboardEventHandler<HTMLDivElement>,
    placeholder?: string,
    preserveWhitespace?: boolean,
    readOnly?: boolean,
    scrollingContainer?: string | HTMLElement,
    style?: React.CSSProperties,
    tabIndex?: number,
    theme?: string,
    value?: DeltaStatic,
}

export interface UnprivilegedEditor {
    getLength(): number;
    getText(index?: number, length?: number): string;
    getHTML(): string;
    getBounds(index: number, length?: number): BoundsStatic;
    getSelection(focus?: boolean): RangeStatic;
    getContents(index?: number, length?: number): DeltaStatic;
}

export interface ReactQuillRef {
    getEditor: () => Quill | null
}

/*
Small helper to execute a function in the next micro-tick.
*/
function postpone(fn: (value: void) => void) {
    Promise.resolve().then(fn);
}

const isEqualValue = (value: DeltaStatic, nextValue: DeltaStatic | undefined): boolean => {
    if (value && nextValue) {
        return isDeltasSame(value as DeltaStatic, nextValue as DeltaStatic);
    }
    return value === nextValue;
};

const setEditorTabIndex = (_editor: Quill, _tabIndex: number) => {
    if (_editor?.scroll?.domNode) {
        (_editor.scroll.domNode as HTMLElement).tabIndex = _tabIndex;
    }
};

/*
Returns a weaker, unprivileged proxy object that only exposes read-only
accessors found on the editor instance, without any state-modifying methods.
*/
const makeUnprivilegedEditor = (_editor: Quill) => {
    const e = _editor;
    return {
        getHTML: () => e.root.innerHTML,
        getLength: e.getLength.bind(e),
        getText: e.getText.bind(e),
        getContents: e.getContents.bind(e),
        getSelection: e.getSelection.bind(e),
        getBounds: e.getBounds.bind(e),
    };
};

const ReactQuill = React.forwardRef<QuillRef, ReactQuillProps>((
    {
        bounds,
        children,
        className,
        formats,
        id,
        modules = {},
        onChange,
        onChangeSelection,
        onFocus,
        onBlur,
        onKeyDown,
        onKeyPress,
        onKeyUp,
        placeholder,
        preserveWhitespace,
        readOnly = false,
        scrollingContainer,
        style,
        tabIndex,
        theme = 'snow',
        value,
    },
    ref
) => {
    const [editor, setEditor] = useState<Quill>();
    // Reference to the element holding the Quill editing area.
    const [editingArea, setEditingArea] = useState<HTMLElement | null>(null);
    // Stores the contents of the editor to be restored after regeneration.
    const [, setRegenerationSnapshot] = useState<{
        delta: DeltaStatic,
        selection: Range,
    }>();

    const onEditorChangeText = useCallback((
        nextValue: string,
        delta: DeltaStatic,
        prevDelta: DeltaStatic,
        source: Sources,
        _editor: UnprivilegedEditor,
    ): void => {
        if (!editor) return;

        // We keep storing the same type of value as what the user gives us,
        // so that value comparisons will be more stable and predictable.
        const nextDelta = _editor.getContents();

        if (!isEqualValue(nextDelta, prevDelta)) {
            onChange?.(nextValue, nextDelta, source, _editor);
        }
    }, [editor, onChange]);

    const onEditorChangeSelection = useCallback((
        nextSelection: Range,
        prevSelection: Range,
        source: Sources,
        _editor: UnprivilegedEditor,
    ): void => {
        if (!editor) return;
        const hasGainedFocus = !prevSelection && nextSelection;
        const hasLostFocus = prevSelection && !nextSelection;

        if (nextSelection?.length === prevSelection?.length && nextSelection?.index === prevSelection?.index) return;

        onChangeSelection?.(nextSelection, source, _editor);

        if (hasGainedFocus) {
            onFocus?.(nextSelection, source, _editor);
        } else if (hasLostFocus) {
            onBlur?.(prevSelection, source, _editor);
        }
    }, [editor, onBlur, onChangeSelection, onFocus]);

    const onEditorChange = useCallback((
        eventName: 'text-change' | 'selection-change',
        target: DeltaStatic | Range,
        oldTarget: DeltaStatic | Range,
        source: Sources,
    ) => {
        // Expose the editor on change events via a weaker, unprivileged proxy
        // object that does not allow accidentally modifying editor state.
        if (editor) {
            const unprivilegedEditor = makeUnprivilegedEditor(editor);
            if (eventName === 'text-change') {
                onEditorChangeText(
                    (editor as Quill).root.innerHTML,
                    target as DeltaStatic,
                    oldTarget as DeltaStatic,
                    source,
                    unprivilegedEditor as UnprivilegedEditor
                );
            } else if (eventName === 'selection-change') {
                onEditorChangeSelection(
                    target as Range,
                    oldTarget as Range,
                    source,
                    unprivilegedEditor as UnprivilegedEditor
                );
            }
        }
    }, [editor, onEditorChangeSelection, onEditorChangeText]);

    const getEditorConfig = useCallback((): QuillOptions => {
        return {
            bounds,
            formats,
            modules,
            placeholder,
            readOnly,
            scrollingContainer,
            tabIndex,
            theme,
        };
    }, [bounds, formats, modules, placeholder, readOnly, scrollingContainer, tabIndex, theme]);

    /**
     Creates an editor on the given element. The editor will be passed the
     configuration, have its events bound,
     */
    const createEditor = useCallback((element: Element, config: QuillOptions): Quill => {
        const _editor = new Quill(element, config);
        if (config.tabIndex != null) {
            setEditorTabIndex(_editor, config.tabIndex);
        }
        return _editor;
    }, []);

    /**
     * Set (or reset) editor selection
     * @param _editor
     * @param range if range is not defined, the editor selection is reset with current value of selection
     */
    const setEditorSelection = (_editor: Quill, range: Range) => {
        if (range) {
            // Validate bounds before applying.
            const length = _editor.getLength();
            range.index = Math.max(0, Math.min(range.index, length - 1));
            range.length = Math.max(0, Math.min(range.length, (length - 1) - range.index));
            _editor.setSelection(range);
        }
    };
    
    useImperativeHandle(ref, () => ({
        getEditor() {
            return editor || null;
        },
    }), [editor]);

    useEffect(() => {
        if (editor && value) {
            const currentDelta = editor.getContents();
            if (!isEqualValue(value, currentDelta)) {
                const prevSelection = editor.getSelection();
                editor.setContents(value);
                postpone(() => setEditorSelection(editor, prevSelection));
            }
        }
    }, [editor, value]);

    useEffect(() => {
        if (editor) {
            if (readOnly) {
                editor.disable();
            } else {
                editor.enable();
            }
        }
    }, [editor, readOnly]);

    useEffect(() => {
        if (editor) {
            // Using `editor-change` allows picking up silent updates, like selection changes on typing.
            editor.on('editor-change', onEditorChange);
        }

        return () => {
            if (editor) {
                editor.off('editor-change', onEditorChange);
            }
        };
    }, [editor, onEditorChange]);

    useEffect(() => {
        if (!editingArea) return () => { /* */ };
        if (editingArea.nodeType === 3) {
            throw new Error('Editing area cannot be a text node');
        }
        setEditor(() => {
            const newEditor = createEditor(
                editingArea,
                getEditorConfig()
            );

            setRegenerationSnapshot((prevSnapshot) => {
                if (prevSnapshot) {
                    newEditor.setContents(prevSnapshot.delta);
                    postpone(() => setEditorSelection(newEditor, prevSnapshot.selection));
                }
                return undefined;
            });

            return newEditor;
        });

        return () => {
            setEditor((prevEditor) => {
                if (prevEditor) {
                    const delta = prevEditor.getContents();
                    const _selection = prevEditor.getSelection();
                    setRegenerationSnapshot({delta, selection: _selection});
                }
                editingArea.parentElement?.childNodes.forEach((node) => node !== editingArea && node.remove());
                return undefined;
            });
        };
    }, [createEditor, editingArea, getEditorConfig]);

    /*
    Renders an editor area, unless it has been provided one to clone.
    */
    const renderEditingArea = (): JSX.Element => {
        const properties = {
            ref: (instance: HTMLElement | null) => {
                setEditingArea(instance);
            },
        };

        if (React.Children.count(children)) {
            return React.cloneElement(
                React.Children.only(children) as React.ReactElement,
                properties
            );
        }

        return preserveWhitespace
            ? <pre ref={properties.ref} />
            : <div ref={properties.ref} />;
    };

    return (
        <div
            id={id}
            style={style}
            className={`quill ${className ?? ''}`}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
        >
            {renderEditingArea()}
        </div>
    );
});

export default ReactQuill;
