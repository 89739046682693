import React from 'react';
import i18n from 'i18next';
import {classWithModifiers} from '../../Helpers/css';
import MuIcon from '../MuIcon';

type Choice = {
    key: string;
    label: React.ReactNode;
    icon: string;
    fileInput?: {
        id: string;
        multiple: boolean;
        accept: string;
        onChange: (files: File[]) => void;
    };
}

type Props = {
    value: string | null;
    setValue: (val: string | null) => void;
    choice1: Choice | false;
    choice2: Choice | false;
    className?: string;
    flat?: boolean;
    disabled?: boolean;
}

const DualChoice: React.FC<Props> = (
    {
        value,
        setValue,
        choice1,
        choice2,
        className,
        flat = false,
        disabled = false,
    }
) => {
    const renderChoice = (choice: Choice) => (choice.fileInput ? (
        <label
            htmlFor={choice.fileInput.id}
            className={classWithModifiers('mu-dual-choice__choice', {selected: value === choice.key, flat})}
        >
            <input
                id={choice.fileInput.id}
                type="file"
                multiple={choice.fileInput.multiple}
                className="mu-dual-choice__choice-input"
                onClick={() => setValue(choice.key)}
                onChange={async (e) => {
                    if (e.target.files) {
                        choice.fileInput?.onChange(Array.from(e.target.files));
                    }
                }}
                accept={choice.fileInput.accept}
                disabled={disabled}
            />
            <MuIcon
                className="mu-dual-choice__choice-icon"
                svgName={choice.icon}
            />
            {choice.label}
        </label>
    ) : (
        <button
            type="button"
            className={classWithModifiers('mu-dual-choice__choice', {selected: value === choice.key, flat})}
            onClick={() => setValue(choice.key)}
            disabled={disabled}
        >
            <MuIcon
                className="mu-dual-choice__choice-icon"
                svgName={choice.icon}
            />
            {choice.label}
        </button>
    ));

    return (
        <section className={classWithModifiers('mu-dual-choice__container', {unique: !choice2}, className)}>
            {choice1 && renderChoice(choice1)}
            {choice1 && choice2 && (
                <span className="mu-dual-choice__or">
                    {i18n.t('modals.addImages.or')}
                </span>
            )}
            {choice2 && renderChoice(choice2)}
        </section>
    );
};

export default DualChoice;
