import React, {useCallback, useState} from 'react';

import {
    deleteMessage,
    editMessage,
    useMe,
    ChatMessageAttachment,
    ProfilesQueryParams,
    ChatMessage, createNote, createNoteText,
} from 'mushin-redux-store';
import i18n from 'i18next';
import Lightbox from 'yet-another-react-lightbox';
import MuIcon from '../MuIcon';
import MessageInput from '../MessageInput/MessageInput';
import Popover from '../Popover/Popover';
import MuButton from '../MuButton';
import {classWithModifiers} from '../../Helpers/css';
import {addNotifAction, addNotifSuccess} from '../../Redux/reducers/appNotificationsSlice';
import {useAppDispatch} from '../../Helpers/hooks';
import Client from '../../Services/Client';
import ActionDropdownMenu from '../ActionDropdownMenu/ActionDropdownMenu';
import QuillTranslation from '../Translation/QuillTranslation';

type Props = React.PropsWithChildren<{
    message: ChatMessage;
    userMentionsQueryParams?: ProfilesQueryParams;
    className?: string;
}>

const ATTACHMENT_AS_NOTE_ACTION_ID = 'ATTACHMENT_AS_NOTE';
const MESSAGE_AS_NOTE_ACTION_ID = 'MESSAGE_AS_NOTE';

const MessageContent: React.FC<Props> = ({
    message,
    userMentionsQueryParams,
    className,
    children,
}) => {
    const me = useMe();
    const [editingMessage, setEditingMessage] = useState(false);
    const [fullscreenIndex, setFullscreenIndex] = useState<number | null>(null);

    const dispatch = useAppDispatch();

    const onSubmitMessage = useCallback((msg) => {
        dispatch(editMessage(message.id, msg));
        setEditingMessage(false);
    }, [dispatch, message.id]);

    const handleDragStartEvent = async (e: React.DragEvent, attachment: ChatMessageAttachment) => {
        if (attachment.note) {
            e.dataTransfer.setData('mushin/note-id', attachment.note.id);
        } else {
            e.dataTransfer.setData('mushin/image', attachment.image_url);
        }
    };

    const isMine = message.author_id === me?.id;

    const messageActions = [
        {
            icon: 'Bulb',
            label: i18n.t('models.notes.addToAlbum'),
            handler: () => dispatch(addNotifAction(
                `${MESSAGE_AS_NOTE_ACTION_ID}-${message.id}`,
                i18n.t('models.notes.addToAlbum'),
                async () => {
                    await dispatch(createNoteText({
                        message_id: message.id,
                        upload_source: 'WEBAPP',
                    }));
                    dispatch(addNotifSuccess(i18n.t('upload_alert.success')));
                },
            )),
            hidden: !Client.isAllowedToEditOrganization(),
        },
        {
            icon: 'Edit',
            label: i18n.t('global.edit'),
            handler: () => setEditingMessage(!editingMessage),
            hidden: !isMine,
        },
        {
            icon: 'Delete',
            label: i18n.t('global.delete'),
            handler: () => dispatch(deleteMessage(message.id)),
            hidden: !isMine,
        },
    ];

    return (
        <div className={classWithModifiers('mu-message-content', null, className)}>
            {children}
            <div className="mu-message-content__attachments">
                {message.attachments?.map((attachment, index) => (
                    <div key={attachment.image_url} className="mu-message-content-attachment">
                        <Popover
                            className="mu-message-content-attachment__action"
                            hoveredElement={(
                                <MuButton
                                    className="mu-message-content-attachment__action-icon"
                                    icon="Bulb"
                                    handleClick={() => dispatch(addNotifAction(
                                        `${ATTACHMENT_AS_NOTE_ACTION_ID}-${attachment.image_url}`,
                                        i18n.t('models.notes.addToAlbum'),
                                        async () => {
                                            await dispatch(createNote({
                                                image: {
                                                    src: attachment.image_url,
                                                },
                                                message_id: message.id,
                                                upload_source: 'WEBAPP',
                                            }));
                                            dispatch(addNotifSuccess(i18n.t('upload_alert.success')));
                                        },
                                    ))}
                                />
                            )}
                            position="bottom"
                        >
                            {i18n.t('models.notes.addToAlbum')}
                        </Popover>
                        <img
                            draggable
                            onDragStart={(e) => handleDragStartEvent(e, attachment)}
                            src={attachment.image_url}
                            alt="attachment"
                            className="mu-message-content-attachment__image"
                            onClick={() => setFullscreenIndex(index)}
                        />
                    </div>
                ))}
            </div>
            {!!message.attachments?.length && (
                <Lightbox
                    open={fullscreenIndex !== null}
                    close={() => setFullscreenIndex(null)}
                    slides={message.attachments.map((attachment) => (
                        { src: attachment.image_url }
                    ))}
                />
            )}
            {editingMessage ? (
                <div className="mu-message-content__message--modify">
                    <MessageInput
                        defaultMessage={message.message}
                        onSubmitMessage={onSubmitMessage}
                        withImportFiles={false}
                        userMentionsQueryParams={userMentionsQueryParams}
                    />
                </div>
            ) : (
                <div className="mu-message-content__message">
                    <QuillTranslation
                        translationProp={message.messages}
                        fallback={message.message}
                        className="mu-rich-input"
                        theme=""
                    />
                </div>
            )}
            {(Client.isAllowedToEditOrganization() || isMine) && (
                <ActionDropdownMenu
                    items={messageActions}
                    toggle={(
                        <div className="mu-message-content__action-bar-item">
                            <MuIcon className="mu-action-bar__item-icon" svgName="MoreOptions" />
                        </div>
                    )}
                    contentClassName="mu-message-content__menu-action"
                />
            )}
        </div>
    );
};

export default MessageContent;
