export const undo = {
    path: 'M11.26 4.928V.752c0-.298-.15-.522-.448-.67-.223-.15-.596-.075-.82.074L.298 8.136c-.15.148-.298.37-.298.595 0 .225.075.45.298.598l9.694 8.053c.224.15.522.225.82.076.3-.15.448-.373.448-.672V12.46c10.44 0 11.185 6.71 11.26 7.01 0 .372.298.67.67.67.373 0 .672-.298.672-.67.074-.225.894-6.265-2.685-10.44-2.162-2.536-5.518-3.953-9.917-4.102zm-1.193 6.264c-.15.15-.224.298-.224.522v3.505L2.088 8.73 9.843 2.32v3.356c0 .447.298.746.746.746 4.25 0 7.455 1.267 9.47 3.58 1.49 1.788 2.236 3.95 2.46 5.74-1.64-2.237-4.475-4.772-11.93-4.772-.225 0-.374.075-.523.224z',
    viewBox: '0 0 24 21',
};
export const redo = {
    path: 'M2.817 9.03c2.162-2.536 5.518-3.953 9.918-4.102V.752c0-.298.15-.522.447-.67.224-.15.596-.075.82.074l9.694 7.98c.15.148.298.37.298.595 0 .225-.074.45-.298.598l-9.694 8.053c-.224.15-.522.225-.82.076-.298-.15-.447-.373-.447-.672V12.46c-10.44 0-11.186 6.71-11.26 7.01 0 .372-.298.67-.67.67-.374 0-.672-.298-.672-.67-.075-.225-.895-6.265 2.684-10.44zm10.59 1.938c.222 0 .37.075.52.224.15.15.224.298.224.522v3.505l7.756-6.49L14.15 2.32v3.356c0 .447-.297.746-.744.746-4.25 0-7.457 1.267-9.47 3.58-1.492 1.788-2.238 3.95-2.46 5.74 1.64-2.237 4.473-4.772 11.93-4.772z',
    viewBox: '0 0 24 21',
};
export const trashBin = {
    path: 'M6 18.75h1.5v-9H6v9zm4.5 0H12V8.25h-1.5v10.5zM21.75 3H15V.75c0-.45-.3-.75-.75-.75h-6C7.8 0 7.5.3 7.5.75V3H.75C.3 3 0 3.3 0 3.75s.3.75.75.75h.75v18c0 .825.675 1.5 1.5 1.5h16.5c.825 0 1.5-.675 1.5-1.5v-18h.75c.45 0 .75-.3.75-.75S22.2 3 21.75 3zM9 1.5h4.5V3H9V1.5zm10.5 3.75v16.5c0 .45-.3.75-.75.75h-15c-.45 0-.75-.3-.75-.75V4.5h16.5l-.15.3c.075.15.15.3.15.45zM15 18.75h1.5v-9H15v9z',
    viewBox: '0 0 23 24',
};
export const folder = {
    path: 'M22.5 3h-12V1.5C10.5.675 9.825 0 9 0H1.5C.675 0 0 .675 0 1.5v21c0 .825.675 1.5 1.5 1.5h21c.825 0 1.5-.675 1.5-1.5v-18c0-.825-.675-1.5-1.5-1.5zm0 5.25v13.5c0 .45-.3.75-.75.75H2.25c-.45 0-.75-.3-.75-.75V7.5h21v.75zM21.75 6H1.5V2.25c0-.45.3-.75.75-.75h6c.45 0 .75.3.75.75V4.5h12.75c.45 0 .75.3.75.75 0 .375-.3.75-.75.75z',
    viewBox: '0 0 24 24',
};
export const moveBackward = {
    path: 'M6.32 15.35V8.33c0-1.1.9-2 2-2h7.03v4.3c0 1.13.9 2.02 2 2.02h4.33v7.02c0 1.1-.9 2-2 2h-7.03v-4.3c0-1.13-.9-2.02-2-2.02H6.3zM16.85 2c0-.28.23-.5.5-.5H26c.28 0 .5.22.5.5v8.64c0 .28-.22.5-.5.5h-8.64c-.28 0-.5-.22-.5-.5V2zm-1.5 0c0-1.1.9-2 2-2H26c1.1 0 2 .9 2 2v8.64c0 1.1-.9 2-2 2h-8.64c-1.1 0-2-.88-2-2V2zM1.5 17.36c0-.28.22-.5.5-.5h8.64c.28 0 .5.22.5.5V26c0 .28-.22.5-.5.5H2c-.28 0-.5-.22-.5-.5v-8.64zm-1.5 0c0-1.1.9-2 2-2h8.64c1.1 0 2 .88 2 2V26c0 1.1-.88 2-2 2H2c-1.1 0-2-.9-2-2v-8.64z',
    viewBox: '0 0 28 28',
};
export const moveForward = {
    path: 'M21.677 12.645v7.025c0 1.11-.89 2.007-2.007 2.007h-7.025v4.32c0 1.106-.89 2.003-2.004 2.003H2.005C.897 28 0 27.11 0 25.996V17.36c0-1.108.89-2.005 2.004-2.005h4.32V8.33c0-1.11.89-2.007 2.006-2.007h7.025v-4.32C15.355.898 16.245 0 17.36 0h8.636C27.103 0 28 .89 28 2.004v8.637c0 1.108-.89 2.005-2.004 2.005h-4.32zm0-1.5h4.32c.282 0 .503-.222.503-.504V2.005c0-.283-.222-.504-.504-.504H17.36c-.284 0-.505.222-.505.504v4.32h2.815c1.11 0 2.007.89 2.007 2.006v2.815zm-15.354 5.71h-4.32c-.282 0-.503.222-.503.504v8.636c0 .283.222.504.504.504h8.637c.284 0 .505-.222.505-.504v-4.32H8.33c-1.11 0-2.007-.89-2.007-2.006v-2.815z',
    viewBox: '0 0 28 28',
};
export const template = {
    path: 'M3 10.5h18V3H3v7.5zm1.5-6h15V9h-15V4.5zM3 21h7.5v-9H3v9zm1.5-7.5H9v6H4.5v-6zM22.5 0h-21C.675 0 0 .675 0 1.5v21c0 .825.675 1.5 1.5 1.5h21c.825 0 1.5-.675 1.5-1.5v-21c0-.825-.675-1.5-1.5-1.5zm0 21.75c0 .45-.3.75-.75.75H2.25c-.45 0-.75-.3-.75-.75V2.25c0-.45.3-.75.75-.75h19.5c.45 0 .75.3.75.75v19.5zm-9-.75H21v-9h-7.5v9zm1.5-7.5h4.5v6H15v-6z',
    viewBox: '0 0 24 24',
};
export const out = {
    path: 'M22.5 0h-18C3.675 0 3 .675 3 1.5V6h1.5V2.25c0-.45.3-.75.75-.75h16.5c.45 0 .75.3.75.75v18c0 .45-.3.75-.75.75H5.25c-.45 0-.75-.3-.75-.75V16.5H3V21c0 .825.675 1.5 1.5 1.5h18c.825 0 1.5-.675 1.5-1.5V1.5c0-.825-.675-1.5-1.5-1.5zm-8.775 16.725c-.3.3-.3.75 0 1.05.3.3.75.3 1.05 0L20.7 11.85l.075-.075c.15-.15.225-.375.225-.525V11.025c0-.15-.075-.225-.15-.3l-.075-.075-5.925-5.925c-.3-.3-.75-.3-1.05 0-.3.3-.3.75 0 1.05l4.725 4.725H.75c-.45 0-.75.3-.75.75s.3.75.75.75h17.7l-4.725 4.725z',
    viewBox: '0 0 23 24',
};
export const squareSplitTop = {
    path: 'M21 0H3C1.35 0 0 1.35 0 3v18c0 1.65 1.35 3 3 3h18c1.65 0 3-1.35 3-3V3c0-1.65-1.35-3-3-3zm1.5 21c0 .825-.675 1.5-1.5 1.5H3c-.825 0-1.5-.675-1.5-1.5V7.5h21V21zm0-15h-21V3c0-.825.675-1.5 1.5-1.5h18c.825 0 1.5.675 1.5 1.5v3z',
    viewBox: '0 0 24 24',
};
export const squareSplitLeft = {
    path: 'M0 3v18c0 1.65 1.35 3 3 3h18c1.65 0 3-1.35 3-3V3c0-1.65-1.35-3-3-3H3C1.35 0 0 1.35 0 3zm21-1.5c.825 0 1.5.675 1.5 1.5v18c0 .825-.675 1.5-1.5 1.5H7.5v-21H21zm-15 0v21H3c-.825 0-1.5-.675-1.5-1.5V3c0-.825.675-1.5 1.5-1.5h3z',
    viewBox: '0 0 24 24',
};
export const squareSplitRight = {
    path: 'M24 3v18c0 1.65-1.35 3-3 3H3c-1.65 0-3-1.35-3-3V3c0-1.65 1.35-3 3-3h18c1.65 0 3 1.35 3 3zM3 1.5c-.825 0-1.5.675-1.5 1.5v18c0 .825.675 1.5 1.5 1.5h13.5v-21H3zm15 0v21h3c.825 0 1.5-.675 1.5-1.5V3c0-.825-.675-1.5-1.5-1.5h-3z',
    viewBox: '0 0 24 24',
};
export const squareSplitBottom = {
    path: 'M21 24H3c-1.65 0-3-1.35-3-3V3c0-1.65 1.35-3 3-3h18c1.65 0 3 1.35 3 3v18c0 1.65-1.35 3-3 3zm1.5-21c0-.825-.675-1.5-1.5-1.5H3c-.825 0-1.5.675-1.5 1.5v13.5h21V3zm0 15h-21v3c0 .825.675 1.5 1.5 1.5h18c.825 0 1.5-.675 1.5-1.5v-3z',
    viewBox: '0 0 24 24',
};
export const expandCorner = {
    path: 'M24.5 0h-21C1.5 0 0 1.6 0 3.5v21c0 2 1.6 3.5 3.5 3.5h21c2 0 3.5-1.6 3.5-3.5v-21c0-2-1.6-3.5-3.5-3.5zm1.8 24.5c0 1-.8 1.8-1.8 1.8h-21c-1 0-1.8-.8-1.8-1.8v-21c0-1 .8-1.8 1.8-1.8h21c1 0 1.8.8 1.8 1.8v21zM23 3.8l-6.4 6.4v-5c0-.5-.3-.8-1-.8-.4 0-.7.3-.7.8V13H23c.5 0 .8-.2.8-.8 0-.5-.3-.8-1-.8H18L24.2 5c.4-.4.4-1 0-1.2-.3-.4-.8-.4-1.2 0zm-10.8 11h-7c-.5 0-.8.4-.8 1 0 .5.3.8.8.8h5L3.8 23c-.4.4-.4 1 0 1.2.3.4.8.4 1.2 0l6.4-6.3v4.8c0 .5.3.8.8.8.6 0 1-.3 1-1v-7l-.3-.5H12z',
    viewBox: '0 0 28 28',
};
export const shrink = {
    path: 'M24.5 0h-21C1.5 0 0 1.6 0 3.5v21c0 2 1.6 3.5 3.5 3.5h21c2 0 3.5-1.6 3.5-3.5v-21c0-2-1.6-3.5-3.5-3.5zm1.8 24.5c0 1-.8 1.8-1.8 1.8h-21c-1 0-1.8-.8-1.8-1.8v-21c0-1 .8-1.8 1.8-1.8h21c1 0 1.8.8 1.8 1.8v21zM23 3.8l-6.4 6.4v-5c0-.5-.3-.8-1-.8-.4 0-.7.3-.7.8V13H23c.5 0 .8-.2.8-.8 0-.5-.3-.8-1-.8H18L24.2 5c.4-.4.4-1 0-1.2-.3-.4-.8-.4-1.2 0zm-10.8 11h-7c-.5 0-.8.4-.8 1 0 .5.3.8.8.8h5L3.8 23c-.4.4-.4 1 0 1.2.3.4.8.4 1.2 0l6.4-6.3v4.8c0 .5.3.8.8.8.6 0 1-.3 1-1v-7l-.3-.5H12z',
    viewBox: '0 0 28 28',
};
export const expand = {
    path: 'M24.5 0h-21C1.5 0 0 1.6 0 3.5v21c0 2 1.6 3.5 3.5 3.5h21c2 0 3.5-1.6 3.5-3.5v-21c0-2-1.6-3.5-3.5-3.5zm1.8 24.5c0 1-.8 1.8-1.8 1.8h-21c-1 0-1.8-.8-1.8-1.8v-21c0-1 .8-1.8 1.8-1.8h21c1 0 1.8.8 1.8 1.8v21zM22 5.2h-4.5c-.5 0-1 .4-1 1 0 .5.5.8 1 .8h2.3L16 10.8c-.3.3-.3.8 0 1.2l.6.3c.3 0 .5 0 .6-.3L21 8.2v2.3c0 .5.4 1 1 1 .4 0 .8-.5.8-1V6c0-.4-.4-.8-1-.8zM8 7h2.3c.5 0 1-.3 1-1 0-.4-.5-.8-1-.8H6c-.4 0-.8.4-.8 1v4.3c0 .5.4 1 1 1 .5 0 .8-.5.8-1V8.2l3.8 3.8c0 .2.4.3.6.3.2 0 .4 0 .6-.3.3-.4.3-1 0-1.2L8.2 7zm2.6 9L7 19.8v-2.3c0-.5-.3-1-1-1-.4 0-.8.5-.8 1V22c0 .4.4.8 1 .8h4.3c.5 0 1-.4 1-1 0-.4-.5-.8-1-.8H8.2l3.8-3.8c.3-.3.3-.8 0-1.2-.4-.3-1-.3-1.2 0zm11 .6c-.4 0-.8.4-.8 1v2.2L17.2 16c-.3-.3-.8-.3-1.2 0-.3.4-.3 1 0 1.2l3.8 3.8h-2.3c-.5 0-1 .4-1 1 0 .4.5.8 1 .8H22c.4 0 .8-.4.8-1v-4.3c0-.5-.4-1-1-1z',
    viewBox: '0 0 28 28',
};
