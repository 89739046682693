import React from 'react';
import {useNotes} from 'mushin-redux-store';
import NotesJustifiedGrid from '../../../../Components/NotesJustifiedGrid/NotesJustifiedGrid';

type Props = {
    noteIds: string[];
    setOpenNoteId: (noteId: string) => void;
}

const ContributorDetailsNotesGrid: React.FC<Props> = ({ noteIds, setOpenNoteId }) => {
    const notes = useNotes(noteIds);

    return notes ? (
        <NotesJustifiedGrid
            uploadingNoteIds={[]}
            notes={notes}
            getItemClickHandler={(note) => () => setOpenNoteId(note.id)}
            showItemFavorite
        />
    ) : null;
};

export default ContributorDetailsNotesGrid;
