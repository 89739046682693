import React from 'react';
import PropTypes from 'prop-types';
import {MoodboardDrawer, Board, TemplateContent} from 'mushin-node-moodboard';
import { Camera } from '../type';
import { Context, Mode, Pointer, State } from './behaviors/type';

import * as cameraUtils from './injectCamera';
import * as translateHandler from './behaviors/translate';
import * as scaleHandler from './behaviors/scale';
import * as hoverHandler from './behaviors/hover';
import * as cameraZoomHandler from './behaviors/cameraZoom';
import * as cameraPanHandler from './behaviors/cameraPan';
import * as selectHandler from './behaviors/select';
import * as cropPanHandler from './behaviors/cropPan';
import * as cropScaleHandler from './behaviors/cropScale';
import * as dropContentInCellHandler from './behaviors/dropContentInCell';
import * as dropNewContentHandler from './behaviors/dropNewContent';
import * as setMixedSplitHandler from './behaviors/setMixedSplit';
import * as editTextHandler from './behaviors/editText';
import {BoardLayout} from './BoardLayout';
import {ActionLayer} from './actionLayer';

type Props = {
  selectedCell: string | null;
  mode: Mode;
  board: Board;
  camera: Camera;
  setMode: (mode: Mode, selectedCell?: string | null) => void;
  setBoard: (board: Board) => void;
  setCamera: (camera: Camera) => void;
  endBoardTransformBatch?: () => void;
  templateContents: TemplateContent;
  draggedElement: any;
  startDragElement: any;
  addElementInNewCell: any;
  addTextInNewCell: any;
  addElementInCell: any;
};

const getHandlerForMode = (mode: Mode): Array<Object> => {
    switch (mode) {
        case 'layout':
            return [hoverHandler, scaleHandler, translateHandler, cameraZoomHandler, cameraPanHandler, selectHandler, dropContentInCellHandler, dropNewContentHandler];

        case 'cell':
            return [hoverHandler, scaleHandler, setMixedSplitHandler, translateHandler, cameraZoomHandler, cameraPanHandler, editTextHandler, selectHandler];

        case 'textEdit':
            return [hoverHandler, selectHandler];

        case 'crop':
            return [hoverHandler, cropPanHandler, cropScaleHandler, selectHandler];

        default:
            return [];
    }
};

class BoardEditor extends React.Component<Props, State> {
    state = {
        drawer: new MoodboardDrawer(),
        drag: null,
        dragging: null,
        ratio: 0,
        rect: {
            width: 1,
            height: 1,
        },
        hoveredCell: null,
        snapRules: [],
    };

    _unsubscribe: null;

    static contextTypes = {
        layoutUpdater: PropTypes.shape({}),
    };

    onDrop: (event: MouseEvent) => any = () => 0;

    onDragOver: (event: MouseEvent) => any = () => 0;

    onTouchDown: (event: MouseEvent) => any = () => 0;

    onTouchMove: (event: MouseEvent) => any = () => 0;

    onTouchUp: (event: MouseEvent) => any = () => 0;

    onWheel: (event: MouseEvent) => any = () => 0;

    initSize = () => {
        if (!this.refs.container) return;

        const rect = this.refs.container.getBoundingClientRect();

        const ratio = Math.min(rect.width / this.props.board.width, rect.height / this.props.board.height) * 0.8;

        const marge = {
            x: rect.width / ratio - this.props.board.width,
            y: rect.height / ratio - this.props.board.height,
        };

        this.props.setCamera({
            a: 1,
            b: {
                x: marge.x / 2,
                y: marge.y / 2,
            },
        });

        this.setState({ ratio, rect });
    };

    onResize = () => {
        if (!this.refs.container) return;

        const rect = this.refs.container.getBoundingClientRect();

        const ratio = Math.min(rect.width / this.props.board.width, rect.height / this.props.board.height) * 0.8;

        if (this.state.ratio !== ratio || this.state.rect.width !== rect.width || this.state.rect.height !== rect.height) {
        // When resize, put the middle of the board at the same proportion than before.
        // Ex: if the middle of the board was at 50% of the x axis before resize, it needs to be at 50% after too.
            const boardMiddle = {
                x: this.props.camera.a * this.props.board.width / 2,
                y: this.props.camera.a * this.props.board.height / 2,
            };
            const oldWidth = this.state.rect.width / this.state.ratio;
            const newWidth = rect.width / ratio;
            const oldHeight = this.state.rect.height / this.state.ratio;
            const newHeight = rect.height / ratio;
            const x = newWidth * (this.props.camera.b.x + boardMiddle.x) / oldWidth - boardMiddle.x;
            const y = newHeight * (this.props.camera.b.y + boardMiddle.y) / oldHeight - boardMiddle.y;

            this.props.setCamera({
                a: this.props.camera.a,
                b: { x, y },
            });

            this.setState({ ratio, rect });
        }
    };

    constructor() {
        super();

        ['onDrop', 'onDragOver', 'onDragStart', 'onTouchMove', 'onTouchUp', 'onTouchDown', 'onWheel'].forEach((eventName) => {
        // $FlowFixMe
            this[eventName] = (event: MouseEvent) => {
                if (!this.insideEditor(event)) {
                    return;
                }

                const unproject = cameraUtils.unproject(this.props.camera);
                const pointers = this.getPointers(event).map((x) => ({
                    ...x,
                    ...unproject(x),
                }));

                const originalContext: Context = {
                    state: this.state,
                    templateContents: this.props.templateContents,
                    board: this.props.board,
                    camera: this.props.camera,
                    mode: this.props.mode,
                    selectedCell: this.props.selectedCell,
                    draggedElement: this.props.draggedElement,

                    // xxx arthur : for cases that does not fit the pattern well
                    actions: {
                        addElementInNewCell: this.props.addElementInNewCell,
                        addTextInNewCell: this.props.addTextInNewCell,
                        addElementInCell: this.props.addElementInCell,
                        startDragElement: this.props.startDragElement,
                    },
                };

                let context = { ...originalContext };

                getHandlerForMode(this.props.mode).map((x) => x[eventName]).filter(Boolean).forEach((handler) => {
                    context = {
                        ...context,
                        ...(handler(context, pointers, event) || {}),
                    };
                });

                if (originalContext.state !== context.state) { this.setState(context.state); }

                if (originalContext.camera !== context.camera) { this.props.setCamera(context.camera); }

                if (originalContext.board !== context.board) { this.props.setBoard(context.board); }

                if (originalContext.mode !== context.mode || originalContext.selectedCell !== context.selectedCell) { this.props.setMode(context.mode, context.selectedCell); }

                if (eventName === 'onTouchUp' && this.props.endBoardTransformBatch) { this.props.endBoardTransformBatch(); }
            };
        });
    }

    insideEditor(event: MouseEvent): Array<Pointer> {
        if (!this.refs.container) return null;

        const {
            top,
            bottom,
            left,
            right,
        } = this.refs.container.getBoundingClientRect();

        return !(event.clientX < left || event.clientX > right || event.clientY < top || event.clientY > bottom);
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize);
            window.removeEventListener('mouseup', this.onTouchUp);
            window.removeEventListener('mousedown', this.onTouchDown);
            window.removeEventListener('mousemove', this.onTouchMove);
            window.removeEventListener('wheel', this.onWheel);
            window.removeEventListener('dragstart', this.onDragStart);
            window.removeEventListener('dragover', this.onDragOver);
            window.removeEventListener('drop', this.onDrop);

            window.addEventListener('resize', this.onResize);
            window.addEventListener('mouseup', this.onTouchUp);
            window.addEventListener('mousedown', this.onTouchDown);
            window.addEventListener('mousemove', this.onTouchMove);
            window.addEventListener('wheel', this.onWheel);
            window.addEventListener('dragstart', this.onDragStart);
            window.addEventListener('dragover', this.onDragOver);
            window.addEventListener('drop', this.onDrop);
        }
        this._unsubscribe && this._unsubscribe();
        this._unsubscribe = this.context.layoutUpdater && this.context.layoutUpdater.subsribe(this.onResize);
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize);
            window.removeEventListener('mouseup', this.onTouchUp);
            window.removeEventListener('mousedown', this.onTouchDown);
            window.removeEventListener('mousemove', this.onTouchMove);
            window.removeEventListener('wheel', this.onWheel);
            window.removeEventListener('dragstart', this.onDragStart);
            window.removeEventListener('dragover', this.onDragOver);
            window.removeEventListener('drop', this.onDrop);
        }
        this._unsubscribe && this._unsubscribe();
    }

    onDragStart: (event: MouseEvent) => any = () => 0;

    getPointers(event: MouseEvent): Array<Pointer> {
        if (!this.refs.container) return null;

        const {
            top,
            left,
        } = this.refs.container.getBoundingClientRect();

        return [{
            key: 'mouse',
            x: (event.clientX - left) / (this.state.ratio || 1),
            y: (event.clientY - top) / (this.state.ratio || 1),
        }];
    }

    render() {
        if (this.state.ratio === 0 && typeof requestAnimationFrame !== 'undefined') { requestAnimationFrame(this.initSize); }

        return (
            <div style={{
                width: '100%',
                height: '100%',
            }}
            >
                <div
                    style={{
                        bottom: 0,
                        right: 0,
                        left: 0,
                        top: 0,
                        position: 'absolute',
                    }}
                    ref="container"
                >
                    <BoardLayout
                        {...this.props}
                        board={this.props.board}
                        camera={this.props.camera}
                        selectedCell={this.props.selectedCell}
                        hoveredCell={this.state.hoveredCell}
                        cropVisible={this.props.mode === 'crop'}
                        ratio={this.state.ratio}
                        width={this.state.rect.width}
                        height={this.state.rect.height}
                        drawer={this.state.drawer}
                    />
                </div>
                <ActionLayer
                    drawer={this.state.drawer}
                    containerDimensions={this.state.rect}
                    templateContents={this.props.templateContents}
                    board={this.props.board}
                    camera={this.props.camera}
                    selectedCell={this.props.selectedCell}
                    hoveredCell={this.state.hoveredCell}
                    snapRules={this.state.snapRules}
                    drag={this.state.drag}
                    setBoard={this.props.setBoard}
                    setCamera={this.props.setCamera}
                    ratio={this.state.ratio}
                />
            </div>
        );
    }
}

export default BoardEditor;
