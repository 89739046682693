import React, { ReactNode, createContext, useContext, useState } from 'react';
import { AvailableLocale } from 'mushin-redux-store';

type Locale = AvailableLocale | 'current';

type ContextValues = {
  locale: Locale;
  onChangeLocale: (_: Locale) => void;
}

const QuestionnaireEditorContext = createContext<ContextValues>({} as ContextValues);

export const useQuestionnaireEditorContext = () => useContext(QuestionnaireEditorContext);

const QuestionnaireEditorProvider = ({ children }: { children: ReactNode}) => {
    const [locale, setLocale] = useState<Locale>('current');
    
    const onChangeLocale = (newLocale: Locale) => {
        setLocale(newLocale);
    };

    return (
        <QuestionnaireEditorContext.Provider value={{ locale, onChangeLocale }}>
            {children}
        </QuestionnaireEditorContext.Provider>
    );
};

export default QuestionnaireEditorProvider;
