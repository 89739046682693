import React, {useState} from 'react';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import MuIcon from '../MuIcon';
import {classWithModifiers} from '../../Helpers/css';

type Props = React.PropsWithChildren<{
    value: React.ReactNode;
    initialOpen?: boolean;
    removable?: boolean;
    disabled?: boolean;
    mod?: 'contrast' | 'light';
    onRemove?: () => void;
    className?: string;
    iconName?: string;
    scrollRef?: (node: HTMLElement | null) => void;
}>

const Select: React.FC<Props> = (
    {
        value,
        initialOpen = false,
        removable,
        disabled,
        mod,
        onRemove,
        className,
        iconName,
        children,
        scrollRef,
    }
) => {
    const [currentValue, setValue] = useState(value);
    const [open, setOpen] = useState(initialOpen);

    if (currentValue !== value) {
        setValue(value);
        setOpen(false);
    }

    return (
        <DropdownMenu
            className={classWithModifiers('mu-select', null, className)}
            align="justify"
            isOpen={open}
            setIsOpen={(_open) => !disabled && setOpen(_open)}
            scrollRef={scrollRef}
            toggle={(
                <div className={classWithModifiers('mu-filters-toolbar__filter-toggle', [{disabled}, mod])}>
                    {iconName && (
                        <MuIcon
                            className="mu-filters-toolbar__icon"
                            svgName={iconName}
                        />
                    )}
                    {currentValue}
                    <MuIcon className="mu-filters-toolbar__filter-toggle-icon" svgName="ArrowDown" />
                    {removable && (
                        <button
                            type="button"
                            className="mu-filters-toolbar__filter-remove-button"
                            onClick={() => {
                                if (onRemove) {
                                    onRemove();
                                }
                            }}
                        >
                            <MuIcon className="mu-filters-toolbar__filter-remove-icon" svgName="CloseCircleFill" />
                        </button>
                    )}
                </div>
            )}
        >
            {children}
        </DropdownMenu>
    );
};

export default Select;
