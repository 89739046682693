import React, { useState } from 'react';
import {TranslatedProp} from 'mushin-redux-store';
import i18next from 'i18next';
import { classWithModifiers } from '../../Helpers/css';
import MuIcon from '../MuIcon';

export interface TextTranslationProps {
    translationProp?: TranslatedProp<string>;
    fallback: string;
    center?: boolean;
    additionalTitle?: React.ReactNode;
    onTranslate?: () => void;
    disabledSwitch?: boolean;
}
const TextTranslation: React.FC<TextTranslationProps> = ({
    translationProp,
    fallback,
    center,
    additionalTitle,
    onTranslate,
    disabledSwitch,
}) => {
    const userLanguage = i18next.resolvedLanguage;

    const translationText = translationProp?.lang
    && userLanguage
    && userLanguage !== translationProp.lang
    && translationProp.translations[userLanguage]?.value;

    const originalText = translationProp?.original;

    const canSwitch = (!!translationText || !!(onTranslate && userLanguage !== translationProp?.lang)) && !disabledSwitch;

    const [showOriginal, setShowOriginal] = useState(onTranslate && !translationText);

    const handleTranslate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        const willBeTranslated = showOriginal;
        if (willBeTranslated && onTranslate && !translationText) {
            onTranslate();
        }
        setShowOriginal((prev) => !prev);
    };

    if (!translationProp) {
        return (
            <div
                className={classWithModifiers('mu-text-translation', {
                    center,
                })}
            >
                <span className="mu-text-translation__content">
                    {fallback}
                    {additionalTitle}
                </span>
            </div>
        );
    }

    return (
        <div
            className={classWithModifiers('mu-text-translation', {
                center,
            })}
        >
            <span className="mu-text-translation__content">
                {showOriginal ? originalText : (translationText || originalText)}
                {additionalTitle}
            </span>
            {canSwitch && (
                <button
                    type="button"
                    className="mu-text-translation__switch"
                    onClick={handleTranslate}
                >
                    <MuIcon svgName="Global" />
                    <span className="mu-text-translation__switch-text">
                        {showOriginal ? i18next.t('global.translate') : i18next.t('global.seeTheOriginal')}
                    </span>
                </button>
            )}
        </div>
    );
};

export default TextTranslation;
