import React from 'react';
import {classWithModifiers, Modifiers} from '../../Helpers/css';

type Props = {
    className?: string;
    classModifiers?: Modifiers;
    children: React.ReactNode;
};

const Badge: React.FC<Props> = ({
    className,
    classModifiers,
    children,
}: Props) => (
    <div className={classWithModifiers('mu-badge', classModifiers, className)}>
        {children}
    </div>
);

export default Badge;
