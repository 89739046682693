import React, {useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Questionnaire, useQuestionnaireAnswer, validateAnswersToQuestionnaire,
} from 'mushin-redux-store';
import i18n from 'i18next';
import * as Sentry from '@sentry/react';
import QuestionAnswer from './QuestionAnswer';
import Client from '../../Services/Client';
import {AnswerValue} from './types';
import MuButton from '../../Components/MuButton';
import {useAppDispatch} from '../../Helpers/hooks';
import {getInitialHistory, getNextQuestionIndex, getQuestionnaireBaseUrl, uploadAnswer, validateAnswer} from '../../Helpers/questionnaires';
import MuButtonWithLoader from '../../Components/MuButtonWithLoader';
import TextTranslation from '../../Components/Translation/TextTranslation';
import QuillTranslation from '../../Components/Translation/QuillTranslation';

type Params = {
    questionnaireId: string;
    userId?: string;
    projectId?: string;
}

type Errors = { [questionId: string]: string | undefined }

type Props = {
    questionnaire: Questionnaire | null | undefined;
}

const QuestionnaireAnswer: React.FC<Props> = ({questionnaire}) => {
    const { questionnaireId, userId, projectId } = useParams<Params>();
    const [questionAnswers] = useQuestionnaireAnswer(
        questionnaireId, userId || Client.getId(),
    );
    const [answersValues, setAnswersValues] = useState<{ [questionId: string]: AnswerValue | null }>({});
    const [answersErrors, setAnswersErrors] = useState<Errors>({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number | null>(null);
    const [prevQuestionIndexes, setPrevQuestionIndexes] = useState<number[]>([]);
    const ref = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    if (questionnaire && questionAnswers && currentQuestionIndex === null) {
        const initialHistory = getInitialHistory(questionnaire, questionAnswers);
        const initialIndex = initialHistory.pop() as number;
        setPrevQuestionIndexes(initialHistory);
        setCurrentQuestionIndex(initialIndex);
    }

    if (!questionAnswers || !questionnaire || currentQuestionIndex === null) return null;

    const hasToAnswer = true;

    const handleValidate = async () => {
        let ok = true;
        const errors: Errors = {};
        const currentQuestion = currentQuestionIndex >= 0 ? questionnaire.questions[currentQuestionIndex] : undefined;
        const answerValue = (
            currentQuestion ? answersValues[currentQuestion.id] || questionAnswers[currentQuestion.id] : undefined
        );

        if (currentQuestion && !userId) {
            const [valid, error] = validateAnswer(
                currentQuestion,
                answerValue,
            );
            if (!valid) {
                ok = false;
                if (error) {
                    errors[currentQuestion.id] = error;
                }
            }
        }
        setAnswersErrors(errors);
        if (!ok) return;

        if (currentQuestion && (answersValues[currentQuestion.id] || !questionAnswers[currentQuestion.id])) {
            try {
                await dispatch(uploadAnswer(
                    questionnaireId,
                    currentQuestion,
                    answersValues[currentQuestion.id],
                    (setter) => {
                        setAnswersValues((values) => ({
                            ...values,
                            [currentQuestion.id]: setter(values[currentQuestion.id]),
                        }));
                    }
                ));
            } catch (e) {
                Sentry.captureException(e);
                errors[currentQuestion.id] = 'global';
                setAnswersErrors(errors);
                return;
            }
        }

        const nextQuestionIndex = getNextQuestionIndex(questionnaire, currentQuestionIndex, answerValue);

        if (nextQuestionIndex < questionnaire?.questions?.length) {
            setCurrentQuestionIndex(nextQuestionIndex);
            setPrevQuestionIndexes((prev) => ([...prev, currentQuestionIndex]));
            ref.current?.scrollIntoView();
        } else {
            await dispatch(validateAnswersToQuestionnaire(questionnaire.id));
            if (!projectId) {
                if (Client.isConfirmed()) {
                    navigate(`/questionnaires/${questionnaire.candidacy ? 'candidacy' : 'response'}/success`);
                } else if (questionnaire.forceSSO) {
                    window.location.href = `/api/v3/login/${questionnaire.forceSSO}`;
                } else {
                    navigate(`/register?landing=${questionnaire.candidacy ? 'candidacy' : 'response'}`);
                }
            } else {
                const questionnaireUrl = getQuestionnaireBaseUrl(questionnaire, projectId);
                navigate(`${questionnaireUrl}/answer-success`);
            }
        }
    };

    const handleBack = () => {
        setCurrentQuestionIndex(prevQuestionIndexes.pop() as number);
        setPrevQuestionIndexes([...prevQuestionIndexes]);
    };

    const buttonLabel = () => {
        if (userId) return i18n.t('global.next');
        return currentQuestionIndex === -1 ? i18n.t('global.start') : i18n.t('global.next');
    };

    const activeQuestion = questionnaire.questions[currentQuestionIndex];

    return (
        <div ref={ref} className="mu-questionnaire-answer">
            {currentQuestionIndex === -1 ? (
                <div className="mu-questionnaire-answer__header">
                    <h1 className="mu-questionnaire-answer__title">
                        <TextTranslation translationProp={questionnaire.titles} fallback={questionnaire.title} />
                    </h1>
                    {questionnaire.desc && (
                        <div className="mu-questionnaire-answer__desc">
                            <QuillTranslation
                                translationProp={questionnaire.descriptions}
                                fallback={questionnaire.desc}
                                className="mu-rich-input"
                                theme=""
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className="mu-questionnaire-answer__questions-list">
                    {activeQuestion && (
                        <div key={activeQuestion.id}>
                            <QuestionAnswer
                                index={currentQuestionIndex}
                                total={questionnaire.questions.length}
                                question={activeQuestion}
                                previousAnswer={questionAnswers[activeQuestion.id]}
                                answer={answersValues[activeQuestion.id] || null}
                                setAnswer={(value) => {
                                    setAnswersValues((values) => ({
                                        ...values,
                                        [activeQuestion.id]: value,
                                    }));
                                }}
                                disabled={!hasToAnswer}
                                error={answersErrors[activeQuestion.id]}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className="mu-questionnaire-answer__action-bar">
                {!!prevQuestionIndexes.length && (
                    <MuButton
                        className="mu-btn mu-btn--outline"
                        label={i18n.t('global.back')}
                        handleClick={handleBack}
                    />
                )}
                <MuButtonWithLoader
                    disabled={
                        !!userId && currentQuestionIndex === questionnaire?.questions?.length - 1
                    }
                    label={buttonLabel()}
                    handleClick={handleValidate}
                />
            </div>
        </div>
    );
};

export default QuestionnaireAnswer;
