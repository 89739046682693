import React, {useState} from 'react';
import * as Sentry from '@sentry/react';
import {Link, useNavigate} from 'react-router-dom';
import {updateAccount} from 'mushin-redux-store';
import i18n from 'i18next';
import SessionsLayout from '../Components/SessionsLayout/SessionsLayout';
import MuButton from '../../../Components/MuButton/MuButton';
import Alert from '../../../Components/Alert/Alert';
import {useAppDispatch} from '../../../Helpers/hooks';
import InputText from '../../../Components/InputText/InputText';
import {passwordValidator} from '../../../Components/Form/validation';
import {useSearchParams} from '../../../Helpers/queryParser';
import {loginUrl, successUrl} from '../helpers';

const ForgotPasswordEdit: React.FC = () => {
    const [searchParams] = useSearchParams<{landing?: string}>();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordError, setPasswordError] = useState<string | null>(null);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <SessionsLayout title={i18n.t('accounts.reset_password.title')}>
            <div>
                {passwordError && (
                    <Alert
                        classModifiers={['error', 'alert-form']}
                        label={i18n.t<string>(passwordError)}
                    />
                )}
                <div className="mu-form-row">
                    <div className="mu-form-row__group">
                        <InputText
                            type="password"
                            classModifiers={['full-width']}
                            placeholder={i18n.t('models.users.password')}
                            value={password}
                            onChange={(event) => setPassword(event.currentTarget.value)}
                            autocomplete="new-password"
                            autoCapitalize="off"
                            contrast
                        />
                    </div>
                </div>
                <div className="mu-form-row">
                    <div className="mu-form-row__group">
                        <InputText
                            type="password"
                            classModifiers={['full-width']}
                            placeholder={i18n.t('models.users.password_confirmation')}
                            value={passwordConfirmation}
                            onChange={(event) => setPasswordConfirmation(event.currentTarget.value)}
                            autocomplete="new-password"
                            autoCapitalize="off"
                            contrast
                        />
                    </div>
                </div>
                <MuButton
                    className="mu-sessions__submit-button"
                    type="button"
                    classModifiers={['large']}
                    label={i18n.t('accounts.reset_password.submit')}
                    handleClick={async (): Promise<void> => {
                        if (!password) {
                            setPasswordError('global.errors.required');
                        } else if (!passwordValidator(password).valid) {
                            setPasswordError('global.errors.wrong_password_format');
                        } else if (password !== passwordConfirmation) {
                            setPasswordError('global.errors.no_correspondance_password');
                        } else {
                            try {
                                await dispatch(updateAccount({
                                    password,
                                }));
                                navigate(successUrl(searchParams.landing));
                            } catch (err) {
                                Sentry.captureException(err);
                                setPasswordError('accounts.reset_password.errors.unknown');
                            }
                        }
                    }}
                />
                <Link to={loginUrl(searchParams.landing) || '/login'} className="mu-form-row__link mu-link">
                    {i18n.t('global.back')}
                </Link>
            </div>
        </SessionsLayout>
    );
};

export default ForgotPasswordEdit;
