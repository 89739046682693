import React, { useState } from 'react';
import {useCurrentOrganizationId, useProjectsQuery} from 'mushin-redux-store';
import i18n from 'i18next';
import Select from '../Select/Select';
import {classWithModifiers} from '../../Helpers/css';
import FormTextInput from '../Form/FormTextInput';
import {UseFieldState} from '../Form/common';

type Props = {
    field: UseFieldState<string>;
};

const CategorySelector: React.FC<Props> = ({
    field,
}) => {
    const [currentOrganizationId] = useCurrentOrganizationId();
    const projectQuery = useProjectsQuery({organization_id: currentOrganizationId}, true);
    const [isNewCategory, setNewCategory] = useState(false);

    if (!currentOrganizationId) return null;

    const selectCategory = (selectedCategory: string, isNew = false) => {
        setNewCategory(isNew);
        field.setValue(selectedCategory);
    };

    return (
        <>
            <label className="mu-input__label">{i18n.t('project.category')}</label>
            <div className="mu-form-row">
                <Select
                    className="mu-form-col"
                    value={
                        isNewCategory
                            ? i18n.t<string>('project.newCategory')
                            : field.value || i18n.t<string>('project.noCategory')
                    }
                >
                    <button
                        className={classWithModifiers(
                            'mu-dropdown-menu__button-item', {selected: !isNewCategory && !field.value}
                        )}
                        type="button"
                        onClick={() => selectCategory('')}
                    >
                        {i18n.t('project.noCategory')}
                    </button>
                    <button
                        className={classWithModifiers(
                            'mu-dropdown-menu__button-item', {selected: isNewCategory}
                        )}
                        type="button"
                        onClick={() => selectCategory('', true)}
                    >
                        {i18n.t('project.newCategory')}
                    </button>
                    {projectQuery.aggregations?.categories.map((cat) => (
                        <button
                            key={cat.key}
                            className={classWithModifiers(
                                'mu-dropdown-menu__button-item', {selected: field.value === cat.key}
                            )}
                            type="button"
                            onClick={() => selectCategory(cat.key)}
                        >
                            {`${cat.key} (${cat.count})`}
                        </button>
                    ))}
                </Select>
                {isNewCategory ? (
                    <FormTextInput
                        className="mu-form-col"
                        placeholder={i18n.t('project.newCategory')}
                        field={field}
                    />
                ) : (<div className="mu-form-col" />)}
            </div>
        </>
    );
};

export default CategorySelector;
