import React from 'react';
import {useMember} from 'mushin-redux-store';
import { UsernameJob } from './UsernameJob';
import {getNameToDisplay} from '../../Helpers/users';
import UserAvatar from './UserAvatar';

type UserCardProps = {
    id: string;
    hideRealName?: boolean;
    hideEmail?: boolean;
    size?: 'small' | 'large';
};

const UserCard: React.FC<UserCardProps> = ({
    id,
    hideEmail = false,
    size,
}) => {
    const user = useMember(id);

    if (!user) return null;

    return (
        <div className="mu-user-card">
            <UserAvatar userId={id} size={size} />
            <UsernameJob
                name={getNameToDisplay(user)}
                job={(user.email && !hideEmail) ? `${user.email} ${user.job ? `(${user.job})` : ''}` : user.job}
                classModifiers="user-card"
            />
        </div>
    );
};

export default UserCard;
