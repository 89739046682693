export const blurIfHasFocus = (dataBlurIfHasFocus: string | null | undefined, richInputclassName?: string) => {
    if (dataBlurIfHasFocus) {
        if (document.activeElement && document.activeElement.getAttribute('data-blurIfHasFocus') === dataBlurIfHasFocus) {
            document.activeElement.blur();
        }
    }
    if (richInputclassName) {
        if (document.activeElement && document.activeElement.className.includes(richInputclassName)) {
            document.activeElement.blur();
        }
    }
};
