/* eslint-disable no-param-reassign */
import Delta from 'quill-delta';
import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Quill } from 'react-quill-new';

import { useAllHashtags, ProfilesQueryParams } from 'mushin-redux-store';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import QuillAutoLinks from 'quill-auto-links';
// eslint-disable-next-line import/no-unresolved
import 'quill-mention/autoregister';
import assert from 'assert';
import i18n from 'i18next';
import { MentionOption, useMentionsSource } from './modules/mentions';
import ReactQuill, { ReactQuillRef } from './ReactQuill';

Quill.register('modules/autoLinks', QuillAutoLinks);

type Toolbar = (string | string[] | Record<string, any>)[];

type Props = {
    value: Delta;
    setValue: (text: Delta) => void;
    userMentionsQueryParams?: ProfilesQueryParams;
    placeholder?: string;
    onEnter?: (value: Delta) => void;
    onBlur?: () => void;
    toolbar?: Toolbar;
    theme?: 'snow' | 'bubble';
    className?: string;
    readOnly?: boolean;
    needTags?: boolean;
};

const QuillRichInput: React.FC<Props> = ({
    value,
    setValue,
    placeholder,
    userMentionsQueryParams,
    onEnter,
    onBlur,
    toolbar,
    theme = 'bubble',
    className,
    readOnly,
    needTags = true,
}) => {
    const editorRef = useRef<ReactQuillRef>();
    const editor = editorRef.current?.getEditor();

    const [internalToolbar, setInternalToolbar] = useState(toolbar);
    const hashtags = useAllHashtags();
    const mentionsSource = useMentionsSource(hashtags, userMentionsQueryParams);

    useEffect(() => {
        return () => {
            editor?.getModule('mention')?.hideMentionList();
        };
    }, [editor]);

    const enterBindingHandler = useCallback(
        (delta: Delta) => {
            if (onEnter) {
                onEnter(delta);
                return false;
            }
            return true;
        },
        [onEnter],
    );

    try {
        assert.deepStrictEqual(toolbar, internalToolbar);
    } catch (e) {
        setInternalToolbar(toolbar);
    }

    const modules = useMemo(
        () => ({
            keyboard: {
                bindings: {
                    enter: {
                        key: 'Enter',
                        handler() {
                            return enterBindingHandler((this as any).quill.getContents());
                        },
                    },
                },
            },
            mention: {
                allowedChars: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9]*$/,
                positioningStrategy: 'fixed',
                mentionDenotationChars: ['@', '#'],
                renderItem: (item: MentionOption) => {
                    const div = document.createElement('div');
                    div.className = 'mu-rich-input__mention-item';
                    const root = createRoot(div);
                    flushSync(() => {
                        if (item.type === 'user') {
                            root.render(
                                <>
                                    <img
                                        className={`mu-rich-input__mention-${item.avatarUrl ? 'item-avatar' : 'all-icon'}`}
                                        src={`${item.avatarUrl || '/assets/images/Group.svg'}`}
                                        alt={`${item.value}`}
                                    />
                                    {item.id === 'all' ? i18n.t<string>('tooltips.all') : item.value}
                                </>,
                            );
                        } else {
                            root.render(<>{item.value}</>);
                        }
                    });
                    return div;
                },
                source: mentionsSource,
            },
            autoLinks: true,
            toolbar: internalToolbar,
            clipboard: {
                matchers: [
                    // eslint-disable-next-line func-names
                    [
                        Node.ELEMENT_NODE,
                        function (_: any, delta: Delta) {
                            delta.forEach((it) => {
                                if (it.attributes) {
                                    delete it.attributes.color;
                                    delete it.attributes.backgroundColor;
                                    delete it.attributes.background;
                                }
                            });
                            return delta;
                        },
                    ],
                ],
            },
        }),
        [enterBindingHandler, mentionsSource, internalToolbar],
    );

    const onChange = useCallback(
        (a, b, c, e) => {
            setValue(e.getContents());
        },
        [setValue],
    );

    if (!hashtags && needTags) return null;

    return (
        <ReactQuill
            ref={editorRef}
            onKeyDown={(e) => {
                e.stopPropagation();
            }}
            className={`mu-rich-input ${className || ''}`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            theme={theme}
            modules={modules}
            readOnly={readOnly}
        />
    );
};

export default QuillRichInput;
