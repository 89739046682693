import { Cell, Content, ContentMixed } from 'mushin-node-moodboard';

export const split = (cell: Cell, content: ContentMixed): {cell: Cell;content: Content;}[] => {
    const imageCell = { ...cell, max: { ...cell.max }, min: { ...cell.min } };
    const textCell = { ...cell, max: { ...cell.max }, min: { ...cell.min } };

    const s = content.split;

    switch (content.position) {
        case 'top':
            textCell.max.y = imageCell.min.y = cell.max.y * s + cell.min.y * (1 - s);
            break;
        case 'left':
            textCell.max.x = imageCell.min.x = cell.max.x * s + cell.min.x * (1 - s);
            break;
        case 'bottom':
            textCell.min.y = imageCell.max.y = cell.max.y * s + cell.min.y * (1 - s);
            break;
        case 'right':
            textCell.min.x = imageCell.max.x = cell.max.x * s + cell.min.x * (1 - s);
            break;
    }

    return [{
        cell: imageCell,
        content: {
            ...content,
            type: 'image',
        },
    }, {
        cell: textCell,
        content: {
            ...content,
            type: 'text',
        },
    }];
};
