import { Cell, Point } from 'mushin-node-moodboard';

/*
 * prevent the box to be smaller than "min"
 */
export const minBox = (min: number, box: Cell, scalingKernel: Point): Cell => {
    if (scalingKernel.x != 0 && box.max.x - box.min.x < min) {
        if (scalingKernel.x > 0) {
            box = {
                ...box,
                max: { ...box.max, x: box.min.x + min },
            };
        } else {
            box = {
                ...box,
                min: { ...box.min, x: box.max.x - min },
            };
        }
    }
    if (scalingKernel.y != 0 && box.max.y - box.min.y < min) {
        if (scalingKernel.y > 0) {
            box = {
                ...box,
                max: { ...box.max, y: box.min.y + min },
            };
        } else {
            box = {
                ...box,
                min: { ...box.min, y: box.max.y - min },
            };
        }
    }
    return box;
};
