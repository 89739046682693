import moment from 'moment';
import i18n from 'i18next';

export const getDateRangeValue = (minDate: string | undefined, maxDate: string | undefined): string | null => {
    const minDateMoment = minDate ? moment.unix(Number(minDate)) : null;
    const maxDateMoment = maxDate ? moment.unix(Number(maxDate)) : null;

    if (minDateMoment && maxDateMoment) {
        return `${minDateMoment.format('L')} - ${maxDateMoment.format('L')}`;
    }
    if (minDateMoment) {
        return `${i18n.t('components.filters.filtersBar.fromDate')} ${minDateMoment.format('L')}`;
    }
    if (maxDateMoment) {
        return `${i18n.t('components.filters.filtersBar.untilDate')} ${maxDateMoment.format('L')}`;
    }
    return null;
};
