import {getProject, getQuestionnaire, getQuestionnaireAnswers, QuestionItems, Questionnaire} from 'mushin-redux-store';
import ExcelJS from 'exceljs';
import {
    ExcelBuilder,
    ExcelValue,
    formatAsBool,
    formatQuillDeltaToCellRichText,
    formatUserFields,
    getCriteria,
    usersHeaders
} from './excelExports';
import {AsyncAppThunk} from '../../Redux/reducers';
import {getQuestionnaireRespondentIds} from '../questionnaires';
import {getProfileStatus, getRespondentStatus} from '../users';

const findQuestionItemLabel = (question: QuestionItems, val: string) => {
    const item = question.items?.find((_item) => _item.slug === val);
    return item?.label || val;
};

export const questionnaireHeaders = (questionnaire: Questionnaire): string[] => {
    const headers: string[] = [];

    questionnaire.questions.forEach((question) => {
        if (question.type === 'checkboxes' || question.type === 'ranking') {
            question.items?.forEach((item) => {
                const header = formatQuillDeltaToCellRichText(question.label);
                header.richText.push({ text: `\n::\n${item.label}` });
                headers.push(header as any);
            });
        } else if (question.type === 'rating') {
            const header = formatQuillDeltaToCellRichText(question.label);
            header.richText.push({ text: `\nn/${question.maxRatingValue}` });
            headers.push(header as any);
        } else {
            const header = formatQuillDeltaToCellRichText(question.label);
            headers.push(header as any);
        }
    });

    return headers;
};

export const formatQuestionnaireFields = (
    questionnaire: Questionnaire,
    answers: Awaited<ReturnType<typeof getQuestionnaireAnswers>>,
): ExcelValue[] => {
    const fields: ExcelValue[] = [];

    questionnaire.questions.forEach((question) => {
        const questionAnswer = answers[question.id];
        if (question.type === 'free') {
            fields.push(questionAnswer?.notes?.reduce(
                (result, note) => `${result}${note.image_src?.original || note.text}\n`, '',
            ) || '');
        } else if (question.type === 'checkboxes') {
            question.items?.forEach((item) => {
                fields.push(formatAsBool((questionAnswer?.value as string[] | null)?.includes(item.slug)));
            });
        } else if (question.type === 'ranking') {
            question.items?.forEach((item) => {
                const index = (questionAnswer?.value as string[] | null)?.indexOf(item.slug) || -1;
                fields.push(`${index > -1 ? index + 1 : ''}`);
            });
        } else {
            fields.push(findQuestionItemLabel(question as QuestionItems, questionAnswer?.value as string) || '');
        }
    });

    return fields;
};

const populateWorksheetWithQuestionnaireData = async (
    worksheet: ExcelJS.Worksheet,
    questionnaire: Questionnaire,
    project: any,
    setProgress: (progress: number) => void,
    dispatch: any
): Promise<void> => {
    worksheet.mergeCells('A1:M2');
    const questionHeaderCell = worksheet.getCell('A1');
    questionHeaderCell.value = questionnaire.title;
    questionHeaderCell.alignment = { vertical: 'middle', horizontal: 'justify' };
    questionHeaderCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '85B8B2' },
    };
    questionHeaderCell.font = { size: 14, bold: true };

    worksheet.mergeCells('A3:M3');
    const descriptionCell = worksheet.getCell('A3');
    descriptionCell.value = formatQuillDeltaToCellRichText(questionnaire.descriptions.original);
    descriptionCell.alignment = { vertical: 'justify', horizontal: 'justify' };
    descriptionCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '85B8B2' },
    };
    descriptionCell.font = { size: 10, bold: false };

    let progress = 0;
    setProgress(0);
    const progressInterval = 1 / (questionnaire.respondent_count * 3);
    const respondentIds = await dispatch(getQuestionnaireRespondentIds(questionnaire));
    const incrementProgress = () => {
        progress += progressInterval;
        setProgress(progress);
    };

    const criteria = (
        questionnaire.candidacy
            ? [] // We don't want criteria in the export of candidacy questionnaires (as answers of these questionnaires are already used to fill criteria)
            : await dispatch(getCriteria(respondentIds, incrementProgress))
    );

    const headerRow = worksheet.addRow([...usersHeaders(criteria, true), ...questionnaireHeaders(questionnaire)]);
    headerRow.eachCell((cell) => {
        const tempCell = cell;
        tempCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'EEEEEE' },
        };
        tempCell.border = {
            top: { style: 'double', color: { argb: '879399' } },
            left: { style: 'double', color: { argb: '879399' } },
            bottom: { style: 'double', color: { argb: '879399' } },
            right: { style: 'double', color: { argb: '879399' } },
        };
    });
    headerRow.alignment = { horizontal: 'center', wrapText: true };
    worksheet.columns.forEach((column) => {
        const tempColumn = column;
        tempColumn.width = 20;
    });

    for (const respondent of respondentIds) {
        // eslint-disable-next-line no-await-in-loop
        const answers = await getQuestionnaireAnswers(questionnaire.id, respondent);

        worksheet.addRow([
            // eslint-disable-next-line no-await-in-loop
            ...(await dispatch(formatUserFields(
                respondent,
                criteria,
                (user) => getRespondentStatus(user, questionnaire, project),
                (user) => getProfileStatus(user),
            ))),
            ...formatQuestionnaireFields(questionnaire, answers)
        ]);
        incrementProgress();
    }

    setProgress(1);
};

export const questionnaireStatsExport = (
    questionnaireId: string,
    setProgress: (progress: number) => void
): AsyncAppThunk => async (dispatch): Promise<void> => {
    const questionnaire = await dispatch(getQuestionnaire(questionnaireId));
    const project = questionnaire?.project_id ? await dispatch(getProject(questionnaire.project_id)) : undefined;
    if (questionnaire) {
        const excelBuilder = new ExcelBuilder(
            questionnaire.title.replace(/[^a-z0-9]/gi, '_'),
            'Questionnaires stats',
        );

        const worksheet = excelBuilder.ws;
        if (worksheet) {
            await populateWorksheetWithQuestionnaireData(worksheet, questionnaire, project, setProgress, dispatch);
        }

        await excelBuilder.export();
    }
};

export const addQuestionnaireToWorkbook = async (
    workbook: ExcelJS.Workbook,
    questionnaireId: string,
    setProgress: (progress: number) => void,
    dispatch: any
): Promise<void> => {
    const questionnaire = await dispatch(getQuestionnaire(questionnaireId));
    const project = questionnaire?.project_id ? await dispatch(getProject(questionnaire.project_id)) : undefined;
    if (questionnaire) {
        const worksheet = workbook.addWorksheet(questionnaire.title.replace(/[^a-z0-9]/gi, '_'));
        await populateWorksheetWithQuestionnaireData(worksheet, questionnaire, project, setProgress, dispatch);
    }
};
