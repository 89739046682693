import React, {ReactNode} from 'react';
import MuIcon from '../MuIcon';
import {MuSvg} from '../MuSvg';
import {SvgrComponent} from '../../Types/common';

type Props = {
    icon?: SvgrComponent | string;
    subtitle?: ReactNode | string;
    title: ReactNode | string;
};
export const ModalHeader: React.FC<Props> = ({
    icon,
    title,
    subtitle,
}) => (
    <header className="mu-modal__header">
        {icon && (
            <div className="mu-modal__icon">
                {typeof icon === 'string' ? <MuIcon svgName={icon} />
                    : <MuSvg SvgComp={icon} />}
            </div>
        )}
        <div className="mu-modal__header-content">
            <h2 className="mu-modal__header-title">{title}</h2>
            {subtitle && <h3 className="mu-modal__header-subtitle">{subtitle}</h3>}
        </div>
    </header>
);
