import React from 'react';
import {classWithModifiers, Modifiers} from '../../Helpers/css';
import Spinner from '../Spinner/Spinner';

type Props = React.PropsWithChildren<{
    classModifiers?: Modifiers;
    className?: string;
    loading?: boolean;
}>;
export const ModalFooter: React.FC<Props> = ({
    children,
    classModifiers,
    className,
    loading,
}) => (
    <footer className={classWithModifiers('mu-modal-footer', classModifiers, className)}>
        {loading ? <Spinner size="25px" /> : children}
    </footer>
);
