import { Handler } from './type';

import { hitBoxes } from '../layoutUtils';

export const extractWhiteColCells = (templateContents) => {
    const idsToRemove = [];
    for (const [key, value] of Object.entries(templateContents)) {
        if (value && value.type === 'whiteCol') {
            idsToRemove.push(key);
        }
    }
    return idsToRemove;
};

export const onDrop: Handler = ({
    state,
    board,
    draggedElement,
    actions,
    templateContents,
}, pointers, event) => {
    const whiteCols = extractWhiteColCells(templateContents);

    const hit = pointers[0] && hitBoxes(board.ids.filter((id) => !whiteCols.includes(id)).map((id) => board.cells[id]), pointers[0]);

    const id = Object.keys(board.cells).find((id) => board.cells[id] === hit);

    if (id && draggedElement) {
        actions.addElementInCell(draggedElement.note, id);
    }
    event && event.preventDefault();
};

export const onDragOver: Handler = ({
    state,
    board,
    draggedElement,
}, pointers, event) => {
    if (!draggedElement) return;

    const hit = pointers[0] && hitBoxes(board.ids.map((id) => board.cells[id]), pointers[0]);

    if (hit && event) event.preventDefault();
};
