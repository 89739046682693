import React from 'react';
import i18n from 'i18next';
import {Questionnaire} from 'mushin-redux-store';

type Props = {
    questionnaire: Questionnaire;
}

const QuestionnairePublicLink: React.FC<Props> = ({questionnaire}) => {
    if (questionnaire.accessType !== 'public') return null;

    return (
        <p>
            {i18n.t('surveys.shareLink')}
            {' : '}
            <a
                className="mu-link"
                target="_blank"
                rel="noopener noreferrer"
                href={`${window.location.origin}/questionnaires/${questionnaire.id}`}
            >
                {`${window.location.origin}/questionnaires/${questionnaire.id}`}
            </a>
        </p>
    );
};

export default QuestionnairePublicLink;
