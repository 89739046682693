import React from 'react';
import { useParams } from 'react-router-dom';
import {useQuestionnaire} from 'mushin-redux-store';

import QuestionnaireAppLayout from './Components/QuestionnaireAppLayout';
import QuestionnaireEditor from './QuestionnaireEditor';
import QuestionnaireEditorProvider from './Components/QuestionnaireEditorContext';

const QuestionnaireEdition: React.FC = () => {
    const { questionnaireId } = useParams<{ questionnaireId: string }>();
    const questionnaire = useQuestionnaire(questionnaireId);

    return (
        <QuestionnaireAppLayout questionnaire={questionnaire}>
            <QuestionnaireEditorProvider>
                <div className="mu-app-layout__scroll">
                    {questionnaire && (<QuestionnaireEditor questionnaire={questionnaire} />)}
                </div>
            </QuestionnaireEditorProvider>
        </QuestionnaireAppLayout>
    );
};

export default QuestionnaireEdition;
