import { Cell, Point } from 'mushin-node-moodboard';
import { Handler } from './type';

import { hitBox, setContent } from '../layoutUtils';

const computeSplit = (cell: Cell, position: 'top' | 'left' | 'right' | 'bottom', p: Point): number => {
    switch (position) {
        case 'top':
            return (p.y - cell.min.y) / (cell.max.y - cell.min.y);
        case 'left':
            return (p.x - cell.min.x) / (cell.max.x - cell.min.x);
        case 'right':
            return (p.x - cell.min.x) / (cell.max.x - cell.min.x);
        case 'bottom':
            return (p.y - cell.min.y) / (cell.max.y - cell.min.y);
    }
};

export const onTouchDown: Handler = ({
    state,
    board,
    selectedCell,
}, pointers) => {
    if (state.dragging) return;

    if (!pointers[0]) return;

    const content = board.contents[selectedCell];
    const cell = board.cells[selectedCell];

    if (!content || content.type !== 'mixed' || !hitBox(cell, pointers[0])) { return; }

    const split = computeSplit(cell, content.position, pointers[0]);

    if (Math.abs(split - content.split) < 0.1) {
        return {
            state: {
                ...state,
                dragging: 'split',
            },
        };
    }
};

export const onTouchMove: Handler = ({
    state,
    board,
    selectedCell,
}, pointers) => {
    if (state.dragging !== 'split') return;

    if (!pointers[0]) return;

    const dx = pointers[0].x - state.drag.originPoint.x;

    const content = board.contents[selectedCell];
    const cell = board.cells[selectedCell];

    const split = computeSplit(cell, content.position, pointers[0]);

    const newContent = {
        ...content,
        split: Math.min(1, Math.max(0, split)),
    };

    return { board: setContent(board, selectedCell, newContent) };
};

export const onTouchUp: Handler = ({
    state,
    selectedCell,
    board,
}) => {
    if (state.dragging !== 'split') return;

    const content = board.contents[selectedCell];

    // defensive check
    // if a more prior handler had change the selectedCell ( which should not happend )
    if (!content || content.type !== 'mixed') {
        return { state: {
            ...state,
            dragging: null,
        },
        };
    }

    if ((content.split > 0.95 && (content.position === 'bottom' || content.position === 'right')) || (content.split < 0.05 && (content.position === 'left' || content.position === 'top'))) {
        const newContent = {
            ...content,
            type: 'image',
        };

        return {
            board: setContent(board, selectedCell, newContent),
            state: {
                ...state,
                dragging: null,
            },
        };
    }

    return {
        state: {
            ...state,
            dragging: null,
        },
    };
};
