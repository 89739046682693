import React from 'react';
import i18n from 'i18next';
import { injectDraggedElement } from '../../Bucket/DragAndDropComponents/elementDragStore';
import AddNotesPanelItem from '../AddNotesPanel/AddNotesPanelItem';
import {useCurrentPageSelector} from '../../../Redux/reducers/moodboardEditorSlice';

type Props = {
    startDragElement: (event: React.DragEvent<HTMLDivElement>, data: {note: any}) => void;
}

const CurrentContent: React.FC<Props> = ({
    startDragElement,
}) => {
    const notePool = useCurrentPageSelector((page) => page.boardData.notePool);
    const notesInBoard = useCurrentPageSelector((page) => {
        const {
            boardData,
            board,
        } = page;

        if (!board || !boardData) return [];

        return boardData.notePool.filter(({
            id,
        }) => Object.keys(board.contents).some((key) => board.contents[key]?.note_id === id));
    });

    const notesOutBoard = notePool.filter(({id}) => !notesInBoard.some((b) => id === b.id));

    return (
        <div id="note-explorer">
            <div className="page">
                <div className="page-content" style={{ overflowY: 'scroll' }}>
                    <div className="beTemplateSelector-label">
                        {i18n.t('components.boardEditor.currentContent')}
                    </div>
                    <div className="mu-add-notes-panel__items">
                        {notesInBoard.map((note) => (
                            <AddNotesPanelItem
                                key={note.id}
                                noteId={note.id}
                                startDragElement={(event) => startDragElement(event, { note })}
                            />
                        ))}
                    </div>
                    {notesOutBoard.length > 0 && (
                        <>
                            <div className="beTemplateList-groupSeparator" />
                            <div className="beTemplateSelector-label">
                                {i18n.t('components.boardEditor.unused_images')}
                            </div>
                            <div className="mu-add-notes-panel__items">
                                {notesOutBoard.map((note) => (
                                    <AddNotesPanelItem
                                        key={note.id}
                                        noteId={note.id}
                                        startDragElement={(event) => startDragElement(event, { note })}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                </div>
            </div>
        </div>
    );
};

export default injectDraggedElement(CurrentContent);
