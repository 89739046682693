import React from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {useCurrentOrganizationId, useCurrentProjectId} from 'mushin-redux-store';
import PrivateRoute from './PrivateRoute';
import ProjectSelector from '../Scenes/ProjectSelector/ProjectSelector';
import ProjectRoutes from './ProjectRoutes';
import OrganizationRoutes from './OrganizationRoutes';
import NotificationsSettings from '../Scenes/Settings/NotificationsSettings/NotificationsSettings';
import Account from '../Scenes/Settings/Account/Account';
import RedirectWildCard from './RedirectWildCard';

/*
Routes with a sidebar
 */

/*
Mushin Home
 */

export const MushinRoutes: React.FC = () => {
    const [currentOrganizationId] = useCurrentOrganizationId();
    const [currentProjectId] = useCurrentProjectId();

    return (
        <Routes>
            <Route path="/account/notifications" element={<PrivateRoute showOnMobile><NotificationsSettings /></PrivateRoute>} />
            <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
            <Route path="/projectSelector" element={<PrivateRoute><ProjectSelector /></PrivateRoute>} />
            <Route
                path="/projects/current/*"
                element={<RedirectWildCard to={`/projects/${currentProjectId}`} />}
            />
            <Route path="/projects/:projectId/*" element={<PrivateRoute><ProjectRoutes /></PrivateRoute>} />
            <Route
                path="/organizations/current/*"
                element={<RedirectWildCard to={`/organizations/${currentOrganizationId}`} />}
            />
            <Route path="/organizations/:organizationId/*" element={<PrivateRoute><OrganizationRoutes /></PrivateRoute>} />
            <Route
                path="/"
                element={(
                    <Navigate
                        to={
                            (currentOrganizationId && currentProjectId)
                                ? `/projects/${currentProjectId}`
                                : '/projectSelector'
                        }
                    />
                )}
            />
        </Routes>
    );
};

export default MushinRoutes;
