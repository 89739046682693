import React from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import { useCurrentOrganizationId, useOrganization } from 'mushin-redux-store';
import InspirationsGallery from '../Scenes/Gallery/InspirationsGallery';
import PrivateRoute from './PrivateRoute';
import CompanyDetails from '../Scenes/Settings/CompanyDetails/CompanyDetails';
import CompanyMembers from '../Scenes/Settings/CompanyMembers/CompanyMembers';
import CompanyGroups from '../Scenes/Settings/CompanyGroups/CompanyGroups';
import {BoardEditor} from '../Scenes/BoardEditor';
import AppLoader from './AppLoader';

/*
Routes with a sidebar
 */

/*
Mushin Home
 */

export const OrganizationRoutes: React.FC = () => {
    const { organizationId } = useParams<{organizationId: string}>();
    const [currentOrganizationId, setCurrentOrganizationId] = useCurrentOrganizationId();
    const organization = useOrganization(currentOrganizationId || undefined);

    if (organizationId && organizationId !== 'null' && currentOrganizationId !== organizationId) {
        setCurrentOrganizationId(organizationId);
        return <AppLoader />;
    }

    if (!organization && (currentOrganizationId === null || (currentOrganizationId && organization === null))) {
        return (
            <Route path="*" element={<Navigate to="/projectSelector" replace />} />
        );
    }

    return (
        <Routes>
            <Route path="members" element={<PrivateRoute><CompanyMembers /></PrivateRoute>} />
            <Route path="groups" element={<PrivateRoute><CompanyGroups /></PrivateRoute>} />
            <Route path="galleries/:inspirationType/note/:note_id" element={<PrivateRoute><InspirationsGallery /></PrivateRoute>} />
            <Route path="galleries/:inspirationType" element={<PrivateRoute><InspirationsGallery /></PrivateRoute>} />
            <Route path="board/:board_id" element={<PrivateRoute><BoardEditor /></PrivateRoute>} />
            <Route path="*" element={<PrivateRoute><CompanyDetails /></PrivateRoute>} />
            <Route path="galleries" element={<Navigate to="galleries/all" />} />
        </Routes>
    );
};

export default OrganizationRoutes;
