import React, {useMemo, useState} from 'react';
import {
    useChatMessagesQuery, useCriterion,
    useCurrentProjectId,
    useProfile, useNotesQuery, useQuestionnairesQuery
} from 'mushin-redux-store';

import i18n from 'i18next';
import Modal from '../../../../Components/Modal';

import MessageGridItem from '../../../../Components/MessageGridItem/MessageGridItem';
import {useScrollThreshold} from '../../../../Helpers/effects';
import EmptyZone from '../../../../Components/EmptyZone/EmptyZone';
import Spinner from '../../../../Components/Spinner';
import ContributorDetailsNotesGrid from './ContributorDetailsNotesGrid';
import {useAppDispatch} from '../../../../Helpers/hooks';
import {openModal} from '../../../../Redux/reducers/modalsSlice';
import ProfileCard from '../../../../Components/UserCard/ProfileCard';
import UserCriteria from '../../../../Components/UserCriteria/UserCriteria';
import ContributorDetailsMissionItem from './ContributorDetailsMissionItem';
import {classWithModifiers} from '../../../../Helpers/css';
import CriteriaFilterSelection from '../../../QuestionnaireEditor/Components/CriteriaFilterSelection';
import CriteriaEditModalValueSelect from '../CriteriaModal/CriteriaEditModalValueSelect';

type Props = {
    profileId: string;
    onClose: () => void;
};

const ContributorDetailsModal: React.FC<Props> = ({
    profileId,
    onClose,
}) => {
    const [currentTab, setCurrentTab] = useState<'ideas' | 'messages' | 'missions'>('ideas');
    const [currentProjectId] = useCurrentProjectId();
    const profile = useProfile(profileId);
    const notesQuery = useNotesQuery({project_id: currentProjectId, profiles: [profileId]});
    const notesRef = useScrollThreshold<HTMLDivElement>(
        notesQuery.getMoreResults, { deps: [notesQuery.results?.length] },
    );
    const messagesQuery = useChatMessagesQuery({profile_id: profileId, project_id: currentProjectId});
    const messagesListRef = useScrollThreshold<HTMLDivElement>(
        messagesQuery.getMoreResults, { deps: [messagesQuery.results?.length] }
    );

    const questionnairesQuery = useQuestionnairesQuery({ project_id: currentProjectId, profile_id: profileId });
    const questionnairesListRef = useScrollThreshold<HTMLDivElement>(
        questionnairesQuery.getMoreResults, { deps: [questionnairesQuery.results?.length] }
    );

    const dispatch = useAppDispatch();

    const openNoteView = (noteId: string) => {
        dispatch(openModal('ContributorDetailsImageView', {
            noteId,
            query: notesQuery,
        }));
    };
    
    const userMentionsQueryParams = useMemo(
        () => ({
            confirmed: true,
            ...(currentProjectId ? {project_id: currentProjectId} : {}),
        }),
        [currentProjectId]
    );

    const [newCriterionId, setNewCriterionId] = useState<string | undefined>(undefined);
    const newCriterion = useCriterion(newCriterionId);

    if (!profile) return null;

    const stats = profile.metrics;
    const totalMissions = questionnairesQuery.pager?.total_items || 0;
    const totalMissionsDone = questionnairesQuery.total_done;

    return (
        <Modal
            classModifiers={['extra-large', 'contributor']}
            handleRemoveModal={onClose}
        >
            <div className="mu-contributor-modal-infos">
                <ProfileCard id={profile.id} size="large" />
                <div className="mu-contributor-modal-infos__stats">
                    <button
                        type="button"
                        className={classWithModifiers(
                            'mu-contributor-modal-infos__stat-title',
                            {active: currentTab === 'ideas'},
                        )}
                        onClick={() => setCurrentTab('ideas')}
                    >
                        {i18n.t('album.ideas.0')}
                    </button>
                    <div className="mu-contributor-modal-infos__stat-value">{stats?.notes?.volume || 0}</div>
                    <button
                        type="button"
                        className={classWithModifiers(
                            'mu-contributor-modal-infos__stat-title',
                            {active: currentTab === 'messages'},
                        )}
                        onClick={() => setCurrentTab('messages')}
                    >
                        {i18n.t('global.messages')}
                    </button>
                    <div className="mu-contributor-modal-infos__stat-value">
                        {(stats?.chat?.message_volume || 0) + (stats?.chat?.comment_volume || 0)}
                    </div>
                    {!questionnairesQuery.loading
                        && (
                            <>
                                <button
                                    type="button"
                                    className={classWithModifiers(
                                        'mu-contributor-modal-infos__stat-title',
                                        {active: currentTab === 'missions'},
                                    )}
                                    onClick={() => setCurrentTab('missions')}
                                >
                                    {i18n.t('global.missions')}
                                </button>
                                <div className="mu-contributor-modal-infos__stat-value">
                                    {`${totalMissionsDone} / ${totalMissions}`}
                                </div>
                            </>
                        )}
                </div>
                <div className="mu-contributor-modal-infos__groups">
                    <UserCriteria profile={profile} contrast>
                        {newCriterion && (
                            <li className="mu-tags__tag">
                                <CriteriaEditModalValueSelect
                                    criterion={newCriterion}
                                    profileId={profileId}
                                    isIconButton={false}
                                    onClose={() => setNewCriterionId(undefined)}
                                />
                            </li>
                        )}
                        <CriteriaFilterSelection
                            onSelectCriterion={setNewCriterionId}
                            toggleClassName="mu-tags__show-all mu-tags__show-all--text"
                            toggleLabel={`+ ${i18n.t('criteria.add')}`}
                            disabled={false}
                        />
                    </UserCriteria>
                </div>
            </div>
            {currentTab === 'ideas' && (
                <div ref={notesRef} className="mu-contributor-modal__panel mu-contributor-modal-ideas">
                    <h3 className="mu-contributor-modal__panel-title">
                        {i18n.t('album.ideas.0')}
                    </h3>
                    <div className="mu-contributor-modal__list-ideas">
                        {notesQuery.results && (
                            notesQuery.results?.length ? (
                                <ContributorDetailsNotesGrid
                                    noteIds={notesQuery.results}
                                    setOpenNoteId={openNoteView}
                                />
                            ) : !notesQuery.loading && (
                                <EmptyZone />
                            )
                        )}
                    </div>
                    {notesQuery.loading && (
                        <Spinner size="50px" />
                    )}
                </div>
            )}
            {currentTab === 'messages' && (
                <div ref={messagesListRef} className="mu-contributor-modal__panel mu-contributor-modal-messages">
                    <h3 className="mu-contributor-modal__panel-title">
                        {i18n.t('global.messages')}
                    </h3>
                    {messagesQuery?.results?.length ? messagesQuery.results.map((messageId) => (
                        <MessageGridItem
                            key={messageId}
                            messageId={messageId}
                            onClick={onClose}
                            userMentionsQueryParams={userMentionsQueryParams}
                        />
                    )) : i18n.t('modals.contributorDetailsModal.noMessages')}
                </div>
            )}
            {currentTab === 'missions' && (
                <div ref={questionnairesListRef} className="mu-contributor-modal__panel mu-contributor-modal-missions">
                    <h3 className="mu-contributor-modal__panel-title">
                        {i18n.t('missions.list')}
                    </h3>
                    <div className="mu-contributor-modal-missions__list">
                        {questionnairesQuery?.results?.length ? questionnairesQuery.results.map((questionnaireId) => (
                            <ContributorDetailsMissionItem
                                key={questionnaireId}
                                id={questionnaireId}
                                profile={profile}
                            />
                        )) : i18n.t('missions.noMissions')}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ContributorDetailsModal;
