import * as React from 'react';
import {classWithModifiers} from '../../Helpers/css';
import MuIcon from '../MuIcon';

type Props = {
    type: 'hashtag' | 'group';
    isSelected: boolean;
    label: React.ReactNode;
    onClick?: () => void;
    onRemove?: () => void;
    contrast?: boolean;
};
const Tag: React.FC<Props> = ({
    type,
    isSelected,
    label,
    onClick,
    onRemove,
    contrast = false,
}) => (
    <button
        type="button"
        className={classWithModifiers('mu-tag', {
            active: isSelected,
            [type]: true,
            contrast,
        })}
        onClick={onClick}
    >
        <span className="mu-tag__text">{label}</span>
        {isSelected && onRemove && (
            <span className="mu-tag__icon" onClick={onRemove}>
                <MuIcon svgName="CloseCircleFill" />
            </span>
        )}
    </button>
);

export default Tag;
