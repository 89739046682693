import React, {useState} from 'react';
import {useAllHashtags} from 'mushin-redux-store';
import i18n from 'i18next';
import {useInputWithDelay} from '../../Helpers/FormsHelpers/hooks';
import InputText from '../InputText/InputText';
import {Aggregation, mergeTags, searchTagList} from '../../Helpers/noteHelpers';
import Tags from '../Tags/Tags';
import {SetSearchParams} from '../../Helpers/queryParser';
import {NoteSearchParams} from '../GalleryToolbar/GalleryToolbar';
import Tag from '../Tags/Tag';
import {haveSameElements} from '../../Helpers/array';
import Client from '../../Services/Client';

type Props = {
    searchParams: NoteSearchParams,
    setSearchParams: SetSearchParams<NoteSearchParams>,
    aggregations: Aggregation[];
}

const MainSearch: React.FC<Props> = (
    {searchParams, setSearchParams, aggregations}
) => {
    const [isOpen, setOpen] = useState(false);
    const [currentQuery, setCurrentQuery] = useInputWithDelay(
        (query) => setSearchParams({query}, true),
        searchParams.query || '',
    );
    const allHashtags = useAllHashtags();

    const tags = searchTagList(currentQuery, mergeTags(aggregations, allHashtags, {selected: searchParams.hashtags}));

    const onTagClick = (tag: string): void => {
        setCurrentQuery('', true);
        setSearchParams({hashtags: [...(searchParams.hashtags || []), tag]}, true);
    };
    const onTagRemove = (tag: string): void => {
        setCurrentQuery('', true);
        const newTags = searchParams.hashtags ? [...searchParams.hashtags] : [];
        const tagIndex = newTags.indexOf(tag);
        if (tagIndex > -1) {
            newTags.splice(tagIndex, 1);
        }
        setSearchParams({hashtags: newTags}, true);
    };

    return (
        <div className="mu-toolbar__search">
            <InputText
                classModifiers={['full-width']}
                leftIcon="Search"
                value={currentQuery}
                onChange={(e) => setCurrentQuery(e.target.value)}
                placeholder={i18n.t('components.filters.search')}
                onFocus={() => setOpen(true)}
                onBlur={() => setTimeout(() => setOpen(false), 200)}
            />
            {isOpen && (
                <div className="mu-toolbar__search-results">
                    <h4 className="mu-toolbar__search-results-title">Tags</h4>
                    <Tags
                        tags={tags.slice(0, 10)}
                        selectedTags={searchParams.hashtags}
                        onTagClick={(tag, active) => (active ? onTagRemove(tag) : onTagClick(tag))}
                        contrast
                    />
                    <h4 className="mu-toolbar__search-results-title">{i18n.t('components.filters.quick')}</h4>
                    <ul className="mu-tags">
                        <li className="mu-tags__tag">
                            <Tag
                                type="group"
                                isSelected={haveSameElements([Client.getId()], searchParams.favorite_of_users)}
                                label={i18n.t('global.myFavorites')}
                                onClick={() => setSearchParams(
                                    {is_favorite: searchParams.is_favorite ? undefined : 'true'}, true,
                                )}
                                contrast
                            />
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MainSearch;
