import React from 'react';
import {useCurrentOrganizationId, useNotesQuery} from 'mushin-redux-store';
import i18n from 'i18next';
import AddNotesPanelItem from './AddNotesPanelItem';
import {useScrollThreshold} from '../../../Helpers/effects';
import {injectDraggedElement} from '../../Bucket/DragAndDropComponents/elementDragStore';
import AlbumsSelector, {buildNoteQueryParams} from '../../AlbumsSelector/AlbumsSelector';

type Props = {
    startDragElement: (event: React.DragEvent<HTMLDivElement>, data: {note: any}) => void;
}

const AddNotesPanel: React.FC<Props> = ({ startDragElement }) => {
    const [currentOrganizationId] = useCurrentOrganizationId();
    const notesQuery = useNotesQuery(buildNoteQueryParams(currentOrganizationId, 'all'), true);

    const ref = useScrollThreshold<HTMLDivElement>(
        notesQuery.getMoreResults,
        { deps: [notesQuery.results?.length] }
    );

    return (
        <div className="mu-add-notes-panel">
            <div className="beTemplateSelector-label">
                {i18n.t('components.boardEditor.explorer')}
            </div>
            <div className="mu-add-notes-panel__header">
                <AlbumsSelector
                    onSelect={(type, id) => {
                        notesQuery.setParams(buildNoteQueryParams(currentOrganizationId, type, id));
                    }}
                    horizontal
                />
            </div>
            <div ref={ref} className="mu-add-notes-panel__items">
                {notesQuery.results?.map((noteId) => (
                    <AddNotesPanelItem key={noteId} noteId={noteId} startDragElement={startDragElement} />
                ))}
            </div>
        </div>
    );
};

export default injectDraggedElement(AddNotesPanel);
