import React, {useMemo, useRef, useState} from 'react';
import i18n from 'i18next';
import {classWithModifiers} from '../../Helpers/css';
import MuIcon from '../MuIcon';
import {DOM_DATA_ATT_add_tag} from './Tags';

type AddTagProps = {
    hashtags: string[];
    handleAddTag: (tag: string) => void;
    placeholder?: string;
};

const specialCharactersRegex = new RegExp(/[.*+?^${}()|[\]\\]/);

const AddTag: React.FC<AddTagProps> = ({hashtags, handleAddTag, placeholder}) => {
    const [isActive, setActive] = useState(false);
    const [selectedTag, setSelectedTag] = useState<number>(-1);
    const [tagToAdd, setTagToAdd] = useState('');

    const selectedTagRef = useRef<HTMLButtonElement>(null);

    const hashtagsList = useMemo(() => {
        const tags = [...hashtags].sort();
        return tagToAdd ? tags.filter((hashtag) => hashtag.startsWith(tagToAdd)) : tags;
    }, [hashtags, tagToAdd]);

    const validate = (value: string): void => {
        const selectedValue = selectedTag > -1 ? hashtagsList[selectedTag] : value;
        if (selectedValue.match(specialCharactersRegex)) return;

        handleAddTag(selectedValue.replace(/\s\s+/g, ' ').toLowerCase().trim().replace(/( )+/g, '-'));
        setTagToAdd('');
        setSelectedTag(-1);
    };

    const onBlur = (): void => {
        // Set a timeout otherwise the dropdown component is destroyed before the onClick is triggered
        setTimeout(() => setActive(false), 200);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value.toLowerCase();
        setTagToAdd(value);
        setSelectedTag(-1);
    };

    const scrollToSelected = () => setTimeout(() => selectedTagRef.current?.scrollIntoView(false), 50);

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        e.stopPropagation();
        if (e.key === 'Enter' || e.key === 'Tab') {
            if (selectedTag > -1 || e.currentTarget.value !== '') {
                e.preventDefault();
                validate(e.currentTarget.value);
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedTag((prevIndex) => (prevIndex >= 0 ? prevIndex - 1 : hashtagsList.length - 1));
            scrollToSelected();
        } else if (e.key === 'ArrowDown') {
            setSelectedTag((prevIndex) => (prevIndex < hashtagsList.length - 1 ? prevIndex + 1 : -1));
            scrollToSelected();
        }
    };

    const onFocus = () => {
        setActive(true);
    };

    const onSuggestClick = (hashtag: string) => {
        validate(hashtag);
    };

    /* eslint-disable jsx-a11y/interactive-supports-focus */
    return (
        <>
            <li key="new-tag" className="mu-tags__tag">
                <div
                    className={classWithModifiers(
                        'mu-tag', { create: true, focused: isActive, error: !!tagToAdd.match(specialCharactersRegex)}
                    )}
                >
                    <input
                        data-blurifhasfocus={DOM_DATA_ATT_add_tag}
                        className="mu-tag__content-editable mu-tag__text"
                        value={tagToAdd}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onChange={onChange}
                        placeholder={placeholder || i18n.t<string>('notes.add_tag')}
                    />

                    <button
                        type="button"
                        className={classWithModifiers('mu-tag__icon', { disabled: !tagToAdd })}
                        disabled={!tagToAdd}
                        onClick={() => validate(tagToAdd)}
                    >
                        <MuIcon svgName="PlusCircleFill" />
                    </button>
                </div>

                {hashtagsList.length > 0 && isActive && (
                    <div className="mu-tag-suggest">
                        {hashtagsList.map((hashtag, index) => {
                            return (
                                <button
                                    key={hashtag}
                                    ref={
                                        (index === selectedTag || (selectedTag < 0 && index === 0))
                                            ? selectedTagRef
                                            : null
                                    }
                                    type="button"
                                    className={classWithModifiers(
                                        'mu-tag-suggest__item', {selected: index === selectedTag},
                                    )}
                                    onFocus={() => {
                                        setSelectedTag(index);
                                    }}
                                    onMouseOver={() => setSelectedTag(index)}
                                    onClick={() => onSuggestClick(hashtag)}
                                >
                                    {hashtag}
                                </button>
                            );
                        })}
                    </div>
                )}
            </li>
        </>
    );
};

export default AddTag;
