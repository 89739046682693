import {useNavigate, useLocation} from 'react-router-dom';
import {SetStateAction, useCallback, useEffect, useState} from 'react';
import {Search} from 'history';

type SearchParams = {
    [key: string]: string | number | string[]
}
type SearchParamsKeys<Params extends SearchParams> = {
    [K in keyof Params]?: 'number' | 'string' | 'array'
}

const parseSearchParams = <Params extends SearchParams>(
    search: Search, keysType: SearchParamsKeys<Params> = {},
): Params => {
    const params: SearchParams = {};
    const urlParams = new URLSearchParams(search);
    for (const key of urlParams.keys()) {
        switch (keysType[key]) {
            case 'array':
                params[key] = urlParams.getAll(key);
                break;
            case 'number':
                params[key] = Number(urlParams.get(key));
                break;
            default:
                params[key] = urlParams.get(key) as string;
        }
    }

    return params as Params;
};

export const buildSearchParams = <Params extends SearchParams>(
    params: Params,
): string => {
    const searchQuery = new URLSearchParams();
    Object.keys(params).forEach((key) => {
        if (params[key]) {
            const param = params[key];
            if (typeof param === 'number') {
                searchQuery.append(key, `${param}`);
            } else if (Array.isArray(param)) {
                param.forEach((val: string) => searchQuery.append(key, val));
            } else {
                searchQuery.append(key, param);
            }
        }
    });
    return searchQuery.toString();
};

export type SetSearchParams<Params extends SearchParams> = (
    nextParams: SetStateAction<Params>, override?: boolean,
) => void

export const useSearchParams = <Params extends SearchParams>(keysType: SearchParamsKeys<Params> = {}): [
    Params,
    SetSearchParams<Params>
] => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useState({
        search: location.search,
        params: parseSearchParams(location.search, keysType),
    });

    useEffect(() => {
        setSearchParams((prevState) => {
            if (prevState.search === location.search) return prevState;
            return {
                search: location.search,
                params: parseSearchParams(location.search, keysType),
            };
        });
    }, [location.search]);

    const changeSearch: SetSearchParams<Params> = useCallback((nextParams, override = false) => {
        setSearchParams((prevState) => {
            let params: Params;
            if (typeof nextParams === 'function') {
                params = nextParams(prevState.params);
            } else {
                params = override ? {...prevState.params, ...nextParams} : nextParams;
            }
            const searchQuery = buildSearchParams(params);
            const search = searchQuery ? `?${searchQuery}` : '';
            navigate({
                ...location,
                search,
            });
            return {
                search,
                params,
            };
        });
    }, [navigate, location]);

    return [searchParams.params, changeSearch];
};
