import { Cell, Point } from 'mushin-node-moodboard';

/*
 * given a container and an image dimension,
 * if the scale is small enougth ( less than minScale ), the cropBox will be left unchanged
 * compute the value minScale
 */
export const computeMinScale = (imageDimension: Point, cell: Cell) => {
    const mCell = Math.min(cell.max.x - cell.min.x, cell.max.y - cell.min.y);
    const mImg = Math.min(imageDimension.x, imageDimension.y);

    const r = mCell / mImg;

    return Math.min(imageDimension.x * r / (cell.max.x - cell.min.x), imageDimension.y * r / (cell.max.y - cell.min.y));
};

/*
 * return the next scale
 */
export const getNextScale = (scale: number, direction: 1 | 1) => Math.min(1, Math.max(0.2, (Math.floor(100 * scale) + direction * 4) / 100));
