import React from 'react';
import { classWithModifiers } from '../../Helpers/css';

type AlertProps = React.PropsWithChildren<{
  label: string;
  classModifiers?: Array<string>;
}>;

type AlertActionProps = {
  handleAction: Function;
  labelAction: string;
};

const Action = ({
    handleAction,
    labelAction,
}: AlertActionProps) => (
    <li className="mu-alert__action">
        <button type="button" onClick={handleAction} className="mu-alert__btn mu-link">{labelAction}</button>
    </li>
);

const Alert: React.FC<AlertProps> = ({
    label,
    children,
    classModifiers,
}) => (
    <article className={classWithModifiers('mu-alert', [...(classModifiers || '')])}>
        <span className="mu-alert__label">{label}</span>
        <ul className="mu-alert__list-actions">
            {children}
        </ul>
    </article>
);

Alert.defaultProps = {
    classModifiers: [''],
};

Alert.Action = Action;

export default Alert;
