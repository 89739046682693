import React, { useState } from 'react';

import { Project, updateProject, deleteProject } from 'mushin-redux-store';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import TextIcon from '../../../../Components/TextIcon/TextIcon';
import { classWithModifiers } from '../../../../Helpers/css';
import MuIcon from '../../../../Components/MuIcon';
import { useAppDispatch } from '../../../../Helpers/hooks';
import DropdownMenu from '../../../../Components/DropdownMenu/DropdownMenu';
import { projectMetricsExport, projectQuestionnairesExport } from '../../../../Helpers/excel/metricsExport';
import MenuProgressAction from '../../../../Components/MenuProgressAction/MenuProgressAction';
import { openModal, openAlert } from '../../../../Redux/reducers/modalsSlice';
import { getStatus } from '../../../../Helpers/date';

const ProjectDropdown: React.FC<{
    project: Project;
    ToggleComponent?: React.FC<{ handleClick: (e: React.MouseEvent) => void }>;
}> = ({ project, ToggleComponent }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const status = getStatus(project.start_date, project.end_date, project.archived_at);
    const inProgress = status !== 'archived' && status !== 'finished';

    const toggleArchived = async (e: React.UIEvent) => {
        e.stopPropagation();
        await dispatch(updateProject(project.id, { archived: !project.archived_at }));
    };

    const handleClose = async (e: React.UIEvent) => {
        e.stopPropagation();
        await dispatch(updateProject(project.id, { end_date: new Date() }));
    };

    const handleAnonymize = async () => {
        setMenuOpen(false);
        const i18nOptions = { name: project.name };
        dispatch(
            openAlert({
                type: 'danger',
                title: i18n.t('modals.anonymizeProject.title', i18nOptions),
                icon: 'Hide',
                confirmLabel: i18n.t('modals.anonymizeProject.confirmButton'),
                body: i18n.t('modals.anonymizeProject.catchPhrase', i18nOptions),
                confirmAction: () => {
                    return dispatch(updateProject(project.id, { anonymized: true }));
                },
            }),
        );
    };

    const handleUpdateProject = (e: React.UIEvent) => {
        e.stopPropagation();
        dispatch(openModal('EditProject', { project }));
    };

    const handleAddNew = (e: React.UIEvent) => {
        e.stopPropagation();
        setMenuOpen(false);
        dispatch(openModal('AddNewsModal', { project }));
    };

    const handleDeleteProject = () => {
        setMenuOpen(false);
        const i18nOptions = { name: project.name };
        dispatch(
            openAlert({
                type: 'danger',
                title: i18n.t('modals.deleteProject.title', i18nOptions),
                icon: 'Delete',
                confirmLabel: i18n.t('modals.deleteProject.confirmButton', i18nOptions),
                body: i18n.t('modals.deleteProject.catchPhrase', i18nOptions),
                confirmAction: () => {
                    return dispatch(deleteProject(project.id)).then(() => navigate('/projectSelector'));
                },
            }),
        );
    };

    return (
        <DropdownMenu
            isOpen={isMenuOpen}
            setIsOpen={setMenuOpen}
            toggle={
                ToggleComponent ? (
                    <ToggleComponent
                        handleClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            setMenuOpen(!isMenuOpen);
                        }}
                    />
                ) : (
                    <button
                        type="button"
                        className="mu-dropdown__toggle"
                        onClick={(e) => {
                            e.stopPropagation();
                            setMenuOpen(!isMenuOpen);
                        }}
                    >
                        <MuIcon svgName="MoreOptions" />
                    </button>
                )
            }
        >
            <li className="mu-dropdown-menu__button-item">
                <button onClick={handleAddNew} type="button">
                    <TextIcon icon="Plus" label={i18n.t('global.addANews')} />
                </button>
            </li>
            <li className="mu-dropdown-menu__button-item">
                <button onClick={handleUpdateProject} type="button">
                    <TextIcon icon="Edit" label={i18n.t('global.edit')} />
                </button>
            </li>
            <MenuProgressAction
                icon="Export"
                label={i18n.t('exportStatistics.action')}
                handler={(setProgress) => dispatch(projectMetricsExport(project.id, setProgress))}
            />
            <MenuProgressAction
                icon="Export"
                label={i18n.t('questionnaires.export')}
                handler={(setProgress) => dispatch(projectQuestionnairesExport(project.id, setProgress))}
            />
            <li className="mu-dropdown-menu__separator" role="separator">
                &nbsp;
            </li>
            {inProgress ? (
                <li className="mu-dropdown-menu__button-item">
                    <button onClick={handleClose} type="button">
                        <TextIcon icon="Close" label={i18n.t('questionnaires.end')} />
                    </button>
                </li>
            ) : (
                !project.anonymized_at && (
                    <li className={classWithModifiers('mu-dropdown-menu__button-item', 'warning')}>
                        <button onClick={handleAnonymize} type="button">
                            <TextIcon icon="Hide" label={i18n.t('global.anonymize')} />
                        </button>
                    </li>
                )
            )}
            <li className={classWithModifiers('mu-dropdown-menu__button-item', 'warning')}>
                <button onClick={toggleArchived} type="button">
                    <TextIcon
                        icon="Archive"
                        label={project.archived_at ? i18n.t('global.unarchive') : i18n.t('global.archive')}
                    />
                </button>
            </li>
            <li className={classWithModifiers('mu-dropdown-menu__button-item', 'warning')}>
                <button onClick={handleDeleteProject} type="button">
                    <TextIcon icon="Delete" label={i18n.t('album.dropdowns.delete')} />
                </button>
            </li>
        </DropdownMenu>
    );
};

export default ProjectDropdown;
