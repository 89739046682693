import React, {useState} from 'react';
import {
    deleteNotes, Note, removeNotesFromAlbum, useAlbum, useCurrentProject, useNotes,
} from 'mushin-redux-store';
import i18n from 'i18next';
import Client from '../../../../Services/Client';
import InfoPanel from '../../../../Components/InfoPanel/InfoPanel';
import ActionBar from '../../../../Components/ActionBar/ActionBar';
import DropdownMenu from '../../../../Components/DropdownMenu/DropdownMenu';
import DraggableNoteList from '../../../../Components/Bucket/DragAndDropComponents/DraggableNoteList';
import {useAppDispatch, useAppSelector} from '../../../../Helpers/hooks';
import {emptyNoteBucket} from '../../../../Redux/reducers/noteBucketSlice';
import {openAlert, openModal} from '../../../../Redux/reducers/modalsSlice';
import { isProjectAdmin } from '../../../../Helpers/users';
import AddToAlbumDropdown from '../../../../Components/AddToAlbumDropdown/AddToAlbumDropdown';
import MuIcon from '../../../../Components/MuIcon';

type Props = {
  albumId: string | undefined;
  placeholder?: React.ReactElement;
};

const GalleryNoteBucket: React.FC<Props> = ({
    albumId,
    placeholder,
}) => {
    const [isOpen, setOpen] = useState(false);
    const noteIds = useAppSelector((state) => state.noteBucket.noteIds);
    const notes = useNotes(noteIds);
    const album = useAlbum(albumId);
    const project = useCurrentProject();

    const dispatch = useAppDispatch();

    const wrapWithEmptyAction = <T extends Array<unknown>>(handler: (...args: T) => void) => {
        return (...args: T): void => {
            handler(...args);
            dispatch(emptyNoteBucket());
        };
    };

    if (!noteIds.length) return placeholder || null;

    const loadedNotes = (notes?.filter(Boolean) || []) as Note[];

    const currentViewIsAlbum = !!album?.id;
    const clientIsOwnerOfAlbum = album && album.owner.id === Client.getId();
    const canDelete = loadedNotes.some((note) => note.permissions?.can_delete) || (project && isProjectAdmin(project));
    const canUpdate = loadedNotes.some((note) => note.permissions?.can_update);

    return (
        <ActionBar
            items={[
                {
                    label: i18n.t('gallery.notebucket.create_moodboard'),
                    icon: 'Moodboard',
                    handler: () => {
                        if (noteIds.length >= 8) {
                            dispatch(openModal('ChooseBoardsNumber', {
                                notes: loadedNotes,
                                albumId: album?.id,
                                onSuccess: emptyNoteBucket,
                            }));
                        } else {
                            dispatch(openModal('CreateMoodboard', {
                                notes: loadedNotes,
                                albumId: album?.id,
                                onSuccess: emptyNoteBucket,
                            }));
                        }
                    },
                },
                {
                    label: i18n.t('gallery.dropdowns.note.update'),
                    icon: 'Edit',
                    handler: () => {
                        dispatch(openModal('EditFiles', {
                            noteIds,
                            validationWrapper: wrapWithEmptyAction,
                        }));
                    },
                    disabled: !canUpdate,
                },
                {
                    label: i18n.t('components.noteBucket.export.generate'),
                    icon: 'Export',
                    handler: () => {
                        dispatch(openModal('ExportNotes', {
                            notes: loadedNotes,
                        }));
                    },
                },
                {
                    label: i18n.t('gallery.dropdowns.note.remove'),
                    icon: 'Minus',
                    handler: () => {
                        const selectedNotesInCurrentGallery = loadedNotes.filter(
                            (note) => note.album_id === album?.id
                        );
                        const filesCount = selectedNotesInCurrentGallery.length;
                        const I18nKey = filesCount > 1 ? 'removeFiles' : 'removeFile';
                        dispatch(openAlert({
                            type: 'danger',
                            title: i18n.t(`modals.${I18nKey}.title`, { filesCount }),
                            icon: 'Delete',
                            confirmLabel: i18n.t(`modals.${I18nKey}.confirmButton`, { filesCount }),
                            body: i18n.t(`modals.${I18nKey}.catchPhrase`, { galleryName: album?.label }),
                            confirmAction: wrapWithEmptyAction(
                                () => {
                                    if (album?.id) {
                                        dispatch(removeNotesFromAlbum(
                                            album?.id,
                                            loadedNotes.map((note) => note.id)
                                        )).then();
                                    }
                                }
                            ),
                        }));
                    },
                    hidden: !currentViewIsAlbum,
                    disabled: !canUpdate || !clientIsOwnerOfAlbum,
                },
                {
                    label: i18n.t('gallery.dropdowns.note.delete'),
                    icon: 'Delete',
                    handler: () => {
                        const selectedNotesDeletable = loadedNotes.filter(
                            (note) => note.permissions?.can_delete || (project && isProjectAdmin(project))
                        );
                        const totalFiles = loadedNotes.length;
                        const filesCount = selectedNotesDeletable.length;
                        const nbNotesCantBeDeleted = totalFiles - filesCount;
                        const I18nKey = filesCount > 1 ? 'deleteFiles' : 'deleteFile';
                        dispatch(openAlert({
                            type: 'danger',
                            title: i18n.t(`modals.${I18nKey}.title`, { filesCount }),
                            icon: 'Delete',
                            confirmLabel: i18n.t(`modals.${I18nKey}.confirmButton`, { filesCount }),
                            body: i18n.t(`modals.${I18nKey}.catchPhrase`),
                            infoPanel: nbNotesCantBeDeleted > 0 ? () => (
                                <InfoPanel
                                    classModifiers={['error']}
                                    iconName="Warning"
                                    text={
                                            i18n.t(
                                                `modals.${I18nKey}.notesCantBeDeleted`,
                                                { nbNotesCantBeDeleted, totalFiles }
                                            ) as string
                                    }
                                />
                            ) : undefined,
                            confirmAction: () => {
                                return dispatch(deleteNotes(selectedNotesDeletable.map((note) => note.id))).then();
                            },
                        }));
                    },
                    disabled: !canDelete,
                },
            ]}
        >
            <div className="mu-action-bar__title">{i18n.t('components.noteBucket.title')}</div>
            <DropdownMenu
                align="center"
                isOpen={isOpen}
                setIsOpen={setOpen}
                toggle={<div className="mu-note-bucket__badge">{loadedNotes.length}</div>}
            >
                <section className="mu-note-bucket__notes">
                    <DraggableNoteList />
                </section>
            </DropdownMenu>
            <AddToAlbumDropdown
                toggle={(
                    <>
                        <div className="mu-action-bar__item-label mu-popover__panel">
                            {i18n.t('gallery.notebucket.addToAnalysis')}
                        </div>
                        <MuIcon className="mu-action-bar__item-icon" svgName="Brainstorming" />
                    </>
                )}
                toggleClassName="mu-action-bar__item"
                notes={notes}
                canCreateAlbum
            />
        </ActionBar>
    );
};

export default GalleryNoteBucket;
