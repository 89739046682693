import React from 'react';
import { createPortal } from 'react-dom';

const Portal: React.FC<React.PropsWithChildren> = ({children}) => {
    const mount = document.getElementById('app-portal') as HTMLElement;

    return createPortal(children, mount);
};

export default Portal;
