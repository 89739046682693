import React from 'react';

import { split } from '../common/mixedContent';
import { Image } from './Image';
import { Text } from './Text';

import { Template } from '../../../type';

const Cell = (props) => (props.content.type === 'text' && <Text {...props} />) || (props.content.type === 'image' && <Image {...props} />) || (props.content.type === 'mixed' && <Mixed {...props} />) || null;

const Mixed = (props) => (
    <g>
        {split(props.cell, props.content).map(({
            cell,
            content,
        }, i) => <Cell key={i} {...props} content={content} cell={cell} />)}
    </g>
);

type Props = {
  template: Template;
  width: number;
  height: number;
  color: string | null | undefined;
};

export const Icon = ({
    template,
    width,
    height,
    color,
}: Props) => (
    <svg viewBox={`-4 -4 ${width + 8} ${height + 8}`}>
        <rect x="-2" y="-2" width={width + 4} height={height + 4} fill="#fff" strokeWidth={4} stroke={color} />
        {template.ids.map((id) => <Cell key={id} color={color} content={template.contents[id]} cell={template.cells[id]} />)}
    </svg>
);

Icon.defaultProps = {
    color: '#E5E5E5',
    width: 300,
    height: 200,
};
