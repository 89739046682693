import React, {CSSProperties} from 'react';
import RedirectToMobileApp from './Scenes/Sessions/RedirectToMobileApp';

const buttonStyle: CSSProperties = {width: '150px', margin: '0 auto', display: 'block', textAlign: 'center'};
const UserAgentRedirect: React.FC<React.PropsWithChildren> = ({children}) => {
    if (navigator.userAgent.match(/Android/i)) {
        return (
            <RedirectToMobileApp>
                <a style={buttonStyle} href="https://play.google.com/store/apps/details?id=com.mushin_mobile_app&hl=fr&gl=US" className="mu-link">
                    <img style={{width: '100%'}} src="/assets/images/get-in-google-play.png" alt="" />
                </a>
            </RedirectToMobileApp>
        );
    }

    if (navigator.userAgent.match(/iPhone/i)) {
        return (
            <RedirectToMobileApp>
                <a style={buttonStyle} href="https://apps.apple.com/fr/app/mushin-nextgen/id1524650736" className="mu-link">
                    <img style={{width: '100%'}} src="/assets/images/get-it-on-app-store.png" alt="" />
                </a>
            </RedirectToMobileApp>
        );
    }

    return <>{children}</>;
};

export default UserAgentRedirect;
