import React, {useState} from 'react';
import { Project, updateProject } from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import { ModalBody } from '../../../../Components/Modal/ModalBody';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton';
import CoverImageStep, { CoverImage } from '../CreationEditionModal/CoverImageStep';
import {useFieldState} from '../../../../Components/Form/common';
import { uploadFile } from '../../../../Helpers/image';
import {useAppDispatch} from '../../../../Helpers/hooks';

type Props = {
    onClose: () => void;
    project: Project;
};

const ProjectCoverModal: React.FC<Props> = ({
    onClose,
    project,
}) => {
    const [coverImage, setCoverImage] = useState<CoverImage | null | string>(project.cover_image_url);
    const coverVideoField = useFieldState(project.cover_video_url || '');

    const dispatch = useAppDispatch();

    return (
        <Modal handleRemoveModal={onClose}>
            <ModalHeader
                subtitle={i18n.t('modals.EditProjectCover.subtitle')}
                title={i18n.t('modals.EditProjectCover.title')}
                icon="SubFolderFill"
            />
            <ModalBody>
                <CoverImageStep
                    coverImageState={[coverImage, setCoverImage]}
                    coverVideoField={coverVideoField}
                    removeImage={() => setCoverImage(null)}
                />
            </ModalBody>
            <ModalFooter>
                <MuButton
                    handleClick={async () => {
                        if (coverVideoField.validate()) {
                            const coverUrl = (coverImage && typeof coverImage !== 'string')
                                ? await dispatch(uploadFile('project_cover_image', coverImage))
                                : coverImage;

                            await dispatch(updateProject(project.id, {
                                cover_image_url: coverUrl,
                                cover_video_url: coverVideoField.value || null,
                            }));
                            onClose();
                        }
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default ProjectCoverModal;
