import React from 'react';
import Spinner from '../Components/Spinner';

const AppLoader: React.FC = () => (
    <div className="mu-app-loader">
        <header className="mu-app-loader__logo">
            <img className="mu-app-loader__logo-img" src="/assets/images/logo-full.png" alt="Mushin logo" />
        </header>
        <Spinner size="50px" />
    </div>
);

export default AppLoader;
