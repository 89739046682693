import React from 'react';
import {Link} from 'react-router-dom';
import {Questionnaire} from 'mushin-redux-store';
import i18n from 'i18next';
import {getQuestionnaireBaseUrl, hasStartedQuestionnaire, isQuestionnaireAdmin} from '../../Helpers/questionnaires';

type Props = {
    questionnaire: Questionnaire;
    isContributor: boolean;
}

const QuestionnaireItemButton: React.FC<Props> = ({ questionnaire, isContributor}) => {
    const questionnaireUrl = getQuestionnaireBaseUrl(questionnaire, questionnaire.project_id);

    if (isContributor) {
        if (!questionnaire.answered) {
            return (
                <Link
                    className="mu-btn mu-btn--important"
                    to={questionnaireUrl}
                >
                    {i18n.t(questionnaire.started ? 'questionnaires.resume' : 'questionnaires.answer')}
                </Link>
            );
        }
    } else if (!hasStartedQuestionnaire(questionnaire)) {
        return (
            <Link
                className="mu-btn"
                to={`${questionnaireUrl}/edit`}
            >
                {isQuestionnaireAdmin(questionnaire) ? i18n.t('global.edit') : i18n.t('global.see')}
            </Link>
        );
    }

    const firstQuestionId = questionnaire.questions.find((it) => it.type !== 'description')?.id;
    return (
        <Link
            className="mu-btn"
            to={`${questionnaireUrl}/questions/${firstQuestionId}/answers`}
        >
            {i18n.t('questionnaires.seeAnswers')}
        </Link>
    );
};

export default QuestionnaireItemButton;
