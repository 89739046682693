import React from 'react';
import { updateOrganization, useCurrentOrganization, ApiV3 } from 'mushin-redux-store';
import i18n from 'i18next';
import MuIcon from '../MuIcon';
import Client from '../../Services/Client';
import { classWithModifiers } from '../../Helpers/css';
import {openModal} from '../../Redux/reducers/modalsSlice';
import Tabs from '../Tabs/Tabs';
import MainMenu from '../MainMenu/MainMenu';
import {useAppDispatch} from '../../Helpers/hooks';

type Props = React.PropsWithChildren<{
    contentHeader?: React.ReactElement | false;
    noScroll?: boolean;
    scrollRef?: (node: HTMLDivElement) => void;
}>;

const CompanySettingsLayout: React.FC<Props> = ({
    children,
    contentHeader,
    noScroll = false,
    scrollRef,
}) => {
    const dispatch = useAppDispatch();
    const organization = useCurrentOrganization();

    if (!organization) {
        return null;
    }

    const uploadCompanyLogo = (fileToCrop: File | undefined) => {
        if (fileToCrop) {
            dispatch(openModal('CropImageModal', {
                submitAction: (file: Blob) => {
                    const data = new FormData();
                    data.append('file', file, `${organization.name}_${organization.id}.png`);
                    data.append('organization_id', organization.id);
                    return ApiV3.getInstance().postForm('/upload_tmp_files', data).then(async (r) => {
                        const organizationLogo = r.data;
                        await dispatch(updateOrganization(organization.id, {
                            logo_src: organizationLogo,
                        }));
                        return true;
                    }).catch(() => false);
                },
                fileToCrop,
                title: i18n.t<string>('administration.organization.label'),
                icon: 'Crop',
            }));
        }
    };

    return (
        <div className="mu-app-layout">
            <div className="mu-app-layout__menu">
                <MainMenu />
            </div>
            <div className="mu-app-layout__content">
                <header className="settings-layout__header">
                    <div className="settings-layout-header__main">
                        <div className="settings-layout-header__avatar">
                            <input
                                id="company-logo"
                                type="file"
                                className="logo-settings__input-file"
                                onChange={(e) => uploadCompanyLogo(e.target.files?.[0])}
                                accept="image/*"
                                disabled={!Client.isAllowedToEditOrganization()}
                            />
                            <label htmlFor="company-logo" className="settings-layout-header__avatar-label">
                                {organization.logo_src ? (
                                    <img
                                        className={classWithModifiers('settings-layout-header__avatar-img', ['img'])}
                                        src={organization.logo_src}
                                        alt={organization.name}
                                    />
                                ) : (
                                    <div className={classWithModifiers('settings-layout-header__avatar-img', ['placeholder'])}>
                                        <MuIcon svgName="Image" />
                                        Logo
                                    </div>
                                )}
                                {Client.isAllowedToEditOrganization() && (
                                    <div className="settings-layout-header__edit-avatar">
                                        <MuIcon svgName="Edit" />
                                    </div>
                                )}
                            </label>
                        </div>
                        <h2 className="settings-layout-header__title">{organization.name}</h2>
                    </div>
                    <Tabs
                        className="settings-layout-header__tabs"
                        tabs={[{
                            key: 'settings',
                            to: `/organizations/${organization.id}/`,
                            label: i18n.t('administration.title'),
                        }, {
                            key: 'members',
                            to: `/organizations/${organization.id}/members`,
                            label: i18n.t('global.team'),
                        }, {
                            key: 'groups',
                            to: `/organizations/${organization.id}/groups`,
                            label: i18n.t('groups.items'),
                        }]}
                    />
                </header>
                <div className="settings-layout__toolbar">
                    {contentHeader}
                </div>
                {noScroll ? children : (
                    <div ref={scrollRef} className="mu-app-layout__scroll">
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompanySettingsLayout;
