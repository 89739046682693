import React from 'react';
import { EntityMember } from 'mushin-redux-store';

import i18n from 'i18next';
import EntityRow from '../../EntityRow/EntityRow';
import MuButton from '../../MuButton/MuButton';
import Modal from '../../Modal/Modal';
import {ModalHeader} from '../../Modal/ModalHeader';
import {ModalFooter} from '../../Modal/ModalFooter';
import {ModalBody} from '../../Modal/ModalBody';

type Props = {
  onClose: () => void;
  users: EntityMember[];
};

const ListContacts: React.FC<Props> = ({
    onClose,
    users,
}) => (
    <Modal handleRemoveModal={onClose} id="ListContacts" classModifiers={['large']}>
        <ModalHeader title={i18n.t('modals.listContacts.title')} />
        <ModalBody>
            <p className="mu-modal__simple-text">
                {i18n.t('modals.listContacts.whoHasAccess')}
            </p>
            <ul className="mu-teams-selector">
                {users.map((user) => {
                    return (
                        <li key={user.id} className="mu-teams-selector__items">
                            <EntityRow id={user.id} role={user.role} />
                        </li>
                    );
                })}
            </ul>
        </ModalBody>
        <ModalFooter>
            <MuButton
                label={i18n.t('global.validate')}
                handleClick={onClose}
                classModifiers={['large']}
            />
        </ModalFooter>
    </Modal>
);

export default ListContacts;
