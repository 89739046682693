import React from 'react';

import EventEmitter from 'events';
import PropTypes from 'prop-types';

const debounce = (delay, fn) => {
    let timeout = null;
    return () => {
        clearTimeout(timeout);
        timeout = setTimeout(fn, delay);
    };
};

export class Provider extends React.Component {
  _ee = new EventEmitter();

  subsribe = (fn: () => any) => {
      this._ee.addListener('update', fn);
      return () => this._ee.removeListener('update', fn);
  };

  update = debounce(1, () => this._ee.emit('update'));

  static childContextTypes = {
      layoutUpdater: PropTypes.shape({
          subsribe: PropTypes.func,
          update: PropTypes.func,
      }),
  };

  getChildContext() {
      return {
          layoutUpdater: {
              subsribe: this.subsribe,
              update: this.update,
          },
      };
  }

  render() {
      return React.Children.only(this.props.children);
  }
}

export const injectLayoutUpdate = (C) => {
    class LayoutUpdateInjector extends React.Component {
        static contextTypes = {
            layoutUpdater: PropTypes.object,
        };

        render() {
            return <C {...this.props} updateLayout={this.context.layoutUpdater.update} />;
        }
    }

    return LayoutUpdateInjector;
};
