import React, {useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionFree } from 'mushin-redux-store';
import { NoteValue } from '../types';
import MuIcon from '../../../Components/MuIcon';
import {classWithModifiers} from '../../../Helpers/css';
import { NOTE_MAX_LENGTH } from '../../../constants';

type Props = {
    index: number;
    value: NoteValue;
    setValue: (ans: NoteValue | null) => void;
    deleteValue: () => void;
    disabled: boolean;
    types: QuestionFree['freeAnswerTypes'];
}

const FreeAnswerItem: React.FC<Props> = ({
    index, value, setValue, deleteValue, disabled, types,
}) => {
    const { t } = useTranslation();

    const setText = (text: string) => setValue({ id: value.id, image: value.image, image_src: value.image_src, text });
    const setImage = (image: File | null) => setValue({ id: value.id, image, text: value.text });

    const media = useMemo(() => {
        if (!value.image && !value.video_url && !value.image_src?.medium) return null;
        return (value.image?.type.startsWith('video/') || value.video_url) ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
                className="mu-free-answer-item__image"
                src={value.image ? URL.createObjectURL(value.image) : value.video_url}
            />
        ) : (
            <img
                className="mu-free-answer-item__image"
                src={value.image ? URL.createObjectURL(value.image) : value.image_src?.medium}
                alt="Your answer"
            />
        );
    }, [value.image, value.image_src?.medium, value.video_url]);

    return (
        <>
            <div className="mu-free-answer-item">
                {!disabled && (
                    <button
                        type="button"
                        className="mu-free-answer-item__remove-button"
                        onClick={deleteValue}
                    >
                        <MuIcon className="mu-free-answer-item__remove-button-icon" svgName="Delete" />
                    </button>
                )}
                {media ? (
                    <div className="mu-free-answer-item__image-wrapper">
                        {types.includes('text') && !disabled && (
                            <button
                                type="button"
                                className="mu-free-answer-item__image-remove-button"
                                onClick={() => setImage(null)}
                            >
                                <MuIcon className="mu-free-answer-item__image-remove-button-icon" svgName="Delete" />
                            </button>
                        )}
                        {media}
                    </div>
                ) : (types.includes('image') || types.includes('video')) && (
                    <label
                        htmlFor={`mu-free-answer-item-${index}`}
                        className="mu-free-answer-item__image-choose-button"
                    >
                        <input
                            id={`mu-free-answer-item-${index}`}
                            type="file"
                            multiple={false}
                            className="mu-free-answer-item__image-choose-button-input"
                            onChange={async (e) => {
                                if (e.target.files) {
                                    setImage(e.target.files[0]);
                                }
                            }}
                            accept="image/*"
                            disabled={disabled}
                        />
                        <MuIcon
                            className="mu-free-answer-item__image-choose-button-icon"
                            svgName="ImageGallery"
                        />
                    </label>
                )}
                <textarea
                    className={classWithModifiers(
                        'mu-free-answer-item__input',
                        {'with-image': (types.includes('image') || types.includes('video'))}, 'mu-input-raw',
                    )}
                    placeholder={(
                        value.image
                            ? t<string>('questionnaires.optionalImageDescription')
                            : t<string>('questionnaires.yourAnswer')
                    )}
                    value={value.text}
                    onChange={(event) => {
                        setText(event.currentTarget.value);
                    }}
                    disabled={disabled}
                />
            </div>
            {!disabled
            && (
                <div className={classWithModifiers('mu-free-answer-item__text-counter', {error: value?.text?.length > NOTE_MAX_LENGTH})}>
                    {`${value?.text?.length || 0} / ${NOTE_MAX_LENGTH}`}
                </div>
            )}
        </>
    );
};

export default FreeAnswerItem;
