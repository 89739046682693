import React from 'react';

export const MarginSelector = ({
    setMargin,
    margin,
}) => (
    <div className="beMarginSelector">
        <input type="range" min="0" max="30" step="1" value={margin} onChange={(e) => setMargin(+e.target.value)} style={{ width: 'calc( 100% - 40px )', margin: 20 }} />
    </div>
);
