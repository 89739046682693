import React from 'react';
import { classWithModifiers } from '../../Helpers/css';
import MuIcon from '../MuIcon';

type Props = {
  defaultValue: number;
  minValue: number;
  maxValue: number;
  handleChange: (value: number) => void;
  leftIcon?: string;
  rightIcon?: string;
  classModifier?: string;
  reverse?: boolean;
};

const RangeSlider = ({
    defaultValue,
    minValue,
    maxValue,
    handleChange,
    leftIcon,
    rightIcon,
    classModifier,
    reverse,
}: Props) => (
    <section className={classWithModifiers('mu-range-slider', [classModifier, { reverse }])}>
        {leftIcon && <MuIcon className="mu-range-slider__icon" svgName={leftIcon} />}
        <input
            className="mu-range-slider__input"
            type="range"
            value={defaultValue}
            onChange={({
                target: {
                    value,
                },
            }) => handleChange(parseInt(value))}
            min={minValue}
            max={maxValue}
        />
        {rightIcon && <MuIcon className="mu-range-slider__icon" svgName={rightIcon} />}
    </section>
);

export default RangeSlider;
