/* eslint-disable max-len */

export const getFileNameExt = (filename: string): string => (filename.lastIndexOf('.') !== -1 ? filename.substring(filename.lastIndexOf('.') + 1, filename.length) : '');

export const splitFileExtAndName = (filename: string): { name: string, ext: string } => {
    const ext = getFileNameExt(filename);
    const name = filename.substring(0, filename.lastIndexOf('.'));
    return { name, ext };
};

export const authorizedFilesExt = ['jpeg', 'jpg', 'png', 'gif', 'pdf'
/* , 'bmp' */
];
