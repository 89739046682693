export const addShadowIfContainerIsScrolled = (scrollTop: number, idElement: string, className: string): void => {
    const elementWithScroll = document.getElementById(idElement);
    if (elementWithScroll) {
        const scrollClassName = `${className}--scroll-style`;
        scrollTop > 0
            ? elementWithScroll.classList.add(scrollClassName)
            : elementWithScroll.classList.remove(scrollClassName);
    }
};

// --- Class modifiers ---

type ModifiersObject = {
    [modifierName: string]: boolean | null | undefined;
}

type Modifier = string | ModifiersObject | false | null | undefined
export type ModifiersArray = ReadonlyArray<Modifier>

export type Modifiers = ModifiersArray | Modifier;

export const classWithModifiers = (
    className: string,
    modifiers: Modifiers,
    optionalClassName?: string
): string => {
    if (!modifiers && !optionalClassName) return className;

    const modifiersArray: ModifiersArray = Array.isArray(modifiers) ? modifiers : [modifiers];

    let classNames = className;

    if (optionalClassName) {
        classNames = `${optionalClassName} ${classNames}`;
    }

    for (const modifier of modifiersArray) {
        if (modifier) {
            if (typeof modifier === 'string') {
                classNames += ` ${className}--${modifier}`;
            } else {
                for (const key of Object.keys(modifier)) {
                    if (modifier[key]) {
                        classNames += ` ${className}--${key}`;
                    }
                }
            }
        }
    }

    return classNames;
};
