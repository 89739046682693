import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {login} from 'mushin-redux-store';
import i18n from 'i18next';
import MuButton from '../../../../Components/MuButton/MuButton';

import Alert from '../../../../Components/Alert/Alert';
import InputText from '../../../../Components/InputText/InputText';
import {useFormField} from '../../../../Helpers/FormsHelpers/formFieldHook';
import {muInputIsEmail} from '../../../../Helpers/FormsHelpers/formHelpers';
import {useRedirectIfConfirmed} from '../../../../Helpers/users';
import {useSearchParams} from '../../../../Helpers/queryParser';
import {successUrl} from '../../helpers';
import {useAppDispatch} from '../../../../Helpers/hooks';
import GoogleButton from '../../../../Components/GoogleButton/GoogleButton';
import {getErrorKey} from '../../../../Helpers/errors';
import MuIcon from '../../../../Components/MuIcon';

export const backUrl = (landing: string | undefined): string => {
    if (landing === 'response' || landing === 'candidacy') return `/register?landing=${landing}`;
    return '/welcome';
};

const ERRORS = {
    LOGIN_ACCOUNT_BLOCKED: 'accounts.login.blocked',
    LOGIN_MUST_USE_SSO: 'accounts.login.mustUseSSO',
    DEFAULT: 'accounts.login.error',
};

const SignInModal: React.FC = () => {
    const [searchParams] = useSearchParams<{landing?: string}>();
    const [errorLogin, setErrorLogin] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const [emailValue, setEmailValue, emailError, validateEmail, setEmailError] = useFormField({
        validator: muInputIsEmail,
        required: true,
    });

    const [passwordValue, setPasswordValue, passwordError, validatePassword, setPasswordError] = useFormField({
        required: true,
    });

    useRedirectIfConfirmed(successUrl(searchParams.landing));

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            setErrorLogin(null);
            setLoading(true);
            await dispatch(login(emailValue, passwordValue));
        } catch (error) {
            setLoading(false);
            setErrorLogin(getErrorKey(error, ERRORS));
        }
    };

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            {errorLogin && (
                <Alert
                    classModifiers={['error', 'alert-form']}
                    label={i18n.t<string>(errorLogin)}
                />
            )}
            <section className="mu-form-row mu-form-row--buttons">
                <GoogleButton />
                <Link className="mu-btn mu-btn--sso" to="/login/sso">
                    <MuIcon className="mu-btn__left-icon" svgName="Key" />
                    SSO
                </Link>
            </section>
            <section className="mu-form-row">
                <div className="mu-form-row__group">
                    <InputText
                        classModifiers={['full-width']}
                        error={!!emailError}
                        errorMessage={emailError}
                        onBlur={validateEmail}
                        onChange={(e) => {
                            setEmailValue(e.target.value.trim());
                            setEmailError('');
                        }}
                        value={emailValue}
                        placeholder={i18n.t('models.users.email')}
                    />
                </div>
            </section>
            <section className="mu-form-row">
                <div className="mu-form-row__group">
                    <InputText
                        classModifiers={['full-width']}
                        error={!!passwordError}
                        errorMessage={passwordError}
                        onBlur={validatePassword}
                        onChange={(e) => {
                            setPasswordValue(e.target.value.trim());
                            setPasswordError('');
                        }}
                        value={passwordValue}
                        placeholder={i18n.t('models.users.password')}
                        type="password"
                        autoCapitalize="off"
                    />
                </div>
            </section>
            <MuButton
                className="mu-sessions__submit-button"
                type="submit"
                disabled={loading || !passwordValue || !emailValue}
                classModifiers={['large']}
                label={i18n.t('accounts.login.submit')}
            />
            <Link to="/forgot_password" className="mu-form-row__link mu-link">
                {i18n.t('accounts.login.forgotten_password')}
            </Link>
            <Link to={backUrl(searchParams.landing)} className="mu-form-row__link mu-link">
                {i18n.t('global.back')}
            </Link>
        </form>
    );
};

export default SignInModal;
