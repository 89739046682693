import React, {useState} from 'react';
import {
    useCurrentOrganizationId,
    useCurrentProjectId,
    useProfile, useProfiles,
    useProfilesQuery
} from 'mushin-redux-store';
import i18n from 'i18next';
import MuButton from '../../../MuButton';
import EntitiesSelect from '../../../EntitySelect/EntitiesSelect';
import {getNameToDisplay} from '../../../../Helpers/users';

type Props = {
    authors?: string[];
    setAuthors: (authors: string[]) => void;
    withGroups?: boolean;
    isContributor?: boolean;
    active?: boolean;
}

const AuthorsDropdownPanel: React.FC<Props> = ({ authors = [], setAuthors, withGroups, isContributor, active }) => {
    const [projectId] = useCurrentProjectId();
    const [selectedAuthors, setSelectedAuthors] = useState<string[]>(authors || []);
    const [organizationId] = useCurrentOrganizationId();
    const profilesQuery = useProfilesQuery({
        organization_id: organizationId,
        include_groups: withGroups,
        type: isContributor ? 'contributor' : 'team',
        active,
        ...(projectId ? {project_id: projectId} : {}),
    }, true);

    const handleSubmitUsers = () => {
        setAuthors(selectedAuthors);
    };

    return (
        <>
            <EntitiesSelect
                className="mu-filters-toolbar__inline-select"
                values={selectedAuthors}
                onSelect={(values) => setSelectedAuthors(values)}
                options={profilesQuery.results || []}
                useItem={useProfile}
                useItems={useProfiles}
                getMoreResults={profilesQuery.getMoreResults}
                placeholder={i18n.t<string>('global.select')}
                getEntityName={(user) => getNameToDisplay(user)}
                search={(query) => profilesQuery.setParams({
                    organization_id: organizationId,
                    include_groups: withGroups,
                    type: isContributor ? 'contributor' : 'team',
                    name_search: query || undefined,
                })}
                hideValue
                inline
            />
            <MuButton
                className="mu-filters-toolbar__panel-submit"
                handleClick={handleSubmitUsers}
                label={i18n.t('components.filters.dropdown.apply')}
            />
        </>
    );
};

export default AuthorsDropdownPanel;
