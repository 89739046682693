import React from 'react';
import {createAlbum, Album} from 'mushin-redux-store';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../../../Helpers/hooks';
import CreateWithTitleModal from '../CreateWithTitleModal/CreateWithTitleModal';

type Props = {
    onClose: () => void;
    projectId: string;
    afterCreate?: (album: Album) => void;
};

const AlbumCreationModal: React.FC<Props> = ({
    onClose,
    projectId,
    afterCreate,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <CreateWithTitleModal
            onClose={onClose}
            labelKey="clusters"
            create={async (label) => {
                const album = await dispatch(createAlbum({
                    project_id: projectId,
                    label,
                }));
                if (afterCreate) {
                    afterCreate(album);
                } else {
                    navigate(`/projects/${projectId}/albums/${album.id}`);
                }
                onClose();
            }}
        />
    );
};

export default AlbumCreationModal;
