import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { ModalData, ModalType } from '../../Scenes/AppModals/Components';
import { AppThunk } from './index';

type State = {
    modalData: Partial<ModalData>;
    modalTypes: ModalType[];
};

export const initialState = {
    modalData: {},
    modalTypes: [],
} as State;

const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        open<Key extends ModalType>(
            state: State,
            action: PayloadAction<{ modalType: Key; modalData: ModalData[Key] }>,
        ) {
            const types = [...state.modalTypes];
            const indexOfType = types.indexOf(action.payload.modalType);
            if (indexOfType > -1) types.splice(indexOfType, 1);
            types.push(action.payload.modalType);
            state.modalTypes = types;
            state.modalData[action.payload.modalType] = action.payload.modalData;
        },
        close(state, action: PayloadAction<ModalType>) {
            const types = [...state.modalTypes];
            const indexOfType = types.indexOf(action.payload);
            if (indexOfType > -1) types.splice(indexOfType, 1);
            state.modalTypes = types;
            delete state.modalData[action.payload];
        },
    },
});

const { open, close } = modalsSlice.actions;

export const openModal =
    <Key extends ModalType>(type: Key, data = {} as ModalData[Key]): AppThunk =>
    (dispatch) =>
        dispatch(open({ modalType: type, modalData: data }));

export const closeModal = close;

export const openAlert =
    (data: ModalData['Alert']): AppThunk =>
    (dispatch) =>
        dispatch(openModal('Alert', data));

export const openQuitWithoutSavingAlert =
    (condition: boolean, onClose: () => void): AppThunk =>
    async (dispatch) => {
        if (condition) {
            dispatch(
                openAlert({
                    type: 'danger',
                    title: i18n.t('modals.leaveAlert.title'),
                    icon: 'Warning',
                    confirmLabel: i18n.t('global.confirm'),
                    body: i18n.t('modals.leaveAlert.body'),
                    confirmAction: () => onClose(),
                }),
            );
        } else {
            onClose();
        }
    };

export default modalsSlice.reducer;
