import React from 'react';
import {Project} from 'mushin-redux-store';
import i18n from 'i18next';
import AppLayout from '../../Components/Layouts/AppLayout';
import Client from '../../Services/Client';

type Props = React.PropsWithChildren<{
    project: Project;
    activeTab: 'dashboard' | 'clusters' | 'insights' | 'opportunities' | 'solutions';
}>;

const SynthesisLayout: React.FC<Props> = ({
    project,
    activeTab,
    children,
}) => {
    let tabs;

    if (Client.isTeamMemberOfProject(project)) {
        tabs = [{
            key: 'dashboard',
            to: `/projects/${project.id}/synthesis`,
            label: i18n.t('global.dashboard'),
            active: activeTab === 'dashboard',
        }, {
            key: 'clusters',
            to: `/projects/${project.id}/synthesis/clusters`,
            label: i18n.t('global.Clusters'),
            active: activeTab === 'clusters',
        }, {
            key: 'insights',
            to: `/projects/${project.id}/synthesis/insights`,
            label: i18n.t('global.Insights'),
            active: activeTab === 'insights',
        }, {
            key: 'opportunities',
            to: `/projects/${project.id}/synthesis/opportunities`,
            label: i18n.t('global.Opportunities'),
            active: activeTab === 'opportunities',
        }, {
            key: 'solutions',
            to: `/projects/${project.id}/synthesis/solutions`,
            label: i18n.t('global.Solutions'),
            active: activeTab === 'solutions',
        }];
    }

    return (
        <AppLayout
            title={i18n.t('global.synthesis')}
            tabs={tabs}
        >
            {children}
        </AppLayout>
    );
};

export default SynthesisLayout;
