import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from '../assets/locales/de.json';
import fr from '../assets/locales/fr.json';
import en from '../assets/locales/en.json';
import es from '../assets/locales/es.json';
import it from '../assets/locales/it.json';
import nl from '../assets/locales/nl.json';
import pl from '../assets/locales/pl.json';

i18next
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            de: {
                translation: de,
            },
            en: {
                translation: en,
            },
            fr: {
                translation: fr,
            },
            es: {
                translation: es,
            },
            it: {
                translation: it,
            },
            nl: {
                translation: nl,
            },
            pl: {
                translation: pl,
            },
        },
    }).then();

document.documentElement.setAttribute('lang', i18next.language);
i18next.on('languageChanged', (lng) => { document.documentElement.setAttribute('lang', lng); });
