import React, {Dispatch, SetStateAction} from 'react';

import {AvailableLocale, EntityMember, inviteProfile} from 'mushin-redux-store';
import i18n from 'i18next';
import SelectUsersDropdown from '../../../../Components/UsersListComponents/SelectUsersDropdown/SelectUsersDropdown';
import ManageMembers from '../../../../Components/UsersListComponents/AdminMembersList/ManageMembers';
import Client from '../../../../Services/Client';
import MuButton from '../../../../Components/MuButton';
import {useAppDispatch} from '../../../../Helpers/hooks';
import {openModal} from '../../../../Redux/reducers/modalsSlice';

type Props = {
    membersState: [EntityMember[], Dispatch<SetStateAction<EntityMember[]>>]
    canRemoveUser?: boolean;
    canUpdateRoles?: boolean | 'read-only';
}

const AddUsersStep: React.FC<Props> = (
    {membersState: [selectedUsers, setSelectedUsers], canRemoveUser = true, canUpdateRoles}
) => {
    const dispatch = useAppDispatch();

    return (
        <>
            {Client.isAllowedToEditOrganization() && (
                <MuButton
                    icon="UserAdd"
                    className="mu-add-users-step__invite-user"
                    handleClick={() => {
                        dispatch(openModal('InviteProfilesModal', {
                            handleSubmit: async (emails: string[], locale: AvailableLocale) => {
                                const users = await dispatch(inviteProfile(emails, {reInvite: false, type: 'team', locale}));
                                setSelectedUsers((prevSelectedUsers) => {
                                    const nextSelectedUsers = [...prevSelectedUsers];
                                    users.forEach((user) => {
                                        if (!prevSelectedUsers.some((prevUser) => prevUser.id === user.id)) {
                                            nextSelectedUsers.push({id: user.id, role: 'regular'});
                                        }
                                    });
                                    return nextSelectedUsers;
                                });
                                return users;
                            },
                        }));
                    }}
                    label={i18n.t('modals.inviteUsers.title')}
                />
            )}
            <SelectUsersDropdown
                selectUser={(userId) => setSelectedUsers([...selectedUsers, {id: userId, role: 'regular'}])}
                excludedUsersIds={selectedUsers.map((usr) => usr.id)}
                withGroups
            />
            <ManageMembers
                selectedUsers={selectedUsers}
                removeUser={canRemoveUser
                    ? (usrId) => {
                        setSelectedUsers(selectedUsers.filter((usr) => usr.id !== usrId));
                    } : undefined}
                roles={canUpdateRoles}
                setRoles={(users) => setSelectedUsers(users)}
            />
        </>
    );
};

export default AddUsersStep;
