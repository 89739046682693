import React from 'react';
import {Link} from 'react-router-dom';

type Props = React.PropsWithChildren<{
    className?: string;
    onClick: string | (() => void) | undefined;
}>;

const LinkOrButton: React.FC<Props> = ({ className, onClick, children }) => {
    if (typeof onClick === 'string') {
        return (
            <Link className={className} to={onClick} onClick={(e) => e.stopPropagation()}>
                {children}
            </Link>
        );
    }
    if (typeof onClick === 'function') {
        return (
            <button
                type="button"
                className={className}
                onClick={(e) => {
                    onClick();
                    e.stopPropagation();
                }}
            >
                {children}
            </button>
        );
    }

    return (
        <div className={className}>
            {children}
        </div>
    );
};

export default LinkOrButton;
