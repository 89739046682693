import React from 'react';
import i18n from 'i18next';
import StatusSelectButton from './StatusSelectButton';
import Badge from '../../../Badge/Badge';
import {classWithModifiers} from '../../../../Helpers/css';

const statusModifiers = {
    draft: 'warning',
    inProgress: 'valid',
    finished: 'finished',
    archived: 'archived',
};

export type StatusFilterOptions = (keyof typeof statusModifiers)[]

type Props = {
    status: string | undefined;
    setStatus: (status: string | undefined) => void;
    options?: StatusFilterOptions
}

const StatusDropdownPanel: React.FC<Props> = (
    {status, setStatus, options = ['draft', 'inProgress', 'finished', 'archived']}
) => (
    <>
        <button
            className={classWithModifiers('mu-dropdown-menu__button-item', {selected: !status})}
            type="button"
            onClick={() => setStatus(undefined)}
        >
            <Badge
                classModifiers={['button', 'all', !status && 'selected']}
            >
                {i18n.t('tooltips.all')}
            </Badge>
        </button>
        {options.map((opt) => (
            <StatusSelectButton
                key={opt}
                status={opt}
                setStatus={setStatus}
                labelKey={`global.status.${opt}`}
                modifier={statusModifiers[opt] as ('warning' | 'valid' | 'finished' | 'archived')}
                selected={status === opt}
            />
        ))}
    </>
);

export default StatusDropdownPanel;
