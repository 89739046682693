import React from 'react';
import { classWithModifiers } from '../../Helpers/css';

type UsernameJobProps = {
    name: string;
    job?: string;
    classModifiers?: Array<string> | string;
};
export const UsernameJob: React.FC<UsernameJobProps> = ({
    name,
    job = '',
    classModifiers,
}) => (
    <div className={`${classWithModifiers('mu-username-job', classModifiers)}`}>
        <h2 className="mu-username-job__name">
            {name}
        </h2>
        {job && (
            <span className="mu-username-job__job">
                {job}
            </span>
        )}
    </div>
);
