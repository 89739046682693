import React from 'react';
import UploadFilesModal from '../../../Components/GenericsModal/UploadFilesModal/UploadFilesModal';
import ShareFilesModal from './ShareFilesModal/ShareFilesModal';
import ExportNotesModal from './ExportNotesModal/ExportNotesModal';
import AlbumsSelectorModal from './AlbumSelectorModal/AlbumsSelectorModal';
import ReactionsModal from './ReactionsModal/ReactionsModal';
import SelectContributorsModal from './SelectUsersModal/SelectContributorsModal';
import PublishModal from './PublishModal/PublishModal';
import ValidateAnswersModal from './ValidateAnswersModal/ValidateAnswersModal';
import TutorialModal from './TutorialModal/TutorialModal';
import EditFilesModal from './EditFilesModal/EditFilesModal';
import CreateOrganizationModal from './CreateOrganizationModal/CreateOrganizationModal';
import CropImageModal from './CropImageModal/CropImageModal';
import InviteProfilesModal from './CreateUserModal/InviteProfilesModal';
import AlbumCreationModal from './AlbumCreationModal/AlbumCreationModal';
import AlbumInfoModal from './AlbumCreationModal/AlbumInfoModal';
import AlbumUsersModal from './AlbumCreationModal/AlbumUsersModal';
import MoveAlbumModal from './MoveAlbumModal/MoveAlbumModal';
import SelectProjectStepModal from './AlbumCreationModal/SelectProjectStepModal';
import CreateProjectModal from './ProjectModal/CreateProjectModal';
import ProjectInfoModal from './ProjectModal/ProjectInfoModal';
import ProjectCoverModal from './ProjectModal/ProjectCoverModal';
import ProjectUsersModal from './ProjectModal/ProjectUsersModal';
import GroupCreationEditionModal from './CreationEditionModal/GroupCreationEditionModal';
import ListContacts from '../../../Components/GenericsModal/ListContacts/ListContacts';
import ContributorDetailsModal from './ContributorDetailsModal/ContributorDetailsModal';
import AddIdeaModal from './AddImagesModal/AddIdeaModal';
import ChooseBoardsNumberModal from './ChooseBoardsNumberModal/ChooseBoardsNumberModal';
import CreateMoodboardModal from './CreateMoodboardModal/CreateMoodboardModal';
import AlertModal from './AlertModal/AlertModal';
import ContributorDetailsImageViewModal from './ContributorDetailsModal/ContributorDetailsImageViewModal';
import CriteriaCreationModal from './CriteriaModal/CriteriaCreationModal';
import CriteriaEditModal from './CriteriaModal/CriteriaEditModal';
import CriteriaImportModal from './CriteriaModal/CriteriaImportModal';
import AddNewsModal from './AddNewsModal/AddNewsModal';
import ProfilesStatsModal from './ProfilesStatsModal/ProfilesStatsModal';
import NewsModal from './NewsModal/NewsModal';
import CreateReminderModal from './CreateReminderModal/CreateReminderModal';
import CreateWithTitleModal from './CreateWithTitleModal/CreateWithTitleModal';
import CreateQuestionnaireModal from './CreateQuestionnaireModal/CreateQuestionnaireModal';
import CriteriaAddUsersModal from './CriteriaModal/CriteriaAddUsersModal';
import OnboardingModal from './OnboardingModal/OnboardingModal';

const modals = {
    UploadFilesModal,
    ShareFilesModal,
    NewsModal,
    ExportNotes: ExportNotesModal,
    SelectAlbumToCreateNote: AlbumsSelectorModal,
    Reactions: ReactionsModal,
    SelectContributors: SelectContributorsModal,
    PublishModal,
    ValidateAnswers: ValidateAnswersModal,
    Tutorial: TutorialModal,
    AddNotesToAlbums: AlbumsSelectorModal,
    EditFiles: EditFilesModal,
    CreateOrganization: CreateOrganizationModal,
    CropImageModal,
    InviteProfilesModal,
    CreateAlbum: AlbumCreationModal,
    EditAnAlbum: AlbumInfoModal,
    EditAlbumUsers: AlbumUsersModal,
    MoveAlbum: MoveAlbumModal,
    SelectProjectStep: SelectProjectStepModal,
    CreateProject: CreateProjectModal,
    EditProject: ProjectInfoModal,
    EditProjectCover: ProjectCoverModal,
    EditProjectUsers: ProjectUsersModal,
    CreateAGroup: GroupCreationEditionModal,
    EditAGroup: GroupCreationEditionModal,
    CreateCriterion: CriteriaCreationModal,
    ImportCriteria: CriteriaImportModal,
    EditCriterion: CriteriaEditModal,
    ListUsers: ListContacts,
    ContributorDetails: ContributorDetailsModal,
    ContributorDetailsImageView: ContributorDetailsImageViewModal,
    ProfilesStats: ProfilesStatsModal,
    AddIdeaModal,
    ChooseBoardsNumber: ChooseBoardsNumberModal,
    CreateMoodboard: CreateMoodboardModal,
    Alert: AlertModal,
    AddNewsModal,
    CreateReminder: CreateReminderModal,
    CreateWithTitleModal,
    CreateQuestionnaire: CreateQuestionnaireModal,
    CriteriaAddUsersModal,
    OnboardingModal,
};

export type ModalType = keyof typeof modals;
export type ModalProps<key extends ModalType> = Omit<
    React.ComponentProps<(typeof modals)[key]>,
    'onClose' | 'id' | 'children'
>;

export type ModalData = {
    [key in ModalType]: ModalProps<key>
}

export default modals;
