/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {TranslatedProp} from 'mushin-redux-store';
import i18next from 'i18next';
import ReactQuill from 'react-quill-new';
import Delta from 'quill-delta';
import MuIcon from '../MuIcon';

interface Props extends ReactQuill.ReactQuillProps {
    translationProp?: TranslatedProp<Delta>;
    fallback: Delta;
    additionalTitle?: React.ReactNode;
}
const QuillTranslation: React.FC<Props> = ({
    translationProp,
    fallback,
    additionalTitle,
    ...reactQuillProps
}) => {
    const [showOriginal, setShowOriginal] = useState(false);

    const translationText = translationProp && translationProp.lang
    && i18next.resolvedLanguage
    && i18next.resolvedLanguage !== translationProp.lang
    && translationProp.translations[i18next.resolvedLanguage]?.value;
    const originalText = translationProp?.original || new Delta([]);

    const canSwitch = !!translationText;

    const content = translationProp
        ? (showOriginal ? originalText : (translationText || originalText))
        : fallback;

    return (
        <div className="mu-quill-translation">
            <span className="mu-quill-translation__content" key={String(showOriginal)}>
                <ReactQuill
                    value={content}
                    readOnly
                    theme=""
                    {...reactQuillProps}
                />
                {additionalTitle}
            </span>
            {canSwitch && (
                <button
                    type="button"
                    className="mu-quill-translation__switch"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setShowOriginal(!showOriginal);
                    }}
                >
                    <MuIcon svgName="Global" />
                    <span className="mu-quill-translation__switch-text">
                        {showOriginal ? i18next.t('global.translate') : i18next.t('global.seeTheOriginal')}
                    </span>
                </button>
            )}
        </div>
    );
};

export default QuillTranslation;
