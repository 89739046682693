import React, {useState} from 'react';
import {useCurrentProjectId, useProfilesQuery} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton';
import {useTextInputWithDelay} from '../../../../Helpers/FormsHelpers/hooks';
import InputText from '../../../../Components/InputText/InputText';
import EntityRow from '../../../../Components/EntityRow/EntityRow';
import {useScrollThreshold} from '../../../../Helpers/effects';
import MuIcon from '../../../../Components/MuIcon';
import {classWithModifiers} from '../../../../Helpers/css';

type Props = {
    onClose: () => void;
    title: string;
    subtitle?: string;
    initialUsers: string[];
    onSelect: (users: string[]) => Promise<boolean>;
};

const SelectContributorsModal: React.FC<Props> = ({
    onClose,
    title,
    subtitle,
    initialUsers,
    onSelect,
}) => {
    const [currentProjectId] = useCurrentProjectId();
    const profilesQuery = useProfilesQuery({project_id: currentProjectId, type: 'contributor'}, true);
    const [currentSearch, setCurrentSearch] = useTextInputWithDelay((value) => {
        profilesQuery.setParams({
            project_id: currentProjectId,
            type: 'contributor',
            name_search: value || undefined,
        });
    });
    const [members, setMembers] = useState<string[]>(initialUsers);

    const ref = useScrollThreshold(profilesQuery.getMoreResults, {deps: [profilesQuery.results?.length]});

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={onClose}>
            <ModalHeader
                title={title}
                subtitle={subtitle}
                icon="Share"
            />
            <InputText
                classModifiers={['full-width']}
                value={currentSearch}
                onChange={(event) => setCurrentSearch(event.target.value)}
                placeholder={i18n.t('components.filters.search')}
                contrast
            />
            <div ref={ref} className="util-modal-oflow-auto">
                {profilesQuery.results?.map((id) => {
                    const isMember = members.includes(id);
                    return (
                        <EntityRow
                            key={id}
                            id={id}
                            useRoles={false}
                            rightChildren={(
                                <button
                                    className={classWithModifiers('mu-entity-row__action-link', {success: isMember})}
                                    type="button"
                                    onClick={() => {
                                        if (isMember) {
                                            setMembers(members.filter((_id) => _id !== id));
                                        } else {
                                            setMembers([...members, id]);
                                        }
                                    }}
                                >
                                    <MuIcon svgName={isMember ? 'Check' : 'Plus'} />
                                </button>
                            )}
                        />
                    );
                })}
            </div>
            <ModalFooter>
                <MuButton
                    handleClick={async () => {
                        if (await onSelect(members)) {
                            onClose();
                        }
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>
    );
};

export default SelectContributorsModal;
