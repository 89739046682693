import React, { useState } from 'react';
import i18n from 'i18next';
import { classWithModifiers } from '../../Helpers/css';
import TextIcon from '../TextIcon/TextIcon';
import MoodboardCarouselPage from './MoodboardCarouselPage';
import {MoodboardPage} from '../BoardComponents/BoardLayout/type';

type Props = {
    pages: MoodboardPage[];
    selectedPage: number;
    setSelectedPage: (index: number) => void;
    draggable?: boolean;
    classModifier?: string;
    removePage?: (index: number) => void;
    movePage?: (from: number, to: number) => void;
    addPage?: () => void;
    heightCarousel?: number;
};

const MoodboardCarousel: React.FC<Props> = (
    {
        pages,
        selectedPage,
        setSelectedPage,
        draggable,
        classModifier,
        heightCarousel,
        removePage,
        movePage,
        addPage,
    }
) => {
    const [draggedIndex, setDraggedIndex] = useState(-1);
    const [dropIndex, setDropIndex] = useState(-1);

    const handleSelectPage = (page: number) => {
        if (page === selectedPage) return;

        setSelectedPage(page);
    };

    const handleRemovePage = (page: number) => {
        if (!removePage) return;

        if (page === selectedPage) {
            setSelectedPage(page > 0 ? page - 1 : 0);
        }

        removePage(page);
    };

    const onDragStart = (index: number, event: React.DragEvent) => {
        if (!draggable) return;

        // set event.dataTransfer, otherwise firefox will not drag
        event.dataTransfer.setData('text', 'whatever');
        setTimeout(() => {
            setDropIndex(index);
            setDraggedIndex(index);
        }, 10);
    };

    const onDragOver = (index: number, event: React.DragEvent) => {
        const {
            top,
            bottom,
        } = event.currentTarget.getBoundingClientRect();
        const distanceFromTop = event.clientY - top;
        const distanceFromBottom = bottom - event.clientY;
        let newDropIndex = distanceFromTop < distanceFromBottom ? index : index + 1;

        if (newDropIndex === draggedIndex + 1) {
            newDropIndex = draggedIndex;
        }

        if (dropIndex !== newDropIndex) {
            setDropIndex(newDropIndex);
        }

        event.preventDefault();
    };

    const onDragEnd = (event: React.DragEvent) => {
        if (!movePage) return;
        movePage(draggedIndex, draggedIndex < dropIndex ? dropIndex - 1 : dropIndex);
        setDraggedIndex(-1);
        setDropIndex(-1);
        event.preventDefault();
    };

    const reorganizedPages = draggedIndex > -1 && draggedIndex !== dropIndex ? [] : pages;

    if (draggable) {
        for (let i = 0; i < pages.length; i += 1) {
            if (i === dropIndex) reorganizedPages.push(pages[draggedIndex]);
            else if (i > dropIndex && i <= draggedIndex) reorganizedPages.push(pages[i - 1]);
            else reorganizedPages.push(pages[i]);
        }
    }

    return (
        <section
            style={heightCarousel ? { height: heightCarousel } : {}}
            className={classWithModifiers('mu-moodboard-carousel', [classModifier])}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={onDragEnd}
            onDrop={(e) => e.preventDefault()}
        >
            {pages.map((page, index) => {
                return (
                    <div key={index} className="mu-moodboard-carousel__page-wrapper">
                        <MoodboardCarouselPage
                            board={page.board}
                            templateContents={page.boardData.templateContents}
                            size={index === dropIndex ? 140 : 150}
                            classModifiers={{
                                selected: index === (dropIndex > -1 ? dropIndex : selectedPage),
                                dragged: index === dropIndex,
                            }}
                            onDragStart={(event) => onDragStart(index, event)}
                            onDragOver={(event) => onDragOver(index, event)}
                            onClick={() => handleSelectPage(index)}
                            removable={draggedIndex === -1 && removePage && pages.length > 1}
                            onRemove={() => handleRemovePage(index)}
                        />
                    </div>
                );
            })}
            {addPage && (
                <div className="mu-moodboard-carousel__add-page">
                    <button type="button" onClick={addPage} className="mu-moodboard-carousel__add-page-btn">
                        <TextIcon icon="Plus" label={i18n.t('components.boardEditor.addAPage')} />
                    </button>
                </div>
            )}
        </section>
    );
};

export default MoodboardCarousel;
