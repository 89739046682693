import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../../../Helpers/hooks';
import CreateWithTitleModal from '../CreateWithTitleModal/CreateWithTitleModal';
import {createAndInitQuestionnaire} from '../../../../Helpers/thunkActions';

type Props = {
    onClose: () => void;
    projectId: string;
};

const CreateQuestionnaireModal: React.FC<Props> = ({
    onClose,
    projectId,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <CreateWithTitleModal
            onClose={onClose}
            labelKey="questionnaires"
            create={async (title) => {
                const questionnaire = await dispatch(createAndInitQuestionnaire({
                    project_id: projectId, accessType: 'project', title,
                }));
                navigate(`/projects/${projectId}/questionnaires/${questionnaire.id}/edit`);
                onClose();
            }}
        />
    );
};

export default CreateQuestionnaireModal;
