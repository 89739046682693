import React, {useEffect, useState} from 'react';
import {
    AvailableLocale, deleteUserFromOrganization,
    useCurrentOrganizationId,
    ProfilesQueryParams,
    useProfilesQuery,
    inviteProfile,
    Profile,
    updateProfile,
} from 'mushin-redux-store';
import i18n from 'i18next';
import CompanySettingsLayout from '../../../Components/SettingsComponents/CompanySettingsLayout';
import Client from '../../../Services/Client';
import InputText from '../../../Components/InputText/InputText';
import CompanyMember from './Components/CompanyMember';
import { useScrollThreshold } from '../../../Helpers/effects';
import {useSearchParams} from '../../../Helpers/queryParser';
import UsersToolbar, {MembersSearchParams} from '../../../Components/UsersToolbar/UsersToolbar';
import {EntityRowRole} from '../../../Components/EntityRow/EntityRow';
import {getProfileStatus} from '../../../Helpers/users';
import {useAppDispatch} from '../../../Helpers/hooks';
import ProfilesGrid from '../../../Components/ProfilesGrid/ProfilesGrid';
import MuButton from '../../../Components/MuButton';
import {openModal} from '../../../Redux/reducers/modalsSlice';

const searchToQueryParams = (searchParams: MembersSearchParams): ProfilesQueryParams => {
    const {
        status,
        ...queryParams
    } = searchParams;

    if (status === 'registered') {
        (queryParams as ProfilesQueryParams).confirmed = true;
    } else if (status === 'invited') {
        (queryParams as ProfilesQueryParams).confirmed = false;
    }

    (queryParams as ProfilesQueryParams).type = 'team';

    return queryParams;
};

const CompanyMembers: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams({ groups: 'array' });
    const [organizationId] = useCurrentOrganizationId();
    const profilesQuery = useProfilesQuery({
        organization_id: organizationId,
        ...searchToQueryParams(searchParams),
    }, !Object.keys(searchParams).length);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const ref = useScrollThreshold(profilesQuery.getMoreResults, {deps: [profilesQuery.results?.length]});

    const dispatch = useAppDispatch();

    const {setParams} = profilesQuery;
    useEffect(() => {
        setParams({organization_id: organizationId, ...searchToQueryParams(searchParams)}, !Object.keys(searchParams).length);
    }, [organizationId, searchParams, setParams]);

    if (!organizationId) {
        return null;
    }

    if (!Client.isAllowedToEditOrganization()) {
        return (
            <CompanySettingsLayout
                contentHeader={(
                    <div className="mu-toolbar">
                        <div className="mu-toolbar__search">
                            <InputText
                                classModifiers={['full-width', 'toolbar']}
                                value={profilesQuery.params.name_search || ''}
                                onChange={(e) => (
                                    profilesQuery.setParams({
                                        organization_id: organizationId,
                                        name_search: e.currentTarget.value,
                                    })
                                )}
                                placeholder={i18n.t('components.filters.search')}
                            />
                        </div>
                    </div>
                )}
                scrollRef={ref}
            >
                {profilesQuery.results?.map((member) => {
                    return !!member && (
                        <CompanyMember
                            key={member}
                            memberId={member}
                        />
                    );
                })}
            </CompanySettingsLayout>
        );
    }

    const changeUserRole = (profile: Profile, roleOption: EntityRowRole) => {
        if (roleOption) {
            dispatch(updateProfile(profile.id, {role: roleOption.key as Profile['role']}));
        }
    };

    return (
        <CompanySettingsLayout
            contentHeader={(
                <UsersToolbar
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    queryParams={profilesQuery.params}
                    removeUsers={async (ids) => {
                        for (const id of ids) {
                            // eslint-disable-next-line no-await-in-loop
                            await dispatch(deleteUserFromOrganization(id, organizationId));
                        }
                    }}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    getStatus={getProfileStatus}
                >
                    <MuButton
                        classModifiers="toolbar"
                        handleClick={() => {
                            dispatch(openModal('InviteProfilesModal', {
                                handleSubmit: async (emails: string[], locale: AvailableLocale) => (
                                    dispatch(inviteProfile(emails, {
                                        organizationId,
                                        reInvite: false,
                                        type: 'team',
                                        locale,
                                    }))
                                ),
                            }));
                        }}
                        icon="Plus"
                        label={i18n.t('modals.inviteUsers.title')}
                    />
                </UsersToolbar>
            )}
            noScroll
        >
            <ProfilesGrid
                scrollRef={ref}
                query={profilesQuery}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                getRole={(profile) => profile.role || 'regular'}
                setRole={changeUserRole}
                roleOptions={[{
                    key: 'owner',
                    icon: 'LockLine',
                    unique: true,
                    fallback: 'admin',
                    canEdit: true,
                }, {
                    key: 'admin',
                    icon: 'Settings',
                    canEdit: true,
                }, {
                    key: 'creator',
                    icon: 'Write',
                }, {
                    key: 'regular',
                    icon: 'UserLine',
                }]}
                getStatus={(user) => getProfileStatus(user)}
                standalone
                withScroll
            />
        </CompanySettingsLayout>
    );
};

export default CompanyMembers;
