import React, { useEffect, useState } from 'react';
import {Questionnaire, QuestionnaireAnswerState} from 'mushin-redux-store';
import i18n from 'i18next';
import { isDeltaEmpty } from 'mushin-node-commons';
import MuButton from '../../Components/MuButton';
import QuestionAnswer from '../QuestionnaireAnswer/QuestionAnswer';
import { getNextQuestionIndex } from '../../Helpers/questionnaires';
import {AnswerValue} from '../QuestionnaireAnswer/types';
import TextTranslation from '../../Components/Translation/TextTranslation';
import QuillTranslation from '../../Components/Translation/QuillTranslation';

type Props = {
    questionnaire: Questionnaire | null | undefined;
    disabled?: boolean;
    answers?: QuestionnaireAnswerState;
    applyConditional?: boolean;
}

const QuestionnairePreview: React.FC<Props> = ({ questionnaire, disabled, answers = {}, applyConditional }) => {
    const [answersValues, setAnswersValues] = useState<{ [questionId: string]: AnswerValue | undefined }>(answers || {});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [prevQuestionIndexes, setPrevQuestionIndexes] = useState<number[]>([]);

    const hasDescription = !!questionnaire?.desc && !isDeltaEmpty(questionnaire?.desc);

    useEffect(() => {
        if (!hasDescription && currentQuestionIndex === -1) {
            setCurrentQuestionIndex(0);
        }
    }, [currentQuestionIndex, hasDescription, questionnaire]);

    if (!questionnaire) return null;

    const handleNext = () => {
        if (!applyConditional) {
            setCurrentQuestionIndex((prev) => prev + 1);
            return;
        }

        const currentQuestion = currentQuestionIndex >= 0 ? questionnaire.questions[currentQuestionIndex] : undefined;
        const answerValue = currentQuestion ? answersValues[currentQuestion.id] : null;

        const nextQuestionIndex = getNextQuestionIndex(questionnaire, currentQuestionIndex, answerValue);

        if (nextQuestionIndex < questionnaire?.questions?.length) {
            setCurrentQuestionIndex(nextQuestionIndex);    
            setPrevQuestionIndexes((prev) => {
                prev.push(currentQuestionIndex);
                return [...prev];
            });
        }
    };

    const handleBack = () => {
        if (!applyConditional) {
            setCurrentQuestionIndex((prev) => prev - 1);
            return;
        }
        setCurrentQuestionIndex(prevQuestionIndexes.pop() as number);
        setPrevQuestionIndexes([...prevQuestionIndexes]);
    };

    return (
        <div className="mu-questionnaire-answer">
            <div className="mu-questionnaire-answer__header" hidden={currentQuestionIndex !== -1}>
                <h1 className="mu-questionnaire-answer__title">
                    <TextTranslation translationProp={questionnaire.titles} fallback={questionnaire.title} />
                </h1>
                {questionnaire.desc && (
                    <div className="mu-questionnaire-answer__desc">
                        <QuillTranslation
                            translationProp={questionnaire.descriptions}
                            fallback={questionnaire.desc}
                            className="mu-rich-input"
                            theme=""
                        />
                    </div>
                )}
            </div>
            <div className="mu-questionnaire-answer__questions-list" hidden={currentQuestionIndex === -1}>
                {questionnaire.questions.map((question, index) => (
                    <div key={question.id} hidden={index !== currentQuestionIndex}>
                        <QuestionAnswer
                            index={index}
                            disabled={disabled}
                            total={questionnaire.questions.length}
                            question={question}
                            previousAnswer={undefined}
                            answer={answersValues[question.id] || null}
                            setAnswer={(value) => setAnswersValues((values) => ({
                                ...values,
                                [question.id]: value,
                            }))}
                        />
                    </div>
                ))}
            </div>
            <div className="mu-questionnaire-answer__action-bar">
                {((currentQuestionIndex >= 0 && hasDescription) || (currentQuestionIndex > 0 && !hasDescription))
                    && (
                        <MuButton
                            className="mu-btn mu-btn--outline"
                            label={i18n.t('global.back')}
                            handleClick={handleBack}
                        />
                    )}
                <MuButton
                    disabled={currentQuestionIndex === questionnaire.questions.length - 1}
                    label={i18n.t('global.next')}
                    handleClick={handleNext}
                />
            </div>
        </div>
    );
};

export default QuestionnairePreview;
