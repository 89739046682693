import React from 'react';
import {ProjectStep, Questionnaire} from 'mushin-redux-store';
import i18n from 'i18next';
import StatusDropdownPanel, {StatusFilterOptions} from './Elements/Status/StatusDropdownPanel';
import {FilterConfig} from './FilterPanelContent';
import AuthorsValue from './Elements/Authors/AuthorsValue';
import AuthorsDropdownPanel from './Elements/Authors/AuthorsDropdownPanel';
import {getDateRangeValue} from './Elements/DateRange/value';
import DateRangeDropdownPanel from './Elements/DateRange/DateRangeDropdownPanel';
import NoteTypeDropdownPanel from './Elements/NoteType/NoteTypeDropdownPanel';
import HashtagsDropdownPanel from './Elements/Hashtags/HashtagsDropdownPanel';
import CategoryDropdownPanel from './Elements/Category/CategoryDropdownPanel';
import StepsDropdownPanel from './Elements/Steps/StepsDropdownPanel';
import MemberStatusDropdownPanel, {memberStatusLabelKeys} from './Elements/Status/MemberStatusDropdownPanel';
import GroupsDropdownPanel from './Elements/Groups/GroupsDropdownPanel';
import GroupValue from './Elements/Groups/GroupValue';
import {classWithModifiers} from '../../Helpers/css';
import CriteriaValue from './Elements/Criterion/CriteriaValue';
import CriteriaDropdownPanel, {CriteriaSelectedValues} from './Elements/Criterion/CriteriaDropdownPanel';
import RespondentStatusDropdownPanel, { respondentStatusLabelKeys } from './Elements/Status/RespondentStatusDropdownPanel';
import QuestionAnswersValue from './Elements/QuestionAnswers/QuestionAnswersValue';
import QuestionAnswersDropdownPanel from './Elements/QuestionAnswers/QuestionAnswersDropdownPanel';

const getNoteTypeValue = (type: string | undefined) => {
    if (type === 'MOODBOARD') return i18n.t('components.filters.moodboard');
    if (type === 'TEXT') return i18n.t('global.text');
    return i18n.t('components.filters.picture');
};

export const noteTypeFilter = (
    type: string | undefined,
    setType: (_type: string | undefined) => void,
): FilterConfig => ({
    icon: 'Settings',
    label: i18n.t('components.filters.type'),
    value: type ? getNoteTypeValue(type) : null,
    dropdownPanel: (
        <NoteTypeDropdownPanel
            type={type}
            setType={setType}
        />
    ),
    active: !!type,
    reset: () => setType(undefined),
});

export const projectStepFilter = (
    selectedStep: string | undefined,
    setStep: (_step: string | undefined) => void,
    steps: ProjectStep[],
): FilterConfig => ({
    icon: 'Stopwatch',
    label: i18n.t('projects.steps.label'),
    value: selectedStep ? steps.find((step) => step.slug === selectedStep)?.title : null,
    dropdownPanel: (
        <>
            <button
                className={classWithModifiers('mu-dropdown-menu__button-item', {selected: !selectedStep})}
                type="button"
                onClick={() => setStep(undefined)}
            >
                {i18n.t('tooltips.all')}
            </button>
            {steps.map((step) => (
                <button
                    key={step.slug}
                    className={classWithModifiers(
                        'mu-dropdown-menu__button-item', {selected: selectedStep === step.slug}
                    )}
                    type="button"
                    onClick={() => setStep(step.slug)}
                >
                    {step.title}
                </button>
            ))}
        </>
    ),
    active: !!selectedStep,
    reset: () => setStep(undefined),
});

export const categoryFilter = (
    category: string | null | undefined,
    setCategory: (_category: string | null | undefined) => void,
    categories: { key: string | null ; count: number }[],
): FilterConfig => ({
    icon: 'Settings',
    label: i18n.t('project.category'),
    value: category !== undefined ? (
        category === null ? i18n.t('project.noCategory') : category
    ) : null,
    dropdownPanel: (
        <CategoryDropdownPanel
            category={category}
            setCategory={setCategory}
            categories={categories}
        />
    ),
    active: category !== undefined,
    reset: () => setCategory(undefined),
});

export const hashtagsFilter = (
    hashtags: string[] | undefined,
    setHashtags: (_hashtags: string[] | undefined) => void,
    allHashtags: {key: string; doc_count: number}[]
): FilterConfig => ({
    icon: 'Settings',
    label: i18n.t('search.inTags'),
    value: hashtags?.length ? (
        `${hashtags[0]} ${
            hashtags.length > 1
                ? `${i18n.t('global.and')} ${hashtags.length - 1} ${i18n.t('global.others')}`
                : ''
        }`
    ) : null,
    dropdownPanel: (
        <HashtagsDropdownPanel
            hashtags={hashtags}
            setHashtags={setHashtags}
            aggregations={allHashtags}
        />
    ),
    active: !!hashtags?.length,
    reset: () => setHashtags(undefined),
});

export const statusFilter = (
    status: string | undefined,
    setStatus: (_status: string | undefined) => void,
    options?: StatusFilterOptions
): FilterConfig => ({
    icon: 'Cogwheel',
    label: i18n.t('global.status.status'),
    value: status ? i18n.t(`global.status.${status}`) : null,
    dropdownPanel: (
        <StatusDropdownPanel
            status={status}
            setStatus={setStatus}
            options={options}
        />
    ),
    active: !!status,
    reset: () => setStatus(undefined),
});

export const memberStatusFilter = (
    status: string | undefined,
    setStatus: (_status: string | undefined) => void,
    withActive = false,
): FilterConfig => ({
    icon: 'Settings',
    label: i18n.t('global.status.status'),
    value: status ? i18n.t(memberStatusLabelKeys[status]) : null,
    dropdownPanel: (
        <MemberStatusDropdownPanel
            status={status}
            setStatus={setStatus}
            withActive={withActive}
        />
    ),
    active: !!status,
    reset: () => setStatus(undefined),
});

export const respondentStatusFilter = (
    status: string | undefined,
    setStatus: (_status: string | undefined) => void,
    questionnaire?: Questionnaire,
): FilterConfig => ({
    icon: 'Settings',
    label: i18n.t('global.status.status'),
    value: status ? i18n.t(respondentStatusLabelKeys[status]) : null,
    dropdownPanel: (
        <RespondentStatusDropdownPanel
            status={status}
            setStatus={setStatus}
            questionnaire={questionnaire}
        />
    ),
    active: !!status,
    reset: () => setStatus(undefined),
});

export const stepsFilter = (
    stepSlug: string | undefined,
    setStep: (_step: string | undefined) => void,
    steps: ProjectStep[]
): FilterConfig => ({
    icon: 'Test',
    label: i18n.t('global.step'),
    value: stepSlug ? steps.find((step) => step.slug === stepSlug)?.title : null,
    dropdownPanel: (
        <StepsDropdownPanel
            stepSlug={stepSlug}
            setStep={setStep}
            steps={steps}
        />
    ),
    active: !!stepSlug,
    reset: () => setStep(undefined),
});

export const authorsFilter = (
    authors: string[] | undefined,
    setAuthors: (_authors: string[] | undefined) => void,
    config?: {
        isContributor?: boolean;
        withGroups?: boolean;
        active?: boolean;
        labelKey?: string;
        icon?: string;
    }
): FilterConfig => ({
    icon: config?.icon || 'User',
    label: i18n.t(config?.labelKey || 'components.filters.author'),
    value: authors?.length ? <AuthorsValue authorsIds={authors} /> : null,
    dropdownPanel: (
        <AuthorsDropdownPanel
            authors={authors}
            setAuthors={setAuthors}
            isContributor={config?.isContributor}
            withGroups={config?.withGroups}
            active={config?.active}
        />
    ),
    active: !!authors?.length,
    reset: () => setAuthors(undefined),
});

export const ownersFilter = (
    owners: string[] | undefined,
    setOwners: (_authors: string[] | undefined) => void,
    config?: {
        isContributor?: boolean;
        withGroups?: boolean;
    }
): FilterConfig => authorsFilter(
    owners, setOwners, {...config, isContributor: false, labelKey: 'models.users.role.owner'},
);

export const favoritesFilter = (
    favorites: string[] | undefined,
    setFavorites: (_authors: string[] | undefined) => void,
): FilterConfig => authorsFilter(
    favorites, setFavorites, {labelKey: 'global.favorites', icon: 'Star', withGroups: true, isContributor: false},
);

export const groupsFilter = (
    groups: string[] | undefined,
    setGroups: (_groups: string[] | undefined) => void,
): FilterConfig => ({
    icon: 'Group',
    label: i18n.t('groups.items'),
    value: groups?.length ? <GroupValue ids={groups} /> : null,
    dropdownPanel: (
        <GroupsDropdownPanel
            groups={groups}
            setGroups={setGroups}
        />
    ),
    active: !!groups?.length,
    reset: () => setGroups(undefined),
});

export const criteriaFilter = (
    criteria: CriteriaSelectedValues | undefined,
    setCriteria: (_criteria: CriteriaSelectedValues | undefined) => void,
): FilterConfig => ({
    icon: 'Group',
    label: i18n.t('criteria.items'),
    value: criteria?.length ? <CriteriaValue values={criteria} /> : null,
    dropdownPanel: (
        <CriteriaDropdownPanel
            criteria={criteria || []}
            setCriteria={setCriteria}
        />
    ),
    active: !!criteria?.length,
    reset: () => setCriteria(undefined),
});

export const criteriaParamsFilter = (
    criteria: string[] | undefined,
    setCriteria: (_criteria: string[] | undefined) => void,
): FilterConfig => criteriaFilter(
    criteria?.map((c) => JSON.parse(c)),
    (_criteria) => setCriteria(_criteria?.map((c) => JSON.stringify(c))),
);

export const questionAnswersFilter = (
    questionAnswers: string[] | undefined,
    setQuestionAnswers: (_questionAnswers: string[] | undefined) => void,
): FilterConfig => ({
    icon: 'Survey',
    label: i18n.t('questionnaires.questionAnswers'),
    value: questionAnswers?.length ? (
        <QuestionAnswersValue
            data={questionAnswers}
        />
    ) : null,
    dropdownPanel: (
        <QuestionAnswersDropdownPanel
            questionAnswers={questionAnswers}
            setQuestionAnswers={setQuestionAnswers}
        />
    ),
    active: !!questionAnswers?.length,
    reset: () => setQuestionAnswers(undefined),
});

const dateRangeFilter = (labelKey: string) => (
    minDate: string | undefined,
    maxDate: string | undefined,
    setDateRange: (_minDate: string | undefined, _maxDate: string | undefined) => void
): FilterConfig => ({
    icon: 'Calendar',
    label: i18n.t(labelKey),
    value: getDateRangeValue(minDate, maxDate),
    dropdownPanel: (
        <DateRangeDropdownPanel
            minDate={minDate}
            maxDate={maxDate}
            setDateRange={setDateRange}
        />
    ),
    active: !!(minDate || maxDate),
    reset: () => setDateRange(undefined, undefined),
});

export const createdAtFilter = dateRangeFilter('components.filters.creationDate');
export const updatedAtFilter = dateRangeFilter('components.filters.updatingDate');
