import React, {useState} from 'react';
import Masonry from 'react-responsive-masonry';
import {Note} from 'mushin-redux-store';
import SquarePicture from '../SquarePicture';
import MuIcon from '../MuIcon';
import ReorderWrapper from '../ReorderWrapper/ReorderWrapper';
import {DnDItemTypes, useReorderedList} from '../../Helpers/dnd';
import ResponsiveMasonry from '../ResponsiveMasonry/ResponsiveMasonry';
import NotesJustifiedGridItem from './NotesJustifiedGridItem';

const COLUMNS_BREAKPOINTS: [number, number][] = [
    [350, 1], [500, 2], [650, 3], [900, 4], [1200, 5],
    [1500, 6], [1800, 7], [2100, 8], [2400, 9], [2700, 10],
    [3000, 11], [3300, 12], [3600, 13], [3900, 14], [4200, 15],
];

export interface LayoutBox {
    aspectRatio: number;
    top: number;
    width: number;
    height: number;
    left: number;
}
export type BoxSpacingOption = number | { horizontal: number; vertical: number }
export type NotesGeometryOptions = {
    containerWidth?: number;
    boxSpacing?: BoxSpacingOption;
    targetRowHeight?: number;
    containerPadding?: number | { top: number; right: number; left: number; bottom: number };
}

type Props = {
    uploadingNoteIds: string[];
    notes: (Note | null)[];
    isItemSelected?: (note: Note) => boolean;
    onSelectItems?: (notes: Note[]) => void;
    onDeselectItem?: (note: Note) => void;
    getItemClickHandler?: (note: Note) => string | (() => void);
    canMoveItem?: boolean;
    moveItem?: (id: string, params: {idBefore?: string, idAfter?: string}) => Promise<void>;
    showItemActions?: boolean;
    showItemInteractions?: boolean;
    showItemInfos?: boolean;
    showItemFavorite?: boolean;
    noteIdsUnread?: string[];
}

const NotesJustifiedGrid: React.FC<Props> = (
    {
        uploadingNoteIds,
        notes,
        isItemSelected,
        onSelectItems,
        onDeselectItem,
        getItemClickHandler,
        canMoveItem = false,
        moveItem,
        showItemActions = false,
        showItemInteractions = false,
        showItemInfos = false,
        showItemFavorite = false,
        noteIdsUnread,
    }
) => {
    const [reorderedNotes, move] = useReorderedList(notes);
    const [lastSelectedId, setLastSelectedId] = useState<string>();

    return (
        <ResponsiveMasonry columnsCountBreakPoints={COLUMNS_BREAKPOINTS}>
            <Masonry gutter="20px">
                {uploadingNoteIds.map((id) => (
                    <article key={id} className="mu-note">
                        <SquarePicture imgUrl="/assets/images/Spinner.png" />
                    </article>
                ))}
                {reorderedNotes.map((note, index) => {
                    const onClick = note ? getItemClickHandler?.(note) : undefined;
                    const isSelected = note ? isItemSelected?.(note) : undefined;
                    return note && (
                        <ReorderWrapper
                            key={note.id}
                            className="mu-note__reorder-wrapper"
                            placeholder={(
                                <div className="mu-note__reorder-placeholder">
                                    <MuIcon svgName="Drop" />
                                </div>
                            )}
                            type={DnDItemTypes.NOTE}
                            id={note.id}
                            index={index}
                            move={move}
                            end={async (item) => {
                                if (moveItem && item.index !== item.dropIndex) {
                                    await moveItem(
                                        item.id,
                                        item.dropIndex === 0 ? {
                                            idAfter: notes[0]?.id,
                                        } : {
                                            idBefore: notes[
                                                item.index > item.dropIndex ? item.dropIndex - 1 : item.dropIndex
                                            ]?.id,
                                        },
                                    );
                                }
                                move(-1, -1);
                            }}
                            horizontal
                            disabled={!canMoveItem}
                        >
                            <NotesJustifiedGridItem
                                note={note}
                                isSelected={isSelected}
                                onSelect={(event) => {
                                    if (onSelectItems && onDeselectItem) {
                                        if (event?.shiftKey && lastSelectedId) {
                                            event.preventDefault();

                                            // we get indexes of the last selected note and the clicked note
                                            const lastSelectedNoteIndex = notes.findIndex(
                                                (_note) => _note?.id === lastSelectedId,
                                            );
                                            const clickedNoteIndex = notes.findIndex((_note) => _note?.id === note.id);

                                            const notesToSelect = (
                                                    clickedNoteIndex > lastSelectedNoteIndex
                                                        ? notes.slice(lastSelectedNoteIndex, clickedNoteIndex + 1)
                                                        : notes.slice(clickedNoteIndex, lastSelectedNoteIndex)
                                                ) as Note[];

                                            onSelectItems(notesToSelect);
                                        } else if (!isSelected) {
                                            onSelectItems([note]);
                                            setLastSelectedId(note?.id);
                                        } else {
                                            onDeselectItem(note);
                                            if (note.id === lastSelectedId) setLastSelectedId(undefined);
                                        }
                                    }
                                }}
                                onClick={onClick}
                                showItemActions={showItemActions}
                                showItemInteractions={showItemInteractions}
                                showItemInfos={showItemInfos}
                                showItemFavorite={showItemFavorite}
                                noteIdsUnread={noteIdsUnread}
                            />
                        </ReorderWrapper>
                    );
                })}
            </Masonry>
        </ResponsiveMasonry>
    );
};

export default NotesJustifiedGrid;
