import React from 'react';
import {useProject} from 'mushin-redux-store';
import i18n from 'i18next';
import { classWithModifiers } from '../../Helpers/css';
import {MuSvg} from '../MuSvg';
import DesignThinking from '../../../assets/icons/DesignThinkingPlaceholder.svg';
import MuButton from '../MuButton';
import { BadgeComponent } from '../Badge/BadgeComponent';
import { getStatus } from '../../Helpers/date';
import TextTranslation from '../Translation/TextTranslation';
import Client from '../../Services/Client';

type Props = {
    projectId: string;
    notificationCount: number;
    handleClick: () => void;
};

const ProjectPreviewBlock: React.FC<Props> = ({
    projectId,
    notificationCount,
    handleClick,
}: Props) => {
    const project = useProject(projectId);
    if (!project) return null;
    const {
        cover_image_url,
    } = project;

    const activeContributorsSize = (
        project.metrics?.active_users?.filter(
            (user) => project.contributors?.some((contributor) => contributor.id === user.id),
        ).length
    );

    return (
        <article className="mu-project-preview">
            <div className="mu-project-preview__status">
                <BadgeComponent
                    status={getStatus(project.start_date, project.end_date, project.archived_at)}
                />
            </div>
            {!!notificationCount && (
                <div className="mu-project-preview__badge">
                    {notificationCount}
                </div>
            )}
            <div className={classWithModifiers('mu-project-preview__pictures', {'no-pictures': !cover_image_url})}>
                { cover_image_url ? (
                    <img
                        src={cover_image_url}
                        alt={`${project.name} project's preview`}
                        className="mu-project-preview__img"
                    />
                ) : (
                    <MuSvg className="mu-project-preview__svg" SvgComp={DesignThinking} />
                )}
            </div>
            <section className="mu-project-preview__content">
                <h2 className="mu-project-preview__title">
                    <TextTranslation translationProp={project.titles} fallback={project.name} />
                </h2>
                {!!activeContributorsSize && (
                    <div className="mu-project-preview__organization-name">
                        {i18n.t('global.participant', {count: activeContributorsSize})}
                    </div>
                )}
                {Client.isTeamMemberOfProject(project) || project.profile?.accepted_at ? (
                    <MuButton
                        className="mu-project-preview__button"
                        handleClick={handleClick}
                        label={i18n.t('global.join')}
                    />
                ) : (
                    <div className="mu-project-preview__message">
                        {i18n.t('projects.pendingCandidacy')}
                    </div>
                )}
            </section>
        </article>
    );
};

export default ProjectPreviewBlock;
