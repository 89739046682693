import { Cell, Point } from 'mushin-node-moodboard';

type Rule = {
  axe: 'x' | 'y';
  value: number;
  side: 1 | -1;
  parent: {cell: 'container';side: 1 | -1;} | {cell: Cell;side: 1 | -1;};
};

/**
 * given and list of rules, a cell to check against and a threshold,
 *
 * return a list of rules that are close enought to apply to the cell
 */
export const findMatchingSnappingRules = (rules: Rule[], axe: 'x' | 'y', cellToSnap: Cell, threshold = 3) => {
    /**
   * find the closest rules
   * group multiple ones if possible
   */
    const res = rules.reduce((best, rule) => {
        const d = rule.value - (rule.side === 1 ? cellToSnap.max : cellToSnap.min)[axe];

        if (!best || Math.abs(best.d) > Math.abs(d)) return { d, rules: [rule] };

        if (Math.abs(best.d - d) < 0.5) return { d, rules: [...best.rules, rule] };

        return best;
    }, { d: Infinity, rules: [] });

    /**
   * if the delta is larger than the threshold, don't snap
   * otherwise return the delta
   * and the rule
   */
    return Math.abs(res.d) > threshold ? { d: 0, rules: [] } : res;
};

/**
 * generate the rules for snapping with the container
 */
export const generateContainerRules = (axe: 'x' | 'y', containerDimension: Point, margin: number): Rule[] => [// snap to the container min
    { axe, value: margin, parent: { cell: 'container', side: -1 }, side: -1 }, // snap to the container max
    { axe, value: containerDimension[axe] - margin, parent: { cell: 'container', side: 1 }, side: 1 }];

/**
 * generate the rules for snapping with a single cell
 */
export const generateCellRules = (axe: 'x' | 'y', cell: Cell, margin: number) => [// snap max to max
    { axe, value: cell.max[axe], side: 1, parent: { cell, side: 1 } }, // snap min to min
    { axe, value: cell.min[axe], side: -1, parent: { cell, side: -1 } }, // snap max to min - margin
    { axe, value: cell.min[axe] - margin, side: 1, parent: { cell, side: -1 } }, // snap min to max + margin
    { axe, value: cell.max[axe] + margin, side: -1, parent: { cell, side: 1 } }];

/**
 * generate the rules for snapping with a
 */
export const generateCellsRules = (axe: 'x' | 'y', cells: Array<Cell>, margin: number): Rule[] => flat(cells.map((cell) => generateCellRules(axe, cell, margin)));

const flat = (items) => [].concat(...items);
