import React, { useEffect, useState } from 'react';

import {
    Note,
    updateNote,
    ProfilesQueryParams,
    useProfile,
    useAlbum,
    ChatRequests,
    markTimelineItemAsRead,
} from 'mushin-redux-store';

import moment from 'moment';
import Delta from 'quill-delta';
import i18n from 'i18next';
import { useParams } from 'react-router-dom';
import Tags from '../Tags/Tags';
import Comment from '../Comment/Comment';
import NoteReaction from '../Reaction/NoteReaction';
import TextIcon from '../TextIcon/TextIcon';
import EditableTitle from '../EditableTitle/EditableTitle';
import Discussion from '../Discussion/Discussion';
import { ProfileAvatar } from '../UserCard/ProfileAvatar';
import { addTagToNote, removeTagFromNote, useNoteActions } from '../../Helpers/noteHelpers';
import UserDetailsPopoverHook from '../UserDetailsPopover/UserDetailsPopoverHook';
import QuillRichInput from '../QuillRichInput/QuillRichInput';
import { useSearchParams } from '../../Helpers/queryParser';
import { useQuillInputWithDelay } from '../../Helpers/FormsHelpers/hooks';
import { classWithModifiers } from '../../Helpers/css';
import MuIcon from '../MuIcon';
import ActionDropdownMenu from '../ActionDropdownMenu/ActionDropdownMenu';
import { useAppDispatch } from '../../Helpers/hooks';
import ImageViewQuestion from './ImageViewQuestion';
import { NOTE_MAX_LENGTH } from '../../constants';
import TextTranslation from '../Translation/TextTranslation';
import VideoFullscreen from '../VideoFullscreen/VideoFullscreen';
import { GalleryParams } from '../../Scenes/Gallery/types';

type Props = {
    note: Note;
    userMentionsQueryParams?: ProfilesQueryParams;
    setFullscreen?: React.Dispatch<React.SetStateAction<boolean>>;
    beforeDelete?: () => void;
    className?: string;
};

const ImageView: React.FC<Props> = ({ note, userMentionsQueryParams, setFullscreen, beforeDelete, className }) => {
    const params = useParams<GalleryParams>();
    const owner = useProfile(note.profile_id);
    const duplicatedBy = useProfile(note.duplicated_by);
    const noteAlbum = useAlbum(params.album_id);
    const [searchParams] = useSearchParams<{ comment?: string }>();
    const [unreadMessageIds, setUnreadMessageIds] = useState<string[]>([]);

    const dispatch = useAppDispatch();

    const actions = useNoteActions(note, noteAlbum, setFullscreen, beforeDelete);

    const [noteDesc, setNoteDesc, resetNoteDesc] = useQuillInputWithDelay(
        (value) => dispatch(updateNote(note?.id || '', { content: value })),
        new Delta(note?.content?.ops) || new Delta([]),
    );
    const [noteTitle, setNoteTitle] = useState(note.label);
    const [showTitleCounter, setShowTitleCounter] = useState(false);

    useEffect(() => {
        resetNoteDesc(new Delta(note?.content?.ops), false);
    }, [note?.content, resetNoteDesc]);

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;
        if (note.chat?.id) {
            ChatRequests.getNotification(note.chat?.id).then((res) => {
                const notificationIds: string[] = [];
                const messageIds: string[] = [];
                res.data.forEach((notification) => {
                    if (notification && !notification.seen) {
                        notificationIds.push(notification._id);
                        messageIds.push(...notification.objects.map((obj) => obj.object_id));
                    }
                });
                setUnreadMessageIds(messageIds);
                if (notificationIds.length) {
                    timeout = setTimeout(() => {
                        dispatch(markTimelineItemAsRead(notificationIds)).then();
                    }, 1000);
                }
            });
        }

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [dispatch, note.chat?.id]);

    if (!note) return null;

    const { label, created_at, hash_tags } = note;

    const addTag = (tag: string) => {
        dispatch(addTagToNote(note, tag));
    };

    const removeTag = (tag: string) => {
        dispatch(removeTagFromNote(note, tag));
    };

    const canEdit = note.permissions?.can_update;

    const firstActions = actions ? actions.slice(0, 2) : [];
    const moreActions = actions ? actions.slice(2) : [];

    const showInteractions = !!(note.albums?.length || note.questionnaire_id);

    return (
        <article className={classWithModifiers('mu-image-view', { 'no-chat': !showInteractions }, className)}>
            <section className="mu-image-view__details">
                {note.questionnaire_id && note.question_id && (
                    <ImageViewQuestion questionnaireId={note.questionnaire_id} questionId={note.question_id} />
                )}
                <div
                    className="mu-image-view__content-wrapper"
                    style={note.type !== 'TEXT' ? { backgroundImage: `url('${note.image_src.full}')` } : undefined}
                >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {note.type !== 'TEXT' ? (
                        note.type !== 'VIDEO' ? (
                            <img
                                src={note.image_src.full}
                                className="mu-image-view__content mu-image-view__content--picture"
                                alt={label}
                            />
                        ) : (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <VideoFullscreen
                                className="mu-image-view__content mu-image-view__content--picture"
                                videoUrl={note.video_url}
                                controls
                            />
                        )
                    ) : (
                        <div
                            className={classWithModifiers('mu-image-view__content', {
                                text: true,
                                'text-short': note.label.length < 50,
                                'text-long': note.label.length > 300,
                            })}
                        >
                            <TextTranslation translationProp={note.labels} fallback={note.label} center />
                        </div>
                    )}
                    <div className="mu-image-view__action-bar">
                        {firstActions.map(
                            (action) =>
                                !action.hidden && (
                                    <button
                                        key={action.icon}
                                        type="button"
                                        disabled={!!action.disabled}
                                        className={classWithModifiers('mu-image-view__action-bar-item', {
                                            disabled: !!action.disabled,
                                        })}
                                        onClick={action.handler}
                                    >
                                        <MuIcon className="mu-image-view__action-bar-item-icon" svgName={action.icon} />
                                        <div className="mu-action-bar__item-popover">{action.label}</div>
                                    </button>
                                ),
                        )}
                        {moreActions.length && (
                            <ActionDropdownMenu
                                items={moreActions}
                                toggle={
                                    <div className="mu-image-view__action-bar-item">
                                        <MuIcon className="mu-action-bar__item-icon" svgName="MoreOptions" />
                                    </div>
                                }
                                position="top"
                            />
                        )}
                    </div>
                </div>
                <div className="mu-image-view__title-wrapper">
                    {note.type !== 'TEXT' && (
                        <div>
                            <EditableTitle
                                defaultValue={note.label}
                                contentEditable={canEdit}
                                className="mu-image-view__title"
                                onBlur={(e: React.FocusEvent) => {
                                    setShowTitleCounter(false);
                                    const content = e.target.textContent || '';
                                    if (content.length > NOTE_MAX_LENGTH) {
                                        return;
                                    }
                                    if (note.label !== content) {
                                        dispatch(updateNote(note.id, { label: content || '' }));
                                    }
                                }}
                                onFocus={() => setShowTitleCounter(true)}
                                onInput={(e) => setNoteTitle(e.currentTarget.textContent || '')}
                                placeholder={i18n.t<string>('models.notes.label')}
                            />
                            {showTitleCounter && (
                                <div
                                    className={classWithModifiers('mu-add-images-modal__text-counter', {
                                        error: noteTitle.length > NOTE_MAX_LENGTH,
                                    })}
                                >
                                    {`${noteTitle.length} / ${NOTE_MAX_LENGTH}`}
                                </div>
                            )}
                        </div>
                    )}
                    <NoteReaction
                        name="favorite"
                        note={note}
                        className="mu-image-view__favorites"
                        contrast
                        horizontal
                    />
                </div>
                <Tags
                    tags={hash_tags}
                    handleRemoveTag={removeTag}
                    handleAddTag={addTag}
                    selectedTags={hash_tags || []}
                />
                <div className="mu-image-view__author">
                    {owner && (
                        <UserDetailsPopoverHook
                            id={owner.id}
                            hoveredElement={
                                <ProfileAvatar className="mu-image-view__author-avatar" userId={owner.id} />
                            }
                        />
                    )}
                    <div>
                        {owner && (
                            <div className="mu-image-view__created-by">
                                <UserDetailsPopoverHook
                                    id={owner.id}
                                    hoveredElement={
                                        <span className="mu-image-view__author-name">{`${owner.name}`}</span>
                                    }
                                    position="bottom"
                                    inline
                                />
                                {` ${i18n.t('noteView.noteDetails.addedThisIdea')}`}
                                {duplicatedBy && (
                                    <>
                                        {` (${i18n.t('global.sharedBy')} `}
                                        <UserDetailsPopoverHook
                                            id={duplicatedBy.id}
                                            hoveredElement={
                                                <span className="mu-image-view__author-name">
                                                    {`${duplicatedBy.name}`}
                                                </span>
                                            }
                                            position="bottom"
                                            inline
                                        />
                                        )
                                    </>
                                )}
                            </div>
                        )}
                        <div className="mu-image-view__metadata">
                            <TextIcon icon="Calendar" label={<time>{moment(created_at).calendar()}</time>} />
                            {note.type !== 'TEXT' && note.type !== 'VIDEO' && (
                                <TextIcon
                                    icon="Crop"
                                    label={
                                        note.image
                                            ? `${note.image.width} x ${note.image.height}`
                                            : `${note.image_width} x ${note.image_height}`
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="mu-image-view__desc">
                    <QuillRichInput
                        value={noteDesc}
                        setValue={(delta) => setNoteDesc(delta)}
                        placeholder={
                            canEdit ? i18n.t<string>('global.addDescription') : i18n.t<string>('global.noDescription')
                        }
                        theme="snow"
                        className="mu-textarea-rich-input"
                        toolbar={[
                            ['bold', 'italic', 'strike', 'underline', 'link'], // toggled buttons
                            [{ list: 'bullet' }, { list: 'ordered' }],
                        ]}
                        readOnly={!canEdit}
                    />
                </div>
                {showInteractions && (
                    <div className="mu-image-view__interactions">
                        <NoteReaction name="like" note={note} contrast size="large" />
                        <NoteReaction name="love" note={note} contrast size="large" />
                        <NoteReaction name="instructive" note={note} contrast size="large" />
                    </div>
                )}
            </section>
            {showInteractions && (
                <div className="mu-image-view__chat">
                    <h2 className="mu-image-view__chat-title">{i18n.t('models.comments.title')}</h2>
                    <Discussion
                        entity_id={note.id}
                        entity_type="NOTE"
                        goToMessage={searchParams.comment}
                        userMentionsQueryParams={userMentionsQueryParams}
                        renderMessage={(id) => (
                            <Comment
                                key={id}
                                id={id}
                                userMentionsQueryParams={userMentionsQueryParams}
                                unread={unreadMessageIds.includes(id)}
                            />
                        )}
                        contrast
                    />
                </div>
            )}
        </article>
    );
};

export default ImageView;
