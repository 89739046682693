import React from 'react';
import i18n from 'i18next';
import {useParams} from 'react-router-dom';
import {useProject} from 'mushin-redux-store';
import SynthesisLayout from '../../SynthesisLayout';
import HeaderSynthesis from '../../HeaderSynthesis';
import PanelSynthesisSolution from '../Dashboard/SyntheComponentsDash/PanelSynthesisSolution';

const SynthesisSolutions: React.FC = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const project = useProject(projectId);

    if (!project) return null;

    return (
        <SynthesisLayout project={project} activeTab="solutions">
            <div className="mu-app-layout__scroll mu-synthesis">
                <HeaderSynthesis
                    activeMode="Solutions"
                    text={i18n.t<string>('SynthesisSolutions.text')}
                    span={i18n.t<string>('SynthesisSolutions.span')}
                    textButton={i18n.t<string>('SynthesisSolutions.button')}
                />
                <PanelSynthesisSolution />
            </div>
        </SynthesisLayout>
    );
};
export default SynthesisSolutions;
