import React from 'react';
import {useNotes} from 'mushin-redux-store';
import {useLocation, useParams} from 'react-router-dom';
import NotesJustifiedGrid from '../../../../Components/NotesJustifiedGrid/NotesJustifiedGrid';
import {GalleryParams} from '../../types';
import {useAppDispatch, useAppSelector} from '../../../../Helpers/hooks';
import {deselectNoteInBucket, selectNotesInBucket} from '../../../../Redux/reducers/noteBucketSlice';
import {getNoteUrl} from '../../../../Helpers/noteHelpers';

type Props = {
    noteIds: string[];
    canMoveItem?: boolean;
    moveItem?: (id: string, params: {idBefore?: string, idAfter?: string}) => Promise<void>;
    noteIdsUnread?: string[];
}

const GalleryJustifiedGrid: React.FC<Props> = ({ noteIds, canMoveItem, moveItem, noteIdsUnread }) => {
    const params = useParams<GalleryParams>();
    const notes = useNotes(noteIds);
    const selectedNoteIds = useAppSelector((state) => state.noteBucket.noteIds);

    const dispatch = useAppDispatch();
    const location = useLocation();

    return notes ? (
        <NotesJustifiedGrid
            uploadingNoteIds={[]}
            notes={notes}
            isItemSelected={(note) => selectedNoteIds.includes(note.id)}
            onSelectItems={(_notes) => dispatch(selectNotesInBucket(_notes.map((note) => note.id)))}
            onDeselectItem={(note) => dispatch(deselectNoteInBucket(note.id))}
            getItemClickHandler={(note) => {
                const url = getNoteUrl(note.id, params);
                return `${url}${location.search}`;
            }}
            canMoveItem={canMoveItem}
            moveItem={moveItem}
            showItemInteractions={!!(params.album_id || params.projectId || params.questionnaireId)}
            showItemInfos
            showItemFavorite
            showItemActions
            noteIdsUnread={noteIdsUnread}
        />
    ) : null;
};

export default GalleryJustifiedGrid;
