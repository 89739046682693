import React, { useEffect, useRef, useState } from 'react';

import {MoodboardDrawer, Board} from 'mushin-node-moodboard';
import { ContextualActionBar } from './ContextualActionBar';
import { SplitCursor } from './SplitCursor';

import { boxIntersection, setContent } from '../layoutUtils';
import TextContent from './TextContent';
import { clamp } from '../../../../../Helpers/math';
import {ActionMode} from './actionButtons/common';
import {Camera} from '../../type';
import {useAppDispatch, useAppSelector} from '../../../../../Helpers/hooks';
import {setEditorMode} from '../../../../../Redux/reducers/moodboardEditorSlice';

const computeCellBox = (camera, cell, ratio) => ({
    top: (cell.min.y * camera.a + camera.b.y) * ratio,
    left: (cell.min.x * camera.a + camera.b.x) * ratio,
    width: (cell.max.x - cell.min.x) * camera.a * ratio,
    height: (cell.max.y - cell.min.y) * camera.a * ratio,
});

const isValidActionBarPosition = (targetPosition, currentActionBar, cellBox, containerDimensions) => {
    const width = currentActionBar.offsetWidth;
    const height = currentActionBar.offsetHeight;

    switch (targetPosition) {
        case 'top':
            return height < cellBox.top;
        case 'bottom':
            return height < containerDimensions.height - (cellBox.top + cellBox.height);
        case 'left':
            return width < cellBox.left;
        case 'right':
            return width < containerDimensions.width - (cellBox.left + cellBox.width);
        default:
            return false;
    }
};

const computeActionBarPosition = (targetPosition, currentActionBar, cellBox, containerDimensions) => {
    const width = currentActionBar.offsetWidth;
    const height = currentActionBar.offsetHeight;

    const position = {};

    // Compute horizontal position
    switch (targetPosition) {
        case 'top': case 'bottom':
            // Sets middle position or min and max position (should not overflow the container)
            position.left = clamp(cellBox.width / 2 - width / 2, -cellBox.left, containerDimensions.width - width - cellBox.left);
            break;
        case 'left':
            position.left = -width;
            break;
        case 'right':
            position.left = cellBox.width;
            break;
        default:
    }

    // Compute vertical position
    switch (targetPosition) {
        case 'top':
            position.top = -height;
            break;
        case 'bottom':
            position.top = cellBox.height;
            break;
        case 'left': case 'right':
            // Sets middle position or min and max position (should not overflow the container)
            position.top = clamp(cellBox.height / 2 - height / 2, -cellBox.top, containerDimensions.height - height - cellBox.top);
            break;
        default:
    }

    return position;
};

const createSetContentHandler = (board, setBoard, selectedCell) => (content) => {
    setBoard(setContent(board, selectedCell, content));
};

const createRemoveHandler = (board, setBoard, selectedCell) => () => {
    const cells = { ...board.cells, [selectedCell]: undefined };
    const contents = { ...board.contents, [selectedCell]: undefined };

    // update the board
    setBoard({
        ...board,
        cells,
        contents,
        ids: board.ids.filter((id) => id !== selectedCell),
    });
    // back to layout mode
};

const createMoveZHandler = (board, setBoard, selectedCell, direction) => {
    const cell = board.cells[selectedCell];

    if (!cell) return null;

    const intersect = board.ids.filter((id) => boxIntersection(cell, board.cells[id]));
    const i = intersect.indexOf(selectedCell);

    const swapWith = intersect[i + direction];

    if (!swapWith) return null;

    return () => setBoard({
        ...board,
        ids: board.ids.map((id) => (id === selectedCell && swapWith) || (id === swapWith && selectedCell) || id),
    });
};

type Props = {
    drawer: MoodboardDrawer;
    containerDimensions: {width: number;height: number;};
    board: Board;
    setBoard: (arg0: Board) => void;
    selectedCell: string;
    camera: Camera;
    ratio: number;
}

export const SelectedCellOverlay: React.FC<Props> = (props) => {
    const mode = useAppSelector((state) => state.moodboardEditor.present.mode) as ActionMode;
    const [actionBarPosition, setActionBarPosition] = useState();
    const actionBar = useRef();
    const cell = props.board.cells[props.selectedCell];
    const content = props.board.contents[props.selectedCell];
    const cellBox = computeCellBox(props.camera, cell, props.ratio);

    const dispatch = useAppDispatch();

    const getBestActionBarPosition = () => {
        for (const targetPosition of ['top', 'bottom', 'right', 'left']) {
            if (isValidActionBarPosition(targetPosition, actionBar.current, cellBox, props.containerDimensions)) {
                return computeActionBarPosition(targetPosition, actionBar.current, cellBox, props.containerDimensions);
            }
        }
        return computeActionBarPosition('top', actionBar.current, cellBox, props.containerDimensions);
    };

    useEffect(() => {
        if (actionBar.current) {
            setActionBarPosition(getBestActionBarPosition());
        }
    }, [actionBar, props.camera, cell, props.ratio]);

    return (
        <div
            className="blSelectCellOverlay"
            style={{
                position: 'absolute',
                top: `${cellBox.top}px`,
                left: `${cellBox.left}px`,
                width: `${cellBox.width}px`,
                height: `${cellBox.height}px`,
            }}
        >
            <div>
                <div className="blCellOverlay-corner blCellOverlay-corner--select" />
                <div className="blCellOverlay-corner blCellOverlay-corner--select" />
                <div className="blCellOverlay-corner blCellOverlay-corner--select" />
                <div className="blCellOverlay-corner blCellOverlay-corner--select" />
            </div>
            <div>
                <div className="blCellOverlay-border" />
                <div className="blCellOverlay-border" />
                <div className="blCellOverlay-border" />
                <div className="blCellOverlay-border" />
            </div>
            {content?.type === 'mixed' && mode === 'cell' && (
                <SplitCursor cell={cell} content={content} ratio={props.ratio} camera={props.camera} />
            )}

            <div
                ref={actionBar}
                className="blActionBar--wrapper"
                style={actionBarPosition ? {
                    position: 'absolute',
                    top: `${actionBarPosition.top}px`,
                    left: `${actionBarPosition.left}px`,
                } : {
                    visibility: 'hidden',
                }}
            >
                <ContextualActionBar
                    drawer={props.drawer}
                    content={content}
                    cell={cell}
                    mode={mode}
                    remove={createRemoveHandler(props.board, props.setBoard, props.selectedCell)}
                    setMode={(_mode) => dispatch(setEditorMode(_mode))}
                    setContent={createSetContentHandler(props.board, props.setBoard, props.selectedCell)}
                    moveForward={createMoveZHandler(props.board, props.setBoard, props.selectedCell, -1)}
                    moveBackward={createMoveZHandler(props.board, props.setBoard, props.selectedCell, 1)}
                />
            </div>

            <TextContent {...props} />
        </div>
    );
};
