import React, {useState} from 'react';
import {remindQuestionnaire, useProfile, useQuestionnaire} from 'mushin-redux-store';
import i18next from 'i18next';
import Modal from '../../../../Components/Modal';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import {ModalBody} from '../../../../Components/Modal/ModalBody';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {useAppDispatch} from '../../../../Helpers/hooks';
import TextTranslation from '../../../../Components/Translation/TextTranslation';
import MuButtonWithLoader from '../../../../Components/MuButtonWithLoader';

type Props = {
    profileId: string;
    missionId: string;
    onClose: () => void;
};

const CreateReminderModal: React.FC<Props> = ({ profileId, missionId, onClose }) => {
    const profile = useProfile(profileId);
    const questionnaire = useQuestionnaire(missionId);
    const [message, setMessage] = useState('');

    const dispatch = useAppDispatch();

    const name = profile?.name || '';

    return (
        <Modal handleRemoveModal={onClose}>
            <ModalHeader
                title={(
                    <TextTranslation translationProp={questionnaire?.titles} fallback={questionnaire?.title || ''} />
                )}
                icon="Bell"
            />
            <ModalBody noScroll>
                <div className="mu-textarea-fixed-text">
                    <div className="mu-textarea-fixed-text__content">
                        {`${i18next.t('global.hello', {lng: profile?.user?.locale})} `}
                        <span className="mu-textarea-fixed-text__important">{name}</span>
                        ,
                    </div>
                    <textarea
                        className="mu-textarea"
                        autoFocus
                        rows={5}
                        style={{paddingTop: '50px'}}
                        value={message}
                        onChange={(event) => setMessage(event.currentTarget.value)}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <MuButtonWithLoader
                    handleClick={async () => {
                        if (profile?.user?.id) {
                            await dispatch(remindQuestionnaire(missionId, profile.user.id, message));
                            onClose();
                        }
                    }}
                    disabled={!message.trim() || !profile?.user?.id}
                    label={i18next.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>
    );
};

export default CreateReminderModal;
