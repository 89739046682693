import React, {useState} from 'react';
import Delta from 'quill-delta';
import { TranslatedProp } from 'mushin-redux-store';
import { classWithModifiers } from '../../../Helpers/css';
import MuIcon from '../../MuIcon';
import DeltaTruncated from '../../DeltaTruncated/DeltaTruncated';
import QuillRichInput from '../../QuillRichInput/QuillRichInput';
import QuillTranslation from '../../Translation/QuillTranslation';
import TextTranslation from '../../Translation/TextTranslation';

type Props = {
    translationTitle?: {
        translationProp?: TranslatedProp<string>;
        fallback: string
    };
    translationContent: {
        translationProp?: TranslatedProp<Delta>;
        fallback: Delta
    };
    placeholder?: React.ReactNode;
    canEditEntity: boolean;
    onSubmit: (value: Delta) => Promise<void>;
    className?: string;
    truncatedSize?: 'small' | 'medium' | 'large';
    secondary?: boolean;
}

const DeltaPanel: React.FC<Props> = ({
    translationTitle,
    translationContent,
    placeholder,
    canEditEntity,
    onSubmit,
    className,
    truncatedSize,
    secondary = false,
}) => {
    const [editValue, setEditValue] = useState(
        new Delta(translationContent.translationProp?.original || translationContent.fallback || []),
    );
    const [edit, setEdit] = useState(false);

    const editButtons = (noTitle = false) => canEditEntity && (
        <div className={classWithModifiers('mu-goal__edit-buttons', {'no-title': noTitle})}>
            <button
                className={classWithModifiers('mu-edit-button', {valid: edit}, 'mu-goal__edit')}
                type="button"
                onClick={() => {
                    if (edit) {
                        onSubmit(editValue).then(() => setEdit(false));
                    } else {
                        setEdit(true);
                    }
                }}
            >
                <MuIcon className="mu-edit-button__icon" svgName={edit ? 'Check' : 'Edit'} />
            </button>
            {edit && (
                <button
                    className={classWithModifiers('mu-edit-button', 'error', 'mu-goal__edit')}
                    type="button"
                    onClick={() => setEdit(false)}
                >
                    <MuIcon className="mu-edit-button__icon" svgName="Remove" />
                </button>
            )}
        </div>
    );

    return (
        <section
            className={classWithModifiers('mu-goal', {secondary, untitled: !translationTitle}, className)}
        >
            {translationTitle ? (
                <h3 className="mu-goal__title">
                    <TextTranslation
                        translationProp={translationTitle.translationProp}
                        fallback={translationTitle.fallback}
                        additionalTitle={editButtons()}
                    />
                </h3>
            ) : editButtons(true)}
            {/* eslint-disable-next-line no-nested-ternary */}
            {edit ? (
                <QuillRichInput
                    value={editValue}
                    setValue={(value) => setEditValue(value)}
                    theme="snow"
                    className="mu-textarea-rich-input"
                    toolbar={[
                        ['bold', 'italic', 'strike', 'underline'], // toggled buttons
                        [{ header: 1 }, { header: 2 }], // custom button values
                        [{ list: 'bullet' }],
                        [{ header: [1, 2, 3, false] }],
                    ]}
                />
            ) : truncatedSize ? (
                <DeltaTruncated
                    translationContent={translationContent}
                    placeholder={placeholder}
                    size={truncatedSize}
                    secondary={secondary}
                />
            ) : (
                <QuillTranslation
                    translationProp={translationContent.translationProp}
                    fallback={translationContent.fallback}
                    className="mu-rich-input"
                    theme=""
                />
            )}
        </section>
    );
};
export default DeltaPanel;
