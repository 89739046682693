import React, { useEffect, useRef } from 'react';

type Props = {
    videoUrl: string;
    className?: string;
    controls?: boolean;
};

const VideoFullscreen: React.FC<Props> = ({
    videoUrl,
    className,
    controls = false,
}) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const handleOpenVideo = () => {
        if (!videoRef.current || controls) return;
        videoRef.current.requestFullscreen().then();
    };

    useEffect(() => {
        if (controls) return () => { /* */ };
        const node = videoRef.current;
        const handler = () => {
            node?.pause();
        };

        node?.addEventListener('fullscreenchange', handler);
        return () => {
            node?.removeEventListener('fullscreenchange', handler);
        };
    }, [controls]);

    return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
            className={className}
            src={videoUrl}
            ref={videoRef}
            onClick={handleOpenVideo}
            controls={controls}
        />
    );
};

export default VideoFullscreen;
