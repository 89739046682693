import React from 'react';
import {useAlbum, useTimelineReadCount} from 'mushin-redux-store';
import i18n from 'i18next';
import {Link} from 'react-router-dom';
import {classWithModifiers} from '../../Helpers/css';
import AlbumDropdownMenu from '../AlbumDropdownMenu/AlbumDropdownMenu';
import {MuSvg} from '../MuSvg';
import Cluster from '../../../assets/icons/Cluster.svg';
import MuIcon from '../MuIcon';

type Props = {
    albumId: string;
    withPictures?: boolean;
    noActions?: boolean;
};

const ClusterCard: React.FC<Props> = ({
    albumId,
    withPictures = true,
    noActions = false,
}: Props) => {
    const album = useAlbum(albumId);
    const unreadCount = useTimelineReadCount({is_contributor: true}, {
        projectId: album?.project_id,
        albumId,
    });

    if (!album) return null;
    const {
        label,
        cover_note,
    } = album;

    const url = `/projects/${album.project_id}/albums/${albumId}`;

    return (
        <article
            className={classWithModifiers('mu-cluster-card',
                [{'without-pictures': !withPictures}]
            )}
        >
            {!noActions && (
                <div className="mu-cluster-card__more-options">
                    <AlbumDropdownMenu album={album} />
                </div>
            )}
            <div className="mu-cluster-card__status">
                <MuIcon className="mu-cluster-card__status-icon" svgName="Bulb" />
                {` ${album.notes_count} ${i18n.t('album.ideas.1._', {count: album.notes_count})}`}
            </div>
            {withPictures && (
                <Link
                    className="mu-cluster-card__button"
                    to={url}
                >
                    <div className={
                        classWithModifiers('mu-cluster-card__pictures', {'no-pictures': !cover_note?.thumbnail.thumb})
                    }
                    >
                        {cover_note?.thumbnail.thumb ? (
                            <img
                                src={cover_note.thumbnail.thumb}
                                alt={`${label} album preview`}
                                className="mu-cluster-card__img"
                            />
                        ) : (
                            <div className="mu-cluster-card__iconlabel">
                                <MuSvg className="mu-cluster-card__icon" SvgComp={Cluster} />
                            </div>
                        )}
                    </div>
                    <section className="mu-cluster-card__content">
                        <h2 className="mu-cluster-card__title">
                            {label}
                            {!!unreadCount && <div className="mu-cluster-card__notif" />}
                        </h2>
                    </section>
                </Link>
            )}
        </article>
    );
};

export default ClusterCard;
