import React from 'react';
import {NavLink} from 'react-router-dom';
import {classWithModifiers} from '../../Helpers/css';

export type TabsList = {
    key: string;
    to: string;
    label: string;
    active?: boolean;
}[];

type Props = {
    className?: string;
    tabs: TabsList;
}

const Tabs: React.FC<Props> = ({ className, tabs }) => (
    <div className={classWithModifiers('mu-tabs', undefined, className)}>
        {tabs.map((tab) => (
            <NavLink
                key={tab.key}
                to={tab.to}
                end
                className={({ isActive: defaultIsActive }) => (
                    classWithModifiers(
                        'mu-tabs__item',
                        {active: tab.active === undefined ? defaultIsActive : tab.active},
                    )
                )}
            >
                {tab.label}
            </NavLink>
        ))}
    </div>
);

export default Tabs;
