import { useCurrentOrganization, User } from 'mushin-redux-store';
import React, { useState } from 'react';
import i18n from 'i18next';
import TextIcon from '../../../TextIcon/TextIcon';
import Client from '../../../../Services/Client';
import DropdownMenu from '../../../DropdownMenu/DropdownMenu';
import UserMenuItem from './UseMenuItem';
import MuIcon from '../../../MuIcon';
import { classWithModifiers } from '../../../../Helpers/css';

type Props = {
    loggedUser: User;
    logout: () => void;
};

const UserMenu: React.FC<Props> = ({ loggedUser, logout }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const loggedUserFullName = loggedUser.name;

    const organization = useCurrentOrganization();

    return (
        <DropdownMenu
            isOpen={isMenuOpen}
            setIsOpen={setMenuOpen}
            position="bottom"
            align="center"
            toggle={
                <div className="mu-main-menu__user-menu">
                    <div className="mu-main-menu__user-menu-avatar mu-user-avatar">
                        <img
                            src={loggedUser.avatar_url}
                            alt={loggedUser.mention_slug}
                            className="mu-user-avatar__img"
                        />
                    </div>
                    <div className="mu-main-menu__user-menu-name">{loggedUser.name}</div>
                    <MuIcon className="mu-main-menu__user-menu-icon" svgName="ArrowDown" />
                </div>
            }
        >
            <div className="settings-nav-dropdown__category-header">
                <div className="settings-nav-dropdown__category-avatar mu-user-avatar">
                    <img
                        src={loggedUser.avatar_url || '/assets/images/defaultAvatar.png'}
                        alt={loggedUser.name}
                        className={`${classWithModifiers('mu-user-avatar__img', 'large')}`}
                    />
                </div>
                <div className="settings-nav-dropdown__category-infos">
                    <h1 className="settings-nav-dropdown__category-title">{loggedUserFullName}</h1>
                    <h2 className="settings-nav-dropdown__category-subtitle">{loggedUser.email}</h2>
                </div>
            </div>
            <UserMenuItem to="/account" icon="User" label="accounts.update.title" setMenuOpen={setMenuOpen} />
            <UserMenuItem
                to="/account/notifications"
                icon="Bell"
                label="accounts.notifications.title"
                setMenuOpen={setMenuOpen}
            />
            {Client.isOrganizationTeamMember() && (
                <>
                    <div className="settings-nav-dropdown__separator" />
                    {organization && (
                        <div className="settings-nav-dropdown__category-header">
                            <img
                                className="settings-nav-dropdown__category-avatar mu-user-avatar__img mu-user-avatar__img--large"
                                src={organization.logo_src}
                                alt={organization.name}
                            />
                            <div className="settings-nav-dropdown__category-infos">
                                <h1 className="settings-nav-dropdown__category-title">{organization.name}</h1>
                                <h2 className="settings-nav-dropdown__category-subtitle">{organization.description}</h2>
                            </div>
                        </div>
                    )}
                    {Client.isAllowedToEditOrganization() && (
                        <UserMenuItem
                            to="/organizations/current/"
                            icon="Cogwheel"
                            label="components.navbar.dropdown.administration"
                            setMenuOpen={setMenuOpen}
                        />
                    )}
                    <UserMenuItem
                        to="/organizations/current/members"
                        icon="Group"
                        label="global.team"
                        setMenuOpen={setMenuOpen}
                    />
                </>
            )}
            <li className="mu-dropdown-menu__item">
                <button
                    type="button"
                    onClick={() => {
                        logout();
                        setMenuOpen(false);
                    }}
                >
                    <TextIcon label={i18n.t('components.navbar.dropdown.logout')} icon="ClosePanel" />
                </button>
            </li>
        </DropdownMenu>
    );
};

export default UserMenu;
