import React from 'react';
import {useAppDispatch, useAppSelector} from '../../Helpers/hooks';
import modals, {ModalProps, ModalType} from './Components';
import {closeModal} from '../../Redux/reducers/modalsSlice';

type Props<T extends ModalType> = {
    type: T;
}

type Component<T extends ModalType> = React.FC<Props<T>>

const AppModalsItem = <T extends ModalType, >({type}: Parameters<Component<T>>[0]): ReturnType<Component<T>> => {
    const modalData = useAppSelector((state) => state.modals.modalData[type]) as ModalProps<T>;

    const dispatch = useAppDispatch();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return React.createElement(modals[type], {
        id: type,
        onClose: () => dispatch(closeModal(type)),
        ...modalData,
    });
};

export default AppModalsItem;
