import React from 'react';
import i18n from 'i18next';
import PanelSynthesisQuotes from '../../../../../Components/PanelSynthesis/PanelSynthesisQuotes';

const SectionPanelSynthesis: React.FC = () => {
    return (
        <div className="mu-synthesis__section-panel">
            <PanelSynthesisQuotes
                activeMode="Insights"
                title={i18n.t<string>('SectionPanelSynthesis.Insights.title')}
                description={i18n.t<string>('SectionPanelSynthesis.Insights.text')}
                createLabel={i18n.t<string>('SectionPanelSynthesis.Insights.label')}
                itemLabel={i18n.t<string>('SectionPanelSynthesis.Insights.span')}
            />
            <PanelSynthesisQuotes
                activeMode="Opportunities"
                title={i18n.t<string>('SectionPanelSynthesis.Opportunities.title')}
                description={i18n.t<string>('SectionPanelSynthesis.Opportunities.text')}
                createLabel={i18n.t<string>('SectionPanelSynthesis.Opportunities.label')}
                itemLabel={i18n.t<string>('SectionPanelSynthesis.Opportunities.span')}
            />
        </div>
    );
};
export default SectionPanelSynthesis;
