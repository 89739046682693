import {AvailableLocale, locales} from 'mushin-redux-store';
import React, {Dispatch, SetStateAction, useRef, useState} from 'react';
import i18n from 'i18next';
import Tag from '../Tags/Tag';
import Portal from '../Portal/Portal';
import {useComputedPosition} from '../../Helpers/position';
import ReactItemInput from '../ReactIntemInput/ReactItemInput';
import MuIcon from '../MuIcon';

type Props = {
    value: AvailableLocale[];
    setValue: Dispatch<SetStateAction<AvailableLocale[]>>;
}

const LanguageMultiSelect: React.FC<Props> = ({ value, setValue }) => {
    const [search, setSearch] = useState('');
    const [isOpen, setOpen] = useState(false);

    const [content, setContent] = useState<HTMLElement | null>(null);
    const container = useRef<HTMLDivElement>(null);
    const computedPosition = useComputedPosition('bottom', container.current, content, isOpen, 'center');

    const onTagClick = (tag: string): void => {
        setSearch('');
        setValue([...value, tag as AvailableLocale]);
    };

    const options = (Object.keys(locales) as AvailableLocale[]).filter(
        (locale) => (
            !value.includes(locale)
            && locales[locale].toLowerCase().includes(search.toLowerCase())
        )
    );

    return (
        <div ref={container}>
            <ReactItemInput
                emails={value}
                setEmails={setValue}
                search={search}
                setSearch={setSearch}
                getLabel={(
                    locale,
                    index,
                    removeLocale
                ) => {
                    return (
                        <div key={locale} className="react-multi-email__item">
                            {locales[locale as AvailableLocale]}
                            <button
                                className="react-multi-email__item-button"
                                type="button"
                                onClick={() => removeLocale(index)}
                            >
                                <MuIcon svgName="Close" />
                            </button>
                        </div>
                    );
                }}
                onFocus={() => setOpen(true)}
                onBlur={() => setTimeout(() => setOpen(false), 200)}
                placeholder={i18n.t('components.filters.search')}
            >
                {isOpen && !!options.length && (
                    <Portal>
                        <div
                            className="mu-toolbar__search-results"
                            ref={(element) => {
                                setContent(element);
                            }}
                            style={{
                                position: 'absolute',
                                visibility: computedPosition ? 'visible' : 'hidden',
                                ...computedPosition?.style,
                            }}
                        >
                            <ul className="mu-tags">
                                {options.map((localeKey) => (
                                    <li key={localeKey} className="mu-tags__tag">
                                        <Tag
                                            type="group"
                                            isSelected
                                            label={locales[localeKey as AvailableLocale]}
                                            onClick={() => onTagClick(localeKey)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Portal>
                )}
            </ReactItemInput>
        </div>
    );
};

export default LanguageMultiSelect;
