import React, {Dispatch, SetStateAction} from 'react';
import i18n from 'i18next';
import {classWithModifiers} from '../../../../Helpers/css';

export type ProjectSteps = {
    slug: string;
    title: string;
    description: string;
}[];

type Props = {
    steps: ProjectSteps;
    stepState: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
}

const AlbumStepSelector: React.FC<Props> = ({steps, stepState: [selectedStep, setSelectedStep]}) => {
    return (
        <>
            <div className="mu-input__label">{i18n.t('albums.selectStep')}</div>
            <div className="mu-project-steps">
                {steps.map((step) => (
                    <button
                        key={step.slug}
                        type="button"
                        className={classWithModifiers('mu-project-steps__item', {selected: selectedStep === step.slug})}
                        onClick={() => setSelectedStep(step.slug)}
                    >
                        <h4 className="mu-project-steps__item-title">{step.title}</h4>
                        <p>{step.description}</p>
                    </button>
                ))}
            </div>
        </>
    );
};

export default AlbumStepSelector;
