import React from 'react';
import MuIcon from '../MuIcon';
import { classWithModifiers } from '../../Helpers/css';

const CheckboxRadioBox: React.FC<{
    className?: string;
    checked: boolean;
    disabled?: boolean;
    uniqueSelection?: boolean;
    styleKey?: 'selection' | 'choice';
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({
    className,
    checked,
    disabled = false,
    uniqueSelection = false,
    styleKey = 'selection',
    onClick,
}) => {
    const modifiers: string[] = [styleKey];
    if (disabled) {
        modifiers.push('disabled');
    }
    if (checked) {
        modifiers.push('checked');
    }
    if (uniqueSelection) {
        modifiers.push('radio');
    }
    return (
        <div className={classWithModifiers('mu-checkbox__box', modifiers, className)} onClick={onClick}>
            {checked && styleKey === 'selection' && (
                <MuIcon className="mu-checkbox__check" svgName="Check" />
            )}
            {checked && styleKey === 'choice' && (
                <MuIcon className="mu-checkbox__check-choice" svgName={uniqueSelection ? "Circle" : "SquareFill"} />
            )}
        </div>
    );
};

export default CheckboxRadioBox;
