import React from 'react';
import i18n from 'i18next';
import Alert from '../../../../Components/Alert/Alert';

type Props = {
    type: 'note' | 'message';
    count: number;
    allCount: number;
    empty: () => void;
    selectAll: () => void;
};

const GallerySelectionBar: React.FC<Props> = (
    {
        type,
        count,
        allCount,
        empty,
        selectAll,
    }
) => (
    <div className="mu-gallery__selection-alert">
        <Alert label={i18n.t(`components.selection.${type}`, {count})}>
            <Alert.Action
                labelAction={i18n.t<string>('components.selection.deselect', {count})}
                handleAction={empty}
            />
            {count !== allCount && (
                <Alert.Action
                    labelAction={i18n.t<string>('components.selection.select_all')}
                    handleAction={selectAll}
                />
            )}
        </Alert>
    </div>
);

export default GallerySelectionBar;
