import React from 'react';
import i18n from 'i18next';
import SessionsLayout from '../Components/SessionsLayout/SessionsLayout';
import EmptyZone from '../../../Components/EmptyZone/EmptyZone';

const MaintenanceFallback: React.FC = () => (
    <div id="mushin-app">
        <SessionsLayout>
            <EmptyZone
                title={i18n.t('maintenance')}
            />
        </SessionsLayout>
    </div>
);

export default MaintenanceFallback;
