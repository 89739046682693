import React from 'react';
import {classWithModifiers} from '../../Helpers/css';
import PanelSynthesis from './PanelSynthesis';

type Props = {
    activeMode: 'Cluster' | 'Insights' | 'Opportunities' | 'Solutions';
    title?: string;
    description?: string;
    createLabel?: string;
    itemLabel?: string;
    onClick?: () => void;
};

const PanelSynthesisQuotes: React.FC<Props> = ({
    activeMode,
    title,
    description,
    createLabel,
    itemLabel,
    onClick,
}: Props) => {
    return (
        <PanelSynthesis
            activeMode={activeMode}
            title={title}
            description={description}
        >
            {createLabel && (
                <button
                    type="button"
                    className="mu-panel-synthesis__button"
                    onClick={onClick}
                >
                    <p className="mu-panel-synthesis__subtext">
                        {createLabel}
                        <span className={classWithModifiers('mu-panel-synthesis__item-label', activeMode)}>
                            {itemLabel}
                        </span>
                    </p>
                </button>
            )}
        </PanelSynthesis>
    );
};

export default PanelSynthesisQuotes;
