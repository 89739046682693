import React, {useEffect} from 'react';
import MuIcon from '../MuIcon';
import {classWithModifiers} from '../../Helpers/css';

type Props = React.PropsWithChildren<{
    className?: string;
    open: boolean;
    active?: boolean;
    onClose: () => void;
    previous: () => void;
    next: () => void;
}>

const CarouselModal: React.FC<Props> = (
    {className, children, open, active = true, onClose, previous, next},
) => {
    const withStopPropagation = (action: () => void) => (e: React.MouseEvent) => {
        action();
        e.stopPropagation();
    };

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowRight') {
                next();
            } else if (e.key === 'ArrowLeft') {
                previous();
            }
        };

        if (open && active) {
            window.addEventListener('keydown', onKeyDown);
        }

        // cleanup this component
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [open, previous, next, active]);

    if (!open) {
        return null;
    }

    return (
        <div
            className="mu-carousel-modal-overlay"
            role="presentation"
            onClick={onClose}
        >
            <button
                type="button"
                className="mu-carousel-modal__button mu-carousel-modal__close"
                onClick={withStopPropagation(onClose)}
            >
                <MuIcon className="mu-carousel-modal__button-icon" svgName="Close" />
            </button>
            <button
                type="button"
                className="mu-carousel-modal__button mu-carousel-modal__previous"
                onClick={withStopPropagation(previous)}
            >
                <MuIcon className="mu-carousel-modal__button-icon" svgName="LongArrowLeft" />
            </button>
            <section
                role="presentation"
                className={classWithModifiers('mu-carousel-modal', undefined, className)}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </section>
            <button
                type="button"
                className="mu-carousel-modal__button mu-carousel-modal__next"
                onClick={withStopPropagation(next)}
            >
                <MuIcon className="mu-carousel-modal__button-icon" svgName="LongArrowRight" />
            </button>
        </div>
    );
};

export default CarouselModal;
