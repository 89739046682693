import React, { useState } from 'react';
import {
    AvailableLocale,
    createGroup,
    EntityMember,
    Group,
    inviteUser,
    updateGroup,
    useCurrentOrganizationId
} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import InputText from '../../../../Components/InputText/InputText';
import MuButton from '../../../../Components/MuButton';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import SelectUsersDropdown from '../../../../Components/UsersListComponents/SelectUsersDropdown/SelectUsersDropdown';
import ManageMembers from '../../../../Components/UsersListComponents/AdminMembersList/ManageMembers';
import {useAppDispatch} from '../../../../Helpers/hooks';
import Client from '../../../../Services/Client';
import {openModal, openAlert} from '../../../../Redux/reducers/modalsSlice';

type Props = {
    onClose: () => void;
    group?: Group;
    canEdit?: boolean;
    canRemoveUser?: boolean;
};

const GroupCreationEditionModal: React.FC<Props> = (
    {
        onClose,
        group,
        canEdit = true,
        canRemoveUser = true,
    }
) => {
    const [organizationId] = useCurrentOrganizationId();
    const dispatch = useAppDispatch();

    const [entityTitle, setEntityTitle] = useState<string>((group && group.name) || '');
    const [hasUpdate, setHasUpdate] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<Array<EntityMember>>(group?.members || []);

    const id = group ? 'EditAGroup' : 'CreateAGroup';

    const handleClose = () => {
        if (hasUpdate) {
            return dispatch(
                openAlert({
                    type: 'danger',
                    title: i18n.t('modals.leaveAlert.title'),
                    icon: 'Warning',
                    confirmLabel: i18n.t('global.confirm'),
                    body: i18n.t('modals.leaveAlert.body'),
                    confirmAction: () => onClose(),
                })
            );
        }
        return onClose();
    };

    const handleChangeUsers = (selectedAuthors: Array<EntityMember>) => {
        setSelectedUsers(selectedAuthors);

        setHasUpdate(true);
    };

    const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEntityTitle(e.target.value);
        setHasUpdate(true);
    };

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={handleClose}>
            <ModalHeader
                title={i18n.t(`modals.${id}.title`)}
                subtitle={i18n.t(`modals.${id}.subtitle`)}
                icon="Public"
            />
            <InputText
                label={i18n.t(`modals.${id}.entityName`)}
                placeholder={i18n.t(`modals.${id}.placeholder`)}
                value={entityTitle}
                onChange={handleChangeTitle}
                contrast
            />
            <div className="mu-modal__label mu-modal__label--with-button">
                <h2>{i18n.t(`modals.${id}.addUsers`)}</h2>
                {Client.isAllowedToEditOrganization() && (
                    <MuButton
                        icon="UserAdd"
                        handleClick={() => {
                            dispatch(openModal('InviteProfilesModal', {
                                handleSubmit: async (emails: string[], locale: AvailableLocale) => {
                                    const users = await dispatch(inviteUser(emails, {reInvite: false, isContributor: false, locale}));
                                    setSelectedUsers((prevUsers) => {
                                        const nextSelectedUsers = [...prevUsers];
                                        users.forEach((user) => {
                                            if (!prevUsers.some(
                                                (prevUser) => prevUser.id === user.id)
                                            ) {
                                                nextSelectedUsers.push({
                                                    id: user.id,
                                                    role: 'regular',
                                                });
                                            }
                                        });
                                        return nextSelectedUsers;
                                    });
                                    return users;
                                },
                            }));
                        }}
                        label={i18n.t('modals.inviteUsers.title')}
                    />
                )}
            </div>
            <SelectUsersDropdown
                selectUser={(userId) => handleChangeUsers(
                    [...selectedUsers, {id: userId, role: 'regular'}]
                )}
                excludedUsersIds={selectedUsers.map((usr) => usr.id)}
            />
            <ManageMembers
                selectedUsers={selectedUsers}
                removeUser={
                    canRemoveUser
                        ? (usrId) => handleChangeUsers(selectedUsers.filter((usr) => usr.id !== usrId))
                        : undefined
                }
                roles
                setRoles={(users) => setSelectedUsers(users)}
                noOwner
            />
            <ModalFooter>
                {!canEdit && <h3 className="mu-modal__header-subtitle">{i18n.t('global.ownerOnly')}</h3>}
                <MuButton
                    handleClick={async () => {
                        if (group) {
                            await dispatch(updateGroup(group.id, {
                                name: entityTitle,
                                members: selectedUsers,
                            }));
                        } else {
                            await dispatch(createGroup({
                                organization_id: organizationId as string,
                                name: entityTitle,
                                members: selectedUsers,
                            }));
                        }
                        onClose();
                    }}
                    disabled={entityTitle.length < 1 || !canEdit}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>
    );
};

export default GroupCreationEditionModal;
