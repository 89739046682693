import React from 'react';
import {ActionBarSelect, ActionProps} from './common';

const lineWidthOptions = ['1', '2', '3', '4', '5', '6'].map((size) => ({ value: size, label: size }));

export const LineWidthSelectButton = ({
    content,
    setContent,
}: ActionProps) => (
    <ActionBarSelect
        classModifiers={['fontSize']}
        onChange={(opt) => setContent({ ...content, lineWidth: opt.value })}
        value={lineWidthOptions.find((o) => o.value === content.lineWidth)}
        options={lineWidthOptions}
        isSearchable={false}
    />
);
