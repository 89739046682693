import axios from 'axios';
import mime from 'mime';
import { splitFileExtAndName } from '../../Helpers/filename';
import parameterize from '../../Helpers/parameterize';

/* eslint-disable max-len */

const storageUri = 'https://mymushinpics.blob.core.windows.net';
const sasToken = '?sv=2017-11-09&ss=b&srt=sco&sp=rwlac&se=2050-08-07T18:26:15Z&st=2018-08-07T10:26:15Z&spr=https,http&sig=nD%2BLqSiZh6MHXwQcqFoEgU4fU1frSVCofBJ%2FWahIpK8%3D';
const defaultContainer = BUILD_NODE_ENV !== 'production' ? 'dev-pics' : 'pics';
const azureBlobUri = `${storageUri}/${defaultContainer}`;
const defaultBlobPrefix = 'original';

/* Service Http spécifique à AzureBlobStorage */
const AzureAxios = axios.create({
    baseURL: azureBlobUri,
    headers: {
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
        'x-ms-blob-type': 'BlockBlob',
    },
});

const getFileMimeType = (file) => (file.type === '' ? mime.getType(file.name) : file.type);

/**
 * @type {{put: (function(File, function(): void): Promise<any>)}}
 */
const AzureBlobService = {
    /**
   * @param file => le File du fileInput
   * @param onUploadProgress => fonction de callback à chaque progression de l'upload
   * @returns {Promise<any>} => Success => la photo a été uploadée, reject, une erreur est apparue
   */
    put: (file: File, onUploadProgress?: (e: Object) => void | null = () => {}): Promise<{src: string}> => new Promise((resolve, reject) => {
        // on récupère le nom et l'extention du fichier
        const {
            name,
            ext,
        } = splitFileExtAndName(file.name);
        // on crééer un fingerprint (nom rendu unique via le timestamp
        const fingerprint = parameterize(`${name}-${new Date().getTime()}`);
        // on définit le path dans le container
        const pathToImg = `${defaultBlobPrefix}/${fingerprint}.${ext}`;
        // on execute la requette http d'envoie du fichier avec le bon content-type
        AzureAxios.put(`${pathToImg}${sasToken}`, file, {
            headers: {
                'x-ms-blob-content-type': getFileMimeType(file),
            },
            onUploadProgress,
        }).then((azure_result) => {
            // on retourne les informations nécessaire à la création de la note par la suite
            resolve({
                azure_result,
                src: `${azureBlobUri}/${pathToImg}`,
                fingerprint,
                content_type: getFileMimeType(file),
            });
        }).catch((error) => {
            reject(error);
        });
    }),
};

export default AzureBlobService;
