import React, { useEffect, useRef, useState } from 'react';
import {
    analyseQuestionWithAI,
    getQuestionnaire,
    QuestionFree,
    Questionnaire,
    updateQuestion,
    useCurrentProject,
    useNotesQuery,
} from 'mushin-redux-store';
import i18n from 'i18next';
import MuIcon from '../../../Components/MuIcon';
import { useAppDispatch } from '../../../Helpers/hooks';
import MuButtonWithLoader from '../../../Components/MuButtonWithLoader';
import Client from '../../../Services/Client';
import MuButton from '../../../Components/MuButton';
import { useSearchParams } from '../../../Helpers/queryParser';
import { classWithModifiers } from '../../../Helpers/css';
import ShimmerChapter from '../../../Components/Shimmer/ShimmerChapter';
import ShimmerLine from '../../../Components/Shimmer/ShimmerLine';
import QuestionAnalysisLearningBadge from './QuestionAnalysisLearningBadge';

type Props = {
    questionnaire: Questionnaire;
    question: QuestionFree;
    query: ReturnType<typeof useNotesQuery>;
};

const autoSizeTextArea = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    // eslint-disable-next-line no-param-reassign
    e.currentTarget.style.height = 'auto';
    // eslint-disable-next-line no-param-reassign
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight + 8}px`;
};

const QuestionAnalysis: React.FC<Props> = ({ questionnaire, question, query }) => {
    const [searchParams, setSearchParams] = useSearchParams<{ learning?: string }>();
    const [edit, setEdit] = useState(false);
    const project = useCurrentProject();

    const ref = useRef<HTMLTextAreaElement>(null);

    const dispatch = useAppDispatch();

    const analysisRunning = question.synthesis && question.synthesis.progress.status !== 'finished';
    useEffect(() => {
        if (analysisRunning) {
            const timeout = setInterval(async () => {
                await dispatch(getQuestionnaire(questionnaire.id, undefined, true));
            }, 5000);

            return () => clearInterval(timeout);
        }

        return () => {
            /* */
        };
    }, [analysisRunning, dispatch, questionnaire.id]);

    const toggleLearningSearch = (value: string) => {
        setSearchParams((params) => ({
            ...params,
            learning: searchParams.learning === value ? undefined : value,
        }));
    };

    return (
        <div className="mu-question-result__analysis">
            <div className="mu-question-result__analysis-content">
                <div className="mu-question-result__analysis-icon">
                    <MuIcon svgName="Quote" />
                </div>
                <div className="mu-question-result__analysis-text">
                    {analysisRunning && !question.synthesis?.progress.description ? (
                        <div className="shimmer-wrapper">
                            <ShimmerChapter />
                        </div>
                    ) : edit ? (
                        <textarea
                            className="mu-question-result__analysis-textarea"
                            ref={ref}
                            onFocus={autoSizeTextArea}
                            onInput={autoSizeTextArea}
                            defaultValue={question.analysis}
                        />
                    ) : (
                        question.synthesis?.description ||
                        question.analysis || (
                            <div className="mu-question-result__analysis-placeholder">
                                {i18n.t('questionnaires.analyseWithAIPlaceholder')}
                            </div>
                        )
                    )}
                </div>
                <div className="mu-question-result__analysis-learnings">
                    {analysisRunning && !question.synthesis?.progress.learnings ? (
                        <div className="shimmer-wrapper">
                            <ShimmerLine height="xl" br theme="bg" />
                            <ShimmerLine height="xl" br theme="bg" />
                            <ShimmerLine height="xl" br theme="bg" />
                            <ShimmerLine height="xl" br theme="bg" />
                            <ShimmerLine height="xl" br theme="bg" />
                            <ShimmerLine height="xl" br theme="bg" />
                        </div>
                    ) : (
                        question.synthesis?.learnings?.map((learning) => (
                            <button
                                key={learning.slug}
                                type="button"
                                onClick={() => toggleLearningSearch(learning.slug)}
                                className={classWithModifiers('mu-question-result__analysis-learning', {
                                    active: searchParams.learning === learning.slug,
                                })}
                            >
                                {learning.label}
                                {analysisRunning && !question.synthesis?.progress.answers ? (
                                    <div className="shimmer-wrapper shimmer-wrapper--auto">
                                        <ShimmerLine width="sm" height="sm" theme="info" />
                                    </div>
                                ) : (
                                    question.synthesis?.totalCount && (
                                        <QuestionAnalysisLearningBadge
                                            learningCount={learning.count}
                                            totalCount={question.synthesis.totalCount}
                                            learningCountFiltered={
                                                query.aggregations?.learnings.find(
                                                    (learningAgg) => learningAgg.key === learning.slug,
                                                )?.doc_count || 0
                                            }
                                            totalCountFiltered={query.pager?.total_items}
                                        />
                                    )
                                )}
                            </button>
                        ))
                    )}
                </div>
            </div>
            <div className="mu-question-result__analysis-buttons">
                {edit ? (
                    <MuButton
                        classModifiers="important"
                        icon="Check"
                        handleClick={async () => {
                            if (ref.current) {
                                await dispatch(
                                    updateQuestion(questionnaire.id, question.id, {
                                        analysis: ref.current.value || '',
                                    }),
                                );
                            }
                            setEdit(false);
                        }}
                        disabled={!project || !Client.isAllowedToEditProject(project)}
                        label={i18n.t('global.validate')}
                    />
                ) : (
                    <>
                        <MuButton
                            icon="Edit"
                            handleClick={() => {
                                setEdit(true);
                                setTimeout(() => {
                                    if (ref.current) {
                                        ref.current.focus();
                                    }
                                }, 10);
                            }}
                            disabled={
                                !project ||
                                !Client.isAllowedToEditProject(project) ||
                                (analysisRunning && !question.synthesis?.progress.description)
                            }
                            label={i18n.t('global.edit')}
                        />
                        <MuButtonWithLoader
                            classModifiers="important"
                            icon="StarAI"
                            handleClick={async () => dispatch(analyseQuestionWithAI(questionnaire.id, question.id))}
                            disabled={!project || !Client.isAllowedToEditProject(project) || analysisRunning}
                            label={i18n.t('questionnaires.analyseWithAI')}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default QuestionAnalysis;
