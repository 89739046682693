import React from 'react';
import { classWithModifiers } from '../../Helpers/css';

type Props = {
  classModifiers?: Array<string>;
  id: string;
  label: string;
  isChecked?: boolean;
  handleChange: () => void;
};

const SwitchBtn = ({
    classModifiers,
    id,
    label,
    isChecked,
    handleChange,
}: Props) => (
    <div className={classWithModifiers('mu-switch-btn', classModifiers)}>
        <span className="mu-switch-btn__label">{label}</span>
        <input className="mu-switch-btn__input" id={id} type="checkbox" checked={isChecked} onClick={handleChange} />
    </div>
);

export default SwitchBtn;
