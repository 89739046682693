import React, {useState} from 'react';
import {ReactionsKeys} from 'mushin-redux-store';
import Popover from '../Popover/Popover';

type Props = {
    icons: ReactionsKeys[];
    renderIcon: (icon: ReactionsKeys, modifier?: string) => React.ReactElement;
    toggleReaction: (key: ReactionsKeys) => void;
}

const ReactionAllButton: React.FC<Props> = ({ icons, renderIcon, toggleReaction }) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Popover
            withDelay
            shadow
            position="top"
            hoveredElement={(
                <button
                    type="button"
                    className="mu-reaction__button"
                    onClick={() => {
                        setOpen((prevOpen) => !prevOpen);
                    }}
                >
                    {icons.map((icon) => renderIcon(icon, 'all'))}
                </button>
            )}
            isOpen={isOpen}
            setOpen={setOpen}
        >
            {icons.map((key) => (
                <button
                    key={key}
                    type="button"
                    className="mu-reaction__button"
                    onClick={() => {
                        toggleReaction(key);
                        setOpen(false);
                    }}
                >
                    {renderIcon(key, 'popover')}
                </button>
            ))}
        </Popover>
    );
};

export default ReactionAllButton;
