import React from 'react';
import {useDispatch} from 'react-redux';
import {Project} from 'mushin-redux-store';
import i18n from 'i18next';
import EmptyZone from '../../../../Components/EmptyZone/EmptyZone';
import AlbumPreviewBlock from '../../../../Components/AlbumPreviewBlock/AlbumPreviewBlock';
import Client from '../../../../Services/Client';
import GridButtonCard from '../../../../Components/GridButtonCard/GridButtonCard';
import {openModal} from '../../../../Redux/reducers/modalsSlice';
import {isProjectAdmin} from '../../../../Helpers/users';

type Props = {
    project: Project;
    albumIds: string[] | null;
};

const AlbumList: React.FC<Props> = ({
    project,
    albumIds,
}) => {
    const dispatch = useDispatch();

    if (!albumIds?.length) {
        if (Client.isAllowedToEditProject(project)) {
            return (
                <EmptyZone
                    handleClick={() => dispatch(openModal('CreateAlbum', {projectId: project.id}))}
                    title={i18n.t('albums.empty.title')}
                    subTitle={i18n.t('albums.empty.desc')}
                />
            );
        }
        return (
            <EmptyZone
                title={i18n.t('albums.empty.desc')}
            />
        );
    }

    return (
        <section className="mu-albums-list">
            {isProjectAdmin(project) && (
                <div className="mu-albums-list__item">
                    <GridButtonCard
                        icon="Plus"
                        onClick={
                            () => dispatch(openModal('CreateAlbum', {projectId: project.id}))
                        }
                        label={i18n.t('modals.CreateAlbum.createButton')}
                    />
                </div>
            )}
            {albumIds.map((albumId: string) => (
                <div key={albumId} className="mu-albums-list__item">
                    <AlbumPreviewBlock albumId={albumId} />
                </div>
            ))}
        </section>
    );
};

export default AlbumList;
