import React, {useState} from 'react';
import i18n from 'i18next';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import MuIcon from '../MuIcon';

type Props = {
    value: string | null | undefined;
    minDate?: Date;
    label: string;
    canEdit: boolean;
    onSubmit: (value: Date | null) => Promise<void>;
};

const DateEditor: React.FC<Props> = (
    {value, minDate, label, canEdit, onSubmit}
) => {
    const [editValue, setEditValue] = useState<Date | null>(value ? new Date(value) : null);
    const [edit, setEdit] = useState(false);

    return (
        <div className="mu-project-dashboard__footer-item">
            <h3 className="mu-project-dashboard__footer-item-title">
                {label}
                <MuIcon className="mu-project-dashboard__footer-item-icon" svgName="Calendar" />
            </h3>
            {edit ? (
                <div className="mu-project-dashboard__footer-item-content">
                    <DatePicker
                        className="mu-input-raw mu-input-raw--contrast mu-input-raw--date"
                        selected={editValue}
                        onChange={(date: Date) => setEditValue(date)}
                        minDate={minDate}
                        showMonthDropdown
                        showYearDropdown
                        dateFormat="PP"
                        placeholderText={i18n.t<string>('albums.undefinedPeriod')}
                    />
                    <button
                        className="mu-edit-button mu-goal__edit"
                        type="button"
                        onClick={async () => {
                            await onSubmit(editValue);
                            setEdit(false);
                        }}
                    >
                        <MuIcon className="mu-edit-button__icon" svgName="Check" />
                    </button>
                </div>
            ) : (
                <div className="mu-project-dashboard__footer-item-content">
                    {value
                        ? moment(value).format('L')
                        : i18n.t('albums.undefinedPeriod')}
                    {canEdit && (
                        <button
                            className="mu-edit-button mu-goal__edit"
                            type="button"
                            onClick={() => setEdit(true)}
                        >
                            <MuIcon className="mu-edit-button__icon" svgName="Edit" />
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default DateEditor;
