import { Handler } from './type';

import { nextZoom, scale } from '../injectCamera';

const ZOOM_REDUCER = 120;

export const onWheel: Handler = ({
    state,
    camera,
}, pointers, event) => {
    if (state.dragging) return;

    if (!event) return;

    const newCamera = scale(camera, nextZoom(camera.a, -event.deltaY / ZOOM_REDUCER), pointers[0]);

    event.preventDefault();

    return { camera: newCamera };
};
