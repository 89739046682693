/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
    Question,
    QuestionFree,
    QuestionItems,
    QuestionRating,
    QuestionType,
    QuestionUpdatePayload,
    Questionnaire,
    QuestionRanking, QuestionCover
} from 'mushin-redux-store';
import i18n from 'i18next';
import Delta from 'quill-delta';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Select from '../../Components/Select/Select';
import MuIcon from '../../Components/MuIcon';
import EditMultipleChoiceItems from './Components/EditMultipleChoiceItems';
import TextIcon from '../../Components/TextIcon/TextIcon';
import { useAppDispatch } from '../../Helpers/hooks';
import EditQuestionImage from './Components/EditQuestionImage';
import EditRatingQuestionOptions from './Components/EditRatingQuestionOptions';
import { AddModalImages } from '../AppModals/Components/AddImagesModal/AddIdeaModal';
import { uploadFile } from '../../Helpers/image';
import {openModal} from '../../Redux/reducers/modalsSlice';
import {classWithModifiers} from '../../Helpers/css';
import CheckboxRadio from '../../Components/Form/CheckboxRadio';
import QuillRichInput from '../../Components/QuillRichInput/QuillRichInput';
import { useQuillInputWithDelay } from '../../Helpers/FormsHelpers/hooks';
import EditFreeQuestionOptions from './Components/EditFreeQuestionOptions';
import QuestionFilterSelection from './Components/QuestionFilterSelection';
import CriteriaFilterSelection from './Components/CriteriaFilterSelection';
import { useQuestionnaireEditorContext } from './Components/QuestionnaireEditorContext';
import { isVideoFile } from '../../Helpers/video';
import EditQuestionVideo from './Components/EditQuestionVideo';

export const QUESTION_TYPE_CONFIG = {
    radios: {
        icon: 'UniqueSelection',
        i18nKey: 'questionEdition.radios',
    },
    checkboxes: {
        icon: 'MultipleSelection',
        i18nKey: 'questionEdition.checkboxes',
    },
    free: {
        icon: 'FreeAnswer',
        i18nKey: 'questionEdition.free',
    },
    rating: {
        icon: 'Star',
        i18nKey: 'questionEdition.rating',
    },
    ranking: {
        icon: 'Line',
        i18nKey: 'questionEdition.ranking',
    },
    description: {
        icon: 'FreeAnswer',
        i18nKey: 'questionEdition.description',
    },
};

const QUESTION_TYPES = Object.keys(QUESTION_TYPE_CONFIG) as (keyof typeof QUESTION_TYPE_CONFIG)[];

type Props = {
    question: Question,
    questionIndex: number;
    questionnaire: Questionnaire;
    updateQuestion: (questionPayload: QuestionUpdatePayload) => void,
    deleteQuestion: () => void,
    duplicateQuestion: (question: Question) => void;
    createQuestion: () => void;
    disabled?: boolean;
    dragHandleProps?: DraggableProvidedDragHandleProps | null;
    isDragging: boolean;
    onDragStart: () => void;
}

const QuestionEdition: React.FC<Props> = ({
    question,
    questionIndex,
    questionnaire,
    updateQuestion,
    deleteQuestion,
    duplicateQuestion,
    createQuestion,
    disabled,
    dragHandleProps,
    isDragging,
    onDragStart,
}) => {
    const { locale } = useQuestionnaireEditorContext();
    const [confidential, setConfidential] = useState(question.confidential || false);
    const [mandatory, setMandatory] = useState(question.mandatory || false);
    const [questionType, setQuestionType] = useState<QuestionType>(question.type || 'text');

    const dispatch = useAppDispatch();

    const [labelField, setLabelField, resetLabelField] = useQuillInputWithDelay(
        (value) => updateQuestion({ label: value }),
        new Delta(question.label?.ops) || new Delta([])
    );

    useEffect(() => {
        resetLabelField(new Delta(question.label?.ops), false);
    }, [question.label, resetLabelField]);

    useEffect(() => {
        if (locale === 'current') {
            setLabelField(new Delta(question.label?.ops) || new Delta([]), true, true);
        } else {
            setLabelField(new Delta(question?.labels?.translations?.[locale]?.value?.ops) || new Delta([]), true, true);
        }
    }, [locale]);

    useEffect(() => {
        if (locale === 'current') return;
        setLabelField(new Delta(question.labels.translations[locale]?.value?.ops) || new Delta([]), true, true);
    }, [question]);

    const changeQuestionType = (type: QuestionType) => {
        setQuestionType(type);
        if (type === 'description') {
            updateQuestion({ type, mandatory: false, nextQuestion: '' });
        }
        if (type !== 'free') {
            updateQuestion({ type, confidential: false });
        }
        updateQuestion({type});
    };

    const changeQuestionMandatory = (value: boolean) => {
        setMandatory(value);
        updateQuestion({mandatory: value});
    };

    const changeQuestionConfidential = (value: boolean) => {
        setConfidential(value);
        updateQuestion({confidential: value});
    };
    
    const changeNextQuestion = (nextQuestionId: string) => {
        updateQuestion({ nextQuestion: nextQuestionId });
    };

    const changeCriteria = (criterionId: string) => {
        const updateValue: Record<string, any> = { criterionId };
        // eslint-disable-next-line no-restricted-syntax
        if ('items' in question) {
            updateValue.items = question.items?.map((it) => ({...it, criterionValues: []}));
        }
        updateQuestion(updateValue);
    };

    const handleStartDrag = () => {
        onDragStart();
    };

    const nextQuestions = questionnaire?.questions
        ?.map((_question, index) => ({ id: _question.id, index: index + 1}))
        ?.filter((_, index) => questionIndex < index) || [];
    const selectedNextQuestionIndex = questionnaire?.questions?.findIndex((it) => it.id === question?.nextQuestion);

    return (
        <div className={classWithModifiers('mu-question-editor', {disabled})}>
            <div
                className="mu-question-editor__drag-handle-icon"
                hidden={disabled}
                {...dragHandleProps}
                onMouseDown={handleStartDrag}
            >
                <MuIcon svgName="Grip" />
            </div>
            <div className="mu-question-editor__question-type">
                <p className="mu-question-editor__question-index">
                    {questionIndex + 1}
                    {' '}
                    .
                </p>
                <QuillRichInput
                    value={labelField}
                    setValue={(e) => setLabelField(e)}
                    placeholder={i18n.t<string>('questionnaires.desc')}
                    theme="snow"
                    className="mu-textarea-rich-input mu-edit-questionnaire__desc"
                    toolbar={[
                        ['bold', 'italic', 'strike', 'underline'], // toggled buttons
                        [{ header: 2 }], // custom button values
                        [{ list: 'bullet' }],
                        [{ header: [2, 3, false] }],
                    ]}
                    needTags={false}
                />
                <div className="mu-question-editor__question-actions">
                    <Select
                        iconName={QUESTION_TYPE_CONFIG[questionType]?.icon}
                        className="mu-question-editor__type"
                        value={i18n.t<string>(QUESTION_TYPE_CONFIG[questionType]?.i18nKey)}
                        disabled={disabled}
                    >
                        {QUESTION_TYPES.map((type) => {
                            const config = QUESTION_TYPE_CONFIG[type];

                            return (
                                <li key={type} className="mu-dropdown-menu__button-item">
                                    <button type="button" onClick={() => changeQuestionType(type)}>
                                        <TextIcon
                                            classModifiers={[{selected: questionType === type}]}
                                            icon={config?.icon}
                                            label={i18n.t(config.i18nKey)}
                                        />
                                    </button>
                                </li>
                            );
                        })}
                    </Select>
                    <div className="mu-question-editor__question-actions-group">
                        {!!question.cover_urls?.length && (
                            <div className="mu-question-editor__file-list">
                                {question.cover_urls.map((cover) => cover.type === 'video' ? (
                                    <EditQuestionVideo
                                        key={cover.url}
                                        videoUrl={cover.url}
                                        updateQuestion={() => updateQuestion({
                                            cover_urls: question.cover_urls?.filter((c) => c.url !== cover.url),
                                        })}
                                    />
                                ) : (
                                    <EditQuestionImage
                                        key={cover.url}
                                        className="mu-question-editor__image"
                                        imageUrl={cover.url}
                                        updateQuestion={() => updateQuestion({
                                            cover_urls: question.cover_urls?.filter((c) => c.url !== cover.url),
                                        })}
                                    />
                                ))}
                            </div>
                        )}
                        <button
                            type="button"
                            className="mu-question-editor__add-image"
                            onClick={() => dispatch(openModal('AddIdeaModal', {
                                multipleSelection: true,
                                textNotes: false,
                                withVideo: true,
                                addImages: async (ideas: AddModalImages) => {
                                    if (ideas.notes.length) {
                                        const cover_urls = [...question.cover_urls || []];
                                        ideas.notes.forEach((note) => {
                                            if (note.type !== 'TEXT' && note.image_src.original) {
                                                cover_urls.push({type: 'image', url: note.image_src.original});
                                            }
                                        });
                                        updateQuestion({cover_urls});
                                    }
                                    if (ideas.images.length) {
                                        const uploadCover = async (file: File): Promise<QuestionCover> => {
                                            const type = isVideoFile(file) ? 'video' : 'image';
                                            const url = await dispatch(uploadFile(type, file));
                                            return {type, url};
                                        };
                                        updateQuestion({
                                            cover_urls: [
                                                ...question.cover_urls || [],
                                                ...(await Promise.all(ideas.images.map((img) => uploadCover(img)))),
                                            ],
                                        });
                                    }
                                },
                            }))}
                        >
                            <MuIcon svgName="Image" />
                        </button>
                        {/* Use question.type to make it pass the typescript rule */}
                        {(question.type === 'checkboxes' || question.type === 'radios') && (
                            <CriteriaFilterSelection
                                onSelectCriterion={changeCriteria}
                                selectedCriterion={question?.criterionId}
                                toggleClassName="mu-dropdown__toggle mu-question-editor__filter-icon"
                                toggleLabel={<MuIcon svgName="Stats" />}
                                disabled={disabled}
                            />
                        )}
                        {(questionType !== 'description') && (
                            <>
                                <QuestionFilterSelection
                                    selectedNextQuestion={question?.nextQuestion}
                                    selectedNextQuestionIndex={selectedNextQuestionIndex + 1}
                                    nextQuestions={nextQuestions}
                                    onSelectNextQuestion={changeNextQuestion}
                                    disabled={disabled}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div hidden={isDragging}>
                {(questionType === 'radios' || questionType === 'checkboxes' || questionType === 'ranking') && (
                    <EditMultipleChoiceItems
                        question={question as QuestionItems | QuestionRanking}
                        updateQuestion={updateQuestion}
                        disabled={disabled}
                        noAddItemOption={questionType === 'ranking'}
                        noRangeChoiceOption={questionType === 'radios'}
                        nextQuestions={nextQuestions}
                        questionnaire={questionnaire}
                    />
                )}
                {(questionType === 'rating') && (
                    <EditRatingQuestionOptions
                        updateQuestion={updateQuestion}
                        question={question as QuestionRating}
                        disabled={disabled}
                    />
                )}
                {(questionType === 'free') && (
                    <EditFreeQuestionOptions
                        updateQuestion={updateQuestion}
                        question={question as QuestionFree}
                        disabled={disabled}
                    />
                )}
            </div>
            <div className="mu-question-editor__footer">
                {(questionType === 'free') && (
                    <CheckboxRadio
                        className="mu-question-editor__mandatory"
                        setChecked={changeQuestionConfidential}
                        checked={confidential}
                        label={i18n.t('questionnaires.confidential')}
                        styleKey="choice"
                        disabled={disabled}
                    />
                )}
                {(questionType !== 'description') && (
                    <CheckboxRadio
                        className="mu-question-editor__mandatory"
                        setChecked={changeQuestionMandatory}
                        checked={mandatory}
                        label={i18n.t('questionnaires.mandatory')}
                        styleKey="choice"
                        disabled={disabled}
                    />
                )}
                {!disabled && (
                    <>
                        <button
                            className="mu-question-editor__footer-btn"
                            type="button"
                            onClick={() => duplicateQuestion(question)}
                        >
                            <MuIcon className="mu-question-editor__footer-icon" svgName="Duplicate" />
                        </button>
                        <button className="mu-question-editor__footer-btn" type="button" onClick={() => deleteQuestion()}>
                            <MuIcon className="mu-question-editor__footer-icon" svgName="Delete" />
                        </button>
                        <button
                            className="mu-question-editor__footer-btn"
                            type="button"
                            onClick={() => createQuestion()}
                        >
                            <MuIcon className="mu-question-editor__footer-icon" svgName="Plus" />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default QuestionEdition;
