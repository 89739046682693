/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';

type Props = {
    video: File;
};

const VideoThumbnail: React.FC<Props> = ({
    video,
}) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const processVideo = () => {
        if (!videoRef.current) return;
        const url = URL.createObjectURL(video);
        videoRef.current.src = url;
    };

    useEffect(() => {
        processVideo();
    }, []);

    return (
        <article className="mu-note mu-note--no-footer">
            <div className="mu-note__content">
                <video ref={videoRef} controls className="mu-note__content-video">
                    <source src={videoRef.current?.src} type={video.type} />
                </video>
            </div>
        </article>
    );
};

export default VideoThumbnail;
