import React, {useState} from 'react';
import Delta from 'quill-delta';
import {Note} from 'mushin-redux-store';
import i18n from 'i18next';
import EditableTitle from '../../../../../Components/EditableTitle/EditableTitle';
import Tags from '../../../../../Components/Tags/Tags';
import QuillRichInput from '../../../../../Components/QuillRichInput/QuillRichInput';
import NoteThumbnail from '../../../../../Components/NoteThumbnail/NoteThumbnail';
import { NOTE_MAX_LENGTH } from '../../../../../constants';
import { classWithModifiers } from '../../../../../Helpers/css';

type Props = {
  note: Note;
  handleEditTitle: (value: string) => void;
  handleEditContent: (value: Delta | undefined) => void;
  removeNoteTag: (tags: string) => void;
  addNoteTag: (tags: string) => void;

};

const NoteSummary: React.FC<Props> = (
    {
        note,
        handleEditTitle,
        handleEditContent,
        removeNoteTag,
        addNoteTag,
    }
) => {
    const [showDescription, setShowDescription] = useState(false);
    const [noteDesc, setNoteDesc] = useState<Delta>(new Delta(note.content?.ops) || new Delta([]));
    const [noteTitle, setNoteTitle] = useState(note.label);
    const [showTitleCounter, setShowTitleCounter] = useState(false);

    const handleChangeTitle = (e: React.FormEvent<HTMLHeadingElement>) => {
        setNoteTitle(e.currentTarget.textContent || '');
    };
    
    const handleBlur = () => {
        setShowTitleCounter(false);
        if (noteTitle.length > NOTE_MAX_LENGTH) {
            return;
        }
        handleEditTitle(noteTitle);
    };
    
    const handleFocus = () => {
        setShowTitleCounter(true);
    };

    return (
        <section className="mu-note-summary">
            <div className="mu-note-summary__picture">
                <NoteThumbnail note={note} small />
            </div>
            <section className="mu-note-summary__infos mu-note-summary__infos--ellipsis">
                <EditableTitle
                    className="mu-note-summary__title"
                    contentEditable
                    defaultValue={note.label}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onInput={handleChangeTitle}
                />
                {showTitleCounter && (
                    <div className={classWithModifiers('mu-add-images-modal__text-counter', {error: noteTitle.length > NOTE_MAX_LENGTH})}>
                        {`${noteTitle.length} / ${NOTE_MAX_LENGTH}`}
                    </div>
                )}
                <Tags
                    handleRemoveTag={(t) => removeNoteTag(t)}
                    handleAddTag={(t) => addNoteTag(t)}
                    selectedTags={note.hash_tags || []}
                    tags={note.hash_tags || []}
                />
                <div className="mu-note-summary__desc">

                    <button
                        className="mu-link mu-note-summary__desc-btn"
                        type="button"
                        onClick={() => setShowDescription(!showDescription)}
                    >
                        {showDescription ? i18n.t('notes.hide_desc') : i18n.t('notes.see_desc')}
                    </button>

                    {showDescription && (
                        <div
                            onBlur={() => handleEditContent(noteDesc)}
                            className="mu-image-view__desc"
                        >
                            <QuillRichInput
                                value={noteDesc}
                                setValue={(e) => setNoteDesc(e)}
                                placeholder={i18n.t<string>('global.addDescription')}
                                theme="snow"
                                className="mu-textarea-rich-input"
                                toolbar={[
                                    ['bold', 'italic', 'strike', 'underline'], // toggled buttons
                                    [{ list: 'bullet' }],
                                ]}
                            />
                        </div>
                    )}
                </div>
            </section>
        </section>
    );
};

export default NoteSummary;
