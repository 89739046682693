import React from 'react';
import CheckboxRadio from '../../Form/CheckboxRadio';

type Props<T> = {
    entityId: string;
    useItem: (id: string | undefined) => T | null;
    onSelect: (entityId: string, entity: NonNullable<T>) => void;
    getEntityName: (entity: NonNullable<T>) => React.ReactNode;
    selected?: boolean | ((entity: NonNullable<T>) => boolean);
}

type EntitySelectItem<T> = React.FC<Props<T>>

const EntitySelectItem = <T, >({
    entityId,
    useItem,
    onSelect,
    getEntityName,
    selected,
}: Parameters<EntitySelectItem<T>>[0]): ReturnType<EntitySelectItem<T>> => {
    const entity = useItem(entityId);
    if (!entity) return null;
    return (
        <li key={entityId}>
            {selected !== undefined ? (
                <CheckboxRadio
                    className="mu-dropdown-menu__button-item"
                    setChecked={() => onSelect(entityId, entity)}
                    checked={typeof selected === 'function' ? selected(entity) : selected}
                    label={getEntityName(entity)}
                />
            ) : (
                <button
                    className="mu-dropdown-menu__button-item"
                    onClick={() => onSelect(entityId, entity)}
                    type="button"
                >
                    {getEntityName(entity)}
                </button>
            )}
        </li>
    );
};

export default EntitySelectItem;
