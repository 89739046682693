import React, { useState } from 'react';
import {QuestionFree, QuestionUpdatePayload} from 'mushin-redux-store';
import i18n from 'i18next';
import CheckboxRadio from '../../../Components/Form/CheckboxRadio';

type Props = {
    updateQuestion: (payload: QuestionUpdatePayload) => void;
    question: QuestionFree;
    className?: string;
    disabled?: boolean;
};

const EditFreeQuestionOptions: React.FC<Props> = ({
    updateQuestion,
    question,
    disabled,
}) => {
    const [freeAnswerTypes, setFreeAnswerTypes] = useState(question.freeAnswerTypes || []);

    const toggleFreeAnswerType = (value: QuestionFree['freeAnswerTypes'][number]) => {
        setFreeAnswerTypes((prevTypes) => {
            const newTypes = [...prevTypes];

            const itemIndex = newTypes.indexOf(value);
            if (itemIndex === -1) {
                newTypes.push(value);
            } else {
                newTypes.splice(itemIndex, 1);
            }

            updateQuestion({freeAnswerTypes: newTypes});

            return newTypes;
        });
    };

    return (
        <>
            <CheckboxRadio
                checked={freeAnswerTypes.includes('text')}
                setChecked={() => toggleFreeAnswerType('text')}
                label={i18n.t('models.notes.types.text')}
                styleKey="choice"
                disabled={disabled}
            />
            <CheckboxRadio
                checked={freeAnswerTypes.includes('image')}
                setChecked={() => toggleFreeAnswerType('image')}
                label={i18n.t('models.notes.types.image')}
                styleKey="choice"
                disabled={disabled}
            />
            <CheckboxRadio
                checked={freeAnswerTypes.includes('video')}
                setChecked={() => toggleFreeAnswerType('video')}
                label={i18n.t('models.notes.types.video')}
                styleKey="choice"
                disabled={disabled}
            />
        </>
    );
};

export default EditFreeQuestionOptions;
