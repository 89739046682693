import React from 'react';
import {useAppDispatch, useAppSelector} from '../../../../../Helpers/hooks';
import {setBoardText} from '../../../../../Redux/reducers/moodboardEditorSlice';

export const TextContent = (props) => {
    const mode = useAppSelector((state) => state.moodboardEditor.present.mode);
    const dispatch = useAppDispatch();

    if (mode !== 'textEdit') return null;

    const selectedContent = props.board.contents[props.selectedCell];

    if (!selectedContent) return null;

    if (selectedContent.type !== 'text' && selectedContent.type !== 'mixed') return null;

    const {
        text_font,
        text_align,
        text_color,
        text_size,
    } = selectedContent;

    return (
        <textarea
            style={{
                backgroundColor: 'white',
                boxShadow: '0 0 8px 0 rgba(0,0,0,0.2)',
                top: '0',
                width: '100%',
                height: '100%',
                position: 'absolute',
                border: 'none',
                resize: 'none',
                color: text_color,
                caretColor: 'black',
                fontSize: `${text_size * props.ratio * props.camera.a}px`,
                textAlign: text_align,
                fontFamily: text_font,
                padding: 0,
                overflow: 'none',
            }}
            onChange={(e) => {
                dispatch(setBoardText(props.selectedCell, e.currentTarget.value));
            }}
            value={selectedContent.text.map(({
                text,
            }) => text).join('\n')}
            autoFocus
        />
    );
};

export default TextContent;
