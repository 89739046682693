import React from 'react';
import i18n from 'i18next';
import MuIcon from '../../../../Components/MuIcon';

type Props = React.PropsWithChildren<{
  hideIntercom?: boolean;
}>;

const SessionsTemplate: React.FC<Props> = ({
    children,
    hideIntercom = false,
}) => (
    <main className="mu-sessions">
        <section className="mu-sessions__aside">
            <img
                className="mu-sessions__logo"
                src="/assets/images/logo-full.png"
                alt="Mushin logo"
            />
            <img
                className="mu-sessions__logo-small"
                src="/assets/images/logo.png"
                alt="Mushin logo"
            />
            {!hideIntercom && (
                <button
                    id="intercom_custom_launcher"
                    className="mu-btn mu-btn--outline mu-main-menu__help"
                    type="button"
                >
                    <MuIcon className="mu-main-menu__help-icon" svgName="Help" />
                    <div className="mu-main-menu__help-text">{i18n.t('components.navbar.dropdown.help')}</div>
                </button>
            )}
        </section>
        <div className="mu-sessions__container">
            {children}
        </div>
    </main>
);

export default SessionsTemplate;
