import React, {ReactNode} from 'react';
import SessionsTemplate from './SessionsTemplate';

type Props = React.PropsWithChildren<{
  title?: string | ReactNode;
  catchphrase?: string | ReactNode;
  mentions?: string;
  hideIntercom?: boolean;
}>;

const SessionsLayout: React.FC<Props> = ({
    children,
    catchphrase,
    mentions,
    title,
    hideIntercom = false,
}) => (
    <SessionsTemplate hideIntercom={hideIntercom}>
        <section className="mu-sessions-content">
            {title && (
                <header className="mu-sessions-content__header">
                    <h1 className="mu-sessions-content__title">
                        {title}
                    </h1>
                    {catchphrase && (
                        <p className="mu-sessions-content__catchphrase">
                            {catchphrase}
                        </p>
                    )}
                </header>
            )}
            <section className="mu-sessions-content__form">
                {children}
            </section>
            <p className="mu-sessions-content__desc">
                {mentions}
            </p>
        </section>
    </SessionsTemplate>
);

export default SessionsLayout;
