import React from 'react';
import { Project } from 'mushin-redux-store';
import i18n from 'i18next';
import ProjectDropdown from '../Dropdowns/ProjectDropdown';
import MuButton from '../../../../Components/MuButton';
import {isProjectAdmin} from '../../../../Helpers/users';
import AppLayout from '../../../../Components/Layouts/AppLayout';
import Client from '../../../../Services/Client';

type Props = React.PropsWithChildren<{
    project: Project;
    activeTab: 'main' | 'team' | 'contributors' | 'criteria';
}>;

const ProjectLayout: React.FC<Props> = ({
    project,
    activeTab,
    children,
}) => {
    let tabs;

    if (Client.isTeamMemberOfProject(project)) {
        tabs = [{
            key: 'all',
            to: `/projects/${project.id}`,
            label: i18n.t('global.generalInformation'),
            active: activeTab === 'main',
        }, {
            key: 'members',
            to: `/projects/${project.id}/members`,
            label: i18n.t('global.team'),
            active: activeTab === 'team',
        }];

        if (project.type === 'contributors') {
            tabs.push({
                key: 'contributors',
                to: `/projects/${project.id}/members?is_contributor=true`,
                label: i18n.t('global.contributors'),
                active: activeTab === 'contributors',
            }, {
                key: 'criteria',
                to: `/projects/${project.id}/criteria`,
                label: i18n.t('criteria.items'),
                active: activeTab === 'criteria',
            });
        }
    }

    return (
        <AppLayout
            title={i18n.t('global.dashboard')}
            actionsComponent={isProjectAdmin(project) && (
                <ProjectDropdown
                    project={project}
                    ToggleComponent={({ handleClick }) => (
                        <MuButton
                            rightIcon="ArrowDown"
                            label={i18n.t('project.actions')}
                            handleClick={handleClick}
                        />
                    )}
                />
            )}
            tabs={tabs}
        >
            {children}
        </AppLayout>
    );
};

export default ProjectLayout;
