import React from 'react';
import {classWithModifiers} from '../../Helpers/css';
import MuIcon from '../MuIcon';
import {getProfileAvatar, useProfileWithDefault} from '../../Helpers/users';

type UserAvatarProps = {
    userId: string;
    size?: 'small' | 'large';
    className?: string;
};
export const ProfileAvatar: React.FC<UserAvatarProps> = ({
    userId,
    size,
    className,
}: UserAvatarProps) => {
    const profile = useProfileWithDefault(userId);

    if (!profile) return null;
    return (
        <div className={classWithModifiers('mu-user-avatar', null, className)}>
            <img
                src={getProfileAvatar(profile)}
                alt={profile.name}
                className={`${classWithModifiers('mu-user-avatar__img', [size])}`}
            />
            {!profile.type && ( // TODO handle groups
                <MuIcon
                    className={`${classWithModifiers('mu-user-avatar__group', [size])}`}
                    svgName="Group"
                />
            )}
        </div>
    );
};
