import React from 'react';
import {AuthResponse} from 'mushin-redux-store';
import i18n from 'i18next';
import InfoPanel from '../../../Components/InfoPanel/InfoPanel';
import CodeInput from './CodeInput';
import {useSearchParams} from '../../../Helpers/queryParser';
import {UseCodeRequest} from './useCodeRequest';

type Props = {
    email: string;
    request: UseCodeRequest;
    onValidate: (credentials: AuthResponse) => void;
}

const CodeRequest: React.FC<Props> = ({ email, request, onValidate }) => {
    const [searchParams, setSearchParams] = useSearchParams<{email?: string; landing?: string}>();

    return (
        <>
            <p className="mu-sessions-content__catchphrase mu-sessions-content__catchphrase--center">
                {i18n.t('accounts.login.confirmation.description')}
            </p>
            {request.error && (
                <InfoPanel classModifiers={['error']} iconName="Warning" text={i18n.t<string>(request.error)} />
            )}
            <CodeInput
                info={email}
                onCodeFulfilled={async (code) => {
                    const credentials = await request.validate(
                        email.trim(),
                        code,
                    );
                    if (credentials) {
                        onValidate(credentials);
                    }
                }}
            />
            <button
                type="button"
                onClick={(): void => {
                    request.send(email).then();
                }}
                className="mu-form-row__link mu-link"
                disabled={!!request.countdown}
            >
                {i18n.t('accounts.login.confirmation.didntReceive')}
                {request.countdown > 0 && ` (${request.countdown})`}
            </button>
            <button
                type="button"
                onClick={(): void => {
                    setSearchParams({landing: searchParams.landing});
                }}
                className="mu-form-row__link mu-link"
            >
                {i18n.t('global.back')}
            </button>
        </>
    );
};

export default CodeRequest;
