import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';

import {
    useProject,
    useNewsQuery,
    getNews, updateProject,
    useMe
} from 'mushin-redux-store';
import i18n from 'i18next';
import moment from 'moment';
import ProjectLayout from './Components/ProjectLayout/ProjectLayout';
import ProjectDashboard from '../../Components/Dashboard/ProjectDashboard';
import {openModal} from '../../Redux/reducers/modalsSlice';
import {useAppDispatch} from '../../Helpers/hooks';
import MissionsPanel from '../../Components/MissionsPanel/MissionsPanel';
import NewsPanel from './Components/NewsPanel/NewsPanel';
import Client from '../../Services/Client';
import settings from '../../settings';
import {isProjectAdmin, isProjectContributor, getProjectContributorAddedTime} from '../../Helpers/users';
import MissionsTeamPanel from './Components/MissionsTeamPanel/MissionsTeamPanel';
import AlbumsSearch from '../AlbumsPage/Components/AlbumsSearch/AlbumsSearch';
import DeltaPanel from '../../Components/Dashboard/Components/DeltaPanel';
import ProjectCoverImage from '../../Components/Dashboard/Components/ProjectCoverImage';
import ProjectCoverVideo from '../../Components/Dashboard/Components/ProjectCoverVideo';

const ProjectDetail: React.FC = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const project = useProject(projectId);
    const newsQuery = useNewsQuery({project_id: projectId});
    const me = useMe();

    const dispatch = useAppDispatch();

    useEffect(() => {
        settings.lastProject.setValue(projectId as string);
    }, [projectId]);

    if (!project) return null;

    const contributorAddedTime = getProjectContributorAddedTime(project);
    const lastNewsId = newsQuery.results?.length && newsQuery.results[0];
    if (lastNewsId) {
        dispatch(getNews(lastNewsId)).then((news) => {
            // !contributorAddedTime means user is not contributor
            if (!news?.isRead && (!contributorAddedTime || moment(contributorAddedTime).isBefore(news?.created_at)) && moment(me?.created_at).isBefore(news?.created_at)) {
                dispatch(openModal('NewsModal', {id: lastNewsId}));
            }
        });
    }

    const canEdit = isProjectAdmin(project);

    return (
        <ProjectLayout project={project} activeTab="main">
            {project.type === 'contributors' ? (
                <main className="mu-app-layout__scroll">
                    <div className="mu-project mu-project--contributors">
                        {isProjectContributor(project) ? (
                            <MissionsPanel projectId={project.id} />
                        ) : (
                            <MissionsTeamPanel projectId={project.id} />
                        )}
                        {lastNewsId ? (
                            <NewsPanel id={lastNewsId} project={project} />
                        ) : (
                            <div className="mu-panel mu-project__news">
                                <h1 className="mu-goal__title">
                                    {i18n.t('accounts.home.title', {name: Client.getUser()?.name})}
                                </h1>
                                <p className="mu-project__news-desc">
                                    {i18n.t(`accounts.home.welcome.${isProjectContributor(project) ? 'contributor' : 'team'}`)}
                                </p>
                            </div>
                        )}
                        <ProjectDashboard project={project} />
                    </div>
                </main>
            ) : (
                <AlbumsSearch>
                    <div className="mu-project__header">
                        {project.cover_video_url ? (
                            <ProjectCoverVideo project={project} header />
                        ) : (
                            <ProjectCoverImage project={project} header />
                        )}
                        <div className="mu-panel mu-project__news">
                            <DeltaPanel
                                translationTitle={{
                                    fallback: i18n.t('project.goal'),
                                }}
                                translationContent={{
                                    translationProp: project.goals,
                                    fallback: project.goal,
                                }}
                                placeholder={i18n.t('dashboardGoal.isEmpty')}
                                canEditEntity={canEdit}
                                onSubmit={(value) => dispatch(updateProject(project.id, {
                                    goal: value,
                                }))}
                                className="mu-project-dashboard__goal"
                                truncatedSize="medium"
                            />
                        </div>
                    </div>
                </AlbumsSearch>
            )}
        </ProjectLayout>
    );
};

export default ProjectDetail;
