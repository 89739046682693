import React from 'react';
import {QuestionRanking} from 'mushin-redux-store';
import i18n from 'i18next';
import {AnswerProps} from '../types';
import MultipleChoicesAnswer from './MultipleChoicesAnswer';
import {classWithModifiers} from '../../../Helpers/css';
import ReorderWrapper from '../../../Components/ReorderWrapper/ReorderWrapper';
import {DnDItemTypes} from '../../../Helpers/dnd';
import { useAppDispatch } from '../../../Helpers/hooks';
import { openLightbox } from '../../../Redux/reducers/lightboxSlice';
import TextTranslation from '../../../Components/Translation/TextTranslation';
import MuIcon from '../../../Components/MuIcon';
import {questionItemsGridTemplateColumns} from '../../../Helpers/questionnaires';

type Props = AnswerProps<QuestionRanking>

const RankingAnswer: React.FC<Props> = (
    { question, value, setValue, disabled },
) => {
    const rankAll = typeof question.minChoices !== 'number' || question.minChoices === question.items.length;
    const dispatch = useAppDispatch();

    if (!question.items) return null;

    const rankingValue = value as string[] | undefined;

    if (rankAll && !value) setValue({ value: question.items.map((item) => item.slug) });

    const withImages = question.items.some((item) => item.image_url);

    return (
        <>
            {!rankAll && (
                <MultipleChoicesAnswer
                    question={question}
                    value={value}
                    setValue={setValue}
                    disabled={disabled}
                    rank
                />
            )}
            {(rankingValue?.length || 0) > 1 && (
                <>
                    <div className={classWithModifiers('mu-question__instruction', {title: !rankAll})}>
                        {i18n.t('questionnaires.rankOptions')}
                    </div>
                    <div className="mu-ranking-answer">
                        <div
                            className="mu-multiple-choice-answer__grid"
                            style={{gridTemplateColumns: questionItemsGridTemplateColumns(question)}}
                        >
                            {rankingValue?.map((val, index) => {
                                const item = question.items.find((item0) => item0.slug === val);
                                return item && (
                                    <ReorderWrapper
                                        key={item.slug}
                                        className={classWithModifiers('mu-ranking-answer__item', {
                                            'no-footer': !(item.label && item.image_url),
                                        })}
                                        type={DnDItemTypes.ANSWER}
                                        id={item.slug}
                                        index={index}
                                        move={(from, to) => {
                                            if (from === to) return true;
                                            const newValue = [];
                                            for (let i = 0; i < rankingValue.length; i += 1) {
                                                if (i === to) newValue.push(rankingValue[from]);
                                                else if (i > to && i <= from) newValue.push(rankingValue[i - 1]);
                                                else if (i >= from && i < to) newValue.push(rankingValue[i + 1]);
                                                else newValue.push(rankingValue[i]);
                                            }
                                            setValue({value: newValue});
                                            return true;
                                        }}
                                        horizontal
                                        disabled={disabled}
                                    >
                                        <div className="mu-ranking-answer__badge">
                                            #
                                            {index + 1}
                                        </div>
                                        {item.image_url && (
                                            <button
                                                className="mu-ranking-answer__expand"
                                                type="button"
                                                onClick={() => dispatch(openLightbox({urls: [item.image_url as string]}))}
                                            >
                                                <MuIcon svgName="Expand" />
                                            </button>
                                        )}
                                        <div className="mu-ranking-answer__button">
                                            {withImages && (
                                                <img
                                                    src={item.image_url || '/assets/images/default.png'}
                                                    alt={item.label}
                                                    className="mu-ranking-answer__image"
                                                />
                                            )}
                                            <div
                                                className={classWithModifiers(
                                                    'mu-ranking-answer__footer', {text: !withImages},
                                                )}
                                            >
                                                <TextTranslation
                                                    translationProp={item.labels}
                                                    fallback={item.label}
                                                    center
                                                />
                                            </div>
                                        </div>
                                    </ReorderWrapper>
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default RankingAnswer;
