import React from 'react';
import {Link} from 'react-router-dom';
import i18n from 'i18next';
import SessionsTemplate from '../Components/SessionsLayout/SessionsTemplate';
import {useRedirectIfLoggedIn} from '../../../Helpers/users';
import GoogleButton from '../../../Components/GoogleButton/GoogleButton';
import MuIcon from '../../../Components/MuIcon';

const Landing: React.FC = () => {
    useRedirectIfLoggedIn();

    return (
        <SessionsTemplate>
            <p className="mu-sessions__welcome-message">{i18n.t('accounts.signUp.welcomeDescription')}</p>
            <Link className="mu-btn mu-btn--large mu-btn--important" to="/register">
                {i18n.t('accounts.signUp.action')}
            </Link>
            <GoogleButton className="mu-sessions__button" />
            <Link className="mu-sessions__button mu-btn mu-btn--sso" to="/login/sso">
                <MuIcon className="mu-btn__left-icon" svgName="Key" />
                SSO
            </Link>
            <Link to="/login" className="mu-form-row__link mu-link">
                {i18n.t('accounts.signUp.alreadyHaveAnAccount')}
            </Link>
            <section className="mu-sessions__footer">
                <Link to="/cgu" className="mu-form-row__link mu-link" target="_blank">
                    {i18n.t('accounts.signUp.cgu.shortTitle')}
                </Link>
                <Link to="/gdpr" className="mu-form-row__link mu-link" target="_blank">
                    {i18n.t('accounts.signUp.gdpr.shortTitle')}
                </Link>
            </section>
        </SessionsTemplate>
    );
};

export default Landing;
