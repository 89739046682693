import * as Sentry from '@sentry/react';
import {User} from 'mushin-redux-store';
import {useEffect} from 'react';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom';
import { APP_CONFIG } from '../../config';

class SentryConfig {
    static init(): void {
        Sentry.init({
            dsn: APP_CONFIG.SENTRY.app_id,
            integrations: APP_CONFIG.SENTRY.enabled ? [
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                Sentry.replayIntegration(),
            ] : [],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for tracing.
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
            tracePropagationTargets: [/^\//, /^https:\/\/api\.mymushin\.com\//],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            enabled: APP_CONFIG.SENTRY.enabled,
        });

        const userData = localStorage.getItem('user_data');
        if (userData) {
            SentryConfig.setUserContext(JSON.parse(userData));
        }
    }

    static setUserContext(user: User | null, extras = []): void {
        Sentry.setUser(user);
        if (extras.length) {
            Sentry.setExtras({ extras });
        }
    }
}

export default SentryConfig;
