import React, {useState, useEffect} from 'react';
import {
    NoteQueryParams,
    useAlbum,
    useAlbumsQuery, useCurrentOrganizationId,
    useProject,
    useProjectsQuery
} from 'mushin-redux-store';
import i18n from 'i18next';
import EntitySelect from '../EntitySelect/EntitySelect';
import Client from '../../Services/Client';
import {classWithModifiers} from '../../Helpers/css';
import {openModal} from '../../Redux/reducers/modalsSlice';
import {useAppDispatch} from '../../Helpers/hooks';

export const buildNoteQueryParams = (
    currentOrganizationId: string | undefined,
    type: 'mine' | 'all' | 'project' | 'album',
    id?: string | undefined,
): NoteQueryParams => {
    const queryParams: NoteQueryParams = {};
    if (id) {
        if (type === 'album') {
            queryParams.album_id = id;
        } else {
            queryParams.project_id = id;
        }
    } else {
        if (type === 'mine') queryParams.users = [Client.getId() as string];
        queryParams.organization_id = currentOrganizationId;
    }
    return queryParams;
};

type Props = {
    onSelect: (type: 'mine' | 'all' | 'project' | 'album', id?: string) => void;
    initialValue?: string;
    onlyAlbums?: boolean;
    canCreateAlbum?: boolean;
    horizontal?: boolean;
};

const AlbumsSelector: React.FC<Props> = (
    {
        onSelect,
        initialValue,
        onlyAlbums = false,
        canCreateAlbum = false,
        horizontal = false,
    }
) => {
    const [currentOrganizationId] = useCurrentOrganizationId();
    const projectsQuery = useProjectsQuery({organization_id: currentOrganizationId}, true);
    const albumsQuery = useAlbumsQuery({organization_id: currentOrganizationId}, true);

    const [projectId, setProjectId] = useState<string | null>(initialValue || null);
    const [albumId, setAlbumId] = useState<string | null>(onlyAlbums ? null : 'all');

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (projectId && projectId !== 'all' && projectId !== 'mine') {
            albumsQuery.setParams({project_id: projectId || undefined});
        }
    }, [albumsQuery, projectId]);

    const defaultOptions: {[key: string]: React.ReactNode} = {};

    if (!onlyAlbums) defaultOptions.all = 'all';
    if (canCreateAlbum) defaultOptions.new = 'new';

    return (
        <div className={classWithModifiers('mu-albums-selector', {horizontal})}>
            <EntitySelect
                className={classWithModifiers('mu-albums-selector__select-entity', {horizontal})}
                value={projectId}
                options={projectsQuery.results || []}
                useItem={useProject}
                onSelect={(id) => {
                    if (id) {
                        switch (id) {
                            case 'mine':
                            case 'all':
                                onSelect(id);
                                break;
                            default:
                                onSelect('project', id);
                        }
                    }
                    setProjectId(id);
                    setAlbumId(onlyAlbums ? null : 'all');
                }}
                getMoreResults={projectsQuery.getMoreResults}
                placeholder={i18n.t<string>('components.albumsSelector.noProject')}
                getEntityName={(entity) => entity.name}
                defaultOptions={onlyAlbums ? undefined : {
                    mine: i18n.t('global.gallery'),
                    all: i18n.t('projects.all'),
                }}
                neverEmpty={!!initialValue}
            />
            <EntitySelect
                className="mu-albums-selector__select-entity"
                value={albumId}
                options={albumsQuery.results || []}
                useItem={useAlbum}
                onSelect={(id) => {
                    if (id) {
                        switch (id) {
                            case 'new':
                                if (projectId) {
                                    dispatch(openModal('CreateAlbum', {
                                        projectId,
                                        afterCreate: (album) => {
                                            setAlbumId(album.id);
                                            onSelect('album', album.id);
                                        },
                                    }));
                                }
                                break;
                            case 'all':
                                if (projectId) {
                                    onSelect('project', projectId);
                                }
                                break;
                            default:
                                onSelect('album', id);
                        }
                    }
                    if (id !== 'new') setAlbumId(id || 'all');
                }}
                getMoreResults={albumsQuery.getMoreResults}
                placeholder={i18n.t<string>('components.albumsSelector.noAlbum')}
                getEntityName={(entity) => entity.label}
                defaultOptions={defaultOptions}
            />
        </div>

    );
};

export default AlbumsSelector;
