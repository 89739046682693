import React, { useState } from 'react';
import {Note} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal/Modal';
import MuButton from '../../../../Components/MuButton/MuButton';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import {ModalBody} from '../../../../Components/Modal/ModalBody';
import RangeSlider from '../../../../Components/RangeSlider';
import {getBestNotesPerBoard, getMaxPages, getMinPages} from '../../../../Helpers/moodboards';
import {useAppDispatch} from '../../../../Helpers/hooks';
import {openModal} from '../../../../Redux/reducers/modalsSlice';

type Props = {
    onClose: () => void;
    notes: Note[];
    albumId?: string;
    onSuccess?: () => void;
};

const ChooseBoardsNumberModal: React.FC<Props> = ({
    onClose,
    notes,
    albumId,
    onSuccess,
}) => {
    const [numberOfBoards, setNumberOfPages] = useState(getMinPages(notes.length));

    const dispatch = useAppDispatch();

    const numberOfPhotosPerBoard = getBestNotesPerBoard(notes.length, numberOfBoards);
    const numberOfPhotosForLastBoard = notes.length - (numberOfPhotosPerBoard * (numberOfBoards - 1));
    const isPerfect = numberOfPhotosPerBoard === numberOfPhotosForLastBoard;

    const handleConfirm = () => {
        onClose();

        dispatch(openModal('CreateMoodboard', {
            notes,
            albumId,
            onSuccess,
            numberPages: numberOfBoards,
        }));
    };

    return (
        <Modal handleRemoveModal={onClose} classModifiers={['large']}>
            <ModalHeader title={i18n.t('modals.chooseBoardsNumber.title')} icon="Moodboard" />
            <ModalBody>
                <section className="mu-choose-boards-number__choose-number">
                    <RangeSlider
                        defaultValue={numberOfBoards}
                        minValue={getMinPages(notes.length)}
                        maxValue={getMaxPages(notes.length)}
                        handleChange={setNumberOfPages}
                        leftIcon="Minus"
                        rightIcon="Plus"
                    />
                </section>
                <p>
                    {i18n.t('modals.chooseBoardsNumber.numberOfBoards', {
                        numberOfBoards: isPerfect ? numberOfBoards : numberOfBoards - 1,
                        numberOfPhotosPerBoard,
                    })}
                    {!isPerfect && (
                        <>
                            {i18n.t('modals.chooseBoardsNumber.lastBoard', { numberOfPhotosForLastBoard })}
                        </>
                    )}
                </p>
            </ModalBody>
            <ModalFooter>
                <section className="mu-modal-footer__buttons">
                    <MuButton
                        label={i18n.t('global.cancel')}
                        handleClick={onClose}
                        classModifiers={['large', 'default']}
                    />
                    <MuButton
                        label={i18n.t('global.confirm')}
                        handleClick={handleConfirm}
                        classModifiers={['large']}
                    />
                </section>
            </ModalFooter>
        </Modal>
    );
};

export default ChooseBoardsNumberModal;
