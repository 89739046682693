import Intercom, {show} from '@intercom/messenger-js-sdk';
import { APP_CONFIG } from '../../config';
import Client from '../Client/Client';

const APP_ID = APP_CONFIG.INTERCOM.app_id;

// Intercom script
/* eslint-disable */
// @ts-ignore
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
/* eslint-enable */

class IntercomConfig {
    static init(): void {
        Intercom({
            app_id: APP_ID,
            custom_launcher_selector: '#intercom_custom_launcher',
        });
    }

    static update(): void {
        const u = Client.getUser();
        if (u) {
            const created_at = (new Date(u.created_at)).getTime() / 1000;
            Intercom({
                app_id: APP_ID,
                alignment: 'left',
                custom_launcher_selector: '#intercom_custom_launcher',
                user_id: u.id,
                company: Client.getOrganization(),
                user_hash: u.user_hash,
                language_override: u.locale,
                name: `${u.name}`,
                email: u.email,
                created_at,
            });
        }
    }
}

export const showIntercom = (): void => {
    show();
};

export default IntercomConfig;
