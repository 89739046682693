import React, {useState} from 'react';
import { updateAlbum, Album, Project, useProjectsQuery, useCurrentOrganizationId } from 'mushin-redux-store';
import {useDispatch} from 'react-redux';
import i18n from 'i18next';
import SearchBar from '../../../../Components/SearchBar/SearchBar';
import {classWithModifiers} from '../../../../Helpers/css';
import MoveAlbumModalItem from './MoveAlbumModalItem';
import Modal from '../../../../Components/Modal';
import MuButton from '../../../../Components/MuButton';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import {ModalBody} from '../../../../Components/Modal/ModalBody';
import {openModal} from '../../../../Redux/reducers/modalsSlice';

type Props = {
    album: Album;
    onClose: () => void;
};

const MoveAlbumModal: React.FC<Props> = (
    {album, onClose}
) => {
    const [currentOrganizationId] = useCurrentOrganizationId();
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const projectsQuery = useProjectsQuery({organization_id: currentOrganizationId});

    const dispatch = useDispatch();

    const handleMove = () => {
        if (selectedProject) {
            if (selectedProject.steps) {
                if (album.step_slug && selectedProject.steps.some((step) => step.slug === album.step_slug)) {
                    dispatch(updateAlbum(album.id, {project_id: selectedProject.id}));
                    onClose();
                } else {
                    // Choose new step slug
                    dispatch(openModal('SelectProjectStep', {
                        projectId: selectedProject.id,
                        selectStep: (step_slug: string) => {
                            dispatch(updateAlbum(album.id, {project_id: selectedProject.id, step_slug}));
                            onClose();
                        },
                    }));
                }
            } else {
                dispatch(updateAlbum(album.id, {project_id: selectedProject.id, step_slug: null}));
                onClose();
            }
        }
    };

    return (
        <Modal handleRemoveModal={onClose} classModifiers={['large']}>
            <ModalHeader
                title={i18n.t('modals.moveAlbum.title', { name: album.label })}
                icon="FolderAdd"
            />
            <ModalBody noScroll>
                <SearchBar
                    handleChange={(e) => projectsQuery.setParams({
                        organization_id: currentOrganizationId,
                        name_search: e.currentTarget.value || undefined,
                    })}
                    value={projectsQuery.params.name_search || ''}
                    ignoreClassName="mu-selector__search-bar"
                    placeholder={i18n.t<string>('components.filters.search')}
                />
                <ul className="mu-selector__list">
                    {projectsQuery?.results?.map((id) => album.project_id !== id && (
                        <li
                            key={id}
                            className={classWithModifiers(
                                'mu-selector__list-item',
                                {selected: selectedProject?.id === id}
                            )}
                        >
                            <MoveAlbumModalItem
                                id={id}
                                handleClick={(project) => setSelectedProject(project)}
                            />
                        </li>
                    ))}
                </ul>
            </ModalBody>
            <ModalFooter>
                <section className="mu-modal-footer__buttons">
                    <MuButton
                        label={i18n.t('global.cancel')}
                        handleClick={onClose}
                        classModifiers={['large', 'default']}
                    />
                    <MuButton
                        disabled={!selectedProject}
                        label={i18n.t('global.validate')}
                        handleClick={handleMove}
                        classModifiers={['large']}
                    />
                </section>
            </ModalFooter>
        </Modal>
    );
};

export default MoveAlbumModal;
