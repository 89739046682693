import { updateAlbum, useAlbum } from 'mushin-redux-store';
import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import i18n from 'i18next';
import AlbumLayout from './Components/AlbumLayout/AlbumLayout';
import GalleryNotes from './Components/GalleryNotes/GalleryNotes';
import DeltaPanel from '../../Components/Dashboard/Components/DeltaPanel';
import Client from '../../Services/Client';
import Chat from '../Chat/Chat';
import { useAppDispatch } from '../../Helpers/hooks';
import { AlbumGalleryParams } from './types';
import GalleryMessages from './Components/GalleryMessages/GalleryMessages';
import { isAlbumAdmin } from '../../Helpers/users';
import useNotesQueryWithSearchParams from './Components/GalleryNotes/useNotesQueryWithSearchParams';

const AlbumGallery: React.FC = () => {
    const { album_id, inspirationType, projectId } = useParams<AlbumGalleryParams>();
    const album = useAlbum(album_id);

    const [query, searchParams, setSearchParams] = useNotesQueryWithSearchParams();

    const dispatch = useAppDispatch();

    const userMentionsQueryParams = useMemo(
        () => ({
            confirmed: true,
            ...(album_id ? { album_id } : {}),
            ...(projectId ? { project_id: projectId } : {}),
        }),
        [album_id, projectId],
    );

    const scrollHeader = (
        <div className="mu-gallery__dashboard">
            {album && (
                <DeltaPanel
                    className="mu-gallery__dashboard-item"
                    translationContent={{
                        fallback: album.goal,
                    }}
                    placeholder={i18n.t('global.noDescription')}
                    canEditEntity={Client.isAllowedToEditOrganization() || Client.getId() === album.owner.id}
                    onSubmit={(value) => dispatch(updateAlbum(album_id, { goal: value }))}
                    truncatedSize="medium"
                    secondary
                />
            )}
        </div>
    );

    return (
        <AlbumLayout albumId={album_id} album={album} inspirationType={inspirationType}>
            {album && (
                <>
                    {inspirationType === 'messages' ? (
                        <GalleryMessages>{scrollHeader}</GalleryMessages>
                    ) : (
                        <GalleryNotes
                            query={query}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            canMove={isAlbumAdmin(album)}
                            isRanked={album.ranked}
                        >
                            {scrollHeader}
                        </GalleryNotes>
                    )}
                    <Chat
                        entity_type="ALBUM"
                        entity_id={album.id}
                        chatId={album.chat?.id}
                        userMentionsQueryParams={userMentionsQueryParams}
                    />
                </>
            )}
        </AlbumLayout>
    );
};

export default AlbumGallery;
