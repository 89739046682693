import { RawTemplateTree } from 'mushin-node-moodboard';

export const templates: RawTemplateTree[] = [{
    axe: 'x',
    children: [{ type: 'image' }, { type: 'image' }],
}, {
    axe: 'y',
    children: [{ type: 'image' }, { type: 'image' }],
}, {
    axe: 'x',
    children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
}, {
    axe: 'y',
    children: [{ type: 'mixed', split: 0.5, position: 'right' }, { type: 'mixed', split: 0.5, position: 'right' }],
}, {
    axe: 'x',
    children: [{ type: 'image' }, {
        axe: 'y',
        children: [{ type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'x',
    children: [{
        axe: 'y',
        sizes: [1, 0],
        children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, null],
    }, {
        axe: 'y',
        children: [{ type: 'mixed', split: 0.5, position: 'right' }, { type: 'mixed', split: 0.5, position: 'right' }],
    }],
}, {
    axe: 'y',
    sizes: [1, 0],
    children: [{
        axe: 'x',
        children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
    }, null],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }],
    }, {
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'mixed', split: 0.6, position: 'right' }, { type: 'mixed', split: 0.6, position: 'right' }],
    }, {
        axe: 'x',
        children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }],
    }, {
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }, {
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'x',
    children: [{
        axe: 'y',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }, {
        axe: 'y',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'mixed', split: 0.5, position: 'right' }, { type: 'mixed', split: 0.5, position: 'right' }],
    }, {
        axe: 'x',
        children: [{ type: 'mixed', split: 0.5, position: 'right' }, { type: 'mixed', split: 0.5, position: 'right' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'mixed', split: 0.5, position: 'right' }, { type: 'mixed', split: 0.5, position: 'right' }, { type: 'mixed', split: 0.5, position: 'right' }],
    }, {
        axe: 'x',
        children: [{ type: 'mixed', split: 0.5, position: 'right' }, { type: 'mixed', split: 0.5, position: 'right' }, { type: 'mixed', split: 0.5, position: 'right' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'mixed', split: 0.6, position: 'bottom' }, { type: 'mixed', split: 0.6, position: 'bottom' }, { type: 'mixed', split: 0.6, position: 'bottom' }],
    }, {
        axe: 'x',
        children: [{ type: 'mixed', split: 0.6, position: 'bottom' }, { type: 'mixed', split: 0.6, position: 'bottom' }, { type: 'mixed', split: 0.6, position: 'bottom' }],
    }],
}, {
    axe: 'x',
    sizes: [0.7, 0.3],
    children: [{ type: 'image' }, {
        axe: 'y',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'y',
    sizes: [0.666, 0.334],
    children: [{
        axe: 'x',
        sizes: [0.666, 0.334],
        children: [{ type: 'image' }, {
            axe: 'y',
            children: [{ type: 'image' }, { type: 'image' }],
        }],
    }, {
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'x',
    sizes: [0.55, 0.45],
    children: [{
        sizes: [1, 0],
        axe: 'y',
        children: [{ type: 'mixed', split: 0.7, position: 'bottom' }],
    }, {
        axe: 'y',
        children: [{ type: 'mixed', split: 0.4, position: 'right' }, { type: 'mixed', split: 0.4, position: 'right' }, { type: 'mixed', split: 0.4, position: 'right' }],
    }],
}, {
    axe: 'x',
    children: [{
        axe: 'y',
        children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
    }, { type: 'mixed', split: 0.8, position: 'bottom' }, {
        axe: 'y',
        children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
    }],
}, {
    axe: 'x',
    sizes: [0.25, 0.5, 0.25],
    children: [{
        axe: 'y',
        children: [{ type: 'mixed', split: 0.6, position: 'right' }, { type: 'mixed', split: 0.6, position: 'right' }, { type: 'mixed', split: 0.6, position: 'right' }],
    }, {
        axe: 'y',
        sizes: [0, 1, 0],
        children: [null, { type: 'mixed', split: 0.7, position: 'bottom' }, null],
    }, {
        axe: 'y',
        children: [{ type: 'mixed', split: 0.6, position: 'right' }, { type: 'mixed', split: 0.6, position: 'right' }, { type: 'mixed', split: 0.6, position: 'right' }],
    }],
}, {
    axe: 'x',
    sizes: [0.25, 0.5, 0.25],
    children: [{
        axe: 'y',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }, { type: 'image' }, {
        axe: 'y',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'x',
    sizes: [0.05, 0.225, 0.45, 0.225, 0.05],
    children: [{ type: 'whiteCol' }, {
        axe: 'y',
        children: [{ type: 'image' }, { type: 'image' }],
    }, { type: 'image' }, {
        axe: 'y',
        children: [{ type: 'image' }, { type: 'image' }],
    }, { type: 'whiteCol' }],
}, {
    axe: 'x',
    sizes: [0.25, 0.5, 0.25],
    children: [{
        axe: 'y',
        children: [{ type: 'image' }, { type: 'image' }],
    }, { type: 'image' }, {
        axe: 'y',
        children: [{ type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'x',
    sizes: [0.38, 0.24, 0.38],
    children: [{ type: 'mixed', split: 0.8, position: 'bottom' }, { type: 'mixed', split: 0.8, position: 'bottom' }, {
        axe: 'y',
        children: [{
            axe: 'x',
            children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
        }, {
            axe: 'x',
            children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
        }],
    }],
}, {
    axe: 'x',
    children: [{ type: 'image' }, {
        axe: 'x',
        children: [{
            axe: 'y',
            children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
        }, {
            axe: 'y',
            children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
        }],
    }],
}, {
    axe: 'x',
    sizes: [0.45, 0.55],
    children: [{ type: 'mixed', split: 0.8, position: 'bottom' }, {
        axe: 'x',
        children: [{
            axe: 'y',
            children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
        }, {
            axe: 'y',
            children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
        }, {
            axe: 'y',
            children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
        }],
    }],
}, {
    axe: 'x',
    sizes: [0.55, 0.45],
    children: [{ type: 'image', sizes: [0.1, 0.1, 0.1] }, {
        axe: 'x',
        children: [{
            axe: 'y',
            children: [{ type: 'image' }, { type: 'image' }],
        }, {
            axe: 'y',
            children: [{ type: 'image' }, { type: 'image' }],
        }],
    }],
}, {
    axe: 'x',
    sizes: [0.075, 0.425, 0.425, 0.075],
    children: [{ type: 'whiteCol', background: 'transparent' }, { type: 'image', sizes: [0.1, 0.1, 0.1] }, {
        axe: 'x',
        children: [{
            axe: 'y',
            children: [{ type: 'image' }, { type: 'image' }],
        }, {
            axe: 'y',
            children: [{ type: 'image' }, { type: 'image' }],
        }],
    }, { type: 'whiteCol', background: 'transparent' }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
    }, {
        axe: 'x',
        children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'mixed', split: 0.7, position: 'right' }, { type: 'mixed', split: 0.7, position: 'right' }, { type: 'mixed', split: 0.7, position: 'right' }],
    }, {
        axe: 'x',
        children: [{ type: 'mixed', split: 0.7, position: 'right' }, { type: 'mixed', split: 0.7, position: 'right' }, { type: 'mixed', split: 0.7, position: 'right' }],
    }, {
        axe: 'x',
        children: [{ type: 'mixed', split: 0.7, position: 'right' }, { type: 'mixed', split: 0.7, position: 'right' }, { type: 'mixed', split: 0.7, position: 'right' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }, {
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }, {
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }, { type: 'image' }],
    }, {
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }, { type: 'image' }],
    }, {
        axe: 'x',
        sizes: [0.25, 0.5, 0.25],
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }],
    }, {
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }, { type: 'image' }],
    }, {
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }, { type: 'image' }, { type: 'image' }],
    }, {
        axe: 'x',
        children: [{ type: 'image' }, { type: 'image' }, { type: 'image' }, { type: 'image' }, { type: 'image' }],
    }],
}, {
    axe: 'y',
    children: [{
        axe: 'x',
        children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
    }, {
        axe: 'x',
        children: [{ type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }, { type: 'mixed', split: 0.7, position: 'bottom' }],
    }],
}];
