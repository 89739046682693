import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useProject, useTimelineUnseen} from 'mushin-redux-store';
import i18n from 'i18next';
import {classWithModifiers} from '../../Helpers/css';
import {BadgeComponent} from '../Badge/BadgeComponent';
import {getStatus} from '../../Helpers/date';
import CountNotifications from '../CountNotifications/CountNotifications';
import MuIcon from '../MuIcon';
import Tabs, {TabsList} from '../Tabs/Tabs';
import Spinner from '../Spinner';
import TextTranslation from '../Translation/TextTranslation';

type Props = {
    title: React.ReactNode;
    actionsComponent?: React.ReactElement | false;
    backLink?: string;
    tabs?: TabsList;
};

const Header: React.FC<Props> = (
    {
        actionsComponent,
        title,
        backLink,
        tabs,
    }
) => {
    const { projectId } = useParams<{ projectId: string }>();
    const project = useProject(projectId);

    const unseenInOtherProjects = useTimelineUnseen({}, (unseenData) => (
        unseenData.some((unseen) => unseen.count && unseen._id.project_id !== projectId)
    ));

    const activeContributorsSize = (
        project?.metrics?.active_users?.filter(
            (user) => project.contributors?.some((contributor) => contributor.id === user.id),
        ).length
    );

    return (
        <header className={classWithModifiers('mu-header', {tabs: !!tabs})}>
            <h1 className="mu-header__title">
                {backLink && (
                    <Link type="button" className="mu-header__back-button mu-edit-button" to={backLink}>
                        <MuIcon className="mu-header__back-button-icon" svgName="ArrowLeft" />
                    </Link>
                )}
                {title || <Spinner size="20px" />}
            </h1>
            <section className="mu-header__project">
                {(project && (
                    <div
                        className={classWithModifiers(
                            'mu-bordered-rounded-picture',
                            {placeholder: !project.organization.logo_src}
                        )}
                    >
                        {project.organization.logo_src ? (
                            <img
                                src={project.organization.logo_src}
                                alt={project.organization.name}
                                className="mu-bordered-rounded-picture__img"
                            />
                        ) : project.organization.name}
                    </div>
                ))}
                <div className="mu-header__project-infos">
                    <h2 className="mu-header__project-title">
                        {project && <TextTranslation translationProp={project.titles} fallback={project.name} />}
                    </h2>
                    {activeContributorsSize ? (
                        <div className="mu-header__project-subtitle">
                            {i18n.t('global.participant', {count: activeContributorsSize})}
                        </div>
                    ) : project?.category && (
                        <div className="mu-header__project-subtitle">
                            {`@${project.category}`}
                        </div>
                    )}
                </div>
                {project && (
                    <BadgeComponent
                        status={getStatus(project.start_date, project.end_date, project.archived_at)}
                    />
                )}
                {projectId && (
                    <Link to="/projectSelector">
                        <CountNotifications nb={unseenInOtherProjects}>
                            <MuIcon className="mu-main-menu-nav__icon" svgName="Swap" />
                        </CountNotifications>
                    </Link>
                )}
            </section>
            <div className="mu-header__header-actions">
                {actionsComponent}
            </div>
            {tabs && (<Tabs className="mu-header__tabs" tabs={tabs} />)}
        </header>
    );
};

export default Header;
