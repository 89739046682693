import React from 'react';
import {classWithModifiers} from '../../Helpers/css';

type Props = React.PropsWithChildren<{
    onSelect: () => void;
    value?: string;
    selectedValue?: string;
    isSelected?: boolean;
}>

const SelectOption: React.FC<Props> = ({value, onSelect, selectedValue, isSelected, children}) => (
    <button
        className={classWithModifiers('mu-dropdown-menu__button-item', {
            selected: selectedValue === value || isSelected,
        })}
        type="button"
        onClick={onSelect}
    >
        {children || value}
    </button>
);

export default SelectOption;
