import React from 'react';
import { AvailableLocale, Questionnaire, locales } from 'mushin-redux-store';
import i18n from 'i18next';
import Select from '../../../Components/Select/Select';
import { useQuestionnaireEditorContext } from './QuestionnaireEditorContext';

type Locale = AvailableLocale | 'current';

type Props = {
    questionnaire: Questionnaire;
}

const LanguageSelect: React.FC<Props> = ({ questionnaire }) => {    
    const localeKeyList: Locale[] = ['current', ...(questionnaire.locales || [])];

    const { locale, onChangeLocale } = useQuestionnaireEditorContext();
    
    if (localeKeyList.length <= 1) return null;

    return (
        <Select
            value={locale === 'current' ? i18n.t('global.defaultLanguage') : locales[locale]}
            className="mu-edit-questionnaire__language-select"
        >
            {localeKeyList.map((localeKey) => (
                <button
                    key={localeKey}
                    type="button"
                    className="mu-dropdown-menu__button-item"
                    onClick={() => {
                        onChangeLocale(localeKey);
                    }}
                >
                    {localeKey === 'current' ? i18n.t('global.defaultLanguage') : locales[localeKey]}
                </button>
            ))}
        </Select>
    ); 
};

export default LanguageSelect;
