import React from 'react';
import {useAppSelector} from '../../Helpers/hooks';
import AppModalsItem from './AppModalsItem';

const AppModals: React.FC = () => {
    const modalTypes = useAppSelector((state) => state.modals.modalTypes);

    return (
        <div id="app-modals">
            {modalTypes.map((modalType) => <AppModalsItem key={modalType} type={modalType} />)}
        </div>
    );
};

export default AppModals;
