import React from 'react';
import i18n from 'i18next';
import Spinner from '../Spinner';

type Props = {
    loading?: boolean;
}

const EmptyMessageList: React.FC<Props> = ({ loading }) => (
    loading ? (
        <section className="mu-discussion-no-messages">
            <Spinner size="50px" />
        </section>
    ) : (
        <section className="mu-discussion-no-messages">
            <img
                src="/assets/images/mushin_picto_mess.svg"
                alt="Send messages"
                className="mu-discussion-no-messages__img"
            />
            <h1 className="mu-discussion-no-messages__title">
                {i18n.t('chat.no_messages.title')}
            </h1>
            <p className="mu-discussion-no-messages__desc">
                {i18n.t('chat.no_messages.desc')}
            </p>
        </section>
    )
);

export default EmptyMessageList;
