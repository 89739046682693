import React from 'react';

import { Cell } from 'mushin-node-moodboard';

type Props = {
  cell: Cell;
  color: string;
};

export const Image = ({
    cell,
    color,
}: Props) => {
    const s = Math.min(cell.max.x - cell.min.x, cell.max.y - cell.min.y, 100);

    return (
        <g>
            <rect fill={color} x={cell.min.x} y={cell.min.y} width={cell.max.x - cell.min.x} height={cell.max.y - cell.min.y} />
            <g transform={`translate(${(cell.max.x - cell.min.x - s) / 2 + cell.min.x},${(cell.max.y - cell.min.y - s) / 2 + cell.min.y}) scale(${s / 100}) `}>
                <circle cx={28} cy={26} r={8} fill="#fff" />
                <path d="M15 75L 40 50L60 70L85 45" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
            </g>
        </g>
    );
};
