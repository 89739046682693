import React from 'react';
import { ActionBar } from './common';
import { ActionProps, DeleteButton } from '../actionButtons/common';

export { default as CropBar } from './CropBar';
export { default as ImageBar } from './ImageBar';
export { default as MixedBar } from './MixedBar';
export { default as TextBar } from './TextBar';
export { default as TextEditBar } from './TextEditBar';

export const DefaultBar = (props: ActionProps) => (
    <ActionBar>
        <DeleteButton {...props} />
    </ActionBar>
);
