import React from 'react';
import {useParams} from 'react-router-dom';
import {
    useQuestionnaire,
} from 'mushin-redux-store';
import QuestionnaireAnswer from './QuestionnaireAnswer';
import QuestionnaireAppLayout from '../QuestionnaireEditor/Components/QuestionnaireAppLayout';

type Params = {
    questionnaireId: string;
}

const QuestionnaireAnswerPrivate: React.FC = () => {
    const { questionnaireId } = useParams<Params>();
    const questionnaire = useQuestionnaire(questionnaireId);

    return (
        <QuestionnaireAppLayout questionnaire={questionnaire}>
            <div className="mu-app-layout__scroll">
                <QuestionnaireAnswer questionnaire={questionnaire} />
            </div>
        </QuestionnaireAppLayout>
    );
};

export default QuestionnaireAnswerPrivate;
