import React from 'react';

import i18n from 'i18next';
import TextIcon from '../TextIcon/TextIcon';
import Select from '../Select/Select';
import {classWithModifiers} from '../../Helpers/css';

export type UserRole = {
    key: string,
    icon: string,
    disabled?: boolean,
    canEdit?: boolean,
    unique?: boolean,
    fallback?: string,
}

type UserRoleProps = {
    role?: string;
    useRoles?: boolean | 'read-only';
    setRole?: (role: UserRole) => void;
    roleOptions?: UserRole[];
};

const UserRoleSelect: React.FC<UserRoleProps> = ({
    role,
    useRoles = false,
    setRole,
    roleOptions,
}) => {
    const selectedOption = roleOptions?.find((opt) => opt.key === role);
    return (
        <>
            {useRoles && roleOptions && (
                useRoles !== 'read-only'
                    ? (
                        <Select
                            disabled={selectedOption?.disabled}
                            value={selectedOption && (
                                <TextIcon
                                    icon={selectedOption.icon}
                                    label={i18n.t(`models.users.role.${selectedOption.key}`)}
                                />
                            )}
                        >
                            {roleOptions.map((opt) => (
                                <button
                                    key={opt.key}
                                    className={classWithModifiers(
                                        'mu-dropdown-menu__button-item',
                                        {selected: opt.key === role},
                                    )}
                                    type="button"
                                    onClick={() => setRole && setRole(opt)}
                                >
                                    <TextIcon
                                        classModifiers={{selected: opt.key === role}}
                                        icon={opt.icon}
                                        label={i18n.t(`models.users.role.${opt.key}`)}
                                    />
                                </button>
                            ))}
                        </Select>
                    )
                    : (
                        <TextIcon
                            icon={roleOptions.find((opt) => opt.key === role)?.icon || ''}
                            label={i18n.t(`models.users.role.${role}`)}
                        />
                    )
            )}
        </>
    );
};

export default UserRoleSelect;
