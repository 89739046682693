import React from 'react';
import i18n from 'i18next';

type Props<T> = {
    getEntityName: (entity: T, index: number) => string;
    value: string[];
    useItems: (ids: string[] | undefined) => (T | null)[] | undefined;
    separator?: string;
}

type EntitySelectValue<T> = React.FC<Props<T>>

const EntitySelectMultipleValue = <T, >({
    value,
    useItems,
    getEntityName,
    separator = ', ',
}: Parameters<EntitySelectValue<T>>[0]): ReturnType<EntitySelectValue<T>> => {
    const items = useItems(value as string & string[]);
    if (!items) return null;

    const entitiesNames = items.map((entity, index) => (entity ? getEntityName(entity, index) : 'unknown'));

    if (entitiesNames.length === 0) {
        return null;
    }
    if (entitiesNames.length === 1) {
        return (<>{entitiesNames[0]}</>);
    }
    if (entitiesNames.length === 2) {
        return (<>{`${entitiesNames[0]} ${i18n.t('global.and')} ${entitiesNames[1]}`}</>);
    }

    return (
        <>
            {`${entitiesNames[0]}${separator}${entitiesNames[1]}`}
            {` ${i18n.t('global.and')} ${entitiesNames.length - 2} ${i18n.t('global.others')}`}
        </>
    );
};

export default EntitySelectMultipleValue;
