import React from 'react';
import { classWithModifiers, Modifiers } from '../../Helpers/css';
import MuIcon from '../MuIcon';

type Props = {
  text: string;
  iconName: string;
  classModifiers?: Modifiers;
};

const InfoPanel = ({
    text,
    iconName,
    classModifiers,
}: Props) => (
    <div className={classWithModifiers('mu-info-panel', classModifiers)}>
        <span className="mu-info-panel__icon">
            <MuIcon svgName={iconName} />
        </span>
        <p className="mu-info-panel__text">
            {text}
        </p>
    </div>
);

export default InfoPanel;
