import {Link} from 'react-router-dom';
import React from 'react';
import i18n from 'i18next';
import TextIcon from '../../../TextIcon/TextIcon';

type Props = {
    to: string;
    icon: string;
    label: string;
    setMenuOpen: (isOpen: boolean) => void;
}

const UserMenuItem: React.FC<Props> = (
    {
        to,
        icon,
        label,
        setMenuOpen,
    }
) => (
    <li className="mu-dropdown-menu__item">
        <Link to={to} onClick={() => setMenuOpen(false)}>
            <TextIcon icon={icon} label={i18n.t(label)} />
        </Link>
    </li>
);

export default UserMenuItem;
