import React, {useMemo, useState} from 'react';
import {useCurrentProjectId, useNotesQuery} from 'mushin-redux-store';

import ImageViewModal from '../../../../Components/ImageViewModal/ImageViewModal';

type Props = {
    noteId: string;
    query: ReturnType<typeof useNotesQuery>;
    onClose: () => void;
};

const ContributorDetailsImageViewModal: React.FC<Props> = ({
    noteId,
    query,
    onClose,
}) => {
    const [currentProjectId] = useCurrentProjectId();
    const [openNoteId, setOpenNoteId] = useState(noteId);

    const userMentionsQueryParams = useMemo(
        () => ({
            confirmed: true,
            ...(currentProjectId ? {project_id: currentProjectId} : {}),
        }),
        [currentProjectId]
    );

    return query.results && (
        <ImageViewModal
            noteId={openNoteId}
            setNoteId={(_noteId) => {
                if (_noteId) {
                    setOpenNoteId(_noteId);
                } else {
                    onClose();
                }
            }}
            noteIds={query.results}
            loadMoreNotes={query.getMoreResults}
            userMentionsQueryParams={userMentionsQueryParams}
        />
    );
};

export default ContributorDetailsImageViewModal;
