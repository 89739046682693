import { Cell, Point } from 'mushin-node-moodboard';
import { generateContainerRules, generateCellsRules, findMatchingSnappingRules } from './snapUtils';

/**
 * helper to snap content on translate
 *
 * generate a set of position where the element should snap
 * if the element is close enought, use the snapping position
 * also return the rule to eventually provide visual feedback
 */
export const snap = (cells: Array<Cell>, containerDimension: Point, cellToSnap: Cell, margin = 5, threshold = 3) => {
    const x = snapAxe('x', cells, containerDimension, margin, threshold, cellToSnap);
    const y = snapAxe('y', cells, containerDimension, margin, threshold, cellToSnap);

    return {
        cell: {
            max: { x: cellToSnap.max.x + x.d, y: cellToSnap.max.y + y.d },
            min: { x: cellToSnap.min.x + x.d, y: cellToSnap.min.y + y.d },
        },
        rules: [...x.rules, ...y.rules],
    };
};

const snapAxe = (axe: 'x' | 'y', cells: Array<Cell>, containerDimension: Point, margin: number, threshold: number, cellToSnap: Cell) => {
    /**
   * generate all the rules
   */
    const rules = [...generateContainerRules(axe, containerDimension, margin), ...generateCellsRules(axe, cells, margin)];

    /**
   * return the matching rules
   */
    return findMatchingSnappingRules(rules, axe, cellToSnap, threshold);
};
