import React, {ReactElement} from 'react';
import {useProfile} from 'mushin-redux-store';
import ProfileDetailsPopover from './ProfileDetailsPopover';

type Props = {
    id: string;
    hoveredElement: ReactElement,
    position?: 'right' | 'bottom',
    inline?: boolean,
}

const UserDetailsPopoverHook: React.FC<Props> = ({ id, hoveredElement, position, inline = false }) => {
    const profile = useProfile(id);

    if (!profile) return null;

    return (
        <ProfileDetailsPopover profile={profile} hoveredElement={hoveredElement} position={position} inline={inline} />
    );
};

export default UserDetailsPopoverHook;
