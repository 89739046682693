import React from 'react';
import MuIcon from '../../../../../Components/MuIcon';

type InsightCardProps = {
    text: string;
    span: string;
    numberOpportunities?: number;
    numberSolutions?: number;
};

const InsightCard: React.FC<InsightCardProps> = ({ text, span, numberOpportunities, numberSolutions}) => {
    return (
        <div className="mu-insights__insight-card">
            <MuIcon className="mu-insights__insight-card__icon" svgName="insightIcon" />
            <div className="mu-insights__insight-card__container">
                <h3 className="mu-insights__insight-card__text">{text}</h3>
                <p className="mu-insights__insight-card__span">{span}</p>
                <div className="mu-insights__insight-card__tags">
                    <div className="mu-insights__insight-card__tags--oportunite">
                        <MuIcon className="mu-insights__insight-card__tags--icon" svgName="Opportunities" />
                        <p className="mu-insights__insight-card__tags--p-oportunite"> {numberOpportunities} Oportunités</p>
                    </div>
                    <div className="mu-insights__insight-card__tags--solution">
                        <MuIcon className="mu-insights__insight-card__tags--icon" svgName="Solutions" />
                        <p className="mu-insights__insight-card__tags--p-solution"> {numberSolutions} Solutions</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsightCard;
