import React, {useState} from 'react';
import {useProject} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import AlbumStepSelector from './AlbumStepSelector';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton';

type Props = {
    onClose: () => void;
    projectId: string;
    selectStep: (stepSlug: string) => void;
};

const SelectProjectStepModal: React.FC<Props> = ({
    onClose,
    projectId,
    selectStep,
}) => {
    const [step, setStep] = useState<string | undefined>();

    const project = useProject(projectId);

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={onClose}>
            <ModalHeader
                subtitle={i18n.t('modals.SelectProjectStep.subtitle')}
                title={i18n.t('modals.SelectProjectStep.title')}
                icon="Calendar"
            />
            {project?.steps?.length && (
                <AlbumStepSelector steps={project.steps} stepState={[step, setStep]} />
            )}
            <ModalFooter>
                <MuButton
                    label={i18n.t('global.cancel')}
                    handleClick={onClose}
                    classModifiers={['large', 'default']}
                />
                <MuButton
                    disabled={!step}
                    handleClick={() => {
                        if (step) {
                            selectStep(step);
                            onClose();
                        }
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default SelectProjectStepModal;
