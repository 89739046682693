import { split } from 'mushin-node-moodboard/lib/common/mixedContent';

import { Handler } from './type';

import { setContent } from '../layoutUtils';
import { computeMinScale, getNextScale } from '../cropUtils';

export const onWheel: Handler = ({
    state,
    camera,
    board,
    selectedCell,
}, pointers, event) => {
    const content = selectedCell && board.contents[selectedCell];

    // only trigger when the selectedCell as image as content, and the crop is 'cover'
    // else ignore
    if (!selectedCell || !event || !content || content.type !== 'image' || content.image_crop.filling !== 'cover') return;

    let imageDimension = state.drawer.cache.getDimensions(content.image_url);

    // image not loaded
    if (!imageDimension) return;

    // reflect image rotation
    if (content.image_rotation === 90 || content.image_rotation === 270) {
        imageDimension = {
            x: imageDimension.y,
            y: imageDimension.x,
        };
    }

    const cell = content.type === 'mixed' ? split(board.cells[selectedCell], content)[0].cell : board.cells[selectedCell];

    const minScale = computeMinScale(imageDimension, cell);

    const scale = Math.min(minScale, getNextScale(Math.min(minScale, content.image_crop.scale), event.deltaY > 0 ? 1 : -1));

    const newContent = {
        ...content,
        image_crop: {
            ...content.image_crop,
            scale,
        },
    };

    event.preventDefault();

    return { board: setContent(board, selectedCell, newContent) };
};
