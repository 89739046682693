import React from 'react';
import {classWithModifiers} from '../../Helpers/css';

type DocumentLevel2Content = {
    type: 'text';
    content: string;
    bold?: boolean;
    underline?: boolean;
} | {
    type: 'link';
    content: string;
    href: string;
}

type DocumentLevel1Content = {
    type: 'paragraph';
    content: DocumentLevel2Content[];
} | {
    type: 'title';
    number?: string;
    content: string;
}

export type DocumentContent = {
    versionCode: number;
    date: Date;
    content: DocumentLevel1Content[];
}

const Document: React.FC<{desc: DocumentContent}> = ({ desc }) => (
    <>
        {desc.content.map((level1Element) => {
            switch (level1Element.type) {
                case 'title':
                    return (
                        <h2 className="mu-terms__chapter">
                            {level1Element.number && `${level1Element.number} ` }
                            {level1Element.content}
                        </h2>
                    );
                case 'paragraph':
                default:
                    return (
                        <p>
                            {level1Element.content.map((level2Element) => {
                                switch (level2Element.type) {
                                    case 'link':
                                        return (
                                            <a href={level2Element.href}>{level2Element.content}</a>
                                        );
                                    case 'text':
                                    default:
                                        return (
                                            <span
                                                className={classWithModifiers('mu-terms__text', {
                                                    bold: level2Element.bold,
                                                    underline: level2Element.underline,
                                                })}
                                            >
                                                {level2Element.content}
                                            </span>
                                        );
                                }
                            })}
                        </p>
                    );
            }
        })}
    </>
);

export default Document;
