import React from 'react';
import i18n from 'i18next';
import {classWithModifiers} from '../../../../Helpers/css';

type Props = {
    category: string | null | undefined;
    setCategory: (type: string | null | undefined) => void;
    categories: { key: string | null ; count: number }[]
}

const CategoryDropdownPanel: React.FC<Props> = (
    {category, setCategory, categories}
) => (
    <>
        <button
            className={classWithModifiers('mu-dropdown-menu__button-item', {selected: category === undefined})}
            type="button"
            onClick={() => setCategory(undefined)}
        >
            {i18n.t('tooltips.all')}
        </button>
        {categories.map((cat) => (
            <button
                key={cat.key}
                className={classWithModifiers(
                    'mu-dropdown-menu__button-item', {selected: cat.key === category}
                )}
                type="button"
                onClick={() => setCategory(cat.key)}
            >
                {`${cat.key || i18n.t('project.noCategory')} (${cat.count})`}
            </button>
        ))}
    </>
);

export default CategoryDropdownPanel;
