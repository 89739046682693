import React from 'react';
import {useLocation} from 'react-router-dom';
import NotifBackgroundTask from '../../Components/NotifBackgroundTask/NotifBackgroundTask';
import UploadingAlert from '../NoteUploader/Components/UploadingAlert/UploadingAlert';
import {useAppDispatch, useAppSelector} from '../../Helpers/hooks';
import {removeAppNotification} from '../../Redux/reducers/appNotificationsSlice';
import {classWithModifiers} from '../../Helpers/css';

const AppNotifs: React.FC = () => {
    const items = useAppSelector((state) => state.appNotifications);

    const location = useLocation();
    const dispatch = useAppDispatch();

    const albumPage = /\/albums\/([\w-]+)/.test(location.pathname);

    return (
        <div className={classWithModifiers('mu-notif-wrapper', {album: albumPage})}>
            {items.map((notif) => (
                <NotifBackgroundTask
                    key={notif.id}
                    message={notif.message}
                    progress={notif.progress}
                    options={{
                        type: notif.type,
                        icon: notif.icon,
                    }}
                    close={() => dispatch(removeAppNotification(notif.id))}
                    delayBeforeClose={notif.type !== 'action' ? 4000 : undefined}
                />
            ))}
            <UploadingAlert />
        </div>
    );
};

export default AppNotifs;
