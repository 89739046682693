import React from 'react';
import i18n from 'i18next';
import DataGridItem from './DataGridItem';
import {DataGridColumn} from './types';
import {classWithModifiers} from '../../Helpers/css';
import CheckboxRadio from '../Form/CheckboxRadio';

type Props<T> = {
    columns: DataGridColumn<T>[];
    data: string[];
    getItem: (id: string) => T | null | undefined;
    onLineClick?: (id: string) => void;
    selectedIds?: string[];
    setSelectedIds?: (ids: string[]) => void;
    standalone?: boolean;
    withScroll?: boolean;
    withToolbar?: boolean;
    scrollRef?: (node: HTMLDivElement) => void;
    totalItems?: number;
    getAllItems?: () => void;
}

type DataGrid<T> = React.FC<Props<T>>

const DataGrid = <T, >(
    {
        columns,
        data,
        getItem,
        onLineClick,
        selectedIds = [],
        setSelectedIds = () => { /* */ },
        standalone = false,
        withScroll = false,
        withToolbar = false,
        scrollRef,
        totalItems,
        getAllItems,
    }: Parameters<DataGrid<T>>[0]
): ReturnType<DataGrid<T>> => {
    const allSelected = !!data.length && selectedIds.length === data.length;

    const renderMetadataItem = (key: string, count: number, all = false) => (
        <div className={classWithModifiers('mu-data-grid__metadata-item', [key, all && 'all'])}>
            {i18n.t(`datagrid.${key}.${(all && 'all') || (count > 1 ? 'plural' : 'singular')}`, {count})}
        </div>
    );

    return (
        <div ref={scrollRef} className={classWithModifiers('mu-data-grid', { standalone, scroll: withScroll })}>
            <div className={classWithModifiers('mu-data-grid__metadata', { toolbar: withToolbar })}>
                {totalItems !== undefined && renderMetadataItem('total', totalItems)}
                {renderMetadataItem('loaded', data.length, totalItems === data.length)}
                {getAllItems && totalItems !== data.length && (
                    <button
                        className="mu-data-grid__metadata-item"
                        type="button"
                        onClick={getAllItems}
                    >
                        {i18n.t('datagrid.loadAll')}
                    </button>
                )}
                {!!selectedIds.length && renderMetadataItem(
                    'selected',
                    selectedIds.length,
                    totalItems === selectedIds.length,
                )}
            </div>
            <div
                className="mu-data-grid__container"
                style={{
                    gridTemplateColumns: `max-content ${columns.map((col) => col.size || 'auto').join(' ')}`,
                }}
            >
                <div className="mu-data-grid__line">
                    <div className={classWithModifiers('mu-data-grid__head', { toolbar: withToolbar })}>
                        <CheckboxRadio
                            setChecked={() => setSelectedIds(allSelected ? [] : data)}
                            checked={allSelected}
                        />
                    </div>
                    {columns.map((column) => (
                        <div
                            key={column.key}
                            className={classWithModifiers('mu-data-grid__head', { toolbar: withToolbar })}
                        >
                            {column.header}
                        </div>
                    ))}
                </div>
                {data.map((id) => {
                    const selected = selectedIds?.includes(id);
                    return (
                        <div
                            key={id}
                            className={classWithModifiers('mu-data-grid__line', {button: !!onLineClick})}
                            onClick={() => onLineClick && onLineClick(id)}
                        >
                            <div className="mu-data-grid__cell">
                                <CheckboxRadio
                                    setChecked={
                                        () => setSelectedIds(
                                            selected ? selectedIds.filter((selId) => selId !== id) : [...selectedIds, id]
                                        )
                                    }
                                    checked={selected}
                                />
                            </div>
                            <DataGridItem columns={columns} id={id} getItem={getItem} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DataGrid;
