import { Handler } from './type';

import { hitBoxes } from '../layoutUtils';

export const onTouchDown: Handler = ({
    state,
}) => ({
    state: {
        ...state,
        click: {
            downDate: Date.now(),
        },
    },
});

export const onTouchUp: Handler = ({
    state,
    board,
    selectedCell,
}, pointers) => {
    if (!state.click || state.click.downDate + 200 < Date.now()) {
    // too long to be a click. It is a drag and drop
        return;
    }

    const hit = pointers[0] && hitBoxes(board.ids.map((id) => board.cells[id]), pointers[0], board.cells[selectedCell]);

    const id = Object.keys(board.cells).find((idCell) => board.cells[idCell] === hit);

    if (hit && selectedCell !== id) {
        return {
            mode: 'cell',
            selectedCell: id,
            state: {
                ...state,
                hoveredCell: null,
            },
        };
    } if (!hit) {
        return {
            mode: 'layout',
            selectedCell: null,
        };
    }
};
