import React, {useState} from 'react';
import {addMemberToCriterion, Criterion, useProfile} from 'mushin-redux-store';
import i18n from 'i18next';
import DropdownMenu from '../../../../Components/DropdownMenu/DropdownMenu';
import CheckboxRadio from '../../../../Components/Form/CheckboxRadio';
import {displayValue, isChecked, setChecked} from '../../../../Helpers/questionnaires';
import {useAppDispatch} from '../../../../Helpers/hooks';
import MuButton from '../../../../Components/MuButton';
import Spinner from '../../../../Components/Spinner';

type Props = {
    criterion: Criterion;
    profileId: string;
    isIconButton?: boolean;
    onClose?: () => void;
};

const CriteriaEditModalValueSelect: React.FC<Props> = ({ criterion, profileId, isIconButton = true, onClose }) => {
    const [isOpen, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newValue, setNewValue] = useState<string | string[] | null>(null);
    const member = useProfile(profileId);

    const dispatch = useAppDispatch();

    if (!member) return null;

    const value = member.criteria?.find((userCriterion) => userCriterion.id === criterion.id)?.value;
    if (!newValue && value) setNewValue(value);

    return (
        <DropdownMenu
            align="center"
            isOpen={isOpen}
            setIsOpen={setOpen}
            toggle={isIconButton ? (
                <div className="mu-filters-toolbar__filter-toggle">
                    {displayValue(criterion.values, value)}
                </div>
            ) : (
                <div className="mu-tag ">
                    {`${criterion.name}: ${displayValue(criterion.values, value)}`}
                </div>
            )}
        >
            {criterion.values.map((option) => {
                const checked = newValue ? isChecked(option.slug, newValue) : false;
                return (
                    <CheckboxRadio
                        className="mu-dropdown-menu__button-item"
                        key={option.slug}
                        setChecked={() => {
                            setNewValue(setChecked(!checked, option.slug, newValue, !criterion.multiple));
                        }}
                        checked={checked}
                        label={option.label}
                        uniqueSelection={!criterion.multiple}
                    />
                );
            })}
            <MuButton
                className="mu-filters-toolbar__panel-submit"
                label={loading ? <Spinner size="20px" /> : i18n.t('global.validate')}
                handleClick={async () => {
                    if (!loading) {
                        try {
                            setLoading(true);
                            if (newValue) {
                                await dispatch(addMemberToCriterion(criterion.id, profileId, newValue));
                            }
                            setLoading(false);
                            setOpen(false);
                            if (onClose) {
                                onClose();
                            }
                        } catch (e) {
                            setLoading(false);
                        }
                    }
                }}
            />
        </DropdownMenu>
    );
};

export default CriteriaEditModalValueSelect;
