import React from 'react';
import {useNavigate} from 'react-router-dom';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import { ModalBody } from '../../../../Components/Modal/ModalBody';
import MuButton from '../../../../Components/MuButton';

type Props = {
    onClose: () => void;
    projectId: string | undefined;
};

const ValidateAnswersModal: React.FC<Props> = (
    {
        onClose,
        projectId,
    }
) => {
    const navigate = useNavigate();

    return (
        <Modal handleRemoveModal={onClose}>
            <ModalBody>
                <div className="mu-modal__message-card">
                    <h1 className="mu-modal__message-card-title">
                        {i18n.t('questionnaires.success.response.title')}
                    </h1>
                    <p className="mu-modal__message-card-text">
                        {i18n.t('questionnaires.success.response.description')}
                    </p>
                    <p className="mu-modal__message-card-emoji">{'\uD83D\uDC4F'}</p>
                    <MuButton
                        handleClick={() => {
                            if (projectId) navigate(`/projects/${projectId}/missions`);
                            else navigate('/');
                            onClose();
                        }}
                        label={i18n.t('global.continueCoCreation')}
                    />
                </div>
            </ModalBody>
        </Modal>

    );
};

export default ValidateAnswersModal;
