import React, {ReactNode, SVGAttributes} from 'react';
import MuIcon from '../MuIcon';
import {MuSvg} from '../MuSvg';
import {classWithModifiers} from '../../Helpers/css';

type Props = {
    icon?: string | React.FC<SVGAttributes<SVGElement>>;
    label?: ReactNode | string;
    onClick?: () => void;
    panel?: boolean;
}

const GridButtonCard: React.FC<Props> = ({ icon, label, onClick, panel = false }) => (
    <button
        type="button"
        className={classWithModifiers('mu-grid-button-card', {panel})}
        onClick={onClick}
    >
        {icon && (
            <>
                {typeof icon === 'string' ? (
                    <MuIcon className="mu-grid-button-card__icon" svgName={icon} />
                ) : (
                    <MuSvg className="mu-grid-button-card__icon" SvgComp={icon} />
                )}
            </>
        )}
        {label && <span className="mu-grid-button-card__label">{label}</span>}
    </button>
);

export default GridButtonCard;
