import React from 'react';
import { useCurrentOrganization } from 'mushin-redux-store';
import OrganizationPart from './Components/OrganizationPart';
import CompanySettingsLayout from '../../../Components/SettingsComponents/CompanySettingsLayout';

const CompanyDetails: React.FC = () => {
    const organization = useCurrentOrganization();

    if (!organization) return null;

    return organization && (
        <CompanySettingsLayout>
            <OrganizationPart organization={organization} />
        </CompanySettingsLayout>
    );
};

export default CompanyDetails;
