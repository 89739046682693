import {Project, useProject} from 'mushin-redux-store';
import React from 'react';
import TextIcon from '../../../../Components/TextIcon/TextIcon';

const MoveAlbumModalItem: React.FC<{
    id: string,
    handleClick: (selectedProject: Project) => void
}> = ({ id, handleClick }) => {
    const project = useProject(id);

    if (!project) return null;

    return (
        <button className="mu-selector__list-item-button" type="button" onClick={() => handleClick(project)}>
            <TextIcon icon="SubFolderLine" label={project.name} />
        </button>
    );
};

export default MoveAlbumModalItem;
