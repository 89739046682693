import React from 'react';
import {Question, QuestionAnswersQueryParams, Questionnaire, useProject, useQuestionAnswers} from 'mushin-redux-store';
import { useParams } from 'react-router-dom';
import i18n from 'i18next';
import QuestionResultItems from './Components/QuestionResultItems';
import QuestionResultRating from './Components/QuestionResultRating';
import QuestionResultRanking from './Components/QuestionResultRanking';
import QuestionHeader from '../Gallery/Components/QuestionHeader/QuestionHeader';
import FiltersToolbar from '../../Components/FiltersToolbar/FiltersToolbar';
import { isProjectContributor } from '../../Helpers/users';
import { criteriaParamsFilter, questionAnswersFilter } from '../../Components/FiltersToolbar/helpers';
import { useSearchParams } from '../../Helpers/queryParser';
import { FilterConfig } from '../../Components/FiltersToolbar/FilterPanelContent';
import Spinner from '../../Components/Spinner';

type Props = {
    questionnaire: Questionnaire;
    question: Question;
}

type QuestionAnswersSearchParams = Pick<QuestionAnswersQueryParams, 'criteria' | 'answers'>

const QuestionResult: React.FC<Props> = ({questionnaire, question}) => {
    const [searchParams, setSearchParams] = useSearchParams<QuestionAnswersSearchParams>({
        criteria: 'array',
        answers: 'array',
    });
    const questionAnswers = useQuestionAnswers(questionnaire.id, question, searchParams);
    const { projectId } = useParams<Record<string, string>>();
    const project = useProject(projectId);

    const filters: {
        [key: string]: FilterConfig
    } = {
        criteria: criteriaParamsFilter(
            searchParams.criteria,
            (criteria) => setSearchParams({criteria}, true),
        ),
        answers: questionAnswersFilter(
            searchParams.answers,
            (answers) => setSearchParams({answers}, true),
        ),
    };

    return (
        <div className="mu-question-result">
            <QuestionHeader questionnaire={questionnaire} question={question} />
            {project && !isProjectContributor(project) && (
                <FiltersToolbar
                    className="mu-question-result__filter"
                    filters={{}}
                    defaultFilters={filters}
                    resetAll={() => setSearchParams({})}
                />
            )}
            {question.type !== 'description' && (
                !questionAnswers ? (
                    <Spinner className="mu-gallery__loader" size="50px" />
                ) : (
                    <>
                        <h2 className="mu-question-result__respondent-count">
                            {i18n.t('respondents.count', {count: questionAnswers?.nbRespondents || 0})}
                        </h2>
                        {questionAnswers && (
                            ((question.type === 'radios' || question.type === 'checkboxes') && (
                                <QuestionResultItems question={question} answers={questionAnswers} />
                            )) || (question.type === 'rating' && (
                                <QuestionResultRating question={question} answers={questionAnswers} />
                            )) || (question.type === 'ranking' && (
                                <QuestionResultRanking question={question} answers={questionAnswers} />
                            ))
                        )}
                    </>
                )
            )}
        </div>
    );
};

export default QuestionResult;
