import React from 'react';

type LoaderProps = {
    size: string;
    weight?: number;
    className?: string;
}

const Loader: React.FC<LoaderProps> = ({
    size, weight = 0.07, className,
}) => {
    const r = 0.5 - weight;

    return (
        <div className={`loader${className ? ` ${className}` : ''}`} style={{ fontSize: size }}>
            <svg width="1em" height="1em">
                <circle cx="0.5em" cy="0.5em" r={`${r}em`} strokeWidth={`${weight}em`} />
            </svg>
        </div>
    );
};

type Props = LoaderProps & React.PropsWithChildren<{
    global?: boolean;
}>

const Spinner: React.FC<Props> = ({ size, weight, global = false, className, children }) => (
    global ? (
        <div className="loader-wrapper-shadow">
            <Loader size={size} weight={weight} className={className} />
            {children}
        </div>
    ) : (
        <Loader size={size} weight={weight} className={className} />
    )
);

export default Spinner;
