import React from 'react';
import {Album, useCurrentProject} from 'mushin-redux-store';

import i18n from 'i18next';
import AlbumDropdownMenu from '../../../../Components/AlbumDropdownMenu/AlbumDropdownMenu';
import MuButton from '../../../../Components/MuButton';
import AppLayout from '../../../../Components/Layouts/AppLayout';

type Props = React.PropsWithChildren<{
    albumId: string;
    album: Album | null | undefined;
    inspirationType?: 'mine' | 'all' | 'messages';
}>;

const AlbumLayout: React.FC<Props> = ({
    albumId,
    album,
    inspirationType,
    children,
}) => {
    const project = useCurrentProject();

    return (
        <AppLayout
            title={album === null ? i18n.t('album.unavailable') : album?.label}
            backLink={(
                project?.type === 'contributors'
                    ? `/projects/${project.id}/synthesis/clusters`
                    : `/projects/${project?.id}`
            )}
            actionsComponent={!!album && (
                <AlbumDropdownMenu
                    album={album}
                    CustomOptionsBtn={({handleClick}) => (
                        <MuButton
                            rightIcon="ArrowDown"
                            label={i18n.t('album.actions')}
                            handleClick={handleClick}
                        />
                    )}
                    isLastOfLine
                />
            )}
            tabs={[{
                key: 'all',
                to: `/projects/${project?.id}/albums/${albumId}`,
                label: i18n.t('album.ideas.0'),
                active: !inspirationType || inspirationType === 'all',
            }, {
                key: 'messages',
                to: `/projects/${project?.id}/albums/${albumId}/messages`,
                label: i18n.t('global.messages'),
                active: inspirationType === 'messages',
            }]}
        >
            {children}
        </AppLayout>
    );
};

export default AlbumLayout;
