import React from 'react';
import { ActionBar } from './common';
import { ActionProps, ActionBarSeparator, commonButtons, BackgroundPickerButton, LineColorPickerButton } from '../actionButtons/common';
import {LineWidthSelectButton} from '../actionButtons/shape';

export default ((props: ActionProps) => {
    return (
        <ActionBar>
            <LineWidthSelectButton {...props} />
            <ActionBarSeparator />
            <LineColorPickerButton {...props} />
            <BackgroundPickerButton {...props} />
            <ActionBarSeparator />
            {commonButtons(props)}
        </ActionBar>
    );
});
