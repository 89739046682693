import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import {
    QuestionUpdatePayload,
    Questionnaire,
    QuestionItems,
    QuestionRanking
} from 'mushin-redux-store';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import MuIcon from '../../../Components/MuIcon';
import { classWithModifiers } from '../../../Helpers/css';
import EditQuestionImage from './EditQuestionImage';
import {openModal} from '../../../Redux/reducers/modalsSlice';
import {AddModalImages} from '../../AppModals/Components/AddImagesModal/AddIdeaModal';
import {uploadFile} from '../../../Helpers/image';
import {useAppDispatch} from '../../../Helpers/hooks';
import CheckboxRadio from '../../../Components/Form/CheckboxRadio';
import Select from '../../../Components/Select/Select';
import { DnDItemTypes } from '../../../Helpers/dnd';
import QuestionFilterSelection from './QuestionFilterSelection';
import {getDefaultMinChoices} from '../../../Helpers/questionnaires';
import CriterionFilterValuesSelection from './CriterionFilterValuesSelection';
import { useQuestionnaireEditorContext } from './QuestionnaireEditorContext';
import CheckboxRadioBox from '../../../Components/Form/CheckboxRadioBox';

type Props = {
    question: QuestionItems | QuestionRanking,
    updateQuestion: (questionPayload: QuestionUpdatePayload) => void,
    disabled?: boolean;
    noAddItemOption?: boolean;
    noRangeChoiceOption?: boolean;
    nextQuestions?: {id: string; index: number}[];
    questionnaire?: Questionnaire;
}

type Items = NonNullable<QuestionUpdatePayload['items']>

const EditMultipleChoiceItems: React.FC<Props> = (
    {
        question,
        updateQuestion,
        disabled,
        noAddItemOption = false,
        noRangeChoiceOption = false,
        questionnaire,
        nextQuestions = [],
    }
) => {
    const { locale } = useQuestionnaireEditorContext();
    const reduxItems = question.items;
    const [items, setItems] = useState<Items>(
        reduxItems?.map(({ label, labels, image_url, nextQuestion, criterionValues }) => ({
            label: locale === 'current' ? label : labels?.translations?.[locale]?.value || '',
            labels,
            image_url,
            nextQuestion,
            criterionValues,
        })) || []
    );
    const [canAddItem, setCanAddItem] = useState(question.canAddItem || false);
    const [rotateItems, setRotateItems] = useState(question.rotateItems || false);
    const [minChoicesValue, setMinChoices] = useState(question.minChoices);
    const [maxChoicesValue, setMaxChoices] = useState(question.maxChoices);
    const [newLabel, setNewLabel] = useState('');

    const dispatch = useAppDispatch();

    const minChoices = minChoicesValue || getDefaultMinChoices(question, items.length);
    const maxChoices = maxChoicesValue || items.length;

    useEffect(() => {
        setItems((prevItems) => {
            for (let i = 0; i < prevItems.length; i++) {
                const prevItem = prevItems[i];
                const reduxItem = question.items?.[i];
                if (
                    reduxItem?.nextQuestion !== prevItem?.nextQuestion
                    || JSON.stringify(reduxItem?.criterionValues)
                      !== JSON.stringify(prevItem.criterionValues)
                ) {
                    return (
                        question.items?.map(
                            ({ label, labels, image_url, nextQuestion, criterionValues }) => ({
                                label: locale === 'current' ? label : labels?.translations?.[locale]?.value || '',
                                labels,
                                image_url,
                                nextQuestion,
                                criterionValues,
                            })
                        ) || []
                    );
                }
            }
            return prevItems;
        });
    }, [question]);

    useEffect(() => {
        if (locale === 'current') {
            setItems((prevItems) => prevItems.map((it) => ({
                ...it,
                label: it.labels?.original || it.label || '',
            })));
        } else {
            setItems(reduxItems.map((it) => ({
                ...it,
                label: it.labels?.translations?.[locale]?.value || '',
            })));
        }
    }, [locale]);

    const changeMinChoices = (value: number) => {
        const payload: QuestionUpdatePayload = {minChoices: value};
        if (maxChoices < value) {
            setMaxChoices(value);
            payload.maxChoices = value;
        }
        setMinChoices(value);
        updateQuestion(payload);
    };

    const changeMaxChoices = (value: number) => {
        const payload: QuestionUpdatePayload = {maxChoices: value};
        if (minChoices > value) {
            setMinChoices(value);
            payload.minChoices = value;
        }
        setMaxChoices(value);
        updateQuestion(payload);
    };

    const changeNextQuestion = (id: string, index: number) => {
        setItems((prevItems) => {
            const updatedItems: Items = [...prevItems];
            updatedItems[index].nextQuestion = id;
            updateQuestion({items: updatedItems});
            return updatedItems;
        });
    };

    const updateItemLabel = (label: string, index: number, save = false) => {
        setItems((prevItems) => {
            const updatedItems: Items = [...prevItems];
            updatedItems[index].label = label;
            if (save) updateQuestion({items: updatedItems});
            return updatedItems;
        });
    };

    const updateItemImage = (imgUrl: string | null, index: number) => {
        setItems((prevItems) => {
            const updatedItems: Items = [...prevItems];
            updatedItems[index].image_url = imgUrl;
            updateQuestion({items: updatedItems});
            return updatedItems;
        });
    };

    const addItem = (...values: string[]) => {
        const newItems: {label: string}[] = [];
        values.forEach((value) => {
            if (value.trim()) newItems.push({label: value.trim()});
        });
        if (newItems.length) {
            setNewLabel('');
            setItems((prevItems) => {
                const payload: QuestionUpdatePayload = {};
                payload.items = [...prevItems, ...newItems];
                updateQuestion(payload);
                return payload.items;
            });
        }
    };

    const deleteItem = (index: number) => {
        setItems((prevItems) => {
            const payload: QuestionUpdatePayload = {};
            const newLength = prevItems.length - 1;
            if (newLength >= 2) {
                if (minChoices === prevItems.length) {
                    setMinChoices(newLength);
                    payload.minChoices = newLength;
                }
                if (maxChoices === prevItems.length) {
                    setMaxChoices(newLength);
                    payload.maxChoices = newLength;
                }
            }
            const updatedItems: Items = [...prevItems];
            updatedItems.splice(index, 1);
            payload.items = updatedItems;
            updateQuestion(payload);
            return updatedItems;
        });
    };

    const chooseImage = (index: number) => dispatch(openModal('AddIdeaModal', {
        multipleSelection: false,
        textNotes: false,
        addImages: async (addModalImages: AddModalImages) => {
            if (addModalImages.notes.length) {
                const note = addModalImages.notes[0];
                if (note.type !== 'TEXT') {
                    updateItemImage(note.image_src.original, index);
                }
            }
            if (addModalImages.images.length) {
                const url = await dispatch(uploadFile(
                    'new_image',
                    addModalImages.images[0],
                ));
                updateItemImage(url, index);
            }
        },
    }));

    const rangeOptions: number[] = [];
    for (let i = 0; i <= items.length; i++) {
        rangeOptions.push(i);
    }

    const handleReorderOptions = ({ source, destination }: DropResult) => {
        if (!destination || source.index === destination.index) return;
        setItems((prevItems) => {
            const target = prevItems[source.index];

            prevItems.splice(source.index, 1);
            prevItems.splice(destination.index, 0, target);

            const newItems = [...prevItems];
            updateQuestion({ items: newItems });
            return newItems;
        });
    };

    const updateItemCriterionValues = (values: string[], index: number) => {
        setItems((prevItems) => {
            const updatedItems: Items = [...prevItems];
            updatedItems[index].criterionValues = values;
            updateQuestion({items: updatedItems});
            return updatedItems;
        });
    };

    return (
        <DragDropContext onDragEnd={handleReorderOptions}>
            <Droppable droppableId={`${DnDItemTypes.QUESTION}-0`} type={DnDItemTypes.QUESTION}>
                {(droppableProvided) => (
                    <ul
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                        className="mu-question-editor__options"
                    >
                        {!noAddItemOption && (
                            <>
                                <CheckboxRadio
                                    checked={canAddItem}
                                    setChecked={(checked) => {
                                        setCanAddItem(checked);
                                        updateQuestion({canAddItem: checked});
                                    }}
                                    label={i18n.t('questionEdition.canAddItem')}
                                    styleKey="choice"
                                    disabled={disabled}
                                />
                                <CheckboxRadio
                                    checked={rotateItems}
                                    setChecked={(checked) => {
                                        setRotateItems(checked);
                                        updateQuestion({rotateItems: checked});
                                    }}
                                    label={i18n.t('questionEdition.rotateItems')}
                                    styleKey="choice"
                                    disabled={disabled}
                                />
                            </>
                        )}
                        {items.map((item, index) => (
                            <Draggable key={index} draggableId={String(index)} index={index} isDragDisabled={disabled}>
                                {(draggableProvided) => (
                                    <li
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                        className="mu-edit-multiple-choice-item"
                                    >
                                        <div
                                            className="mu-edit-multiple-choice-item__drag-handle-icon"
                                            hidden={disabled}
                                            {...draggableProvided.dragHandleProps}
                                        >
                                            <MuIcon svgName="Grip" />
                                        </div>
                                        <CheckboxRadioBox
                                            checked={false}
                                            uniqueSelection={question.type === 'radios'}
                                            styleKey="choice"
                                        />
                                        <div className="mu-edit-multiple-choice-item__item">
                                            <input
                                                className="mu-edit-multiple-choice-item__input"
                                                placeholder={i18n.t('questionEdition.changeLabelItem') as string}
                                                value={item.label}
                                                onChange={(e) => {
                                                    updateItemLabel(e.currentTarget.value, index);
                                                }}
                                                onBlur={(e) => {
                                                    updateItemLabel(e.currentTarget.value, index, true);
                                                }}
                                                disabled={disabled}
                                            />
                                        </div>
                                        {item.image_url ? (
                                            <EditQuestionImage
                                                className="mu-edit-multiple-choice-item__image"
                                                imageUrl={item.image_url}
                                                updateQuestion={() => updateItemImage(null, index)}
                                            />
                                        ) : (
                                            <button
                                                className="mu-edit-multiple-choice-item__action"
                                                type="button"
                                                onClick={() => chooseImage(index)}
                                            >
                                                <MuIcon
                                                    className="mu-edit-multiple-choice-item__icon"
                                                    svgName="Image"
                                                />
                                            </button>
                                        )}
                                        {(question.type === 'checkboxes' || question.type === 'radios') && question.criterionId && (
                                            <CriterionFilterValuesSelection
                                                selectedCriterion={question.criterionId}
                                                onSelectMembers={(members) => updateItemCriterionValues(members, index)}
                                                selectedMembers={item.criterionValues}
                                                disabled={disabled || !question.criterionId}
                                            />
                                        )}
                                        {noRangeChoiceOption && questionnaire && (
                                            <QuestionFilterSelection
                                                selectedNextQuestion={item?.nextQuestion}
                                                selectedNextQuestionIndex={questionnaire?.questions?.findIndex(
                                                    (it) => it.id === item?.nextQuestion
                                                ) + 1}
                                                nextQuestions={nextQuestions}
                                                onSelectNextQuestion={(id) => changeNextQuestion(id, index)}
                                                disabled={disabled}
                                            />
                                        )}
                                        <button
                                            className="mu-edit-multiple-choice-item__action"
                                            type="button"
                                            onClick={() => deleteItem(index)}
                                        >
                                            <MuIcon
                                                className="mu-edit-multiple-choice-item__icon"
                                                svgName="Remove"
                                            />
                                        </button>
                                    </li>
                                )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                        {!disabled && (
                            <div className={classWithModifiers('mu-edit-multiple-choice-item', ['item-to-add'])}>
                                <CheckboxRadioBox
                                    checked={false}
                                    uniqueSelection={question.type === 'radios'}
                                    styleKey="choice"
                                />
                                <div className="mu-edit-multiple-choice-item__item">
                                    <input
                                        className="mu-edit-multiple-choice-item__input"
                                        placeholder={i18n.t('questionEdition.addItem') as string}
                                        value={newLabel}
                                        onChange={(e) => setNewLabel(e.currentTarget.value)}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' || event.key === 'Tab') {
                                                if (event.currentTarget.value) {
                                                    event.preventDefault();
                                                    addItem(event.currentTarget.value);
                                                }
                                            }
                                        }}
                                        onBlur={(event) => {
                                            addItem(event.currentTarget.value);
                                        }}
                                        onPaste={(event) => {
                                            const labels = event.clipboardData.getData('Text').split('\n');
                                            const lastLabel = labels.pop();
                                            addItem(...labels);
                                            if (lastLabel) {
                                                setNewLabel(lastLabel);
                                            }
                                            event.preventDefault();
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {!noRangeChoiceOption && items.length >= 2 && (
                            <div className="mu-question-editor__choice-range">
                                {i18n.t('questionEdition.rangeChoices.0')}
                                <Select value={minChoices}>
                                    {rangeOptions.map((opt) => (
                                        <button
                                            key={opt}
                                            className="mu-dropdown-menu__button-item mu-question-editor__choice-range-item"
                                            type="button"
                                            onClick={() => changeMinChoices(opt)}
                                        >
                                            {opt}
                                        </button>
                                    ))}
                                </Select>
                                {i18n.t('questionEdition.rangeChoices.1')}
                                <Select value={maxChoices}>
                                    {rangeOptions.map((opt) => opt >= 2 && (
                                        <button
                                            key={opt}
                                            className="mu-dropdown-menu__button-item mu-question-editor__choice-range-item"
                                            type="button"
                                            onClick={() => changeMaxChoices(opt)}
                                        >
                                            {opt}
                                        </button>
                                    ))}
                                </Select>
                                {i18n.t('questionEdition.rangeChoices.2')}
                            </div>
                        )}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default EditMultipleChoiceItems;
