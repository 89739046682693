import React from 'react';
import i18n from 'i18next';
import StatusSelectButton from './StatusSelectButton';
import {classWithModifiers} from '../../../../Helpers/css';

export const memberStatusLabelKeys: {[key: string]: string} = {
    registered: 'models.users.registered',
    invited: 'global.invited',
    active: 'global.active',
};

type Props = {
    status: string | undefined;
    setStatus: (status: string | undefined) => void;
    withActive: boolean;
}

const MemberStatusDropdownPanel: React.FC<Props> = (
    {status, setStatus, withActive}
) => (
    <>
        <button
            className={classWithModifiers('mu-dropdown-menu__button-item', {selected: !status})}
            type="button"
            onClick={() => setStatus(undefined)}
        >
            {i18n.t('tooltips.all')}
        </button>
        {withActive && (
            <StatusSelectButton
                status="active"
                setStatus={setStatus}
                labelKey={memberStatusLabelKeys.active}
                modifier="valid"
                selected={status === 'active'}
            />
        )}
        <StatusSelectButton
            status="registered"
            setStatus={setStatus}
            labelKey={memberStatusLabelKeys.registered}
            modifier="info"
            selected={status === 'registered'}
        />
        <StatusSelectButton
            status="invited"
            setStatus={setStatus}
            labelKey={memberStatusLabelKeys.invited}
            modifier="warning"
            selected={status === 'invited'}
        />
    </>
);

export default MemberStatusDropdownPanel;
