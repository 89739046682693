import moment from 'moment';

export const dateToProgressBar = (startDate: string | undefined, endDate: string | undefined): [number, number] => {
    if (startDate && endDate) {
        const now = moment();
        const startMoment = moment(startDate);
        const endMoment = moment(endDate);
        const totalDays = endMoment.diff(startMoment, 'days', true);
        if (endMoment.isBefore(now)) {
            return [totalDays, totalDays];
        }
        if (now.isBefore(startMoment)) {
            return [0, totalDays];
        }
        return [
            now.diff(startMoment, 'days', true),
            endMoment.diff(startMoment, 'days', true)
        ];
    }
    return [0.25, 0.5];
};

export const getStatus = (
    startDate: string | undefined,
    endDate: string | undefined,
    archivedAt: string | undefined,
): 'archived' | 'draft' | 'scheduled' | 'finished' | 'inProgress' => {
    if (archivedAt) {
        return 'archived';
    }
    if (!startDate) return 'draft';
    if (startDate) {
        const startDateTimestamp = new Date(startDate).getTime();
        if (Date.now() < startDateTimestamp) {
            return 'scheduled';
        }
    }
    if (endDate) {
        const endDateTimestamp = new Date(endDate).getTime();
        if (Date.now() > endDateTimestamp) {
            return 'finished';
        }
    }
    return 'inProgress';
};

export const formatPastDateTime = (dateString: string): string => {
    const date = moment(dateString);
    const diff = date.diff(moment(), 'days');
    if (diff >= -1) return date.calendar();
    return date.format('L LT');
};
