import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../Components/DropdownMenu/DropdownMenu';
import MuIcon from '../../../Components/MuIcon';

type Props = {
    onSelectNextQuestion: (id: string) => void;
    nextQuestions: {id: string; index: number}[];
    selectedNextQuestion?: string;
    selectedNextQuestionIndex?: number;
    disabled?: boolean;
}

const QuestionFilterSelection: React.FC<Props> = ({
    selectedNextQuestion, selectedNextQuestionIndex, nextQuestions, disabled, onSelectNextQuestion,
}) => {
    const { t } = useTranslation();

    const [isMenuOpen, setMenuOpen] = useState(false);
    
    const onSelect = (id: string) => {
        onSelectNextQuestion(id);
        setMenuOpen(false);
    };

    return (
        <DropdownMenu
            isOpen={isMenuOpen}
            setIsOpen={setMenuOpen}
            toggle={(
                <button
                    type="button"
                    className="mu-dropdown__toggle mu-question-editor__filter-icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        setMenuOpen(!isMenuOpen);
                    }}
                    disabled={disabled}
                >
                    <MuIcon svgName="Filter" />
                    {((typeof selectedNextQuestionIndex === 'number' && selectedNextQuestionIndex > 0) || selectedNextQuestion === 'end') && (
                        <div className="mu-question-editor__filter-value">
                            {!disabled && (
                                <button
                                    type="button"
                                    className="mu-question-editor__remove-filter-icon"
                                    onClick={(e) => { e.stopPropagation(); onSelect(''); }}
                                >
                                    <MuIcon svgName="Close" />
                                </button>
                            )}
                            {selectedNextQuestion === 'end' ? t('questionnaires.end') : `Q${selectedNextQuestionIndex}`}
                        </div>
                    )}
                </button>
            )}
        >
            {nextQuestions.map((question) => (
                <li
                    key={question.id}
                    onClick={() => onSelect(question.id)}
                    className="mu-dropdown-menu__button-item"
                >
                    <button type="button">{`Q${question.index}`}</button>
                </li>
            ))}
            {nextQuestions.length > 0 && (
                <li
                    onClick={() => onSelect('end')}
                    className="mu-dropdown-menu__button-item"
                >
                    <button type="button">{t('questionnaires.end')}</button>
                </li>
            )}
        </DropdownMenu>
    );
};

export default QuestionFilterSelection;
