import React from 'react';
import {Question, QuestionAnswer as QuestionAnswerType} from 'mushin-redux-store';
import i18n from 'i18next';
import {AnswerValue} from './types';
import MultipleChoicesAnswer from './components/MultipleChoicesAnswer';
import RatingAnswer from './components/RatingAnswer';
import RankingAnswer from './components/RankingAnswer';
import { useAppDispatch } from '../../Helpers/hooks';
import { openLightbox } from '../../Redux/reducers/lightboxSlice';
import FreeAnswer from './components/FreeAnswer';
import CustomSwiper from '../../Components/CustomSwiper/CustomSwiper';
import QuillTranslation from '../../Components/Translation/QuillTranslation';
import VideoFullscreen from '../../Components/VideoFullscreen/VideoFullscreen';

type Props = {
    question: Question;
    previousAnswer: QuestionAnswerType | undefined;
    answer: AnswerValue | null;
    setAnswer: (answer: AnswerValue) => void;
    index: number;
    total: number;
    error?: string;
    disabled?: boolean;
}

const QuestionAnswer: React.FC<Props> = ({
    question, previousAnswer, answer, setAnswer, error, index, total, disabled = false,
}) => {
    const dispatch = useAppDispatch();

    const renderAnswerComponent = (): React.ReactNode => {
        switch (question.type) {
            case 'checkboxes':
            case 'radios':
                return (
                    <MultipleChoicesAnswer
                        question={question}
                        value={answer?.value || previousAnswer?.value || null}
                        setValue={setAnswer}
                        uniqueSelection={question.type === 'radios'}
                        disabled={disabled}
                    />
                );
            case 'free':
                return (
                    <FreeAnswer
                        question={question}
                        value={answer?.notes || previousAnswer?.notes}
                        setValue={setAnswer}
                        disabled={disabled}
                    />
                );
            case 'rating':
                return (
                    <RatingAnswer
                        question={question}
                        value={answer?.value || previousAnswer?.value || null}
                        setValue={setAnswer}
                        disabled={disabled}
                    />
                );
            case 'ranking':
                return (
                    <RankingAnswer
                        question={question}
                        value={answer?.value || previousAnswer?.value || null}
                        setValue={setAnswer}
                        disabled={disabled}
                    />
                );
            default:
        }
        return null;
    };

    return (
        <div className="mu-question">
            {question.type !== 'description' && (
                <div className="mu-question__index">
                    {i18n.t('questionnaires.questionIndex', {index: index + 1, total})}
                </div>
            )}
            <div className="mu-question__title">
                <QuillTranslation
                    translationProp={question.labels}
                    fallback={question.label}
                    className="mu-rich-input"
                    theme=""
                    additionalTitle={question.mandatory && (
                        <span className="mu-question__mandatory">*</span>
                    )}
                />
            </div>
            {!!question.cover_urls?.length && (
                <CustomSwiper>
                    {question.cover_urls.map((cover, coverIndex) => (cover.type === 'video' ? (
                        <VideoFullscreen
                            key={cover.url}
                            videoUrl={cover.url}
                            controls
                            className="mu-question__video"
                        />
                    ) : (
                        <img
                            key={cover.url}
                            src={cover.url}
                            alt=""
                            className="mu-question__image"
                            onClick={() => dispatch(openLightbox({
                                urls: question.cover_urls?.map((c) => c.url) as string[],
                                index: coverIndex,
                            }))}
                        />
                    )))}
                </CustomSwiper>
            )}
            {error && (
                <div className="mu-question__error">{i18n.t(`questionnaires.error.${error}`)}</div>
            )}
            {renderAnswerComponent()}
        </div>
    );
};

export default QuestionAnswer;
