import React from 'react';
import {useParams} from 'react-router-dom';
import {useProject, useQuestionnaire} from 'mushin-redux-store';
import SessionsTemplate from '../Sessions/Components/SessionsLayout/SessionsTemplate';
import QuestionnaireAnswer from './QuestionnaireAnswer';
import {classWithModifiers} from '../../Helpers/css';

type Params = {
    questionnaireId: string;
}

const QuestionnaireAnswerPublic: React.FC = () => {
    const { questionnaireId } = useParams<Params>();
    const questionnaire = useQuestionnaire(questionnaireId);
    const project = useProject(questionnaire?.project_id);

    return (
        <SessionsTemplate>
            <div className="mu-questionnaire-answer__organization-logo">
                {(project && (
                    <div
                        className={classWithModifiers(
                            'mu-bordered-rounded-picture',
                            {placeholder: !project.organization.logo_src, large: true}
                        )}
                    >
                        {project.organization.logo_src ? (
                            <img
                                src={project.organization.logo_src}
                                alt={project.organization.name}
                                className="mu-bordered-rounded-picture__img"
                            />
                        ) : project.organization.name}
                    </div>
                ))}
                {'\uD83E\uDD1D'}
                <div
                    className={classWithModifiers(
                        'mu-bordered-rounded-picture',
                        {large: true}
                    )}
                >
                    <img
                        src="/assets/images/logo-app.png"
                        alt="Mushin logo"
                        className="mu-bordered-rounded-picture__img"
                    />
                </div>
            </div>
            {!!project?.organization.name && (
                <div className="mu-questionnaire-answer__organization-name">
                    {project?.organization.name}
                    {' x Mushin'}
                </div>
            )}
            <QuestionnaireAnswer questionnaire={questionnaire} />
        </SessionsTemplate>
    );
};

export default QuestionnaireAnswerPublic;
