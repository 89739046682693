import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import UserAgentRedirect from '../UserAgentRedirection';
import {useAppSelector} from '../Helpers/hooks';
import TermsModal from '../Scenes/Mushin/MushinModals/TermsModal';

const PrivateRoute: React.FC<React.PropsWithChildren<{ showOnMobile?: boolean }>> = ({showOnMobile, children}) => {
    const isConnected = useAppSelector((state) => state.auth.isConnected);
    const isConfirmed = useAppSelector((state) => !!state.auth.user?.confirmed_at);
    const shouldAcceptCGU = useAppSelector((state) => state.auth.user?.shouldAcceptCGU);
    const shouldAcceptGDPR = useAppSelector((state) => state.auth.user?.shouldAcceptGDPR);
    const location = useLocation();

    if (!isConnected) {
        if (location) {
            localStorage.setItem('redirectAfterLogin', `${location.pathname}${location.search}`);
        }
        return showOnMobile ? (
            <Navigate to="/login" state={{ from: location }} replace />
        ) : (
            <UserAgentRedirect>
                <Navigate to="/login" state={{ from: location }} replace />
            </UserAgentRedirect>
        );
    }
    if (!isConfirmed) {
        if (location) {
            localStorage.setItem('redirectAfterLogin', `${location.pathname}${location.search}`);
        }
        return showOnMobile ? (
            <Navigate to="/register" state={{ from: location }} replace />
        ) : (
            <UserAgentRedirect>
                <Navigate to="/register" state={{ from: location }} replace />
            </UserAgentRedirect>
        );
    }

    if (showOnMobile) {
        return (
            <>{children}</>
        );
    }

    return (
        <UserAgentRedirect>
            {shouldAcceptCGU ? (
                <TermsModal type="cgu" />
            ) : (shouldAcceptGDPR && (
                <TermsModal type="gdpr" />
            ))}
            {children}
        </UserAgentRedirect>
    );
};

export default PrivateRoute;
