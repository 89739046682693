import {
    addQuestion,
    createQuestionnaire, QuestionAddPayload,
    Questionnaire,
    QuestionnaireCreatePayload,
} from 'mushin-redux-store';
import i18n from 'i18next';
import {AsyncAppThunk} from '../Redux/reducers';
import {formatQuestionTitle} from './questionnaires';

export const createAndInitQuestionnaire = (
    questionnaireCreatePayload?: Omit<QuestionnaireCreatePayload, 'title'> & {
        title?: string;
    },
    questionPayload?: QuestionAddPayload
): AsyncAppThunk<Questionnaire> => async (dispatch, getState): Promise<Questionnaire> => {
    const state = getState();
    const currentOrgaId = state.auth.currentOrganizationId;
    const questionnaire = await dispatch(
        createQuestionnaire({
            organization_id: currentOrgaId,
            title: i18n.t<string>('questionnaires.newDefaultQuestionnaire'),
            ...questionnaireCreatePayload,
        })
    );
    dispatch(
        addQuestion(questionnaire.id, {
            type: 'free',
            freeAnswerTypes: ['text'],
            label: formatQuestionTitle(i18n.t<string>('questionEdition.newDefaultQuestion')),
            mandatory: true,
            ...questionPayload,
        })
    );

    return questionnaire;
};
