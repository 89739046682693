import * as React from 'react';
import {NoteQueryParams, useAlbum, useProject} from 'mushin-redux-store';
import {useParams} from 'react-router-dom';
import i18n from 'i18next';
import SortBySelect from '../FiltersToolbar/Elements/SortBySelect';
import FiltersToolbar from '../FiltersToolbar/FiltersToolbar';
import {
    authorsFilter,
    criteriaParamsFilter,
    favoritesFilter,
    groupsFilter,
    hashtagsFilter,
    noteTypeFilter,
    questionAnswersFilter,
} from '../FiltersToolbar/helpers';
import GalleryNoteBucket from '../../Scenes/Gallery/Components/GalleryNoteBucket/GalleryNoteBucket';
import {FilterConfig} from '../FiltersToolbar/FilterPanelContent';
import MainSearch from '../MainSearch/MainSearch';
import {SetSearchParams} from '../../Helpers/queryParser';
import {isProjectContributor} from '../../Helpers/users';
import {GalleryParams} from '../../Scenes/Gallery/types';

export type NoteSearchParams = Omit<NoteQueryParams, 'is_favorite'> & {
    is_favorite?: string;
}

type Props = {
    searchParams: NoteSearchParams;
    setSearchParams: SetSearchParams<NoteSearchParams>;
    tags: {key: string; doc_count: number}[];
    gallery: string | undefined;
};

const GalleryToolbar: React.FC<Props> = (
    {
        searchParams,
        setSearchParams,
        tags,
        gallery,
    }
) => {
    const {questionnaireId, album_id: albumId, projectId } = useParams<GalleryParams>();
    const project = useProject(projectId);
    const album = useAlbum(albumId);

    const filters: {
        [key: string]: FilterConfig
    } = {
        hashtags: hashtagsFilter(
            searchParams.hashtags,
            (hashtags) => setSearchParams({hashtags}, true),
            tags
        ),
        type: noteTypeFilter(
            searchParams.types,
            (types) => setSearchParams({types}, true)
        ),
        criteria: criteriaParamsFilter(
            searchParams.criteria,
            (criteria) => setSearchParams({criteria}, true),
        ),
    };

    if (gallery === 'all' || albumId || questionnaireId) {
        filters.authors = authorsFilter(
            searchParams.profiles,
            (profiles) => setSearchParams({...searchParams, profiles}),
            questionnaireId ? {isContributor: true, active: true} : {}
        );
        if (!questionnaireId) {
            filters.groups = groupsFilter(
                searchParams.groups,
                (groups) => setSearchParams({groups}, true),
            );
        }
        filters.favoriteOfUsers = favoritesFilter(
            searchParams.favorite_of_users,
            (favorite_of_users) => setSearchParams({favorite_of_users}, true),
        );
    }
    
    if (questionnaireId) {
        filters.answers = questionAnswersFilter(
            searchParams.answers,
            (answers) => setSearchParams({answers}, true),
        );
    }

    return (
        <section className="mu-toolbar">
            <GalleryNoteBucket
                albumId={albumId}
            />
            <div className="mu-toolbar__filters">
                <MainSearch
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    aggregations={tags}
                />
                {project && !isProjectContributor(project) && (
                    <FiltersToolbar
                        filters={filters}
                        resetAll={() => setSearchParams({})}
                    />
                )}
                <SortBySelect
                    sort={searchParams.sort}
                    setSort={(sort) => setSearchParams({sort}, true)}
                    options={[
                        {key: 'rank', icon: 'Line', label: i18n.t('components.sort.order'), hidden: !album?.ranked},
                        {key: '-created_at', icon: 'Calendar', label: i18n.t('components.sort.mostRecent')},
                        {key: 'created_at', icon: 'Calendar', label: i18n.t('components.sort.oldest')},
                        {
                            key: '-comments',
                            icon: 'Comment',
                            label: i18n.t('components.sort.mostCommented'),
                            hidden: !albumId && !projectId,
                        },
                        {
                            key: '-reactions',
                            icon: 'Heart',
                            label: i18n.t('components.sort.mostReactions'),
                            hidden: !albumId && !projectId,
                        },
                        {key: '-favorites', icon: 'Star', label: i18n.t('components.sort.mostFavorites')}
                    ]}
                    defaultSort={album?.ranked ? 'rank' : '-created_at'}
                />
            </div>
        </section>
    );
};

export default GalleryToolbar;
