import React from 'react';
import i18n from 'i18next';
import {useParams} from 'react-router-dom';
import {useProject} from 'mushin-redux-store';
import SynthesisLayout from '../../SynthesisLayout';
import HeaderSynthesis from '../../HeaderSynthesis';
import OpportunitiesList from './OpportunitiesComponents/OpportunitiesList';
import PanelSynthesisQuotes from '../../../../Components/PanelSynthesis/PanelSynthesisQuotes';

const SynthesisOpportunities: React.FC = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const project = useProject(projectId);

    if (!project) return null;

    return (
        <SynthesisLayout project={project} activeTab="opportunities">
            <div className="mu-app-layout__scroll mu-synthesis">
                <HeaderSynthesis
                    activeMode="Opportunities"
                    text={i18n.t<string>('SynthesisOpportunities.text')}
                    span={i18n.t<string>('SynthesisOpportunities.span')}
                    textButton={i18n.t<string>('SynthesisOpportunities.button')}
                />
                <OpportunitiesList project={project} />
                <div className="mu-synthesis__section-panel">
                    <PanelSynthesisQuotes
                        activeMode="Solutions"
                        title={i18n.t<string>('SectionPanelSynthesis.Solutions.title')}
                        description={i18n.t<string>('SectionPanelSynthesis.Solutions.text')}
                        createLabel={i18n.t<string>('SectionPanelSynthesis.Solutions.label')}
                        itemLabel={i18n.t<string>('SectionPanelSynthesis.Solutions.span')}
                    />
                </div>
            </div>
        </SynthesisLayout>
    );
};
export default SynthesisOpportunities;
