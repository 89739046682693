import React from 'react';
import {QuestionAnswersQueryResult, QuestionItems} from 'mushin-redux-store';
import CheckboxRadio from '../../../Components/Form/CheckboxRadio';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import {roundWithPrecision} from '../../../Helpers/math';
import { useAppDispatch } from '../../../Helpers/hooks';
import { openLightbox } from '../../../Redux/reducers/lightboxSlice';
import TextTranslation from '../../../Components/Translation/TextTranslation';

type Props = {
    question: QuestionItems;
    answers: QuestionAnswersQueryResult<QuestionItems>;
}

const QuestionResultItems: React.FC<Props> = ({question, answers}) => {
    const items = [...question.items];
    const dispatch = useAppDispatch();

    const renderItem = (item: { slug: string; label: string; image_url?: string; }, count: number, ratio: number) => (
        <React.Fragment key={item.slug}>
            <CheckboxRadio
                className="mu-question-result__item-value"
                checked={!!count}
                setChecked={() => { /* */ }}
                label={(
                    <>
                        {item.image_url && (
                            <img
                                src={item.image_url}
                                alt=""
                                className="mu-question-result__item-image"
                                onClick={() => dispatch(openLightbox({ urls: [item.image_url as string] }))}
                            />
                        )}
                        <TextTranslation translationProp={item.labels} fallback={item.label} />
                    </>
                )}
                uniqueSelection={question.type === 'radios'}
                styleKey="choice"
            />
            <ProgressBar className="mu-question-result__item-progress" value={ratio} maxValue={1} />
            <div className="mu-question-result__item-count">
                {`${roundWithPrecision(ratio * 100, 1)}% (${count})`}
            </div>
        </React.Fragment>
    );

    return (
        <div className="mu-question-result__items">
            {answers.stats.map(({value, count, ratio}) => {
                const index = items.findIndex((item) => item.slug === value);
                if (index < 0) return renderItem({slug: value, label: value}, count, ratio);
                const item = items.splice(index, 1)[0];
                return renderItem(item, count, ratio);
            })}
            {items.map((item) => renderItem(item, 0, 0))}
        </div>
    );
};

export default QuestionResultItems;
