import {useEffect, useState} from 'react';

const MOBILE_SIZE = 600;

const useIsMobileSize = (): boolean => {
    const [isMobileSize, setIsMobileSize] = useState(window.innerWidth < MOBILE_SIZE);

    useEffect(() => {
        const windowSizeHandler = () => {
            const value = window.innerWidth < MOBILE_SIZE;
            if (isMobileSize !== value) setIsMobileSize(value);
        };
        window.addEventListener('resize', windowSizeHandler);

        return () => {
            window.removeEventListener('resize', windowSizeHandler);
        };
    }, [isMobileSize]);

    return isMobileSize;
};

export default useIsMobileSize;
