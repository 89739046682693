let apiV3_base_path;
let socket_base_path;
let ga_id;
const sentry_id = 'https://f6b245bcc6274e26bf1567d323044500@o97671.ingest.us.sentry.io/241010';
const intercom_app_id = 't6a8nrfj';

const domain = 'mymushin.com';

let sentry_enabled = true;
switch (window.location.host) {
    case 'app.mymushin.com':
        apiV3_base_path = `https://app.${domain}`;
        socket_base_path = `https://socket.${domain}`;
        ga_id = 'UA-77193944-1';
        break;

    case 'pre-prod.mymushin.com':
        apiV3_base_path = `https://pre-prod.${domain}`;
        socket_base_path = `https://pre-prod-socket.${domain}`;
        break;

    case 'dev-web.mymushin.com':
        apiV3_base_path = `https://dev-web.${domain}`;
        socket_base_path = `https://dev-socket.${domain}`;
        ga_id = 'UA-108934576-1';
        break;

    default:
        apiV3_base_path = `http://${window.location.hostname}:4000`;
        socket_base_path = `http://${window.location.hostname}:8000`;
        sentry_enabled = false;
        break;
}

export const APP_CONFIG = {
    API_V3_HOST: apiV3_base_path,
    API_URL_V3: `${apiV3_base_path}/api/v3/`,
    SOCKET_CHAT_URL: `${socket_base_path}/chat`,
    SOCKET_NOTIFICATIONS_URL: `${socket_base_path}/notifications`,
    LOCATION_HOST: window.location.host,
    GA: {
        app_id: ga_id,
        conf: {
            debug: BUILD_NODE_ENV === 'development',
        },
    },
    INTERCOM: {
        app_id: intercom_app_id,
    },
    SENTRY: {
        app_id: sentry_id,
        environment: BUILD_NODE_ENV === 'development' ? 'development' : 'production',
        enabled: sentry_enabled,
    },
    APP_SETTINGS: {
        demo_url: 'https://mymushin.com/?popin=ask_for_a_demo',
        fileTransfer: { fileMaxSize: 157286400 },
        moodboard_width: 2000,
    },
};

export const IS_LOCAL_DEV_ENV = (BUILD_NODE_ENV === 'development' && APP_CONFIG.LOCATION_HOST.includes('localhost'));
export const imgWithCredentials = IS_LOCAL_DEV_ENV ? false : 'use-credentials';
