import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

const GlobalErrors: React.FC = () => {
    const type = window.location.pathname.substring(1);
    return (
        <main className="mu-global-error">
            <div className="mu-global-error__container">
                <img src="/assets/images/LogoMushinSmallWhite.svg" alt="mushinLogo" className="mu-global-error__logo" />
                <h1 className="mu-global-error__status">{i18n.t(`globalErrors.status.${type}`)}</h1>
                <h2 className="mu-global-error__title">{i18n.t(`globalErrors.title.${type}`)}</h2>
                <p className="mu-global-error__details">{i18n.t(`globalErrors.details.${type}`)}</p>
                <footer className="mu-global-error__actions">
                    {type === '403' && (
                        <>
                            {i18n.t('globalErrors.footer.403.backTo')}
                            <Link to="/" className="mu-global-error__actions-link">
                                {i18n.t('globalErrors.footer.403.goHome')}
                            </Link>
                        </>
                    )}
                    {type === '404' && (
                        <>
                            {i18n.t('globalErrors.footer.404.backTo')}
                            <Link to="/" className="mu-global-error__actions-link">
                                {i18n.t('globalErrors.footer.404.goHome')}
                            </Link>
                            {
                                /* {i18n.translate('globalErrors.footer.404.or')}*/
                            }
                            {
                                /* <Link to="/" className="mu-global-error__actions-link">*/
                            }
                            {
                                /* {i18n.translate('globalErrors.footer.404.contactUs')}*/
                            }
                            {
                                /* </Link>*/
                            }
                        </>
                    )}
                    {type === 'invitationExpired' && (
                        <>
                            <Link to="/login" className="mu-global-error__actions-link">
                                {i18n.t('globalErrors.footer.invitationExpired.connect')}
                            </Link>
                            {
                                /* {i18n.translate('globalErrors.footer.invitationExpired.or')}*/
                            }
                            {
                                /* <Link to="/" className="mu-global-error__actions-link">*/
                            }
                            {
                                /* {i18n.translate('globalErrors.footer.invitationExpired.contactUs')}*/
                            }
                            {
                                /* </Link>*/
                            }
                        </>
                    )}
                </footer>
            </div>
        </main>
    );
};

export default GlobalErrors;
