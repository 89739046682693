import React, {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';
import { useMe, User, useTimelineItem } from 'mushin-redux-store';
import moment from 'moment';
import ReactQuill from 'react-quill-new';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import {classWithModifiers} from '../../Helpers/css';
import getMessageKey from './utils/getMessageKey';
import {buildContextualMessageParams, buildObjectHandler, buildTargetHandler} from './utils';
import NoteThumbnail from '../NoteThumbnail/NoteThumbnail';
import Popover from '../Popover/Popover';
import MissionItem from '../../Scenes/Missions/MissionItem';
import MuIcon from '../MuIcon';
import Badge from '../Badge/Badge';

const DEFAULT_SHOWING_AVATARS = 2;

type Props = {
    id: string;
    light?: boolean;
}

const renderActors = (actors: User[]) => {
    if (!actors || !actors.length) return null;

    const actorsNames = [];
    for (const actor of actors) {
        actorsNames.push(actor.name);
    }
    const last = actorsNames.pop();
    return actorsNames.length ? `${actorsNames.join(', ')} and ${last}` : last;
};

const renderUpdatedFields = (fields: string[]) => {
    const fieldsNames: string[] = [];
    for (const field of fields) {
        fieldsNames.push(i18n.t(`global.info.${field}`));
    }
    const last = fieldsNames.pop();
    const result = (i18n.t('global.info.updated', {
        count: fields.length,
        info: fieldsNames.length ? `${fieldsNames.join(', ')} and ${last}` : last,
    }) as string);
    return result.charAt(0).toUpperCase() + result.slice(1);
};

const Activity: React.FC<Props> = ({id, light = false}) => {
    const navigate = useNavigate();
    const me = useMe();
    const timelineItem = useTimelineItem(id);
    if (!timelineItem || !me) return null;

    const messageKey = getMessageKey(timelineItem, me);
    if (!messageKey) return null;

    const targetHandler = buildTargetHandler(timelineItem);

    const handleTargetClick = () => {
        const path = targetHandler && targetHandler();
        if (path) {
            navigate(path);
        }
    };

    const avatarList = timelineItem.event_type === 'custom'
        ? [me]
        : timelineItem.actors?.slice(0, DEFAULT_SHOWING_AVATARS) || [];
    const moreAvatars = avatarList.slice(DEFAULT_SHOWING_AVATARS)?.length;

    return (
        <div
            role="button"
            tabIndex={0}
            className="mu-activity"
            onKeyUp={handleTargetClick}
            onClick={handleTargetClick}
        >
            <div className="mu-activity__infos">
                <time
                    className={classWithModifiers('mu-activity__how-long-ago', { unread: !timelineItem.seen })}
                    dateTime={timelineItem.issued_at}
                >
                    {moment(timelineItem.issued_at).fromNow()}

                    {!timelineItem.viewed
                        && (
                            <Badge className="mu-activity__new" classModifiers={['simple', 'warning']}>
                                {i18n.t('gallery.note.isNew')}
                            </Badge>
                        )}

                </time>
                <h2 className="mu-activity__title">
                    {!!avatarList.length && (
                        <div className="mu-activity__avatar-container">
                            {avatarList.map((it) => (
                                <div key={it.id} className="mu-activity__avatar">
                                    <img
                                        src={it.user?.avatar_url || '/assets/images/defaultAvatar.png'}
                                        alt="img"
                                        width="100%"
                                    />
                                </div>
                            ))}
                            {!!moreAvatars && (
                                <div className="mu-activity__avatar">
                                    <span className="mu-activity__avatar-more">
                                        {`+${moreAvatars}`}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    {timelineItem.event_type === 'custom' ? (
                        <>
                            {i18n.t('global.hello')}
                            {' '}
                            <span className="mu-activity__title-1">{me.name}</span>
                            {', '}
                            <span className="mu-activity__title-2">{timelineItem.message}</span>
                        </>
                    ) : (
                        <Trans
                            i18nKey={`Timeline.${messageKey}`}
                            values={{
                                username: renderActors(timelineItem.actors),
                                ...buildContextualMessageParams(timelineItem),
                            }}
                            components={{
                                1: <span className="mu-activity__title-1" />,
                                2: <span className="mu-activity__title-2" />,
                            }}
                        >
                            {timelineItem.event_type}
                        </Trans>
                    )}
                </h2>
                {!light && timelineItem.objects && !!timelineItem.objects.length && (
                    <div className="mu-activity__sublist">
                        {timelineItem.objects.map((object) => {
                            const isNote = object.type === 'Note';
                            const isNoteImage = isNote && object.object_versionned.type !== 'TEXT';
                            const isNoteText = isNote && object.object_versionned.type === 'TEXT';
                            const isChatMessage = object.type === 'ChatMessage';
                            const isMission = object.type === 'Mission';

                            const objectHandler = buildObjectHandler(
                                timelineItem, object,
                            );

                            const handleObjectClick = () => {
                                const path = objectHandler && objectHandler();
                                if (path) {
                                    navigate(path);
                                }
                            };

                            let objectContent: ReactNode = object.display_name;

                            if (isNote) {
                                objectContent = object.updated_fields?.length ? (
                                    <Popover
                                        position="top"
                                        hoveredElement={<NoteThumbnail note={object.object_versionned} small />}
                                    >
                                        {renderUpdatedFields(object.updated_fields)}
                                    </Popover>
                                ) : (
                                    <NoteThumbnail note={object.object_versionned} small />
                                );
                            }
                            if (isChatMessage) {
                                objectContent = (
                                    <ReactQuill
                                        className="mu-rich-input"
                                        value={object.display_name}
                                        readOnly
                                        theme=""
                                    />
                                );
                            }
                            if (isMission) {
                                objectContent = (
                                    <MissionItem id={object.id} />
                                );
                            }

                            return (
                                <button
                                    key={object.id || object.object_versionned.id}
                                    type="button"
                                    disabled={!objectHandler}
                                    className={classWithModifiers('mu-activity__sublist-item', {
                                        'note-image': isNoteImage,
                                        'note-text': isNoteText,
                                        'chat-message': isChatMessage,
                                        mission: isMission,
                                    })}
                                    onClick={(e) => {
                                        if (objectHandler) {
                                            handleObjectClick();
                                            e.stopPropagation();
                                        }
                                    }}
                                >
                                    {isNoteText && <MuIcon svgName="Bulb" className="bulb-icon" />}
                                    {isChatMessage && <span className="quote-icon">“</span>}
                                    {objectContent}
                                </button>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Activity;
