import React from 'react';
import {useCriterion, ProfilesStats} from 'mushin-redux-store';
import i18n from 'i18next';
import CheckboxRadio from '../../../../Components/Form/CheckboxRadio';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import {roundWithPrecision} from '../../../../Helpers/math';
import CriteriaTranslation from '../../../../Components/Translation/CriteriaTranslation';

type Props = {
    stat: ProfilesStats[number];
}

const CriterionValueStat: React.FC<Props> = ({ stat }) => {
    const criteria = useCriterion(stat._id);

    if (!criteria) return null;

    const rest = stat.total - stat.count;
    const restRatio = rest / stat.total;

    return (
        <>
            <div className="mu-users-stats__title">
                <CriteriaTranslation criterion={criteria} />
            </div>
            {stat.stats.map((statItem) => {
                const value = criteria.values.find((val) => val.slug === statItem.value);
                return (
                    <>
                        <CheckboxRadio
                            className="mu-users-stats__option"
                            checked={!!statItem.count}
                            setChecked={() => { /* */
                            }}
                            label={value?.label}
                            uniqueSelection={!criteria.multiple}
                            styleKey="choice"
                        />
                        <ProgressBar
                            className="mu-question-result__option-progress"
                            value={statItem.ratio}
                            maxValue={1}
                        />
                        <div className="mu-users-stats__option-count">
                            {`${roundWithPrecision(statItem.ratio * 100, 1)}% (${statItem.count})`}
                        </div>
                    </>
                );
            })}
            <CheckboxRadio
                className="mu-users-stats__option"
                checked={!!rest}
                setChecked={() => { /* */
                }}
                label={i18n.t('albums.undefinedPeriod')}
                uniqueSelection={!criteria.multiple}
                styleKey="choice"
            />
            <ProgressBar
                className="mu-question-result__option-progress"
                value={restRatio}
                maxValue={1}
            />
            <div className="mu-users-stats__option-count">
                {`${roundWithPrecision(restRatio * 100, 1)}% (${rest})`}
            </div>
        </>
    );
};

export default CriterionValueStat;
