import React from 'react';
import memoize from 'memoizee';
import { TemplateList } from '../TemplateSelector/component/TemplateList';
import { templates as rawTemplates } from '../BoardLayoutTemplate/templates';
import { buildWithMarginTop, getTemplateSpotCount } from '../BoardLayoutTemplate/templateBuilder';
import {Template} from '../BoardLayout/type';

const getTemplateGroup = memoize((noteCount) => {
    const ns = rawTemplates.map(getTemplateSpotCount);

    const max = Math.max(...ns);
    const min = Math.min(...ns);

    if (noteCount < min || !ns.includes(noteCount)) noteCount = min;

    if (noteCount > max) noteCount = max;

    return ns.reduce((ids, n, id) => (n == noteCount ? [...ids, id] : ids), []);
}, { max: 10 });

type Props = {
    noteCount: number;
    selectedTemplateId: number;
    setTemplate: (template: Template, id: number) => void;
}

export const TemplatePicker: React.FC<Props> = ({
    noteCount,
    selectedTemplateId,
    setTemplate,
}) => {
    const width = 300;
    const height = width * Math.SQRT1_2;
    const margin = 10;

    const ids = getTemplateGroup(noteCount);

    const templates = ids.map((id) => buildWithMarginTop(rawTemplates[id], width, height, margin));

    return <TemplateList templates={templates} templateId={ids.findIndex((id) => id === selectedTemplateId)} setTemplateId={(i) => setTemplate(templates[i], ids[i])} width={width} height={height} />;
};
