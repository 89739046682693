import {
    Note,
    updateNote,
    StoreThunk,
    rotateNote,
    addNotesToAlbum,
    deleteNotes,
    removeNotesFromAlbum, Album, updateAlbum, useCurrentProject
} from 'mushin-redux-store';
import Delta from 'quill-delta';
import {useNavigate} from 'react-router-dom';
import React from 'react';
import i18n from 'i18next';
import Fuse from 'fuse.js';
import {printImage} from './download';
import Client from '../Services/Client';
import {useAppDispatch} from './hooks';
import {ActionBarItem} from '../Components/ActionBar/ActionBar';
import {createAndInitQuestionnaire} from './thunkActions';
import {openModal, openAlert} from '../Redux/reducers/modalsSlice';
import {addNotifError, addNotifSuccess} from '../Redux/reducers/appNotificationsSlice';
import {GalleryParams} from '../Scenes/Gallery/types';
import {formatQuestionTitle} from './questionnaires';
import {isContributor, isProjectAdmin} from './users';

export const addTagToContent = (tag: string, content: Delta): Delta => {
    const delta = new Delta(content.ops);
    let retain = delta.length();
    const lastInsert = delta.ops?.length && delta.ops[delta.ops.length - 1].insert;
    if (typeof lastInsert === 'string' && lastInsert.endsWith('\n')) retain -= 1;
    return new Delta(content.ops).compose(
        new Delta().retain(retain).insert(' ').insert({ mention: {
            denotationChar: '#',
            id: tag,
            value: tag,
        }})
    );
};

export const addTagToNote = (note: Note, tag: string): StoreThunk => {
    const match = note.hash_tags.some((hash_tag) => hash_tag === tag);
    if (match) {
        return () => { /* */ };
    }
    const patch = {
        content: addTagToContent(tag, note.content),
    };
    return updateNote(note.id, patch);
};

export const removeTagFromContent = (tag: string, content: Delta): Delta => {
    if (content.ops) {
        const tagIndex = content.ops.findIndex(
            (op) => op.insert.mention && op.insert.mention.denotationChar === '#' && op.insert.mention.value === tag,
        );
        if (tagIndex === -1) return content;
        const newContent = [...content.ops];
        newContent.splice(tagIndex, 1);
        return new Delta(newContent);
    }
    return content;
};

export const removeTagFromNote = (note: Note, tag: string): StoreThunk => {
    return updateNote(note.id, {
        content: removeTagFromContent(tag, note.content),
    });
};

export type Aggregation = {key: string; doc_count: number}

export const mergeTags = (
    aggregations: Aggregation[],
    all: string[] | null,
    options: {selected?: string[], except?: string[]} = {},
): Aggregation[] => {
    const tags = [...aggregations.filter((agg) => !options.except?.includes(agg.key))];
    if (options.selected) {
        for (const tag of options.selected) {
            if (!options.except?.includes(tag) && !aggregations.some((agg) => agg.key === tag) && !all?.includes(tag)) {
                tags.push({key: tag, doc_count: 0});
            }
        }
    }
    if (all) {
        for (const tag of all) {
            if (!options.except?.includes(tag) && !aggregations.some((agg) => agg.key === tag)) {
                tags.push({key: tag, doc_count: 0});
            }
        }
    }
    return tags;
};

export const searchTagList = (
    search: string,
    tags: Aggregation[],
): Aggregation[] => {
    if (search) {
        return new Fuse(tags, {
            keys: ['key'],
            sortFn: (tagA, tagB) => {
                if (tagA.score < tagB.score) return -1;
                if (tagA.score > tagB.score) return 1;
                if (tagA.item.doc_count > tagB.item.doc_count) return -1;
                if (tagA.item.doc_count < tagB.item.doc_count) return 1;
                if (tagA.item.key < tagB.item.key) return -1;
                if (tagA.item.key > tagB.item.key) return 1;
                return 0;
            },
        }).search(search).map((result) => result.item);
    }

    tags.sort((tagA, tagB) => {
        if (tagA.doc_count > tagB.doc_count) return -1;
        if (tagA.doc_count < tagB.doc_count) return 1;
        if (tagA.key < tagB.key) return -1;
        if (tagA.key > tagB.key) return 1;
        return 0;
    });

    return tags;
};

export const getNoteUrl = (
    noteId: string,
    {album_id: albumId, projectId, inspirationType, organizationId, questionnaireId, questionId}: GalleryParams,
): string => {
    if (questionnaireId) {
        return `/projects/${projectId}/questionnaires/${questionnaireId}/questions/${questionId}/note/${noteId}`;
    }
    if (albumId) {
        if (inspirationType) {
            return `/projects/${projectId}/albums/${albumId}/${inspirationType}/note/${noteId}`;
        }
        return `/projects/${projectId}/albums/${albumId}/note/${noteId}`;
    }
    if (projectId) return `/projects/${projectId}/galleries/${inspirationType}/note/${noteId}`;
    return `/organizations/${organizationId}/galleries/${inspirationType}/note/${noteId}`;
};

export const useNoteActions = (
    note: Note | null | undefined,
    album: Album | null | undefined,
    setFullscreen?: React.Dispatch<React.SetStateAction<boolean>>,
    beforeDelete?: () => void,
): ActionBarItem[] => {
    const project = useCurrentProject();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    if (!note) return [];

    const actions = [];

    const isNoteContributor = isContributor(project);

    if (setFullscreen) {
        actions.push({
            icon: 'Expand',
            label: i18n.t<string>('tooltips.full_screen'),
            handler: () => setFullscreen(true),
            hidden: note.type === 'TEXT',
        });

        actions.push({
            icon: 'Redo',
            label: i18n.t<string>('tooltips.rotate_right'),
            handler: () => dispatch(rotateNote(note.id, 90)),
            hidden: note.type === 'TEXT',
        });

        actions.push({
            icon: 'Print',
            label: i18n.t<string>('noteView.dropdowns.print'),
            handler: () => {
                if (note.type !== 'TEXT') printImage(note.image_src.original);
            },
        });
    }

    actions.push({
        icon: 'Edit',
        label: i18n.t('gallery.dropdowns.note.update'),
        handler: () => {
            dispatch(openModal('EditFiles', { noteIds: [note.id] }));
        },
        disabled: !note.permissions?.can_update,
    });

    actions.push({
        icon: 'Image',
        label: i18n.t('gallery.dropdowns.note.change_cover'),
        handler: async () => {
            try {
                await dispatch(updateAlbum(album?.id as string, {cover_note_id: note.id}));
                dispatch(addNotifSuccess(i18n.t('gallery.dropdowns.note.change_cover_success')));
            } catch (e) {
                dispatch(addNotifError(i18n.t('gallery.dropdowns.note.change_cover_error')));
            }
        },
        hidden: !album,
    });

    actions.push({
        icon: 'Moodboard',
        label: i18n.t('gallery.dropdowns.note.updateBoard'),
        handler: () => navigate(`/projects/current/board/${note.id}`),
        hidden: note.type !== 'MOODBOARD',
    });

    const handleAddNoteToAlbums = () => {
        dispatch(openModal('AddNotesToAlbums', {
            handleConfirm: (albums) => {
                albums.forEach((_album) => dispatch(
                    addNotesToAlbum(_album, [note.id])
                ));
            },
        }));
    };
    actions.push({
        icon: 'FolderLine',
        label: i18n.t<string>(`gallery.notebucket.${album ? 'shareInOtherAlbum' : 'shareInAlbum'}`),
        handler: handleAddNoteToAlbums,
        hidden: isNoteContributor,
    });

    actions.push({
        icon: 'Survey',
        label: i18n.t('surveys.create'),
        handler: async () => {
            const questionnaire = await dispatch(createAndInitQuestionnaire({
                title: note.label,
                project_id: note.project_id,
                accessType: 'project',
                desc: new Delta([{insert: i18n.t<string>('questionEdition.surveyFromNoteDesc')}]),
            }, {
                type: 'rating',
                label: formatQuestionTitle(i18n.t<string>('questionEdition.surveyFromNoteQuestionLabel')),
                cover_urls: note.type !== 'TEXT' ? (
                    [note.type !== 'VIDEO'
                        ? {type: 'image', url: note.image_src.original}
                        : {type: 'video', url: note.video_url}]
                ) : null,
            }));
            navigate(`/projects/${note.project_id}/questionnaires/${questionnaire.id}/edit`);
        },
        hidden: isNoteContributor || !project,
        disabled: !(project && Client.isAllowedToEditProject(project)),
    });

    actions.push({
        icon: 'Remove',
        label: i18n.t('gallery.dropdowns.note.remove'),
        handler: () => {
            dispatch(
                openAlert({
                    type: 'danger',
                    title: i18n.t('modals.removeFile.title'),
                    icon: 'Delete',
                    confirmLabel: i18n.t('modals.removeFile.confirmButton'),
                    body: i18n.t('modals.removeFile.catchPhrase', { galleryName: album?.label }),
                    confirmAction: async () => {
                        if (album) {
                            beforeDelete?.();
                            dispatch(removeNotesFromAlbum(album.id, [note.id])).then();
                        }
                    },
                })
            );
        },
        hidden: !album,
        disabled: !note.permissions?.can_delete && album?.owner.id !== Client.getId(),
    });

    const deleteNote = () => {
        const confirmAction = async () => {
            beforeDelete?.();
            dispatch(deleteNotes([note.id])).then();
        };

        dispatch(openAlert({
            type: 'danger',
            title: i18n.t('modals.deleteFile.title'),
            icon: 'Delete',
            confirmLabel: i18n.t('modals.deleteFile.confirmButton'),
            body: i18n.t('modals.deleteFile.catchPhrase'),
            confirmAction,
        }));
    };
    actions.push({
        icon: 'Delete',
        label: i18n.t<string>('noteView.dropdowns.delete'),
        handler: deleteNote,
        disabled: note.profile_id !== project?.profile?.id && !(project && isProjectAdmin(project)),
    });

    return actions;
};
