import React, {useState} from 'react';
import EntitySelectItem from './EntitySelectItem';
import { useScrollThreshold } from '../../../Helpers/effects';
import DropdownMenu from '../../DropdownMenu/DropdownMenu';
import {classWithModifiers} from '../../../Helpers/css';
import MuIcon from '../../MuIcon';

type Props<T> = {
    className?: string;
    value: React.ReactNode;
    options: string[];
    useItem: (id: string | undefined) => T | null;
    onSelect: (entityId: string) => void;
    getMoreResults: () => void;
    getEntityName: (entity: NonNullable<T>) => string;
    defaultOptions?: {[key: string]: React.ReactNode}
    removable?: boolean;
    onRemove?: () => void;
    disabled?: boolean;
}

type EntitySelect<T> = React.FC<Props<T>>

const EntitySelectCommon = <T, >(
    {
        value,
        options,
        useItem,
        onSelect,
        getMoreResults,
        getEntityName,
        className,
        defaultOptions,
        removable,
        onRemove,
        disabled,
    }: Parameters<EntitySelect<T>>[0]
): ReturnType<EntitySelect<T>> => {
    const ref = useScrollThreshold(getMoreResults, {deps: [options?.length]});
    const [open, setOpen] = useState(false);

    const handleSelect = (val: string) => {
        onSelect(val);
        setOpen(false);
    };

    return (
        <DropdownMenu
            className={classWithModifiers('mu-select', null, className)}
            align="justify"
            isOpen={open}
            setIsOpen={(_open) => !disabled && setOpen(_open)}
            scrollRef={ref}
            toggle={(
                <div className={classWithModifiers('mu-filters-toolbar__filter-toggle', {disabled})}>
                    {value}
                    <MuIcon className="mu-filters-toolbar__filter-toggle-icon" svgName="ArrowDown" />
                    {removable && (
                        <button
                            type="button"
                            className="mu-filters-toolbar__filter-remove-button"
                            onClick={() => {
                                if (onRemove) {
                                    onRemove();
                                }
                            }}
                        >
                            <MuIcon className="mu-filters-toolbar__filter-remove-icon" svgName="CloseCircleFill" />
                        </button>
                    )}
                </div>
            )}
        >
            {defaultOptions && Object.keys(defaultOptions).map((key) => (
                <button
                    key={key}
                    type="button"
                    className="mu-dropdown-menu__button-item"
                    onClick={() => handleSelect(key)}
                >
                    {defaultOptions[key]}
                </button>
            ))}
            {options.map((entityId) => {
                return (
                    <EntitySelectItem
                        key={entityId}
                        entityId={entityId}
                        useItem={useItem}
                        onSelect={handleSelect}
                        getEntityName={getEntityName}
                    />
                );
            })}
        </DropdownMenu>
    );
};

export default EntitySelectCommon;
