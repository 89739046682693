import React, {useState} from 'react';
import {
    Note,
    useAlbumsQuery,
    useCurrentProjectId,
} from 'mushin-redux-store';
import i18n from 'i18next';
import {openModal} from '../../Redux/reducers/modalsSlice';
import {useAppDispatch} from '../../Helpers/hooks';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import InputText from '../InputText/InputText';
import {useTextInputWithDelay} from '../../Helpers/FormsHelpers/hooks';
import AddToAlbumDropdownItem from './AddToAlbumDropdownItem';
import MuIcon from '../MuIcon';

type Props = {
    toggle: React.ReactNode;
    notes: (Note | null)[] | undefined;
    toggleClassName?: string;
    canCreateAlbum?: boolean;
};

const AddToAlbumDropdown: React.FC<Props> = ({toggle, toggleClassName, notes, canCreateAlbum = false}) => {
    const [open, setOpen] = useState(false);
    const [projectId] = useCurrentProjectId();
    const albumsQuery = useAlbumsQuery({project_id: projectId}, true);
    const [currentQuery, setCurrentQuery] = useTextInputWithDelay((value) => {
        albumsQuery.setParams({
            project_id: projectId,
            name_search: value || undefined,
        });
    });

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentQuery(e.target.value);
    };

    const dispatch = useAppDispatch();

    return (
        <DropdownMenu
            isOpen={open}
            setIsOpen={setOpen}
            align="center"
            toggle={toggle}
            toggleClassName={toggleClassName}
            contentClassName="mu-filters-toolbar__panel"
        >
            <div className="mu-filters-toolbar__panel-header">
                {i18n.t('gallery.notebucket.addToAnalysis')}
                <button
                    type="button"
                    className="mu-add-to-album__close-button"
                    onClick={() => setOpen(false)}
                >
                    <MuIcon svgName="Close" />
                </button>
            </div>
            <div className="mu-filters-toolbar__inline-select">
                <InputText
                    className="mu-dropdown-menu__search"
                    classModifiers={['full-width']}
                    leftIcon="Magnifier"
                    value={currentQuery}
                    onChange={onSearch}
                    placeholder={i18n.t('global.search')}
                    contrast
                />
                <div className="mu-dropdown-menu__content--scroll">
                    {canCreateAlbum && projectId && (
                        <button
                            type="button"
                            className="mu-add-to-album__create"
                            onClick={() => dispatch(openModal('CreateAlbum', {
                                projectId,
                                afterCreate: () => { /* */ },
                            }))}
                        >
                            {'+ '}
                            {i18n.t('clusters.create')}
                        </button>
                    )}
                    {albumsQuery.results?.map((albumId) => (
                        <AddToAlbumDropdownItem
                            key={albumId}
                            id={albumId}
                            notes={notes}
                        />
                    ))}
                </div>
            </div>
        </DropdownMenu>

    );
};

export default AddToAlbumDropdown;
