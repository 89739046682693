import React from 'react';
import {Board} from 'mushin-node-moodboard';

type State = {board: Board;batchKey: string | null;};

// determine what type of transform occurred from a to b
const transformKey = (a: Board, b: Board) => {
    if (a.ids.length < b.ids.length) return 'add cell';

    if (a.ids.length > b.ids.length) return 'remove cell';

    if (a.ids.some((_, i) => a.ids[i] != a.ids[i])) return 'reorder cell';

    if (a.cells != b.cells) {
        const modified = a.ids.filter((id) => a.cells[id] != b.cells[id]);

        if (modified.length > 1) return 'mixed';

        if (modified.length == 1) {
            const id = modified[0];

            const cella = a.cells[id];
            const cellb = b.cells[id];

            if (cella.max == cellb.max || cella.min == cellb.min) { return `scaling cell ${id}`; }

            return `transform cell ${id}`;
        }
    }

    if (a.contents != b.contents) {
        const modified = a.ids.filter((id) => a.contents[id] != b.contents[id]);

        if (modified.length > 1) return 'mixed';

        if (modified.length == 1) {
            const id = modified[0];

            const ca = a.contents[id];
            const cb = b.contents[id];

            if (!ca) return 'add content';

            if (!cb) return 'remove content';

            if (ca.type != cb.type) return `set content ${id} type `;

            if (ca.split != cb.split) return `set content ${id} split`;

            if (ca.image_crop && ca.image_crop.scale != cb.image_crop.scale) { return `set content ${id} crop scale`; }

            if (ca.image_crop && (ca.image_crop.focusPoint.x != cb.image_crop.focusPoint.x || ca.image_crop.focusPoint.y != cb.image_crop.focusPoint.y)) { return `set content ${id} crop focus`; }

            if (ca.image_crop && ca.image_crop.filling != cb.image_crop.filling) { return `set content ${id} crop filling`; }

            if (ca.image_url !== cb.image_url) return `set content ${id} image`;

            if (ca.image_rotation !== cb.image_rotation) return 'set rotation';

            if (ca.text !== cb.text) return `set content ${id} text`;
        }
    }

    return 'no transform';
};

// is the transform batchable ?
const batchable = (key) => key.match(/(scaling|transform) cell/) || key.match(/set content [\w-]+ (split|crop scale|crop focus)/);

export const injectTransformBatcher = (C, delay: number) => {
    class ModeInjector extends React.Component {
    state: State = {
        board: {
            cells: {},
            contents: {},
            ids: [],
            width: 0,
            height: 0,
            margin: 0,
        },
        batchKey: null,
    };

    _endBatchTimeout = null;

    constructor(props) {
        super(props);
        this.state = { batchKey: null, board: props.board };
    }

    setBoard = (board: Board) => {
        const key = transformKey(this.state.board, board);
        if (key == 'no transform') {
            return this.setState({ board });
        }

        if (this.state.batchKey && key != this.state.batchKey) {
            this.endBoardTransformBatch();
        }

        if (batchable(key)) {
            this.setState({ board, batchKey: key });

            clearTimeout(this._endBatchTimeout);
            this._endBatchTimeout = setTimeout(this.endBoardTransformBatch, delay || 600);
        } else {
            this.props.setBoard(board);
        }
    };

    endBoardTransformBatch = () => {
        clearTimeout(this._endBatchTimeout);

        if (this.state.board !== this.props.board) {
            this.props.setBoard(this.state.board);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (this.state.board !== nextProps.board) {
            this.setState({ board: nextProps.board, batchKey: null });
        }
    }

    render() {
        return <C {...this.props} {...this.state} setBoard={this.setBoard} endBoardTransformBatch={this.endBoardTransformBatch} />;
    }
    }
    return ModeInjector;
};
