import React from 'react';
import {Project, updateNews, useNews, useNewsQuery} from 'mushin-redux-store';
import {isProjectAdmin} from '../../../../Helpers/users';
import DeltaPanel from '../../../../Components/Dashboard/Components/DeltaPanel';
import {useAppDispatch} from '../../../../Helpers/hooks';
import NewsButton from '../NewsModal/NewsButton';
import {openModal} from '../../../../Redux/reducers/modalsSlice';

type Props = {
    id: string;
    project: Project;
};

const NewsPanel: React.FC<Props> = ({
    id,
    project,
}: Props) => {
    const news = useNews(id);
    const newsQuery = useNewsQuery({project_id: project.id});
    const dispatch = useAppDispatch();

    if (!news) return null;

    const hasNewsHistory = newsQuery.results && newsQuery.results.length > 1;

    return (
        <div className="mu-panel mu-project__news mu-project__news-flex">
            <DeltaPanel
                translationTitle={{
                    translationProp: news.titles,
                    fallback: news.title,
                }}
                translationContent={{
                    translationProp: news.descriptions,
                    fallback: news.content,
                }}
                canEditEntity={isProjectAdmin(project)}
                onSubmit={(value) => dispatch(updateNews(id, {content: value}))}
                truncatedSize="medium"
            />
            {hasNewsHistory && (
                <>
                    <NewsButton onClick={() => dispatch(openModal('AddNewsModal', { project }))} />
                </>
            )}
        </div>
    );
};

export default NewsPanel;
