import React, {useEffect} from 'react';
import {useCurrentOrganizationId, useCurrentProject, useCurrentProjectId, useMe} from 'mushin-redux-store';
import {isMobile} from '../../Helpers/navigator';
import HeaderMobile from '../../Components/HeaderMobile/HeaderMobile';
import MushinRoutes from '../../Router/MushinRoutes';
import {useAppDispatch} from '../../Helpers/hooks';
import {isContributor} from '../../Helpers/users';
import {openModal} from '../../Redux/reducers/modalsSlice';

const Mushin: React.FC = () => {
    const me = useMe();
    const [currentOrganizationId] = useCurrentOrganizationId();
    const [currentProjectId] = useCurrentProjectId();
    const project = useCurrentProject();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!me?.tutorial?.onboarding && isContributor(project)) {
            dispatch(openModal('OnboardingModal'));
        }
    }, [me, dispatch, project]);

    if (currentOrganizationId === undefined || currentProjectId === undefined) return null;

    return isMobile() ? (
        <div id="mushin">
            <HeaderMobile />
            <MushinRoutes />
        </div>
    ) : (
        <div id="mushin">
            <MushinRoutes />
        </div>
    );
};

export default Mushin;
