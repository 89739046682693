import React, {useState} from 'react';
import * as Sentry from '@sentry/react';
import {useNavigate} from 'react-router-dom';
import {AvailableLocale, confirmAccount, useMe} from 'mushin-redux-store';
import {useTranslation} from 'react-i18next';
import FormTextInput from '../../../Components/Form/FormTextInput';
import {useFieldState} from '../../../Components/Form/common';
import {multipleValidate} from '../../../Components/Form/validation';
import MuButton from '../../../Components/MuButton';
import SessionsLayout from '../Components/SessionsLayout/SessionsLayout';
import LanguageSelect from '../../../Components/Form/LanguageSelect';
import NewPasswordInput from '../../../Components/Form/NewPasswordInput';
import InfoPanel from '../../../Components/InfoPanel/InfoPanel';
import {useAppDispatch} from '../../../Helpers/hooks';
import {useSearchParams} from '../../../Helpers/queryParser';
import {successUrl} from '../helpers';

const ConfirmAccount: React.FC = () => {
    const [searchParams] = useSearchParams<{landing?: string}>();
    const [loading, setLoading] = useState(false);
    const [hasRequestError, setRequestError] = useState(false);
    const nameToDisplay = useFieldState('');
    const password = useFieldState('');

    const me = useMe();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const validate = async (e: React.FormEvent) => {
        e.preventDefault();
        setRequestError(false);
        if (multipleValidate([
            password.validate,
            nameToDisplay.validate,
        ])) {
            setLoading(true);
            try {
                await dispatch(confirmAccount({
                    locale: i18n.resolvedLanguage as AvailableLocale,
                    name: nameToDisplay.value,
                    password: password.value,
                }));
                navigate(successUrl(searchParams.landing));
            } catch (err) {
                Sentry.captureException(err);
                setLoading(false);
                setRequestError(true);
            }
        }
    };

    return (
        <SessionsLayout title={t(`accounts.${searchParams.landing || 'signUp'}.title`)}>
            <p className="mu-modal__catchphrase">{t('accounts.signUp.catchphrase')}</p>
            {hasRequestError && (
                <InfoPanel
                    classModifiers={['error']}
                    iconName="Warning"
                    text={t('register.errors.invitationExpired') as string}
                />
            )}
            <form onSubmit={validate}>
                <div className="mu-form-row">
                    <div className="mu-form-row__group">
                        <LanguageSelect
                            value={i18n.resolvedLanguage as AvailableLocale}
                            onChange={(locale) => i18n.changeLanguage(locale)}
                            mod="contrast"
                        />
                    </div>
                </div>
                <InfoPanel
                    iconName="InfoLine"
                    text={t('register.nameHint')}
                />
                <div className="mu-form-row">
                    <FormTextInput
                        field={nameToDisplay}
                        label={t('models.users.nameToDisplay')}
                        required
                        contrast
                        min={5}
                    />
                </div>
                <input // This field is set to disable email/password suggestions for the name field above (Firefox)
                    style={{display: 'none'}}
                    type="email"
                    value={me?.email}
                    readOnly
                />
                <NewPasswordInput passwordField={password} />
                <MuButton
                    className="mu-sessions__submit-button"
                    type="submit"
                    disabled={loading}
                    classModifiers={['large']}
                    label={t('accounts.signUp.submit')}
                />
            </form>
        </SessionsLayout>
    );
};

export default ConfirmAccount;
