import React from 'react';
import MainMenu from '../MainMenu/MainMenu';
import Header from '../Header/Header';
import {TabsList} from '../Tabs/Tabs';
import useIsMobileSize from '../../Helpers/useIsMobileSize';
import HeaderMobile from '../HeaderMobile/HeaderMobile';
import MainMenuMobile from '../MainMenu/MainMenuMobile';

type Props = React.PropsWithChildren<{
    title: React.ReactNode;
    actionsComponent?: React.ReactElement | false;
    backLink?: string;
    tabs?: TabsList;
}>

const AppLayout: React.FC<Props> = ({
    title,
    actionsComponent,
    backLink,
    tabs,
    children,
}) => {
    const isMobileSize = useIsMobileSize();

    return isMobileSize ? (
        <div className="mu-app-layout-mobile">
            <HeaderMobile />
            {children}
            <MainMenuMobile />
        </div>
    ) : (
        <div className="mu-app-layout">
            <div className="mu-app-layout__menu">
                <MainMenu />
            </div>
            <div className="mu-app-layout__content">
                <Header title={title} actionsComponent={actionsComponent} backLink={backLink} tabs={tabs} />
                {children}
            </div>
        </div>
    );
};

export default AppLayout;
