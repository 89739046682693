import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';

import {
    useProject,
    AlbumsQueryParams,
    useAlbumsQuery,
} from 'mushin-redux-store';
import AlbumList from '../AlbumList/AlbumList';
import AlbumToolbar, {AlbumSearchParams} from '../../../../Components/GalleryToolbar/AlbumToolbar';
import {useSearchParams} from '../../../../Helpers/queryParser';
import {useScrollThreshold} from '../../../../Helpers/effects';
import {statusToParams} from '../../../../Helpers/status';

const searchToQueryParams = (searchParams: AlbumSearchParams): AlbumsQueryParams => {
    const {
        status,
        ...queryParams
    } = searchParams;

    return {
        ...queryParams,
        ...statusToParams(status),
    };
};

const AlbumsSearch: React.FC<React.PropsWithChildren> = ({children}) => {
    const [searchParams, setSearchParams] = useSearchParams({ owners: 'array' });

    const { projectId } = useParams<{ projectId: string }>();
    const project = useProject(projectId);

    const albumsQuery = useAlbumsQuery({
        project_id: projectId,
        ...searchToQueryParams(searchParams),
    });
    const ref = useScrollThreshold(albumsQuery.getMoreResults, {deps: [albumsQuery.results?.length]});

    const {setParams} = albumsQuery;
    useEffect(() => {
        setParams({
            ...searchToQueryParams(searchParams),
            project_id: projectId,
        });
    }, [projectId, searchParams, setParams]);

    if (!project) return null;

    return (
        <div className="mu-app-layout__scroll">
            {children}
            <main ref={ref} className="mu-albums-page">
                <AlbumToolbar
                    project={project}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
                <AlbumList
                    project={project}
                    albumIds={albumsQuery.results}
                />
            </main>
        </div>
    );
};

export default AlbumsSearch;
