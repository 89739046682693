// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("./assets/favicon/app-icon@16x16.ico", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("./assets/favicon/app-icon@16x16.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("./assets/favicon/app-icon@32x32.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("./assets/favicon/app-icon@48x48.png", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("./assets/favicon/app-icon@128x128.png", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("./assets/favicon/app-icon@256x256.png", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var code = "<!DOCTYPE html> <html lang=\"en\" translate=\"no\"> <head> <meta charset=\"UTF-8\"> <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\"> <title>Mushin</title> <link href=\"https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Fauna+One|Fjalla+One|Josefin+Sans:400,400i,700|Libre+Baskerville:400,400i,700|Nixie+One|Nunito:400,400i,700|Oswald:400,700|Playfair+Display:400,400i,700|Raleway:400,400i,700|Roboto:400,400i,700|Space+Mono:400,400i,700|Volkhov:400,400i,700\" rel=\"stylesheet\"> <link rel=\"shortcut icon\" href=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" type=\"image/x-icon\"> <link rel=\"icon\" href=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" type=\"image/png\"> <link rel=\"icon\" sizes=\"32x32\" href=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" type=\"image/png\"> <link rel=\"icon\" sizes=\"48x48\" href=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" type=\"image/png\"> <link rel=\"icon\" sizes=\"128x128\" href=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" type=\"image/png\"> <link rel=\"icon\" sizes=\"256x256\" href=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\" type=\"image/png\"> </head> <body translate=\"no\"> <noscript> You need to enable JavaScript to run this app. </noscript> <div id=\"app\" class=\"app\"></div> <div id=\"app-portal\"></div> <script>location.href.indexOf(\"confirm_email\")>-1&&(window.location.href=\"/login\")</script> </body> </html> ";
// Exports
export default code;