import React, {useState} from 'react';
import ReactQuill from 'react-quill-new';
import {Link, useLocation, useParams} from 'react-router-dom';
import i18n from 'i18next';
import {ProfilesQueryParams, useAlbum, useChatMessage, useNote} from 'mushin-redux-store';
import MuIcon from '../MuIcon';
import {ProfileAvatar} from '../UserCard/ProfileAvatar';
import {getNameToDisplay, useProfileWithDefault} from '../../Helpers/users';
import MessageReaction from '../Reaction/MessageReaction';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import DiscussionMessages from '../Discussion/DiscussionMessages';
import Comment from '../Comment/Comment';
import NoteThumbnail from '../NoteThumbnail/NoteThumbnail';
import {getNoteUrl} from '../../Helpers/noteHelpers';
import {GalleryParams} from '../../Scenes/Gallery/types';

type Props = {
    messageId: string;
    onClick?: () => void;
    className?: string,
    userMentionsQueryParams?: ProfilesQueryParams;
};

const MessageGridItem: React.FC<Props> = ({
    messageId,
    onClick,
    className,
    userMentionsQueryParams,
}) => {
    const [isOpen, setOpen] = useState(false);
    const params = useParams<GalleryParams>();
    const location = useLocation();
    const message = useChatMessage(messageId);
    const album = useAlbum(message?.relative_content.album_id);
    const note = useNote(message?.relative_content.note_id);
    const profile = useProfileWithDefault(message?.profile_id);

    if (!message) return null;

    const messageTypes = {
        ALBUM: i18n.t('modals.contributorDetailsModal.chat'),
        NOTE: i18n.t('modals.contributorDetailsModal.comment'),
    };

    const {note_id, project_id, album_id} = message.relative_content;
    const link = note_id
        ? `${getNoteUrl(note_id, params)}${location.search}?comment=${message.id}`
        : `/projects/${project_id}/albums/${album_id}?msg=${message.id}`;

    return (
        <article className={`mu-message-grid-item${className ? ` ${className}` : ''}`}>
            <div className="mu-message-grid-item__header">
                <ProfileAvatar userId={message.profile_id} />
                <div className="mu-message-grid-item__infos">
                    <div className="mu-message-grid-item__author">{getNameToDisplay(profile)}</div>
                    <div className="mu-message-grid-item__type">{messageTypes[(message.relative_content.type)]}</div>
                </div>
            </div>
            <ReactQuill
                className="mu-message-grid-item__content"
                value={message.message}
                readOnly
                theme=""
            />
            <DropdownMenu
                position="top"
                align="center"
                className="mu-message-grid-item__link"
                contentClassName="mu-message-grid-item__chat-content"
                isOpen={isOpen}
                setIsOpen={setOpen}
                toggle={<MuIcon svgName="Show" />}
            >
                <Link
                    to={link}
                    onClick={onClick}
                    type="button"
                    className="mu-message-grid-item__chat-target"
                >
                    {album && (
                        <div className="mu-message-grid-item__chat-album">{album.label}</div>
                    )}
                    {message.relative_content.type === 'NOTE' && note && (
                        <NoteThumbnail note={note} />
                    )}
                </Link>
                <div className="mu-message-grid-item__chat">
                    <DiscussionMessages
                        chatId={message.chat_id}
                        renderMessage={(id) => (
                            <Comment key={id} id={id} userMentionsQueryParams={userMentionsQueryParams} />
                        )}
                        goToMessage={messageId}
                        noShadow
                    />
                </div>
            </DropdownMenu>
            <MessageReaction
                name="favorite"
                message={message}
                horizontal
                contrast
            />
        </article>
    );
};

export default MessageGridItem;
