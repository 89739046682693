import React, {useState, useEffect, useRef} from 'react';
import {QuestionItems, QuestionRanking} from 'mushin-redux-store';
import i18n from 'i18next';
import {AnswerProps} from '../types';
import {classWithModifiers} from '../../../Helpers/css';
import CheckboxRadioBox from '../../../Components/Form/CheckboxRadioBox';
import {isChecked, questionItemsGridTemplateColumns, setChecked} from '../../../Helpers/questionnaires';
import { useAppDispatch } from '../../../Helpers/hooks';
import { openLightbox } from '../../../Redux/reducers/lightboxSlice';
import TextTranslation from '../../../Components/Translation/TextTranslation';
import MuIcon from '../../../Components/MuIcon';
import {shuffleArray} from '../../../Helpers/array';

type Props = AnswerProps<QuestionItems | QuestionRanking> & {
    uniqueSelection?: boolean;
    rank?: boolean;
}

const MultipleChoicesAnswer: React.FC<Props> = (
    { question, value, setValue, uniqueSelection = false, rank = false, disabled },
) => {
    const slugs = question.items.map((item) => item.slug);
    const allAnswers = typeof value === 'string' || typeof value === 'number' ? [`${value}`] : [...(value as string[] | undefined || [])];
    const existingOtherValue = !question.canAddItem ? [] : allAnswers.filter((answer) => !slugs?.includes(answer));
    const [otherValue, setOtherValue] = useState(existingOtherValue.length ? existingOtherValue[0] : '');
    const [isOtherSelected, setOtherSelected] = useState(!!existingOtherValue.length);
    const dispatch = useAppDispatch();

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isOtherSelected && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isOtherSelected]);

    if (!question.items) return null;

    const min = question.minChoices || (question.mandatory ? 1 : 0);
    const max = question.maxChoices || question.items.length;

    const withImages = question.items.some((item) => item.image_url);

    const handleOtherClick = () => {
        setOtherSelected(!isOtherSelected);
        if (!isOtherSelected && otherValue) {
            setValue({ value: setChecked(!isOtherSelected, otherValue, value, uniqueSelection) });
        } else if (uniqueSelection && otherValue) {
            setValue({ value: null });
        }
        if (uniqueSelection && !isOtherSelected) {
            setValue({ value: setChecked(true, otherValue, [], uniqueSelection) });
        }
    };

    const orderItems = question.rotateItems ? shuffleArray(question.items) : question.items;

    return (
        <>
            {question.type !== 'radios' && (question.minChoices || question.maxChoices) && (
                <div className="mu-question__instruction">
                    {min !== max
                        ? i18n.t('questionnaires.chooseOptionsMinMax', {
                            min,
                            max,
                        })
                        : i18n.t('questionnaires.chooseOptionsCount', {count: min})}
                    {rank && i18n.t('questionnaires.thenRankThem')}
                </div>
            )}
            <div className="mu-multiple-choice-answer">
                <div
                    className="mu-multiple-choice-answer__grid"
                    style={{gridTemplateColumns: questionItemsGridTemplateColumns(question)}}
                >
                    {orderItems.map((item) => {
                        const checked = isChecked(item.slug, value);

                        const onClick = () => {
                            if (!disabled) {
                                if (!checked && Array.isArray(value) && value.length >= max) return;
                                if (uniqueSelection && !checked) setOtherSelected(false);
                                setValue({value: setChecked(!checked, item.slug, value, uniqueSelection)});
                            }
                        };

                        return (
                            <div
                                key={item.slug}
                                className={classWithModifiers('mu-multiple-choice-answer__item', {
                                    'no-footer': !(item.label && item.image_url),
                                })}
                            >
                                {item.image_url && (
                                    <button
                                        className="mu-multiple-choice-answer__expand"
                                        type="button"
                                        onClick={() => dispatch(openLightbox({urls: [item.image_url as string]}))}
                                    >
                                        <MuIcon svgName="Expand" />
                                    </button>
                                )}
                                <button
                                    type="button"
                                    onClick={onClick}
                                    className="mu-multiple-choice-answer__button"
                                >
                                    {withImages && (
                                        <img
                                            src={item.image_url || '/assets/images/default.png'}
                                            alt={item.label}
                                            className="mu-multiple-choice-answer__image"
                                        />
                                    )}
                                    <div
                                        className={classWithModifiers(
                                            'mu-multiple-choice-answer__footer', {text: !withImages},
                                        )}
                                    >
                                        <CheckboxRadioBox
                                            className="mu-multiple-choice-answer__checkbox"
                                            checked={checked}
                                            disabled={disabled}
                                            uniqueSelection={uniqueSelection}
                                            styleKey="choice"
                                            onClick={onClick}
                                        />
                                        <TextTranslation translationProp={item.labels} fallback={item.label} center />
                                    </div>
                                </button>
                            </div>
                        );
                    })}
                    {question.canAddItem && (
                        <div className="mu-multiple-choice-answer__item'">
                            <button
                                onClick={() => {
                                    if (!isOtherSelected) {
                                        handleOtherClick();
                                    }
                                }}
                                type="button"
                                className={classWithModifiers(
                                    'mu-multiple-choice-answer__button', {input: isOtherSelected}
                                )}
                            >
                                <div
                                    className="mu-multiple-choice-answer__footer
                                    mu-multiple-choice-answer__footer--text"
                                >
                                    <CheckboxRadioBox
                                        className="mu-multiple-choice-answer__checkbox"
                                        checked={isOtherSelected}
                                        uniqueSelection={uniqueSelection}
                                        styleKey="choice"
                                        disabled={disabled}
                                        onClick={handleOtherClick}
                                    />
                                    <>
                                        {isOtherSelected ? (
                                            <input
                                                ref={inputRef}
                                                disabled={disabled}
                                                placeholder={i18n.t<string>('questionnaires.yourAnswer')}
                                                className="mu-multiple-choice-answer__input-other"
                                                value={otherValue}
                                                onChange={(event) => {
                                                    const eventValue = event.currentTarget.value;
                                                    setOtherValue((prevOtherValue) => {
                                                        let newValue = setChecked(false,
                                                            prevOtherValue, value, uniqueSelection);
                                                        if (eventValue) {
                                                            newValue = setChecked(true,
                                                                eventValue, newValue, uniqueSelection);
                                                        }
                                                        setValue({value: newValue});
                                                        return eventValue;
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <label>
                                                {i18n.t('global.other')}
                                            </label>
                                        )}
                                    </>
                                </div>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default MultipleChoicesAnswer;
