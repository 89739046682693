import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Project, updateProject, useMe} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton';
import {useFieldState} from '../../../../Components/Form/common';
import {multipleValidate} from '../../../../Components/Form/validation';
import {openQuitWithoutSavingAlert} from '../../../../Redux/reducers/modalsSlice';
import CategorySelector from '../../../../Components/CategorySelector/CategorySelector';
import FormTextInput from '../../../../Components/Form/FormTextInput';
import LanguageMultiSelect from '../../../../Components/Form/LanguageMultiSelect';

type Props = {
    onClose: () => void;
    project: Project;
};

const ProjectInfoModal: React.FC<Props> = ({
    onClose,
    project,
}) => {
    const me = useMe();
    const categoryField = useFieldState(project.category);
    const labelField = useFieldState(project.name);
    const [locales, setLocales] = useState(project.locales || []);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(openQuitWithoutSavingAlert(
            (
                categoryField.value !== project.category
                || labelField.value !== project.name
            ),
            onClose,
        ));
    };

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={handleClose}>
            <ModalHeader
                subtitle={i18n.t('modals.EditProject.subtitle')}
                title={i18n.t('modals.EditProject.title')}
                icon="SubFolderFill"
            />
            <div className="util-modal-oflow-auto">
                <CategorySelector field={categoryField} />
                <div className="mu-form-row">
                    <FormTextInput
                        label={i18n.t('global.name')}
                        placeholder={i18n.t('global.namePlaceholder', {year: new Date().getFullYear()})}
                        field={labelField}
                        required
                        contrast
                    />
                </div>
                {me?.admin && (
                    <>
                        <label className="mu-input__label">{i18n.t('global.translate')}</label>
                        <div className="mu-form-row">
                            <LanguageMultiSelect
                                value={locales}
                                setValue={setLocales}
                            />
                        </div>
                    </>
                )}
            </div>
            <ModalFooter>
                <MuButton
                    handleClick={() => {
                        if (multipleValidate([categoryField.validate, labelField.validate])) {
                            dispatch(updateProject(project.id, {
                                category: categoryField.value,
                                name: labelField.value,
                                locales,
                            }));
                            onClose();
                        }
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default ProjectInfoModal;
