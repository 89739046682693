import buildLocalizeFn from '../../../_lib/buildLocalizeFn/index';
import type { Localize, LocalizeFn } from '../../../types';

const eraValues = {
    narrow: ['AC', 'DC'],
    abbreviated: ['AC', 'DC'],
    wide: ['antes de cristo', 'después de cristo'],
} as const;

const quarterValues = {
    narrow: ['1', '2', '3', '4'],
    abbreviated: ['T1', 'T2', 'T3', 'T4'],
    wide: ['1º trimestre', '2º trimestre', '3º trimestre', '4º trimestre'],
} as const;

const monthValues = {
    narrow: ['e', 'f', 'm', 'a', 'm', 'j', 'j', 'a', 's', 'o', 'n', 'd'],
    abbreviated: [
        'ene',
        'feb',
        'mar',
        'abr',
        'may',
        'jun',
        'jul',
        'ago',
        'sep',
        'oct',
        'nov',
        'dic',
    ],
    wide: [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
    ],
} as const;

const dayValues = {
    narrow: ['d', 'l', 'm', 'm', 'j', 'v', 's'],
    short: ['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sá'],
    abbreviated: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    wide: [
        'domingo',
        'lunes',
        'martes',
        'miércoles',
        'jueves',
        'viernes',
        'sábado',
    ],
} as const;

const dayPeriodValues = {
    narrow: {
        am: 'a',
        pm: 'p',
        midnight: 'mn',
        noon: 'md',
        morning: 'mañana',
        afternoon: 'tarde',
        evening: 'tarde',
        night: 'noche',
    },
    abbreviated: {
        am: 'AM',
        pm: 'PM',
        midnight: 'medianoche',
        noon: 'mediodia',
        morning: 'mañana',
        afternoon: 'tarde',
        evening: 'tarde',
        night: 'noche',
    },
    wide: {
        am: 'a.m.',
        pm: 'p.m.',
        midnight: 'medianoche',
        noon: 'mediodia',
        morning: 'mañana',
        afternoon: 'tarde',
        evening: 'tarde',
        night: 'noche',
    },
} as const;

const formattingDayPeriodValues = {
    narrow: {
        am: 'a',
        pm: 'p',
        midnight: 'mn',
        noon: 'md',
        morning: 'de la mañana',
        afternoon: 'de la tarde',
        evening: 'de la tarde',
        night: 'de la noche',
    },
    abbreviated: {
        am: 'AM',
        pm: 'PM',
        midnight: 'medianoche',
        noon: 'mediodia',
        morning: 'de la mañana',
        afternoon: 'de la tarde',
        evening: 'de la tarde',
        night: 'de la noche',
    },
    wide: {
        am: 'a.m.',
        pm: 'p.m.',
        midnight: 'medianoche',
        noon: 'mediodia',
        morning: 'de la mañana',
        afternoon: 'de la tarde',
        evening: 'de la tarde',
        night: 'de la noche',
    },
} as const;

const ordinalNumber: LocalizeFn<number, undefined> = (
    dirtyNumber,
    _options
) => {
    const number = Number(dirtyNumber);
    return `${number}º`;
};

const localize: Localize = {
    ordinalNumber,

    era: buildLocalizeFn({
        values: eraValues,
        defaultWidth: 'wide',
    }),

    quarter: buildLocalizeFn({
        values: quarterValues,
        defaultWidth: 'wide',
        argumentCallback: (quarter) => (Number(quarter) - 1) as Quarter,
    }),

    month: buildLocalizeFn({
        values: monthValues,
        defaultWidth: 'wide',
    }),

    day: buildLocalizeFn({
        values: dayValues,
        defaultWidth: 'wide',
    }),

    dayPeriod: buildLocalizeFn({
        values: dayPeriodValues,
        defaultWidth: 'wide',
        formattingValues: formattingDayPeriodValues,
        defaultFormattingWidth: 'wide',
    }),
};

export default localize;
