import React from 'react';
import useNotesQueryWithSearchParams from '../Gallery/Components/GalleryNotes/useNotesQueryWithSearchParams';
import GalleryNotes from '../Gallery/Components/GalleryNotes/GalleryNotes';
import QuestionHeader from '../Gallery/Components/QuestionHeader/QuestionHeader';
import QuestionAnalysis from './Components/QuestionAnalysis';
import { QuestionFree, Questionnaire } from 'mushin-redux-store';

type Props = {
    questionnaire: Questionnaire;
    question: QuestionFree;
};

const QuestionResultFree: React.FC<Props> = ({ questionnaire, question }) => {
    const [query, searchParams, setSearchParams] = useNotesQueryWithSearchParams();

    return (
        <GalleryNotes query={query} searchParams={searchParams} setSearchParams={setSearchParams}>
            <QuestionHeader questionnaire={questionnaire} question={question} />
            <QuestionAnalysis questionnaire={questionnaire} question={question} query={query} />
        </GalleryNotes>
    );
};

export default QuestionResultFree;
