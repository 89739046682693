import React from 'react';
import { Cell, Content } from 'mushin-node-moodboard';
import { Camera } from '../../type';

const computePosition = (content, cell, ratio, camera) => {
    const p = {
        y: (cell.max.y - cell.min.y) / 2,
        x: (cell.max.x - cell.min.x) / 2,
    };

    const s = content.split;

    switch (content.position) {
        case 'top': case 'bottom':
            p.y = (cell.max.y - cell.min.y) * s;
            break;
        case 'left': case 'right':
            p.x = (cell.max.x - cell.min.x) * s;
            break;
        default:
    }

    return { top: p.y * ratio * camera.a - 13, left: p.x * ratio * camera.a - 13 };
};

const getIcon = ({
    position,
}) => ((position === 'top' || position === 'bottom') && 'arrow-v') || ((position === 'left' || position === 'right') && 'arrow-h') || '';

export const SplitCursor = ({
    content,
    cell,
    ratio,
    camera,
}: {
  content: Content;cell: Cell;ratio: number;camera: Camera;
}) => (
    <div className="blSplitCursor" style={computePosition(content, cell, ratio, camera)}>
        <i className={`old-icon old-icon-${getIcon(content)}`} />
    </div>
);
