import React from 'react';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton';
import {CriteriaImporter, IMPORT_CRITERIA_ACTION_ID} from '../../../../Helpers/excel/criteriaImport';
import CriteriaImportModalItem from './CriteriaImportModalItem';
import {addNotifAction, addNotifSuccess} from '../../../../Redux/reducers/appNotificationsSlice';
import {useAppDispatch} from '../../../../Helpers/hooks';

type Props = {
    onClose: () => void;
    criteriaImporter: CriteriaImporter;
};

const CriteriaImportModal: React.FC<Props> = (
    {
        onClose,
        criteriaImporter,
    }
) => {
    const dispatch = useAppDispatch();

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={onClose}>
            <ModalHeader
                subtitle={i18n.t('modals.CreateCriterion.subtitle')}
                title={i18n.t('modals.CreateCriterion.title')}
                icon="Filter"
            />
            <div className="util-modal-oflow-auto">
                {criteriaImporter.criteria.map((criterion, index) => (
                    <CriteriaImportModalItem
                        key={criterion.name}
                        criteriaImporter={criteriaImporter}
                        item={criterion}
                        index={index}
                    />
                ))}
            </div>
            <ModalFooter>
                <MuButton
                    handleClick={async () => {
                        dispatch(addNotifAction(
                            IMPORT_CRITERIA_ACTION_ID,
                            i18n.t('criteria.import'),
                            async (setProgress) => {
                                await dispatch(criteriaImporter.run(setProgress));
                                dispatch(addNotifSuccess(i18n.t('criteria.import')));
                            },
                        ));
                        onClose();
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default CriteriaImportModal;
