import React from 'react';
import { useDispatch } from 'react-redux';
import { Project } from 'mushin-redux-store';
import i18n from 'i18next';
import ReactPlayer from 'react-player';
import MuIcon from '../../MuIcon';
import {isProjectAdmin} from '../../../Helpers/users';
import {openModal} from '../../../Redux/reducers/modalsSlice';
import {classWithModifiers} from '../../../Helpers/css';

type Props = {
    project: Project;
    header?: boolean;
};

const ProjectCoverVideo: React.FC<Props> = ({project, header}) => {
    const dispatch = useDispatch();

    const openDashboardModal = (type: 'EditProject' | 'EditProjectUsers' | 'EditProjectCover') => {
        dispatch(openModal(type, { project }));
    };

    const canEdit = isProjectAdmin(project);

    if (project.cover_video_url) {
        return (
            <div className={classWithModifiers('mu-project-dashboard__picture-block', {header})}>
                <div className="mu-project-dashboard__player-wrapper">
                    <ReactPlayer
                        className="mu-project-dashboard__player"
                        url={project.cover_video_url}
                        width="100%"
                        height="100%"
                        controls
                    />
                    {canEdit && (
                        <button
                            className="mu-edit-button mu-project-dashboard__picture-edit"
                            type="button"
                            onClick={() => openDashboardModal('EditProjectCover')}
                        >
                            <MuIcon className="mu-edit-button__icon" svgName="Edit" />
                        </button>
                    )}
                </div>
            </div>
        );
    }

    if (canEdit) {
        return (
            <button
                type="button"
                className="mu-panel-secondary mu-project-dashboard__picture-block"
                onClick={() => openDashboardModal('EditProjectCover')}
            >
                <MuIcon className="mu-project-dashboard__picture-block-icon" svgName="VideoCamera" />
                <span className="mu-project-dashboard__picture-block-text">
                    {i18n.t('projects.addCoverVideo')}
                </span>
            </button>
        );
    }

    return null;
};

export default ProjectCoverVideo;
