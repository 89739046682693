import { SetSearchParams, useSearchParams } from '../../../../Helpers/queryParser';
import { NoteSearchParams } from '../../../../Components/GalleryToolbar/GalleryToolbar';
import { NoteQueryParams, useNotesQuery } from 'mushin-redux-store';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GalleryParams } from '../../types';
import Client from '../../../../Services/Client';

const buildQueryParams = (params: GalleryParams, searchParams: NoteSearchParams): NoteQueryParams => {
    const queryParams = { ...searchParams };
    if (params.questionnaireId) {
        queryParams.questionnaire_id = params.questionnaireId;
        queryParams.question_id = params.questionId;
    } else if (params.album_id) {
        queryParams.album_id = params.album_id;
    } else {
        queryParams.organization_id = params.organizationId;
        queryParams.project_id = params.projectId;
    }
    if (params.inspirationType === 'mine') {
        queryParams.users = [Client.getId() as string];
    }
    return queryParams as NoteQueryParams;
};

const useNotesQueryWithSearchParams = (): [
    ReturnType<typeof useNotesQuery>,
    NoteSearchParams,
    SetSearchParams<NoteSearchParams>,
] => {
    const params = useParams<GalleryParams>();

    const [searchParams, setSearchParams] = useSearchParams<NoteSearchParams>({
        profiles: 'array',
        users: 'array',
        groups: 'array',
        hashtags: 'array',
        favorite_of_users: 'array',
        criteria: 'array',
        answers: 'array',
    });
    const notesQuery = useNotesQuery(buildQueryParams(params, searchParams), !Object.keys(searchParams).length);
    const { setParams } = notesQuery;
    useEffect(() => {
        setParams(buildQueryParams(params, searchParams), !Object.keys(searchParams).length);
    }, [params, searchParams, setParams]);

    return [notesQuery, searchParams, setSearchParams];
};

export default useNotesQueryWithSearchParams;
