/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { Children, useRef, useState } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import { Modifiers, classWithModifiers } from '../../Helpers/css';
import MuIcon from '../MuIcon';

interface ICustomSwiper {
  classModifier?: Modifiers;
  className?: string;
  config?: SwiperProps;
  children: React.ReactNode;
}

const CustomSwiper: React.FC<ICustomSwiper> = ({ className = '', classModifier, config, children }) => {
    const [hideNavigation, setHideNavigation] = useState(true);
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);

    const renderContent = () => {
        const childList: any[] = [];
        Children.forEach(children, (child) => childList.push(child));
        return childList.map((child, index) => <SwiperSlide key={child?.key ?? index}>{child}</SwiperSlide>);
    };

    const handleUnlock = () => {
        setHideNavigation(false);
    };

    return (
        <div className={classWithModifiers('mu-custom-swiper', classModifier, className)}>
            <button hidden={hideNavigation} type="button" ref={prevRef} className="mu-custom-swiper__prev-btn">
                <MuIcon svgName="ArrowLeft" />
            </button>
            <Swiper
                modules={[Navigation]}
                navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                }}
                observer
                observeParents
                parallax
                spaceBetween={20}
                slidesPerView={1}
                watchOverflow
                onUnlock={handleUnlock}
                {...config}
            >
                {renderContent()}
            </Swiper>
            <button hidden={hideNavigation} type="button" ref={nextRef} className="mu-custom-swiper__next-btn">
                <MuIcon svgName="ArrowRight" />
            </button>
        </div>
    );
};

export default CustomSwiper;
