import React from 'react';
import { ProfileAvatar } from './ProfileAvatar';
import { UsernameJob } from './UsernameJob';
import {getNameToDisplay, useProfileWithDefault} from '../../Helpers/users';

type UserCardProps = {
    id: string;
    hideEmail?: boolean;
    size?: 'small' | 'large';
};

const ProfileCard: React.FC<UserCardProps> = ({
    id,
    hideEmail = false,
    size,
}) => {
    const profile = useProfileWithDefault(id);

    if (!profile) return null;

    return (
        <div className="mu-user-card">
            <ProfileAvatar userId={id} size={size} />
            <UsernameJob
                name={getNameToDisplay(profile)}
                job={
                    (profile.user?.email && !hideEmail)
                        ? profile.user?.email
                        : undefined
                }
                classModifiers="user-card"
            />
        </div>
    );
};

export default ProfileCard;
