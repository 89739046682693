export const clamp = (value: number, min: number, max: number): number => {
    if (value < min) return min;
    if (value > max) return max;
    return value;
};

export const roundWithPrecision = (x: number, precision: number): number => {
    const mod = 10 ** precision;
    return Math.round((x + Number.EPSILON) * mod) / mod;
};
