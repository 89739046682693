import { Handler } from './type';

import { project, translate } from '../injectCamera';

export const onTouchDown: Handler = ({
    state,
    camera,
}, pointers) => {
    if (state.dragging) return;

    return {
        state: {
            ...state,
            dragging: 'camera',
            drag: {
                originPoint: project(camera)(pointers[0]),
                originCamera: camera,
            },
        },
    };
};

export const onTouchMove: Handler = ({
    state,
    camera,
}, pointers) => {
    if (state.dragging !== 'camera') return;

    if (!pointers[0]) return;
    const {
        originPoint,
        originCamera,
    } = state.drag;

    const p = project(camera)(pointers[0]);

    const dx = p.x - state.drag.originPoint.x;
    const dy = p.y - state.drag.originPoint.y;

    const b = {
        x: originCamera.b.x + dx,
        y: originCamera.b.y + dy,
    };

    return { camera: translate(originCamera, b) };
};

export const onTouchUp: Handler = ({
    state,
}) => {
    if (state.dragging !== 'camera') return;

    return {
        state: {
            ...state,
            dragging: null,
        },
    };
};
