import React from 'react';
import i18n from 'i18next';
import {QuestionFree} from 'mushin-redux-store';
import {AnswerProps, NoteValue} from '../types';
import DualChoice from '../../../Components/DualChoice/DualChoice';
import FreeAnswerItem from './FreeAnswerItem';

type Props = AnswerProps<QuestionFree>

const FreeAnswer: React.FC<Props> = ({ question, value: notes, setValue, disabled }) => {
    const setItemValue = (index: number, itemValue: NoteValue | null) => {
        const newValue = notes ? [...notes] : [];
        if (itemValue) {
            newValue[index] = itemValue;
        } else {
            newValue.splice(index, 1);
        }
        setValue({notes: newValue});
    };

    const deleteItem = (index: number, itemValue: NoteValue) => {
        const newValue = notes ? [...notes] : [];
        if (itemValue.id) {
            newValue[index] = { ...itemValue, deleted: true };
        } else {
            newValue.splice(index, 1);
        }
        setValue({notes: newValue});
    };

    const addItemValue = (...itemValues: NoteValue[]) => {
        setValue({notes: notes ? [...notes, ...itemValues] : itemValues});
    };

    const allowText = question.freeAnswerTypes.includes('text');
    const allowImage = question.freeAnswerTypes.includes('image');
    const allowVideo = question.freeAnswerTypes.includes('video');

    if (question.freeAnswerTypes.length === 1 && allowText && !notes) {
        setValue({notes: [{image: null, text: ''}]});
    }

    const mediaType = (
        (allowImage && (allowVideo ? 'image/*,video/*' : 'image/*')) || (allowVideo ? 'video/*' : null)
    );

    const lastItem = notes?.length && notes[notes.length - 1];
    const canAddAnswer = !lastItem
        ? true
        : lastItem.image || lastItem.text || lastItem.image_src || lastItem.video_url;

    return (
        <div className="mu-free-answer">
            {question.confidential && (
                <div className="mu-question__instruction">
                    {i18n.t('questionnaires.confidentialInstruction')}
                </div>
            )}
            <div className="mu-free-answer__items">
                {notes?.map((noteValue, index) => (
                    !noteValue.deleted
                    && (
                        <FreeAnswerItem
                            key={index}
                            index={index}
                            value={noteValue}
                            setValue={(_itemValue) => setItemValue(index, _itemValue)}
                            deleteValue={() => deleteItem(index, noteValue)}
                            disabled={disabled}
                            types={question.freeAnswerTypes}
                        />
                    )
                ))}
            </div>
            {!!notes?.length && canAddAnswer && (
                <div className="mu-free-answer__add-answer">{i18n.t('questionnaires.addAnswer')}</div>
            )}
            {canAddAnswer && (
                <DualChoice
                    className="mu-modal__body"
                    value={null}
                    setValue={(val) => {
                        if (val === 'text') addItemValue({image: null, text: ''});
                    }}
                    choice1={!!mediaType && {
                        key: 'image',
                        label: (
                            (allowImage ? i18n.t('models.notes.types.image') : '')
                            + (allowImage && allowVideo ? ' / ' : '')
                            + (allowVideo ? i18n.t('models.notes.types.video') : '')
                        ),
                        icon: 'ImageGallery',
                        fileInput: {
                            id: `add-image-from-computer-${question.id}`,
                            multiple: false,
                            accept: mediaType,
                            onChange: (files) => addItemValue({image: files[0], text: ''}),
                        },
                    }}
                    choice2={allowText && {
                        key: 'text',
                        label: i18n.t('models.notes.types.text'),
                        icon: 'Chat',
                    }}
                    flat
                    disabled={disabled}
                />
            )}
        </div>
    );
};

export default FreeAnswer;
