import React, {useEffect, useState} from 'react';
import {ApiV3, AvailableLocale} from 'mushin-redux-store';
import i18n from 'i18next';
import SessionsLayout from '../Components/SessionsLayout/SessionsLayout';
import Document, {DocumentContent} from '../../../Components/Document/Document';

const CguPage: React.FC = () => {
    const [cgu, setCGU] = useState<DocumentContent | null>(null);

    useEffect(() => {
        ApiV3.getInstance().get(
            'users/cgu/public', { params: {locale: i18n.resolvedLanguage as AvailableLocale}}
        ).then((response) => setCGU(response.data));
    }, []);

    return (
        <div id="mushin-app">
            <SessionsLayout>
                <h1 className="mu-terms__title">{i18n.t('accounts.signUp.cgu.title')}</h1>
                {cgu && (<Document desc={cgu} />)}
            </SessionsLayout>
        </div>
    );
};

export default CguPage;
