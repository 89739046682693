import React, { useState } from 'react';
import { AvailableLocale, Criterion, CriterionAddUserByEmailResponse } from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import MuButton from '../../../../Components/MuButton';
import Spinner from '../../../../Components/Spinner';
import InfoPanel from '../../../../Components/InfoPanel/InfoPanel';
import { ModalHeader } from '../../../../Components/Modal/ModalHeader';
import { ModalFooter } from '../../../../Components/Modal/ModalFooter';
import { ModalBody } from '../../../../Components/Modal/ModalBody';
import MuIcon from '../../../../Components/MuIcon';
import ReactMultiEmail from '../../../../Components/ReactMultiEmail/ReactMultiEmail';
import { addNotifSuccess } from '../../../../Redux/reducers/appNotificationsSlice';
import { useAppDispatch } from '../../../../Helpers/hooks';
import DropdownMenu from '../../../../Components/DropdownMenu/DropdownMenu';
import { displayValue, isChecked, setChecked } from '../../../../Helpers/questionnaires';
import CheckboxRadio from '../../../../Components/Form/CheckboxRadio';
import LanguageSelect from '../../../../Components/Form/LanguageSelect';

type Props = {
    criterion: Criterion;
    handleSubmit: (emails: string[], criterionValue: string | string[], locale: AvailableLocale) => Promise<CriterionAddUserByEmailResponse>;
    onClose: () => void;
};

const CriteriaAddUsersModal: React.FC<Props> = ({
    criterion,
    handleSubmit,
    onClose,
}) => {
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [emails, setEmails] = useState<string[]>([]);
    const [criterionValue, setCriterionValue] = useState<string | string[] | null>(null);
    const [locale, setLocale] = useState(i18n.resolvedLanguage as AvailableLocale);
    const [isOpen, setOpen] = useState(false);

    const dispatch = useAppDispatch();

    const submit = async () => {
        setLoading(true);
        if (emails.length && criterionValue) {
            try {
                const result = await handleSubmit(emails, criterionValue, locale);
                dispatch(addNotifSuccess(i18n.t('modals.inviteUsers.success', { count: result?.users?.length })));
                onClose();
            } catch (e) {
                console.error('Add error', e);
                setError(i18n.t<string>('modals.inviteUsers.error'));
            }
        }
        setLoading(false);
    };

    return (
        <Modal classModifiers={['large']} handleRemoveModal={onClose}>
            <ModalHeader title={i18n.t('modals.inviteUsers.title')} icon="UserLine" />
            <ModalBody>
                {error && <InfoPanel classModifiers={['error']} iconName="Warning" text={error} />}
                {isLoading ? (
                    <div className="mu-modal__spinner">
                        <Spinner size="50px" />
                    </div>
                ) : (
                    <>
                        <label className="mu-input__label">
                            {i18n.t('modals.inviteUsers.language')}
                        </label>
                        <div className="mu-form-row">
                            <LanguageSelect value={locale} onChange={setLocale} />
                        </div>
                        <label className="mu-input__label">{i18n.t('modals.inviteUsers.criterionValues')}</label>
                        <div className="mu-form-row">
                            <DropdownMenu
                                align="center"
                                isOpen={isOpen}
                                setIsOpen={setOpen}
                                toggle={(
                                    <div className="mu-filters-toolbar__filter-toggle">
                                        {displayValue(
                                            criterion.values,
                                            Array.isArray(criterionValue) && !criterionValue.length
                                                ? null
                                                : criterionValue
                                        )}
                                    </div>
                                )}
                            >
                                {criterion.values.map((option) => {
                                    const checked = criterionValue ? isChecked(option.slug, criterionValue) : false;
                                    return (
                                        <CheckboxRadio
                                            className="mu-dropdown-menu__button-item"
                                            key={option.slug}
                                            setChecked={() => {
                                                setCriterionValue(
                                                    setChecked(
                                                        !checked,
                                                        option.slug,
                                                        criterionValue,
                                                        !criterion.multiple
                                                    )
                                                );
                                            }}
                                            checked={checked}
                                            label={option.label}
                                            uniqueSelection={!criterion.multiple}
                                        />
                                    );
                                })}
                            </DropdownMenu>
                        </div>
                        <label className="mu-input__label">{i18n.t('modals.inviteUsers.description')}</label>
                        <div className="mu-form-row">
                            <ReactMultiEmail
                                placeholder={i18n.t('modals.inviteUsers.placeholder')}
                                emails={emails}
                                onChange={(newEmails) => {
                                    setEmails(newEmails);
                                }}
                                getLabel={(
                                    email,
                                    index,
                                    removeEmail
                                ) => {
                                    return (
                                        <div key={index} className="react-multi-email__item">
                                            {email}
                                            <button
                                                className="react-multi-email__item-button"
                                                type="button"
                                                onClick={() => removeEmail(index)}
                                            >
                                                <MuIcon svgName="Close" />
                                            </button>
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <MuButton
                    label={i18n.t('global.cancel')}
                    handleClick={onClose}
                    classModifiers={['large', 'default']}
                />
                <MuButton
                    disabled={
                        !emails.length
                        || !(Array.isArray(criterionValue) ? criterionValue.length : criterionValue)
                        || isLoading
                    }
                    label={`${i18n.t('global.confirm')}${emails.length ? ` (${emails.length})` : ''}`}
                    handleClick={submit}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>
    );
};

export default CriteriaAddUsersModal;
