import {Target} from 'mushin-redux-store';

export const targetTitle = (target: Target): string => {
    if (target.questionnaire) return target.questionnaire.title;
    if (target.album) return target.album.label;
    if (target.project) return target.project.name;
    return target.organization.name;
};

export const targetUrl = (target: Target): string => {
    let url = `/projects/${target.project_id}`;
    if (target.album) url += `/albums/${target.album_id}`;
    if (target.questionnaire) url += `/questionnaires/${target.questionnaire_id}`;
    return url;
};
