import React from 'react';
import { classWithModifiers } from '../../../../../../Helpers/css';

export type ActionBarProps = React.PropsWithChildren<{
  direction?: 'vertical' | 'horizontal';
}>;

export const ActionBar: React.FC<ActionBarProps> = ({
    children,
    direction = 'horizontal',
}) => {
    return (
        <div className={classWithModifiers('blActionBar', [direction])} role="group" onMouseDown={(e) => e.stopPropagation()}>
            {children}
        </div>
    );
};

export const ActionBarGroup: React.FC<ActionBarProps> = ({
    children,
    direction = 'horizontal',
}) => {
    return (
        <div className={classWithModifiers('blActionBarGroup', [direction])} role="group">
            {children}
        </div>
    );
};
