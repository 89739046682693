import React from 'react';
import i18n from 'i18next';
import Badge from './Badge';

type SCProps = {
    status: string;
    className?: string;
    background?: boolean;
};

const statusModifiers: {[key: string]: string | undefined} = {
    inProgress: 'valid',
    draft: 'warning',
    scheduled: 'info',
    finished: 'finished',
    archived: 'archived',
};

export const BadgeComponent: React.FC<SCProps> = ({ status, className, background = false }) => {
    const modifier = statusModifiers[status];
    return (
        <Badge
            className={className}
            classModifiers={[modifier, background && 'background']}
        >
            {i18n.t(`global.status.${status}`)}
        </Badge>
    );
};
