import { snap } from './snap';
import { minBox } from './minBox';
import { hitExpandedBoxes, hitMargin, setCell } from '../../layoutUtils';
import { Handler } from '../type';

export const MARGIN = 8;

/**
 * check if the pointer did is down on a cell margin
 * if so, register the state as dragging:scale
 */
export const onTouchDown: Handler = ({
    state,
    board,
    selectedCell,
}, pointers) => {
    if (state.dragging) return;

    /**
   * do a hit test with the cells
   */
    const hit = pointers[0] && hitExpandedBoxes(board.ids.map((id) => board.cells[id]), MARGIN / 2, pointers[0], board.cells[selectedCell]);

    /**
   * get the id of the cell
   */
    const id = Object.keys(board.cells).find((idCell) => board.cells[idCell] === hit);

    let hoveredCell = id;
    if (selectedCell === id) {
        hoveredCell = null;
    }

    /**
   * do a hit test with a fine margin around the cell
   */
    const marginHit = hit && hitMargin(hit, MARGIN / 2, pointers[0]);

    if (marginHit) {
        return {
            state: {
                ...state,
                dragging: 'scale',
                hoveredCell,
                drag: {
                    scalingKernel: marginHit,
                    originPoint: pointers[0],
                    originCell: hit,
                    cellId: id,
                },
            },
        };
    }
};

export const onTouchMove: Handler = ({
    state,
    board,
    selectedCell,
}, pointers) => {
    if (state.dragging !== 'scale') return;

    if (!pointers[0]) return;

    const {
        originCell,
        cellId,
        scalingKernel,
    } = state.drag;

    const dx = pointers[0].x - state.drag.originPoint.x;
    const dy = pointers[0].y - state.drag.originPoint.y;

    // translate the cell
    const newCell = {
        max: {
            x: originCell.max.x + dx * (scalingKernel.x === 1 ? 1 : 0),
            y: originCell.max.y + dy * (scalingKernel.y === 1 ? 1 : 0),
        },
        min: {
            x: originCell.min.x + dx * (scalingKernel.x === -1 ? 1 : 0),
            y: originCell.min.y + dy * (scalingKernel.y === -1 ? 1 : 0),
        },
    };

    // snap to margin
    const snapRes = snap(board.ids.filter((id) => id !== cellId).map((id) => board.cells[id]), {
        x: board.width,
        y: board.height,
    }, newCell, scalingKernel, board.margin);

    // prevent the box from being too small
    const sizeLimit = Math.min(board.width, board.height) / 20;
    const newCell2 = minBox(sizeLimit, snapRes.cell, scalingKernel);

    let hoveredCell = cellId;
    if (selectedCell === cellId) {
        hoveredCell = null;
    }

    return {
        state: { ...state, hoveredCell, snapRules: snapRes.rules },
        board: setCell(board, cellId, newCell2),
    };
};

export const onTouchUp: Handler = ({
    state,
}) => {
    if (state.dragging !== 'scale') return;

    return {
        state: {
            ...state,
            snapRules: [],
            dragging: null,
        },
    };
};
