import type { FormatLong } from '../../../types';
import buildFormatLongFn from '../../../_lib/buildFormatLongFn/index';

const dateFormats = {
    full: 'EEEE d MMMM y',
    long: 'd MMMM y',
    medium: 'd MMM y',
    short: 'dd/MM/y',
};

const timeFormats = {
    full: 'HH:mm:ss zzzz',
    long: 'HH:mm:ss z',
    medium: 'HH:mm:ss',
    short: 'HH:mm',
};

const dateTimeFormats = {
    full: '{{date}} {{time}}',
    long: '{{date}} {{time}}',
    medium: '{{date}} {{time}}',
    short: '{{date}} {{time}}',
};

const formatLong: FormatLong = {
    date: buildFormatLongFn({
        formats: dateFormats,
        defaultWidth: 'full',
    }),

    time: buildFormatLongFn({
        formats: timeFormats,
        defaultWidth: 'full',
    }),

    dateTime: buildFormatLongFn({
        formats: dateTimeFormats,
        defaultWidth: 'full',
    }),
};

export default formatLong;
