import React, { useState } from 'react';
import {QuestionRating} from 'mushin-redux-store';
import i18n from 'i18next';
import Select from '../../../Components/Select/Select';

type UpdateQuestionPayload = {
    maxRatingValue?: number,
    minRatingLabel?: string,
    maxRatingLabel?: string,
}
type Props = {
    updateQuestion: (payload: UpdateQuestionPayload) => void;
    question: QuestionRating;
    className?: string;
    disabled?: boolean;
};

const EditRatingQuestionOptions: React.FC<Props> = ({
    updateQuestion,
    question,
    disabled,
}) => {
    const [minValueLabel, setMinValueLabel] = useState(question.minRatingLabel);
    const [maxValueLabel, setMaxValueLabel] = useState(question.maxRatingLabel);

    return (
        <>
            <div className="mu-question-editor__rating-range">
                1
                <span className="mu-question-editor__range-to-separator">
                    {i18n.t('questionEdition.to')}
                </span>
                <Select
                    className=""
                    value={question.maxRatingValue?.toString() || ''}
                    disabled={disabled}
                    mod="light"
                >
                    {[5, 10].map((i) => (
                        <button
                            key={i}
                            className="mu-dropdown-menu__button-item"
                            type="button"
                            onClick={() => {
                                updateQuestion({maxRatingValue: i});
                            }}
                        >
                            {i}
                        </button>
                    ))}
                </Select>
            </div>
            <div className="mu-question-editor__rating-label">
                1
                <input
                    className="mu-edit-multiple-choice-item__input"
                    placeholder={i18n.t<string>('questionEdition.optionalLabel')}
                    value={minValueLabel}
                    onChange={(e) => setMinValueLabel(e.currentTarget.value)}
                    onBlur={(e) => {
                        updateQuestion({minRatingLabel: e.currentTarget.value});
                    }}
                    disabled={disabled}
                />
            </div>
            <div className="mu-question-editor__rating-label">
                {question.maxRatingValue}
                <input
                    className="mu-edit-multiple-choice-item__input"
                    placeholder={i18n.t<string>('questionEdition.optionalLabel')}
                    value={maxValueLabel}
                    onChange={(e) => setMaxValueLabel(e.currentTarget.value)}
                    onBlur={(e) => {
                        updateQuestion({maxRatingLabel: e.currentTarget.value});
                    }}
                    disabled={disabled}
                />
            </div>
        </>
    );
};

export default EditRatingQuestionOptions;
