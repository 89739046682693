import React, { useEffect, useState } from 'react';
import { useMe, UserPayload, deleteMyAccount, AvailableLocale, updateMe } from 'mushin-redux-store';
import { useTranslation } from 'react-i18next';
import { AccountAlerts } from '../../../Services/Alerts/accountAlert';
import AccountSettingsLayout from '../../../Components/SettingsComponents/AccountSettingsLayout';
import InputText from '../../../Components/InputText/InputText';
import LanguageSelect from '../../../Components/Form/LanguageSelect';
import { openAlert } from '../../../Redux/reducers/modalsSlice';
import { useAppDispatch } from '../../../Helpers/hooks';

const Account: React.FC = () => {
    const me = useMe();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordConfirmationError, setPasswordConfirmationError] = useState('');

    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (me) {
            setName(me.name || '');
            setEmail(me.email);
            setCurrentPassword('');
            setPassword('');
            setPasswordConfirmation('');
        }
    }, [me]);

    if (!me) return null;
    const handleFormValidation = () => {
        let has_errors = false;

        if (!email.length) {
            setEmailError(i18n.t<string>('global.errors.no_empty'));
            has_errors = true;
        } else if (!email.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gi)) {
            setEmailError(i18n.t<string>('global.errors.invalid_format'));
            has_errors = true;
        }

        if (password || passwordConfirmation) {
            if (!currentPassword) {
                setCurrentPasswordError(i18n.t<string>('global.errors.no_empty_password'));
                has_errors = true;
            }
            if (password.length > 0 && password.length < 8) {
                setPasswordError(i18n.t<string>('models.users.errors.short_password'));
                has_errors = true;
            }
            if (password.length > 0 && password !== passwordConfirmation) {
                setPasswordConfirmationError(i18n.t<string>('models.users.errors.different_confirmation'));
                has_errors = true;
            }
        }

        return has_errors;
    };

    const handleProfileUpdate = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!handleFormValidation()) {
            const editedDataToUpdate: UserPayload = {
                name,
                email,
                locale: i18n.resolvedLanguage as AvailableLocale,
            };
            if (password) {
                editedDataToUpdate.current_password = currentPassword;
                editedDataToUpdate.password = password;
                editedDataToUpdate.password_confirmation = passwordConfirmation;
            }
            try {
                await dispatch(updateMe(editedDataToUpdate));
                AccountAlerts.accountUpdateSuccess();
            } catch (error) {
                if (error.response?.data.message === 'Bad current password') {
                    setCurrentPasswordError(i18n.t<string>('global.errors.no_correspondance_password'));
                } else if (error.response?.data.message === 'No new password') {
                    setPasswordError(i18n.t<string>('global.errors.no_new_password'));
                } else {
                    console.error(error);
                    AccountAlerts.accountUpdateError();
                }
            }
        }
    };

    return (
        <AccountSettingsLayout imgUrl={me.avatar_url} imgAlt={me.name} subTitle={me.name}>
            <div className="settings-layout__container settings-layout__user-info">
                <form noValidate onSubmit={handleProfileUpdate}>
                    <h3 className="settings-layout__title">{i18n.t('accounts.update.informations')}</h3>
                    <div className="mu-form-row">
                        <div className="mu-form-row__group">
                            <InputText
                                classModifiers={['full-width']}
                                id="account-settings-name"
                                onChange={(e) => setName(e.target.value)}
                                label={i18n.t('models.users.nameToDisplay')}
                                type="text"
                                value={name}
                            />
                        </div>
                    </div>
                    <div className="mu-form-row">
                        <div className="mu-form-row__group mu-form-row__group--cols2">
                            <InputText
                                classModifiers={['full-width']}
                                id="account-settings-email"
                                onChange={(e) => setEmail(e.target.value)}
                                label={i18n.t('models.users.email')}
                                type="text"
                                error={!!emailError}
                                errorMessage={emailError}
                                value={email}
                            />
                        </div>
                        <div className="mu-form-row__group mu-form-row__group--cols2">
                            <LanguageSelect
                                value={i18n.resolvedLanguage as AvailableLocale}
                                onChange={(locale) => i18n.changeLanguage(locale)}
                            />
                        </div>
                    </div>

                    <div className="settings-layout__button-container">
                        <button type="submit" id="profile_update_button2" className="mu-btn">
                            {i18n.t('global.validate')}
                        </button>
                    </div>

                    <h3 className="settings-layout__title">{i18n.t('accounts.update.password')}</h3>

                    <div className="mu-form-row">
                        <div className="mu-form-row__group mu-form-row__group--cols2">
                            <InputText
                                classModifiers={['full-width']}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                placeholder={i18n.t('models.users.current_password')}
                                type="password"
                                error={!!currentPasswordError}
                                errorMessage={currentPasswordError}
                                value={currentPassword}
                                autocomplete="new-password"
                            />
                        </div>
                    </div>
                    <div className="mu-form-row">
                        <div className="mu-form-row__group mu-form-row__group--cols2">
                            <InputText
                                classModifiers={['full-width']}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={i18n.t('models.users.password')}
                                type="password"
                                error={!!passwordError}
                                errorMessage={passwordError}
                                value={password}
                                autocomplete="new-password"
                            />
                        </div>
                        <div className="mu-form-row__group mu-form-row__group--cols2">
                            <InputText
                                classModifiers={['full-width']}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                placeholder={i18n.t('models.users.password_confirmation')}
                                type="password"
                                error={!!passwordConfirmationError}
                                errorMessage={passwordConfirmationError}
                                value={passwordConfirmation}
                                autocomplete="new-password"
                            />
                        </div>
                    </div>

                    <div className="settings-layout__button-container">
                        <button type="submit" id="profile_update_button" className="mu-btn">
                            {i18n.t('global.validate')}
                        </button>
                    </div>
                </form>

                <h3 className="settings-layout__title">{i18n.t('accounts.actions')}</h3>

                <div className="settings-layout__button-container">
                    <button
                        type="button"
                        className="mu-btn mu-btn--error"
                        onClick={() =>
                            dispatch(
                                openAlert({
                                    type: 'danger',
                                    title: i18n.t('modals.deleteAccount.title'),
                                    icon: 'Warning',
                                    confirmLabel: i18n.t('global.confirm'),
                                    body: i18n.t('modals.deleteAccount.body'),
                                    confirmAction: async () => {
                                        await dispatch(deleteMyAccount());
                                    },
                                }),
                            )
                        }
                    >
                        {i18n.t('accounts.delete')}
                    </button>
                </div>
            </div>
        </AccountSettingsLayout>
    );
};

export default Account;
