import React, { useCallback, useState } from 'react';
import Delta from 'quill-delta';

import { useDispatch } from 'react-redux';
import { Chat, ImageNote, ProfilesQueryParams } from 'mushin-redux-store';
import { isOpEmpty } from 'mushin-node-commons';
import i18n from 'i18next';
import AzureBlobService from '../../Services/APIDirectory/azure';

import UniqueIdGenerator from '../../Helpers/UniqueIdGenerator';
import MuIcon from '../MuIcon';
import QuillRichInput from '../QuillRichInput/QuillRichInput';
import { AddModalImages } from '../../Scenes/AppModals/Components/AddImagesModal/AddIdeaModal';
import ImageThumbnail from '../ImageThumbnail/ImageThumbnail';
import { openModal } from '../../Redux/reducers/modalsSlice';

type Attachment = {
    type: 'NOTE' | 'INTERNAL_LINK' | 'EXTERNAL_LINK';
    content: string;
    imageSrc?: string;
    id?: string;
};

type AddMessageProps = {
    chat?: Chat;
    onSubmitMessage: (message: Delta, attachments: Attachment[]) => void;
    userMentionsQueryParams?: ProfilesQueryParams;
    withImportFiles?: boolean;
    defaultMessage?: Delta;
};

const MessageInput: React.FC<AddMessageProps> = ({
    chat,
    onSubmitMessage,
    userMentionsQueryParams,
    defaultMessage,
    withImportFiles = true,
}) => {
    const [messageToSend, setMessageToSend] = useState(new Delta(defaultMessage?.ops || [{ insert: '' }]));
    const [attachments, setAttachments] = useState<Attachment[]>([]);

    const dispatch = useDispatch();

    const handleSubmit = useCallback(
        (value: Delta) => {
            if ((!value.ops?.length || (value.ops.length === 1 && isOpEmpty(value.ops[0]))) && !attachments.length) {
                return;
            }

            const attachmentsList = [...attachments];
            if (withImportFiles) {
                /* const contentMatch = messageToSend.toLowerCase().match(pictureRegExp());
            if (contentMatch) {
                attachmentsList.push({
                    type: 'EXTERNAL_LINK',
                    content: contentMatch[0],
                });
            }
            */

                for (const attachment of attachments) {
                    delete attachment.imageSrc;
                    delete attachment.id;
                }
            }

            onSubmitMessage(value, attachmentsList);
            setMessageToSend(new Delta([{ insert: '' }]));
            setAttachments([]);
        },
        [attachments, onSubmitMessage, withImportFiles],
    );

    const importPictureFromComputer = async (images: File[]) => {
        if (images) {
            const newFiles = [...attachments];
            for await (const file of Array.from(images)) {
                await AzureBlobService.put(file).then((data) => {
                    newFiles.push({
                        type: 'INTERNAL_LINK',
                        content: data.src,
                        imageSrc: data.src,
                        id: UniqueIdGenerator(),
                    });
                });
            }

            setAttachments(newFiles);
        }
    };

    const importPictureFromMushin = (notes: Array<ImageNote>) => {
        const imagesAttachments: Attachment[] = notes.map((note) => ({
            type: 'NOTE',
            content: note.id,
            imageSrc: note.image_src.thumb,
            id: UniqueIdGenerator(),
        }));

        setAttachments((prevState) => [...prevState, ...imagesAttachments]);
    };

    const removeAttachments = (index: number) => {
        const newAttachments = attachments.filter((attachment, attachmentIndex) => attachmentIndex !== index);
        setAttachments(newAttachments);
    };

    return (
        <>
            {attachments && attachments.length > 0 && (
                <section className="mu-message-input__attachments">
                    {attachments &&
                        attachments.map(({ imageSrc }, index) => {
                            return (
                                <div className="mu-message-input__attachment" key={imageSrc}>
                                    <ImageThumbnail imgUrl={imageSrc || ''} />
                                    <button
                                        type="button"
                                        className="mu-note-bucket__notes-item-btn"
                                        onClick={() => removeAttachments(index)}
                                    >
                                        <MuIcon className="mu-note-bucket__notes-item-icon" svgName="CloseCircleFill" />
                                    </button>
                                </div>
                            );
                        })}
                </section>
            )}
            <div className="mu-message-input">
                <div className="mu-message-input__input">
                    <div className="mu-message-input__input-text">
                        <QuillRichInput
                            value={messageToSend}
                            setValue={setMessageToSend}
                            placeholder={`${i18n.t('models.comments.add')}`}
                            userMentionsQueryParams={userMentionsQueryParams}
                            onEnter={handleSubmit}
                            toolbar={[
                                ['bold', 'italic', 'link'], // toggled buttons
                                [{ list: 'bullet' }, { list: 'ordered' }],
                            ]}
                        />
                    </div>
                    {withImportFiles && chat && (
                        <button
                            type="button"
                            className="mu-message-input__add-picture"
                            onClick={() => {
                                dispatch(
                                    openModal('AddIdeaModal', {
                                        addImages: async (addModalImages: AddModalImages) => {
                                            if (addModalImages.notes.length) {
                                                importPictureFromMushin(addModalImages.notes as ImageNote[]);
                                            }
                                            if (addModalImages.images.length) {
                                                await importPictureFromComputer(addModalImages.images);
                                            }
                                        },
                                    }),
                                );
                            }}
                        >
                            <MuIcon svgName="Image" />
                        </button>
                    )}
                </div>
                <button
                    type="submit"
                    className="mu-message-input__submit-btn"
                    onClick={() => handleSubmit(messageToSend)}
                >
                    <MuIcon svgName="Send" />
                </button>
            </div>
        </>
    );
};

export default MessageInput;
