import React, {useState} from 'react';
import {
    addNotesToAlbum, Note, useAlbum,
} from 'mushin-redux-store';
import {useAppDispatch} from '../../Helpers/hooks';
import MuIcon from '../MuIcon';
import Spinner from '../Spinner';

type Props = {
    id: string;
    notes: (Note | null)[] | undefined;
};

const AddToAlbumDropdownItem: React.FC<Props> = ({id, notes}) => {
    const album = useAlbum(id);
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    if (!album) return null;

    const noteToAddIds: string[] = [];

    notes?.forEach((note) => {
        if (note && !note.albums?.some((alb) => alb.id === id)) {
            noteToAddIds.push(note.id);
        }
    });

    return (
        <div className="mu-add-to-album__item">
            {album.label}
            {/* eslint-disable-next-line no-nested-ternary */}
            {noteToAddIds.length ? (
                loading ? (
                    <Spinner className="mu-add-to-album__item-loader" size="16px" />
                ) : (
                    <button
                        type="button"
                        className="mu-add-to-album__item-button"
                        onClick={async () => {
                            setLoading(true);
                            await dispatch(addNotesToAlbum(id, noteToAddIds));
                            setLoading(false);
                        }}
                    >
                        <MuIcon svgName="Plus" />
                    </button>
                )
            ) : (
                <div className="mu-add-to-album__item-button mu-add-to-album__item-button--valid">
                    <MuIcon svgName="Check" />
                </div>
            )}
        </div>
    );
};

export default AddToAlbumDropdownItem;
