import React from 'react';
import {Link} from 'react-router-dom';
import {
    useMe,
} from 'mushin-redux-store';
import {ProfileAvatar} from '../UserCard/ProfileAvatar';

const HeaderMobile: React.FC = () => {
    const me = useMe();

    return (
        <section className="mu-header-mobile">
            <Link className="mu-header-mobile__logo" to="/">
                <img
                    className="mu-header-mobile__logo-img"
                    src="/assets/images/logo.png"
                    alt="Mushin logo"
                />
            </Link>
            {me && (
                <ProfileAvatar userId={me.id} />
            )}
        </section>
    );
};

export default HeaderMobile;
