import React from 'react';
import { EntityMember } from 'mushin-redux-store';

import EntityRow from '../../EntityRow/EntityRow';
import UserRoleSelect, {UserRole} from '../../EntityRow/UserRoleSelect';

type Props = {
    selectedUsers: EntityMember[];
    roles?: boolean | 'read-only';
    noOwner?: boolean;
    setRoles?: (users: EntityMember[]) => void;
    removeUser?: (usrId: string) => void;
};

const ManageMembers: React.FC<Props> = ({
    selectedUsers,
    removeUser,
    roles = 'read-only',
    noOwner = false,
    setRoles,
}) => {
    const roleOptions: UserRole[] = [{
        key: 'admin',
        icon: 'Write',
    }, {
        key: 'regular',
        icon: 'UserLine',
    }];

    if (!noOwner) {
        roleOptions.unshift({
            key: 'owner',
            icon: 'LockLine',
            unique: true,
            fallback: 'admin',
        });
    }

    return (
        <ul className="util-modal-oflow-auto">
            {selectedUsers.map((user, index) => (
                <li key={user.id} className="mu-select-users">
                    <EntityRow
                        id={user.id}
                        role={user.role || 'regular'}
                        removeUser={removeUser && ((usrId) => removeUser(usrId))}
                        useRoles={roles || 'read-only'}
                        rightChildren={(
                            <UserRoleSelect
                                useRoles={roles || 'read-only'}
                                roleOptions={roleOptions}
                                role={user.role || 'regular'}
                                setRole={setRoles && ((role) => {
                                    const members = [...selectedUsers];

                                    if (role?.key === 'owner') {
                                        const lastOwnerIndex = members.findIndex((member) => member.role === 'owner');
                                        members[lastOwnerIndex].role = 'admin';
                                    }
                                    members[index] = {
                                        id: user.id,
                                        role: (role?.key as EntityMember['role']) || 'regular',
                                    };
                                    return setRoles(members);
                                })}
                            />
                        )}
                    />
                </li>
            ))}
        </ul>
    );
};

export default ManageMembers;
