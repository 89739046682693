import React from 'react';
import {Profile, useCurrentProject} from 'mushin-redux-store';
import ProfileCard from '../UserCard/ProfileCard';
import Popover from '../Popover/Popover';
import { useAppDispatch } from '../../Helpers/hooks';
import {openModal} from '../../Redux/reducers/modalsSlice';
import {Position} from '../../Helpers/position';
import UserCriteria from '../UserCriteria/UserCriteria';
import {isProjectContributor} from '../../Helpers/users';

type Props = {
    profile: Profile;
    hoveredElement: React.ReactNode,
    position?: Position,
    inline?: boolean,
}

const ProfileDetailsPopover: React.FC<Props> = ({
    profile, hoveredElement, position, inline = false,
}) => {
    const project = useCurrentProject();
    const dispatch = useAppDispatch();

    if (!project || isProjectContributor(project)) return (<>{hoveredElement}</>);

    const openContributorModal = () => dispatch(openModal('ContributorDetails', {profileId: profile.id}));

    return (
        <Popover
            withDelay
            hoveredElement={(
                <button className="mu-popover-content__trigger" type="button" onClick={openContributorModal}>
                    {hoveredElement}
                </button>
            )}
            position={position}
            inline={inline}
        >
            <ProfileCard id={profile.id} />
            <UserCriteria className="mu-tags--limited" profile={profile} contrast />
        </Popover>

    );
};

export default ProfileDetailsPopover;
