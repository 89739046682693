import React from 'react';
import {Question, Questionnaire} from 'mushin-redux-store';
import i18n from 'i18next';
import {Link} from 'react-router-dom';
import {getStatus} from '../../../../Helpers/date';
import {BadgeComponent} from '../../../../Components/Badge/BadgeComponent';
import MuIcon from '../../../../Components/MuIcon';
import {openLightbox} from '../../../../Redux/reducers/lightboxSlice';
import {useAppDispatch} from '../../../../Helpers/hooks';
import QuillTranslation from '../../../../Components/Translation/QuillTranslation';
import {useSearchParams} from '../../../../Helpers/queryParser';
import {getQuestionnaireBaseUrl} from '../../../../Helpers/questionnaires';
import Badge from '../../../../Components/Badge/Badge';
import Popover from '../../../../Components/Popover/Popover';
import {QUESTION_TYPE_CONFIG} from '../../../QuestionnaireEditor/QuestionEdition';
import CustomSwiper from '../../../../Components/CustomSwiper/CustomSwiper';
import VideoFullscreen from '../../../../Components/VideoFullscreen/VideoFullscreen';

type Props = {
    questionnaire: Questionnaire;
    question: Question;
}

const QuestionHeader: React.FC<Props> = ({
    questionnaire,
    question,
}) => {
    const [searchParams] = useSearchParams({
        criteria: 'array',
        answers: 'array',
    });
    const persistParams = new URLSearchParams();
    if (searchParams.answers) {
        (searchParams.answers as string[]).forEach((it) => persistParams.append('answers', it));
    }
    if (searchParams.criteria) {
        (searchParams.criteria as string[]).forEach((it) => persistParams.append('criteria', it));
    }
    const questionList = questionnaire.questions.filter((it) => it.type !== 'description');
    const index = questionList.findIndex((q) => q.id === question.id);
    const baseUrl = getQuestionnaireBaseUrl(questionnaire, questionnaire.project_id);
    const currentUrl = `${baseUrl}/questions/${question.id}/answers?${persistParams.toString()}`;
    const nextUrl = `${baseUrl}/questions/${questionList[index + 1]?.id}/answers?${persistParams.toString()}`;
    const prevUrl = `${baseUrl}/questions/${questionList[index - 1]?.id}/answers?${persistParams.toString()}`;

    const dispatch = useAppDispatch();

    return (
        <div className="mu-questionnaire-result__header">
            <div className="mu-questionnaire-result__question-switch">
                <Link
                    className="mu-questionnaire-result__question-switch-button"
                    to={index > 0 ? prevUrl : currentUrl}
                >
                    <MuIcon svgName="ArrowLeft" />
                </Link>
                <div className="mu-questionnaire-result__question-switch-text">
                    {i18n.t('questionnaires.question')}
                    <span className="mu-questionnaire-result__question-switch-count">
                        {` ${index + 1} / ${questionList.length}`}
                    </span>
                </div>
                <Link
                    className="mu-questionnaire-result__question-switch-button"
                    to={
                        index < questionList.length - 1 ? nextUrl : currentUrl
                    }
                >
                    <MuIcon svgName="ArrowRight" />
                </Link>
            </div>
            <div className="mu-questionnaire-result__header-badges">
                <Badge
                    classModifiers={['warning', 'simple']}
                >
                    <MuIcon svgName={QUESTION_TYPE_CONFIG[question.type]?.icon} />
                    {i18n.t(QUESTION_TYPE_CONFIG[question.type]?.i18nKey)}
                </Badge>
                {question.confidential && (
                    <Badge
                        classModifiers={['simple']}
                    >
                        <MuIcon svgName="Hide" />
                        {i18n.t('questionnaires.confidential')}
                    </Badge>
                )}
                <BadgeComponent status={getStatus(questionnaire.start_date, questionnaire.end_date, undefined)} />
                <Badge
                    classModifiers={['info', 'simple']}
                >
                    {i18n.t(`questionnaires.accessType.${questionnaire.accessType}`)}
                </Badge>
                {questionnaire.accessType === 'public' && (
                    <button
                        type="button"
                        onClick={() => (
                            navigator.clipboard.writeText(
                                `${window.location.origin}/questionnaires/${questionnaire.id}`
                            )
                        )}
                    >
                        <Popover
                            position="bottom"
                            hoveredElement={(
                                <Badge
                                    classModifiers={['info', 'simple']}
                                >
                                    {'\uD83D\uDD17'}
                                </Badge>
                            )}
                        >
                            {`${window.location.origin}/questionnaires/${questionnaire.id}`}
                        </Popover>
                    </button>
                )}
                <Badge
                    classModifiers={['info', 'simple']}
                >
                    <MuIcon svgName="Group" />
                    {i18n.t('global.participant', {count: questionnaire.respondent_count})}
                </Badge>
            </div>
            {!!question.cover_urls?.length && (
                <CustomSwiper className="mu-questionnaire-result__images">
                    {question.cover_urls.map((cover, coverIndex) => (cover.type === 'video' ? (
                        <VideoFullscreen
                            key={cover.url}
                            videoUrl={cover.url}
                            controls
                            className="mu-question__video"
                        />
                    ) : (
                        <img
                            key={cover.url}
                            src={cover.url}
                            alt="Question cover"
                            className="mu-question__image"
                            onClick={() => dispatch(openLightbox({
                                urls: question.cover_urls?.map((c) => c.url) as string[],
                                index: coverIndex,
                            }))}
                        />
                    )))}
                </CustomSwiper>
            )}
            <QuillTranslation
                translationProp={question.labels}
                fallback={question.label}
                className="mu-rich-input"
                theme=""
            />
        </div>
    );
};

export default QuestionHeader;
