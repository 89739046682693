import React from 'react';
import {classWithModifiers} from '../../Helpers/css';
import MuIcon from '../MuIcon';

type Props = React.PropsWithChildren<{
    activeMode: 'Cluster' | 'Insights' | 'Opportunities' | 'Solutions';
    title?: string;
    description?: string;
}>;

const PanelSynthesis: React.FC<Props> = ({
    activeMode,
    title,
    description,
    children,
}: Props) => {
    return (
        <article className={classWithModifiers('mu-panel-synthesis', activeMode)}>
            <div className="mu-panel-synthesis__header">
                <div className="mu-panel-synthesis__header--header-container">
                    {activeMode && (
                        <MuIcon className="mu-action-card-button__icon" svgName={activeMode} />
                    )}
                    <h2 className="mu-panel-synthesis__header-title">{title || activeMode}</h2>
                </div>
            </div>
            <div className="mu-panel-synthesis__content">
                <p className="mu-panel-synthesis__content-text">{description}</p>
            </div>
            {children}
        </article>
    );
};

export default PanelSynthesis;
