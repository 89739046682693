import React from 'react';
import i18n from 'i18next';
import MuIcon from '../../MuIcon';
import {useAppDispatch} from '../../../Helpers/hooks';
import {addShapeInNewCell, addTextInNewCell} from '../../../Redux/reducers/moodboardEditorSlice';

const ElementsInsertion: React.FC = () => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="beTextEditor-label">{i18n.t('components.boardEditor.addText')}</div>
            <div className="beTextEditor__button-group">
                <button
                    type="button"
                    className="beTextEditor__button beTextEditor__button-group--addTitle"
                    onClick={() => dispatch(addTextInNewCell({ size: '10', style: 'bold' }))}
                >
                    {i18n.t('components.textEditor.addTitleText')}
                </button>
                <button
                    type="button"
                    className="beTextEditor__button beTextEditor__button-group--addSubtitle"
                    onClick={() => dispatch(addTextInNewCell({ size: '6', style: 'bold' }))}
                >
                    {i18n.t('components.textEditor.addSubtitleText')}
                </button>
                <button
                    type="button"
                    className="beTextEditor__button beTextEditor__button-group--addStandard"
                    onClick={() => dispatch(addTextInNewCell({ size: '6', style: 'normal' }))}
                >
                    {i18n.t('components.textEditor.addStandardText')}
                </button>
            </div>
            <div className="beTextEditor-label">{i18n.t('components.boardEditor.addShape')}</div>
            <div className="beTextEditor__button-group">
                <button
                    type="button"
                    className="beTextEditor__form"
                    onClick={() => dispatch(addShapeInNewCell('circle'))}
                >
                    <MuIcon className="beTextEditor__form-icon" svgName="Circle" />
                    {i18n.t('components.formsEditor.circle')}
                </button>
                <button
                    type="button"
                    className="beTextEditor__form"
                    onClick={() => dispatch(addShapeInNewCell('rect'))}
                >
                    <MuIcon className="beTextEditor__form-icon" svgName="Square" />
                    {i18n.t('components.formsEditor.rect')}
                </button>
                <button
                    type="button"
                    className="beTextEditor__form"
                    onClick={() => dispatch(addShapeInNewCell('arrow'))}
                >
                    <MuIcon className="beTextEditor__form-icon" svgName="LongArrowRight" />
                    {i18n.t('components.formsEditor.arrow')}

                </button>
            </div>
        </>
    );
};

export default ElementsInsertion;
