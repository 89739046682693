import React, {useState} from 'react';
import i18n from 'i18next';
import CheckboxRadio from '../../../../Components/Form/CheckboxRadio';
import InputText from '../../../../Components/InputText/InputText';
import {CriteriaImporter, CriterionImport} from '../../../../Helpers/excel/criteriaImport';
import MuIcon from '../../../../Components/MuIcon';
import Popover from '../../../../Components/Popover/Popover';

type Props = {
    criteriaImporter: CriteriaImporter;
    index: number;
    item: CriterionImport;
};

const CriteriaImportModalItem: React.FC<Props> = (
    {
        criteriaImporter,
        index,
        item,
    }
) => {
    const [ignore, setIgnore] = useState(!!item.ignore);
    const [name, setName] = useState(item.name);
    return (
        <div className="mu-form-row">
            <CheckboxRadio
                setChecked={(checked) => {
                    setIgnore(!checked);
                    criteriaImporter.setColumnIgnore(index, !checked);
                }}
                checked={!ignore}
            />
            <InputText
                classModifiers={['full-width']}
                onChange={(e) => {
                    setName(e.currentTarget.value);
                    criteriaImporter.setColumnName(index, e.currentTarget.value);
                }}
                value={name}
                placeholder={item.name}
                contrast
            />
            <Popover hoveredElement={<MuIcon className="mu-form-row__icon" svgName="Show" />} shadow>
                <h4>{i18n.t('criteria.valuesCount', {count: item.values.length })}</h4>
                {item.values.map((value) => {
                    const label = typeof value === 'string' ? value : value.key;
                    return (<div key={label}>{label}</div>);
                })}
            </Popover>
        </div>
    );
};

export default CriteriaImportModalItem;
