import React, {useState} from 'react';
import Delta from 'quill-delta';
import {useAlbum} from 'mushin-redux-store';
import i18n from 'i18next';
import QuillRichInput from '../../../../Components/QuillRichInput/QuillRichInput';
import {useAppDispatch} from '../../../../Helpers/hooks';
import { TemplatePicker } from '../../../../Components/BoardComponents/TemplatePicker';
import MuButton from '../../../../Components/MuButton';
import MuIcon from '../../../../Components/MuIcon';
import TextIcon from '../../../../Components/TextIcon/TextIcon';
import SwitchBtn from '../../../../Components/SwitchBtn';
import {openModal} from '../../../../Redux/reducers/modalsSlice';

type Props = {
    title: string;
    setTitle: (title: string) => void;
    noteDesc: Delta;
    setNoteDesc: React.Dispatch<React.SetStateAction<Delta>>;
    noteAlbumId: string | undefined;
    setNoteAlbumId: React.Dispatch<React.SetStateAction<string | undefined>>;
    templateNoteCount: number;
    templateId: number;
    setTemplateId: (id: number, applyToAll: boolean) => void;
}

const CreateMoodboardNoteInfo: React.FC<Props> = (
    {
        title,
        setTitle,
        noteDesc,
        setNoteDesc,
        noteAlbumId,
        setNoteAlbumId,
        templateNoteCount,
        templateId,
        setTemplateId,
    }
) => {
    const [applyTemplateToAll, setApplyTemplateToAll] = useState(false);
    const album = useAlbum(noteAlbumId);

    const dispatch = useAppDispatch();

    const handleOpenSelectGalleryModal = () => (
        dispatch(openModal('SelectAlbumToCreateNote', {
            handleConfirm: () => setNoteAlbumId(undefined),
        }))
    );

    return (
        <div className="noteEditor">
            <div className="noteEditor-title">
                <div className="label-container">
                    <input
                        type="text"
                        autoComplete="false"
                        value={title}
                        onChange={(evt) => { setTitle(evt.target.value); }}
                        placeholder={i18n.t<string>('models.notes.label')}
                    />
                </div>
            </div>
            <div className="noteEditor-description">
                <QuillRichInput
                    value={noteDesc}
                    setValue={(delta) => setNoteDesc(delta)}
                    placeholder={i18n.t<string>('global.addDescription')}
                    theme="snow"
                    className="mu-textarea-rich-input"
                    toolbar={[
                        ['bold', 'italic', 'strike', 'underline'], // toggled buttons
                        [{ list: 'bullet' }],
                    ]}
                />
            </div>
            <div className="noteEditor__choose-gallery">
                <button
                    type="button"
                    className="noteEditor__selected-folder-text"
                    onClick={() => handleOpenSelectGalleryModal()}
                >
                    {!noteAlbumId && (
                        <MuButton
                            icon="Cloud"
                            classModifiers={['outline']}
                            label={i18n.t('components.selectNoteDestination.selectDestination')}
                        />
                    )}
                    {noteAlbumId && (
                        <TextIcon icon="Image" label={`Album "${album?.label}"`} />
                    )}
                </button>
                {noteAlbumId && (
                    <button
                        className="noteEditor__remove-album"
                        type="button"
                        onClick={() => setNoteAlbumId(undefined)}
                    >
                        <MuIcon svgName="Close" />
                    </button>
                )}
            </div>
            {!noteAlbumId && (
                <TextIcon icon="Image" label={i18n.t('components.noteform.defaultCreate')} />
            )}
            <div className="noteEditor-templatePanel">
                <h3 className="noteEditor-sectionLabel">
                    {i18n.t('components.boardEditor.template')}
                </h3>
                <div className="noteEditor-applyToAll">
                    <SwitchBtn
                        id="createMoodboardApplyToAllCheck"
                        label={i18n.t<string>('components.boardEditor.applyToAll')}
                        isChecked={applyTemplateToAll}
                        handleChange={() => setApplyTemplateToAll((prevValue) => !prevValue)}
                    />
                </div>
                <TemplatePicker
                    noteCount={templateNoteCount}
                    selectedTemplateId={templateId}
                    setTemplate={(template, id) => setTemplateId(id, applyTemplateToAll)}
                />
            </div>
        </div>
    );
};

export default CreateMoodboardNoteInfo;
