import React from 'react';
import {classWithModifiers} from '../../Helpers/css';

type Props = React.PropsWithChildren<{
    noScroll?: boolean;
    standalone?: boolean;
    className?: string;
}>;
export const ModalBody: React.FC<Props> = (
    {
        children,
        noScroll = false,
        standalone = false,
        className,
    }
) => (
    <article className={classWithModifiers('mu-modal__body', {'no-scroll': noScroll, standalone}, className)}>
        {children}
    </article>
);
