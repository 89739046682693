import React, {useState} from 'react';
import i18n from 'i18next';
import {useCurrentProjectId} from 'mushin-redux-store';
import { ModalHeader } from '../../../../Components/Modal/ModalHeader';
import Modal from '../../../../Components/Modal';
import AlbumsSelector from '../../../../Components/AlbumsSelector/AlbumsSelector';
import MuButton from '../../../../Components/MuButton';
import { ModalFooter } from '../../../../Components/Modal/ModalFooter';
import {ModalBody} from '../../../../Components/Modal/ModalBody';

type Props = {
    onClose: () => void;
    handleConfirm: (albumsIds: string[]) => void;
};

const AlbumsSelectorModal: React.FC<Props> = ({
    onClose,
    handleConfirm,
}) => {
    const [currentProjectId] = useCurrentProjectId();
    const [selectedAlbumId, setSelectedAlbumId] = useState<string | null>(null);
    return (
        <Modal handleRemoveModal={onClose}>
            <ModalHeader title={i18n.t('modals.albumsSelector.title')} icon="Image" />
            <ModalBody>
                <AlbumsSelector
                    onSelect={(type, id) => setSelectedAlbumId(type === 'album' && id ? id : null)}
                    initialValue={currentProjectId}
                    onlyAlbums
                    canCreateAlbum
                />
            </ModalBody>
            <ModalFooter>
                <section className="mu-modal-footer__buttons">
                    <MuButton
                        label={i18n.t('global.cancel')}
                        handleClick={onClose}
                        classModifiers={['large', 'default']}
                    />
                    <MuButton
                        disabled={!selectedAlbumId}
                        label={i18n.t('global.confirm')}
                        handleClick={() => {
                            handleConfirm(selectedAlbumId ? [selectedAlbumId] : []);
                            onClose();
                        }}
                        classModifiers={['large']}
                    />
                </section>
            </ModalFooter>
        </Modal>

    );
};

export default AlbumsSelectorModal;
