import React from 'react';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import MuIcon from '../MuIcon';
import {classWithModifiers} from '../../Helpers/css';
import Spinner from '../Spinner';

export type ActionBarItem = {
    icon: string;
    label: React.ReactNode;
    handler: () => void;
    disabled?: boolean;
    hidden?: boolean;
    progress?: boolean | number;
}

type Props = React.PropsWithChildren<{
    items: ActionBarItem[];
}>

const ActionBar: React.FC<Props> = ({ items, children }) => {
    const renderProgress = (action: ActionBarItem) => {
        if (action.progress === true) return <Spinner className="mu-action-bar__item-progress" size="1.8rem" />;
        if (typeof action.progress === 'number' && action.progress > -1) {
            return (
                <CircularProgressbar
                    className="mu-action-bar__item-progress"
                    value={action.progress}
                    maxValue={100}
                    strokeWidth={6}
                    background
                    styles={buildStyles({
                        // Rotation of path and trail, in number of turns (0-1)
                        rotation: 0.3,

                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,

                        // Can specify path transition in more detail, or remove it entirely
                        // pathTransition: 'none',

                        // Colors
                        pathColor: '#fe6f63',
                        trailColor: '#ffffff',
                        backgroundColor: 'transparent',
                    })}
                />
            );
        }

        return null;
    };

    return (
        <div className="mu-action-bar">
            {children}
            {items.map((action) => {
                if (action.hidden) return null;

                const disabled = (
                    !!action.disabled
                    || action.progress === true
                    || (typeof action.progress === 'number' && action.progress > -1)
                );

                return (
                    <button
                        key={action.icon}
                        type="button"
                        disabled={disabled}
                        className={classWithModifiers('mu-action-bar__item', {disabled})}
                        onClick={action.handler}
                    >
                        <div className="mu-action-bar__item-label mu-popover__panel">{action.label}</div>
                        <MuIcon className="mu-action-bar__item-icon" svgName={action.icon} />
                        {renderProgress(action)}
                    </button>
                );
            })}
        </div>
    );
};

export default ActionBar;
