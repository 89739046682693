import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from './index';

export const initialState = {
    draggedNoteId: null as string | null,
    noteIds: [] as string[],
    multipleOrigin: false,
};

const noteBucketSlice = createSlice({
    name: 'noteBucket',
    initialState,
    reducers: {
        setNotesMultipleOrigin(state, action: PayloadAction<boolean>) {
            state.multipleOrigin = action.payload;
        },
        empty(state) {
            state.noteIds = [];
        },

        deselectNote(state, action: PayloadAction<string>) {
            state.noteIds = state.noteIds.filter((id) => id !== action.payload);
        },

        selectNotes(state, action: PayloadAction<string[]>) {
            action.payload.forEach((noteId) => {
                if (!state.noteIds.includes(noteId)) state.noteIds.push(noteId);
            });
        },
        setDraggedNote(state, action: PayloadAction<string>) {
            state.draggedNoteId = action.payload;
        },

        deleteDraggedNote(state) {
            state.draggedNoteId = null;
        },

        moveNote: (state, action: PayloadAction<{draggedIndex: number, dropIndex: number}>) => {
            const {
                draggedIndex,
                dropIndex,
            } = action.payload;

            // Ids without the dragged note
            const newList = [...state.noteIds];
            const draggedId = newList[draggedIndex];
            newList.splice(draggedIndex, 1);
            newList.splice(dropIndex, 0, draggedId);
            state.noteIds = newList;
        },
    },
    extraReducers: {
        'notes/deleteItem': (state, action: PayloadAction<string>) => {
            state.noteIds = state.noteIds.filter((id) => id !== action.payload);
        },
    },
});

export const {
    setNotesMultipleOrigin,
    empty: emptyNoteBucket,
    deselectNote: deselectNoteInBucket,
    selectNotes: selectNotesInBucket,
    moveNote,
} = noteBucketSlice.actions;

export const moveNoteInBucket = (draggedIndex: number, dropIndex: number): AppThunk => (dispatch) => dispatch(moveNote({
    draggedIndex, dropIndex,
}));

export default noteBucketSlice.reducer;
