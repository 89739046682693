/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import MuIcon from '../../../Components/MuIcon';
import { classWithModifiers } from '../../../Helpers/css';
import VideoFullscreen from '../../../Components/VideoFullscreen/VideoFullscreen';

type Props = {
    videoUrl: string;
    updateQuestion: () => void;
    className?: string;
};

const EditQuestionVideo: React.FC<Props> = ({
    updateQuestion,
    videoUrl,
    className,
}) => {
    return (
        <div className={classWithModifiers('mu-edit-question-file', [], className)}>
            <button
                type="button"
                onClick={updateQuestion}
                className="mu-edit-question-file__file-remove"
            >
                <MuIcon
                    svgName="CloseCircleFill"
                />
            </button>
            <VideoFullscreen videoUrl={videoUrl} className="mu-edit-question-file__video" />
        </div>
    );
};

export default EditQuestionVideo;
