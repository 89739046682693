import {Dispatch, SetStateAction, useCallback, useState} from 'react';
import i18n from 'i18next';
import {Validator} from './validation';

export type UseFieldState<T> = {
    value: T,
    setValue: Dispatch<SetStateAction<T>>,
    status: ReturnType<Validator<T>>,
    setValidator: (validator: Validator<T>) => void,
    validate: () => boolean,
    resetStatus: () => void,
}

const initialStatus = { valid: true, message: null };

export const useFieldState = <T>(initialValue: T): UseFieldState<T> => {
    const [value, setValue] = useState(initialValue);
    const [validator, _setValidator] = useState<Validator<T> | null>(null);
    const [status, setStatus] = useState<ReturnType<Validator<T>>>(initialStatus);

    const validate = useCallback((): boolean => {
        if (validator) {
            const _status = validator(value);
            setStatus(_status);
            return _status.valid;
        }
        return true;
    }, [validator, value]);

    const setValidator = useCallback((_validator: Validator<T>) => {
        _setValidator(() => _validator);
    }, []);

    const resetStatus = useCallback(() => setStatus(initialStatus), []);

    return {
        value,
        setValue,
        status,
        setValidator,
        validate,
        resetStatus,
    };
};

export const withOptionalMention = <T>(text: T | undefined, required: boolean): T | string | undefined => (
    text && !required ? `${text} (${i18n.t('questionnaires.optional')})` : text
);
