import React from 'react';
import {updateMe, useMe} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import { ModalBody } from '../../../../Components/Modal/ModalBody';
import { ModalHeader } from '../../../../Components/Modal/ModalHeader';
import MuButton from '../../../../Components/MuButton';
import { ModalFooter } from '../../../../Components/Modal/ModalFooter';
import {useAppDispatch} from '../../../../Helpers/hooks';

type Props = {
    onClose: () => void;
    name: string;
};

const TutorialModal: React.FC<Props> = (
    {
        onClose,
        name,
    }
) => {
    const me = useMe();
    const dispatch = useAppDispatch();

    return (
        <Modal handleRemoveModal={onClose}>
            <ModalHeader
                title={i18n.t(`tutorial.${name}.title`)}
                icon="Help"
            />
            <ModalBody>
                {i18n.t(`tutorial.${name}.description`)}
            </ModalBody>
            <ModalFooter>
                <MuButton
                    handleClick={onClose}
                    label={i18n.t('global.close')}
                    classModifiers={['large', 'default']}
                />
                <MuButton
                    handleClick={async () => {
                        if (me) {
                            await dispatch(updateMe({
                                tutorial: {
                                    ...me.tutorial,
                                    [name]: true,
                                },
                            }));
                        }
                        onClose();
                    }}
                    label={i18n.t('tutorial.understood')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default TutorialModal;
