import React, {useRef, useState} from 'react';
import { classWithModifiers } from '../../Helpers/css';

type Props = {
    defaultValue: string;
    contentEditable?: boolean;
    className?: string;
    onBlur?: (e: React.FocusEvent<HTMLHeadingElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLHeadingElement>) => void;
    onInput?: (e: React.FormEvent<HTMLHeadingElement>) => void;
    level?: number;
    placeholder?: string;
};

const EditableTitle: React.FC<Props> = (
    {
        defaultValue,
        contentEditable,
        className,
        onFocus,
        onBlur,
        onInput,
        level = 2,
        placeholder,
    }
) => {
    const [isEditing, setEditing] = useState(false);
    const [isEmpty, setEmpty] = useState(!defaultValue);
    const ref = useRef<HTMLHeadingElement>(null);

    if (isEmpty !== !defaultValue) setEmpty(!defaultValue);

    const Heading = `h${level}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

    return (
        <Heading
            ref={ref}
            className={
                classWithModifiers('mu-editable-title', [{ 'is-editing': isEditing, empty: isEmpty }], className)
            }
            data-placeholder={placeholder}
            contentEditable={contentEditable}
            suppressContentEditableWarning
            onBlur={(e: React.FocusEvent<HTMLHeadingElement>) => {
                if (onBlur) onBlur(e);
                setEditing(false);
            }}
            onFocus={(e) => {
                setEditing(true);
                onFocus && onFocus(e);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLHeadingElement>) => {
                e.stopPropagation();
                if (e.key === 'Enter' && ref.current) {
                    ref.current.blur();
                }
            }}
            onInput={(e) => {
                if (!e.currentTarget.textContent) {
                    setEmpty(true);
                } else {
                    setEmpty(false);
                }
                onInput && onInput(e);
            }}
        >
            {defaultValue}
        </Heading>
    );
};

export default EditableTitle;
