import React, {useState} from 'react';
import {Reactions, ReactionsKeys} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import { ModalBody } from '../../../../Components/Modal/ModalBody';
import { ModalHeader } from '../../../../Components/Modal/ModalHeader';
import MuButton from '../../../../Components/MuButton';
import { ModalFooter } from '../../../../Components/Modal/ModalFooter';
import MuIcon from '../../../../Components/MuIcon';
import {classWithModifiers} from '../../../../Helpers/css';
import EntityRow from '../../../../Components/EntityRow/EntityRow';

const icons = {
    all: 'ThumbsUp',
    like: 'ThumbsUp',
    love: 'Heart',
    instructive: 'Bulb',
    favorite: 'Star',
};

type Props = {
    onClose: () => void;
    name: 'favorite' | 'all' | ReactionsKeys;
    reactions: Reactions | undefined;
    favorites: string[] | undefined;
};

const ReactionsModal: React.FC<Props> = ({
    onClose,
    name,
    reactions,
    favorites,
}) => {
    const [key] = useState(name); // TODO add way to switch between different reaction types

    const renderUserReaction = (userId: string, _key: 'favorite' | ReactionsKeys) => {
        return (
            <EntityRow
                key={userId}
                id={userId}
                rightChildren={(
                    <div className={classWithModifiers('mu-entity-row__react', [_key])}>
                        <MuIcon svgName={icons[_key]} />
                    </div>
                )}
            />
        );
    };

    return (
        <Modal handleRemoveModal={onClose}>
            <ModalHeader
                title={i18n.t('modals.reactions.title')}
                icon={icons[key]}
            />
            <ModalBody>
                <div className="mu-reactions-list">
                    {key === 'all' ? (
                        reactions?.all?.map(({user_id, type}) => (
                            renderUserReaction(user_id, type)
                        ))
                    ) : (
                        (key === 'favorite' ? favorites : reactions?.[key])?.map((userId) => (
                            renderUserReaction(userId, key)
                        ))
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <MuButton
                    handleClick={onClose}
                    label={i18n.t('global.close')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default ReactionsModal;
