import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import { Provider as ReactReduxProvider } from 'react-redux';
import {useTranslation} from 'react-i18next';
import {checkToken, useAuthState} from 'mushin-redux-store';
import moment from 'moment';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {createRoot} from 'react-dom/client';
import { store } from './Redux/store';

import Sentry from './Services/Config/sentry';
import GoogleAnalytics from './Services/Config/ga';

import AppRoutes from './Router/AppRoutes';
import AppModals from './Scenes/AppModals/AppModals';
import AppNotifs from './Scenes/AppNotifs/AppNotifs';

import './i18n'; // import i18n (needs to be bundled ;))
import {setDefaultDatepickerLocale} from '../assets/locales/datepicker';
import {useAppDispatch} from './Helpers/hooks';
import MaintenanceFallback from './Scenes/Sessions/MaintenanceFallback/MaintenanceFallback';
import AppLoader from './Router/AppLoader';
import IntercomConfig from './Services/Config/intercom';
import AppFullscreenCarousel from './Scenes/AppLightbox/AppFullscreenCarousel';

const App: React.FC = () => {
    const { isLoading, user, isConnected, maintenance } = useAuthState();
    const { i18n } = useTranslation();

    const dispatch = useAppDispatch();

    useEffect(() => {
        Sentry.setUserContext(user || null);
        if (user) {
            IntercomConfig.update();
        } else {
            IntercomConfig.init();
        }
    }, [user]);

    useEffect(() => {
        if (user?.email) {
            GoogleAnalytics.setUserId(user.email);
        }
    }, [user?.email]);

    useEffect(() => {
        if (user?.locale) {
            i18n.changeLanguage(user.locale).then();
            moment.locale(user.locale);
            setDefaultDatepickerLocale(user.locale);
        }
    }, [i18n, user?.locale]);

    useEffect(() => {
        dispatch(checkToken()).then();
    }, [dispatch]);

    if (maintenance) {
        return <MaintenanceFallback />;
    }

    if (isLoading || (isConnected && !user)) {
        return (
            <AppLoader />
        );
    }

    return (
        <div id="mushin-app">
            <BrowserRouter>
                <AppRoutes />
                <AppModals />
                <AppNotifs />
                <AppFullscreenCarousel />
            </BrowserRouter>
        </div>
    );
};

const AppDom = (
    <ReactReduxProvider store={store}>
        <DndProvider backend={HTML5Backend}>
            <App />
        </DndProvider>
    </ReactReduxProvider>
);

Sentry.init();
IntercomConfig.init();
GoogleAnalytics.init();

const root = createRoot(document.getElementById('app') as HTMLElement);
root.render(AppDom);

export default App;
