import React, {ReactNode} from 'react';
import { classWithModifiers, Modifiers } from '../../Helpers/css';
import MuIcon from '../MuIcon';

type Props = {
  icon: string;
  label: ReactNode | string;
  subLabel?: string;
  classModifiers?: Modifiers;
  className?: string;
};

const TextIcon: React.FC<Props> = ({
    icon,
    label,
    subLabel,
    className,
    classModifiers = [],
}) => (
    <span className={classWithModifiers('mu-text-icon', classModifiers, className)}>
        <span className="mu-text-icon__icon">
            <MuIcon svgName={icon} />
        </span>
        <span className="mu-text-icon__label">
            {label}
            {subLabel && (
                <span className="mu-text-icon__sub-label">
                    <br />
                    {subLabel}
                </span>
            )}
        </span>
    </span>
);

export default TextIcon;
