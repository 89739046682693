import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';

import Login from '../Scenes/Sessions/Login/Login';
import Mushin from '../Scenes/Mushin/Mushin';
import ForgotPassword from '../Scenes/Sessions/ForgotPassword/index';
import PrivateRoute from './PrivateRoute';
import GlobalErrors from '../Scenes/Sessions/Components/GlobalError/GlobalError';
import ConfirmAccount from '../Scenes/Sessions/ConfirmAccount/ConfirmAccount';
import CguPage from '../Scenes/Sessions/CguPage/CguPage';
import Landing from '../Scenes/Sessions/Landing/Landing';
import Register from '../Scenes/Sessions/Register/Register';
import QuestionnaireAnswerPublic from '../Scenes/QuestionnaireAnswer/QuestionnaireAnswerPublic';
import QuestionnaireAnswerPublicSuccess from '../Scenes/QuestionnaireAnswer/QuestionnaireAnswerPublicSuccess';
import {useAppSelector} from '../Helpers/hooks';
import AccountDeletionHelp from '../Scenes/Sessions/AccountDeletionHelp/AccountDeletionHelp';
import ForgotPasswordEdit from '../Scenes/Sessions/ForgotPassword/ForgotPasswordEdit';
import GdprPage from '../Scenes/Sessions/GdprPage/GdprPage';
import {showIntercom} from '../Services/Config/intercom';
import SsoLogin from '../Scenes/Sessions/SsoLogin/SsoLogin';

const AppRoutes: React.FC = () => {
    const isConnected = useAppSelector((state) => state.auth.isConnected);

    const { hash } = useLocation();

    useEffect(() => {
        if (hash === '#intercom') {
            showIntercom();
        }
    }, [hash]);

    return (
        <Routes>
            {!isConnected && (
                <Route path="/" element={<Navigate to="/welcome" replace />} />
            )}
            <Route path="/welcome" Component={Landing} />
            <Route path="/register" Component={Register} />
            <Route path="/login" Component={Login} />
            <Route path="/login/sso" Component={SsoLogin} />
            <Route path="/confirm_account" Component={ConfirmAccount} />
            <Route path="/forgot_password/edit" Component={ForgotPasswordEdit} />
            <Route path="/forgot_password" Component={ForgotPassword} />
            <Route path="/cgu" Component={CguPage} />
            <Route path="/gdpr" Component={GdprPage} />
            <Route path="/help/deleteAccount" Component={AccountDeletionHelp} />
            <Route path="/questionnaires/:questionnaireType/success" Component={QuestionnaireAnswerPublicSuccess} />
            <Route path="/questionnaires/:questionnaireId" Component={QuestionnaireAnswerPublic} />
            <Route path="/403" element={<PrivateRoute><GlobalErrors /></PrivateRoute>} />
            <Route path="*" Component={Mushin} />
        </Routes>
    );
};

export default AppRoutes;
