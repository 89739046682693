import React, { useMemo } from 'react';
import { Questionnaire, useQuestionnaire } from 'mushin-redux-store';
import { useParams } from 'react-router-dom';
import EntitySelectMultipleValue from '../../../EntitySelect/Components/EntitySelectMultipleValue';
import { deltaToText } from '../../../../Helpers/delta';

type Props = {
  data: string[];
};

const QuestionAnswersValue: React.FC<Props> = ({ data = [] }) => {
    const { questionnaireId } = useParams<any>();
    const questionnaire = useQuestionnaire(questionnaireId);

    const parsedData = useMemo(() => {
        return data.map((it) => {
            try {
                const { id, value } = JSON.parse(it) || {};
                return { id, value };
            } catch {
                return null;
            }
        }).filter(Boolean);
    }, [data]);

    const ids = parsedData.map((it) => it?.id);

    if (!questionnaire) return null;

    const getQuestionAnswerLabel = (
        question: Questionnaire['questions'][0],
        value: string
    ) => {
        if (question.type === 'checkboxes' || question.type === 'radios') {
            return question.items.find((it) => it.slug === value)?.label;
        }
        return value;
    };

    return (
        <EntitySelectMultipleValue
            value={ids}
            getEntityName={(question, index) => (question
                ? `${deltaToText(question.label)} - ${getQuestionAnswerLabel(
                    question,
                    parsedData[index]?.value
                )}`
                : '')}
            useItems={() => ids.map((id) => questionnaire.questions.find((q) => q.id === id))}
        />
    );
};

export default QuestionAnswersValue;
