import React from 'react';
import {
    addHashtagToAllUploads,
    removeHashtagFromAllUploads,
    useUploads,
    useUploadsAllHashtags
} from 'mushin-redux-store';
import i18n from 'i18next';
import Tags from '../../../../Components/Tags/Tags';
import {UploadingRow} from '../UploadingRow/UploadingRow';
import {useAppDispatch} from '../../../../Helpers/hooks';

export const TransferManager: React.FC = () => {
    const uploads = useUploads();
    const allHashtags = useUploadsAllHashtags();
    const dispatch = useAppDispatch();

    return (
        <div className="transfer-manager">
            <div className="transfer-manager__main-actions">
                <Tags
                    id="ApplyToAllTags"
                    placeholder={i18n.t<string>('notes.add_tag_to_all')}
                    classModifiers={['small']}
                    tags={allHashtags}
                    selectedTags={allHashtags}
                    handleRemoveTag={(tag) => {
                        dispatch(removeHashtagFromAllUploads(tag));
                    }}
                    handleAddTag={(tag) => {
                        dispatch(addHashtagToAllUploads(tag));
                    }}
                />
            </div>
            <hr />
            <section className="transfer-manager__files">
                {uploads.map((uploadId) => <UploadingRow id={uploadId} key={uploadId} />)}
            </section>
        </div>
    );
};

export default TransferManager;
