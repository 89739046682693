import { split } from 'mushin-node-moodboard/lib/common/mixedContent';
import { computeBox } from 'mushin-node-moodboard/lib/common/cropUtils';

import { Handler } from './type';

import { setContent } from '../layoutUtils';

export const onTouchDown: Handler = ({
    state,
    board,
    selectedCell,
}, pointers) => {
    if (state.dragging) return;

    if (!pointers[0]) return;

    const content = selectedCell && board.contents[selectedCell];

    if (!content || !content.image_url) return;

    return {
        state: {
            ...state,
            dragging: 'crop',
            drag: {
                originPoint: pointers[0],
                originCrop: content.image_crop,
            },
        },
    };
};

export const onTouchMove: Handler = ({
    state,
    board,
    selectedCell,
}, pointers) => {
    if (state.dragging !== 'crop') return;

    if (!pointers[0]) return;

    const dx = pointers[0].x - state.drag.originPoint.x;
    const dy = pointers[0].y - state.drag.originPoint.y;

    const {
        originCrop,
        cellIndex,
    } = state.drag;

    const content = selectedCell && board.contents[selectedCell];

    if (!content) return;

    const cell = content.type === 'mixed' ? split(board.cells[selectedCell], content)[0].cell : board.cells[selectedCell];

    let imageDimension = state.drawer.cache.getDimensions(content.image_url);

    if (!imageDimension) return;

    const containerDimension = {
        x: cell.max.x - cell.min.x,
        y: cell.max.y - cell.min.y,
    };

    // reflect image rotation
    if (content.image_rotation === 90 || content.image_rotation === 270) {
        imageDimension = {
            x: imageDimension.y,
            y: imageDimension.x,
        };
    }

    const cropBox = computeBox(content.image_crop, imageDimension, containerDimension);

    // cropBox dimension
    const sx = cropBox.max.x - cropBox.min.x;
    const sy = cropBox.max.y - cropBox.min.y;

    // margin, to constraint into a domain where it actually change the crop
    const mx = (1 - (sx - containerDimension.x) / sx) / 2;
    const my = (1 - (sy - containerDimension.y) / sy) / 2;
    // delta focusPoint
    const lx = dx / sx;
    const ly = dy / sy;
    const newContent = {
        ...content,
        image_crop: {
            ...content.image_crop,
            focusPoint: {
                x: Math.min(1 - mx, Math.max(mx, originCrop.focusPoint.x - lx)),
                y: Math.min(1 - my, Math.max(my, originCrop.focusPoint.y - ly)),
            },
        },
    };
    return { board: setContent(board, selectedCell, newContent) };
};

export const onTouchUp: Handler = ({
    state,
}) => {
    if (state.dragging !== 'crop') return;

    return {
        state: {
            ...state,
            dragging: null,
        },
    };
};
