import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import React from 'react';
import UniqueIdGenerator from '../../Helpers/UniqueIdGenerator';
import {AppThunk} from './index';

type AppNotificationOptions = {
    message: string;
    type?: 'success' | 'error' | 'info' | 'action';
    progress?: number;
    icon?: string;
}

type AppNotification = {id: string} & AppNotificationOptions

export const initialState = [] as AppNotification[];

const appNotificationsSlice = createSlice({
    name: 'appNotifications',
    initialState,
    reducers: {
        add(state, action: PayloadAction<{id?: string} & AppNotificationOptions>) {
            state.push({
                id: UniqueIdGenerator(),
                ...action.payload,
            });
        },
        setProgress(state, action: PayloadAction<{id: string; progress: number}>) {
            const index = state.findIndex((item) => item.id === action.payload.id);
            if (index > -1) {
                state[index].progress = action.payload.progress;
            }
        },
        remove(state, action: PayloadAction<string>) {
            return state.filter((item) => action.payload !== item.id);
        },
    },
});

const {
    add,
    setProgress: setNotifProgress,
    remove,
} = appNotificationsSlice.actions;

export const addNotifAction = (
    id: string,
    message: React.ReactNode,
    handler: (setProgress: (progress: number) => void) => Promise<void>): AppThunk => (dispatch, getState) => {
    if (getState().appNotifications.some((item) => item.id === id)) throw new Error(`Action ${id} is already launched`);
    dispatch(add({
        id,
        message: message as string,
        type: 'action',
        progress: 0,
    }));
    handler((progress) => dispatch(setNotifProgress({id, progress})))
        .then(() => dispatch(remove(id)));
};

export const addNotifSuccess = (message: React.ReactNode): AppThunk => (dispatch) => {
    dispatch(add({
        message: message as string,
        type: 'success',
    }));
};

export const addNotifError = (message: React.ReactNode): AppThunk => (dispatch) => {
    dispatch(add({
        message: message as string,
        type: 'error',
    }));
};

export const removeAppNotification = remove;

export default appNotificationsSlice.reducer;
