import React from 'react';
import {
    useCriterion,
} from 'mushin-redux-store';
import CriteriaTranslation from '../../../Translation/CriteriaTranslation';
import CheckboxRadio from '../../../Form/CheckboxRadio';

type Props = {
    criterionId: string;
    selectedValues: string[];
    selectValue: (slug: string) => void;
}

const CriterionDropdownPanelItem: React.FC<Props> = ({ criterionId, selectedValues, selectValue }) => {
    const criterion = useCriterion(criterionId);

    if (!criterion) {
        return null;
    }

    return (
        <div key={criterion.id}>
            <p className="mu-dropdown-menu__label-group">
                <CriteriaTranslation criterion={criterion} />
            </p>
            {criterion.values?.map((value) => (
                <div key={value.slug}>
                    <CheckboxRadio
                        className="mu-dropdown-menu__button-item"
                        setChecked={() => selectValue(value.slug)}
                        checked={selectedValues.includes(value.slug)}
                        label={value.label}
                    />
                </div>
            ))}
        </div>
    );
};

export default CriterionDropdownPanelItem;
