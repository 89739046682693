import React from 'react';
import i18n from 'i18next';
import MuIcon from '../MuIcon';

type Props = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: () => void;
  handleOpenSearchResultsPage?: () => void;
  placeholder?: string;
  value: string | undefined;
  ignoreClassName: string;
};

const SearchBar = ({
    handleChange,
    handleFocus,
    handleOpenSearchResultsPage,
    value = '',
    ignoreClassName,
    placeholder,
}: Props) => (
    <article className={`search-bar ${ignoreClassName}`}>
        <MuIcon className="search-bar__icon search-bar__icon--search" svgName="Search" />
        <input
            className="search-bar__input"
            type="text"
            placeholder={placeholder || i18n.t<string>('header.search_bar')}
            onChange={handleChange}
            value={value}
            onFocus={handleFocus}
            onKeyDown={
                handleOpenSearchResultsPage && ((e) => {
                    if (e.key === 'Enter') {
                        handleOpenSearchResultsPage();
                    }
                })
            }
        />
    </article>
);
export default SearchBar;
