import moment from 'moment';
import {
    createNoteFromFile,
    createNoteText,
    createQuestionAnswer,
    deleteNotes, getProfilesQuery,
    Note,
    Question,
    QuestionAnswer,
    QuestionItems,
    Questionnaire,
    QuestionnaireAnswerState,
    QuestionRanking,
    updateNote,
    updateNoteFromFile
} from 'mushin-redux-store';
import React from 'react';
import i18n from 'i18next';
import Delta from 'quill-delta';
import {isDeltaEmpty} from 'mushin-node-commons';
import Client from '../Services/Client';
import {AnswerValue, NoteValue} from '../Scenes/QuestionnaireAnswer/types';
import {NOTE_MAX_LENGTH} from '../constants';
import {AsyncAppThunk} from '../Redux/reducers';

export const getQuestionnaireDuration = (questionnaire: Questionnaire): number => {
    let duration = 0;

    questionnaire.questions.forEach((question) => {
        switch (question.type) {
            case 'radios':
                duration += 10;
                break;
            case 'checkboxes':
                duration += 20;
                break;
            case 'free':
                duration += 60;
                break;
            default:
        }
    });

    return duration;
};

export const isQuestionnaireAnswered = (questionnaire: Questionnaire, userId: string): boolean => {
    for (const participant of questionnaire.participants) {
        if (userId === participant.id) {
            return !!(participant.started_at || participant.completed_at);
        }
    }
    return false;
};

export const getQuestionnaireRespondentIds = (
    questionnaire: Questionnaire
): AsyncAppThunk<string[]> => async (dispatch): Promise<string[]> => {
    const profilesQuery = await dispatch(getProfilesQuery({
        questionnaire_id: questionnaire.id,
        questionnaire_response_status: 'answered',
    }, true, true));
    return profilesQuery?.data || [];
};

export const renderQuestionnaireStatus = (questionnaire: Questionnaire): React.ReactNode => {
    const startMoment = questionnaire.start_date && moment(new Date(questionnaire.start_date));
    const endMoment = questionnaire.end_date && moment(new Date(questionnaire.end_date));
    const now = moment();

    if (!startMoment) return i18n.t('global.status.draft');
    if (endMoment && endMoment.isBefore(now)) return i18n.t('global.status.finished');
    if (startMoment.isAfter(now)) return `${i18n.t('album.sessionStarts')} ${startMoment.fromNow(true)}`;
    if (endMoment) return `${endMoment.toNow(true)} ${i18n.t('album.sessionEnds')}`;

    return i18n.t('global.status.inProgress');
};

export const formatQuestionTitle = (title: string): Delta => (
    new Delta([
        {
            insert: title,
        },
        {
            attributes: {
                header: 2,
            },
            insert: '\n',
        }
    ])
);

export const hasStartedQuestionnaire = (questionnaire: Questionnaire): boolean => {
    const startMoment = questionnaire.start_date && moment(new Date(questionnaire.start_date));

    return !!startMoment && startMoment.isBefore(moment());
};

export const isQuestionnaireAdmin = (questionnaire: Questionnaire): boolean => {
    return Client.isAllowedToEditOrganization() || questionnaire.admins.includes(Client.getId() as string);
};

export const getQuestionnaireBaseUrl = (questionnaire: Questionnaire, projectId?: string): string => {
    let prefix = '';
    if (projectId) prefix = `/projects/${projectId}`;
    return `${prefix}/${questionnaire.candidacy ? 'candidacy' : 'questionnaires'}/${questionnaire.id}`;
};

export const getQuestionnaireUrl = (questionnaire: Questionnaire, projectId?: string): string => {
    const baseUrl = getQuestionnaireBaseUrl(questionnaire, projectId);
    if (!hasStartedQuestionnaire(questionnaire)) return `${baseUrl}/edit`;

    const hasToAnswer = questionnaire.participants?.some(
        (participant) => participant.id === Client.getId() as string
    ) && !questionnaire.answered;
    if (!hasToAnswer && isQuestionnaireAdmin(questionnaire)) return `${baseUrl}/answers`;

    return baseUrl;
};

const isFreeAnswerEmpty = (answer: AnswerValue['notes']) => {
    if (!answer?.length) return true;
    return !answer.some((freeValue) => (
        freeValue.image || freeValue.text.trim() || freeValue.image_src || freeValue.video_url
    ));
};

// eslint-disable-next-line import/prefer-default-export
export const validateAnswer = (question: Question, answer: AnswerValue | undefined): [true] | [false, string] => {
    if (question.type === 'description') {
        return [true];
    }
    if (question.type === 'free') {
        if (answer?.notes?.some((it) => it?.text?.length > NOTE_MAX_LENGTH)) {
            return [false, 'length'];
        }
        if (question.mandatory && isFreeAnswerEmpty(answer?.notes?.filter((it) => !it.deleted))) {
            return [false, 'mandatory'];
        }
    } else {
        const value = answer?.value;
        if (question.mandatory && (!value || (Array.isArray(value) && !value.length))) return [false, 'mandatory'];

        if (question.type === 'radios' || question.type === 'checkboxes' || question.type === 'ranking') {
            if (Array.isArray(value)) {
                if (question.minChoices && value.length < question.minChoices) return [false, 'range'];
                if (question.maxChoices && value.length > question.maxChoices) return [false, 'range'];
            }
        }
    }

    return [true];
};

export const uploadFreeValueItem = (
    questionnaireId: string,
    questionId: string,
    noteValue: NoteValue,
    setItemInfo: (noteId: string) => void,
    noteId?: string,
): AsyncAppThunk => async (dispatch): Promise<void> => {
    let note: Note | undefined | void;
    if (noteValue.image) {
        const payload = {
            content: new Delta([{insert: noteValue.text.trim()}]),
            upload_source: 'WEBAPP',
            questionnaire_id: questionnaireId,
            question_id: questionId,
        } as const;
        if (noteId) {
            note = await dispatch(updateNoteFromFile(noteId, noteValue.image, payload));
        } else {
            note = await dispatch(createNoteFromFile(noteValue.image, payload));
        }
    } else if (noteValue.text.trim()) {
        const payload = {
            label: noteValue.text.trim(),
            content: new Delta([{insert: noteValue.text.trim()}]),
            questionnaire_id: questionnaireId,
            question_id: questionId,
            upload_source: 'WEBAPP',
            image: noteValue.image_src ? undefined : null,
        } as const;
        if (noteId) {
            note = await dispatch(updateNote(noteId, payload));
        } else {
            note = await dispatch(createNoteText(payload));
        }
    }

    if (note) setItemInfo(note.id);
};

const deleteFreeValueItem = (
    noteIds: string[],
): AsyncAppThunk => async (dispatch): Promise<void> => {
    await dispatch(deleteNotes(noteIds));
};

export const uploadAnswer = (
    questionnaireId: string,
    question: Question,
    answer: AnswerValue | null,
    setAnswer: React.Dispatch<(prevState: AnswerValue | null) => AnswerValue | null>,
): AsyncAppThunk<QuestionAnswer> => async (dispatch): Promise<QuestionAnswer> => {
    if (question.type === 'free') {
        const setItemInfo = (index: number, noteId: string) => {
            setAnswer((prevValue) => {
                if (!prevValue) return prevValue;
                const newValue = {...prevValue};
                if (newValue.notes) newValue.notes[index].id = noteId;
                return newValue;
            });
        };

        const promises: Promise<unknown>[] = [];
        const deleteNoteIds: string[] = [];
        answer?.notes?.forEach((freeValueItem, index) => {
            if (!freeValueItem.deleted) {
                promises.push(dispatch(uploadFreeValueItem(
                    questionnaireId,
                    question.id,
                    freeValueItem,
                    (noteId) => setItemInfo(index, noteId),
                    freeValueItem.id
                )));
            }
            if (freeValueItem.deleted && freeValueItem.id) {
                deleteNoteIds?.push(freeValueItem.id);
            }
        });
        if (deleteNoteIds?.length) {
            promises.push(dispatch(deleteFreeValueItem(deleteNoteIds)));
        }
        await Promise.all(promises);
    }
    return dispatch(createQuestionAnswer({
        questionnaire_id: questionnaireId,
        question_id: question.id,
        value: answer?.value || null,
    }));
};

export const getNextQuestionIndex = (
    questionnaire: Questionnaire,
    questionIndex: number,
    answer: AnswerValue | null | undefined,
): number => {
    if (questionIndex < 0) return 0;

    const currentQuestion = questionnaire.questions[questionIndex];
    let nextQuestionId: string | undefined;

    if (currentQuestion?.type === 'radios') {
        nextQuestionId = currentQuestion.items.find(
            (it) => it.slug === answer?.value
        )?.nextQuestion;
    }

    if (!nextQuestionId) nextQuestionId = currentQuestion.nextQuestion;
    
    if (nextQuestionId === 'end') {
        return questionnaire.questions.length;
    }

    if (nextQuestionId) {
        const nextQuestionIndex = questionnaire.questions?.findIndex((q) => q.id === nextQuestionId);
        if (nextQuestionIndex > -1) return nextQuestionIndex;
    }

    return questionIndex + 1;
};

export const getInitialHistory = (
    questionnaire: Questionnaire,
    questionAnswers: NonNullable<QuestionnaireAnswerState>,
    result: number[] = [],
    index = 0,
): number[] => {
    const question = questionnaire.questions[index];
    if (!questionAnswers[question?.id]) {
        if (index === 0) return questionnaire?.desc && !isDeltaEmpty(questionnaire?.desc) ? [-1] : [0];
        if (index >= questionnaire.questions.length) return result;
        return [...result, index];
    }

    return getInitialHistory(
        questionnaire,
        questionAnswers,
        [...result, index],
        getNextQuestionIndex(questionnaire, index, questionAnswers[question?.id])
    );
};

type Option = {slug: string; label: string}
const getValueLabel = (options: Option[], answerValue: string): string | undefined => (
    options.find((option) => option.slug === answerValue)?.label
);
export const displayValue = (options: Option[], answerValue: QuestionAnswer['value'] | undefined): string => {
    if (!answerValue) return i18n.t('albums.undefinedPeriod');

    if (Array.isArray(answerValue)) return answerValue.map((value) => getValueLabel(options, value)).join(', ');
    if (typeof answerValue === 'string') {
        const label = getValueLabel(options, answerValue);
        if (label) return label;
    }
    return answerValue.toString();
};

export const isChecked = (itemSlug: string, answerValue: QuestionAnswer['value'] | undefined): boolean => {
    if (!answerValue) return false;

    if (Array.isArray(answerValue)) return answerValue.includes(itemSlug);

    return itemSlug === answerValue;
};

export const setChecked = (
    checked: boolean,
    itemSlug: string,
    answerValue: AnswerValue['value'] | undefined,
    uniqueSelection = false,
): string | string[] | null => {
    if (uniqueSelection) return checked ? itemSlug : null;

    // Multiple selection
    const value = [...(answerValue as string[] | undefined || [])];

    const itemIndex = value.indexOf(itemSlug);

    if (checked) {
        if (itemIndex === -1) value.push(itemSlug);
    } else if (itemIndex !== -1) {
        value.splice(itemIndex, 1);
    }

    return value;
};

export const getDefaultMinChoices = (question: Question, itemsLength: number): number => {
    if (question.type === 'ranking') return itemsLength;
    return question.mandatory ? 1 : 0;
};

export const questionItemsGridTemplateColumns = (question: QuestionItems | QuestionRanking): string => {
    let maxLength = 0;
    const withImages = question.items.some((item) => item.image_url);
    for (const item of question.items) {
        if (item.label.length > 200 && !withImages) return '1fr';
        if (item.label.length > maxLength) maxLength = item.label.length;
    }
    return (
        `repeat(auto-fit, minmax(${maxLength > 100 ? 300 : 180}px, max-content ))`
    );
};
