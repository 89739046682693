import React from 'react';
import i18n from 'i18next';
import {useParams} from 'react-router-dom';
import {useProject} from 'mushin-redux-store';
import SynthesisLayout from '../../SynthesisLayout';
import HeaderSynthesis from '../../HeaderSynthesis';
import InsightsSearch from './InsightsComponents/InsightsSearch';
import PanelSynthesisQuotes from '../../../../Components/PanelSynthesis/PanelSynthesisQuotes';

const SynthesisInsights: React.FC = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const project = useProject(projectId);

    if (!project) return null;

    return (
        <SynthesisLayout project={project} activeTab="insights">
            <div className="mu-app-layout__scroll mu-synthesis">
                <HeaderSynthesis
                    activeMode="Insights"
                    text={i18n.t<string>('SynthesisInsights.text')}
                    span={i18n.t<string>('SynthesisInsights.span')}
                    textButton={i18n.t<string>('SynthesisInsights.button')}
                />
                <InsightsSearch />
                <div className="mu-synthesis__section-panel">
                    <PanelSynthesisQuotes
                        activeMode="Opportunities"
                        title={i18n.t<string>('SectionPanelSynthesis.Opportunities.title')}
                        description={i18n.t<string>('SectionPanelSynthesis.Opportunities.textFromInsight')}
                        createLabel={i18n.t<string>('SectionPanelSynthesis.Opportunities.label')}
                        itemLabel={i18n.t<string>('SectionPanelSynthesis.Opportunities.span')}
                    />
                    <PanelSynthesisQuotes
                        activeMode="Solutions"
                        title={i18n.t<string>('SectionPanelSynthesis.Solutions.title')}
                        description={i18n.t<string>('SectionPanelSynthesis.Solutions.textFromInsight')}
                        createLabel={i18n.t<string>('SectionPanelSynthesis.Solutions.label')}
                        itemLabel={i18n.t<string>('SectionPanelSynthesis.Solutions.span')}
                    />
                </div>
            </div>
        </SynthesisLayout>
    );
};
export default SynthesisInsights;
