import React from 'react';
import {
    Note,
    useCurrentOrganizationId,
    useNotesQuery,
} from 'mushin-redux-store';
import i18n from 'i18next';
import AddIdeaModalNote from './AddIdeaModalNote';
import { useScrollThreshold } from '../../../../Helpers/effects';
import Spinner from '../../../../Components/Spinner';
import AlbumsSelector, {buildNoteQueryParams} from '../../../../Components/AlbumsSelector/AlbumsSelector';

type Props = {
    selectedNotes: Note[];
    setSelectedNotes: (notes: Note[]) => void;
    multipleSelection?: boolean;
};

const AddIdeaModalNotes: React.FC<Props> = ({
    multipleSelection = true,
    setSelectedNotes,
    selectedNotes,
}) => {
    const [currentOrganizationId] = useCurrentOrganizationId();
    const notesQuery = useNotesQuery(buildNoteQueryParams(currentOrganizationId, 'all'), true);

    const ref = useScrollThreshold<HTMLUListElement>(notesQuery.getMoreResults, {deps: [notesQuery.results?.length]});

    return (
        <>
            <AlbumsSelector
                onSelect={(type, id) => {
                    notesQuery.setParams(buildNoteQueryParams(currentOrganizationId, type, id));
                    setSelectedNotes([]);
                }}
                initialValue="mine"
            />
            {notesQuery.results && notesQuery.results.length > 0 ? (
                <ul ref={ref} className="mu-add-images-modal__notes">
                    {notesQuery.results.map((noteId) => {
                        const selectedNoteIndex = selectedNotes?.findIndex((selectedNote) => {
                            return selectedNote.id === noteId;
                        });
                        return (
                            <li key={noteId} className="mu-add-images-modal__note-item">
                                <AddIdeaModalNote
                                    noteId={noteId}
                                    isSelected={selectedNoteIndex !== -1}
                                    handleSelectNote={async (note) => {
                                        if (multipleSelection) {
                                            const newSelectedNotes = [...selectedNotes];
                                            if (selectedNoteIndex !== -1) {
                                                newSelectedNotes.splice(selectedNoteIndex, 1);
                                            } else {
                                                newSelectedNotes.push(note);
                                            }
                                            setSelectedNotes(newSelectedNotes);
                                        } else {
                                            setSelectedNotes(selectedNoteIndex !== -1 ? [] : [note]);
                                        }
                                    }}
                                />
                            </li>
                        );
                    })}
                </ul>
            ) : (<p className="mu-add-images-modal__no-entity">{i18n.t('modals.addImages.noResults')}</p>)}
            {notesQuery.loading && (
                <li><Spinner size="50px" /></li>
            )}
        </>
    );
};

export default AddIdeaModalNotes;
