import {AvailableLocale, Questionnaire, TranslatedProp} from 'mushin-redux-store';
import Delta from 'quill-delta';
import i18next from 'i18next';

export const isTranslated = (property: TranslatedProp<Delta | string>, locales: AvailableLocale[]): boolean => {
    if (!property?.original) return true;

    for (const locale of locales || []) {
        const translation = property.translations[locale];
        if (
            !translation?.value
            || new Date(translation.at).getTime() < new Date(property.at).getTime()
        ) {
            return false;
        }
    }
    return true;
};

export const isQuestionnaireTranslated = (questionnaire: Questionnaire): boolean => {
    if (!isTranslated(questionnaire.titles, questionnaire.locales)) return false;
    if (!isTranslated(questionnaire.descriptions, questionnaire.locales)) return false;
    for (const question of questionnaire.questions) {
        if (!isTranslated(question.labels, questionnaire.locales)) return false;
        if (question.items) {
            for (const item of question.items) {
                if (!isTranslated(item.labels, questionnaire.locales)) return false;
            }
        }
    }

    return true;
};

export const t_prop = <T extends Delta | string>(prop: TranslatedProp<T> | undefined, fallback: T): T => {
    if (!prop) return fallback;
    if (prop.lang && i18next.resolvedLanguage && i18next.resolvedLanguage !== prop.lang) {
        return prop.translations[i18next.resolvedLanguage]?.value || prop.original;
    }
    return prop.original;
};
