import React, {useState} from 'react';
import {createNoteMoodboard, Note} from 'mushin-redux-store';
import Delta from 'quill-delta';
import {useNavigate} from 'react-router-dom';
import i18n from 'i18next';
import MoodboardCarousel from '../../../../Components/MoodboardCarousel/MoodboardCarousel';
import {
    createBoardPagesFromNotes,
    setTemplateToBoardPage,
    setTemplateToBoardPages, setTitleToBoardPage
} from '../../../../Helpers/moodboards';
import {BoardLayout} from '../../../../Components/BoardComponents/BoardLayout/component/BoardLayout';
import CreateMoodboardNoteInfo from './CreateMoodboardNoteInfo';
import {useAppDispatch} from '../../../../Helpers/hooks';
import Spinner from '../../../../Components/Spinner';
import MuIcon from '../../../../Components/MuIcon';
import {addNotifSuccess} from '../../../../Redux/reducers/appNotificationsSlice';

type Props = {
    onClose: () => void;
    notes: Note[];
    numberPages?: number;
    albumId?: string;
    onSuccess?: () => void;
};

const CreateMoodboardModal: React.FC<Props> = (
    {
        notes,
        numberPages = 1,
        onClose,
        albumId,
        onSuccess,
    }
) => {
    const defaultTitle = i18n.t('gallery.moodboard.defaultTitle').toUpperCase();
    const [pages, setPages] = useState(() => createBoardPagesFromNotes(notes, numberPages, defaultTitle));
    const [selectedPage, setSelectedPage] = useState(0);

    const [title, setTitle] = useState(defaultTitle);
    const [desc, setDesc] = useState(new Delta([]));
    const [chosenAlbumId, setChosenAlbumId] = useState(albumId);
    const [isLoading, setLoading] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {width, height} = pages[0].board;
    const ratio = 650 / Math.max(width, height);

    const save = async (edit = false) => {
        setLoading(true);
        const result = await dispatch(createNoteMoodboard({
            moodboard_pages: pages.map((page) => ({ data: JSON.stringify(page) })),
            upload_source: 'WEBAPP',
            album_id: chosenAlbumId,
            label: title,
            content: desc,
        }));

        if (result.async) {
            dispatch(addNotifSuccess(i18n.t('notifications.moodboardCreationIsPending.success')));
        }
        if (!result.async && edit) {
            if (result.project_id) {
                navigate(`/projects/${result.project_id}/board/${result.id}`);
            } else {
                navigate(`/organizations/current/board/${result.id}`);
            }
        }

        if (onSuccess) onSuccess();
        setLoading(false);
        onClose();
    };

    const onTitleChange = (_title: string) => {
        setTitle(_title);
        setPages((prevPages) => {
            const newPages = [...prevPages];
            newPages[0] = setTitleToBoardPage(_title, pages[0]);
            return newPages;
        });
    };

    return (
        <div
            id="note-form"
            className="noteForm"
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onDragEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <div className="noteForm-main">
                <MoodboardCarousel
                    heightCarousel={ratio * height}
                    pages={pages}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                />
                <div className="noteForm-notePreview">
                    <div className="noteForm-notePreviewWrapper">
                        <BoardLayout
                            width={width * ratio}
                            height={height * ratio}
                            board={pages[selectedPage].board}
                            ratio={ratio}
                        />
                    </div>
                </div>
                <div className="noteForm-noteEditor">
                    <div className="noteForm-noteEditorWrapper">
                        <CreateMoodboardNoteInfo
                            title={title}
                            setTitle={onTitleChange}
                            noteDesc={desc}
                            setNoteDesc={setDesc}
                            noteAlbumId={chosenAlbumId}
                            setNoteAlbumId={setChosenAlbumId}
                            templateNoteCount={pages[selectedPage].boardData.notePool.length}
                            templateId={pages[selectedPage].boardData.templateId as number}
                            setTemplateId={(id, applyToAll) => (
                                applyToAll
                                    ? setPages(setTemplateToBoardPages(id, pages))
                                    : setPages((prevPages) => {
                                        const newPages = [...prevPages];
                                        newPages[selectedPage] = setTemplateToBoardPage(id, pages[selectedPage]);
                                        return newPages;
                                    })
                            )}
                        />
                        <div className="noteForm-footer text-align-center">
                            {isLoading ? <Spinner size="24px" /> : (
                                <>
                                    <button
                                        className="mu-btn"
                                        type="button"
                                        onClick={() => save()}
                                    >
                                        {i18n.t('models.boards.create')}
                                    </button>
                                    <button className="mu-btn" type="button" onClick={() => save(true)}>
                                        {i18n.t('models.boards.createAndEdit')}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" className="close" onClick={onClose}>
                <MuIcon svgName="Close" />
            </button>
        </div>
    );
};

export default CreateMoodboardModal;
