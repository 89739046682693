import React, { useState } from 'react';
import {createProject, Project, useCurrentOrganizationId} from 'mushin-redux-store';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import { useFieldState } from '../../../../Components/Form/common';
import { useAppDispatch } from '../../../../Helpers/hooks';
import DualChoice from '../../../../Components/DualChoice/DualChoice';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import CreateWithTitleModal from '../CreateWithTitleModal/CreateWithTitleModal';
import CategorySelector from '../../../../Components/CategorySelector/CategorySelector';

type Props = {
    onClose: () => void;
};

const CreateProjectModal: React.FC<Props> = ({
    onClose,
}) => {
    const [type, setType] = useState<Project['type'] | null>(null);
    const [currentOrganizationId] = useCurrentOrganizationId();
    const categoryField = useFieldState('');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    if (!currentOrganizationId) return null;

    if (!type) {
        return (
            <Modal classModifiers={['content-margin', 'no-footer']} handleRemoveModal={onClose}>
                <ModalHeader title={i18n.t('projects.create')} />
                <DualChoice
                    value={type}
                    setValue={(val) => setType(val as Project['type'])}
                    choice1={{
                        key: 'contributors',
                        label: i18n.t('global.coCreation'),
                        icon: 'Brainstorming',
                    }}
                    choice2={{
                        key: 'team',
                        label: i18n.t('global.internal'),
                        icon: 'Cogwheel',
                    }}
                />
            </Modal>
        );
    }

    return (
        <CreateWithTitleModal
            onClose={onClose}
            labelKey="projects"
            create={async (name) => {
                const project = await dispatch(createProject({
                    type,
                    organization_id: currentOrganizationId,
                    category: categoryField.value,
                    name,
                }));
                onClose();
                navigate(`/projects/${project.id}`);
            }}
        >
            <CategorySelector field={categoryField} />
        </CreateWithTitleModal>
    );
};

export default CreateProjectModal;
