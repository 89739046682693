import React, { useRef, useState } from 'react';
import { useCurrentOrganizationId, useProfilesQuery } from 'mushin-redux-store';
import i18n from 'i18next';
import { useClickOutside, useScrollThreshold } from '../../../Helpers/effects';
import InputText from '../../InputText/InputText';
import {useTextInputWithDelay} from '../../../Helpers/FormsHelpers/hooks';
import Spinner from '../../Spinner';
import ProfileCard from '../../UserCard/ProfileCard';
import DropdownMenu from '../../DropdownMenu/DropdownMenu';

type Props = {
    selectUser: (userId: string) => void;
    excludedUsersIds: string[];
    withGroups?: boolean;
}

const SelectUsersDropdown: React.FC<Props> = ({
    selectUser,
    excludedUsersIds,
    withGroups,
}) => {
    const [currentOrganizationId] = useCurrentOrganizationId();
    const profilesQuery = useProfilesQuery({
        organization_id: currentOrganizationId,
        type: 'team',
        include_groups: withGroups,
    }, true);
    const [currentQuery, setCurrentQuery] = useTextInputWithDelay((value) => {
        profilesQuery.setParams({
            organization_id: currentOrganizationId,
            type: 'team',
            include_groups: withGroups,
            name_search: value || undefined,
        });
    });
    const ref = useScrollThreshold(profilesQuery.getMoreResults, {deps: [profilesQuery.results?.length]});
    const [isOpen, setIsOpen] = useState(false);
    const container = useRef<HTMLElement>(null);

    useClickOutside(container, isOpen, () => setIsOpen(false));

    const filteredUsers = profilesQuery.results?.filter((userId) => !excludedUsersIds.includes(userId)) || [];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentQuery(e.target.value);
    };

    return (
        <DropdownMenu
            className="mu-select-with-search"
            contentClassName="mu-select-with-search__content"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            toggle={(
                <InputText
                    classModifiers={['full-width']}
                    value={currentQuery}
                    onChange={handleChange}
                    placeholder={i18n.t('modals.EditProject.addUsers')}
                    contrast
                />
            )}
            align="justify"
            scrollRef={ref}
        >
            {filteredUsers.map((userId) => {
                return (
                    <button
                        key={userId}
                        className="mu-select-with-search__user"
                        type="button"
                        onClick={() => {
                            selectUser(userId);
                            setIsOpen(false);
                        }}
                    >
                        <ProfileCard id={userId} />
                    </button>
                );
            })}
            {profilesQuery.loading && (
                <div className="mu-select-with-search__loading">
                    <Spinner size="40px" />
                </div>
            )}
        </DropdownMenu>
    );
};

export default SelectUsersDropdown;
