import React, {useEffect, useState} from 'react';
import {ApiV3, updateMe} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../Components/Modal/Modal';
import {ModalHeader} from '../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../Components/Modal/ModalFooter';
import {ModalBody} from '../../../Components/Modal/ModalBody';
import Document, {DocumentContent} from '../../../Components/Document/Document';
import {useAppDispatch} from '../../../Helpers/hooks';

const TermsModal: React.FC<{type: 'cgu' | 'gdpr'}> = ({type}) => {
    const [terms, setTerms] = useState<DocumentContent | null>(null);

    const dispatch = useAppDispatch();

    const handleAcceptTerms = async () => {
        if (terms) {
            await dispatch(updateMe({ [type]: terms.versionCode }));
        }
    };

    useEffect(() => {
        setTerms(null);
        ApiV3.getInstance().get(`users/${type}`).then((response) => setTerms(response.data));
    }, [type]);

    return (
        <Modal classModifiers="important">
            <ModalHeader title={i18n.t(`accounts.signUp.${type}.title`)} />
            <ModalBody>
                {terms && (<Document desc={terms} />)}
            </ModalBody>
            <ModalFooter>
                <div className="text-align-center">
                    <button type="button" onClick={handleAcceptTerms} className="mu-btn" id="accept_terms_button">
                        {i18n.t(`accounts.signUp.${type}.accept`)}
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default TermsModal;
