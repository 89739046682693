import { split } from 'mushin-node-moodboard/lib/common/mixedContent';
import { Handler } from './type';

import { hitBox } from '../layoutUtils';

const getTextBox = (cell, content) => {
    if (!content) return;

    if (content.type === 'text') {
        return cell;
    }

    if (content.type === 'mixed') {
        return split(cell, content).reduce((o, {
            cell,
            content,
        }) => o || getTextBox(cell, content), null);
    }
};

export const onTouchDown: Handler = ({
    state,
}) => ({
    state: {
        ...state,
        click: {
            downDate: Date.now(),
        },
    },
});

export const onTouchUp: Handler = ({
    state,
    board,
    selectedCell,
}, pointers) => {
    if (!state.click || state.click.downDate + 200 < Date.now()) {
    // too long to be a click. It is a drag and drop
        return;
    }

    if (!pointers[0]) return;

    const cell = selectedCell && getTextBox(board.cells[selectedCell], board.contents[selectedCell]);

    if (!cell) return;

    if (hitBox(cell, pointers[0])) {
        return {
            mode: 'textEdit',
        };
    }
};
