import { Handler } from '../type';

import { hitBoxes, setCell } from '../../layoutUtils';
import { snap } from './snap';

/**
 * check if the pointer did is down on a cell margin
 * if so, register the state as dragging:translate
 *
 * notice how this is ignored if the state is already dragging ( which introduce a notion of priority )
 */
export const onTouchDown: Handler = ({
    state,
    board,
    selectedCell,
}, pointers) => {
    if (state.dragging) return;

    const hit = pointers[0] && hitBoxes(board.ids.map((id) => board.cells[id]), pointers[0], board.cells[selectedCell]);

    const id = Object.keys(board.cells).find((idCell) => board.cells[idCell] === hit);

    if (hit) {
        return {
            state: {
                ...state,
                dragging: 'translate',
                drag: {
                    originPoint: pointers[0],
                    originCell: hit,
                    cellId: id,
                },
            },
        };
    }
};

export const onTouchMove: Handler = ({
    state,
    board,
}, pointers) => {
    if (state.dragging !== 'translate' || !state.drag) return;

    if (!pointers[0]) return;

    const {
        originPoint,
        originCell,
        cellId,
    } = state.drag;

    const dx = pointers[0].x - originPoint.x;
    const dy = pointers[0].y - originPoint.y;

    // translate the cell
    const newCell = {
        max: { x: originCell.max.x + dx, y: originCell.max.y + dy },
        min: { x: originCell.min.x + dx, y: originCell.min.y + dy },
    };

    // snap to margin
    const snapRes = snap(board.ids.filter((id) => id !== cellId).map((id) => board.cells[id]), {
        x: board.width,
        y: board.height,
    }, newCell, board.margin);

    return {
        board: setCell(board, cellId, snapRes.cell),
        state: {
            ...state,
            snapRules: snapRes.rules,
        },
    };
};

export const onTouchUp: Handler = ({
    state,
}) => {
    if (state.dragging !== 'translate') return;

    return {
        state: {
            ...state,
            dragging: null,
            snapRules: [],
        },
    };
};
