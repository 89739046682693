import React, {useState} from 'react';
import {useAllHashtags} from 'mushin-redux-store';
import i18n from 'i18next';
import MuButton from '../../../MuButton';
import EntitiesSelect from '../../../EntitySelect/EntitiesSelect';
import {Aggregation, mergeTags, searchTagList} from '../../../../Helpers/noteHelpers';

type Props = {
    hashtags: string[] | undefined;
    setHashtags: (hashtags: string[] | undefined) => void;
    aggregations: Aggregation[];
}

const HashtagsDropdownPanel: React.FC<Props> = ({ hashtags, setHashtags, aggregations }) => {
    const [search, setSearch] = useState('');
    const [selectedHashtags, setSelectedHashtags] = useState(hashtags || []);
    const allHashtags = useAllHashtags();

    const tags = searchTagList(search, mergeTags(aggregations, allHashtags, {selected: hashtags}));

    return (
        <>
            <EntitiesSelect
                className="mu-filters-toolbar__inline-select"
                values={selectedHashtags}
                options={tags.map((result) => result.key)}
                useItem={(id) => tags.find((opt) => opt.key === id) as Aggregation}
                useItems={() => tags}
                onSelect={setSelectedHashtags}
                getMoreResults={() => { /* */ }}
                placeholder={i18n.t<string>('components.filters.dropdown.selectTagsPlaceholder')}
                getEntityName={(entity) => `${entity.key}${entity.doc_count ? ` (${entity.doc_count})` : ''}`}
                search={(query) => setSearch(query)}
                noSearchDelay
                inline
            />
            <MuButton
                className="mu-filters-toolbar__panel-submit"
                handleClick={() => setHashtags(selectedHashtags)}
                label={i18n.t('components.filters.dropdown.apply')}
            />
        </>
    );
};

export default HashtagsDropdownPanel;
