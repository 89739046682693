import { Cell, Point } from 'mushin-node-moodboard';

import { generateContainerRules, generateCellsRules, findMatchingSnappingRules } from '../translate/snapUtils';

/**
 * helper to snap content on scale
 *
 * generate a set of position where the element should snap
 * if the element is close enought, use the snapping position
 * also return the rule to eventually provide visual feedback
 */
export const snap = (cells: Array<Cell>, containerDimension: Point, cellToSnap: Cell, scalingKernel: Point, margin = 5, threshold = 3) => {
    const x = snapAxe('x', scalingKernel, cells, containerDimension, margin, threshold, cellToSnap);
    const y = snapAxe('y', scalingKernel, cells, containerDimension, margin, threshold, cellToSnap);

    return {
        cell: {
            max: {
                x: cellToSnap.max.x + x.d * (scalingKernel.x > 0 ? 1 : 0),
                y: cellToSnap.max.y + y.d * (scalingKernel.y > 0 ? 1 : 0),
            },
            min: {
                x: cellToSnap.min.x + x.d * (scalingKernel.x < 0 ? 1 : 0),
                y: cellToSnap.min.y + y.d * (scalingKernel.y < 0 ? 1 : 0),
            },
        },
        rules: [...x.rules, ...y.rules],
    };
};

const snapAxe = (axe: 'x' | 'y', scalingKernel, cells: Array<Cell>, containerDimension: Point, margin: number, threshold: number, cellToSnap: Cell) => {
    /**
   * generate all the rules
   */
    const rules = [...generateContainerRules(axe, containerDimension, margin), ...generateCellsRules(axe, cells, margin)]
    /**
   * discard rules that concern the side of the cell which is not being dragged
   */
        .filter(({
            side,
        }) => scalingKernel[axe] === side);

    /**
   * return the matching rules
   */
    return findMatchingSnappingRules(rules, axe, cellToSnap, threshold);
};
