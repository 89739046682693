import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import * as Sentry from '@sentry/react';
import {AvailableLocale, logout, register, useMe} from 'mushin-redux-store';
import PhoneInput, {Value, ExternalValue, Country} from 'react-phone-number-input';
import CodeRequest from '../CodeRequest/CodeRequest';
import SessionsLayout from '../Components/SessionsLayout/SessionsLayout';
import {useSearchParams} from '../../../Helpers/queryParser';
import {getInfoKey, loginUrl, successUrl} from '../helpers';
import InfoPanel from '../../../Components/InfoPanel/InfoPanel';
import {useFieldState, withOptionalMention} from '../../../Components/Form/common';
import {useAppDispatch} from '../../../Helpers/hooks';
import {multipleValidate} from '../../../Components/Form/validation';
import FormTextInput from '../../../Components/Form/FormTextInput';
import NewPasswordInput from '../../../Components/Form/NewPasswordInput';
import MuButton from '../../../Components/MuButton';
import timezoneToCountry from '../../../Helpers/timezoneToCountry';
import MuIcon from '../../../Components/MuIcon';
import useCodeRequest from '../CodeRequest/useCodeRequest';
import GoogleButton from '../../../Components/GoogleButton/GoogleButton';

export const backUrl = (landing: string | undefined): string | undefined => {
    if (!landing) return '/welcome';
    if (landing === 'response') return '/questionnaires/success';
    return undefined;
};

const Register: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams<{email?: string; landing?: string}>();
    const me = useMe();

    const [loading, setLoading] = useState(false);
    const [hasRequestError, setRequestError] = useState(false);
    const codeRequest = useCodeRequest();
    const email = useFieldState(me?.email || searchParams.email || '');
    const nameToDisplay = useFieldState(me?.name || '');
    const phone = useFieldState<Value | ExternalValue | undefined>(me?.phone || '');
    const password = useFieldState('');

    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const validate = async (e: React.FormEvent) => {
        e.preventDefault();
        setRequestError(false);
        if (multipleValidate(me?.sso ? [
            email.validate,
            nameToDisplay.validate,
        ] : [
            email.validate,
            nameToDisplay.validate,
            password.validate,
        ])) {
            setLoading(true);
            try {
                await dispatch(register({
                    locale: i18n.resolvedLanguage as AvailableLocale,
                    name: nameToDisplay.value,
                    phone: phone.value?.toString() || undefined,
                    password: password.value,
                }));
                if (me?.email_verified) {
                    navigate(successUrl(searchParams.landing));
                } else if (await codeRequest.send(email.value)) {
                    setSearchParams((prevState) => ({
                        ...prevState,
                        email: email.value,
                    }));
                }
            } catch (err) {
                Sentry.captureException(err);
                setRequestError(true);
            }
            setLoading(false);
        }
    };

    const infoKey = getInfoKey(searchParams.landing);
    const loginLink = loginUrl(searchParams.landing);
    const backLink = backUrl(searchParams.landing);

    if (searchParams.email) {
        return (
            <SessionsLayout title={i18n.t(`accounts.${searchParams.landing || 'signUp'}.title`)}>
                <InfoPanel iconName="Check" text={i18n.t('register.valid')} classModifiers="valid" />
                <CodeRequest
                    email={searchParams.email}
                    request={codeRequest}
                    onValidate={() => {
                        navigate(successUrl(searchParams.landing));
                    }}
                />
            </SessionsLayout>
        );
    }

    return (
        <SessionsLayout title={i18n.t(`accounts.${searchParams.landing || 'signUp'}.title`)}>
            {infoKey && (
                <InfoPanel iconName="InfoLine" text={i18n.t(infoKey)} classModifiers="valid" />
            )}
            <form onSubmit={validate}>
                <section className="mu-form-row mu-form-row--buttons">
                    <GoogleButton />
                    <Link className="mu-btn mu-btn--sso" to="/login/sso">
                        <MuIcon className="mu-btn__left-icon" svgName="Key" />
                        SSO
                    </Link>
                </section>
                <section className="mu-form-row">
                    <div className="mu-form-row__group">
                        <FormTextInput
                            placeholder={i18n.t('models.users.email')}
                            disabled={me?.sso}
                            field={email}
                            autoCapitalize="none"
                            required
                            email
                        />
                    </div>
                </section>
                <input // This field is set to disable email/password suggestions for the name field above (Firefox)
                    style={{display: 'none'}}
                    type="email"
                    value={me?.email}
                    readOnly
                />
                {!me?.sso && (
                    <NewPasswordInput passwordField={password} />
                )}
                <div className="mu-form-row">
                    <FormTextInput
                        field={nameToDisplay}
                        placeholder={t('models.users.nameToDisplay')}
                        required
                        min={5}
                        message={t<string>('register.nameHint')}
                    />
                </div>
                <div className="mu-form-row">
                    <PhoneInput
                        className="mu-input mu-input--full-width"
                        numberInputProps={{className: 'mu-input-raw'}}
                        placeholder={withOptionalMention(i18n.t('models.users.phone'), false)}
                        value={phone.value}
                        onChange={phone.setValue}
                        defaultCountry={timezoneToCountry(Intl.DateTimeFormat().resolvedOptions().timeZone) as Country}
                    />
                    <span className="mu-input-message-item">
                        <MuIcon className="mu-input-message-item__icon" svgName="InfoLine" />
                        {t('register.phoneHint')}
                    </span>
                </div>
                {hasRequestError && (
                    <InfoPanel
                        classModifiers={['error']}
                        iconName="Warning"
                        text={t('accounts.reset_password.errors.unknown') as string}
                    />
                )}
                {codeRequest.error && (
                    <InfoPanel classModifiers={['error']} iconName="Warning" text={i18n.t<string>(codeRequest.error)} />
                )}
                <MuButton
                    className="mu-sessions__submit-button"
                    type="submit"
                    disabled={loading}
                    classModifiers={['large']}
                    label={t('accounts.signUp.submit')}
                />
                {loginLink && (
                    <Link to={loginLink} className="mu-form-row__link mu-link">
                        {i18n.t('accounts.signUp.alreadyHaveAnAccount')}
                    </Link>
                )}
                {backLink && (
                    <Link to={backLink} onClick={() => dispatch(logout())} className="mu-form-row__link mu-link">
                        {i18n.t(me?.sso ? 'components.navbar.dropdown.logout' : 'global.back')}
                    </Link>
                )}
            </form>
        </SessionsLayout>
    );
};

export default Register;
