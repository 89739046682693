import React from 'react';
import {Album, updateAlbum} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton';
import {useFieldState} from '../../../../Components/Form/common';
import {multipleValidate} from '../../../../Components/Form/validation';
import {useAppDispatch} from '../../../../Helpers/hooks';
import {openQuitWithoutSavingAlert} from '../../../../Redux/reducers/modalsSlice';
import FormTextInput from '../../../../Components/Form/FormTextInput';

type Props = {
    onClose: () => void;
    album: Album;
};

const AlbumInfoModal: React.FC<Props> = ({
    onClose,
    album,
}) => {
    const labelField = useFieldState(album.label);

    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(openQuitWithoutSavingAlert(
            album.label !== labelField.value,
            onClose,
        ));
    };

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={handleClose}>
            <ModalHeader
                subtitle={i18n.t('modals.EditAnAlbum.subtitle')}
                title={i18n.t('modals.EditAnAlbum.title')}
                icon="SubFolderFill"
            />
            <div className="util-modal-oflow-auto">
                <div className="mu-form-row">
                    <FormTextInput
                        label={i18n.t('global.name')}
                        placeholder={i18n.t('global.namePlaceholder', {year: new Date().getFullYear()})}
                        field={labelField}
                        required
                        contrast
                    />
                </div>
            </div>
            <ModalFooter>
                <MuButton
                    handleClick={async () => {
                        if (multipleValidate([labelField.validate])) {
                            await dispatch(updateAlbum(album.id, {
                                label: labelField.value,
                            }));
                            onClose();
                        }
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default AlbumInfoModal;
