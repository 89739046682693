import React from 'react';
import i18n from 'i18next';
import AppLayout from '../../Components/Layouts/AppLayout';
import AlbumsSearch from './Components/AlbumsSearch/AlbumsSearch';

const AlbumsPage: React.FC = () => {
    return (
        <AppLayout title={i18n.t('global.synthesis')}>
            <AlbumsSearch />
        </AppLayout>
    );
};

export default AlbumsPage;
