import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import i18n from 'i18next';
import {logout} from 'mushin-redux-store';
import SessionsLayout from './Components/SessionsLayout/SessionsLayout';
import {useAppDispatch} from '../../Helpers/hooks';
import Client from '../../Services/Client';
import MuButton from '../../Components/MuButton';

const RedirectToMobileApp: React.FC<React.PropsWithChildren> = ({children}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const location = useLocation();

    return (
        <SessionsLayout
            hideIntercom
            title={i18n.t('accounts.home.title', { name: Client.getUser()?.name})}
            catchphrase={i18n.t('accounts.redirect_to_mobile_app.infos')}
        >
            {children}
            <p className="mu-sessions-content__text">{i18n.t('accounts.redirect_to_mobile_app.alreadyHave')}</p>
            <MuButton
                type="button"
                className="mu-sessions-content__button"
                label={i18n.t('accounts.redirect_to_mobile_app.open')}
                handleClick={() => {
                    window.location.href = `mymushin:/${location.pathname}`;
                }}
            />
            {Client.getId() && (
                <button
                    type="button"
                    className="mu-form-row__link mu-link mu-link--full-size"
                    onClick={async () => {
                        await dispatch(logout());
                        navigate('/login');
                    }}
                >
                    {i18n.t('components.navbar.dropdown.logout')}
                </button>
            )}
        </SessionsLayout>
    );
};

export default RedirectToMobileApp;
