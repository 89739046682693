import React from 'react';
import { ActionBar } from './common';
import { ActionProps, ActionBarSeparator, commonButtons } from '../actionButtons/common';
import { imageButtons } from '../actionButtons/image';

export default ((props: ActionProps) => {
    return (
        <ActionBar>
            {imageButtons(props)}
            <ActionBarSeparator />
            {commonButtons(props)}
        </ActionBar>
    );
});
