import {registerLocale, setDefaultLocale} from 'react-datepicker';
import { AvailableLocale } from 'mushin-redux-store';

import en from './en-US';
import fr from './fr';
import es from './es';
import it from './it';

registerLocale('en', en);
registerLocale('fr', fr);
registerLocale('es', es);
registerLocale('it', it);

export const setDefaultDatepickerLocale = (locale: AvailableLocale): void => {
    setDefaultLocale(locale);
};
