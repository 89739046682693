import * as React from 'react';
import {Project} from 'mushin-redux-store';
import i18n from 'i18next';
import InputText from '../InputText/InputText';
import FiltersToolbar from '../FiltersToolbar/FiltersToolbar';
import SortBySelect from '../FiltersToolbar/Elements/SortBySelect';
import {
    createdAtFilter,
    ownersFilter,
    statusFilter,
    stepsFilter,
    updatedAtFilter
} from '../FiltersToolbar/helpers';
import {FilterConfig} from '../FiltersToolbar/FilterPanelContent';

export type AlbumSearchParams = {
    sort?: string;
    name_search?: string;
    step_slug?: string;
    min_server_updated_at?: string;
    max_server_updated_at?: string;
    min_date?: string;
    max_date?: string;
    owners?: string[];
    status?: string;
}

type Props = {
    project: Project;
    searchParams: AlbumSearchParams;
    setSearchParams: (searchParams: AlbumSearchParams) => void;
};

const AlbumToolbar: React.FC<Props> = ({
    project,
    searchParams,
    setSearchParams,
}) => {
    const filters: {
        [key: string]: FilterConfig
    } = {
        status: statusFilter(
            searchParams.status,
            (status) => setSearchParams({...searchParams, status})
        ),
        authors: ownersFilter(
            searchParams.owners,
            (owners) => setSearchParams({...searchParams, owners})
        ),
        creationDate: createdAtFilter(
            searchParams.min_date,
            searchParams.max_date,
            (min_date, max_date) => setSearchParams(
                {...searchParams, min_date, max_date}
            )
        ),
        updatingDate: updatedAtFilter(
            searchParams.min_server_updated_at,
            searchParams.max_server_updated_at,
            (min_server_updated_at, max_server_updated_at) => setSearchParams(
                {...searchParams, min_server_updated_at, max_server_updated_at}
            )
        ),
    };

    if (project.steps) {
        filters.steps = stepsFilter(
            searchParams.step_slug,
            (step_slug) => setSearchParams({...searchParams, step_slug}),
            project.steps,
        );
    }

    return (
        <section className="mu-toolbar mu-albums-page__toolbar">
            <div className="mu-toolbar__search">
                <InputText
                    classModifiers={['full-width']}
                    leftIcon="Search"
                    value={searchParams.name_search}
                    onChange={(e) => setSearchParams({...searchParams, name_search: e.target.value})}
                    placeholder={i18n.t('components.filters.search')}
                />
            </div>
            <SortBySelect
                sort={searchParams.sort}
                setSort={(sort) => setSearchParams({...searchParams, sort})}
                options={[
                    {key: undefined, icon: 'Dashboard', label: i18n.t('components.sort.relevant')},
                    {key: '-created_at', icon: 'Calendar', label: i18n.t('components.sort.mostRecent')},
                    {key: 'created_at', icon: 'Calendar', label: i18n.t('components.sort.oldest')},
                    {key: 'label', icon: 'ArrowDown', label: i18n.t('components.sort.alphabetic')},
                    {key: '-label', icon: 'ArrowUp', label: i18n.t('components.sort.reverseAlphabetic')}
                ]}
            />
            <FiltersToolbar
                filters={filters}
                resetAll={() => setSearchParams({})}
            />
        </section>
    );
};

export default AlbumToolbar;
