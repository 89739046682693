import React, {useEffect, useRef, useState} from 'react';
import Delta from 'quill-delta';
import { isDeltaEmpty } from 'mushin-node-commons';
import {ResizeSensor} from 'css-element-queries';
import i18n from 'i18next';
import { TranslatedProp } from 'mushin-redux-store';
import MuIcon from '../MuIcon';
import {classWithModifiers} from '../../Helpers/css';
import QuillTranslation from '../Translation/QuillTranslation';
import { t_prop } from '../../Helpers/translate';

type Props = {
    translationContent: {
        translationProp?: TranslatedProp<Delta>;
        fallback: Delta
    };
    placeholder?: React.ReactNode;
    size?: 'small' | 'medium' | 'large';
    secondary?: boolean;
}

const DeltaTruncated: React.FC<Props> = ({translationContent, placeholder, size = 'medium', secondary = false}) => {
    const content = t_prop(translationContent?.translationProp, translationContent?.fallback || new Delta([]));
    const [hasGoalOverflow, setGoalOverflow] = useState(true);
    const [isGoalExpanded, setGoalExpanded] = useState(false);

    const goalContainer = useRef<HTMLParagraphElement>(null);
    const goalParagraph = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (goalParagraph.current && goalContainer.current) {
            const sensor = new ResizeSensor(goalParagraph.current, () => {
                if (!isGoalExpanded && goalParagraph.current && goalContainer.current) {
                    setGoalOverflow(goalParagraph.current.scrollHeight > goalContainer.current.offsetHeight + 20);
                }
            });
            // Indicates how to clean the effect
            return function cleanup() {
                sensor.detach();
            };
        }
        return () => { /* */ };
    }, [isGoalExpanded]);
    const deltaIsEmpty = isDeltaEmpty(content);
    return (
        <div className="mu-delta-truncated__wrapper">
            <div
                className={classWithModifiers('mu-delta-truncated', {
                    [size]: true,
                    empty: deltaIsEmpty,
                    expanded: isGoalExpanded,
                    overflow: hasGoalOverflow && !deltaIsEmpty,
                    secondary,
                })}
                ref={goalContainer}
            >
                {!deltaIsEmpty ? (
                    <article
                        className="mu-delta-truncated__content"
                        ref={goalParagraph}
                    >
                        <QuillTranslation
                            translationProp={translationContent.translationProp}
                            fallback={translationContent.fallback}
                            className="mu-rich-input"
                            theme=""
                        />
                    </article>
                ) : (
                    <div className="mu-delta-truncated__placeholder">
                        {placeholder}
                    </div>
                )}
            </div>
            {!deltaIsEmpty && (hasGoalOverflow || isGoalExpanded) && (
                <button
                    type="button"
                    className="mu-delta-truncated__see-more"
                    onClick={() => setGoalExpanded(!isGoalExpanded)}
                >
                    <MuIcon className="mu-delta-truncated__see-more-icon" svgName="Plus" />
                    {isGoalExpanded ? i18n.t('dashboardGoal.hide') : i18n.t('dashboardGoal.display')}
                </button>
            )}
        </div>
    );
};
export default DeltaTruncated;
