import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import SessionsLayout from '../Components/SessionsLayout/SessionsLayout';
import {useSearchParams} from '../../../Helpers/queryParser';
import {useFieldState} from '../../../Components/Form/common';
import FormTextInput from '../../../Components/Form/FormTextInput';
import MuButton from '../../../Components/MuButton';
import Alert from '../../../Components/Alert/Alert';
import {getErrorKey} from '../../../Helpers/errors';

const ERRORS = {
    NO_SSO_CONFIG: 'accounts.login.error',
    DEFAULT: 'accounts.login.error',
};

const SsoLogin: React.FC = () => {
    const [searchParams] = useSearchParams<{email?: string; landing?: string; error?: string}>();
    const email = useFieldState(searchParams.email || '');

    const { i18n } = useTranslation();

    return (
        <SessionsLayout title={i18n.t(`accounts.${searchParams.landing || 'signUp'}.title`)}>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    window.location.href = `/api/v3/login/sso?email=${email.value}`;
                }}
            >
                {searchParams.error && (
                    <Alert
                        classModifiers={['error', 'alert-form']}
                        label={i18n.t<string>(getErrorKey(searchParams.error, ERRORS))}
                    />
                )}
                <section className="mu-form-row">
                    <div className="mu-form-row__group">
                        <FormTextInput
                            placeholder={i18n.t('models.users.email')}
                            field={email}
                            autoCapitalize="none"
                            required
                            email
                        />
                    </div>
                </section>
                <MuButton
                    className="mu-sessions__submit-button"
                    disabled={!email.value}
                    label={i18n.t('accounts.login.submit')}
                    type="submit"
                />
                <Link to={-1} className="mu-form-row__link mu-link">
                    {i18n.t('global.back')}
                </Link>
            </form>
        </SessionsLayout>
    );
};

export default SsoLogin;
