import React, {useState} from 'react';
import {
    deleteAlbum,
    removeUserFromAlbum,
    archiveAlbum,
    unarchiveAlbum,
    Album,
    User,
    useProject, ApiV3
} from 'mushin-redux-store';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import TextIcon from '../TextIcon/TextIcon';
import { classWithModifiers } from '../../Helpers/css';
import Client from '../../Services/Client';
import { MoreOptionsPreviewBlockBtn } from '../AlbumPreviewBlock/MoreOptionPreviewBlock';
import { useAppDispatch } from '../../Helpers/hooks';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import MenuProgressAction from '../MenuProgressAction/MenuProgressAction';
import {openModal, openAlert} from '../../Redux/reducers/modalsSlice';
import {isAlbumAdmin} from '../../Helpers/users';

type Props = {
  album: Album;
  CustomOptionsBtn?: React.FC<{
      handleClick: () => void;
  }>;
  isLastOfLine?: boolean;
};

const AlbumDropdownMenu: React.FC<Props> = (
    {
        album,
        isLastOfLine,
        CustomOptionsBtn,
    }
) => {
    const project = useProject(album.project_id);
    const [isMenuOpen, setMenuOpen] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const client = Client.getUser() as User;
    const isMemberOfProject = project && Client.isTeamMemberOfProject(project);
    const isAdmin = isAlbumAdmin(album);

    const handleDeleteAlbum = (e: React.UIEvent): void => {
        e.stopPropagation();
        dispatch(
            openAlert({
                type: 'danger',
                title: i18n.t('modals.deleteAlbum.title', { albumName: album.label }),
                icon: 'Delete',
                confirmLabel: i18n.t('modals.deleteAlbum.confirmButton'),
                body: i18n.t('modals.deleteAlbum.catchPhrase', { albumName: album.label }),
                confirmAction: () => dispatch(deleteAlbum(album.id)).then(
                    () => {
                        if (project) {
                            navigate(`/projects/${project.id}/albums`);
                        }
                    }
                ),
            })
        );
        setMenuOpen(false);
    };

    const handleLeaveAlbum = (e: React.UIEvent): void => {
        e.stopPropagation();
        dispatch(
            openAlert({
                type: 'danger',
                title: i18n.t('modals.removeAlbum.title', { albumName: album.label }),
                icon: 'Delete',
                confirmLabel: i18n.t('modals.removeAlbum.confirmButton'),
                body: i18n.t('modals.removeAlbum.catchPhrase', { albumName: album.label }),
                confirmAction: () => dispatch(removeUserFromAlbum(album.id, client.id)).then(
                    () => {
                        if (project) {
                            navigate(`/projects/${project.id}/albums`);
                        }
                    }
                ),
            })
        );
        setMenuOpen(false);
    };

    const handleUpdateAlbum = (e: React.UIEvent): void => {
        e.stopPropagation();
        dispatch(openModal('EditAnAlbum', { album }));
        setMenuOpen(false);
    };

    const handleEditAlbumUsers = (e: React.UIEvent): void => {
        e.stopPropagation();
        dispatch(openModal('EditAlbumUsers', { album }));
        setMenuOpen(false);
    };

    const handleMoveAlbum = (e: React.UIEvent): void => {
        e.stopPropagation();
        dispatch(openModal('MoveAlbum', { album }));
        setMenuOpen(false);
    };

    return (
        <DropdownMenu
            className={classWithModifiers('mu-dropdown', ['album-preview'])}
            isOpen={isMenuOpen}
            setIsOpen={setMenuOpen}
            toggle={(
                CustomOptionsBtn
                    ? <CustomOptionsBtn handleClick={() => setMenuOpen(!isMenuOpen)} />
                    : <MoreOptionsPreviewBlockBtn toggleDropdown={() => setMenuOpen(!isMenuOpen)} />
            )}
            align={isLastOfLine ? 'start' : 'end'}
        >
            {isAdmin && (
                <li className="mu-dropdown-menu__button-item">
                    <button onClick={handleUpdateAlbum} type="button">
                        <TextIcon icon="Edit" label={i18n.t('global.edit')} />
                    </button>
                </li>
            )}
            <li className="mu-dropdown-menu__button-item">
                <button onClick={handleEditAlbumUsers} type="button">
                    <TextIcon icon="Share" label={i18n.t('global.share')} />
                </button>
            </li>
            {isAdmin && (
                <li className="mu-dropdown-menu__button-item">
                    <button onClick={handleMoveAlbum} type="button">
                        <TextIcon icon="FolderLine" label={i18n.t('album.dropdowns.move')} />
                    </button>
                </li>
            )}
            {isAdmin && (
                <>
                    <li className="mu-dropdown-menu__button-item">
                        <button
                            onClick={album.archived_at
                                ? (e) => {
                                    e.stopPropagation();
                                    dispatch(unarchiveAlbum(album.id));
                                }
                                : (e) => {
                                    e.stopPropagation();
                                    dispatch(archiveAlbum(album.id));
                                }}
                            type="button"
                        >
                            <TextIcon
                                icon="Archive"
                                label={i18n.t(album.archived_at
                                    ? 'album.dropdowns.unarchive' : 'album.dropdowns.archive')}
                            />
                        </button>
                    </li>
                </>
            )}
            {client.admin && ( // TODO remove when useless (should be useless after 2023)
                <MenuProgressAction
                    icon="Survey"
                    label="Transformer en mission"
                    handler={() => ApiV3.getInstance().post('questionnaires/from_album', {album_id: album.id})}
                />
            )}
            {isAdmin ? (
                <>
                    <li className="mu-dropdown-menu__separator" role="separator">&nbsp;</li>
                    <li className={classWithModifiers('mu-dropdown-menu__button-item', 'warning')}>
                        <button onClick={handleDeleteAlbum} type="button">
                            <TextIcon icon="Delete" label={i18n.t('album.dropdowns.delete')} />
                        </button>
                    </li>
                </>
            ) : !isMemberOfProject && (
                <>
                    <li className="mu-dropdown-menu__separator" role="separator">&nbsp;</li>
                    <li className={classWithModifiers('mu-dropdown-menu__button-item', 'warning')}>
                        <button onClick={handleLeaveAlbum} type="button">
                            <TextIcon icon="ClosePanel" label={i18n.t('album.dropdowns.leave')} />
                        </button>
                    </li>
                </>
            )}
        </DropdownMenu>
    );
};

export default AlbumDropdownMenu;
