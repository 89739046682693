import React, {useEffect, useState} from 'react';
import {Note, NoteRequests} from 'mushin-redux-store';
import i18n from 'i18next';
import MuButton from '../../../../Components/MuButton/MuButton';
import Modal from '../../../../Components/Modal/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {downloadFile} from '../../../../Helpers/download';
import Spinner from '../../../../Components/Spinner';
import {ModalBody} from '../../../../Components/Modal/ModalBody';

type Props = {
    onClose: () => void;
    notes: Note[];
};

const ExportNotesModal: React.FC<Props> = (
    {
        onClose,
        notes,
    }
) => {
    const [status, setStatus] = useState('pending');
    const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

    useEffect(() => {
        NoteRequests.export(notes.map((note) => note.id))
            .then((response) => {
                if (response.status === 202) {
                    setStatus('transaction');
                } else {
                    setStatus('done');
                    setDownloadUrl(response.data);
                }
            }).catch(() => setStatus('error'));
    }, []);

    const download = () => {
        if (downloadUrl) {
            downloadFile(downloadUrl);
            onClose();
        }
    };

    let catchPhrase;
    switch (status) {
        case 'transaction':
            catchPhrase = i18n.t('components.noteBucket.export.pending');
            break;
        case 'done':
            catchPhrase = i18n.t('components.noteBucket.export.done');
            break;
        case 'error':
            catchPhrase = i18n.t('components.noteBucket.export.unknown_error');
            break;
        default:
    }

    return (
        <Modal handleRemoveModal={onClose}>
            <ModalHeader title={i18n.t('components.noteBucket.export.generate')} icon="Export" />
            <ModalBody>
                {status === 'pending' ? (
                    <div className="mu-modal__spinner">
                        <Spinner size="50px" />
                    </div>
                ) : (
                    <div className="mu-modal__popup-content">
                        <p className="mu-modal__catchphrase">
                            {catchPhrase}
                        </p>
                        {downloadUrl && (
                            <MuButton
                                label={i18n.t('components.noteBucket.export.download')}
                                handleClick={download}
                                classModifiers={['large']}
                            />
                        )}
                        {(status === 'error' || status === 'transaction') && (
                            <MuButton
                                label={i18n.t('global.close')}
                                handleClick={onClose}
                                classModifiers={['large', 'default']}
                            />
                        )}
                    </div>
                )}
            </ModalBody>
        </Modal>
    );
};

export default ExportNotesModal;
