import React from 'react';
import { classWithModifiers } from '../../Helpers/css';
import CheckboxRadioBox from './CheckboxRadioBox';

const CheckboxRadio: React.FC<{
    className?: string;
    setChecked: (checked: boolean) => void;
    checked: boolean;
    label?: React.ReactNode;
    disabled?: boolean;
    uniqueSelection?: boolean;
    styleKey?: 'selection' | 'choice';
}> = ({
    className,
    checked,
    setChecked,
    label,
    disabled = false,
    uniqueSelection = false,
    styleKey = 'selection',
}) => {
    const modifiers: string[] = [styleKey];
    if (disabled) {
        modifiers.push('disabled');
    }
    if (checked) {
        modifiers.push('checked');
    }
    if (uniqueSelection) {
        modifiers.push('radio');
    }
    return (
        <button
            type="button"
            className={classWithModifiers('mu-checkbox', modifiers, className)}
            onClick={() => { if (!disabled) setChecked(!checked); }}
        >
            <CheckboxRadioBox
                checked={checked}
                disabled={disabled}
                uniqueSelection={uniqueSelection}
                styleKey={styleKey}
            />
            {label && (
                <div className="mu-checkbox__label">{label}</div>
            )}
        </button>
    );
};

export default CheckboxRadio;
