import React from 'react';
import { useProfiles } from 'mushin-redux-store';
import i18n from 'i18next';

type Props = {
    authorsIds: string[] | undefined;
}

const AuthorsValue: React.FC<Props> = ({ authorsIds }) => {
    const profiles = useProfiles(authorsIds);
    if (!profiles) return null;

    const selectedFirstnames = profiles.map((profile) => profile?.name.split(' ')[0]);

    if (selectedFirstnames.length === 0) {
        return null;
    }
    if (selectedFirstnames.length === 1) {
        return (<>{selectedFirstnames[0]}</>);
    }
    if (selectedFirstnames.length === 2) {
        return (<>{`${selectedFirstnames[0]} ${i18n.t('global.and')} ${selectedFirstnames[1]}`}</>);
    }

    return (
        <>
            {`${selectedFirstnames[0]}, ${selectedFirstnames[1]}`}
            {` ${i18n.t('global.and')} ${selectedFirstnames.length - 2} ${i18n.t('global.others')}`}
        </>
    );
};

export default AuthorsValue;
