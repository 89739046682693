import type { FormatRelativeFn, FormatRelativeFnOptions } from '../../../types';
import isSameUTCWeek from '../../../_lib/isSameUTCWeek';

const weekdays = [
    'domenica',
    'lunedì',
    'martedì',
    'mercoledì',
    'giovedì',
    'venerdì',
    'sabato',
];

function lastWeek(day: number): string {
    switch (day) {
        case 0:
            return '\'domenica scorsa alle\' p';
        default:
            return `'${weekdays[day]} scorso alle' p`;
    }
}

function thisWeek(day: number): string {
    return `'${weekdays[day]} alle' p`;
}

function nextWeek(day: number): string {
    switch (day) {
        case 0:
            return '\'domenica prossima alle\' p';
        default:
            return `'${weekdays[day]} prossimo alle' p`;
    }
}

const formatRelativeLocale = {
    lastWeek: (date: Date, baseDate: Date, options?: FormatRelativeFnOptions) => {
        const day = date.getUTCDay();
        if (isSameUTCWeek(date, baseDate, options)) {
            return thisWeek(day);
        } 
        return lastWeek(day);
    },
    yesterday: '\'ieri alle\' p',
    today: '\'oggi alle\' p',
    tomorrow: '\'domani alle\' p',
    nextWeek: (date: Date, baseDate: Date, options?: FormatRelativeFnOptions) => {
        const day = date.getUTCDay();
        if (isSameUTCWeek(date, baseDate, options)) {
            return thisWeek(day);
        } 
        return nextWeek(day);
    },
    other: 'P',
};

const formatRelative: FormatRelativeFn = (token, date, baseDate, options) => {
    const format = formatRelativeLocale[token];

    if (typeof format === 'function') {
        return format(date, baseDate, options);
    }

    return format;
};

export default formatRelative;
