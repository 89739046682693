import React, { Dispatch, SetStateAction } from 'react';
import {useDispatch} from 'react-redux';
import i18n from 'i18next';
import {classWithModifiers} from '../../../../Helpers/css';
import MuIcon from '../../../../Components/MuIcon';
import FormTextInput from '../../../../Components/Form/FormTextInput';
import {UseFieldState} from '../../../../Components/Form/common';
import {openModal} from '../../../../Redux/reducers/modalsSlice';

export type CoverImage = File

type Props = {
    coverImageState: [CoverImage | null | string, Dispatch<SetStateAction<CoverImage | null | string>>]
    coverVideoField?: UseFieldState<string>;
    crop?: boolean;
    removeImage?: () => void;
}

const CoverImageStep: React.FC<Props> = ({
    coverImageState: [coverImage, setCoverImage],
    coverVideoField,
    crop = true,
    removeImage,
}) => {
    const dispatch = useDispatch();

    const uploadCoverImage = (fileToCrop: File | undefined) => {
        if (fileToCrop) {
            if (crop) {
                dispatch(openModal('CropImageModal', {
                    submitAction: async (blob: Blob) => {
                        setCoverImage(new File([blob], fileToCrop.name));
                        return true;
                    },
                    fileToCrop,
                    title: i18n.t<string>('administration.organization.label'),
                    icon: 'Crop',
                    viewport: { width: 260, height: 130, type: 'square' },
                    targetWidth: 800,
                }));
            } else {
                setCoverImage(fileToCrop);
            }
        }
    };

    return (
        <>
            <div className="mu-cover-image-selector">
                <input
                    id="company-logo"
                    type="file"
                    className="mu-cover-image-selector__input"
                    onChange={(e) => uploadCoverImage(e.target.files?.[0])}
                    accept="image/*"
                />
                <label htmlFor="company-logo" className="mu-cover-image-selector__label">
                    {(coverImage) && (
                        <>
                            <button
                                type="button"
                                onClick={() => {
                                    if (removeImage) {
                                        removeImage();
                                    }
                                    setCoverImage(null);
                                }}
                                className="mu-cover-image-selector__remove-img"
                            >
                                <MuIcon svgName="Remove" />
                            </button>
                            <img
                                className={classWithModifiers('mu-cover-image-selector__image', ['img'])}
                                src={typeof coverImage === 'string' ? coverImage : URL.createObjectURL(coverImage)}
                                alt="chosen cover media"
                            />
                        </>
                    )}
                    <div className={classWithModifiers(
                        'mu-cover-image-selector__upload-block', {'with-image': !!coverImage}
                    )}
                    >
                        <MuIcon className="mu-cover-image-selector__upload-block-icon" svgName="Import" />
                        <p className="mu-cover-image-selector__upload-block-text">{i18n.t('global.importImage')}</p>
                    </div>
                </label>
            </div>
            {coverVideoField && (
                <FormTextInput
                    label={i18n.t('projects.coverVideo')}
                    field={coverVideoField}
                    placeholder={i18n.t('projects.coverVideoPlaceholder')}
                    video
                    contrast
                />
            )}
        </>

    );
};

export default CoverImageStep;
