import {
    RootState,
    StoreThunk,
    AsyncStoreThunk,
} from 'mushin-redux-store';
import appNotificationsReducer from './appNotificationsSlice';
import modalsReducer from './modalsSlice';
import moodboardEditorReducer from './moodboardEditorSlice';
import noteBucketReducer from './noteBucketSlice';
import lightboxReducer from './lightboxSlice';

export const customSlices = {
    appNotifications: appNotificationsReducer,
    modals: modalsReducer,
    moodboardEditor: moodboardEditorReducer,
    noteBucket: noteBucketReducer,
    lightbox: lightboxReducer,
};

export type AppRootState = RootState<typeof customSlices>;
export type AppThunk<Return = void> = StoreThunk<Return, typeof customSlices>;
export type AsyncAppThunk<Return = void> = AsyncStoreThunk<Return, typeof customSlices>;
