import {DependencyList, RefObject, useCallback, useEffect, useState} from 'react';

const getPositionInModals = (el: HTMLElement) => {
    let parent: HTMLElement | null = el;

    while (parent) {
        if (parent.parentElement?.id === 'app-portal') {
            return Array.prototype.indexOf.call(parent.parentElement.children, parent);
        }
        parent = parent.parentElement;
    }

    return -1;
};

export const useClickOutside = <T extends HTMLElement>(
    ref: RefObject<T>, isActive: boolean, handler: () => void
): void => {
    useEffect(() => {
        /**
         * Call handler if clicked on outside of element
         */
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                const targetPosition = getPositionInModals(event.target as HTMLElement);
                const refPosition = getPositionInModals(ref.current);
                if (refPosition >= targetPosition) {
                    handler();
                }
            }
        };

        if (isActive) {
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, isActive, handler]);
};

export const useScrollThreshold = <T extends HTMLElement>(
    handler: (() => void) | null,
    {threshold = 0.5, deps = []}: {threshold?: number, deps?: DependencyList} = {},
): (node: T | null) => void => {
    const [scrollElement, setScrollElement] = useState<T | null>(null);
    const [thresholdReached, setThresholdReached] = useState(false);

    const ref = useCallback((node: T | null) => {
        setScrollElement(node);
    }, []);

    const onScroll = useCallback(() => {
        if (!thresholdReached && handler && scrollElement) {
            const scrollTopMax = scrollElement.scrollHeight - scrollElement.offsetHeight;
            if (
                Math.abs(scrollElement.scrollTop) >= threshold * scrollTopMax
                || scrollTopMax < threshold * scrollElement.offsetHeight
            ) {
                setThresholdReached(true);
                handler();
            }
        }
    }, [handler, thresholdReached, scrollElement]);

    useEffect(() => {
        setThresholdReached(false);
    }, deps);

    useEffect(() => {
        if (!thresholdReached) {
            onScroll();
        }
    }, [thresholdReached, onScroll]);

    useEffect(() => {
        if (scrollElement) {
            scrollElement.addEventListener('scroll', onScroll);
        }
        return () => {
            if (scrollElement) {
                scrollElement.removeEventListener('scroll', onScroll);
            }
        };
    }, [
        scrollElement,
        onScroll,
    ]);

    return ref;
};
