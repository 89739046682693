export const haveSameElements = <T>(arr1: T[], arr2: T[] | null | undefined): boolean => {
    if (!arr2 || arr1.length !== arr2.length) return false;

    for (const el1 of arr1) {
        if (!arr2.includes(el1)) return false;
    }

    return true;
};

export const shuffleArray = <T>(questionItems: T[]): T[] => {
    const items = Array.from(questionItems);

    for (let i = items.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [items[i], items[j]] = [items[j], items[i]];
    }
    return items;
};
