import React from 'react';
import {ProfilesQueryParams, useProfilesStats} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import Spinner from '../../../../Components/Spinner';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalBody} from '../../../../Components/Modal/ModalBody';
import CriterionValueStat from './CriterionValueStat';

type Props = {
  queryParams: ProfilesQueryParams;
  onClose: () => void;
};

const ProfilesStatsModal: React.FC<Props> = ({
    queryParams,
    onClose,
}) => {
    const stats = useProfilesStats(queryParams);

    return (
        <Modal classModifiers={['large']} handleRemoveModal={onClose}>
            <ModalHeader title={`${i18n.t('criteria.items')} (${stats?.[0]?.total || 0})`} icon="Stats" />
            <ModalBody>
                {!stats ? (
                    <div className="mu-modal__spinner">
                        <Spinner size="50px" />
                    </div>
                ) : (
                    <>
                        <div className="mu-users-stats">
                            {stats.map((stat) => {
                                return (
                                    <CriterionValueStat
                                        key={stat._id}
                                        stat={stat}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}
            </ModalBody>
        </Modal>
    );
};

export default ProfilesStatsModal;
