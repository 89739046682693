import React from 'react';
import MuIcon from '../../../Components/MuIcon';

type Props = {
    label: React.ReactNode;
    icon: string;
    disabled?: boolean;
    onClick?: () => void;
};

export const BoardHeaderButton: React.FC<Props> = ({label, icon, disabled, onClick}) => (
    <button className="beToolBar-button" onClick={onClick} disabled={disabled} type="button">
        <div className="beToolBar-buttonIcon">
            <MuIcon svgName={icon} />
        </div>
        <div className="beToolBar-buttonLabel">{label}</div>
    </button>
);

export default BoardHeaderButton;
