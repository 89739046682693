/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import i18n from 'i18next';
import { useQuestionnaire, useQuestionnairesQuery } from 'mushin-redux-store';
import MuButton from '../../Components/MuButton';
import AppLayout from '../../Components/Layouts/AppLayout';
import { getQuestionnaireBaseUrl } from '../../Helpers/questionnaires';

type Params = {
    questionnaireId: string;
    projectId?: string;
};

const QuestionnaireAnswerSuccess: React.FC = () => {
    const { questionnaireId = '', projectId } = useParams<Params>();
    const navigate = useNavigate();

    const currentQuestionnaire = useQuestionnaire(questionnaireId);
    const questionnairesQuery = useQuestionnairesQuery({ project_id: projectId }, true);
    const firstQuestionnaire = useQuestionnaire(questionnairesQuery?.results?.[0]);

    const nextQuestionnaire = firstQuestionnaire && !firstQuestionnaire.answered ? firstQuestionnaire : undefined;

    return (
        <AppLayout
            title={i18n.t('missions.list')}
        >
            <div className="mu-questionnaire-answer-success">
                <h1 className="mu-questionnaire-answer-success__title">
                    {i18n.t('questionnaires.success.response.title')}
                </h1>
                <p className="mu-questionnaire-answer-success__text">
                    {i18n.t('questionnaires.success.response.description')}
                </p>
                <p className="mu-questionnaire-answer-success__emoji">{'\uD83D\uDC4F'}</p>
                <div className="mu-questionnaire-answer-success__action">
                    <MuButton
                        handleClick={() => {
                            if (nextQuestionnaire) {
                                const questionnaireUrl = getQuestionnaireBaseUrl(nextQuestionnaire, projectId);
                                navigate(questionnaireUrl);
                                return;
                            }
                            navigate(`/projects/${projectId}/missions`);
                        }}
                        label={nextQuestionnaire ? i18n.t('questionnaires.nextMission') : i18n.t('global.back')}
                    />
                    <MuButton
                        handleClick={() => {
                            if (!currentQuestionnaire) return;
                            const questionnaireUrl = getQuestionnaireBaseUrl(currentQuestionnaire, projectId);
                            navigate(`${questionnaireUrl}/questions/${currentQuestionnaire.questions[0].id}/answers`);
                        }}
                        label={i18n.t('questionnaires.seeAnswers')}
                    />
                </div>
            </div>
        </AppLayout>
    );
};

export default QuestionnaireAnswerSuccess;
