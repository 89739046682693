import React from 'react';
import { classWithModifiers } from '../../Helpers/css';

type Props = {
    width?: 'full' | '80' | '60' | '40' | '20' | '10' | 'sm' | 'md' | 'lg';
    height?: 'sm' | 'md' | 'lg' | 'xl';
    theme?: 'bg' | 'info' | 'primary';
    br?: boolean;
};

const ShimmerLine: React.FC<Props> = ({ width = 'full', height, theme, br = false }) => (
    <div
        className={classWithModifiers(
            'shimmer-line',
            [`w${width}`, `h${height}`, br && 'br'],
            classWithModifiers('shimmer-animate', theme),
        )}
    />
);

export default ShimmerLine;
