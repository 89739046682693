import Swal from 'sweetalert2';
import i18n from 'i18next';

export class GlobalAlerts {
    static showBrowserTooOldModal = () => {
        Swal.fire({
            title: i18n.t<string>('global.errors.browser_too_old.title'),
            text: i18n.t<string>('global.errors.browser_too_old.content'),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: i18n.t<string>('global.cancel'),
        }).then();
    };
}
