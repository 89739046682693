import React, {useCallback, useState} from 'react';
import {
    useNote,
    ProfilesQueryParams
} from 'mushin-redux-store';
import Lightbox from 'yet-another-react-lightbox';
import CarouselModal from '../CarouselModal/CarouselModal';
import ImageView from '../ImageView/ImageView';

type Props = {
    userMentionsQueryParams?: ProfilesQueryParams;
    noteId: string | undefined;
    setNoteId: (noteId: string | undefined) => void;
    noteIds: string[];
    loadMoreNotes?: () => void;
    active?: boolean;
}

const ImageViewModal: React.FC<Props> = (
    {
        userMentionsQueryParams,
        noteId,
        setNoteId,
        noteIds,
        loadMoreNotes,
        active,
    }
) => {
    const [fullscreen, setFullscreen] = useState(false);
    const currentNote = useNote(noteId);

    const noteIndex = noteId ? noteIds.indexOf(noteId) : -1;
    const previous = useCallback((): void => {
        if (noteIndex > 0) {
            setNoteId(noteIds[noteIndex - 1]);
        }
    }, [noteIds, noteIndex, setNoteId]);
    const next = useCallback((): void => {
        if (loadMoreNotes && noteIndex > noteIds.length - 5) loadMoreNotes();
        if (noteIndex > -1 && noteIndex < noteIds.length - 1) {
            setNoteId(noteIds[noteIndex + 1]);
        }
    }, [loadMoreNotes, noteIds, noteIndex, setNoteId]);

    const closeNoteView = (): void => {
        setNoteId(undefined);
    };

    return (
        <CarouselModal
            onClose={closeNoteView}
            open={!!noteId}
            active={active}
            previous={previous}
            next={next}
        >
            {currentNote && currentNote.type !== 'TEXT' && fullscreen && (
                <Lightbox
                    open={fullscreen}
                    close={() => setFullscreen(false)}
                    slides={[
                        { src: currentNote.image_src.original },
                    ]}
                />
            )}
            {currentNote && (
                <ImageView
                    className="mu-image-view--modal"
                    note={currentNote}
                    userMentionsQueryParams={userMentionsQueryParams}
                    setFullscreen={setFullscreen}
                    beforeDelete={() => {
                        if (noteIndex > -1 && noteIndex < noteIds.length - 1) {
                            next();
                        } else if (noteIndex > 0) {
                            previous();
                        } else {
                            closeNoteView();
                        }
                    }}
                />
            )}
        </CarouselModal>
    );
};

export default ImageViewModal;
