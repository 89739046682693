import React from 'react';
import {useParams} from 'react-router-dom';
import {Note as NoteType, useAlbum} from 'mushin-redux-store';
import ActionDropdownMenu from '../ActionDropdownMenu/ActionDropdownMenu';
import MuIcon from '../MuIcon';
import {useNoteActions} from '../../Helpers/noteHelpers';
import {LayoutBox, NotesGeometryOptions} from './NotesJustifiedGrid';
import {GalleryParams} from '../../Scenes/Gallery/types';

const geometryPadding = (options: NotesGeometryOptions): number => (
    typeof options.containerPadding === 'number' ? options.containerPadding : options.containerPadding?.right || 0
);
const geometryHBoxSpacing = (options: NotesGeometryOptions): number => (
    typeof options.boxSpacing === 'number' ? options.boxSpacing : options.boxSpacing?.horizontal || 0
);

export const isLastOfLine = (box: LayoutBox, options: NotesGeometryOptions): boolean => (
    (box.left + box.width + 1) >= (
        (options.containerWidth || 0) - geometryPadding(options) - 2 * geometryHBoxSpacing(options)
    )
);

type Props = {
    note: NoteType;
}

const NotesJustifiedGridItemActions: React.FC<Props> = ({note}) => {
    const { album_id } = useParams<GalleryParams>();
    const album = useAlbum(album_id);

    const actions = useNoteActions(note, album);

    return (
        <ActionDropdownMenu
            position="bottom"
            align="end"
            toggle={(<MuIcon svgName="MoreOptions" />)}
            items={actions}
        />
    );
};

export default NotesJustifiedGridItemActions;
