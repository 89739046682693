import React from 'react';
import i18n from 'i18next';
import ActionCard from '../../../../../Components/ActionCard/ActionCard';
import PanelSynthesis from '../../../../../Components/PanelSynthesis/PanelSynthesis';

const PanelSynthesisSolution: React.FC = () => {
    return (
        <div className="mu-synthesis__section-panel">
            <PanelSynthesis
                activeMode="Solutions"
                title={i18n.t<string>('SectionSolution.title')}
                description={i18n.t<string>('SectionSolution.text')}
            >
                <div className="mu-panel-synthesis__content">
                    <ActionCard
                        activeMode="Solutions"
                        create
                        createText={i18n.t<string>('solutions.create')}
                    />
                </div>
            </PanelSynthesis>
        </div>
    );
};
export default PanelSynthesisSolution;
