import React from 'react';
import EntitySelectCommon from './Components/EntitySelectCommon';
import EntitySelectMultipleValue from './Components/EntitySelectMultipleValue';
import EntitySelectInline from './Components/EntitySelectInline';

type Props<T> = {
    className?: string;
    values: string[] | undefined | null;
    options: string[];
    useItem: (id: string | undefined) => T | null;
    useItems: (ids: string[] | undefined) => (T | null)[] | undefined;
    onSelect: (entityIds: string[]) => void;
    getMoreResults: () => void;
    placeholder: string;
    getEntityName: (entity: T) => string;
    search?: (query: string) => void;
    noSearchDelay?: boolean;
    hideValue?: boolean;
    inline?: boolean;
}

type EntitySelect<T> = React.FC<Props<T>>

const EntitiesSelect = <T, >(
    {
        values,
        options,
        useItem,
        useItems,
        onSelect,
        getMoreResults,
        placeholder,
        getEntityName,
        className,
        search,
        noSearchDelay = false,
        hideValue = false,
        inline = false,
    }: Parameters<EntitySelect<T>>[0]
): ReturnType<EntitySelect<T>> => {
    const valuesMultiple = values || [];
    const onSelectMultiple = (id: string) => {
        const _values = [...valuesMultiple];
        const index = valuesMultiple.indexOf(id);
        if (index > -1) {
            _values.splice(index, 1);
            onSelect(_values);
        } else {
            _values.push(id);
            onSelect(_values);
        }
    };

    if (inline) {
        return (
            <EntitySelectInline
                className={className}
                values={values}
                placeholder={placeholder}
                options={options}
                useItem={useItem}
                onSelect={onSelectMultiple}
                getMoreResults={getMoreResults}
                getEntityName={getEntityName}
                search={search}
                noSearchDelay={noSearchDelay}
            />
        );
    }

    const optionsMultiple = options.filter((opt) => !values?.includes(opt));

    return (
        <EntitySelectCommon
            className={className}
            value={!hideValue && values?.length ? (
                <EntitySelectMultipleValue
                    getEntityName={getEntityName}
                    value={values}
                    useItems={useItems}
                />
            ) : (
                <>{placeholder}</>
            )}
            options={optionsMultiple}
            useItem={useItem}
            onSelect={onSelectMultiple}
            getMoreResults={getMoreResults}
            getEntityName={getEntityName}
            removable={!!values}
            onRemove={() => onSelect([])}
        />
    );
};

export default EntitiesSelect;
