import React, {useState} from 'react';
import {addNotesToAlbum, uploadFiles} from 'mushin-redux-store';
import {useAppDispatch} from '../../../../Helpers/hooks';
import {GlobalAlerts} from '../../../../Services/Alerts';
import {openModal} from '../../../../Redux/reducers/modalsSlice';
import {GalleryParams} from '../../types';

type GalleryDragAndDrop = {
    isDragging: boolean;
    dragEnterHandler: (e: React.DragEvent) => void;
    dragLeaveHandler: () => void;
    dropHandler: (e: React.DragEvent) => Promise<void>;
    dragOverHandler: (e: React.DragEvent) => void;
}

const useGalleryDragAndDrop = (params: GalleryParams): GalleryDragAndDrop => {
    const [draggingError, setDraggingError] = useState(false);
    const [isDragging, setDragging] = useState(false);
    let dragEnterCounter = 0;

    const dispatch = useAppDispatch();

    const dragEnterHandler = (e: React.DragEvent) => {
        e.preventDefault();
        dragEnterCounter += 1;
        if (!Array.isArray(e.dataTransfer.types)) {
            setDraggingError(true);
        } else if (e.dataTransfer.types.indexOf('Files') !== -1
            || !!e.dataTransfer.getData('mushin/image')
            || !!e.dataTransfer.getData('mushin/note-id')
        ) {
            setDragging(true);
        }
    };

    const dragOverHandler = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const dragLeaveHandler = () => {
        dragEnterCounter -= 1;
        if (dragEnterCounter === 0) {
            setDragging(false);
            setDraggingError(false);
        }
    };

    const dropHandler = async (e: React.DragEvent) => {
        e.preventDefault();
        if (draggingError && e.dataTransfer.files.length > 0) {
            GlobalAlerts.showBrowserTooOldModal();
            setDraggingError(false);
        } else {
            let files: FileList | File[] | null = null;
            if (e.dataTransfer.types.indexOf('Files') !== -1) {
                files = e.dataTransfer.files;
            } else if (e.dataTransfer.getData('mushin/image')) {
                const imageUrl = e.dataTransfer.getData('mushin/image');
                const file = await fetch(imageUrl);
                const blob = await file.blob();
                const imageNameRegexp = imageUrl.match(/\/([^/?]+)[^/]*$/);
                const image = await new File([blob], imageNameRegexp ? imageNameRegexp[1] : '', {type: blob.type});
                files = [image];
            } else if (e.dataTransfer.getData('mushin/note-id')) {
                if (params.album_id) {
                    dispatch(addNotesToAlbum(params.album_id, [e.dataTransfer.getData('mushin/note-id')]));
                }
            }
            if (files) {
                dispatch(uploadFiles(files, {
                    upload_source: 'WEBAPP',
                    organization_id: params.organizationId || undefined,
                    album_id: params.album_id || undefined,
                    questionnaire_id: params.questionnaireId || undefined,
                    question_id: params.questionId || undefined,
                }));
                dispatch(openModal('UploadFilesModal'));
            }

            setDragging(false);
        }
    };

    return {
        isDragging,
        dragEnterHandler,
        dragLeaveHandler,
        dropHandler,
        dragOverHandler,
    };
};

export default useGalleryDragAndDrop;
