import React, {useState} from 'react';
import i18n from 'i18next';
import {
    addMembersByEmailToCriterion,
    AvailableLocale,
    Criterion, ProfilesQueryParams,
    updateCriterion,
    useCurrentProjectId,
    useProfilesQuery
} from 'mushin-redux-store';
import Modal from '../../../../Components/Modal';
import {useTextInputWithDelay} from '../../../../Helpers/FormsHelpers/hooks';
import {useScrollThreshold} from '../../../../Helpers/effects';
import InputText from '../../../../Components/InputText/InputText';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import CriteriaEditModalValueSelect from './CriteriaEditModalValueSelect';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton';
import {multipleValidate} from '../../../../Components/Form/validation';
import {useFieldState} from '../../../../Components/Form/common';
import FormTextInput from '../../../../Components/Form/FormTextInput';
import {useAppDispatch} from '../../../../Helpers/hooks';
import EntityRow from '../../../../Components/EntityRow/EntityRow';
import {openModal} from '../../../../Redux/reducers/modalsSlice';
import CheckboxRadio from '../../../../Components/Form/CheckboxRadio';

type Props = {
    onClose: () => void;
    criterion: Criterion;
};

const CriteriaEditModal: React.FC<Props> = ({ onClose, criterion }) => {
    const [currentProjectId] = useCurrentProjectId();
    const query: ProfilesQueryParams = {
        project_id: currentProjectId,
        type: 'contributor',
        sort_by_criterion_id: criterion.id,
    };

    const [onlyProject, setOnlyProject] = useState(!!criterion.project_id);
    const nameField = useFieldState(criterion.name);

    const profilesQuery = useProfilesQuery(query, true);
    const [currentQuery, setCurrentQuery] = useTextInputWithDelay((value) => {
        profilesQuery.setParams({
            ...query,
            name_search: value || undefined,
        });
    });

    const dispatch = useAppDispatch();
    const ref = useScrollThreshold(profilesQuery.getMoreResults, {deps: [profilesQuery.results?.length]});

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={onClose}>
            <ModalHeader
                subtitle={i18n.t('modals.EditCriterion.subtitle')}
                title={i18n.t('modals.EditCriterion.title')}
                icon="Filter"
            />
            <FormTextInput
                label={i18n.t('global.name')}
                placeholder={i18n.t('global.name')}
                field={nameField}
                required
                contrast
            />
            {currentProjectId && (
                <CheckboxRadio
                    checked={onlyProject}
                    setChecked={(checked) => {
                        setOnlyProject(checked);
                    }}
                    label={i18n.t('modals.CreateCriterion.projectOnly')}
                    styleKey="choice"
                />
            )}
            <div className="mu-modal__label mu-modal__label--with-button">
                <h2>{i18n.t('users.add')}</h2>
                <MuButton
                    icon="UserAdd"
                    handleClick={() => {
                        dispatch(openModal('CriteriaAddUsersModal', {
                            criterion,
                            handleSubmit: async (emails: string[], criterionValues: string | string[], locale: AvailableLocale) => {
                                return dispatch(
                                    addMembersByEmailToCriterion(criterion.id, {
                                        project_id: currentProjectId || '',
                                        emails,
                                        value: criterionValues,
                                        locale,
                                    })
                                );
                            },
                        }));
                    }}
                    label={i18n.t('modals.inviteUsers.title')}
                />
            </div>
            <InputText
                classModifiers={['full-width']}
                value={currentQuery}
                onChange={(event) => setCurrentQuery(event.target.value)}
                placeholder={i18n.t('components.filters.search')}
                contrast
            />
            <div ref={ref} className="util-modal-oflow-auto">
                {profilesQuery.results?.map((id) => (
                    <EntityRow
                        key={id}
                        id={id}
                        useRoles={false}
                        rightChildren={(
                            <CriteriaEditModalValueSelect criterion={criterion} profileId={id} />
                        )}
                    />
                ))}
            </div>
            <ModalFooter>
                <MuButton
                    handleClick={async () => {
                        if (multipleValidate([nameField.validate])) {
                            await dispatch(updateCriterion(criterion.id, {
                                name: nameField.value,
                                project_id: onlyProject ? currentProjectId as string : null,
                            }));
                            onClose();
                        }
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>
    );
};

export default CriteriaEditModal;
