import React from 'react';
import {useCriteria} from 'mushin-redux-store';
import EntitySelectMultipleValue from '../../../EntitySelect/Components/EntitySelectMultipleValue';
import { t_prop } from '../../../../Helpers/translate';
import {CriteriaSelectedValues} from './CriteriaDropdownPanel';

type Props = {
    values: CriteriaSelectedValues;
}

const CriteriaValue: React.FC<Props> = ({ values }) => {
    return (
        <EntitySelectMultipleValue
            value={values.map((v) => v.id)}
            getEntityName={(criterion, index) => {
                const valueLabels = values[index].values.map((valueSlug) => (
                    criterion.values.find((value) => value.slug === valueSlug)?.label
                ));
                return `${t_prop(criterion.titles, criterion.name)} : ${valueLabels.join(',')}`;
            }}
            useItems={useCriteria}
            separator=" ; "
        />
    );
};

export default CriteriaValue;
