import { ReactNode, useState } from 'react';
import i18n from 'i18next';

type UseFormFieldParams = {
    validator?: (value: string) => string | ReactNode;
    required?: boolean;
    trimOnBlur?: boolean;
    initialValue?: string;
}

type UseFormFieldReturn = [
    string,
    (value: string) => void,
    string,
    () => void,
    (value: string) => void,
]

export const useFormField = (
    {validator, required, trimOnBlur, initialValue } : UseFormFieldParams
): UseFormFieldReturn => {
    const [value, setValue] = useState(initialValue || '');
    const [error, setError] = useState('');

    const validate = () => {
        if (required && !value) setError(i18n.t<string>('global.errors.inputTextError')); // validation
        else if (validator) setError(validator(value) as string);
        else setError('' as string);
        if (trimOnBlur) setValue(value.trim());
    };

    return [value, setValue, error, validate, setError];
};
