import {Organization, Project, Me, Questionnaire} from 'mushin-redux-store';
import {store} from '../../Redux/store';

class Client {
    static getId(defaultValue: string | null = null): string | null {
        return store.getState().auth.user?.id || defaultValue;
    }

    static isConfirmed(): boolean {
        return !!store.getState().auth.user?.confirmed_at;
    }

    static getUser(): Me | undefined {
        return store.getState().auth.user;
    }

    static getOrganizationId(): string | undefined {
        return store.getState().auth.currentOrganizationId;
    }

    static getOrganization(): Organization | null | undefined {
        const {currentOrganizationId} = store.getState().auth;
        if (!currentOrganizationId) return undefined;
        return store.getState().auth.organizations[currentOrganizationId];
    }

    static isOrganizationContributor(organizationId?: string): boolean {
        const user = Client.getUser();
        const oid = organizationId || Client.getOrganization()?.id;
        if (user && oid) {
            return user.profiles?.some((profile) => (
                profile.organization_id === oid && profile.type === 'contributor'
            )) || false;
        }
        return false;
    }

    static isOrganizationTeamMember(): boolean {
        const user = Client.getUser();
        const organization = Client.getOrganization();
        if (user && organization) {
            return user.profiles?.some((profile) => (
                profile.organization_id === organization.id && profile.type === 'team'
            )) || false;
        }
        return false;
    }

    static isAllowedToEditOrganization(organizationId?: string): boolean {
        const user = Client.getUser();
        const oid = organizationId || Client.getOrganization()?.id;
        if (user && oid) {
            const profile = user.profiles?.find((p) => p.organization_id === oid);
            if (profile) {
                return profile.role === 'owner' || profile.role === 'admin';
            }
        }
        return false;
    }

    static isAllowedToCreateProject(): boolean {
        const user = Client.getUser();
        const organization = Client.getOrganization();
        if (user && organization) {
            const profile = user.profiles?.find((p) => p.organization_id === organization.id);
            if (profile) {
                return profile.role === 'owner' || profile.role === 'admin' || profile.role === 'creator';
            }
        }
        return false;
    }

    static getProjectRole(project: Project): string | undefined {
        return project.profile?.role;
    }

    static isTeamMemberOfProject(project: Project): boolean {
        return Client.isAllowedToEditOrganization(project.organization_id) || (
            project.profile?.type === 'team'
            && !!project.profile?.accepted_at
        );
    }

    static isAllowedToEditProject(project: Project): boolean {
        const userRole = Client.getProjectRole(project);
        return (
            Client.isAllowedToEditOrganization(project.organization_id)
            || userRole === 'admin'
            || userRole === 'owner'
        );
    }

    static isAllowedToEditQuestionnaire(questionnaire: Questionnaire): boolean {
        return (
            Client.isAllowedToEditOrganization()
            || questionnaire.admins.includes(Client.getId() as string)
        );
    }
}

export default Client;
