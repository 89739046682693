import React, {useState} from 'react';
import {createCriterion, useCurrentOrganizationId, useCurrentProjectId} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton';
import {useFieldState} from '../../../../Components/Form/common';
import {multipleValidate} from '../../../../Components/Form/validation';
import {useAppDispatch} from '../../../../Helpers/hooks';
import {openQuitWithoutSavingAlert} from '../../../../Redux/reducers/modalsSlice';
import FormTextInput from '../../../../Components/Form/FormTextInput';
import CheckboxRadio from '../../../../Components/Form/CheckboxRadio';
import MuIcon from '../../../../Components/MuIcon';
import {classWithModifiers} from '../../../../Helpers/css';
import InputText from '../../../../Components/InputText/InputText';

type Props = {
    onClose: () => void;
};

const CriteriaCreationModal: React.FC<Props> = (
    {
        onClose,
    }
) => {
    const nameField = useFieldState('');
    const [onlyProject, setOnlyProject] = useState(false);
    const [multiple, setMultiple] = useState<boolean>(false);
    const [values, setValues] = useState<string[]>([]);
    const [newValue, setNewValue] = useState('');

    const [currentProjectId] = useCurrentProjectId();
    const [currentOrganizationId] = useCurrentOrganizationId();
    const dispatch = useAppDispatch();

    const addValue = (value: string) => {
        const cleanValue = value.trim();
        if (cleanValue) {
            setNewValue('');
            setValues((prevValues) => {
                return [...prevValues, cleanValue];
            });
        }
    };

    const updateValue = (value: string, index: number) => {
        setValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[index] = value;
            return updatedValues;
        });
    };

    const deleteValue = (index: number) => {
        setValues((prevValues) => {
            const updatedItems = [...prevValues];
            updatedItems.splice(index, 1);
            return updatedItems;
        });
    };

    const handleClose = () => {
        dispatch(openQuitWithoutSavingAlert(
            !!nameField.value
            || !!values.length,
            onClose,
        ));
    };

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={handleClose}>
            <ModalHeader
                subtitle={i18n.t('modals.CreateCriterion.subtitle')}
                title={i18n.t('modals.CreateCriterion.title')}
                icon="Filter"
            />
            <div className="util-modal-oflow-auto">
                <FormTextInput
                    label={i18n.t('global.name')}
                    placeholder={i18n.t('global.name')}
                    field={nameField}
                    required
                    contrast
                />
                {currentProjectId && (
                    <CheckboxRadio
                        checked={onlyProject}
                        setChecked={(checked) => {
                            setOnlyProject(checked);
                        }}
                        label={i18n.t('modals.CreateCriterion.projectOnly')}
                        styleKey="choice"
                    />
                )}
                <CheckboxRadio
                    checked={multiple}
                    setChecked={(checked) => {
                        setMultiple(checked);
                    }}
                    label={i18n.t('modals.CreateCriterion.multiple')}
                    styleKey="choice"
                />
                {values.map((value, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="mu-form-row">
                        <InputText
                            classModifiers={['full-width']}
                            onChange={(e) => updateValue(e.currentTarget.value, index)}
                            value={value}
                            placeholder={i18n.t('questionEdition.changeLabelItem') as string}
                            contrast
                        />
                        <button
                            className="mu-edit-multiple-choice-item__action"
                            type="button"
                            onClick={() => deleteValue(index)}
                        >
                            <MuIcon
                                className="mu-edit-multiple-choice-item__icon"
                                svgName="Remove"
                            />
                        </button>
                    </div>
                ))}
                <div className={classWithModifiers('mu-edit-multiple-choice-item', ['item-to-add'])}>
                    <div className="mu-edit-multiple-choice-item__item">
                        <InputText
                            classModifiers={['full-width']}
                            placeholder={i18n.t('questionEdition.addItem') as string}
                            value={newValue}
                            onChange={(e) => setNewValue(e.currentTarget.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' || event.key === 'Tab') {
                                    if (event.currentTarget.value) {
                                        event.preventDefault();
                                        addValue(event.currentTarget.value);
                                    }
                                }
                            }}
                            onBlur={(event) => {
                                addValue(event.currentTarget.value);
                            }}
                            onPaste={(event) => {
                                const labels = event.clipboardData.getData('Text').split('\n');
                                const lastLabel = labels.pop();
                                for (const label of labels) {
                                    addValue(label);
                                }
                                if (lastLabel) {
                                    setNewValue(lastLabel);
                                }
                                event.preventDefault();
                            }}
                            contrast
                        />
                    </div>
                </div>
            </div>
            <ModalFooter>
                <MuButton
                    handleClick={async () => {
                        if (multipleValidate([nameField.validate])) {
                            await dispatch(createCriterion({
                                organization_id: currentOrganizationId as string,
                                project_id: onlyProject ? currentProjectId as string : null,
                                name: nameField.value,
                                multiple,
                                values,
                            }));
                            onClose();
                        }
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default CriteriaCreationModal;
