import React from 'react';
import {classWithModifiers} from '../../Helpers/css';

type Props = {
    totalItems: number,
    totalDone: number,
}

const MissionsRatio: React.FC<Props> = ({ totalItems, totalDone }) => (
    <div
        className={classWithModifiers('mu-missions-ratio', {
            completed: totalItems === totalDone, empty: totalItems !== 0 && totalDone === 0,
        })}
    >
        {totalDone}
        /
        {totalItems}
    </div>
);

export default MissionsRatio;
