import React, { useState } from 'react';
import Delta from 'quill-delta';
import { useTranslation } from 'react-i18next';
import { Project, createNews, useNewsQuery } from 'mushin-redux-store';
import Modal from '../../../../Components/Modal';
import { ModalBody } from '../../../../Components/Modal/ModalBody';
import QuillRichInput from '../../../../Components/QuillRichInput/QuillRichInput';
import { useAppDispatch } from '../../../../Helpers/hooks';
import NewsList from '../../../../Components/NewsList/NewsList';
import { isProjectAdmin } from '../../../../Helpers/users';

type Props = {
    onClose: () => void;
    project: Project;
};

const AddNewsModal: React.FC<Props> = ({ onClose, project }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState(new Delta([]));
    const [error, setError] = useState(false);

    const newsQuery = useNewsQuery({ project_id: project.id });

    const handleAddNews = async () => {
        try {
            if (!title) {
                setError(true);
                return;
            }
            setError(false);

            setLoading(true);
            await dispatch(
                createNews({
                    title,
                    content,
                    project_id: project.id,
                }),
            );
            setTitle('');
            setContent(new Delta([]));
        } finally {
            setLoading(false);
        }
    };

    const isAdmin = isProjectAdmin(project);

    return (
        <Modal classModifiers={['large']} handleRemoveModal={onClose}>
            <ModalBody>
                <div className="mu-add-news-modal">
                    {isAdmin && (
                        <>
                            <input
                                type="title"
                                placeholder={t('news.titlePlaceholder') || ''}
                                className="mu-add-news-modal__title-input"
                                value={title}
                                onChange={(e) => setTitle(e.currentTarget.value)}
                            />
                            {error && <div className="mu-add-news-modal__error">{t('news.error.title')}</div>}
                            <QuillRichInput
                                value={content}
                                setValue={(e) => setContent(e)}
                                placeholder={t('news.descPlaceholder') || ''}
                                theme="snow"
                                className="mu-textarea-rich-input mu-add-news-modal__content-input"
                                needTags={false}
                            />
                            <button
                                className="mu-add-news-modal__add-button"
                                onClick={handleAddNews}
                                disabled={loading}
                                type="button"
                            >
                                {t('news.add')}
                            </button>
                        </>
                    )}
                    <NewsList
                        news={newsQuery.results || []}
                        pager={newsQuery.pager}
                        getMore={newsQuery.getMoreResults}
                    />
                </div>
            </ModalBody>
        </Modal>
    );
};

export default AddNewsModal;
