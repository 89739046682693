import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import i18n from 'i18next';
import SessionsLayout from '../Components/SessionsLayout/SessionsLayout';
import CodeRequest from '../CodeRequest/CodeRequest';
import {useRedirectIfConfirmed} from '../../../Helpers/users';
import {confirmUrl, loginUrl} from '../helpers';
import {useSearchParams} from '../../../Helpers/queryParser';
import MuButton from '../../../Components/MuButton';
import useCodeRequest from '../CodeRequest/useCodeRequest';
import {useFieldState} from '../../../Components/Form/common';
import FormTextInput from '../../../Components/Form/FormTextInput';

const ForgotPassword: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams<{landing?: string; email?: string}>();
    const email = useFieldState(searchParams.email || '');
    const codeRequest = useCodeRequest();
    const navigate = useNavigate();

    useRedirectIfConfirmed();

    if (searchParams.email) {
        return (
            <SessionsLayout title={i18n.t('accounts.reset_password.title')}>
                <CodeRequest
                    email={searchParams.email}
                    request={codeRequest}
                    onValidate={(credentials) => {
                        if (credentials.confirmed) {
                            navigate(
                                searchParams.landing
                                    ? `/forgot_password/edit?landing=${searchParams.landing}`
                                    : '/forgot_password/edit'
                            );
                        } else {
                            navigate(confirmUrl(searchParams.landing));
                        }
                    }}
                />
            </SessionsLayout>
        );
    }

    return (
        <SessionsLayout title={i18n.t('accounts.reset_password.title')}>
            <p className="mu-sessions-content__catchphrase">{i18n.t('accounts.signUp.enterEmail')}</p>
            <section className="mu-form-row">
                <div className="mu-form-row__group">
                    <FormTextInput
                        placeholder={i18n.t('models.users.email')}
                        field={email}
                        autoCapitalize="none"
                        required
                        email
                    />
                </div>
            </section>
            <MuButton
                className="mu-sessions__submit-button"
                label={i18n.t('global.validate')}
                handleClick={async (): Promise<void> => {
                    if (email.validate()) {
                        await codeRequest.send(email.value);
                        setSearchParams((params) => ({...params, email: email.value.trim()}));
                    }
                }}
            />
            <Link to={loginUrl(searchParams.landing) || '/login'} className="mu-form-row__link mu-link">
                {i18n.t('global.back')}
            </Link>
        </SessionsLayout>
    );
};

export default ForgotPassword;
