import React, {useState} from 'react';
import {inviteUser, Profile} from 'mushin-redux-store';
import i18n from 'i18next';
import Popover from '../Popover/Popover';
import MuIcon from '../MuIcon';
import {useAppDispatch} from '../../Helpers/hooks';

type Props = {
    profile: Profile;
    contributors?: boolean;
    projectId?: string;
}

const UserReInviteAction: React.FC<Props> = ({ profile, contributors = false, projectId }) => {
    const [invited, setInvited] = useState(false);

    const dispatch = useAppDispatch();

    if (!profile.user || profile.metrics.first_activity_at) {
        return null;
    }

    const handleClick = async () => {
        try {
            if (profile.user?.email) {
                await dispatch(inviteUser(
                    [profile.user?.email],
                    {reInvite: true, isContributor: contributors, projectId},
                ));
            }
            setInvited(true);
            setTimeout(() => setInvited(false), 5000);
        } catch (err) {
            /* */
        }
    };

    return invited ? (
        <Popover position="bottom" hoveredElement={<MuIcon className="mu-entity-row__action-info" svgName="Check" />}>
            {i18n.t('administration.members.inviteSent')}
        </Popover>
    ) : (
        <Popover
            position="bottom"
            hoveredElement={(
                <button
                    className="mu-entity-row__action-link"
                    type="button"
                    onClick={handleClick}
                >
                    <MuIcon svgName="Redo" />
                </button>
            )}
        >
            {i18n.t('administration.members.resendInvite')}
        </Popover>
    );
};

export default UserReInviteAction;
