import React from 'react';
import i18n from 'i18next';
import {
    Questionnaire,
    QuestionnaireAccessType,
    updateQuestionnaire
} from 'mushin-redux-store';
import Select from '../../../Components/Select/Select';
import MuButton from '../../../Components/MuButton';
import {openModal} from '../../../Redux/reducers/modalsSlice';
import {hasStartedQuestionnaire} from '../../../Helpers/questionnaires';
import SelectOption from '../../../Components/Select/SelectOption';
import {useAppDispatch} from '../../../Helpers/hooks';
import Client from '../../../Services/Client';
import {criteriaFilter} from '../../../Components/FiltersToolbar/helpers';
import DefaultFilter from '../../../Components/FiltersToolbar/DefaultFilter';

type Props = {
    questionnaire: Questionnaire;
}

const AccessTypeSelect: React.FC<Props> = ({questionnaire}) => {
    const dispatch = useAppDispatch();

    if (questionnaire.candidacy || !Client.isAllowedToEditQuestionnaire(questionnaire)) return null;

    const canEdit = !hasStartedQuestionnaire(questionnaire);

    const renderAccessTypeOption = (accessType: QuestionnaireAccessType) => (
        <SelectOption
            value={accessType}
            onSelect={() => dispatch(updateQuestionnaire(questionnaire.id, { accessType }))}
            selectedValue={questionnaire.accessType}
        >
            {i18n.t(`questionnaires.accessType.${accessType}`)}
        </SelectOption>
    );

    return (
        <div className="mu-edit-questionnaire__access-type">
            <Select
                className="mu-edit-questionnaire__access-type-select mu-edit-questionnaire__header-select"
                value={i18n.t<string>(`questionnaires.accessType.${questionnaire.accessType}`)}
                disabled={!canEdit}
            >
                {renderAccessTypeOption('public')}
                {questionnaire.project_id && renderAccessTypeOption('project')}
                {renderAccessTypeOption('criteria')}
                {renderAccessTypeOption('list')}
            </Select>
            {questionnaire.accessType === 'criteria' && (
                <DefaultFilter
                    config={criteriaFilter(
                        questionnaire.selectedCriteria,
                        (criteria) => dispatch(updateQuestionnaire(questionnaire.id, { selectedCriteria: criteria }))
                    )}
                />
            )}
            {questionnaire.accessType === 'list' && (
                <MuButton
                    label={`${i18n.t('questionnaires.selectUsers')} (${questionnaire.participants?.length})`}
                    handleClick={() => {
                        dispatch(openModal('SelectContributors', {
                            title: i18n.t<string>('questionnaires.selectUsers'),
                            initialUsers: questionnaire.selectedParticipants || [],
                            onSelect: async (selectedParticipants: string[]): Promise<boolean> => {
                                await dispatch(updateQuestionnaire(questionnaire.id, { selectedParticipants }));
                                return true;
                            },
                        }));
                    }}
                />
            )}
        </div>
    );
};

export default AccessTypeSelect;
