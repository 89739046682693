import React from 'react';
import i18n from 'i18next';
import SessionsLayout from '../Components/SessionsLayout/SessionsLayout';
import SignInModal from './SignInModal/SignInModal';

const Login: React.FC = () => (
    <SessionsLayout title={i18n.t('accounts.login.title')} catchphrase={i18n.t('accounts.login.catchphrase')}>
        <SignInModal />
    </SessionsLayout>
);

export default Login;
