import React from 'react';
import {useAlbumsQuery, useProject} from 'mushin-redux-store';
import {useParams} from 'react-router-dom';
import i18n from 'i18next';
import IntelligentSummary from './SyntheComponentsDash/IntelligentSummary';
import DownloadSynthesis from './SyntheComponentsDash/DownloadSynthesis';
import ClusterList from '../Clusters/ClusterComponents/ClusterList';
import SectionPanelSynthesis from './SyntheComponentsDash/SectionPanelSynthesis';
import PanelSynthesisSolution from './SyntheComponentsDash/PanelSynthesisSolution';

const SynthesisDashboard: React.FC = () => {
    const {projectId} = useParams<{ projectId: string }>();
    const project = useProject(projectId);
    const albumsQuery = useAlbumsQuery({
        project_id: projectId || '',
    });

    if (!project) return null;

    return (
        <div className="mu-app-layout__scroll mu-synthesis">
            <IntelligentSummary project={project} />
            <DownloadSynthesis />
            <p className="mu-synthesis__section-header mu-panel-synthesis__content-text">
                {i18n.t('TextSynthesisClusters.text')}
                <span className="mu-synthesis__textp--span">
                    &quot;
                    {i18n.t('global.Clusters').toLowerCase()}
                    &quot;
                </span>
                {i18n.t('TextSynthesisClusters.text2')}
            </p>
            <ClusterList project={project} albumIds={albumsQuery.results} />
            <SectionPanelSynthesis />
            <PanelSynthesisSolution />
        </div>
    );
};
export default SynthesisDashboard;
