import { Box, RawTemplateTree } from 'mushin-node-moodboard';
import { Template } from '../BoardLayout/type';

/**
 * compute the n-th sub box
 */
const computeSubBox = (containerBox: Box, axe: 'x' | 'y', sizes: number[], i: number) => {
    // duplicate
    const s = {
        ...containerBox,
        max: { ...containerBox.max },
        min: { ...containerBox.min },
    };

    const w = containerBox.max[axe] - containerBox.min[axe];

    const min = sizes.slice(0, i).reduce((sum, x) => sum + x, 0);
    const max = sizes.slice(0, i + 1).reduce((sum, x) => sum + x, 0);

    s.min[axe] = w * min + containerBox.min[axe];
    s.max[axe] = w * max + containerBox.min[axe];

    return s;
};

/**
 * merge two template a and b
 */
const mergeTemplate = (a, b) => ({
    ids: [...a.ids, ...b.ids],
    cells: { ...a.cells, ...b.cells },
    contents: { ...a.contents, ...b.contents },
});

/**
 * rec build
 */
const build_ = (tree, box: Box, id = '0') => {
    if (!tree || !tree.children) {
        if (tree && tree.type) {
            return {
                cells: { [id]: box },
                contents: { [id]: tree },
                ids: [id],
            };
        }
        return {
            cells: {},
            contents: {},
            ids: [],
        };
    }

    return tree.children.map((child, i) => build_(child, computeSubBox(box, tree.axe, tree.sizes || tree.children.map(() => 1 / tree.children.length), i), `${id}-${i}`)).reduce(mergeTemplate, { cells: {}, contents: {}, ids: [] });
};

export const build = (tree, width: number, height: number, margin = 0): Template => {
    const box = {
        min: { x: margin / 2, y: margin / 2 },
        max: { x: width - margin / 2, y: height - margin / 2 },
    };

    const res = build_(tree, box);

    res.ids.forEach((id) => {
        res.cells[id].max.x -= margin / 2;
        res.cells[id].min.x += margin / 2;
        res.cells[id].max.y -= margin / 2;
        res.cells[id].min.y += margin / 2;
    });

    return { ...res, width, height, margin };
};

export const buildWithMarginTop = (tree: RawTemplateTree, width: number, height: number, margin: number): Template => {
    const marginTop = 20 - margin;

    const res = build(tree, width, height - marginTop, margin);

    res.ids.forEach((id) => {
        res.cells[id].max.y += marginTop;
        res.cells[id].min.y += marginTop;
    });

    res.height = height;

    return res;
};

/**
 *  return the number of empty spot for this rawTemplateTree
 */
export const getTemplateSpotCount = (tree: RawTemplateTree) => (!tree ? 0 : tree.children ? tree.children.reduce((sum, tree) => sum + getTemplateSpotCount(tree), 0) : 1);
