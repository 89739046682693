import React from 'react';
import {exportNote, ExportNotesTransaction, waitTransaction} from 'mushin-redux-store';
import i18n from 'i18next';
import Spinner from '../../../Components/Spinner';
import TextIcon from '../../../Components/TextIcon/TextIcon';
import {classWithModifiers} from '../../../Helpers/css';
import {downloadFile} from '../../../Helpers/download';
import {useAppDispatch, useAppSelector} from '../../../Helpers/hooks';
import {saveMoodboard} from '../../../Redux/reducers/moodboardEditorSlice';

const download = async (noteId: string, title: string): Promise<void> => {
    const response = await exportNote(noteId);
    let url: string;

    if (!response.async) {
        url = response.result;
    } else {
        const transaction = (await waitTransaction(response.result.id)) as ExportNotesTransaction;
        if (!transaction.result) throw new Error('Transaction has no result');
        url = transaction.result.url;
    }

    downloadFile(url, `${title}.pdf`);
};

type Props = {
    inProgress: boolean;
    setInProgress: (value: boolean) => void;
    title: string;
}

const DownloadDropdownItemAll: React.FC<Props> = ({
    inProgress,
    setInProgress,
    title,
}) => {
    const noteId = useAppSelector((state) => state.moodboardEditor.present.noteId);
    const dispatch = useAppDispatch();

    if (!noteId) return null;

    return (
        <button
            type="button"
            disabled={inProgress}
            className={classWithModifiers('beDownload-item', {rendering: inProgress})}
            onClick={async () => {
                setInProgress(true);
                await dispatch(saveMoodboard(title));
                await download(noteId, title);
                setInProgress(false);
            }}
        >
            <div className="mu-toolbar__export">
                <TextIcon
                    label={`${i18n.t('components.boardEditor.all')} : PDF`}
                    icon="Pdf"
                />
                {inProgress && <Spinner size="12px" />}
            </div>
        </button>
    );
};

export default DownloadDropdownItemAll;
