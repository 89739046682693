import React, {useEffect, useState} from 'react';
import {
    Me,
    useCurrentOrganizationId, useCurrentProjectId,
    useMe,
    useProjectsQuery, useTimelineQuery,
} from 'mushin-redux-store';
import {useNavigate} from 'react-router-dom';
import i18n from 'i18next';
import {useAppDispatch} from '../../Helpers/hooks';
import ProjectPreviewBlock from '../../Components/ProjectPreviewBlock/ProjectPreviewBlock';
import { OrganizationAvatar } from '../../Components/OrganizationAvatar/OrganizationAvatar';
import Client from '../../Services/Client';
import GridButtonCard from '../../Components/GridButtonCard/GridButtonCard';
import MuIcon from '../../Components/MuIcon';
import Popover from '../../Components/Popover/Popover';
import Projects from '../Projects/Projects';
import {openModal} from '../../Redux/reducers/modalsSlice';
import AppLayout from '../../Components/Layouts/AppLayout';
import {useScrollThreshold} from '../../Helpers/effects';
import Spinner from '../../Components/Spinner';

const ProjectSelector: React.FC = () => {
    const [, setCurrentProjectId] = useCurrentProjectId();
    const me = useMe() as Me;
    const [currentOrganizationId, setCurrentOrganizationId] = useCurrentOrganizationId();
    const timelineQuery = useTimelineQuery({ userId: me?.id as string }, true);
    const [advancedSearch, setAdvancedSearch] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const projectsQuery = useProjectsQuery({
        organization_id: currentOrganizationId,
        archived: false,
    }, true);

    const {setParams} = projectsQuery;

    useEffect(() => {
        setParams({
            organization_id: currentOrganizationId,
            archived: false,
        }, true);
    }, [currentOrganizationId, setParams]);

    const ref = useScrollThreshold(projectsQuery.getMoreResults, {deps: [projectsQuery.results?.length]});

    if (!me) return null;

    const organizationIds = Array.from(new Set(me.profiles?.map((profile) => profile.organization_id) || []));

    const getNotificationCount = (id: string) => {
        const notificationCount = timelineQuery.projects_unseen?.find(
            (projectUnseen) => projectUnseen._id.project_id === id,
        );
        return notificationCount ? notificationCount.count : 0;
    };

    return (
        <AppLayout
            title={(
                <>
                    <span>
                        {i18n.t('projectSelector.title.0')}
                        <span className="mu-project-selector__title--red">{i18n.t('projectSelector.title.1')}</span>
                    </span>
                    <Popover
                        className="mu-project-selector__switch-view"
                        hoveredElement={(
                            <button
                                type="button"
                                onClick={() => setAdvancedSearch((prevState) => !prevState)}
                            >
                                <MuIcon svgName={advancedSearch ? 'Dashboard' : 'Search'} />
                            </button>
                        )}
                    >
                        {i18n.t(advancedSearch ? 'global.dashboard' : 'global.advancedSearch')}
                    </Popover>
                </>
            )}
        >
            <div ref={ref} className="mu-app-layout__scroll mu-project-selector">
                {!advancedSearch && (<p className="mu-project-selector__desc">{i18n.t('projectSelector.desc')}</p>)}
                {organizationIds.length > 0 ? (
                    <>
                        <ul className="mu-project-selector__organizations">
                            {organizationIds.map((organizationId) => (
                                <li className="mu-project-selector__organization" key={organizationId}>
                                    <button
                                        type="button"
                                        onClick={() => setCurrentOrganizationId(organizationId)}
                                    >
                                        <OrganizationAvatar
                                            organizationId={organizationId}
                                            isSelected={organizationId === currentOrganizationId}
                                        />
                                    </button>
                                </li>
                            ))}
                            {me.admin && (
                                <li className="mu-project-selector__organization">
                                    <button
                                        type="button"
                                        onClick={() => dispatch(openModal('CreateOrganization'))}
                                    >
                                        <div className="mu-project-selector__create-organization">
                                            <MuIcon svgName="Plus" />
                                        </div>
                                    </button>
                                </li>
                            )}
                        </ul>
                    </>
                ) : (
                    <div className="mu-project-selector__empty-panel">
                        {i18n.t('project.none.description')}
                    </div>
                )}
                {projectsQuery.results && (
                    advancedSearch ? (
                        <Projects />
                    ) : (
                        <section className="mu-project-selector__projects">
                            {!projectsQuery.loading && (
                                Client.isAllowedToCreateProject() ? (
                                    <div className="mu-project-selector__project">
                                        <GridButtonCard
                                            icon="Brain"
                                            onClick={
                                                () => dispatch(openModal('CreateProject'))
                                            }
                                            label={i18n.t('projects.create')}
                                            panel
                                        />
                                    </div>
                                ) : !projectsQuery.results.length && (
                                    <div className="mu-project-selector__empty-panel">
                                        {i18n.t('project.none.description')}
                                    </div>
                                )
                            )}
                            {projectsQuery.results.map((projectId) => (
                                <div key={projectId} className="mu-project-selector__project">
                                    <ProjectPreviewBlock
                                        handleClick={async () => {
                                            await setCurrentProjectId(projectId);
                                            navigate(`/projects/${projectId}`);
                                        }}
                                        projectId={projectId}
                                        notificationCount={getNotificationCount(projectId)}
                                    />
                                </div>
                            ))}
                            {projectsQuery.loading && (
                                <div className="mu-project-selector__loading">
                                    <Spinner size="40px" />
                                </div>
                            )}
                        </section>
                    )
                )}
            </div>
        </AppLayout>
    );
};

export default ProjectSelector;
