import React, {useRef, ReactNode, useCallback, useState} from 'react';
import {classWithModifiers} from '../../Helpers/css';
import {useClickOutside} from '../../Helpers/effects';
import Portal from '../Portal/Portal';
import {Align, Position, useComputedPosition} from '../../Helpers/position';

type Props = React.PropsWithChildren<{
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    position?: Position;
    align?: Align;
    toggle: ReactNode;
    className?: string;
    toggleClassName?: string;
    contentClassName?: string;
    scrollRef?: (node: HTMLElement | null) => void;
    preventDefault?: boolean;
    disabled?: boolean;
}>

const DropdownMenu: React.FC<Props> = ({
    isOpen,
    setIsOpen,
    position = 'bottom',
    align = 'start',
    toggle,
    className,
    toggleClassName,
    contentClassName,
    children,
    scrollRef,
    preventDefault = false,
    disabled = false,
}) => {
    const [content, setContent] = useState<HTMLElement | null>(null);
    const container = useRef<HTMLElement>(null);
    const computedPosition = useComputedPosition(position, container.current, content, isOpen, align);

    const closeDropdown = useCallback(() => setIsOpen(false), [setIsOpen]);

    useClickOutside(container, isOpen, () => closeDropdown());

    return (
        <section
            className={classWithModifiers('mu-dropdown-menu', {open: isOpen}, className)}
            ref={container}
            onClick={preventDefault ? (e) => e.preventDefault() : undefined}
        >
            <button
                type="button"
                className={classWithModifiers('mu-dropdown-menu__toggle', {open: isOpen}, toggleClassName)}
                onClick={(event) => {
                    setIsOpen(!isOpen);
                    event.stopPropagation();
                    event.preventDefault();
                }}
                disabled={disabled}
            >
                {toggle}
            </button>
            {isOpen && (
                <Portal>
                    <section
                        ref={(element) => {
                            setContent(element);
                            if (scrollRef) scrollRef(element);
                        }}
                        onMouseDown={(event) => event.stopPropagation()}
                        className={classWithModifiers(
                            'mu-dropdown-menu__content', {scroll: !!scrollRef}, contentClassName,
                        )}
                        style={{
                            position: 'absolute',
                            visibility: computedPosition ? 'visible' : 'hidden',
                            ...computedPosition?.style,
                        }}
                    >
                        {children}
                    </section>
                </Portal>
            )}
        </section>
    );
};

export default DropdownMenu;
