import React from 'react';
import {useDispatch} from 'react-redux';
import {Project} from 'mushin-redux-store';
import i18n from 'i18next';
import {openModal} from '../../../../../Redux/reducers/modalsSlice';
import ClusterCard from '../../../../../Components/ClusterCard/ClusterCard';
import ActionCard from '../../../../../Components/ActionCard/ActionCard';
import {isProjectAdmin} from '../../../../../Helpers/users';

type Props = {
    project: Project;
    albumIds: string[] | null;
};

const ClusterList: React.FC<Props> = ({
    project,
    albumIds,
}) => {
    const dispatch = useDispatch();

    return (
        <section className="mu-synthesis__section-clusters">
            {isProjectAdmin(project) && (
                <div className="mu-albums-list__item">
                    <ActionCard
                        activeMode="Cluster"
                        onClick={() => dispatch(openModal('CreateAlbum', {projectId: project.id}))}
                        create
                        createText={i18n.t<string>('clusters.create')}
                    />
                </div>
            )}
            {albumIds?.map((albumId: string) => (
                <div key={albumId} className="mu-albums-list__item">
                    <ClusterCard albumId={albumId} />
                </div>
            ))}
        </section>
    );
};

export default ClusterList;
