import React from 'react';
import i18n from 'i18next';
import NewsItem from './NewsItem';
import { classWithModifiers } from '../../Helpers/css';
import MuButton from '../MuButton';

type Props = {
    news: Array<string>;
    className?: string;
    classModifiers?: [string];
    pager?: {
      total_items: number;
      page: number;
    } | null;
    getMore?: () => void;
}

const NewsList: React.FC<Props> = ({news, className = '', classModifiers, pager, getMore}) => {
    return (
        <section className={classWithModifiers('mu-news-list', classModifiers, className)}>
            <h2 className="mu-news-list__title">{i18n.t('news.lastNews')}</h2>
            <ul className="mu-news-list__container">
                {news.map((newsId, index) => (
                    <li key={newsId} className="mu-surveys-list__item">
                        <NewsItem id={newsId} isFirstItem={index === 0} />
                    </li>
                ))}
            </ul>
            {pager && pager.page * 15 < pager.total_items && (
                <MuButton
                    className="mu-news-list__see-more"
                    label={i18n.t('global.seeMore')}
                    handleClick={() => getMore && getMore()}
                />
            )}
        </section>
    );
};

export default NewsList;
