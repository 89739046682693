import React from 'react';
import {ReorderDragItem, useReorder} from '../../Helpers/dnd';
import {classWithModifiers} from '../../Helpers/css';

type Props = React.PropsWithChildren<{
    className?: string;
    placeholder?: React.ReactElement;
    type: string;
    id: string;
    index: number;
    move: (from: number, to: number) => boolean;
    end?: (item: ReorderDragItem) => void;
    horizontal?: boolean;
    disabled?: boolean;
}>

const ReorderWrapper: React.FC<Props> = (
    {
        className,
        placeholder,
        type,
        id,
        index,
        move,
        end,
        horizontal = false,
        disabled = false,
        children,
    }
) => {
    const [{isDragging, handlerId}, ref] = useReorder(type, id, index, {move, end}, horizontal, disabled);

    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            className={className && classWithModifiers(className, {dragging: isDragging})}
        >
            {isDragging && placeholder}
            {children}
        </div>
    );
};

export default ReorderWrapper;
