import React from 'react';
import {Project, updateProject} from 'mushin-redux-store';
import i18n from 'i18next';
import DeltaPanel from './Components/DeltaPanel';
import MuIcon from '../MuIcon';
import {openModal} from '../../Redux/reducers/modalsSlice';
import {isProjectAdmin} from '../../Helpers/users';
import ProjectCoverVideo from './Components/ProjectCoverVideo';
import ProjectCoverImage from './Components/ProjectCoverImage';
import MuButton from '../MuButton';
import {getStatus} from '../../Helpers/date';
import {useAppDispatch} from '../../Helpers/hooks';
import DateEditor from '../DateEditor/DateEditor';
import UserCard from '../UserCard/UserCard';

type Props = {
    project: Project;
};

const ProjectDashboard: React.FC<Props> = ({project}) => {
    const dispatch = useAppDispatch();

    const canEdit = isProjectAdmin(project);
    const status = getStatus(project.start_date, project.end_date, project.archived_at);

    return (
        <section className="mu-panel mu-project-dashboard">
            <div className="mu-project-dashboard__pictures">
                <ProjectCoverImage project={project} />
                <ProjectCoverVideo project={project} />
            </div>
            <div className="mu-project-dashboard__panel">
                <DeltaPanel
                    translationTitle={{
                        fallback: i18n.t('project.goal'),
                    }}
                    translationContent={{
                        translationProp: project.goals,
                        fallback: project.goal,
                    }}
                    placeholder={i18n.t('dashboardGoal.isEmpty')}
                    canEditEntity={canEdit}
                    onSubmit={(value) => dispatch(updateProject(project.id, {
                        goal: value,
                    }))}
                    className="mu-project-dashboard__goal"
                />
            </div>
            <div className="mu-project-dashboard__footer">
                <div className="mu-project-dashboard__footer-item">
                    <h3 className="mu-project-dashboard__footer-item-title">
                        {i18n.t('global.leader')}
                        <MuIcon className="mu-project-dashboard__footer-item-icon" svgName="User" />
                    </h3>
                    <div className="mu-project-dashboard__footer-item-content">
                        <UserCard id={project.owner.id} hideEmail />
                    </div>
                </div>
                {status === 'draft' ? (
                    <div className="mu-project-dashboard__footer-item">
                        <MuButton
                            type="button"
                            classModifiers={['large', 'important']}
                            label={i18n.t('global.publish')}
                            handleClick={() => dispatch(openModal('PublishModal', {
                                onPublish: ({start_date, end_date}) => dispatch(updateProject(project.id, {
                                    start_date,
                                    end_date,
                                })),
                                labelKey: 'projects',
                            }))}
                        />
                    </div>
                ) : (
                    <>
                        <DateEditor
                            value={project.start_date}
                            minDate={new Date()}
                            label={i18n.t('components.filters.dropdown.beginDateLabel')}
                            canEdit={canEdit}
                            onSubmit={(value) => dispatch(updateProject(project.id, {
                                start_date: value,
                            }))}
                        />
                        <DateEditor
                            value={project.end_date}
                            minDate={new Date()}
                            label={i18n.t('components.filters.dropdown.endDateLabel')}
                            canEdit={canEdit}
                            onSubmit={(value) => dispatch(updateProject(project.id, {
                                end_date: value,
                            }))}
                        />
                    </>
                )}
            </div>
        </section>
    );
};

export default ProjectDashboard;
