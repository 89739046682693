import React from 'react';
import { ToggleTextButton } from './text';
import { ActionProps, ActionBarSeparator } from './common';
import { GoToCropModeButton } from './crop';
import { RotateLeftButton, RotateRightButton } from './rotate';

export const imageButtons = (props: ActionProps) => [
    <ToggleTextButton key="ToggleTextButton" {...props} />,
    <ActionBarSeparator key="ActionBarSeparator" />,
    <GoToCropModeButton key="GoToCropModeButton" {...props} />,
    <RotateLeftButton key="RotateLeftButton" {...props} />,
    <RotateRightButton key="RotateRightButton" {...props} />,
];
