import React from 'react';
import { useDispatch } from 'react-redux';
import { Project } from 'mushin-redux-store';
import i18n from 'i18next';
import MuIcon from '../../MuIcon';
import {isProjectAdmin} from '../../../Helpers/users';
import {openModal} from '../../../Redux/reducers/modalsSlice';
import {classWithModifiers} from '../../../Helpers/css';

type Props = {
    project: Project;
    header?: boolean;
};

const ProjectCoverImage: React.FC<Props> = ({project, header}) => {
    const dispatch = useDispatch();

    const openDashboardModal = (type: 'EditProject' | 'EditProjectUsers' | 'EditProjectCover') => {
        dispatch(openModal(type, { project }));
    };

    const canEdit = isProjectAdmin(project);

    if (project.cover_image_url) {
        return (
            <div className={classWithModifiers('mu-project-dashboard__picture-block', {header})}>
                <img
                    className="mu-project-dashboard__picture"
                    src={project.cover_image_url}
                    alt="Cover"
                />
                {canEdit && (
                    <button
                        className="mu-edit-button mu-project-dashboard__picture-edit"
                        type="button"
                        onClick={() => openDashboardModal('EditProjectCover')}
                    >
                        <MuIcon className="mu-edit-button__icon" svgName="Edit" />
                    </button>
                )}
            </div>
        );
    }

    if (canEdit) {
        return (
            <button
                type="button"
                className="mu-panel-secondary mu-project-dashboard__picture-block"
                onClick={() => openDashboardModal('EditProjectCover')}
            >
                <MuIcon className="mu-project-dashboard__picture-block-icon" svgName="PhotoCamera" />
                <span className="mu-project-dashboard__picture-block-text">
                    {i18n.t('projects.addCoverImage')}
                </span>
            </button>
        );
    }

    return null;
};

export default ProjectCoverImage;
