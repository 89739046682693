import formatDistance from './_lib/formatDistance/index';
import formatLong from './_lib/formatLong/index';
import formatRelative from './_lib/formatRelative/index';
import localize from './_lib/localize/index';
import match from './_lib/match/index';
import type { Locale } from '../types';

/**
 * @type {Locale}
 * @category Locales
 * @summary Spanish locale.
 * @language Spanish
 * @iso-639-2 spa
 * @author Juan Angosto [@juanangosto]{@link https://github.com/juanangosto}
 * @author Guillermo Grau [@guigrpa]{@link https://github.com/guigrpa}
 * @author Fernando Agüero [@fjaguero]{@link https://github.com/fjaguero}
 * @author Gastón Haro [@harogaston]{@link https://github.com/harogaston}
 * @author Yago Carballo [@YagoCarballo]{@link https://github.com/YagoCarballo}
 */
const locale: Locale = {
    code: 'es',
    formatDistance,
    formatLong,
    formatRelative,
    localize,
    match,
    options: {
        weekStartsOn: 1 /* Monday */,
        firstWeekContainsDate: 1,
    },
};

export default locale;
