import React from 'react';
import {useGroup, deleteGroup} from 'mushin-redux-store';
import i18n from 'i18next';
import { Modifiers, classWithModifiers } from '../../../../Helpers/css';
import MuIcon from '../../../../Components/MuIcon';
import Client from '../../../../Services/Client';
import {openModal, openAlert} from '../../../../Redux/reducers/modalsSlice';
import {useAppDispatch} from '../../../../Helpers/hooks';

type Props = {
    groupId: string;
    classModifiers?: Modifiers;
};

const GroupRow: React.FC<Props> = ({
    groupId,
    classModifiers,
}: Props) => {
    const dispatch = useAppDispatch();
    const group = useGroup(groupId);

    if (!group) return null;

    const admin = Client.isAllowedToEditOrganization() || group.role === 'admin';

    const openGroupsModal = () => {
        if (admin) {
            dispatch(openModal('EditAGroup', {group}));
        } else {
            dispatch(openModal('ListUsers', {users: group.members}));
        }
    };

    return (
        <div key={groupId} className={classWithModifiers('mu-admin-row', classModifiers)}>
            <button
                className="mu-admin-row__infos"
                type="button"
                onClick={openGroupsModal}
            >
                <div className="mu-admin-row__name">{group.name}</div>
                <div className="mu-admin-row__count">
                    <MuIcon className="mu-admin-row__count-icon" svgName="Group" />
                    {i18n.t('groups.membersCount', {count: group.members.length })}
                </div>
            </button>
            {admin && (
                <button
                    className="mu-admin-row__delete-btn"
                    type="button"
                    onClick={() => {
                        dispatch(
                            openAlert({
                                type: 'danger',
                                title: i18n.t('modals.deleteGroup.title', { albumName: group.name }),
                                icon: 'Delete',
                                confirmLabel: i18n.t('modals.deleteGroup.confirmButton'),
                                body: i18n.t('modals.deleteGroup.catchPhrase', { albumName: group.name }),
                                confirmAction: () => dispatch(deleteGroup(groupId)),
                            })
                        );
                    }}
                >
                    <MuIcon svgName="Delete" />
                </button>
            )}
        </div>
    );
};

export default GroupRow;
