import React from 'react';
import {ProjectStep} from 'mushin-redux-store';
import i18n from 'i18next';
import {classWithModifiers} from '../../../../Helpers/css';

type Props = {
    stepSlug: string | undefined;
    setStep: (step: string | undefined) => void;
    steps: ProjectStep[];
}

const StepsDropdownPanel: React.FC<Props> = (
    {stepSlug, setStep, steps}
) => (
    <>
        <button
            className={classWithModifiers('mu-dropdown-menu__button-item', {selected: !stepSlug})}
            type="button"
            onClick={() => setStep(undefined)}
        >
            {i18n.t('tooltips.all')}
        </button>
        {steps.map((step) => (
            <button
                key={step.slug}
                className={classWithModifiers('mu-dropdown-menu__button-item', {selected: stepSlug === step.slug})}
                type="button"
                onClick={() => setStep(step.slug)}
            >
                {step.title}
            </button>
        ))}
    </>
);

export default StepsDropdownPanel;
