import React, {useState} from 'react';
import i18n from 'i18next';
import DropdownMenu from '../../DropdownMenu/DropdownMenu';
import TextIcon from '../../TextIcon/TextIcon';
import MuIcon from '../../MuIcon';
import {classWithModifiers} from '../../../Helpers/css';

type Option = {
    key: string | undefined;
    icon: string;
    label: React.ReactNode;
    hidden?: boolean;
}

type Props = {
    sort: string | undefined;
    setSort: (sort: string | undefined) => void;
    options: Option[];
    defaultSort?: string;
}

const SortBySelect: React.FC<Props> = ({ sort, setSort, options, defaultSort }) => {
    const [isOpen, setOpen] = useState(false);

    const onOptionClick = (value: string | undefined) => {
        setSort(value);
        setOpen(false);
    };

    return (
        <div className="mu-filters-toolbar">
            <DropdownMenu
                isOpen={isOpen}
                setIsOpen={setOpen}
                toggle={(
                    <div className="mu-filters-toolbar__filter-toggle">
                        {i18n.t('components.sort.button')}
                        {' : '}
                        {options.find((opt) => opt.key === (sort || defaultSort))?.label}
                        <MuIcon className="mu-filters-toolbar__filter-toggle-icon" svgName="ArrowDown" />
                    </div>
                )}
                align="center"
            >
                {options.map((option) => {
                    if (option.hidden) return null;
                    const selected = sort ? sort === option.key : option.key === defaultSort;
                    return (
                        <button
                            key={option.key || ''}
                            className={
                                classWithModifiers('mu-dropdown-menu__button-item', {selected})
                            }
                            type="button"
                            onClick={() => onOptionClick(option.key)}
                        >
                            <TextIcon classModifiers={{selected}} icon={option.icon} label={option.label} />
                        </button>
                    );
                })}
            </DropdownMenu>
        </div>
    );
};

export default SortBySelect;
