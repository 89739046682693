import i18n from 'i18next';
import isEmail from '../isEmail';

export function muInputIsEmail(value: string): string {
    if (!value) return ''; // le fait de checker le required n'est pas pour cette fonction
    if (!isEmail(value)) {
        return i18n.t('global.errors.inputEmailError');
    }
    return '';
}
