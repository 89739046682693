import React from 'react';
import {Navigate, useLocation, useParams} from 'react-router-dom';

const RedirectWildCard: React.FC<{to: string}> = ({ to }) => {
    const location = useLocation();
    const wildcard = useParams()['*'];
    return (
        <Navigate
            to={{pathname: `${to}/${wildcard}`, search: location.search}}
            replace
        />
    );
};

export default RedirectWildCard;
