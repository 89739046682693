import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import {Note, updateNote, ApiV3} from 'mushin-redux-store';
import {AxiosPromise, AxiosRequestConfig} from 'axios';
import {
    Board,
    Cell,
    ShapeType,
    TextBlock,
    TextBlockStyle
} from 'mushin-node-moodboard';
import i18n from 'i18next';
import {MoodboardPage, Template} from '../../Components/BoardComponents/BoardLayout/type';
import {
    adaptContentForTemplate,
    noteToContent
} from '../../Helpers/moodboardContent';
import {getActionCreators, getUndoActionsWithPrefix, getUndoTypeOptions} from '../helpers/undo';
import {AppThunk, AsyncAppThunk} from './index';
import {useAppSelector} from '../../Helpers/hooks';
import {Mode} from '../../Components/BoardComponents/BoardLayout/component/behaviors/type';

type State = {
    noteId: string | null;
    pages: MoodboardPage[];
    selectedPage: number;
    mode: Mode;
    selectedCell: string | null;
    saved: boolean;
    loading: boolean;
}

const initialState: State = {
    noteId: null,
    pages: [],
    selectedPage: -1,
    mode: 'layout',
    selectedCell: null,
    saved: true,
    loading: true,
};

const genId = () => Math.random().toString(16).slice(2, 8);

const getTextStyle = (text: TextBlock | undefined): TextBlockStyle => (
    typeof text === 'string' ? 'normal' : text?.style || 'normal'
);

const defaultCell = {
    max: { x: 50, y: 50 },
    min: { x: 0, y: 0 },
};

const moodboardEditorSlice = createSlice({
    name: 'moodboardEditor',
    initialState,
    reducers: {
        init(state, action: PayloadAction<{ noteId: string; pages: MoodboardPage[] }>) {
            state.noteId = action.payload.noteId;
            state.pages = action.payload.pages;
            state.selectedPage = 0;
            state.saved = true;
            state.loading = false;
        },
        startSave(state) {
            state.loading = true;
        },
        endSave(state, action: PayloadAction<boolean>) {
            state.loading = false;
            state.saved = action.payload;
        },
        setSelectedPage(state, action: PayloadAction<number>) {
            state.selectedPage = action.payload;
        },
        setBoard(state, action: PayloadAction<Board>) {
            state.pages[state.selectedPage].board = action.payload;
        },
        setMode(state, action: PayloadAction<{mode: Mode; selectedCell?: string | null}>) {
            const {mode, selectedCell} = action.payload;
            if (mode === 'cell' && !selectedCell && !state.selectedCell) {
                throw new Error('selectedCell must be present for "cell" mode');
            }
            state.mode = mode;
            if (selectedCell !== undefined) state.selectedCell = selectedCell;
        },
        addNoteElement(state, action: PayloadAction<{note: Note; cellId?: string; dropCell?: Cell}>) {
            const {
                note,
                cellId,
                dropCell,
            } = action.payload;

            if (state.selectedPage === -1) return;
            const page = state.pages[state.selectedPage];

            const cellIsEmpty = cellId && page.boardData.templateContents[cellId]?.type === 'whiteCol';

            // if note is not in notePool yet, add it
            if (!page.boardData.notePool.some((_note) => _note.id === note.id)) {
                page.boardData.notePool.push(note);
            }

            const cell = {
                id: !cellId || cellIsEmpty ? genId() : cellId,
                content: noteToContent(
                    note,
                    !cellId || cellIsEmpty ? null : page.boardData.templateContents[cellId],
                    !cellId || cellIsEmpty ? null : page.board.contents[cellId],
                ),
            };

            // no content
            if (!cell.content) return;

            page.board.contents[cell.id] = cell.content;

            // new cell
            if (!cellId || cellIsEmpty) {
                page.board.cells[cell.id] = dropCell || defaultCell;
                page.board.ids.unshift(cell.id);
            }
        },
        addTextElement(state, action: PayloadAction<{style: TextBlockStyle; size: string; dropCell?: Cell}>) {
            const {
                style,
                size,
                dropCell,
            } = action.payload;

            if (state.selectedPage === -1) return;
            const page = state.pages[state.selectedPage];

            const id = genId();

            page.board.contents[id] = {
                type: 'text',
                text: [{style, text: i18n.t<string>('global.text')}],
                text_align: 'left',
                vertical_align: 'vcenter',
                text_color: '#000000',
                background: 'transparent',
                text_font: 'Helvetica',
                text_size: size,
            };
            page.board.cells[id] = dropCell || defaultCell;
            page.board.ids.unshift(id);
        },
        addShapeElement(state, action: PayloadAction<{shape: ShapeType; dropCell?: Cell}>) {
            const {
                shape,
                dropCell,
            } = action.payload;

            if (state.selectedPage === -1) return;
            const page = state.pages[state.selectedPage];

            const id = genId();

            page.board.contents[id] = {
                type: 'shape',
                lineWidth: 1,
                color: '#000000',
                background: 'transparent',
                shape,
            };
            page.board.cells[id] = dropCell || defaultCell;
            page.board.ids.unshift(id);
        },
        setTemplate(state, action: PayloadAction<{template: Template, templateId: number}>) {
            if (state.selectedPage === -1) return;
            const page = state.pages[state.selectedPage];

            page.board = adaptContentForTemplate(action.payload.template, null, page.board);
            page.boardData.templateId = action.payload.templateId;
            page.boardData.templateContents = action.payload.template.contents;
        },
        setBoardText(state, action: PayloadAction<{id: string, content: string}>) {
            if (state.selectedPage === -1) return;
            const page = state.pages[state.selectedPage];

            const {id} = action.payload;
            const content = page.board.contents[id];

            if (!content || (content.type !== 'text' && content.type !== 'mixed')) return;

            const text = action.payload.content;
            content.text = [{style: getTextStyle(content.text[0]), text}];

            if (id === 'title') page.board.title = text;
        },
    },
});

export const {
    setSelectedPage,
    setBoard,
} = moodboardEditorSlice.actions;

const {
    init,
    startSave,
    endSave,
    setMode,
    addNoteElement,
    addTextElement,
    addShapeElement,
    setTemplate: setInternalTemplate,
    setBoardText: setInternalBoardText,
} = moodboardEditorSlice.actions;

export const setEditorMode = (mode: Mode, selectedCell?: string | null): AppThunk => (dispatch) => dispatch(setMode({
    mode,
    selectedCell,
}));

export const addElementInNewCell = (note: Note, dropCell?: Cell): AppThunk => (dispatch) => dispatch(addNoteElement({
    note,
    dropCell,
}));

export const addElementInCell = (note: Note, cellId: string): AppThunk => (dispatch) => dispatch(addNoteElement({
    note,
    cellId,
}));

type TextConfig = {style: TextBlockStyle; size: string}
export const addTextInNewCell = (
    config: TextConfig,
    dropCell?: Cell,
): AppThunk => (dispatch) => dispatch(addTextElement({
    ...config,
    dropCell,
}));

export const addShapeInNewCell = (
    shape: ShapeType,
    dropCell?: Cell,
): AppThunk => (dispatch) => dispatch(addShapeElement({
    shape,
    dropCell,
}));

export const setBoardText = (id: string, content: string): AppThunk => (dispatch) => dispatch(setInternalBoardText({
    id,
    content,
}));

export const setTemplate = (
    template: Template,
    templateId: number,
): AppThunk => (dispatch) => dispatch(setInternalTemplate({
    template,
    templateId,
}));

const HistoryActionTypes = getUndoActionsWithPrefix(moodboardEditorSlice.name);

export const HistoryActionCreators = getActionCreators(HistoryActionTypes);

export const initMoodboardEditor = (noteId: string, pages: MoodboardPage[]): AppThunk => (dispatch, getState) => {
    const currentNoteId = getState().moodboardEditor.present.noteId;
    if (noteId !== currentNoteId) {
        dispatch(init({noteId, pages}));
        dispatch(HistoryActionCreators.clearHistory());
    }
};

export const saveMoodboard = (label?: string): AsyncAppThunk => async (dispatch, getState) => {
    const {loading, noteId, pages} = getState().moodboardEditor.present;
    if (!loading && noteId) {
        dispatch(startSave());
        try {
            await dispatch(updateNote(
                noteId,
                {moodboard_pages: pages.map((page) => ({ data: JSON.stringify(page) })), label})
            );
            dispatch(endSave(true));
        } catch (e) {
            dispatch(endSave(false));
        }
    }
};

const exportBoards = (boards: Board[], label?: string, config?: AxiosRequestConfig): AxiosPromise<string> => {
    return ApiV3.getInstance().patch('notes/boards/export', { label, boards }, config);
};

export const exportToPdf = (
    label: string,
    index?: number,
): AsyncAppThunk<string> => async (dispatch, getState): Promise<string> => {
    const {pages} = getState().moodboardEditor.present;
    const boards = index ? [pages[index].board] : pages.map((page) => page.board);

    const response = await exportBoards(boards, label);
    return response.data;
};

export const useCurrentPageSelector = <TSelected = unknown>(
    selector: (page: MoodboardPage) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected => {
    return useAppSelector(
        (state) => {
            const {present} = state.moodboardEditor;
            return selector(present.pages[present.selectedPage]);
        },
        equalityFn,
    );
};

export default undoable(moodboardEditorSlice.reducer, {
    ...getUndoTypeOptions(HistoryActionTypes),
    filter: (action) => ![startSave.type, endSave.type].includes(action.type),
});
