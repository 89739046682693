import React, {ReactNode, SVGAttributes} from 'react';
import {classWithModifiers, Modifiers} from '../../Helpers/css';
import { MuSvg } from '../MuSvg';
import MuIcon from '../MuIcon';

type Props = {
  classModifiers?: Modifiers;
  className?: string;
  getRef?: (ref: Object) => void;
  label?: ReactNode | string;
  rightIcon?: string | React.FC<SVGAttributes<SVGElement>>;
  icon?: string | React.FC<SVGAttributes<SVGElement>>;
  disabled?: boolean;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => any;
  type?: 'button' | 'submit' | 'reset';
};

const MuButton: React.FC<Props> = ({
    classModifiers = [],
    className,
    label,
    rightIcon,
    icon,
    disabled = false,
    handleClick,
    type = 'button',
    getRef,
}) => {
    const iconOnly = !!(icon || rightIcon) && !label;

    let fullClassName = classWithModifiers('mu-btn', classModifiers, className);

    if (iconOnly) {
        fullClassName += ` ${classWithModifiers('mu-icon-btn', classModifiers)}`;
    }

    return (// eslint-disable-next-line react/button-has-type
        <button
            ref={getRef}
            type={type}
            className={fullClassName}
            disabled={disabled}
            onClick={handleClick}
        >
            {icon && (
                <>
                    {typeof icon === 'string' ? (
                        <MuIcon className="mu-btn__left-icon" svgName={icon} />
                    ) : (
                        <MuSvg className="mu-btn__left-icon" SvgComp={icon} />
                    )}
                </>
            )}

            {label && <span className="mu-btn__text">{label}</span>}
            {rightIcon && (
                <>
                    {typeof rightIcon === 'string' ? (
                        <MuIcon className="mu-btn__right-icon" svgName={rightIcon} />
                    ) : (
                        <MuSvg className="mu-btn__right-icon" SvgComp={rightIcon} />
                    )}
                </>
            )}
        </button>
    );
};

export default MuButton;
