import React from 'react';
import {useGroups} from 'mushin-redux-store';
import EntitySelectMultipleValue from '../../../EntitySelect/Components/EntitySelectMultipleValue';

type Props = {
    ids: string[];
}

const GroupItem: React.FC<Props> = ({ ids }) => {
    return (
        <EntitySelectMultipleValue
            value={ids}
            getEntityName={(group) => group.name}
            useItems={useGroups}
        />
    );
};

export default GroupItem;
