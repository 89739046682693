import React from 'react';

import {Note, NoteImgSrc, useProfile} from 'mushin-redux-store';
import {classWithModifiers} from '../../Helpers/css';
import MuIcon from '../MuIcon';
import CheckboxRadio from '../Form/CheckboxRadio';
import LinkOrButton from '../LinkOrButton/LinkOrButton';
import {ProfileAvatar} from '../UserCard/ProfileAvatar';
import NoteReaction from '../Reaction/NoteReaction';
import TextTranslation from '../Translation/TextTranslation';

type Props = {
    note: Note;
    isSelected?: boolean;
    onSelect?: (e?: React.MouseEvent<HTMLDivElement>) => void;
    onClick?: string | (() => void);
    size?: keyof NoteImgSrc;
    MoreOptionsDropDown?: React.ReactNode | null;
    footerComponents?: React.ReactNode;
    isNew?: boolean;
    displayAsSquare?: boolean;
    showInfos?: boolean;
    showFavorite?: boolean;
    small?: boolean;
};

const getTextModifier = (textLength: number, small?: boolean) => {
    if (small) {
        if (textLength > 300) return {xs: true};
        if (textLength > 50) return {s: true};
        return {};
    }
    if (textLength > 300) return {};
    if (textLength > 50) return {l: true};
    return {xl: true};
};

const NoteThumbnail: React.FC<Props> = ({
    note,
    isSelected,
    onSelect,
    onClick,
    size = 'thumb',
    MoreOptionsDropDown,
    footerComponents,
    isNew = false,
    displayAsSquare = true,
    showInfos = false,
    showFavorite = false,
    small = false,
}) => {
    const member = useProfile(note.profile_id);

    const createdBy = member && (
        <h3 className="mu-note__created-by">
            <ProfileAvatar userId={member.id} size="small" />
            {member.name}
        </h3>
    );

    return (
        <section
            className={`${classWithModifiers('mu-note', {
                isNew,
                'no-footer': !footerComponents,
                text: note.type === 'TEXT',
            })}`}
        >
            <div
                className={classWithModifiers(
                    'mu-note__content',
                    [note.type === 'TEXT' ? 'text' : 'picture', {selected: isSelected, square: displayAsSquare, small}],
                )}
            >
                {/* eslint-disable-next-line no-nested-ternary */}
                {note.type !== 'TEXT' ? (
                    note.type !== 'VIDEO' ? (
                        <img
                            className={classWithModifiers('mu-note__content-picture', {square: displayAsSquare})}
                            src={note.image_src[size]}
                            alt={note.label}
                        />
                    ) : (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video
                            className={classWithModifiers('mu-note__content-picture', {square: displayAsSquare})}
                            src={note.video_url}
                        />
                    )
                ) : (
                    <>
                        <span
                            className={classWithModifiers(
                                'mu-note__content-text', getTextModifier(note.label.length, small),
                            )}
                        >
                            <TextTranslation translationProp={note.labels} fallback={note.label} center />
                        </span>
                        {showInfos && createdBy}
                    </>
                )}
                <LinkOrButton className="mu-note__content-overlay" onClick={onClick}>
                    {showInfos && note.type !== 'TEXT' && (
                        <section className="mu-note__infos">
                            {note.label && (
                                <h2 className="mu-note__title">
                                    <TextTranslation translationProp={note.labels} fallback={note.label} center />
                                </h2>
                            )}
                            {createdBy}
                        </section>
                    )}
                </LinkOrButton>
                {onSelect && (
                    <CheckboxRadio
                        className="mu-note__selection"
                        setChecked={() => onSelect()}
                        checked={!!isSelected}
                    />
                )}
                <div className="mu-note__more-options">
                    {MoreOptionsDropDown}
                    {note.type === 'MOODBOARD' && (
                        <div className="mu-note__board-icon">
                            <MuIcon svgName="Moodboard" />
                            <div className="mu-note__board-icon-count">{note.moodboard_length || 0}</div>
                        </div>
                    )}
                    {showFavorite && (
                        <NoteReaction
                            name="favorite"
                            note={note}
                            horizontal
                        />
                    )}
                </div>
            </div>
            {footerComponents && (
                <div className="mu-note__footer">
                    {footerComponents}
                </div>
            )}
        </section>
    );
};

export default NoteThumbnail;
