import {useCallback, useState} from 'react';
import Delta from 'quill-delta';

type UseWithDelayReturn<T> = [
    T,
    (value: T, immediate?: boolean, stopHandler?:boolean) => void,
    (value: T, resetTimeout?: boolean) => void,
]

export const useInputWithDelay = <T>(
    handler: (value: T) => void, initialValue: T, condition: (value: T) => boolean = () => true, delay = 1000
): UseWithDelayReturn<T> => {
    const [, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);
    const [currentValue, setCurrentValue] = useState(initialValue);

    const setValue = (value: T, immediate = false, stopHandler = false) => {
        setCurrentValue(value);
        if (stopHandler) return;
        if (condition(value)) {
            if (immediate) {
                handler(value);
            } else {
                setCurrentTimeout((prevTimeout) => {
                    if (prevTimeout) {
                        clearTimeout(prevTimeout);
                    }
                    return setTimeout(() => handler(value), delay);
                });
            }
        } else {
            handler(initialValue);
        }
    };

    const reset = useCallback((value: T, resetTimeout = true): void => {
        setCurrentValue(value);
        if (resetTimeout) {
            setCurrentTimeout((prevTimeout) => {
                if (prevTimeout) {
                    clearTimeout(prevTimeout);
                }
                return null;
            });
        }
    }, []);

    return [currentValue, setValue, reset];
};

export const useTextInputWithDelay = (
    handler: (value: string) => void, minLength = 1, delay = 500,
): UseWithDelayReturn<string> => {
    return useInputWithDelay(
        handler,
        '' as string,
        (value) => value.length >= minLength,
        delay,
    );
};

export const useQuillInputWithDelay = (
    handler: (value: Delta) => void, initialValue: Delta, delay = 3000
): UseWithDelayReturn<Delta> => {
    return useInputWithDelay(
        handler,
        initialValue,
        undefined,
        delay,
    );
};
