import { connect } from 'react-redux';
import { injectCamera } from './injectCamera';
import { injectTransformBatcher } from './injectTransformBatcher';
import BoardEditor from './BoardEditor';

import { injectDraggedElement } from '../../../Bucket/DragAndDropComponents/elementDragStore';
import {
    addElementInCell,
    addElementInNewCell,
    setBoard,
    setEditorMode
} from '../../../../Redux/reducers/moodboardEditorSlice';
import {AppRootState} from '../../../../Redux/reducers';

const mapStateToProps = (state: AppRootState) => {
    const {present} = state.moodboardEditor;
    const page = present.pages[present.selectedPage];

    return {
        board: page.board,
        templateContents: page.boardData.templateContents,
        mode: present.mode,
        selectedCell: present.selectedCell,
    };
};

const mapDispatchToProps = {
    setBoard,
    setMode: setEditorMode,
    addElementInNewCell,
    addElementInCell,
};

export const injectRedux = connect(mapStateToProps, mapDispatchToProps);

const BoardWithActionLayerComponent = injectDraggedElement(BoardEditor);

export const BoardComponent = [injectRedux, injectTransformBatcher, injectCamera].reduceRight((C, hoc) => hoc(C), BoardWithActionLayerComponent);
