import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';

type Props = React.PropsWithChildren<{
    emails: string[];
    setEmails: Dispatch<SetStateAction<string[]>>;
    search?: string;
    setSearch: Dispatch<SetStateAction<string>>;
    noClass?: boolean;
    style?: React.CSSProperties;
    getLabel: (
        email: string,
        index: number,
        removeEmail: (idx: number) => void,
    ) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    className?: string;
    placeholder?: string | React.ReactNode;
}>

const ReactItemInput: React.FC<Props> = (
    {
        emails,
        setEmails,
        search,
        setSearch,
        noClass,
        style,
        getLabel,
        onFocus,
        onBlur,
        className,
        placeholder,
        children,
    }
) => {
    const [focused, setFocused] = useState(false);

    const emailInputRef: React.RefObject<HTMLInputElement> = useRef(null);

    useEffect(() => {
        setSearch('');
    }, [emails, setSearch]);

    const findEmailAddress = (value: string, isEnter?: boolean) => {
        setSearch(value);
    };

    const onChangeInputValue = (value: string) => {
        findEmailAddress(value);
    };

    const removeEmail = (index: number, isDisabled?: boolean) => {
        if (isDisabled) {
            return;
        }
        setEmails((prevEmails) => [
            ...prevEmails.slice(0, index),
            ...prevEmails.slice(index + 1),
        ]);
    };

    const handleOnKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.which) {
            case 13:
            case 9:
                e.preventDefault();
                break;
            case 8:
                if (!e.currentTarget.value) {
                    removeEmail(emails.length - 1, false);
                }
                break;
            default:
        }
    };

    const handleOnKeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.which) {
            case 13:
            case 9:
                findEmailAddress(e.currentTarget.value, true);
                break;
            default:
        }
    };

    const handleOnChange = (e: React.SyntheticEvent<HTMLInputElement>) => onChangeInputValue(e.currentTarget.value);

    const handleOnBlur = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setFocused(false);
        findEmailAddress(e.currentTarget.value, true);
        onBlur?.();
    };

    const handleOnFocus = () => {
        setFocused(true);
        onFocus?.();
    };

    return (
        <div
            className={`${className} ${noClass ? '' : 'react-multi-email'} ${
                focused ? 'focused' : ''
            } ${search === '' && emails.length === 0 ? 'empty' : ''}`}
            style={style}
            onClick={() => {
                if (emailInputRef.current) {
                    emailInputRef.current.focus();
                    onFocus?.();
                }
            }}
        >
            {placeholder ? <span data-placeholder={placeholder}>{placeholder}</span> : null}
            {emails.map((email: string, index: number) => getLabel(email, index, removeEmail))}
            <input
                ref={emailInputRef}
                type="text"
                value={search}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                onKeyDown={handleOnKeydown}
                onKeyUp={handleOnKeyup}
            />
            {children}
        </div>
    );
};

export default ReactItemInput;
