import React from 'react';
import {MoodboardDrawer} from 'mushin-node-moodboard';
import i18n from 'i18next';
import Spinner from '../../../Components/Spinner';
import TextIcon from '../../../Components/TextIcon/TextIcon';
import {classWithModifiers} from '../../../Helpers/css';
import {downloadFile} from '../../../Helpers/download';
import {exportToPdf, useCurrentPageSelector} from '../../../Redux/reducers/moodboardEditorSlice';
import {useAppDispatch, useAppSelector} from '../../../Helpers/hooks';

type Props = {
    format: 'jpg' | 'pdf';
    inProgress: boolean;
    setInProgress: (value: boolean) => void;
    title: string;
}

const DownloadDropdownItem: React.FC<Props> = ({
    format,
    inProgress,
    setInProgress,
    title,
}) => {
    const index = useAppSelector((state) => state.moodboardEditor.present.selectedPage);
    const board = useCurrentPageSelector((page) => page.board);

    const dispatch = useAppDispatch();

    return (
        <button
            type="button"
            disabled={inProgress}
            className={classWithModifiers('beDownload-item', {rendering: inProgress})}
            onClick={async () => {
                setInProgress(true);

                let url: string;
                if (format === 'jpg') {
                    const drawer = new MoodboardDrawer(board);
                    url = (await drawer.generateImage()).jpg;
                } else {
                    const base64 = await dispatch(exportToPdf(title, index));
                    url = `data:application/pdf;base64,${base64}`;
                }
                downloadFile(url, `${title} p${index + 1}.${format}`);

                setInProgress(false);
            }}
        >
            <div className="mu-toolbar__export">
                <TextIcon
                    label={`${i18n.t('components.boardEditor.page')} : ${format.toUpperCase()}`}
                    icon={format === 'jpg' ? 'Image' : 'Pdf'}
                />
                {inProgress && <Spinner size="12px" />}
            </div>
        </button>
    );
};

export default DownloadDropdownItem;
