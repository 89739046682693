import React from 'react';
import i18n from 'i18next';
import FormTextInput from '../../../../Components/Form/FormTextInput';
import Modal from '../../../../Components/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import {useFieldState} from '../../../../Components/Form/common';
import MuButtonWithLoader from '../../../../Components/MuButtonWithLoader';

type Props = React.PropsWithChildren<{
    onClose: () => void;
    labelKey: string;
    create: (title: string) => Promise<void>;
}>

const CreateWithTitleModal: React.FC<Props> = ({onClose, labelKey, create, children}) => {
    const labelField = useFieldState('');

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={onClose}>
            <ModalHeader
                title={i18n.t(`${labelKey}.create`)}
                icon="Brain"
            />
            {children}
            <div className="mu-form-row">
                <FormTextInput
                    placeholder={i18n.t(`${labelKey}.titlePlaceholder`, {year: new Date().getFullYear()})}
                    field={labelField}
                    required
                />
            </div>
            <ModalFooter>
                <MuButtonWithLoader
                    handleClick={async () => {
                        if (labelField.validate()) {
                            await create(labelField.value);
                            onClose();
                        }
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>
    );
};

export default CreateWithTitleModal;
