import React from 'react';
import {useNote} from 'mushin-redux-store';
import NoteThumbnail from '../../NoteThumbnail/NoteThumbnail';

type Props = {
    noteId: string;
    startDragElement: (event: React.DragEvent<HTMLDivElement>, data: {note: any}) => void;
}

const AddNotesPanelItem: React.FC<Props> = ({ noteId, startDragElement }) => {
    const note = useNote(noteId);

    if (!note) return null;

    return (
        <div
            className="mu-add-notes-panel__item"
            draggable
            onDragStart={(e) => startDragElement(e, { note })}
        >
            <NoteThumbnail note={note} />
        </div>
    );
};

export default AddNotesPanelItem;
