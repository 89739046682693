import React, {useEffect} from 'react';
import { classWithModifiers } from '../../Helpers/css';
import EditableTitle from '../EditableTitle/EditableTitle';
import Spinner from '../Spinner';
import BorderedIcon from '../BorderedIcon/BorderedIcon';
import MuIcon from '../MuIcon';

type FileUploadProgressProps = React.PropsWithChildren<{
    id: string;
    file: File;
    isPdf?: boolean;
    progress: number;
    status: 'inProgress' | 'waiting' | 'success' | 'error';
    statusMessage?: string;
    onFileTitleEdit?: (event: React.FocusEvent<HTMLHeadingElement>) => void;
}>;

const FileUploadProgress: React.FC<FileUploadProgressProps> = ({
    id,
    file,
    isPdf,
    onFileTitleEdit,
    progress,
    status,
    statusMessage = '',
    children,
}) => {
    useEffect(() => {
        if (!isPdf && FileReader) {
            const fr = new FileReader();
            fr.onload = () => {
                const img = document.getElementById(`upload-img-${id}`) as HTMLImageElement;
                if (img) {
                    img.src = fr.result as string;
                }
            };
            fr.readAsDataURL(file);
        }
    }, [file, id, isPdf]);

    const getFileSizeString = (size: number): string => {
        if (!size) return '';
        const mbSize = size / 1000000;
        return `${mbSize.toFixed(2)} Mb`;
    };

    const getIcon = (_status: 'inProgress' | 'waiting' | 'success' | 'error') => {
        if (_status === 'error') return 'Remove';
        if (_status === 'success') return 'Check';
        return 'Upload';
    };

    return (
        <div className={classWithModifiers('file-upload-progress', [status])}>
            <div className="file-upload-progress__picture">
                {isPdf ? (
                    <MuIcon svgName="Pdf" className="file-upload-progress__pdf" />
                ) : (
                    <img id={`upload-img-${id}`} alt="" className="file-upload-progress__img" />
                )}
                {status === 'inProgress' ? (
                    <div className="file-upload-progress__loader"><Spinner size="40px" weight={0.12} /></div>
                ) : (
                    <BorderedIcon svgName={getIcon(status)} className="file-upload-progress__icon" modifiers={status} />
                )}
                <progress className="file-upload-progress__progress-bar" value={progress} max="100" />
            </div>

            <section className="file-upload-progress__details file-upload-progress__details--ellipsis">
                <div className="file-upload-progress__infos">
                    <EditableTitle
                        className="file-upload-progress__title"
                        contentEditable={!!onFileTitleEdit}
                        onBlur={onFileTitleEdit && onFileTitleEdit}
                        defaultValue={file.name}
                    />
                    <span className="file-upload-progress__size">
                        {getFileSizeString(file.size)}
                    </span>
                    {statusMessage && (
                        <span className="file-upload-progress__status-message">
                            {statusMessage}
                        </span>
                    )}
                </div>
                {children}
            </section>
        </div>
    );
};

export default FileUploadProgress;
