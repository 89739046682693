import React, {ReactNode, useState} from 'react';
import {ActionBarItem} from '../ActionBar/ActionBar';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import TextIcon from '../TextIcon/TextIcon';
import {Align, Position} from '../../Helpers/position';

type Props = React.PropsWithChildren<{
    items: ActionBarItem[];
    toggle: ReactNode;
    position?: Position;
    align?: Align;
    className?: string;
    contentClassName?: string;
    preventDefault?: boolean;
}>

const ActionDropdownMenu: React.FC<Props> = (
    { items, toggle, position, align, className, contentClassName, preventDefault, children }
) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <DropdownMenu
            isOpen={isOpen}
            setIsOpen={setOpen}
            toggle={toggle}
            position={position}
            align={align}
            className={className}
            contentClassName={contentClassName}
            preventDefault={preventDefault}
        >
            {children}
            {items.map((action) => !action.hidden && (
                <button
                    key={action.icon}
                    type="button"
                    disabled={!!action.disabled}
                    className="mu-dropdown-menu__button-item"
                    onClick={() => {
                        setOpen(false);
                        action.handler();
                    }}
                >
                    <TextIcon icon={action.icon} label={action.label} />
                </button>
            ))}
        </DropdownMenu>
    );
};

export default ActionDropdownMenu;
