import * as React from 'react';
import {GetChatMessagesParams} from 'mushin-redux-store';
import i18n from 'i18next';
import SortBySelect from '../FiltersToolbar/Elements/SortBySelect';
import FiltersToolbar from '../FiltersToolbar/FiltersToolbar';
import {
    authorsFilter, favoritesFilter,
    groupsFilter,
} from '../FiltersToolbar/helpers';
import {FilterConfig} from '../FiltersToolbar/FilterPanelContent';
import InputText from '../InputText/InputText';
import { useInputWithDelay } from '../../Helpers/FormsHelpers/hooks';

type Props = {
    searchParams: GetChatMessagesParams,
    setSearchParams: (params: GetChatMessagesParams) => void,
};

const MessagesToolbar: React.FC<Props> = (
    {
        searchParams,
        setSearchParams,
    }
) => {
    const [currentQuery, setCurrentQuery] = useInputWithDelay(
        (query) => setSearchParams({ ...searchParams, query }),
        searchParams.query || '',
    );

    const filters: {
        [key: string]: FilterConfig
    } = {
        favoriteOfUsers: favoritesFilter(
            searchParams.favorite_of_users,
            (favorite_of_users) => setSearchParams({...searchParams, favorite_of_users}),
        ),
        authors: authorsFilter(
            searchParams.profiles,
            (profiles) => setSearchParams({...searchParams, profiles}),
            {isContributor: true, active: true}
        ),
        groups: groupsFilter(
            searchParams.groups,
            (groups) => setSearchParams({...searchParams, groups}),
        ),
    };

    return (
        <section className="mu-toolbar">
            <div className="mu-toolbar__search">
                <InputText
                    classModifiers={['full-width']}
                    leftIcon="Search"
                    value={currentQuery}
                    onChange={(e) => setCurrentQuery(e.target.value)}
                    placeholder={i18n.t('components.filters.search')}
                />
            </div>
            <SortBySelect
                sort={searchParams.sort}
                setSort={(sort) => setSearchParams({...searchParams, sort})}
                options={[
                    {key: '-created_at', icon: 'Calendar', label: i18n.t('components.sort.mostRecent')},
                    {key: '-favorites', icon: 'Star', label: i18n.t('components.sort.mostFavorites')}
                ]}
                defaultSort="-created_at"
            />
            <FiltersToolbar
                filters={filters}
                resetAll={() => setSearchParams({})}
            />
        </section>
    );
};

export default MessagesToolbar;
