import React, {useEffect, useState} from 'react';

import i18n from 'i18next';
import { templates as rawTemplateTrees } from '../BoardLayoutTemplate/templates';
import { TemplateList } from './component/TemplateList';
import { RatioSelector } from './component/RatioSelector';
import { MarginSelector } from './component/MarginSelector';
import {buildWithMarginTop} from '../BoardLayoutTemplate/templateBuilder';
import {useAppDispatch} from '../../../Helpers/hooks';
import {setTemplate, useCurrentPageSelector} from '../../../Redux/reducers/moodboardEditorSlice';

export const TemplateSelector: React.FC = () => {
    const templateId = useCurrentPageSelector((page) => page.boardData.templateId);
    const width = useCurrentPageSelector((page) => page.board.width);
    const height = useCurrentPageSelector((page) => page.board.height);
    const boardMargin = useCurrentPageSelector((page) => page.board.margin || 10);

    const [ratio, setRatio] = useState(height / width);
    const [margin, setMargin] = useState(boardMargin);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setRatio(height / width);
    }, [height, width]);

    useEffect(() => {
        setMargin(boardMargin);
    }, [boardMargin]);

    const setTemplateId = (newTemplateId: number) => {
        if (!rawTemplateTrees[newTemplateId]) return;

        const template = buildWithMarginTop(rawTemplateTrees[newTemplateId], width, width * ratio, margin);

        dispatch(setTemplate(template, newTemplateId));
    };

    const changeMargin = (newMargin: number) => {
        setMargin(newMargin);

        if (!templateId || !rawTemplateTrees[templateId]) return;

        const template = buildWithMarginTop(rawTemplateTrees[templateId], width, width * ratio, newMargin);

        dispatch(setTemplate(template, templateId));
    };

    const changeRatio = (newRatio: number) => {
        setRatio(newRatio);

        if (!templateId || !rawTemplateTrees[templateId]) return;

        const template = buildWithMarginTop(rawTemplateTrees[templateId], width, width * newRatio, margin);

        dispatch(setTemplate(template, templateId));
    };

    const templates = rawTemplateTrees.map((rawTemplateTree) => buildWithMarginTop(
        rawTemplateTree, width, width * ratio, margin
    ));

    return (
        <div className="beTemplateSelector">
            <div className="beTemplateSelector-ratioSelector">
                <div className="beTemplateSelector-label">
                    {i18n.t('components.boardEditor.aspectRatio')}
                </div>
                <RatioSelector ratio={ratio} setRatio={changeRatio} />
            </div>
            <div className="beTemplateSelector-templateList">
                <div className="beTemplateSelector-label">
                    {i18n.t('components.boardEditor.template')}
                </div>
                <TemplateList setTemplateId={setTemplateId} width={width} height={height} templates={templates} templateId={templateId} />
            </div>
            <div className="beTemplateSelector-marginSelector">
                <div className="beTemplateSelector-label">
                    {i18n.t('components.boardEditor.margin')}
                </div>
                <MarginSelector margin={margin} setMargin={changeMargin} />
            </div>
        </div>
    );
};
