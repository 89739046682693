import buildMatchPatternFn from '../../../_lib/buildMatchPatternFn/index';
import buildMatchFn from '../../../_lib/buildMatchFn/index';
import type { Match } from '../../../types';

const matchOrdinalNumberPattern = /^(\d+)(º)?/i;
const parseOrdinalNumberPattern = /\d+/i;

const matchEraPatterns = {
    narrow: /^(ac|dc|a|d)/i,
    abbreviated: /^(a\.?\s?c\.?|a\.?\s?e\.?\s?c\.?|d\.?\s?c\.?|e\.?\s?c\.?)/i,
    wide: /^(antes de cristo|antes de la era com[uú]n|despu[eé]s de cristo|era com[uú]n)/i,
};
const parseEraPatterns = {
    any: [/^ac/i, /^dc/i] as const,
    wide: [
        /^(antes de cristo|antes de la era com[uú]n)/i,
        /^(despu[eé]s de cristo|era com[uú]n)/i,
    ] as const,
};

const matchQuarterPatterns = {
    narrow: /^[1234]/i,
    abbreviated: /^T[1234]/i,
    wide: /^[1234](º)? trimestre/i,
};
const parseQuarterPatterns = {
    any: [/1/i, /2/i, /3/i, /4/i] as const,
};

const matchMonthPatterns = {
    narrow: /^[efmajsond]/i,
    abbreviated: /^(ene|feb|mar|abr|may|jun|jul|ago|sep|oct|nov|dic)/i,
    wide: /^(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|octubre|noviembre|diciembre)/i,
};
const parseMonthPatterns = {
    narrow: [
        /^e/i,
        /^f/i,
        /^m/i,
        /^a/i,
        /^m/i,
        /^j/i,
        /^j/i,
        /^a/i,
        /^s/i,
        /^o/i,
        /^n/i,
        /^d/i,
    ] as const,
    any: [
        /^en/i,
        /^feb/i,
        /^mar/i,
        /^abr/i,
        /^may/i,
        /^jun/i,
        /^jul/i,
        /^ago/i,
        /^sep/i,
        /^oct/i,
        /^nov/i,
        /^dic/i,
    ] as const,
};

const matchDayPatterns = {
    narrow: /^[dlmjvs]/i,
    short: /^(do|lu|ma|mi|ju|vi|s[áa])/i,
    abbreviated: /^(dom|lun|mar|mi[ée]|jue|vie|s[áa]b)/i,
    wide: /^(domingo|lunes|martes|mi[ée]rcoles|jueves|viernes|s[áa]bado)/i,
};
const parseDayPatterns = {
    narrow: [/^d/i, /^l/i, /^m/i, /^m/i, /^j/i, /^v/i, /^s/i] as const,
    any: [/^do/i, /^lu/i, /^ma/i, /^mi/i, /^ju/i, /^vi/i, /^sa/i] as const,
};

const matchDayPeriodPatterns = {
    narrow: /^(a|p|mn|md|(de la|a las) (mañana|tarde|noche))/i,
    any: /^([ap]\.?\s?m\.?|medianoche|mediodia|(de la|a las) (mañana|tarde|noche))/i,
};
const parseDayPeriodPatterns = {
    any: {
        am: /^a/i,
        pm: /^p/i,
        midnight: /^mn/i,
        noon: /^md/i,
        morning: /mañana/i,
        afternoon: /tarde/i,
        evening: /tarde/i,
        night: /noche/i,
    },
};

const match: Match = {
    ordinalNumber: buildMatchPatternFn({
        matchPattern: matchOrdinalNumberPattern,
        parsePattern: parseOrdinalNumberPattern,
        valueCallback(value) {
            return parseInt(value, 10);
        },
    }),

    era: buildMatchFn({
        matchPatterns: matchEraPatterns,
        defaultMatchWidth: 'wide',
        parsePatterns: parseEraPatterns,
        defaultParseWidth: 'any',
    }),

    quarter: buildMatchFn({
        matchPatterns: matchQuarterPatterns,
        defaultMatchWidth: 'wide',
        parsePatterns: parseQuarterPatterns,
        defaultParseWidth: 'any',
        valueCallback: (index) => (index + 1) as Quarter,
    }),

    month: buildMatchFn({
        matchPatterns: matchMonthPatterns,
        defaultMatchWidth: 'wide',
        parsePatterns: parseMonthPatterns,
        defaultParseWidth: 'any',
    }),

    day: buildMatchFn({
        matchPatterns: matchDayPatterns,
        defaultMatchWidth: 'wide',
        parsePatterns: parseDayPatterns,
        defaultParseWidth: 'any',
    }),

    dayPeriod: buildMatchFn({
        matchPatterns: matchDayPeriodPatterns,
        defaultMatchWidth: 'any',
        parsePatterns: parseDayPeriodPatterns,
        defaultParseWidth: 'any',
    }),
};

export default match;
