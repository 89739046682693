import React from 'react';
import i18n from 'i18next';
import InputText from '../../../../Components/InputText/InputText';
import {openModal} from '../../../../Redux/reducers/modalsSlice';
import {useAppDispatch} from '../../../../Helpers/hooks';
import MuButton from '../../../../Components/MuButton';
import Client from '../../../../Services/Client';

export type MembersSearchParams = {
    name_search?: string;
}

type Props = {
    searchParams: MembersSearchParams;
    setSearchParams: (searchParams: MembersSearchParams) => void;
};

const GroupsToolbar: React.FC<Props> = ({
    searchParams,
    setSearchParams,
}) => {
    const dispatch = useAppDispatch();

    return (
        <section className="mu-toolbar">
            <div className="mu-toolbar__search">
                <InputText
                    classModifiers={['full-width', 'toolbar']}
                    value={searchParams.name_search}
                    onChange={(e) => setSearchParams({...searchParams, name_search: e.target.value})}
                    placeholder={i18n.t('components.filters.search')}
                />
            </div>
            {Client.isAllowedToEditOrganization() && (
                <MuButton
                    classModifiers="toolbar"
                    handleClick={() => dispatch(openModal('CreateAGroup'))}
                    icon="Plus"
                    label={i18n.t('administration.groups.addAGroup')}
                />
            )}
        </section>
    );
};

export default GroupsToolbar;
