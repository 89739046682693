import React, { useEffect, useRef, useState } from 'react';
import {
    markChatNotificationAsRead,
    ChatRequests,
    ProfilesQueryParams,
} from 'mushin-redux-store';
import i18n from 'i18next';
import Discussion from '../../Components/Discussion/Discussion';
import ChatMessage from '../../Components/ChatMessage/ChatMessage';
import ChatMessageSeparator from './ChatMessageSeparator';
import MuIcon from '../../Components/MuIcon';
import {useAppDispatch} from '../../Helpers/hooks';
import {useSearchParams} from '../../Helpers/queryParser';
import CountNotifications from '../../Components/CountNotifications/CountNotifications';
import { useClickOutside } from '../../Helpers/effects';
import { classWithModifiers } from '../../Helpers/css';

type Props = {
    entity_id: string;
    entity_type: string;
    chatId: string | undefined;
    userMentionsQueryParams?: ProfilesQueryParams;
}

const Chat: React.FC<Props> = ({ entity_id, entity_type, chatId, userMentionsQueryParams }) => {
    const [firstUnreadMessage, setFirstUnreadMessage] = useState<string>();
    const [isOpen, setOpen] = useState(false);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const [searchParams] = useSearchParams<{ msg?: string }>();
    const dispatch = useAppDispatch();

    const container = useRef<HTMLDivElement>(null);

    useClickOutside(container, isOpen, () => {
        setOpen(false);
    });

    useEffect(() => {
        if (searchParams.msg) {
            setOpen(true);
        }
    }, [searchParams]);

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;
        if (chatId) {
            ChatRequests.getNotification(chatId).then((res) => {
                const notification = res.data[0];
                if (notification) {
                    setUnreadMessagesCount(notification.seen ? 0 : notification.objects.length);
                    if (isOpen && notification && !notification.seen) {
                        setFirstUnreadMessage(notification.objects[0].object_id);
                        timeout = setTimeout(() => {
                            dispatch(markChatNotificationAsRead(notification._id)).then(() => {
                                setUnreadMessagesCount(0);
                            });
                        }, 1000);
                    }
                }
            });
        }

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [chatId, dispatch, isOpen]);

    return (
        <div
            ref={container}
            onClick={() => setOpen(false)}
            className={classWithModifiers('mu-gallery__chat', {'is-open': isOpen})}
        >
            {isOpen && (
                <section onClick={(e) => e.stopPropagation()} className="mu-gallery__chat-panel">
                    <h2 className="mu-gallery__chat-title">
                        {i18n.t('components.chat.title')}
                    </h2>
                    <Discussion
                        entity_id={entity_id}
                        entity_type={entity_type}
                        userMentionsQueryParams={userMentionsQueryParams}
                        goToMessage={searchParams.msg || firstUnreadMessage}
                        renderMessage={(id) => (
                            <ChatMessage key={id} id={id} userMentionsQueryParams={userMentionsQueryParams} />
                        )}
                        renderSeparator={(prevId, nextId) => (
                            <ChatMessageSeparator
                                key={`${nextId}-sep`}
                                previousMessageId={prevId}
                                nextMessageId={nextId}
                                firstUnreadMessageId={firstUnreadMessage}
                            />
                        )}
                        classModifier="chat"
                    />
                </section>
            )}

            <button
                type="button"
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!isOpen);
                }}
                className="mu-gallery__chat-btn"
            >
                <CountNotifications nb={unreadMessagesCount}>
                    <MuIcon className="mu-gallery__chat-btn-icon" svgName={isOpen ? 'ArrowDown' : 'Chat'} />
                </CountNotifications>
            </button>

        </div>
    );
};

export default Chat;
