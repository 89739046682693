import React, {useState} from 'react';
import i18n from 'i18next';
import {
    getQuestionnaire,
    getQuestionnairesQuery,
    Project, Question,
    QuestionAnswersQueryResult,
    QuestionItems,
    QuestionRanking,
    QuestionRating,
    QuestionAnswerRequests,
} from 'mushin-redux-store';
import MuIcon from '../../../../../Components/MuIcon';
import MuButtonWithLoader from '../../../../../Components/MuButtonWithLoader';
import {useAppDispatch} from '../../../../../Helpers/hooks';
import {deltaToText} from '../../../../../Helpers/delta';
import {roundWithPrecision} from '../../../../../Helpers/math';

type Props = {
    project: Project;
}

const getItemsPrompt = (question: QuestionItems, answers: QuestionAnswersQueryResult<QuestionItems>) => {
    let prompt = '';
    const items = [...question.items];
    answers.stats.forEach(({value, ratio}) => {
        const index = items.findIndex((item) => item.slug === value);
        if (index < 0) {
            prompt += ` - ${value} : ${ratio * 100}%\n`;
        } else {
            const item = items.splice(index, 1)[0];
            prompt += ` - ${item.label} : ${ratio * 100}%\n`;
        }
    });
    items.forEach((item) => {
        prompt += ` - ${item.label} : 0%\n`;
    });
    return prompt;
};

const getRatingPrompt = (question: QuestionRating, answers: QuestionAnswersQueryResult<QuestionRating>) => (
    `Note moyenne : ${roundWithPrecision(answers.average, 2)} / ${question.maxRatingValue}\n`
);

const getRankingPrompt = (question: QuestionRanking, answers: QuestionAnswersQueryResult<QuestionRanking>) => {
    let prompt = '';
    let absoluteRank = 0;
    let lastAverageRank = 0;
    const items = [...question.items || []];
    answers.stats.forEach(({value, average}, statIndex) => {
        if (average !== lastAverageRank) absoluteRank = statIndex + 1;
        lastAverageRank = average;
        const index = items.findIndex((item) => item.slug === value);
        if (index < 0) {
            prompt += ` - Rang #${absoluteRank} : ${value}\n`;
        } else {
            const item = items.splice(index, 1)[0];
            prompt += ` - Rang #${absoluteRank} : ${item.label}\n`;
        }
    });
    items.forEach((item) => {
        prompt += ` - Rang #${answers.stats.length + 1} : ${item.label}\n`;
    });
    return prompt;
};

const IntelligentSummary: React.FC<Props> = ({ project }) => {
    const [generatedPrompt, setPrompt] = useState('');
    const dispatch = useAppDispatch();

    const analyze = async () => {
        let prompt = '';
        const questionnaires = await dispatch(getQuestionnairesQuery({
            project_id: project.id, sort: 'start_date',
        }, true, true));
        for (const [questionnaireIndex, questionnaireId] of (questionnaires?.data || []).entries()) {
            // eslint-disable-next-line no-await-in-loop
            const questionnaire = await dispatch(getQuestionnaire(questionnaireId));
            prompt += `Questionnaire ${questionnaireIndex + 1} : ${questionnaire?.title}\n\n`;
            for (const [questionIndex, question] of (questionnaire?.questions || []).entries()) {
                // eslint-disable-next-line no-await-in-loop
                const answers = await QuestionAnswerRequests.getAll<Question>(
                    { questionnaire_id: questionnaireId, question_id: question.id },
                );

                prompt += `Question ${questionIndex + 1} (${i18n.t(`questionEdition.${question.type}`)}) :`;
                prompt += ` ${deltaToText(question.label)}\n`;
                if (question.type === 'free') {
                    if (question.analysis) {
                        prompt += `Analyse :\n${question.analysis}\n`;
                    } else {
                        prompt += 'Analyse non disponible';
                    }
                } else if (question.type === 'rating') {
                    prompt += getRatingPrompt(question, answers.data);
                } else if (question.type === 'ranking') {
                    prompt += getRankingPrompt(question, answers.data);
                } else if (question.type === 'radios' || question.type === 'checkboxes') {
                    prompt += getItemsPrompt(question, answers.data);
                }
                prompt += '\n';
            }
            prompt += '\n';
        }

        setPrompt(prompt);
    };

    return (
        <div className="mu-synthesis__panel">
            <div className="mu-synthesis-ai">
                <div className="mu-synthesis-ai__texticon">
                    <span className="mu-synthesis-ai__span">{i18n.t('IntelligentSummary.new')}</span>
                    <MuIcon svgName="MushinAI" className="mu-synthesis-ai__icon" />
                </div>
                <p className="mu-synthesis-ai__text">
                    {i18n.t('IntelligentSummary.text')}
                    <br />
                    <span>{i18n.t('IntelligentSummary.text2')}</span>
                </p>
                <MuButtonWithLoader
                    className="mu-synthesis-ai__button"
                    label={i18n.t(`IntelligentSummary.${generatedPrompt ? 'copy' : 'button'}`)}
                    handleClick={(
                        generatedPrompt
                            ? () => navigator.clipboard.writeText(generatedPrompt)
                            : analyze
                    )}
                />
            </div>
        </div>
    );
};
export default IntelligentSummary;
