import React, {useState} from 'react';
import {
    Note,
} from 'mushin-redux-store';
import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuIcon from '../../../../Components/MuIcon';
import MuButton from '../../../../Components/MuButton';
import AddIdeaModalNotes from './AddIdeaModalNotes';
import DualChoice from '../../../../Components/DualChoice/DualChoice';
import ImageThumbnail from '../../../../Components/ImageThumbnail/ImageThumbnail';
import Spinner from '../../../../Components/Spinner';
import AddIdeaModalNoteTextInput from './AddIdeaModalNoteTextInput';
import { NOTE_MAX_LENGTH } from '../../../../constants';
import { isVideoFile } from '../../../../Helpers/video';
import VideoThumbnail from '../../../../Components/VideoThumbnail/VideoThumbnail';

export type AddModalImages = {
    images: File[],
    notes: Note[]
}

type Props = {
    onClose: () => void;
    addImages: (imagesToSend: AddModalImages) => void;
    addText?: (text: string) => void;
    multipleSelection?: boolean;
    withPdf?: boolean;
    withVideo?: boolean;
    withNotes?: boolean;
    textNotes?: boolean;
};

const NOTE_TYPE_IMAGE = 'image';
const NOTE_TYPE_TEXT = 'text';
type NoteType = typeof NOTE_TYPE_IMAGE | typeof NOTE_TYPE_TEXT | null;

const IMAGE_MODE_COMPUTER = 'computer';
const IMAGE_MODE_MUSHIN = 'mushin';
type ImageSelectionMode = typeof IMAGE_MODE_COMPUTER | typeof IMAGE_MODE_MUSHIN | null;

const AddIdeaModal: React.FC<Props> = ({
    onClose,
    addImages,
    addText,
    multipleSelection = true,
    withPdf = false,
    withVideo = false,
    withNotes = true,
    textNotes = true,
}) => {
    const [noteType, setNoteType] = useState<NoteType>(null);
    const [text, setText] = useState('');
    const [imageSelectionMode, setImageSelectionMode] = useState<ImageSelectionMode>(null);
    const [selectedNotes, setSelectedNotes] = useState<Note[]>([]);
    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);

    const noteTypeChoice = textNotes ? noteType : NOTE_TYPE_IMAGE;

    const disabled = (
        loading
        || !noteTypeChoice
        || (noteTypeChoice === NOTE_TYPE_IMAGE && !imageSelectionMode)
        || (noteType === NOTE_TYPE_TEXT && text.length < 1)
        || (noteType === NOTE_TYPE_TEXT && text.length > NOTE_MAX_LENGTH)
        || (imageSelectionMode === IMAGE_MODE_COMPUTER && !imageFiles.length)
        || (imageSelectionMode === IMAGE_MODE_MUSHIN && !selectedNotes.length)
    );

    const getFileThumbnail = (file: File) => {
        if (file.type === 'application/pdf') {
            return (
                <MuIcon svgName="Pdf" />
            );
        }

        if (isVideoFile(file)) {
            return <VideoThumbnail video={file} />;
        }

        return (
            <ImageThumbnail
                imgUrl={URL.createObjectURL(file)}
            />
        );
    };

    return (
        <Modal handleRemoveModal={onClose}>
            <ModalHeader
                title={noteTypeChoice === NOTE_TYPE_IMAGE
                    ? i18n.t('modals.addImages.title')
                    : i18n.t('global.publishContent')}
                icon="Image"
            />
            {textNotes && !noteType && (
                <DualChoice
                    className="mu-modal__body"
                    value={noteType}
                    setValue={setNoteType as ((val: string | null) => void)}
                    choice1={{
                        key: NOTE_TYPE_IMAGE,
                        label: i18n.t('models.notes.types.image'),
                        icon: 'ImageGallery',
                    }}
                    choice2={{
                        key: NOTE_TYPE_TEXT,
                        label: i18n.t('models.notes.types.text'),
                        icon: 'Chat',
                    }}
                />
            )}
            {noteTypeChoice === NOTE_TYPE_IMAGE && imageSelectionMode !== IMAGE_MODE_MUSHIN && (
                <DualChoice
                    className="mu-modal__body"
                    value={imageSelectionMode}
                    setValue={setImageSelectionMode as ((val: string | null) => void)}
                    choice1={{
                        key: IMAGE_MODE_COMPUTER,
                        label: i18n.t('modals.addImages.fromComputer'),
                        icon: 'Import',
                        fileInput: {
                            id: 'add-image-from-computer',
                            multiple: multipleSelection,
                            accept: `image/* ${withPdf ? ',.pdf' : ''} ${
                                withVideo ? ',video/mp4,video/x-m4v,video/*' : ''
                            }`,
                            onChange: setImageFiles,
                        },
                    }}
                    choice2={withNotes && {
                        key: IMAGE_MODE_MUSHIN,
                        label: i18n.t('modals.addImages.fromMushin'),
                        icon: 'Select',
                    }}
                />
            )}

            {(
                noteTypeChoice === NOTE_TYPE_IMAGE
                && imageSelectionMode === IMAGE_MODE_COMPUTER
                && imageFiles.length > 0
            ) && (
                <ul className="mu-add-images-modal__notes">
                    {imageFiles.map((image) => {
                        return (
                            <div key={image.name}>
                                <li className="mu-add-images-modal__note-item">
                                    {getFileThumbnail(image)}
                                </li>
                            </div>
                        );
                    })}
                </ul>
            )}

            {noteTypeChoice === NOTE_TYPE_IMAGE && imageSelectionMode === IMAGE_MODE_MUSHIN && (
                <AddIdeaModalNotes
                    selectedNotes={selectedNotes}
                    setSelectedNotes={setSelectedNotes}
                    multipleSelection={multipleSelection}
                />
            )}

            {noteTypeChoice === NOTE_TYPE_TEXT && (
                <div>
                    <AddIdeaModalNoteTextInput text={text} onChangeText={setText} />
                </div>
            )}

            <ModalFooter>
                {(noteType || imageSelectionMode === IMAGE_MODE_MUSHIN) && (
                    <MuButton
                        label={i18n.t('global.back')}
                        handleClick={() => {
                            if (imageSelectionMode) {
                                setImageSelectionMode(null);
                            } else {
                                setNoteType(null);
                            }
                        }}
                        classModifiers={['default']}
                    />
                )}
                {loading && (
                    <Spinner size="24px" />
                )}
                <MuButton
                    handleClick={async () => {
                        setLoading(true);
                        try {
                            if (noteType === NOTE_TYPE_TEXT) {
                                if (addText) await addText(text);
                                onClose();
                            } else {
                                const imagesToSend: AddModalImages = (
                                    imageSelectionMode === IMAGE_MODE_COMPUTER
                                        ? {images: imageFiles, notes: []}
                                        : {images: [], notes: selectedNotes}
                                );
                                onClose();
                                await addImages(imagesToSend);
                            }
                        } catch (e) {
                            Sentry.captureException(e, {});
                        }
                        setLoading(false);
                    }}
                    disabled={disabled}
                    label={i18n.t('global.validate')}
                />
            </ModalFooter>
        </Modal>
    );
};

export default AddIdeaModal;
