import React from 'react';
import MuIcon from '../MuIcon';

type Props = {
    toggleDropdown: () => void;
};

export const MoreOptionsPreviewBlockBtn: React.FC<Props> = ({
    toggleDropdown,
}) => (
    <button
        type="button"
        className="mu-album-preview__more-options-btn"
        onClick={(e) => {
            e.stopPropagation();
            toggleDropdown();
        }}
    >
        <MuIcon svgName="MoreOptions" />
    </button>
);
