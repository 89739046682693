import React, {useEffect, useMemo, useState} from 'react';
import {GetChatMessagesParams, NoteQueryParams, useChatMessagesQuery} from 'mushin-redux-store';
import i18n from 'i18next';
import {useParams} from 'react-router-dom';
import {classWithModifiers} from '../../../../Helpers/css';
import GalleryImageViewModal from '../ImageViewModal/GalleryImageViewModal';
import EmptyZone from '../../../../Components/EmptyZone/EmptyZone';
import Spinner from '../../../../Components/Spinner';
import GallerySelectionBar from '../GallerySelectionBar/GallerySelection';
import {useSearchParams} from '../../../../Helpers/queryParser';
import {NoteSearchParams} from '../../../../Components/GalleryToolbar/GalleryToolbar';
import {useScrollThreshold} from '../../../../Helpers/effects';
import {GalleryParams} from '../../types';
import MessagesToolbar from '../../../../Components/GalleryToolbar/MessagesToolbar';
import MessageGridItem from '../../../../Components/MessageGridItem/MessageGridItem';

const buildQueryParams = (
    album_id: string | undefined,
    project_id: string | undefined,
    organization_id: string | undefined,
    params: NoteSearchParams,
): NoteQueryParams => {
    const queryParams = {...params};
    if (album_id) {
        queryParams.album_id = album_id;
    }
    queryParams.organization_id = organization_id;
    queryParams.project_id = project_id;
    return queryParams as NoteQueryParams;
};

const GalleryMessages: React.FC<React.PropsWithChildren> = ({ children }) => {
    const {
        album_id: albumId,
        inspirationType,
        organizationId,
        projectId,
        note_id,
    } = useParams<GalleryParams>();

    const [selectedMessageIds, setSelectedMessageIds] = useState<string[]>([]);

    const [searchParams, setSearchParams] = useSearchParams<GetChatMessagesParams & {comment?: string}>({
        profiles: 'array', users: 'array', groups: 'array', favorite_of_users: 'array',
    });
    const messagesQuery = useChatMessagesQuery(
        buildQueryParams(albumId, projectId, organizationId, searchParams),
        !Object.keys(searchParams).length,
    );
    const {setParams} = messagesQuery;
    useEffect(() => {
        if (!note_id && !searchParams.comment) {
            setParams(
                buildQueryParams(albumId, projectId, organizationId, searchParams),
                !Object.keys(searchParams).length,
            );
        }
    }, [albumId, inspirationType, note_id, organizationId, projectId, searchParams, setParams]);

    const scrollRef = useScrollThreshold(messagesQuery.getMoreResults, { deps: [messagesQuery.results?.length] });

    const userMentionsQueryParams = useMemo(
        () => ({
            confirmed: true,
            ...(albumId ? {album_id: albumId} : {}),
            ...(projectId ? {project_id: projectId} : {}),
        }),
        [albumId, projectId]
    );

    return (
        <div ref={scrollRef} className="mu-app-layout__scroll mu-gallery">
            <GalleryImageViewModal
                noteIds={[]}
                userMentionsQueryParams={userMentionsQueryParams}
            />
            <main
                ref={scrollRef}
                className={classWithModifiers('mu-gallery__content',
                    [{ messages: true }]
                )}
            >
                {children}
                <div className="mu-gallery__content-header">
                    <MessagesToolbar
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                    />
                </div>
                {messagesQuery.results && (
                    messagesQuery.results?.length ? messagesQuery.results.map((messageId) => (
                        <MessageGridItem
                            className="mu-gallery__message"
                            key={messageId}
                            messageId={messageId}
                            userMentionsQueryParams={userMentionsQueryParams}
                        />
                    )) : !messagesQuery.loading && (
                        <EmptyZone
                            title={i18n.t('gallery.no_files.title')}
                            subTitle={i18n.t('gallery.no_files.desc')}
                        />
                    )
                )}
                {messagesQuery.loading && (
                    <Spinner className="mu-gallery__loader" size="50px" />
                )}
            </main>
            {selectedMessageIds.length > 0 && (
                <GallerySelectionBar
                    type="message"
                    count={selectedMessageIds.length}
                    allCount={messagesQuery.pager?.total_items || 0}
                    empty={() => setSelectedMessageIds([])}
                    selectAll={() => setSelectedMessageIds(messagesQuery.results as string[])}
                />
            )}
        </div>
    );
};

export default GalleryMessages;
