import React from 'react';
import MuIcon from '../../../../Components/MuIcon';

type Props = {
    onClick: () => void;
};

const NewsButton: React.FC<Props> = ({ onClick }) => (
    <button type="button" className="mu-edit-button mu-edit-button__news" onClick={onClick}>
        <MuIcon className="mu-edit-button__icon " svgName="ArrowDown" />
    </button>
);

export default NewsButton;
