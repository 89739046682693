import React, {useState} from 'react';
import {updateNote, useNotes} from 'mushin-redux-store';
import {useDispatch} from 'react-redux';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal/Modal';
import MuButton from '../../../../Components/MuButton/MuButton';
import Tags, { DOM_DATA_ATT_add_tag } from '../../../../Components/Tags/Tags';
import NoteSummary from './components/NoteSummary';
import { blurIfHasFocus } from '../../../../Helpers/manipulateDom';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import {ModalBody} from '../../../../Components/Modal/ModalBody';
import {addTagToNote, removeTagFromNote} from '../../../../Helpers/noteHelpers';

type HandlerFunction = (...params: any[]) => void;

type Props = {
    onClose: () => void;
    noteIds: string[];
    validationWrapper?: (handler: HandlerFunction) => HandlerFunction;
};

const EditFilesModal: React.FC<Props> = (
    {
        onClose,
        noteIds,
        validationWrapper = (handler) => handler,
    }
) => {
    const [tagsForAll, setTagsForAll] = useState<string[]>([]);
    const notes = useNotes(noteIds);

    const dispatch = useDispatch();

    const handleSubmit = () => {
        // AN ALERT to say 'end of editing ?'
        onClose();
    };

    const closeModal = () => {
        // If user click outside during editing tag, trigger blur event to saving modification
        blurIfHasFocus(DOM_DATA_ATT_add_tag);
        onClose();
    };

    return (
        <Modal classModifiers={['large']} handleRemoveModal={closeModal}>
            <ModalHeader icon="Edit" title={i18n.t('modal.multiselect_update.title')} />
            <ModalBody>
                {notes && notes.length > 1 && (
                    <>
                        <p className="mu-modal__catchphrase">
                            {i18n.t('modal.multiselect_update.catchphrase')}
                        </p>
                        <Tags
                            placeholder={i18n.t<string>('notes.add_tag_to_all')}
                            tags={tagsForAll}
                            selectedTags={tagsForAll}
                            handleAddTag={(tag) => {
                                const newTags = [...tagsForAll];
                                newTags.push(tag);
                                setTagsForAll(newTags);
                                notes.forEach((note) => note && dispatch(addTagToNote(note, tag)));
                            }}
                            handleRemoveTag={(tag) => {
                                const newTags = [...tagsForAll];
                                const tagIndex = newTags.indexOf(tag);
                                if (tagIndex > -1) {
                                    newTags.splice(tagIndex, 1);
                                }
                                setTagsForAll(newTags);
                                notes.forEach((note) => note && dispatch(removeTagFromNote(note, tag)));
                            }}
                        />
                        <hr />
                    </>
                )}
                <section>
                    {notes?.map((note) => {
                        return note && (
                            <NoteSummary
                                key={note.id}
                                handleEditTitle={(label) => {
                                    dispatch(updateNote(note.id, { label }));
                                }}
                                handleEditContent={(value) => {
                                    dispatch(updateNote(note.id, { content: value }));
                                }}
                                note={note}
                                addNoteTag={(tag) => dispatch(addTagToNote(note, tag))}
                                removeNoteTag={(tag) => dispatch(removeTagFromNote(note, tag))}
                            />
                        );
                    })}
                </section>
            </ModalBody>
            <ModalFooter>
                <section className="mu-modal-footer__buttons">
                    <MuButton
                        disabled={!notes}
                        label={i18n.t('global.close')}
                        handleClick={validationWrapper(handleSubmit)}
                        classModifiers={['large']}
                    />
                </section>
            </ModalFooter>
        </Modal>
    );
};

export default EditFilesModal;
