import React from 'react';
import { TemplateIcon } from '../../BoardLayoutTemplate/component/TemplateIcon';

const groupTemplate = (templates) => Array.from({
    length: Math.max(0, ...templates.map(({
        ids,
    }) => ids.length)) + 1,
}).map((_, i) => templates.map((template, id) => ({ template, id })).filter(({
    template,
}) => i === Object.values(template.contents).filter((content) => content.type !== 'whiteCol').length)).filter((arr) => arr.length > 0);

export const TemplateList = ({
    templates,
    templateId,
    width,
    height,
    setTemplateId,
}) => (
    <div className="beTemplateList">
        {groupTemplate(templates).map((templates, i) => (
            <div className="beTemplateList-group" key={i}>
                <div className="beTemplateList-groupSeparator" />
                {templates.map(({
                    template,
                    id,
                }) => (
                    <div className={`beTemplateList-template${templateId === id ? ' beTemplateList-template--selected' : ''}`} key={id} onClick={() => setTemplateId(id)}>
                        <TemplateIcon template={template} width={width} height={height} color={templateId === id ? '#0000ff' : '#E5E5E5'} />
                    </div>
                ))}
            </div>
        ))}
    </div>
);
