import React, {useState} from 'react';
import FilterPanelContent, {FilterConfig} from './FilterPanelContent';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import TextIcon from '../TextIcon/TextIcon';
import {classWithModifiers} from '../../Helpers/css';

type Props = {
    config: FilterConfig
}

const DefaultFilter: React.FC<Props> = ({config}) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <DropdownMenu
            contentClassName="mu-filters-toolbar__panel"
            isOpen={isOpen}
            setIsOpen={setOpen}
            toggle={(
                <span className={classWithModifiers('mu-filters-toolbar__filter-toggle', 'default')}>
                    <TextIcon icon={config.icon} label={config.label} />
                    {config.value && (
                        <div className={classWithModifiers('mu-filters-toolbar__filter-value', 'default')}>
                            {config.value}
                        </div>
                    )}
                </span>
            )}
            align="center"
        >
            <FilterPanelContent
                filter={config}
                requestClose={() => {
                    setOpen(false);
                }}
            />
        </DropdownMenu>
    );
};

export default DefaultFilter;
