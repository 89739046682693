import {ApiV3} from 'mushin-redux-store';
import {AsyncAppThunk} from '../Redux/reducers';
import { splitFileExtAndName } from './filename';

export const uploadFile = (
    name: string, file: File,
): AsyncAppThunk<string> => async (dispatch, getState): Promise<string> => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const organizationId = getState().auth.currentOrganizationId as string;
    const data = new FormData();
    const { ext } = splitFileExtAndName(file.name);
    data.append('file', file, `${name}_${Date.now()}.${ext}`);
    data.append('organization_id', organizationId);
    return ApiV3.getInstance().postForm('/upload_tmp_files', data).then((r) => {
        return r.data;
    }).catch((e) => console.error(e));
};
