import * as ExcelJS from 'exceljs';
import i18n from 'i18next';
import {
    addMemberToCriterion,
    addProjectContributors,
    AvailableLocale,
    createCriterion,
    inviteUser,
    User
} from 'mushin-redux-store';
import {AsyncAppThunk} from '../../Redux/reducers';

export const IMPORT_CRITERIA_ACTION_ID = 'IMPORT_CRITERIA';

/* eslint-disable no-useless-escape */
// eslint-disable-next-line max-len
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export type CriterionImport = (
    {name: string; ignore?: boolean; multiple: true; values: {column: number; key: string}[]}
    | {name: string; ignore?: boolean; multiple: false; column: number; values: string[]}
)

export class CriteriaImporter {
    inviteToProject = false

    worksheet: ExcelJS.Worksheet | undefined

    emailColumn = -1

    criteria: CriterionImport[] = []

    async init(file: File, inviteToProject = false): Promise<void> {
        this.inviteToProject = inviteToProject;

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(await file.arrayBuffer());
        // eslint-disable-next-line prefer-destructuring
        this.worksheet = workbook.worksheets[0];
        const firstRow = this.worksheet.getRow(1);

        // Fill criteria array with info from first row
        firstRow.eachCell((cell, colNumber) => {
            if (this.worksheet && cell.text) {
                // Check if email column
                const valCell = this.worksheet.getCell(2, colNumber);
                if (valCell.text && emailRegex.test(valCell.text.trim())) {
                    this.emailColumn = colNumber;
                    return;
                }

                // Check if name contains `_` or `::`. If so, consider it as a multiple criterion
                const name = cell.text;
                let separatorIndex = name.lastIndexOf('_');
                let separatorSize = 1;
                if (separatorIndex === -1) {
                    separatorIndex = name.lastIndexOf('::');
                    separatorSize = 2;
                }

                if (separatorIndex === -1) {
                    const values = new Set<string>();
                    this.worksheet?.getColumn(colNumber).eachCell((_cell, row) => {
                        if (row !== 1 && _cell.text) values.add(_cell.text.trim());
                    });
                    this.criteria.push({
                        name: name.trim(),
                        multiple: false,
                        column: colNumber,
                        values: [...values],
                    });
                } else {
                    const criterionName = name.slice(0, separatorIndex).trim();
                    const criterionValue = name.slice(separatorIndex + separatorSize).trim();
                    const criterion = this.criteria.find((_criterion) => _criterion.name === criterionName);
                    if (criterion?.multiple) {
                        criterion.values.push({
                            column: colNumber,
                            key: criterionValue,
                        });
                    } else {
                        this.criteria.push({
                            name: criterionName,
                            multiple: true,
                            values: [{
                                column: colNumber,
                                key: criterionValue,
                            }],
                        });
                    }
                }
            }
        });
    }

    setColumnIgnore(index: number, ignore: boolean): void {
        this.criteria[index].ignore = ignore;
    }

    setColumnName(index: number, name: string): void {
        this.criteria[index].name = name;
    }

    run(setProgress: (progress: number) => void): AsyncAppThunk<void> {
        return async (dispatch, getState): Promise<void> => {
            if (this.worksheet) {
                // Treat only non ignored criteria
                const criteria = this.criteria.filter((criterion) => !criterion.ignore);

                // Progress variables
                let count = 0;
                const totalImport = criteria.length * (this.worksheet.rowCount - 1);

                // Invite users
                const emails: string[] = [];
                this.worksheet?.getColumn(this.emailColumn).eachCell((cell, col) => {
                    if (col !== 1 && cell.text) emails.push(cell.text.trim());
                });
                const users = (await dispatch(inviteUser(
                    emails, {reInvite: false, isContributor: true, locale: i18n.resolvedLanguage as AvailableLocale},
                ))).reduce((result, user) => ({
                    ...result,
                    [user.email as string]: user,
                }), {} as {[email: string]: User});

                const orgaId = getState().auth.currentOrganizationId as string;
                for (const criterionImport of criteria) {
                    // eslint-disable-next-line no-await-in-loop
                    const criterion = await dispatch(createCriterion({
                        organization_id: orgaId,
                        name: criterionImport.name,
                        multiple: criterionImport.multiple,
                        values: criterionImport.multiple
                            ? criterionImport.values.map((val) => val.key)
                            : criterionImport.values,
                    }));

                    const options = criterion.values.map((val) => ({
                        ...val,
                        column: (
                            criterionImport.multiple
                                ? (criterionImport.values.find(
                                    (val0) => val0.key === val.label
                                ) as { column: number; key: string }).column
                                : -1
                        ),
                    }));

                    for (let i = 2; i <= this.worksheet.rowCount; i++) {
                        const row = this.worksheet.getRow(i);
                        const email = row.getCell(this.emailColumn).text;
                        const user = email && users[email];
                        if (user && !user.criteria?.some((userCriterion) => userCriterion.id === criterion.id)) {
                            if (criterionImport.multiple) {
                                const value = [];
                                for (const option of options) {
                                    const cellValue = row.getCell(option.column).text;
                                    if (cellValue === '1' || cellValue === 'Y') {
                                        value.push(option.slug);
                                    }
                                }
                                if (value.length) {
                                    // eslint-disable-next-line no-await-in-loop
                                    await dispatch(addMemberToCriterion(criterion.id, user.id, value, false));
                                }
                            } else {
                                const cellValue = row.getCell(criterionImport.column).text;
                                const value = options.find(
                                    (option) => option.label === cellValue,
                                )?.slug;
                                // eslint-disable-next-line no-await-in-loop
                                if (value) await dispatch(addMemberToCriterion(criterion.id, user.id, value, false));
                            }
                        }
                        count += 1;
                        setProgress(count / totalImport);
                    }
                }

                // Refresh users after criteria import
                const invitedUsers = await dispatch(inviteUser(
                    emails, {reInvite: false, isContributor: true, locale: i18n.resolvedLanguage as AvailableLocale},
                ));
                const projectId = getState().auth.currentProjectId;
                if (this.inviteToProject && projectId) {
                    await dispatch(addProjectContributors(
                        projectId,
                        invitedUsers.map((user) => user.id),
                    ));
                }
            }
        };
    }
}
