import React from 'react';
import {
    useCriteria,
    Profile,
} from 'mushin-redux-store';
import GenericList from './GenericList';
import { displayValue } from '../../Helpers/questionnaires';
import { t_prop } from '../../Helpers/translate';
import MuIcon from '../MuIcon';
import {classWithModifiers} from '../../Helpers/css';
import CriteriaEditModalValueSelect from '../../Scenes/AppModals/Components/CriteriaModal/CriteriaEditModalValueSelect';

type Props = React.PropsWithChildren<{
    className?: string;
    profile: Profile;
    contrast?: boolean;
    limit?: boolean;
}>

const MAX_ITEMS = 3;

/**
 * UserCriteria component
 *
 * @param props - The component props.
 * @param props.className - Optional CSS class for the component.
 * @param props.profile - The profile containing criteria.
 * @param props.limit - Optional flag to limit the number of displayed items.
 * @returns The rendered UserCriteria component.
 */
const UserCriteria: React.FC<Props> = (
    { className, profile, limit, children }
) => {
    const ids: string[] = profile.criteria.map((criterion) => criterion.id);

    const criteria = useCriteria(ids);

    if (!criteria) return null;

    const criteriaNames = [];
    for (const criterion of criteria) {
        if (criterion) {
            const value = displayValue(
                criterion.values,
                profile.criteria?.find((item) => item.id === criterion.id)?.value,
            );
            criteriaNames.push(`${t_prop(criterion.titles, criterion.name)} : ${value}`);
        }
    }

    return (
        <div className="mu-user-criteria">
            <GenericList
                className={classWithModifiers('mu-tags', null, className)}
                items={criteria}
                max={limit ? MAX_ITEMS : criteriaNames.length}
                toggle={(showAll, setShowAll) => (
                    <button
                        type="button"
                        className="mu-tags__show-all"
                        onClick={() => setShowAll((prev) => !prev)}
                    >
                        <MuIcon svgName={showAll ? 'Minus' : 'Plus'} />
                    </button>
                )}
                renderItem={(criterion) => criterion && (
                    <li key={criterion.id} className="mu-tags__tag">
                        <CriteriaEditModalValueSelect
                            criterion={criterion}
                            profileId={profile.id}
                            isIconButton={false}
                        />
                    </li>
                )}
            >
                {children}
            </GenericList>
        </div>
    );
};

export default UserCriteria;
