import React from 'react';
import { ActionProps, ActionBarMuIconButton } from './common';

const createRotationHandler = (content, cell, setContent, direction) => () => {
    const angleInterval = content.type === 'shape' ? 45 : 90;

    const toEnum = (x) => {
    // Round angle to a multiple of angle interval and reset angle to 0 if over 360
        return (360 + Math.round(x / angleInterval) * angleInterval) % 360;
    };

    // new rotation value
    const image_rotation = toEnum((content.image_rotation || 0) + direction * angleInterval);

    // reset the focus point
    // ( could probably make so that the focus point is invariantwith rotation, but I don't really want to make the compuatation )
    const image_crop = content.image_crop && content.image_crop.filling === 'contain' ? content.image_crop : {
        ...content.image_crop,
        focusPoint: { x: 0.5, y: 0.5 },
    };

    const newContent = {
        ...content,
        image_rotation,
        image_crop,
    };

    setContent(newContent);
};

export const RotateLeftButton = ({
    content,
    setContent,
    cell,
}: ActionProps) => <ActionBarMuIconButton onClick={createRotationHandler(content, cell, setContent, -1)} svgName="RotateLeft" />;

export const RotateRightButton = ({
    content,
    setContent,
    cell,
}: ActionProps) => <ActionBarMuIconButton onClick={createRotationHandler(content, cell, setContent, 1)} svgName="RotateRight" />;
