import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Album, EntityMember, updateAlbum} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton';
import AddUsersStep from '../CreationEditionModal/AddUsersStep';
import Client from '../../../../Services/Client';

type Props = {
    onClose: () => void;
    album: Album;
};

const AlbumUsersModal: React.FC<Props> = ({
    onClose,
    album,
}) => {
    const dispatch = useDispatch();
    const membersState = useState<EntityMember[]>(album.selectedMembers);

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={onClose}>
            <ModalHeader
                subtitle={i18n.t('modals.EditAlbumUsers.subtitle')}
                title={i18n.t('modals.EditAlbumUsers.title')}
                icon="Share"
            />
            <AddUsersStep
                membersState={membersState}
                canRemoveUser={Client.isAllowedToEditOrganization() || Client.getId() === album.owner.id}
            />
            <ModalFooter>
                <MuButton
                    handleClick={() => {
                        dispatch(updateAlbum(
                            album.id,
                            {
                                selectedMembers: membersState[0],
                            }
                        ));
                        onClose();
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default AlbumUsersModal;
