import React from 'react';
import { useDispatch } from 'react-redux';
import {setMe, useMe} from 'mushin-redux-store';

import i18n from 'i18next';
import UserRequests from '../../Services/APIRequests/UserRequests';
import MuIcon from '../MuIcon';
import {openModal} from '../../Redux/reducers/modalsSlice';
import Tabs from '../Tabs/Tabs';
import MainMenu from '../MainMenu/MainMenu';

type Props = React.PropsWithChildren<{
  imgUrl: string;
  imgAlt: string;
  subTitle: string;
}>;

const AccountSettingsLayout: React.FC<Props> = ({
    imgUrl,
    imgAlt,
    subTitle,
    children,
}) => {
    const dispatch = useDispatch();
    const me = useMe();

    const uploadUserAvatar = (fileToCrop: File) => {
        dispatch(openModal('CropImageModal', {
            submitAction: async (file) => {
                if (me) {
                    const data = new FormData();
                    data.append('file', file, `${me.mention_slug}_${me.id}.png`);
                    try {
                        const response = await UserRequests.updateAvatar(me.id, data);
                        dispatch(setMe(response.data));
                        return true;
                    } catch (e) {
                        return false;
                    }
                }
                return false;
            },
            fileToCrop,
            title: i18n.t<string>('accounts.update.avatar'),
            icon: 'UserLine',
        }));
    };

    return (
        <div className="mu-app-layout">
            <div className="mu-app-layout__menu">
                <MainMenu />
            </div>
            <div className="mu-app-layout__content">
                <header className="settings-layout__header">
                    <div className="settings-layout-header__main">
                        <div className="settings-layout-header__avatar">
                            <input
                                type="file"
                                id="user-avatar"
                                className="logo-settings__input-file"
                                onChange={(e) => e.target.files && uploadUserAvatar(e.target.files[0])}
                                accept="image/*"
                            />
                            <label htmlFor="user-avatar" className="settings-layout-header__avatar-label">
                                <img className="settings-layout-header__avatar-img" src={imgUrl} alt={imgAlt} />
                                <div className="settings-layout-header__edit-avatar">
                                    <MuIcon svgName="Edit" />
                                </div>
                            </label>
                        </div>
                        <h2 className="settings-layout-header__title">{subTitle}</h2>
                    </div>
                    <Tabs
                        className="settings-layout-header__tabs"
                        tabs={[{
                            key: 'account',
                            to: '/account',
                            label: i18n.t<string>('accounts.update.title'),
                        }, {
                            key: 'notifications',
                            to: '/account/notifications',
                            label: i18n.t<string>('accounts.notifications.title'),
                        }]}
                    />
                </header>
                <div className="mu-app-layout__scroll">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AccountSettingsLayout;
