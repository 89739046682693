import React, {useState} from 'react';
import {classWithModifiers, ModifiersArray} from '../../Helpers/css';
import { MuSvg } from '../MuSvg';
import infoIcon from '../../../assets/icons/mu-icons/InfoLine.svg';
import warningIcon from '../../../assets/icons/mu-icons/Warning.svg';
import MuIcon from '../MuIcon';

type Props = {
    id?: string;
    leftIcon?: string;
    warning?: boolean;
    warningMessage?: string;
    error?: boolean;
    errorMessage?: string;
    placeholder?: React.ReactNode | string;
    label?: React.ReactNode | string;
    success?: boolean;
    value?: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
    autocomplete?: string;
    autoCapitalize?: string;
    maxLength?: number;
    className?: string;
    classModifiers?: ModifiersArray;
    type?: string;
    message?: string;
    disabled?: boolean;
    contrast?: boolean;
};

const InputText: React.FC<Props> = (
    {
        id,
        className,
        classModifiers,
        warning,
        warningMessage,
        error,
        errorMessage,
        placeholder,
        leftIcon,
        label,
        message,
        success,
        type,
        value,
        onBlur,
        onChange,
        onFocus,
        onKeyDown,
        onPaste,
        autoFocus,
        autocomplete,
        autoCapitalize,
        maxLength,
        disabled,
        contrast = false,
    }
) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div
            className={classWithModifiers(
                'mu-input',
                [
                    {
                        error: error && !success,
                        'left-icon': !!leftIcon,
                        valid: success && !error,
                    },
                    ...(classModifiers || [])
                ],
                className
            )}
        >
            {label && (
                <label htmlFor={id} className="mu-input__label">
                    {label}
                </label>
            )}
            <div className="mu-input__raw-wrapper">
                {leftIcon && (<MuIcon className="mu-input__left-icon" svgName={leftIcon} />)}
                <input
                    id={id}
                    type={!type || showPassword ? 'text' : type}
                    placeholder={(placeholder || label) as string}
                    className={classWithModifiers('mu-input-raw', {contrast})}
                    autoFocus={autoFocus}
                    autoComplete={autocomplete}
                    autoCapitalize={autoCapitalize}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    onPaste={onPaste}
                    maxLength={maxLength}
                    onFocus={onFocus}
                    disabled={disabled}
                />
                {type === 'password' && (
                    <button
                        className="mu-input__show-password-button"
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        <MuIcon className="mu-input__show-password-icon" svgName={showPassword ? 'Hide' : 'Show'} />
                    </button>
                )}
            </div>
            {error && errorMessage && (
                <span className={classWithModifiers(' mu-input-message-item', ['error'])}>
                    <MuSvg className="mu-input-message-item__icon" SvgComp={warningIcon} />
                    {errorMessage}
                </span>
            )}
            {warning && warningMessage && (
                <span className={classWithModifiers(' mu-input-message-item', ['warning'])}>
                    <MuSvg className="mu-input-message-item__icon" SvgComp={warningIcon} />
                    {warningMessage}
                </span>
            )}
            {message && !error && !warning && (
                <span className="mu-input-message-item">
                    <MuSvg className="mu-input-message-item__icon" SvgComp={infoIcon} />
                    {message}
                </span>
            )}
        </div>
    );
};

InputText.defaultProps = {
    label: '',
    error: false,
    success: false,
    errorMessage: '',
    autoFocus: false,
    maxLength: 62,
    classModifiers: [''],
};

export default InputText;
