import * as React from 'react';
import DatePicker from 'react-datepicker';
import moment, {Moment} from 'moment';
import i18n from 'i18next';
import {classWithModifiers} from '../../../../../Helpers/css';

type Props = {
  beginDate?: Moment | null;
  endingDate?: Moment | null;
  handleChangeBeginDate: (date: Moment | null) => void;
  handleChangeEndingDate: (date: Moment | null) => void;
  contrast?: boolean;
};

const getDate = (date: Date | [Date, Date] /* for selectsRange */ | null) => {
    if (!date || Array.isArray(date)) return null;
    return moment(date as Date);
};

const getDateStartOfDay = (date: Date | [Date, Date] /* for selectsRange */ | null) => {
    const dateMoment = getDate(date);
    return dateMoment && dateMoment.startOf('day');
};

const getDateEndOfDay = (date: Date | [Date, Date] /* for selectsRange */ | null) => {
    const dateMoment = getDate(date);
    return dateMoment && dateMoment.endOf('day');
};

const ChooseTimeSlot: React.FC<Props> = ({
    beginDate,
    endingDate,
    handleChangeBeginDate,
    handleChangeEndingDate,
    contrast = false,
}) => {
    return (
        <>
            <div className="mu-form-row">
                <span className="mu-input__label">
                    {i18n.t('components.filters.dropdown.beginDateLabel')}
                </span>
                <DatePicker
                    id="beginDate"
                    selected={beginDate?.toDate()}
                    onChange={(date) => handleChangeBeginDate(getDateStartOfDay(date))}
                    placeholderText={i18n.t<string>('components.filters.dropdown.datePlaceholder')}
                    className={classWithModifiers('mu-input-raw', {contrast})}
                    calendarClassName="mu-calendar"
                    dateFormat="P"
                />
            </div>
            <div className="mu-form-row">
                <span className="mu-input__label">{i18n.t('components.filters.dropdown.endDateLabel')}</span>
                <DatePicker
                    id="endingDate"
                    selected={endingDate?.toDate()}
                    onChange={(date) => handleChangeEndingDate(getDateEndOfDay(date))}
                    placeholderText={i18n.t<string>('components.filters.dropdown.datePlaceholder')}
                    className={classWithModifiers('mu-input-raw', {contrast})}
                    calendarClassName="mu-calendar"
                    dateFormat="P"
                />
            </div>
        </>
    );
};

export default ChooseTimeSlot;
