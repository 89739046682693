import React, {useState} from 'react';
import { useCurrentOrganizationId, useGroup, useGroups, useGroupsQuery } from 'mushin-redux-store';
import i18n from 'i18next';
import MuButton from '../../../MuButton';
import EntitiesSelect from '../../../EntitySelect/EntitiesSelect';

type Props = {
    groups?: string[];
    setGroups: (groups: string[]) => void;
}

const GroupsDropdownPanel: React.FC<Props> = ({ groups = [], setGroups }) => {
    const [selectedGroups, setSelectedGroups] = useState<string[]>(groups || []);
    const [organizationId] = useCurrentOrganizationId();
    const groupsQuery = useGroupsQuery({organization_id: organizationId}, true);

    const handleSubmitUsers = () => {
        setGroups(selectedGroups);
    };

    return (
        <>
            <EntitiesSelect
                className="mu-filters-toolbar__inline-select"
                values={selectedGroups}
                onSelect={(values) => setSelectedGroups(values)}
                options={groupsQuery.results || []}
                useItem={useGroup}
                useItems={useGroups}
                getMoreResults={groupsQuery.getMoreResults}
                placeholder={i18n.t<string>('global.select')}
                getEntityName={(group) => group?.name || ''}
                search={(query) => groupsQuery.setParams({
                    organization_id: organizationId, name_search: query || undefined,
                })}
                hideValue
                inline
            />
            <MuButton
                className="mu-filters-toolbar__panel-submit"
                handleClick={handleSubmitUsers}
                label={i18n.t('components.filters.dropdown.apply')}
            />
        </>
    );
};

export default GroupsDropdownPanel;
