import React from 'react';

import {reactToNote, toggleNoteFavorite, Note} from 'mushin-redux-store';
import {useAppDispatch} from '../../Helpers/hooks';
import Reaction, {CommonReactionProps} from './Reaction';

type Props = CommonReactionProps & {
    note: Note;
}

const NoteReaction: React.FC<Props> = (
    {
        name,
        note,
        className,
        horizontal,
        contrast,
        size,
    }
) => {
    const dispatch = useAppDispatch();

    return (
        <Reaction
            name={name}
            reactions={note.reactions}
            favorites={note.favorites}
            toggleReaction={(key) => dispatch(reactToNote(note, key))}
            toggleFavorite={() => dispatch(toggleNoteFavorite(note))}
            className={className}
            horizontal={horizontal}
            contrast={contrast}
            size={size}
        />
    );
};

export default NoteReaction;
