import React, { useEffect } from 'react';
import {useCriteriaQuery, useCurrentProject, useCurrentProjectId} from 'mushin-redux-store';
import i18n from 'i18next';
import CriterionRow from './Components/CriterionRow';
import EmptyZone from '../../Components/EmptyZone/EmptyZone';
import Spinner from '../../Components/Spinner';
import CriteriaToolbar from './Components/CriteriaToolbar';
import { useSearchParams } from '../../Helpers/queryParser';
import ProjectLayout from '../ProjectDetail/Components/ProjectLayout/ProjectLayout';
import {useScrollThreshold} from '../../Helpers/effects';

const ProjectCriteria: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams<{name_search?: string}>();

    const project = useCurrentProject();
    const [currentProjectId] = useCurrentProjectId();
    const criteriaQuery = useCriteriaQuery(
        {project_id: currentProjectId, ...searchParams},
        !Object.keys(searchParams).length,
    );

    const scrollRef = useScrollThreshold<HTMLDivElement>(
        criteriaQuery.getMoreResults, {deps: [criteriaQuery.results?.length]},
    );

    const {setParams} = criteriaQuery;
    useEffect(() => {
        setParams({project_id: currentProjectId, ...searchParams}, !Object.keys(searchParams).length);
    }, [currentProjectId, searchParams, setParams]);

    if (!project) {
        return null;
    }

    return (
        <ProjectLayout project={project} activeTab="criteria">
            <CriteriaToolbar searchParams={searchParams} setSearchParams={setSearchParams} />
            <div ref={scrollRef} className="mu-app-layout__scroll">
                {criteriaQuery.results && (
                    criteriaQuery.results.length ? (
                        criteriaQuery.results.map((criterionId) => {
                            return (
                                <CriterionRow
                                    criterionId={criterionId}
                                    key={criterionId}
                                />
                            );
                        })
                    ) : !criteriaQuery.loading && (
                        <EmptyZone
                            urlImg="/assets/images/add-files.svg"
                            title={i18n.t('criteria.none')}
                        />
                    )
                )}
                {criteriaQuery.loading && (
                    <Spinner className="mu-project-criteria__loading" size="40px" />
                )}
            </div>
        </ProjectLayout>
    );
};

export default ProjectCriteria;
