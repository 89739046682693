import React, {useState} from 'react';
import i18n from 'i18next';
import {classWithModifiers} from '../../../../Helpers/css';
import { NOTE_MAX_LENGTH } from '../../../../constants';

type Props = {
    text: string;
    onChangeText: (text: string) => void;
};

const AddIdeaModalNoteTextInput: React.FC<Props> = ({
    text,
    onChangeText,
}) => {
    const [isEmpty, setEmpty] = useState(true);
    const placeholder = i18n.t<string>('gallery.manage_workspace_bar.import');

    return (
        <div
            className={classWithModifiers('mu-add-images-modal__text-input', {empty: isEmpty})}
            data-placeholder={placeholder}
        >
            <div
                className="mu-add-images-modal__text-input-edit"
                contentEditable
                onInput={(e) => {
                    if (!e.currentTarget.textContent) {
                        setEmpty(true);
                    } else {
                        setEmpty(false);
                    }
                    onChangeText(e.currentTarget.textContent || '');
                }}
            />
            <div className={classWithModifiers('mu-add-images-modal__text-counter', {error: text.length > NOTE_MAX_LENGTH})}>
                {`${text.length} / ${NOTE_MAX_LENGTH}`}
            </div>
        </div>
    );
};

export default AddIdeaModalNoteTextInput;
