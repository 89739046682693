import i18n from 'i18next';
import React from 'react';
import {
    useProfile,
    UseQueryReturn,
    ProfilesQueryParams,
    SearchUsersResponse,
    Profile,
    useCurrentProject,
} from 'mushin-redux-store';
import { openModal } from '../../Redux/reducers/modalsSlice';
import UserCriteria from '../UserCriteria/UserCriteria';
import UserRoleSelect, { UserRole } from '../EntityRow/UserRoleSelect';
import Badge from '../Badge/Badge';
import UserReInviteAction from '../UserReInviteAction/UserReInviteAction';
import DataGrid from '../DataGrid/DataGrid';
import { useAppDispatch } from '../../Helpers/hooks';
import { getNameToDisplay, isContextAdmin } from '../../Helpers/users';
import { DataGridColumn } from '../DataGrid/types';
import { formatPastDateTime } from '../../Helpers/date';

type Props = {
    query: UseQueryReturn<ProfilesQueryParams, SearchUsersResponse>;
    selectedIds: string[];
    setSelectedIds: (ids: string[]) => void;
    getRole: (user: Profile) => string;
    setRole: (user: Profile, role: UserRole) => void;
    roleOptions: UserRole[];
    getStatus: (user: Profile) => { mod: string; label: string; date?: string };
    contributors?: boolean;
    standalone?: boolean;
    withScroll?: boolean;
    withToolbar?: boolean;
    scrollRef?: (node: HTMLDivElement) => void;
};

const ProfilesGrid: React.FC<Props> = ({
    query,
    selectedIds,
    setSelectedIds,
    getRole,
    setRole,
    roleOptions,
    getStatus,
    contributors = false,
    standalone,
    withScroll,
    withToolbar,
    scrollRef,
}) => {
    const currentProject = useCurrentProject();
    const dispatch = useAppDispatch();

    const columns: DataGridColumn<Profile>[] = [
        {
            key: 'firstname',
            header: i18n.t('global.name'),
            ValueComponent: ({ item }) => <strong>{getNameToDisplay(item)}</strong>,
            classModifiers: 'link',
            onClick: (item) => dispatch(openModal('ContributorDetails', { profileId: item.id })),
            size: '1fr',
        },
        {
            key: 'email',
            header: i18n.t('models.users.email'),
            ValueComponent: ({ item }) => <strong>{item.user?.email}</strong>,
            size: '1fr',
        },
    ];

    if (contributors) {
        columns.push({
            key: 'groups',
            header: i18n.t('criteria.items'),
            ValueComponent: ({ item }) => <UserCriteria profile={item} limit />,
            size: '3fr',
        });
    } else {
        columns.push({
            key: 'role',
            header: i18n.t('models.users.role.label'),
            ValueComponent: ({ item }) => {
                return (
                    <UserRoleSelect
                        useRoles={isContextAdmin(query.params.project_id ? currentProject : undefined) || 'read-only'}
                        setRole={(roleOption) => setRole(item, roleOption)}
                        roleOptions={roleOptions}
                        role={getRole(item)}
                    />
                );
            },
            size: '1fr',
        });
    }

    columns.push({
        key: 'creationDate',
        header: i18n.t('global.status.status'),
        ValueComponent: ({ item }) => {
            const status = getStatus(item);
            return (
                <>
                    <Badge classModifiers={status.mod}>{i18n.t(status.label)}</Badge>
                    <span className="mu-users-grid__datetime">{status.date && formatPastDateTime(status.date)}</span>
                    {item.invited_at && !item.user?.confirmed_at && (
                        <UserReInviteAction
                            profile={item}
                            contributors={contributors}
                            projectId={query.params.project_id}
                        />
                    )}
                </>
            );
        },
        size: '2fr',
    });

    return (
        <DataGrid
            columns={columns}
            data={query.results || []}
            getItem={useProfile}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            totalItems={query.pager?.total_items}
            getAllItems={query.getAllResults}
            standalone={standalone}
            withScroll={withScroll}
            withToolbar={withToolbar}
            scrollRef={scrollRef}
        />
    );
};

export default ProfilesGrid;
