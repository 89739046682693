import React from 'react';
import {useAlbum, useTimelineReadCount} from 'mushin-redux-store';
import i18n from 'i18next';
import {Link} from 'react-router-dom';
import { classWithModifiers } from '../../Helpers/css';
import AlbumDropdownMenu from '../AlbumDropdownMenu/AlbumDropdownMenu';
import {MuSvg} from '../MuSvg';
import Cluster from '../../../assets/icons/Cluster.svg';
import MuIcon from '../MuIcon';

type Props = {
    albumId: string;
    withPictures?: boolean;
    noActions?: boolean;
};

const AlbumPreviewBlock: React.FC<Props> = ({
    albumId,
    withPictures = true,
    noActions = false,
}: Props) => {
    const album = useAlbum(albumId);
    const unreadCount = useTimelineReadCount({ is_contributor: true }, {projectId: album?.project_id, albumId});

    if (!album) return null;
    const {
        label,
        cover_note,
    } = album;

    const url = `/projects/${album.project_id}/albums/${albumId}`;

    return (
        <article
            className={classWithModifiers('mu-album-preview',
                [{'without-pictures': !withPictures}]
            )}
        >
            {!noActions && (
                <div className="mu-album-preview__more-options">
                    <AlbumDropdownMenu album={album} />
                </div>
            )}
            <div className="mu-album-preview__status">
                <MuIcon className="mu-album-preview__status-icon" svgName="Bulb" />
                {` ${album.notes_count} ${i18n.t('album.ideas.1._', { count: album.notes_count })}`}
            </div>
            {withPictures && (
                <Link
                    className="mu-album-preview__button"
                    to={url}
                >
                    <div className={classWithModifiers('mu-album-preview__pictures', {'no-pictures': !cover_note})}>
                        <div className="mu-album-preview__overlay">
                            {i18n.t('global.join')}
                        </div>
                        { cover_note?.thumbnail.thumb ? (
                            <img
                                src={cover_note.thumbnail.thumb}
                                alt={`${label} album preview`}
                                className="mu-album-preview__img"
                            />
                        ) : (
                            <MuSvg SvgComp={Cluster} />
                        )}
                    </div>
                    <section className="mu-album-preview__content">
                        <h2 className="mu-album-preview__title">
                            {label}
                            {!!unreadCount && <div className="mu-album-preview__notif" />}
                        </h2>
                    </section>
                </Link>
            )}
        </article>
    );
};

export default AlbumPreviewBlock;
