import React from 'react';
import {SvgrComponent} from '../../Types/common';
import MuIcon from '../MuIcon';
import {classWithModifiers, Modifiers} from '../../Helpers/css';

type Props = {
    className?: string;
    modifiers?: Modifiers;
    svg?: SvgrComponent;
    svgName?: string;
};

const BorderedIcon: React.FC<Props> = ({
    className,
    modifiers,
    svg,
    svgName,
}: Props) => {
    return (
        <div className={`mu-bordered-icon ${className}`}>
            <MuIcon className={classWithModifiers('mu-bordered-icon__svg', modifiers)} svg={svg} svgName={svgName} />
        </div>
    );
};

export default BorderedIcon;
