import React from 'react';
import {updateNews, useNews} from 'mushin-redux-store';
import ReactQuill from 'react-quill-new';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../Helpers/hooks';

type Props = {
    id: string;
    isFirstItem?: boolean;
}

const NewsItem: React.FC<Props> = ({id, isFirstItem}) => {
    const { t } = useTranslation();
    const news = useNews(id);
    const dispatch = useAppDispatch();

    if (!news) return null;
    
    const handleRead = () => {
        dispatch(updateNews(id, { read: true }));
    };

    return (
        <div key={id} className="mu-news-item">
            {isFirstItem && !news.isRead && (
                <button 
                    className="mu-news-item__read-button"
                    type="button"
                    onClick={handleRead}
                >
                    {t('news.mark_as_read')}
                </button>
            )}
            <div className="mu-news-item__header">
                <span>{moment(news.created_at).format('L LT')}</span>
            </div>
            <p className="mu-news-item__title">
                {news.title}
            </p>
            <div className="mu-news-item__content">
                <ReactQuill
                    className="mu-rich-input"
                    value={news.content}
                    readOnly
                    theme=""
                />    
            </div>
        </div>
    );
};

export default NewsItem;
