import React from 'react';
import i18n from 'i18next';
import {useParams} from 'react-router-dom';
import {useProject} from 'mushin-redux-store';
import SynthesisLayout from '../../SynthesisLayout';
import HeaderSynthesis from '../../HeaderSynthesis';
import ClusterSearch from './ClusterComponents/ClusterSearch';

const SynthesisClusters: React.FC = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const project = useProject(projectId);

    if (!project) return null;

    return (
        <SynthesisLayout project={project} activeTab="clusters">
            <div className="mu-app-layout__scroll mu-synthesis">
                <HeaderSynthesis
                    activeMode="Cluster"
                    text={i18n.t<string>('SynthesisClusters.text')}
                    span={i18n.t<string>('SynthesisClusters.span')}
                    textButton={i18n.t<string>('SynthesisClusters.button')}
                />
                <ClusterSearch />
            </div>
        </SynthesisLayout>
    );
};
export default SynthesisClusters;
