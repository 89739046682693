import React from 'react';
import i18n from 'i18next';
import MuIcon from '../../../../../Components/MuIcon';

const DownloadSynthesis: React.FC = () => {
    return (
        <div className="mu-synthesis__container">
            <div className="mu-synthesis-download">
                <p className="mu-synthesis-download__text">{ i18n.t('DownloadSynthesis.text')}</p>
                <MuIcon svgName="Telechargement" className="mu-synthesis-download__icon" />
            </div>
        </div>
    );
};

export default DownloadSynthesis;
