import React, {useEffect} from 'react';
import i18n from 'i18next';
import InputText from '../InputText/InputText';
import {UseFieldState} from './common';
import {compositeValidator, passwordValidator, requiredValidator} from './validation';

type Props = {
    passwordField: UseFieldState<string>;
}

export const NewPasswordInput: React.FC<Props> = (
    {
        passwordField,
    }
) => {
    useEffect(() => {
        passwordField.setValidator(
            compositeValidator([
                requiredValidator,
                passwordValidator,
            ])
        );
    }, [passwordField.value]);

    return (
        <div className="mu-form-row">
            <InputText
                classModifiers={['full-width']}
                type="password"
                error={!passwordField.status.valid}
                errorMessage={(!passwordField.status.valid && passwordField.status.message) || undefined}
                onBlur={passwordField.validate}
                onChange={(e) => {
                    passwordField.resetStatus();
                    passwordField.setValue(e.currentTarget.value);
                }}
                value={passwordField.value}
                placeholder={i18n.t('models.users.password')}
                autocomplete="new-password"
                autoCapitalize="off"
                contrast
            />
        </div>
    );
};

export default NewPasswordInput;
