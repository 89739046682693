import React, { useEffect, useState } from 'react';
import { Organization, updateOrganization } from 'mushin-redux-store';
import i18n from 'i18next';
import InputText from '../../../../Components/InputText/InputText';
import { useAppDispatch } from '../../../../Helpers/hooks';

type Props = {
  organization: Organization;
};

const OrganizationPart: React.FC<Props> = ({
    organization,
}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (organization) {
            setName(organization.name);
            setDescription(organization.description);
        }
    }, [organization]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(updateOrganization(organization.id, { name, description }));
    };

    return (
        <form className="settings-layout__container" onSubmit={handleSubmit}>
            <h3 className="settings-layout__title">{i18n.t('administration.organization.informations')}</h3>
            <div className="mu-form-row">
                <div className="mu-form-row__group mu-form-row__group--cols2">
                    <InputText
                        onChange={(e) => setName(e.target.value)}
                        placeholder={i18n.t('administration.organization.name')}
                        type="text"
                        value={name}
                    />
                </div>
            </div>
            <div className="mu-form-row">
                <InputText
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder={i18n.t('administration.organization.description')}
                    type="text"
                    maxLength={500}
                    value={description}
                />
            </div>

            <div className="settings-layout__button-container">
                <button type="submit" className="mu-btn">
                    {i18n.t('global.validate')}
                </button>
            </div>
        </form>
    );
};

export default OrganizationPart;
