import axios from 'axios';

export const getErrorKey = (error: unknown, map: Record<string, string>): string => {
    if (axios.isAxiosError(error)) {
        if (error.response?.data.message) {
            return map[error.response.data.message] || map.DEFAULT;
        }
    }

    return map.DEFAULT;
};
