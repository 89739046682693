import toDate from '../toDate/index';
import type { LocaleOptions, WeekStartOptions } from '../../types';
import requiredArgs from '../requiredArgs/index';
import toInteger from '../toInteger/index';

export default function startOfUTCWeek(
    dirtyDate: Date | number,
    dirtyOptions?: LocaleOptions & WeekStartOptions
): Date {
    requiredArgs(1, arguments);

    const options = dirtyOptions || {};
    const {locale} = options;
    const localeWeekStartsOn = locale && locale.options && locale.options.weekStartsOn;
    const defaultWeekStartsOn = localeWeekStartsOn == null ? 0 : toInteger(localeWeekStartsOn);
    const weekStartsOn = options.weekStartsOn == null
        ? defaultWeekStartsOn
        : toInteger(options.weekStartsOn);

    // Test if weekStartsOn is between 0 and 6 _and_ is not NaN
    if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
        throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
    }

    const date = toDate(dirtyDate);
    const day = date.getUTCDay();
    const diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;

    date.setUTCDate(date.getUTCDate() - diff);
    date.setUTCHours(0, 0, 0, 0);
    return date;
}
