import React, {useEffect} from 'react';
import {MoodboardNote, useNote} from 'mushin-redux-store';
import {useParams} from 'react-router-dom';
import { Provider as DragProvider } from '../../Components/Bucket/DragAndDropComponents/elementDragStore';
import { Provider as LayoutUpdaterProvider } from './LeftPanel/layoutUpdater';
import { BoardComponent } from '../../Components/BoardComponents/BoardLayout/component';
import LeftPanel from './LeftPanel/LeftPanel';
import Header from './Header/Header';
import MoodboardCarousel from '../../Components/MoodboardCarousel/MoodboardCarousel';
import {useAppDispatch, useAppSelector} from '../../Helpers/hooks';
import {initMoodboardEditor, setSelectedPage} from '../../Redux/reducers/moodboardEditorSlice';

export const BoardEditor: React.FC = () => {
    const { board_id } = useParams<{[key: string]: string}>();
    const note = useNote(board_id) as MoodboardNote | null | undefined;
    const pages = useAppSelector((state) => state.moodboardEditor.present.pages);
    const selectedPage = useAppSelector((state) => state.moodboardEditor.present.selectedPage);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (note) {
            dispatch(initMoodboardEditor(note.id, note.moodboard_pages.map((page) => JSON.parse(page.data))));
        }
    }, [dispatch, note]);

    if (!note || selectedPage === -1) return null;

    return (
        <div className="be-wrapper">
            <div className="be-header">
                <Header noteTitle={note.label} />
            </div>
            <DragProvider>
                <LayoutUpdaterProvider>
                    <div className="boardEditor">
                        <div className="be-mainRow">
                            <div className="be-leftPanel">
                                <LeftPanel />
                            </div>
                            <MoodboardCarousel
                                pages={pages}
                                selectedPage={selectedPage}
                                setSelectedPage={(page) => dispatch(setSelectedPage(page))}
                            />
                            <div className="be-main">
                                <div className="be-editorZone">
                                    <BoardComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutUpdaterProvider>
            </DragProvider>
        </div>
    );
};
