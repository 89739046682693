import React from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import {useAppDispatch, useAppSelector} from '../../Helpers/hooks';
import {closeLightbox} from '../../Redux/reducers/lightboxSlice';

const AppFullscreenCarousel: React.FC = () => {
    const imageUrls = useAppSelector((state) => state.lightbox.imageUrls);
    const index = useAppSelector((state) => state.lightbox.index);

    const dispatch = useAppDispatch();

    return (
        <Lightbox
            open={!!imageUrls.length}
            close={() => dispatch(closeLightbox())}
            slides={imageUrls.map((src) => ({src}))}
            index={index}
            plugins={[Zoom]}
            zoom={{scrollToZoom: true, maxZoomPixelRatio: 2, zoomInMultiplier: 1.2, wheelZoomDistanceFactor: 500}}
        />
    );
};

export default AppFullscreenCarousel;
