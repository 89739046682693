import React, {useEffect, useState} from 'react';

import {
    useChatMessagesQuery
} from 'mushin-redux-store';

import {useScrollThreshold} from '../../Helpers/effects';
import {classWithModifiers} from '../../Helpers/css';
import EmptyMessageList from './EmptyMessageList';

type Props = {
    chatId: string;
    renderMessage: (id: string) => React.ReactElement;
    renderSeparator?: (prevId: string, nextId: string) => React.ReactElement;
    goToMessage?: string;
    contrast?: boolean;
    noShadow?: boolean;
}

const DiscussionMessages: React.FC<Props> = (
    {
        chatId,
        renderMessage,
        renderSeparator,
        goToMessage,
        contrast = false,
        noShadow = false,
    }
) => {
    const chatMessagesQuery = useChatMessagesQuery({chat_id: chatId});
    const ref = useScrollThreshold(chatMessagesQuery.getMoreResults, {deps: [chatMessagesQuery.results?.length]});
    const [loading, setLoading] = useState(!!goToMessage || chatMessagesQuery.loading);

    const {setParams} = chatMessagesQuery;
    useEffect(() => {
        setParams({chat_id: chatId});
        setLoading(true);
    }, [chatId, setParams]);

    useEffect(() => {
        if (!loading && goToMessage) {
            setTimeout(() => document.getElementById(goToMessage)?.scrollIntoView({behavior: 'smooth'}), 500);
        }
    }, [goToMessage, loading]);

    if (loading) {
        if (goToMessage) {
            if (chatMessagesQuery.results?.includes(goToMessage)) {
                setLoading(false);
            } else if (!chatMessagesQuery.loading) {
                chatMessagesQuery.getMoreResults();
            }
        } else if (!chatMessagesQuery.loading) setLoading(false);
    }

    if (loading || !chatMessagesQuery.results?.length) {
        return (
            <EmptyMessageList loading={loading} />
        );
    }

    return (
        <section className={classWithModifiers('mu-discussion__messages', {contrast, shadow: !noShadow})} ref={ref}>
            {chatMessagesQuery.results.map((messageId, index, messageIds) => (
                <>
                    {index > 0 && renderSeparator?.(messageId, messageIds[index - 1])}
                    {renderMessage(messageId)}
                </>
            ))}
        </section>
    );
};

export default DiscussionMessages;
