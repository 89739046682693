import React, { useEffect } from 'react';
import {useQuestionnairesQuery} from 'mushin-redux-store';
import {Link} from 'react-router-dom';
import i18n from 'i18next';

type Props = {
    projectId: string;
}

const MissionsTeamPanel: React.FC<Props> = ({ projectId }: Props) => {
    const questionnairesQuery = useQuestionnairesQuery({ project_id: projectId }, true);

    const { setParams } = questionnairesQuery;
    useEffect(() => {
        setParams({ project_id: projectId });
    }, [projectId, setParams]);

    const total = questionnairesQuery.pager?.total_items || 0;

    return (
        <div className="mu-panel mu-missions-panel">
            <div className="mu-missions-panel__content">
                {i18n.t('missions.count', {count: total})}
            </div>
            <Link className="mu-btn" to={`/projects/${projectId}/missions`}>
                {i18n.t('missions.list')}
            </Link>
        </div>
    );
};

export default MissionsTeamPanel;
