import React from 'react';
import {
    updateUploadInfos,
    useUpload,
    addHashtagToUpload,
    removeHashtagFromUpload,
    syncUploadInfos
} from 'mushin-redux-store';
import i18n from 'i18next';
import FileUploadProgress from '../../../../Components/FileUploadProgress/FileUploadProgress';
import Tags from '../../../../Components/Tags/Tags';
import QuillRichInput from '../../../../Components/QuillRichInput/QuillRichInput';
import {useAppDispatch} from '../../../../Helpers/hooks';

export const UploadingRow: React.FC<{ id: string }> = ({ id }) => {
    const upload = useUpload(id);

    const dispatch = useAppDispatch();

    const addTag = (tag: string) => {
        dispatch(addHashtagToUpload(upload.id, tag));
    };

    const removeTag = (tag: string) => {
        dispatch(removeHashtagFromUpload(upload.id, tag));
    };

    const displayError = (error: string): React.ReactNode => {
        if (error === 'noExt') return i18n.t('upload_alert.noExtension');
        if (error === 'unsupportedExt') {
            return i18n.t('upload_alert.unauthorizedExtension', {extensionName: upload.ext});
        }
        return i18n.t('global.error');
    };

    if (upload.data.type !== 'file') return null;

    return (
        <article className="transfer-manager__file">
            <FileUploadProgress
                file={upload.data.file}
                isPdf={upload.type === 'pdf'}
                id={upload.id}
                onFileTitleEdit={(event) => {
                    dispatch(updateUploadInfos(upload.id, { label: event.target.innerText }, true));
                }}
                progress={upload.progress}
                status={upload.status}
            >
                {upload.error ? (
                    <p className="transfer-manager-file__error">
                        {displayError(upload.error)}
                    </p>
                ) : (
                    <>
                        <Tags
                            tags={upload.hashtags}
                            selectedTags={upload.hashtags}
                            handleRemoveTag={removeTag}
                            handleAddTag={addTag}
                        />
                        <div className="transfer-manager-file__desc">
                            <QuillRichInput
                                placeholder={i18n.t<string>('global.addDescription')}
                                value={upload.content}
                                setValue={(content) => dispatch(updateUploadInfos(upload.id, {content}))}
                                onBlur={() => {
                                    dispatch(syncUploadInfos(upload.id));
                                }}
                            />
                        </div>
                    </>
                )}
            </FileUploadProgress>
        </article>
    );
};
