import React from 'react';
import {useChatMessage} from 'mushin-redux-store';
import moment from 'moment';
import i18n from 'i18next';
import {classWithModifiers} from '../../Helpers/css';

type Props = {
    previousMessageId?: string;
    nextMessageId: string;
    firstUnreadMessageId: string | undefined;
}

const ChatMessageSeparator: React.FC<Props> = (
    { previousMessageId, nextMessageId, firstUnreadMessageId }
) => {
    const previousMessage = useChatMessage(previousMessageId);
    const nextMessage = useChatMessage(nextMessageId);

    let daySeparator = null;
    if (nextMessage) {
        const nextDate = moment(nextMessage.created_at);
        if (!previousMessageId || (previousMessage && !nextDate.isSame(previousMessage.created_at, 'day'))) {
            daySeparator = (
                <time className="mu-discussion__day-separator">
                    {nextDate.calendar({
                        sameDay: `[${i18n.t('datetime.today')}]`,
                        lastDay: `[${i18n.t('datetime.yesterday')}]`,
                        nextWeek: 'll',
                        lastWeek: 'll',
                        sameElse: 'll',
                    })}
                </time>
            );
        }
    }

    const newSeparator = (nextMessageId === firstUnreadMessageId && i18n.t('gallery.note.isNew')) || null;

    return (daySeparator || newSeparator) && (
        <div className="mu-discussion__separator">
            {daySeparator}
            <hr className={classWithModifiers('mu-discussion__separator-line', {new: newSeparator})} />
            {newSeparator}
        </div>
    );
};

export default ChatMessageSeparator;
