import React from 'react';
import {useQuestionnaire, useTimelineReadCount} from 'mushin-redux-store';
import i18n from 'i18next';
import moment from 'moment/moment';
import MuIcon from '../../Components/MuIcon';
import {
    getQuestionnaireDuration,
} from '../../Helpers/questionnaires';
import QuestionnaireItemButton from './QuestionnaireItemButton';
import {BadgeComponent} from '../../Components/Badge/BadgeComponent';
import {getStatus} from '../../Helpers/date';
import QuestionnaireItemDropdown from '../../Components/SurveysList/QuestionnaireItemDropdown';
import { classWithModifiers } from '../../Helpers/css';
import TextTranslation from '../../Components/Translation/TextTranslation';

type Props = {
    id: string;
    isContributor: boolean;
}

const QuestionnaireItem: React.FC<Props> = ({ id, isContributor}) => {
    const questionnaire = useQuestionnaire(id);
    const unreadCount = useTimelineReadCount({ is_contributor: true }, {projectId: questionnaire?.project_id, questionnaireId: id});

    if (!questionnaire) return null;
    const thumbnail = questionnaire.image_url || questionnaire.questions.find((it) => it.image_urls?.length)?.image_urls?.[0];

    return (
        <div className="mu-mission-item">
            {isContributor && questionnaire.answered && thumbnail && (
                <div className="mu-mission-item__state">
                    <MuIcon svgName="Check" />
                </div>
            )}
            {thumbnail && (
                <img
                    className="mu-mission-item__thumbnail"
                    src={thumbnail}
                    alt="thumbnail"
                />
            )}
            {!thumbnail
                && (
                    <div
                        className={classWithModifiers(
                            'mu-mission-item__thumbnail-placeholder',
                            { completed: questionnaire.answered }
                        )}
                    >
                        <MuIcon svgName={questionnaire.answered ? 'Check' : 'Mission'} />
                    </div>
                )}
            <QuestionnaireItemDropdown
                className="mu-mission-item__menu"
                questionnaire={questionnaire}
                hasRespondentListOption
            />
            <BadgeComponent
                className="mu-mission-item__status"
                status={getStatus(questionnaire.start_date, questionnaire.end_date, questionnaire.archived_at)}
            />
            <div className="mu-mission-item__info">
                <h4 className="mu-mission-item__title">
                    <TextTranslation translationProp={questionnaire.titles} fallback={questionnaire.title} center />
                    {!!unreadCount && <div className="mu-mission-item__notif" />}
                </h4>
                {!isContributor && (
                    <div className="mu-mission-item__stat">
                        <MuIcon svgName="Group" className="mu-mission-item__stat-icon" />
                        <span className="mu-mission-item__stat-label">
                            {i18n.t('global.participant', {count: questionnaire.respondent_count})}
                        </span>
                    </div>
                )}
                <div className="mu-mission-item__stat">
                    <MuIcon svgName="Survey" className="mu-mission-item__stat-icon" />
                    <span className="mu-mission-item__stat-label">
                        {i18n.t('questionnairesList.question', {count: questionnaire.questions.length})}
                    </span>
                </div>
                {isContributor && !questionnaire.answered && (
                    <div className="mu-mission-item__stat">
                        <MuIcon svgName="Stopwatch" className="mu-mission-item__stat-icon" />
                        <span className="mu-mission-item__stat-label">
                            {moment.duration(getQuestionnaireDuration(questionnaire), 'seconds').humanize()}
                        </span>
                    </div>
                )}
            </div>
            <QuestionnaireItemButton questionnaire={questionnaire} isContributor={isContributor} />
        </div>
    );
};

export default QuestionnaireItem;
