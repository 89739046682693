import React from 'react';
import i18n from 'i18next';
import SurveyItem from './SurveyItem';
import { classWithModifiers } from '../../Helpers/css';
import MuButton from '../MuButton';

type Props = {
    title?: string;
    surveys: Array<string>;
    pager?: {
        total_items: number;
        page: number;
    } | null;
    getMore?: () => void;
    className?: string;
}

const SurveyList: React.FC<Props> = ({title, surveys, pager, getMore, className}) => {
    return (
        <section className={classWithModifiers('mu-surveys-list', undefined, className)}>
            {title && (
                <h2 className="mu-surveys-list__title">{title}</h2>
            )}
            <ul>
                {surveys.map((surveyId) => (
                    <li key={surveyId} className="mu-surveys-list__item">
                        <SurveyItem id={surveyId} />
                    </li>
                ))}
            </ul>
            {pager && pager.page * 15 < pager.total_items && (
                <MuButton
                    className="mu-surveys-list__see-more"
                    label={i18n.t('global.seeMore')}
                    handleClick={getMore}
                />
            )}
        </section>
    );
};

export default SurveyList;
