import React from 'react';
import { classWithModifiers } from '../../../Helpers/css';

type Props = {
    learningCount: number;
    totalCount: number;
    learningCountFiltered?: number;
    totalCountFiltered?: number;
};

const QuestionAnalysisLearningBadge: React.FC<Props> = ({
    learningCount,
    totalCount,
    learningCountFiltered,
    totalCountFiltered,
}) => {
    const ratio = Math.round((learningCount / totalCount) * 100);
    let ratioFiltered = ratio;
    if (typeof learningCountFiltered === 'number' && totalCountFiltered) {
        ratioFiltered = Math.round((learningCountFiltered / totalCountFiltered) * 100);
    }

    const difference = ratioFiltered - ratio;

    return (
        <div
            className={classWithModifiers('mu-question-result__analysis-learning-badge', {
                success: difference >= 10,
                error: difference <= -10,
            })}
        >
            {difference ? `${ratioFiltered}% (${difference > 0 ? '+' : ''}${difference})` : `${ratio}%`}
        </div>
    );
};

export default QuestionAnalysisLearningBadge;
