import React from 'react';

import { Cell } from 'mushin-node-moodboard';

type Props = {
  cell: Cell;
  color: string;
};

export const Text = ({
    cell,
    color,
}: Props) => (
    <g>
        <rect fill="#f2f2f2" x={cell.min.x} y={cell.min.y} width={cell.max.x - cell.min.x} height={cell.max.y - cell.min.y} />
        {cell.max.y - cell.min.y > 18 && <rect fill={color} x={cell.min.x + 6} y={cell.min.y + 6} width={(cell.max.x - cell.min.x) * 0.8} height={6} />}
        {cell.max.y - cell.min.y > 30 && <rect fill={color} x={cell.min.x + 6} y={cell.min.y + 18} width={(cell.max.x - cell.min.x) * 0.5} height={6} />}
    </g>
);
