import React from 'react';
import MuIcon from '../../../Components/MuIcon';
import { classWithModifiers } from '../../../Helpers/css';
import { useAppDispatch } from '../../../Helpers/hooks';
import { openLightbox } from '../../../Redux/reducers/lightboxSlice';

type Props = {
    imageUrl: string;
    updateQuestion: () => void;
    className?: string;
};

const EditQuestionImage: React.FC<Props> = ({
    updateQuestion,
    imageUrl,
    className,
}) => {
    const dispatch = useAppDispatch();

    return (
        <div className={classWithModifiers('mu-edit-question-file', [], className)}>
            <button
                type="button"
                onClick={updateQuestion}
                className="mu-edit-question-file__file-remove"
            >
                <MuIcon
                    svgName="CloseCircleFill"
                />
            </button>
            <img
                className="mu-edit-question-file__image"
                src={imageUrl}
                alt="question"
                onClick={() => dispatch(openLightbox({ urls: [imageUrl] }))}
            />
        </div>
    );
};

export default EditQuestionImage;
