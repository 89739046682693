import React from 'react';

import { nextZoom, scale } from './injectCamera';

export const SimpleZoomBar = ({
    startZoomIn,
    startZoomOut,
    camera,
}) => (
    <div className="zoomBar" onMouseDown={(e) => e.stopPropagation()}>
        <div
            className="zoomBar-zoomOut"
            onMouseDown={(e) => {
                startZoomOut(e);
                e.stopPropagation();
            }}
        >
            -
        </div>
        <div className="zoomBar-label">
            {Math.round(camera.a * 100)}
            %
        </div>
        <div
            className="zoomBar-zoomIn"
            onMouseDown={(e) => {
                startZoomIn(e);
                e.stopPropagation();
            }}
        >
            +
        </div>
    </div>
);

export class ZoomBar extends React.Component {
  _timeout = null;

  loop = (direction: 1 | -1, i: number) => {
      this.props.setCamera(scale(this.props.camera, nextZoom(this.props.camera.a, direction, true), {
          x: 150,
          y: 100,
      }));

      this._timeout = setTimeout(() => this.loop(direction, i + 1), i === 0 ? 500 : 120);
  };

  startZoomOut = () => this.loop(-1, 0);

  startZoomIn = () => this.loop(1, 0);

  stop = () => clearTimeout(this._timeout);

  componentWillMount() {
      window.addEventListener('mouseup', this.stop);
  }

  componentWillUnmount() {
      window.removeEventListener('mouseup', this.stop);
      this.stop();
  }

  render() {
      return (
          <>
              <SimpleZoomBar {...this.props} startZoomOut={this.startZoomOut} startZoomIn={this.startZoomIn} />
          </>
      );
  }
}
