import {
    getProject, Metrics,
    getQuestionnairesQuery, getQuestionnaire, getProfilesQuery, getProfile
} from 'mushin-redux-store';
import {Dispatch, SetStateAction} from 'react';
import i18n from 'i18next';
import ExcelJS from 'exceljs';
import {
    ExcelBuilder,
    formatUserFields, getCriteria,
    usersHeaders
} from './excelExports';
import {AsyncAppThunk} from '../../Redux/reducers';
import {addQuestionnaireToWorkbook} from './questionnaireStatsExport';

const metricsHeaders = (): string[] => ([
    i18n.t<string>('exportStatistics.headers.notesVolume'),
    i18n.t<string>('exportStatistics.headers.notesMobileVolume'),
    i18n.t<string>('exportStatistics.headers.notesMushitVolume'),
    i18n.t<string>('exportStatistics.headers.notesWebappVolume'),
    i18n.t<string>('exportStatistics.headers.chatMessageVolume'),
    i18n.t<string>('exportStatistics.headers.chatCommentVolume'),
    i18n.t<string>('exportStatistics.headers.questionnaireAnswerVolume'),
    i18n.t<string>('exportStatistics.headers.notesMoodboardVolume'),
    i18n.t<string>('exportStatistics.headers.pdfVolume'),
    i18n.t<string>('exportStatistics.headers.tagsVolume'),
]);

const formatMetricsFields = (metrics: Metrics | undefined): number[] => {
    return [
        metrics?.notes?.volume || 0,
        metrics?.notes?.mobile_volume || 0,
        metrics?.notes?.mushit_volume || 0,
        metrics?.notes?.webapp_volume || 0,
        metrics?.chat?.message_volume || 0,
        metrics?.chat?.comment_volume || 0,
        metrics?.questionnaire?.answer_volume || 0,
        metrics?.moodboards?.volume || 0,
        metrics?.pdf?.volume || 0,
        metrics?.tags?.volume || 0,
    ];
};

const fillMetricWorksheet = (
    excelBuilder: ExcelBuilder,
    profileIds: string[],
    incrementProgress: () => void,
): AsyncAppThunk => async (dispatch) => {
    const criteria = await dispatch(getCriteria(profileIds));
    excelBuilder.setHeaders([...usersHeaders(criteria), ...metricsHeaders()]);
    for (const profileId of profileIds) {
        // eslint-disable-next-line no-await-in-loop
        const profile = await dispatch(getProfile(profileId));
        excelBuilder.addLine([
            // eslint-disable-next-line no-await-in-loop
            ...(await dispatch(formatUserFields(
                profileId,
                criteria,
                () => ({mod: '', label: i18n.t('global.active')}),
            ))),
            ...formatMetricsFields(profile?.metrics)
        ]);
        incrementProgress();
    }
};

export const projectMetricsExport = (
    projectId: string,
    setProgress: Dispatch<SetStateAction<number>> = () => { /* */ }
): AsyncAppThunk => async (dispatch) => {
    const project = await dispatch(getProject(projectId));
    if (project) {
        const excelBuilder = new ExcelBuilder(
            project.name.replace(/[^a-z0-9]/gi, '_'),
            'Global',
        );
        setProgress(10);

        const profilesQuery = await dispatch(getProfilesQuery({
            project_id: projectId,
        }, true, true));

        const progressNumIntervals = profilesQuery?.data.length || 1;

        excelBuilder.setHeaders(['', ...metricsHeaders()]);
        excelBuilder.addLine([i18n.t<string>('exportStatistics.total'), ...formatMetricsFields(project.metrics)]);

        const progressInterval = 90 / progressNumIntervals;
        const incrementProgress = () => setProgress((progress) => progress + progressInterval);

        const profileIds = profilesQuery?.data || [];
        if (profileIds.length) {
            excelBuilder.createWorksheet('Per user');
            await dispatch(fillMetricWorksheet(excelBuilder, profileIds, incrementProgress));
        }

        setProgress(100);

        await excelBuilder.export();
    }
};

export const projectQuestionnairesExport = (
    projectId: string,
    setProgress: Dispatch<SetStateAction<number>> = () => { /* */ }
): AsyncAppThunk => async (dispatch) => {
    try {
        const project = await dispatch(getProject(projectId));
        if (project) {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Project Overview');

            worksheet.mergeCells('A1:D2');
            const headerCell = worksheet.getCell('A1');
            headerCell.value = project.name;
            headerCell.alignment = { vertical: 'middle', horizontal: 'center' };
            headerCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '85B8B2' },
            };
            headerCell.font = { size: 16, bold: true };

            const subtitles = ['Nom', 'N° participants', 'N° questions', 'Cible'];
            const subtitleRow = worksheet.getRow(3);
            subtitleRow.values = subtitles;

            subtitleRow.eachCell((cell) => {
                const tempCell = cell;
                tempCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '9DB4BA' },
                };
                tempCell.font = { size: 12, bold: true, color: { argb: 'FFFFFF' } };
                tempCell.alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getColumn(1).width = 50; // 400px
                worksheet.getColumn(2).width = 25; // 200px
                worksheet.getColumn(3).width = 25; // 200px
                worksheet.getColumn(4).width = 62.5; // 500px
            });
            worksheet.views = [{ state: 'frozen', ySplit: 3, activeCell: 'A1'}];

            setProgress(0);

            const questionnairesQuery = await dispatch(getQuestionnairesQuery({ project_id: projectId }, true, true));
            const questionnaires = questionnairesQuery?.data || [];

            if (!Array.isArray(questionnaires)) {
                throw new TypeError('Invalid attempt to iterate non-iterable instance.');
            }

            const totalQuestionnaires = questionnaires.length;
            const progressInterval = 100 / totalQuestionnaires;
            let progress = 0;

            const questionnairePromises = questionnaires.map(async (questionnaireId, index) => {
                const rowNumber = index + 4;
                const questionnaire = await dispatch(getQuestionnaire(questionnaireId));
                if (questionnaire) {
                    const cell = worksheet.getCell(`A${rowNumber}`);
                    cell.value = questionnaire.title;
                    cell.alignment = { wrapText: true };
                    worksheet.getRow(rowNumber).height = worksheet.getRow(rowNumber).height || 20;

                    const participantsCell = worksheet.getCell(`B${rowNumber}`);
                    participantsCell.value = questionnaire.respondent_count || 0;

                    const questionsCell = worksheet.getCell(`C${rowNumber}`);
                    questionsCell.value = questionnaire.questions.length;

                    const accessTypeCell = worksheet.getCell(`D${rowNumber}`);
                    accessTypeCell.value = questionnaire.accessType || 'Public';
                }
            });

            await Promise.all(questionnairePromises);
            // pages
            const questionnairePromises2 = questionnaires.map(async (questionnaireId) => {
                const questionnaire = await dispatch(getQuestionnaire(questionnaireId));
                if (questionnaire && questionnaire.respondent_count > 0) {
                    await addQuestionnaireToWorkbook(workbook, questionnaireId, setProgress, dispatch);
                }
                progress += progressInterval;
                setProgress(progress);
            });

            await Promise.all(questionnairePromises2);

            setProgress(100);

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const now = new Date();
            const dateExport = `
            ${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}
            `;
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `export_${project.name.replace(/[^a-z0-9]/gi, '_')}_${dateExport}.xlsx`;
            link.click();
        }
    } catch (error) {
        console.error('Error export pdf', error);
    }
};
