import React from 'react';
import {Questionnaire, useProject} from 'mushin-redux-store';
import {useParams} from 'react-router-dom';
import i18n from 'i18next';
import AppLayout from '../../../Components/Layouts/AppLayout';
import TextTranslation from '../../../Components/Translation/TextTranslation';

type Props = React.PropsWithChildren<{
    questionnaire?: Questionnaire | null;
}>;

const QuestionnaireAppLayout: React.FC<Props> = ({
    questionnaire,
    children,
}) => {
    const {projectId} = useParams<{[key: string]: string}>();
    const project = useProject(projectId);

    if (!project) return null;

    return (
        <AppLayout
            title={(
                questionnaire === null
                    ? i18n.t('questionnaires.unavailable')
                    : questionnaire
                        && (
                            <TextTranslation
                                translationProp={questionnaire.titles}
                                fallback={questionnaire.title}
                            />
                        ) 
            )}
            backLink={(
                questionnaire?.candidacy
                    ? `/projects/${project.id}/members?is_contributor=true`
                    : `/projects/${project.id}/missions`
            )}
        >
            {children}
        </AppLayout>
    );
};

export default QuestionnaireAppLayout;
