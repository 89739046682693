import React from 'react';
import {useDispatch} from 'react-redux';
import {Project} from 'mushin-redux-store';
import i18n from 'i18next';
import {openModal} from '../../../../../Redux/reducers/modalsSlice';
import ActionCard from '../../../../../Components/ActionCard/ActionCard';
import OpportunitiesCard from './OpportunitiesCard';
import {isProjectAdmin} from '../../../../../Helpers/users';

type Props = {
    project: Project;
};

const OpportunitiesList: React.FC<Props> = ({
    project,
}) => {
    const dispatch = useDispatch();

    return (
        <section className="mu-insights__section-insights">
            {isProjectAdmin(project) && (
                <div className="mu-insights__create">
                    <ActionCard
                        activeMode="Opportunities"
                        onClick={() => dispatch(openModal('CreateAlbum', {projectId: project.id}))}
                        create
                        createText={i18n.t<string>('opportunities.create')}
                    />
                </div>
            )}
            <div className="mu-insights__section-list">
                <p className="mu-synthesis-header__header--text ">
                    {i18n.t<string>('SynthesisOpportunities.textList')}
                </p>
                <OpportunitiesCard
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut l
                abore et dolore magna aliqua..."
                    span="Publié par Maxime, hier à 22:32"
                    numberSolutions={5}
                />
                <OpportunitiesCard
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut l
                abore et dolore magna aliqua..."
                    span="Publié par Maxime, hier à 22:32"
                    numberSolutions={10}
                />
                <OpportunitiesCard
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut l
                abore et dolore magna aliqua..."
                    span="Publié par Maxime, hier à 22:32"
                    numberSolutions={4}
                />
                <OpportunitiesCard
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut l
                abore et dolore magna aliqua..."
                    span="Publié par Maxime, hier à 22:32"
                    numberSolutions={3}
                />
            </div>
        </section>
    );
};

export default OpportunitiesList;
