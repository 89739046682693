import {UndoableOptions} from 'redux-undo';
import {Action} from 'redux';

type UndoActions = {
    UNDO: string;
    REDO: string;
    JUMP_TO_FUTURE: string;
    JUMP_TO_PAST: string;
    JUMP: string;
    CLEAR_HISTORY: string;
}

type UndoActionCreators = {
    undo: () => Action;
    redo: () => Action;
    jump: (point: number) => Action;
    jumpToPast: (index: number) => Action;
    jumpToFuture: (index: number) => Action;
    clearHistory: () => Action;
}

export const getUndoActionsWithPrefix = (prefix: string): UndoActions => ({
    UNDO: `${prefix}/UNDO`,
    REDO: `${prefix}/REDO`,
    JUMP_TO_FUTURE: `${prefix}/JUMP_TO_FUTURE`,
    JUMP_TO_PAST: `${prefix}/JUMP_TO_PAST`,
    JUMP: `${prefix}/JUMP`,
    CLEAR_HISTORY: `${prefix}/CLEAR_HISTORY`,
});

export const getActionCreators = (actions: UndoActions): UndoActionCreators => ({
    undo() {
        return { type: actions.UNDO };
    },
    redo() {
        return { type: actions.REDO };
    },
    jumpToFuture(index: number) {
        return { type: actions.JUMP_TO_FUTURE, index };
    },
    jumpToPast(index: number) {
        return { type: actions.JUMP_TO_PAST, index };
    },
    jump(index: number) {
        return { type: actions.JUMP, index };
    },
    clearHistory() {
        return { type: actions.CLEAR_HISTORY };
    },
});

export const getUndoTypeOptions = (actions: UndoActions): UndoableOptions => ({
    undoType: actions.UNDO,
    redoType: actions.REDO,
    jumpToFutureType: actions.JUMP_TO_FUTURE,
    jumpToPastType: actions.JUMP_TO_PAST,
    jumpType: actions.JUMP,
    clearHistoryType: actions.CLEAR_HISTORY,
});
