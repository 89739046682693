import moment from 'moment';

export const statusToParams = (status: string | undefined): {
    archived?: boolean;
    min_start_date?: number;
    max_start_date?: number;
    min_end_date?: number;
    max_end_date?: number;
} => {
    switch (status) {
        case 'draft':
            return {
                min_start_date: moment().unix(),
                archived: false,
            };
        case 'inProgress':
            return {
                max_start_date: moment().unix(),
                min_end_date: moment().unix(),
                archived: false,
            };
        case 'finished':
            return {
                max_end_date: moment().unix(),
                archived: false,
            };
        case 'archived':
            return {
                archived: true,
            };
        default:
            return {};
    }
};
