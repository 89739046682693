const getSetting = (key: string, defaultValue: string | null = null) => ({
    value: localStorage.getItem(`settings.${key}`) || defaultValue,
    setValue: (value: string) => localStorage.setItem(`settings.${key}`, value),
});

const getNumberSetting = <T extends number | null>(key: string, defaultValue: T = null as T) => {
    const { value, setValue } = getSetting(key);
    return {
        value: value ? parseInt(value) : defaultValue,
        setValue: (val: number) => setValue(val.toString()),
    };
};

const getBooleanSetting = (key: string, defaultValue = false) => {
    const { value, setValue } = getSetting(key);
    return {
        value: value ? value === 'true' : defaultValue,
        setValue: (val: boolean) => setValue(val.toString()),
    };
};

export default {
    lastProject: getSetting('last_project', null),
    galleryColumnNumber: getNumberSetting('gallery_column_number', 2),
};
