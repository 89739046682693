import React from 'react';
import { classWithModifiers } from '../../Helpers/css';
import { imgWithCredentials } from '../../config';

type Props = {
  imgUrl: string;
  classModifiers?: Array<string>;
  className?: string;
  removeCredentials?: boolean;
};

const SquarePicture = ({
    imgUrl,
    classModifiers,
    className,
    removeCredentials,
}: Props) => (
    <div className={`${className || ''} ${classWithModifiers('mu-note-picture-block', [...(classModifiers || '')])}`}>
        <div className="mu-note-picture-block__square">
            <img alt="" className="mu-note-picture-block__img" src={imgUrl} crossOrigin={imgWithCredentials && !removeCredentials ? imgWithCredentials : undefined} />
        </div>
    </div>
);

SquarePicture.defaultProps = {
    classModifiers: [],
};

export default SquarePicture;
