/* eslint-disable react/jsx-props-no-spreading */
import { Criterion, translateCriterion } from 'mushin-redux-store';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import TextTranslation, { TextTranslationProps } from './TextTranslation';

interface Props extends Omit<TextTranslationProps, 'fallback' | 'translationProp'> {
  criterion: Criterion;
}

const CriteriaTranslation: FC<Props> = ({ criterion, ...props }) => {
    const dispatch = useDispatch();

    const handleTranslateCriterion = () => {
        const translateLanguage = i18next.resolvedLanguage;
        if (!translateLanguage || !criterion) return;
        dispatch(translateCriterion(criterion.id, { lang: translateLanguage }));
    };

    return (
        <TextTranslation
            {...props}
            translationProp={criterion?.titles}
            fallback={criterion?.name}
            onTranslate={handleTranslateCriterion}
        />
    ); 
};

export default CriteriaTranslation;
