import React from 'react';
import { ActionBar } from './common';
import { ActionProps, ActionBarSeparator, ReturnToCellModeButton } from '../actionButtons/common';
import { ContainButton, ExpandButton, ZoomInButton, ZoomOutButton } from '../actionButtons/crop';

export default ((props: ActionProps) => (
    <ActionBar>
        <ReturnToCellModeButton {...props} />
        <ActionBarSeparator />
        <ZoomInButton {...props} />
        <ZoomOutButton {...props} />
        <ActionBarSeparator />
        <ExpandButton {...props} />
        <ContainButton {...props} />
    </ActionBar>
));
