import React from 'react';
import EntitySelectCommon from './Components/EntitySelectCommon';

type Props<T> = {
    className?: string;
    value: string | undefined | null;
    options: string[];
    useItem: (id: string | undefined) => T | null;
    onSelect: (entityId: string | null) => void;
    getMoreResults: () => void;
    placeholder: string;
    getEntityName: (entity: NonNullable<T>) => string;
    defaultOptions?: {[key: string]: React.ReactNode};
    neverEmpty?: boolean;
    disabled?: boolean;
}

type EntitySelect<T> = React.FC<Props<T>>

const getId = (
    value: string | undefined | null,
    defaultOptions?: {[key: string]: React.ReactNode},
): string | undefined => {
    if (!value) return undefined;
    if (defaultOptions && Object.keys(defaultOptions).includes(value)) return undefined;
    return value;
};

const getValue = (
    value: string | null,
    placeholder: string,
    defaultOptions?: {[key: string]: React.ReactNode},
): React.ReactNode => {
    if (!value) return placeholder;
    if (defaultOptions && Object.keys(defaultOptions).includes(value)) return defaultOptions[value];
    return value;
};

const EntitySelect = <T, >(
    {
        value,
        options,
        useItem,
        onSelect,
        getMoreResults,
        placeholder,
        getEntityName,
        className,
        defaultOptions,
        neverEmpty,
        disabled,
    }: Parameters<EntitySelect<T>>[0]
): ReturnType<EntitySelect<T>> => {
    const entityId = getId(value, defaultOptions);
    const entity = useItem(entityId);
    return (
        <EntitySelectCommon
            className={className}
            value={getValue(
                entityId ? (entity && getEntityName(entity)) : (value || null),
                placeholder,
                defaultOptions,
            )}
            options={options}
            useItem={useItem}
            onSelect={onSelect}
            getMoreResults={getMoreResults}
            getEntityName={getEntityName}
            removable={!neverEmpty && !!value}
            onRemove={() => onSelect(null)}
            defaultOptions={defaultOptions}
            disabled={disabled}
        />
    );
};

export default EntitySelect;
