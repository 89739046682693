import React, {useEffect, useState} from 'react';
import {useUploadsCount} from 'mushin-redux-store';
import i18n from 'i18next';
import NotifBackgroundTask from '../../../../Components/NotifBackgroundTask/NotifBackgroundTask';
import {useAppDispatch, useAppSelector} from '../../../../Helpers/hooks';
import {openModal} from '../../../../Redux/reducers/modalsSlice';
import {addNotifSuccess} from '../../../../Redux/reducers/appNotificationsSlice';

const UploadingAlert: React.FC = () => {
    const uploadsCount = useUploadsCount();
    const uploadModalIsClosed = useAppSelector(
        (state) => !state.modals.modalTypes.includes('UploadFilesModal')
    );
    const [uploading, setUploading] = useState<boolean | null>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (uploading === false) {
            dispatch(addNotifSuccess(i18n.t('upload_alert.success')));
        }
    }, [dispatch, uploading]);

    if (uploadsCount > 0 && !uploading) {
        setUploading(true);
    }
    if (uploadsCount === 0 && uploading) {
        setUploading(false);
    }

    const tradPlural = uploadsCount > 1 ? '_plural' : '';
    return uploadsCount > 0 ? (
        <NotifBackgroundTask
            title={i18n.t<string>('upload_alert.title')}
            message={i18n.t<string>(`upload_alert.text${tradPlural}`, { nbFiles: uploadsCount })}
            options={{ icon: 'Upload', type: 'info' }}
            labelAction={i18n.t<string>('upload_alert.action')}
            handleAction={uploadModalIsClosed ? () => dispatch(openModal('UploadFilesModal')) : null}
        />
    ) : null;
};

export default UploadingAlert;
