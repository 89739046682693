import React, {useState} from 'react';
import Modal from '../../../../Components/Modal/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalBody} from '../../../../Components/Modal/ModalBody';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton/MuButton';
import Spinner from '../../../../Components/Spinner';

type Props = {
    title: React.ReactNode;
    body: React.ReactNode;
    onClose: () => void;
    confirmAction: () => Promise<void>;
    confirmLabel: React.ReactNode;
    icon: string;
    withCancelBtn?: boolean;
    cancelAction?: () => void;
    cancelLabel?: React.ReactNode;
    type?: 'danger';
    infoPanel?: () => React.ReactNode;
};

const AlertModal: React.FC<Props> = ({
    body,
    cancelAction,
    cancelLabel,
    onClose,
    confirmAction,
    confirmLabel,
    icon,
    withCancelBtn = false,
    title,
    type,
    infoPanel,
}) => {
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        if (cancelAction) cancelAction();
        onClose();
    };

    const handleConfirm = async () => {
        setLoading(true);
        if (confirmAction) await confirmAction();
        setLoading(false);
        onClose();
    };

    return (
        <Modal classModifiers={['small', type]} handleRemoveModal={onClose}>
            <ModalHeader title={title} icon={icon} />
            <ModalBody>
                {infoPanel && infoPanel()}
                <p className="mu-modal__catchphrase">
                    {body}
                </p>
            </ModalBody>
            <ModalFooter classModifiers="alert">
                <section className="mu-modal-footer__buttons">
                    {withCancelBtn && (
                        <MuButton
                            label={cancelLabel}
                            handleClick={handleCancel}
                            classModifiers={['large', 'default']}
                        />
                    )}
                    <MuButton
                        label={loading ? <Spinner size="20px" /> : confirmLabel}
                        handleClick={handleConfirm}
                        classModifiers={['large', type === 'danger' ? 'error' : '']}
                        disabled={loading}
                    />
                </section>
            </ModalFooter>
        </Modal>
    );
};

export default AlertModal;
