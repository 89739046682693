import React from 'react';

import {ReactionsKeys, Reactions, useMe} from 'mushin-redux-store';
import MuIcon from '../MuIcon';
import {classWithModifiers} from '../../Helpers/css';
import {useAppDispatch} from '../../Helpers/hooks';
import ReactionAllButton from './ReactionAllButton';
import {openModal} from '../../Redux/reducers/modalsSlice';

const icons = {
    like: 'ThumbsUp',
    love: 'Heart',
    instructive: 'Bulb',
    favorite: 'Star',
};

export type CommonReactionProps = {
    className?: string;
    name: 'favorite' | 'all' | ReactionsKeys;
    horizontal?: boolean;
    contrast?: boolean;
    size?: 'small' | 'large';
}

type Props = CommonReactionProps & {
    reactions: Reactions | undefined;
    favorites: string[] | undefined;
    toggleReaction: (key: ReactionsKeys) => void;
    toggleFavorite: () => void;
}

const Reaction: React.FC<Props> = (
    {
        name,
        reactions,
        favorites,
        toggleReaction,
        toggleFavorite,
        className,
        horizontal = false,
        contrast = false,
        size,
    }
) => {
    const me = useMe();

    const dispatch = useAppDispatch();

    const renderIcon = (key: 'favorite' | ReactionsKeys, modifier?: string) => {
        const list = key === 'favorite' ? favorites : reactions?.[key];
        return (
            <MuIcon
                key={key}
                className={classWithModifiers(
                    'mu-reaction__icon',
                    [{[`${key}-active`]: (me && list?.includes(me.id)), horizontal}, key, size, modifier]
                )}
                svgName={icons[key]}
            />
        );
    };

    const openReactions = () => dispatch(openModal('Reactions', {
        name: name === 'favorite' ? 'favorite' : 'all',
        reactions,
        favorites,
    }));

    const list = name === 'favorite' ? favorites : reactions?.[name];

    return (
        <div className={classWithModifiers('mu-reaction', [{[name]: true, horizontal, contrast}, size], className)}>
            {name === 'all' ? (
                <ReactionAllButton
                    icons={['like', 'love', 'instructive']}
                    renderIcon={renderIcon}
                    toggleReaction={toggleReaction}
                />
            ) : (
                <button
                    type="button"
                    className="mu-reaction__button"
                    onClick={() => {
                        if (name === 'favorite') {
                            toggleFavorite();
                        } else {
                            toggleReaction(name);
                        }
                    }}
                >
                    {renderIcon(name)}
                </button>
            )}
            <button
                type="button"
                className={classWithModifiers('mu-reaction__total', [{horizontal}, size])}
                onClick={openReactions}
            >
                {list?.length || 0}
            </button>
        </div>
    );
};

export default Reaction;
