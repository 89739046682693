import {Note} from 'mushin-redux-store';
import {
    buildWithMarginTop as buildTemplate,
    getTemplateSpotCount
} from '../Components/BoardComponents/BoardLayoutTemplate/templateBuilder';
import {templates as rawTemplates} from '../Components/BoardComponents/BoardLayoutTemplate/templates';
import {MoodboardPage} from '../Components/BoardComponents/BoardLayout/type';
import {adaptContentForTemplate} from './moodboardContent';

const MARGIN = 10;
const WIDTH = 300;
const HEIGHT = WIDTH * Math.SQRT1_2;

export const getMinPages = (length: number): number => Math.ceil(length / 11);
export const getMaxPages = (length: number): number => Math.floor(length / 2);

const getBestTemplate = (spotCount: number) => {
    const error = (i: number) => Math.abs(getTemplateSpotCount(rawTemplates[i]) - spotCount);

    return rawTemplates.reduce((best, _, i) => (error(best) > error(i) ? i : best), 0);
};

export const createBoardPageFromNotes = (notes: Note[], title?: string): MoodboardPage => {
    const templateId = getBestTemplate(notes.length);

    const template = buildTemplate(rawTemplates[templateId], WIDTH, HEIGHT, MARGIN);

    return {
        boardData: {
            notePool: notes,
            templateId,
            templateContents: template.contents,
        },
        board: adaptContentForTemplate(template, notes, null, title),
    };
};

export const getBestNotesPerBoard = (length: number, numberPages: number): number => {
    if (length % numberPages === 0) return length / numberPages;

    // First pages refer here to all pages except the last
    const lengthForFirstPages = length - Math.ceil(length / numberPages);
    return Math.floor(lengthForFirstPages / (numberPages - 1));
};

export const createBoardPagesFromNotes = (notes: Note[], numberPages = 1, title?: string): MoodboardPage[] => {
    const boards = [];
    const notesPerBoard = getBestNotesPerBoard(notes.length, numberPages);

    for (let i = 0; i < numberPages; i++) {
        const start = i * notesPerBoard;
        boards.push(
            createBoardPageFromNotes(
                notes.slice(start, i === numberPages - 1 ? undefined : start + notesPerBoard),
                i === 0 ? title : undefined
            )
        );
    }

    return boards;
};

export const setTitleToBoardPage = (title: string, page: MoodboardPage): MoodboardPage => {
    const template = buildTemplate(rawTemplates[page.boardData.templateId as number], WIDTH, HEIGHT, MARGIN);

    return {
        boardData: {
            ...page.boardData,
            templateContents: template.contents,
        },
        board: adaptContentForTemplate(template, page.boardData.notePool, null, title),
    };
};

export const setTemplateToBoardPage = (templateId: number, page: MoodboardPage): MoodboardPage => {
    const template = buildTemplate(rawTemplates[templateId], WIDTH, HEIGHT, MARGIN);
    return {
        boardData: {
            ...page.boardData,
            templateId,
            templateContents: template.contents,
        },
        board: adaptContentForTemplate(template, page.boardData.notePool, page.board, page.board.title),
    };
};

export const setTemplateToBoardPages = (templateId: number, pages: MoodboardPage[]): MoodboardPage[] => (
    pages.map((page) => setTemplateToBoardPage(templateId, page))
);
