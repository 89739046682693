const data = {
    'Pacific/Midway': 'US',
    'Pacific/Pago_Pago': 'AS',
    'Pacific/Honolulu': 'US',
    'America/Juneau': 'US',
    'America/Los_Angeles': 'US',
    'America/Tijuana': 'MX',
    'America/Denver': 'US',
    'America/Phoenix': 'US',
    'America/Chihuahua': 'MX',
    'America/Mazatlan': 'MX',
    'America/Chicago': 'US',
    'America/Regina': 'CA',
    'America/Mexico_City': 'MX',
    'America/Monterrey': 'MX',
    'America/Guatemala': 'GT',
    'America/New_York': 'US',
    'America/Indiana/Indianapolis': 'US',
    'America/Bogota': 'CO',
    'America/Lima': 'PE',
    'America/Halifax': 'CA',
    'America/Caracas': 'VE',
    'America/La_Paz': 'BO',
    'America/Santiago': 'CL',
    'America/St_Johns': 'CA',
    'America/Sao_Paulo': 'BR',
    'America/Argentina/Buenos_Aires': 'AR',
    'America/Guyana': 'GY',
    'America/Godthab': 'GL',
    'Atlantic/South_Georgia': 'GS',
    'Atlantic/Azores': 'PT',
    'Atlantic/Cape_Verde': 'CV',
    'Europe/Dublin': 'IE',
    'Europe/London': 'GB',
    'Europe/Lisbon': 'PT',
    'Africa/Casablanca': 'MA',
    'Africa/Monrovia': 'LR',
    'Etc/UTC': 'ZZ',
    'Europe/Belgrade': 'RS',
    'Europe/Bratislava': 'SK',
    'Europe/Budapest': 'HU',
    'Europe/Ljubljana': 'SI',
    'Europe/Prague': 'CZ',
    'Europe/Sarajevo': 'BA',
    'Europe/Skopje': 'MK',
    'Europe/Warsaw': 'PL',
    'Europe/Zagreb': 'HR',
    'Europe/Brussels': 'BE',
    'Europe/Copenhagen': 'DK',
    'Europe/Madrid': 'ES',
    'Europe/Paris': 'FR',
    'Europe/Amsterdam': 'NL',
    'Europe/Berlin': 'DE',
    'Europe/Rome': 'IT',
    'Europe/Stockholm': 'SE',
    'Europe/Vienna': 'AT',
    'Africa/Algiers': 'DZ',
    'Europe/Bucharest': 'RO',
    'Africa/Cairo': 'EG',
    'Europe/Helsinki': 'FI',
    'Europe/Kiev': 'UA',
    'Europe/Riga': 'LV',
    'Europe/Sofia': 'BG',
    'Europe/Tallinn': 'EE',
    'Europe/Vilnius': 'LT',
    'Europe/Athens': 'GR',
    'Europe/Istanbul': 'TR',
    'Europe/Minsk': 'BY',
    'Asia/Jerusalem': 'IL',
    'Africa/Harare': 'ZW',
    'Africa/Johannesburg': 'ZA',
    'Europe/Kaliningrad': 'RU',
    'Europe/Moscow': 'RU',
    'Europe/Volgograd': 'RU',
    'Europe/Samara': 'RU',
    'Asia/Kuwait': 'KW',
    'Asia/Riyadh': 'SA',
    'Africa/Nairobi': 'KE',
    'Asia/Baghdad': 'IQ',
    'Asia/Tehran': 'IR',
    'Asia/Muscat': 'OM',
    'Asia/Baku': 'AZ',
    'Asia/Tbilisi': 'GE',
    'Asia/Yerevan': 'AM',
    'Asia/Kabul': 'AF',
    'Asia/Yekaterinburg': 'RU',
    'Asia/Karachi': 'PK',
    'Asia/Tashkent': 'UZ',
    'Asia/Kolkata': 'IN',
    'Asia/Kathmandu': 'NP',
    'Asia/Dhaka': 'BD',
    'Asia/Colombo': 'LK',
    'Asia/Almaty': 'KZ',
    'Asia/Novosibirsk': 'RU',
    'Asia/Rangoon': 'MM',
    'Asia/Bangkok': 'TH',
    'Asia/Jakarta': 'ID',
    'Asia/Krasnoyarsk': 'RU',
    'Asia/Shanghai': 'CN',
    'Asia/Chongqing': 'CN',
    'Asia/Hong_Kong': 'HK',
    'Asia/Urumqi': 'CN',
    'Asia/Kuala_Lumpur': 'MY',
    'Asia/Singapore': 'SG',
    'Asia/Taipei': 'TW',
    'Australia/Perth': 'AU',
    'Asia/Irkutsk': 'RU',
    'Asia/Ulaanbaatar': 'MN',
    'Asia/Seoul': 'KR',
    'Asia/Tokyo': 'JP',
    'Asia/Yakutsk': 'RU',
    'Australia/Darwin': 'AU',
    'Australia/Adelaide': 'AU',
    'Australia/Sydney': 'AU',
    'Australia/Brisbane': 'AU',
    'Australia/Hobart': 'AU',
    'Asia/Vladivostok': 'RU',
    'Pacific/Guam': 'GU',
    'Pacific/Port_Moresby': 'PG',
    'Asia/Magadan': 'RU',
    'Asia/Srednekolymsk': 'RU',
    'Pacific/Noumea': 'NC',
    'Pacific/Fiji': 'FJ',
    'Asia/Kamchatka': 'RU',
    'Pacific/Majuro': 'MH',
    'Pacific/Auckland': 'NZ',
    'Pacific/Tongatapu': 'TO',
    'Pacific/Apia': 'WS',
    'Pacific/Fakaofo': 'TK',
};

const timezoneToCountry = (timezone: string): string | undefined => (
    data[timezone as keyof typeof data]
);

export default timezoneToCountry;
