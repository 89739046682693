import React from 'react';
import * as path from './path';

type Props = {
  color?: string;
  size?: number;
  icon: string;
  MuFontIcon?: boolean;
  onClick?: Function;
  className?: string;
};

const Icon = ({
    color,
    icon,
    size,
    MuFontIcon = false,
    onClick,
    className,
}: Props) => {
    if (MuFontIcon || !path[icon]) {
        const style = { color, fontSize: size ? `${size}px` : 'inherit', cursor: onClick && 'pointer' };
        return <i className={`old-icon old-icon-${icon} ${className || ''}`} style={style} onClick={(evt) => onClick && onClick(evt)} />;
    } if (path[icon]) {
        return (
            <svg viewBox={path[icon].viewBox} style={{ width: size || '100%', height: size || '100%' }} className={className}>
                <path d={path[icon].path} fill={color || '#333'} style={{ transition: 'fill 300ms ease' }} />
            </svg>
        );
    }
    console.warn('[CUSTOM ICON NOT FOUND]', color, icon, size, MuFontIcon);

    return null;
};

export default Icon;
