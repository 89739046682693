import React, { useEffect, useState } from 'react';
import Croppie, {CropType} from 'croppie';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import MuButton from '../../../../Components/MuButton';
import Spinner from '../../../../Components/Spinner';
import InfoPanel from '../../../../Components/InfoPanel/InfoPanel';
import { classWithModifiers } from '../../../../Helpers/css';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import {ModalBody} from '../../../../Components/Modal/ModalBody';

type Props = {
  onClose: () => void;
  fileToCrop: File;
  submitAction: (blob: Blob) => Promise<boolean>;
  title: string;
  icon: string;
  viewport?: {width: number;height: number;type?: CropType;};
  targetWidth?: number;
};

const CropImageModal: React.FC<Props> = ({
    onClose,
    fileToCrop,
    submitAction,
    title,
    icon,
    viewport = { width: 150, height: 150, type: 'square' },
    targetWidth = 300,
}) => {
    const [croppie, setCroppie] = useState<Croppie>();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);
        if (FileReader && fileToCrop) {
            const fr = new FileReader();
            fr.onload = () => {
                const cropImage = document.getElementById('cropImage') as HTMLImageElement;
                if (cropImage) {
                    cropImage.onload = () => {
                        setTimeout(() => {
                            setCroppie(new Croppie(cropImage, {viewport}));
                            setLoading(false);
                        }, 200);
                    };
                    cropImage.src = fr.result as string;
                }
            };
            fr.readAsDataURL(fileToCrop);
        }
    }, [fileToCrop]);

    const handleSubmit = async () => {
        setLoading(true);
        setError('');

        const link = document.createElement('a');
        link.download = 'hello.png';

        const blob = await croppie?.result({
            type: 'blob',
            size: { width: targetWidth, height: targetWidth * (viewport.height / viewport.width) },
        });

        if (blob) {
            const result = await submitAction(blob);
            setLoading(false);
            if (result) {
                onClose();
                return;
            }
        }
        setError(i18n.t<string>('accounts.update.avatarError'));
    };

    return (
        <Modal classModifiers={['large']} handleRemoveModal={onClose}>
            <ModalHeader title={title} icon={icon} />
            <ModalBody>
                {error && <InfoPanel classModifiers={['error']} iconName="Warning" text={error} />}
                {isLoading && (
                    <div className="mu-modal__spinner">
                        <Spinner size="50px" />
                    </div>
                )}
                <div className={classWithModifiers('mu-crop-image-modal__container', { hidden: isLoading })}>
                    <div className="mu-crop-image-modal__workspace">
                        <img id="cropImage" alt="Crop profile" />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <MuButton
                    label={i18n.t('global.cancel')}
                    handleClick={onClose}
                    classModifiers={['large', 'default']}
                />
                <MuButton
                    disabled={isLoading}
                    label={i18n.t('global.confirm')}
                    handleClick={handleSubmit}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>
    );
};

export default CropImageModal;
