import React from 'react';
import EventEmitter from 'events';
import PropTypes from 'prop-types';

// const generatePreview = ((size) => {
//     if (typeof document === 'undefined') return () => null
//
//     const canvas = document.createElement('canvas')
//     canvas.width = canvas.height = size
//
//     if (!document.body) return () => null
//
//     document.body.appendChild(canvas)
//     canvas.style.position = 'absolute'
//     canvas.style.top = '-999px'
//     canvas.style.left = '-999px'
//
//     const ctx = canvas.getContext('2d')
//
//     if (!ctx) return () => null
//
//     return (element) => {
//         ctx.fillStyle = element.image_url
//         ctx.beginPath()
//         ctx.rect(0, 0, size, size)
//         ctx.fill()
//
//         return canvas
//     }
// })(100)

export class Provider extends React.Component {
  _ee = new EventEmitter();

  _dragged: Object | null = null;

  getDragged = () => this._dragged;

  subsribe = (fn: () => any) => {
      this._ee.addListener('update', fn);
      return () => this._ee.removeListener('update', fn);
  };

  startDragElement = (event: DragEvent, element: Object) => {
      this._dragged = element;

      this._ee.emit('update');
  };

  onDragEnd = () => {
      if (this._dragged) {
          this._dragged = null;

          this._ee.emit('update');
      }
  };

  static childContextTypes = {
      elementDragStore: PropTypes.shape({
          subsribe: PropTypes.func,
          getDragged: PropTypes.func,
          startDragElement: PropTypes.func,
      }),
  };

  getChildContext() {
      return {
          elementDragStore: {
              subsribe: this.subsribe,
              getDragged: this.getDragged,
              startDragElement: this.startDragElement,
          },
      };
  }

  componentDidMount() {
      if (typeof window === 'undefined') return;
      window.removeEventListener('dragend', this.onDragEnd);
      window.addEventListener('dragend', this.onDragEnd);
      this.onDragEnd();
  }

  componentWillUnmount() {
      if (typeof window === 'undefined') return;
      window.removeEventListener('dragend', this.onDragEnd);
  }

  render() {
      return React.Children.only(this.props.children);
  }
}

type DragInjectorProps = {};
type DragInjectorState = {
  draggedElement: Object | null;
};

/**
 * @desc this wrapper to inject drag&drop management with the MoodBoard Canvas
 * @param C
 * @returns {DragInjector}
 */
// @todo how to type this ? React.Component gives flow error in the rendering func
export const injectDraggedElement = (C: React.ComponentType<any, any>) => {
    class DragInjector extends React.Component<DragInjectorProps, DragInjectorState> {
    static contextTypes = {
        elementDragStore: PropTypes.shape({
            subsribe: PropTypes.func,
            getDragged: PropTypes.func,
            startDragElement: PropTypes.func,
        }),
    };

    context: Object;

    state = { draggedElement: null };

    _unsubsribe = null;

    onUpdateDragStore = () => this.setState({
        draggedElement: this.context.elementDragStore.getDragged(),
    });

    componentDidMount() {
        this._unsubsribe && this._unsubsribe();
        this._unsubsribe = this.context.elementDragStore.subsribe(this.onUpdateDragStore);
    }

    componentWillUnmount() {
        this._unsubsribe && this._unsubsribe();
    }

    render() {
        return <C {...this.props} {...this.state} startDragElement={this.context.elementDragStore.startDragElement} />;
    }
    }

    return DragInjector;
};
