import {
    Album,
    Project,
    Chat,
    Note,
    Notification,
    Transaction,
    Questionnaire,
    PureTarget,
    Target,
    NotificationObject,
} from 'mushin-redux-store';
import {downloadFile} from '../../../Helpers/download';
import {getQuestionnaireUrl} from '../../../Helpers/questionnaires';

export type ElementParameter = (
    { type: 'Transaction', value: Transaction }
    | { type: 'Chat', value: Chat }
    | { type: 'Note' | 'NOTE' | 'MOODBOARD' | 'PHOTO', value: Note }
    | { type: 'Album' | 'ALBUM', value: Album }
    | { type: 'Project', value: Project }
    | { type: 'Questionnaire', value: Questionnaire }
);

type ElementHandler = (() => string | null) | null;

const buildUrlHandler = (url: string) => () => url;
const buildDownloadHandler = (url: string) => () => {
    downloadFile(url);
    return null;
};

export const goToTargetNote = (noteId: string, target: PureTarget, params?: string): ElementHandler => {
    if (target.questionnaire_id) {
        return buildUrlHandler(`/projects/${target.project_id}/questionnaires/${target.questionnaire_id}/questions/${target.question_id}/note/${noteId}${params || ''}`);
    }
    if (target.album_id) {
        return buildUrlHandler(`/projects/${target.project_id}/albums/${target.album_id}/note/${noteId}${params || ''}`);
    }
    return buildUrlHandler(`/organizations/current/galleries/all/note/${noteId}${params || ''}`);
};

export const goToTargetChat = (target: PureTarget, params?: string): ElementHandler => {
    if (target.note_id) {
        return goToTargetNote(target.note_id, target, params);
    }
    if (target.album_id) {
        return buildUrlHandler(`/projects/${target.project_id}/albums/${target.album_id}${params || ''}`);
    }
    return null;
};

export const buildTargetHandler = (notification: Notification): ElementHandler => {
    const { target } = notification;
    let params = '';
    const object = notification.objects.length ? notification.objects[0] : null;
    if (target && object && object.type === 'ChatMessage') {
        let msgType = '';
        if (target.note_id) {
            msgType = 'comment';
        } else if (target.album_id) {
            msgType = 'msg';
        }
        params = `?${msgType}=${object.object_versionned.id}`;
    }
    if (target.transaction && target.transaction.result) {
        if (target.transaction.type === 'export_notes') {
            return buildDownloadHandler(target.transaction.result.url);
        }
    }
    if (target.chat_id) return goToTargetChat(target, params);
    if (target.note_id) return goToTargetNote(target.note_id, target, params);
    if (target.questionnaire_id) {
        return buildUrlHandler(getQuestionnaireUrl(target.questionnaire, target.project_id));
    }
    if (target.album_id) return buildUrlHandler(`/projects/${target.project_id}/albums/${target.album_id}`);
    if (target.project_id) {
        const withMissions = notification.objects.every((item) => item.type === 'Mission');
        return buildUrlHandler(`/projects/${target.project_id}${withMissions ? '/missions' : ''}`);
    }
    return null;
};

export const buildObjectHandler = (notification: Notification, object: NotificationObject): ElementHandler => {
    if (object?.type === 'Note') return goToTargetNote(object.object_versionned.id, notification.target);
    if (object?.type === 'Mission') return () => null;
    return null;
};

const getEntityLabel = (element: ElementParameter): string => {
    if (!element.value) return '';

    switch (element.type) {
        case 'Chat':
            return element.value.relative_content.name;
        case 'Transaction':
            return element.value.message || '';
        case 'Project':
            return element.value.name;
        case 'Questionnaire':
            return element.value.title;
        default:
            return element.value.label;
    }
};

type MessageParams = {
    targetLabel?: string;
    number?: number;
    objectLabel?: string;
}

interface ContextualMessageParams {
    target: Target;
    count?: number;
    number?: number;
    objectLabel?: string;
}

export const buildContextualMessageParams = (notification: Notification): MessageParams => {
    const messageParams: ContextualMessageParams = { target: notification.target };

    if (notification.objects && notification.objects.length) {
        messageParams.count = notification.objects.length;
        if (notification.objects.length > 1) {
            messageParams.number = notification.objects.length;
        } else {
            messageParams.objectLabel = getEntityLabel({
                type: notification.objects[0].type,
                value: notification.objects[0].object_versionned,
            } as ElementParameter);
        }
    }
    return messageParams;
};
