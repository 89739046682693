import React, {useCallback, useEffect, useState} from 'react';
import io from 'socket.io-client';
import {useDispatch} from 'react-redux';

import {
    postMessage,
    useMe,
    addMessageInSlice,
    deleteMessageInSlice,
    updateMessageInSlice,
    ChatRequests,
    ChatMessage,
    Chat,
    ProfilesQueryParams,
} from 'mushin-redux-store';
import MessageInput from '../MessageInput/MessageInput';
import {APP_CONFIG} from '../../config';
import {classWithModifiers} from '../../Helpers/css';
import EmptyMessageList from './EmptyMessageList';
import DiscussionMessages from './DiscussionMessages';

type Props = {
    entity_id: string;
    entity_type: string;
    userMentionsQueryParams?: ProfilesQueryParams;
    renderMessage: (id: string) => React.ReactElement;
    renderSeparator?: (prevId: string, nextId: string) => React.ReactElement;
    goToMessage?: string;
    classModifier?: string;
    contrast?: boolean;
}

const Discussion: React.FC<Props> = (
    {
        entity_id,
        entity_type,
        userMentionsQueryParams,
        renderMessage,
        renderSeparator,
        goToMessage,
        classModifier,
        contrast = false,
    }
) => {
    const me = useMe();
    const [chat, setChat] = useState<Chat>();

    const dispatch = useDispatch();

    const onSubmitMessage = useCallback((message, attachments) => {
        if (chat) {
            dispatch(postMessage({
                chat_id: chat.id,
                message,
                entity_id: chat.relative_content.id,
                entity_type: chat.relative_content.type,
                attachments,
            }));
        }
    }, [chat, dispatch]);

    useEffect(() => {
        setChat(undefined);
        ChatRequests.getChats({
            entity_id,
            entity_type,
            chat_type: 'PUBLIC',
        }).then(({ data }) => {
            setChat(data);
        });
    }, [entity_id, entity_type]);

    useEffect(() => {
        if (chat?.id) {
            const ioClient = io.connect(APP_CONFIG.SOCKET_CHAT_URL, { transports: ['websocket'] });
            const chatIoData = { chat_id: chat?.id, user_id: me && me.id };
            ioClient.on('connect', () => {
                ioClient.emit('user_connect', chatIoData);

                // ioClient.on('user_connected', (usersData) => {
                //     // console.error('MessagesList::SocketIo::user_connected =>', usersData);
                //     // ICI On peut gérer la liste des users connectés
                // });
                //
                // ioClient.on('user_disconnected', (usersData) => {
                //     console.error('MessagesList::SocketIo::user_disconnected =>', usersData);
                //     // ICI On peut gérer la liste des users connectés
                // });

                ioClient.on('message_posted', (newMessageData: ChatMessage) => {
                    if (me && me.id !== newMessageData.author_id) {
                        dispatch(addMessageInSlice(newMessageData));
                    }
                });
                ioClient.on('message_patched', (newMessageData: ChatMessage) => {
                    console.error('MessagesList::SocketIo::message_patched =>', newMessageData);
                    dispatch(updateMessageInSlice(newMessageData));
                });
                ioClient.on('message_deleted', (newMessageData: ChatMessage) => {
                    if (me && me.id !== newMessageData.author_id) {
                        console.error('MessagesList::SocketIo::message_deleted =>', newMessageData);
                        dispatch(deleteMessageInSlice(newMessageData.id));
                    }
                });
            });
            return (): void => {
                if (ioClient) {
                    ioClient.emit('user_disconnect', chatIoData);
                    ioClient.disconnect();
                }
            };
        }
        return (): void => { /**/ };
    }, [dispatch, me, chat?.id]);

    return (
        <section className="mu-discussion">
            {chat?.id ? (
                <DiscussionMessages
                    chatId={chat.id}
                    renderMessage={renderMessage}
                    renderSeparator={renderSeparator}
                    goToMessage={goToMessage}
                    contrast={contrast}
                />
            ) : (
                <EmptyMessageList loading />
            )}
            {chat && (
                <div className={classWithModifiers('mu-discussion__message-input', [classModifier, {contrast}])}>
                    <MessageInput
                        chat={chat}
                        onSubmitMessage={onSubmitMessage}
                        userMentionsQueryParams={userMentionsQueryParams}
                    />
                </div>
            )}
        </section>
    );
};

export default Discussion;
