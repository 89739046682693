import React, { useState } from 'react';
import {Question, useQuestionnaire} from 'mushin-redux-store';
import i18n from 'i18next';
import { useParams } from 'react-router-dom';
import MuButton from '../../../MuButton';
import InputText from '../../../InputText/InputText';
import EntitySelectItem from '../../../EntitySelect/Components/EntitySelectItem';
import { deltaToText } from '../../../../Helpers/delta';
import {t_prop} from '../../../../Helpers/translate';
import TextTranslation from '../../../Translation/TextTranslation';

type Props = {
    questionAnswers?: string[];
    setQuestionAnswers: (questionAnswers: string[]) => void;
};

const QuestionAnswersDropdownPanel: React.FC<Props> = ({
    questionAnswers = [],
    setQuestionAnswers,
}) => {
    const { questionnaireId } = useParams<any>();
    const questionnaire = useQuestionnaire(questionnaireId);

    const [query, setQuery] = useState('');
    const [selectedQuestionAnswers, setSelectedQuestionAnswers] = useState<string[]>(questionAnswers);
    
    if (!questionnaire) return null;

    const getQuestionAnswerOptions = (
        question: Question
    ) => {
        let result: string[] = [];
        if (question.type === 'checkboxes' || question.type === 'radios') {
            result = question.items.map((it) => it.slug);
        } else if (question.type === 'rating') {
            result = Array(question.maxRatingValue).fill(0).map((_, index) => `${index + 1}`);
        }
        return result;
    };

    const options = questionnaire?.questions
        ?.filter((q) => (
            q.type === 'checkboxes'
            || q.type === 'radios'
            || q.type === 'rating')
            && deltaToText(q.label).trim().toLowerCase().includes(query.trim().toLowerCase())
        )
        ?.map((q) => ({
            index: questionnaire.questions.findIndex((it) => it.id === q.id) + 1,
            label: deltaToText(t_prop(q.labels, q.label)),
            question: q,
            options: getQuestionAnswerOptions(q),
        }));

    const getQuestionAnswerLabel = (
        question: Question,
        value: string
    ) => {
        if (question.type === 'checkboxes') {
            const option = question.items.find((it) => it.slug === value);
            return option ? <TextTranslation translationProp={option.labels} fallback={option.label} /> : '';
        }
        if (question.type === 'radios') {
            const option = question.items.find((it) => it.slug === value);
            const nextQuestionIndex = questionnaire.questions.findIndex((q) => q.id === option?.nextQuestion);
            const label = option
                ? (
                    <TextTranslation
                        translationProp={option.labels}
                        fallback={option.label}
                        additionalTitle={nextQuestionIndex !== -1 ? ` (→ Q${nextQuestionIndex + 1})` : null}
                    />
                )
                : '';
            return label;
        }
        return value;
    };

    const onSelectMultiple = (data: string) => {
        const _values = [...selectedQuestionAnswers];
        const index = selectedQuestionAnswers.indexOf(data);
        if (index > -1) {
            _values.splice(index, 1);
            setSelectedQuestionAnswers(_values);
        } else {
            _values.push(data);
            setSelectedQuestionAnswers(_values);
        }
    };
    
    const onChangeQuery = (value: string) => {
        setQuery(value);
        setSelectedQuestionAnswers([]);
    };

    const handleSubmit = () => {
        setQuestionAnswers(selectedQuestionAnswers);
    };

    return (
        <>
            <div className="mu-filters-toolbar__inline-select">
                <InputText
                    className="mu-dropdown-menu__search"
                    classModifiers={['full-width']}
                    value={query}
                    onChange={(event) => onChangeQuery(event.target.value)}
                    placeholder={i18n.t<string>('global.select')}
                    contrast
                />
                <div className="mu-dropdown-menu__content--scroll">
                    {options?.map((data, idx) => {
                        return (
                            <div key={idx}>
                                <p className="mu-dropdown-menu__label-group">{`Q${data.index}. ${data.label}`}</p>
                                {data.options?.map((entityId, idy) => (
                                    <EntitySelectItem
                                        key={idy}
                                        entityId={entityId}
                                        useItem={() => data.question}
                                        onSelect={(_, question) => onSelectMultiple(
                                            JSON.stringify({
                                                id: question.id,
                                                value: entityId,
                                            })
                                        )}
                                        getEntityName={(question) => getQuestionAnswerLabel(question, entityId)}
                                        selected={(question) => !!selectedQuestionAnswers?.includes(
                                            JSON.stringify({
                                                id: question.id,
                                                value: entityId,
                                            })
                                        )}
                                    />
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>
            <MuButton
                className="mu-filters-toolbar__panel-submit"
                handleClick={handleSubmit}
                label={i18n.t('components.filters.dropdown.apply')}
            />
        </>
    );
};

export default QuestionAnswersDropdownPanel;
