import React from 'react';
import { ActionProps, ActionBarMuIconButton } from './common';

const getNextSplitValue = (previousPosition, nextPosition, previousSplit) => {
    const k = ['left', 'top'].includes(previousPosition) ? previousSplit : 1 - previousSplit;

    return ['left', 'top'].includes(nextPosition) ? k : 1 - k;
};

const createMixedLayoutButton = (position, svgName) => ({
    content,
    setContent,
}: ActionProps) => (
    <ActionBarMuIconButton
        active={content.position === position}
        onClick={() => {
            setContent({
                ...content,
                split: getNextSplitValue(content.position, position, content.split),
                position,
            });
        }}
        svgName={svgName}
    />
);

export const TextTopLayoutButton = createMixedLayoutButton('top', 'TextTop');
export const TextLeftLayoutButton = createMixedLayoutButton('left', 'TextLeft');
export const TextBottomLayoutButton = createMixedLayoutButton('bottom', 'TextBottom');
export const TextRightLayoutButton = createMixedLayoutButton('right', 'TextRight');
