/**
 *
 * this expose a set of convenient geometric functions
 *
 */

import { Board, Box, Cell, Point } from 'mushin-node-moodboard';

export const hitBox = (box: Box, p: Point): boolean => box.min.x <= p.x && p.x <= box.max.x && box.min.y <= p.y && p.y <= box.max.y;

export const hitBoxes = (boxes: Array<Cell>, p: Point, selectedCell: Cell): Cell | null => (selectedCell && hitBox(selectedCell, p) && selectedCell) || boxes.find((box) => hitBox(box, p)) || null;

export const hitExpandedBox = (box: Box, margin: number, p: Point): boolean => box.min.x - margin <= p.x && p.x <= box.max.x + margin && box.min.y - margin <= p.y && p.y <= box.max.y + margin;

export const hitExpandedBoxes = (boxes: Array<Cell>, margin: number, p: Point, selectedCell: Cell): Cell | null => (selectedCell && hitExpandedBox(selectedCell, margin, p) && selectedCell) || boxes.find((box) => hitExpandedBox(box, margin, p)) || null;

export const hitMargin = (box: Box, margin: number, p: Point): Point | null => {
    const res = { x: 0, y: 0 };
    if (p.x < box.min.x + margin) res.x = -1;

    if (p.x > box.max.x - margin) res.x = 1;

    if (p.y < box.min.y + margin) res.y = -1;

    if (p.y > box.max.y - margin) res.y = 1;

    return res.x === 0 && res.y === 0 ? null : res;
};

const intervalIntersection = (a1: number, b1: number, a2: number, b2: number): boolean => (a1 <= b2 && a2 <= b1) || (a1 >= b2 && a2 >= b1);

export const boxIntersection = (a: Box, b: Box): boolean => intervalIntersection(a.min.x, a.max.x, b.min.x, b.max.x) && intervalIntersection(a.min.y, a.max.y, b.min.y, b.max.y);

//
export const setCell = (board: Board, cellId: string, cell: Object): Board => ({
    ...board,
    cells: {
        ...board.cells,
        [cellId]: cell,
    },
});
export const setContent = (board: Board, cellId: string, content: Object): Board => ({
    ...board,
    contents: {
        ...board.contents,
        [cellId]: (content && { ...board.contents[cellId], ...content }) || null,
    },
});
