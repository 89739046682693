import React, {useEffect} from 'react';
import { useGroupsQuery } from 'mushin-redux-store';
import { useParams } from 'react-router-dom';
import i18n from 'i18next';
import CompanySettingsLayout from '../../../Components/SettingsComponents/CompanySettingsLayout';
import GroupRow from './Components/GroupRow';
import EmptyZone from '../../../Components/EmptyZone/EmptyZone';
import {useScrollThreshold} from '../../../Helpers/effects';
import {useSearchParams} from '../../../Helpers/queryParser';
import GroupsToolbar from './Components/GroupsToolbar';
import Spinner from '../../../Components/Spinner';

const CompanyGroups: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams<{name_search?: string}>();
    const { organizationId } = useParams<{[key: string]: string}>();
    const groupsQuery = useGroupsQuery({organization_id: organizationId, ...searchParams});
    const scrollRef = useScrollThreshold<HTMLDivElement>(
        groupsQuery.getMoreResults, {deps: [groupsQuery.results?.length]},
    );

    const {setParams} = groupsQuery;
    useEffect(() => {
        setParams({organization_id: organizationId, ...searchParams});
    }, [organizationId, searchParams, setParams]);

    return (
        <CompanySettingsLayout
            contentHeader={(
                <GroupsToolbar searchParams={searchParams} setSearchParams={setSearchParams} />
            )}
            scrollRef={scrollRef}
        >
            {groupsQuery.results && (
                groupsQuery.results.length ? (
                    groupsQuery.results.map((groupId) => {
                        return (
                            <GroupRow
                                groupId={groupId}
                                key={groupId}
                            />
                        );
                    })
                ) : !groupsQuery.loading && (
                    <EmptyZone
                        urlImg="/assets/images/add-files.svg"
                        title={i18n.t('administration.groups.empty.title')}
                        subTitle={i18n.t('administration.groups.empty.subtitle')}
                    />
                )
            )}
            {groupsQuery.loading && (
                <Spinner className="groups-container__loading" size="40px" />
            )}
        </CompanySettingsLayout>
    );
};

export default CompanyGroups;
