import React from 'react';
import {Link} from 'react-router-dom';
import {useMission} from 'mushin-redux-store';
import i18n from 'i18next';
import MuIcon from '../../Components/MuIcon';
import {classWithModifiers} from '../../Helpers/css';
import {targetTitle, targetUrl} from '../../Helpers/targets';

type Props = {
    id: string;
}

const MissionItem: React.FC<Props> = ({ id }) => {
    const mission = useMission(id);

    if (!mission) return null;

    return (
        <Link className="mu-mission-item" to={targetUrl(mission.target)}>
            <h4 className="mu-mission-item__title">{i18n.t(targetTitle(mission.target))}</h4>
            <p className="mu-mission-item__subtitle">{i18n.t(`missions.type.${mission.type}.description`)}</p>
            <div className={classWithModifiers('mu-mission-item__state', {completed: !!mission.completed_at})}>
                <MuIcon svgName={mission.completed_at ? 'Check' : 'Play'} />
            </div>
        </Link>
    );
};

export default MissionItem;
