import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {classWithModifiers} from '../../Helpers/css';
import Portal from '../Portal/Portal';
import {
    isCursorOutsideRect,
    Rect,
    Position,
    useComputedPosition
} from '../../Helpers/position';

type Props = React.PropsWithChildren<{
    className?: string;
    position?: Position,
    hoveredElement: ReactElement | React.ReactNode,
    inline?: boolean,
    withDelay?: boolean,
    isOpen?: boolean,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}>

const Popover: React.FC<Props> = ({
    className,
    position = 'right',
    hoveredElement,
    children,
    inline = false,
    withDelay = false,
    isOpen,
    setOpen,
}) => {
    const [internalIsOpen, internalSetOpen] = useState(false);
    const _isOpen = isOpen !== undefined ? isOpen : internalIsOpen;
    const _setOpen = setOpen || internalSetOpen;

    const [panel, setPanel] = useState<HTMLElement | null>(null);
    const hoveredRef = useRef<HTMLDivElement>(null);
    const computedPosition = useComputedPosition(position, hoveredRef.current, panel, _isOpen);
    let openTimeout: ReturnType<typeof setTimeout>;

    useEffect(() => {
        const handleMouseLeave = (event: MouseEvent) => {
            const hoveredRect = hoveredRef.current?.getBoundingClientRect();
            const popoverRect = panel?.getBoundingClientRect();
            if (hoveredRect && popoverRect) {
                const betweenRect: Rect = { width: 0, height: 0, x: 0, y: 0 };

                // Computes the rectangle between hovered element and popover to be able to keep the popover open in that space
                if (computedPosition) {
                    if (computedPosition.position === 'left' || computedPosition.position === 'right') {
                        betweenRect.y = Math.max(hoveredRect.y, popoverRect.y);
                        betweenRect.height = Math.min(hoveredRect.height, popoverRect.height);

                        if (computedPosition.position === 'left') {
                            betweenRect.x = popoverRect.x + popoverRect.width;
                            betweenRect.width = hoveredRect.x - betweenRect.x;
                        } else {
                            betweenRect.x = hoveredRect.x + hoveredRect.width;
                            betweenRect.width = popoverRect.x - betweenRect.x;
                        }
                    } else { // computedPosition.position === 'top' || computedPosition.position === 'bottom'
                        betweenRect.x = Math.max(hoveredRect.x, popoverRect.x);
                        betweenRect.width = Math.min(hoveredRect.width, popoverRect.width);

                        if (computedPosition.position === 'top') {
                            betweenRect.y = popoverRect.y + popoverRect.height;
                            betweenRect.height = hoveredRect.y - betweenRect.y;
                        } else {
                            betweenRect.y = hoveredRect.y + hoveredRect.height;
                            betweenRect.height = popoverRect.y - betweenRect.y;
                        }
                    }
                }

                if ( // If cursor is neither on hovered element, on popover nor in between, we close the popover
                    isCursorOutsideRect(event, hoveredRect)
                    && isCursorOutsideRect(event, popoverRect)
                    && isCursorOutsideRect(event, betweenRect)
                ) {
                    _setOpen(false);
                }
            }
        };

        if (_isOpen) {
            document.addEventListener('mousemove', handleMouseLeave);
            document.addEventListener('wheel', handleMouseLeave);
        }
        return () => {
            document.removeEventListener('mousemove', handleMouseLeave);
            document.removeEventListener('wheel', handleMouseLeave);
        };
    }, [_setOpen, computedPosition, _isOpen, panel]);

    return (
        <div
            ref={hoveredRef}
            onMouseEnter={() => {
                openTimeout = setTimeout(() => {
                    _setOpen(true);
                }, withDelay ? 800 : 0);
            }}
            onMouseLeave={() => {
                clearTimeout(openTimeout);
            }}
            className={classWithModifiers('mu-popover', {inline}, className)}
        >
            {hoveredElement}
            {(_isOpen) && (
                <Portal>
                    <div
                        onMouseDown={(e) => {
                            e.stopPropagation();
                        }}
                        ref={(element) => setPanel(element)}
                        className="mu-popover__panel"
                        style={{
                            position: 'absolute',
                            visibility: computedPosition ? 'visible' : 'hidden',
                            overflow: computedPosition ? 'auto' : undefined,
                            ...computedPosition?.style,
                        }}
                    >
                        {children}
                    </div>
                </Portal>
            )}
        </div>
    );
};

export default Popover;
