import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import MuButton from '../../../../Components/MuButton';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import { ModalFooter } from '../../../../Components/Modal/ModalFooter';
import InfoPanel from '../../../../Components/InfoPanel/InfoPanel';
import {ModalBody} from '../../../../Components/Modal/ModalBody';
import MuIcon from '../../../../Components/MuIcon';

type Props = {
    onClose: () => void;
    onPublish: (dates: { start_date: Date, end_date: Date | null | undefined }) => Promise<void>;
    labelKey: string;
};

const PublishModal: React.FC<Props> = (
    {
        onClose,
        onPublish,
        labelKey,
    }
) => {
    const [startDate, setStartDate] = useState<Date | null | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | null | undefined>(undefined);

    const handleStartDate = (date: Date) => {
        setStartDate(date);
        if (endDate && date.getTime() > endDate?.getTime()) {
            setEndDate(null);
        }
    };

    return (
        <Modal handleRemoveModal={onClose}>
            <ModalHeader
                title={i18n.t('global.publish')}
                icon="Stopwatch"
            />
            <ModalBody noScroll>
                <InfoPanel iconName="Help" text={i18n.t<string>(`${labelKey}.publishInfo`)} />
                <div className="mu-form-row">
                    <div className="mu-form-row__group mu-form-row__group--cols2">
                        <h3 className="mu-project-dashboard__footer-item-title">
                            {i18n.t('components.filters.dropdown.beginDateLabel')}
                            <MuIcon className="mu-project-dashboard__footer-item-icon" svgName="Calendar" />
                        </h3>
                        <DatePicker
                            className="mu-input-raw mu-input-raw--contrast mu-input-raw--date"
                            selected={startDate}
                            onChange={handleStartDate}
                            minDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dateFormat="PP"
                            placeholderText={i18n.t<string>('datetime.today')}
                        />
                    </div>
                    <div className="mu-form-row__group mu-form-row__group--cols2">
                        <h3 className="mu-project-dashboard__footer-item-title">
                            {i18n.t('components.filters.dropdown.endDateLabel')}
                            <MuIcon className="mu-project-dashboard__footer-item-icon" svgName="Calendar" />
                        </h3>
                        <DatePicker
                            className="mu-input-raw mu-input-raw--contrast mu-input-raw--date"
                            selected={endDate}
                            onChange={(date: Date) => setEndDate(date)}
                            minDate={startDate || new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dateFormat="PP"
                            placeholderText={i18n.t<string>('albums.undefinedPeriod')}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <MuButton
                    handleClick={async () => {
                        await onPublish({
                            start_date: startDate || new Date(),
                            end_date: endDate,
                        });
                        onClose();
                    }}
                    label={i18n.t('global.publish')}
                />
            </ModalFooter>
        </Modal>

    );
};

export default PublishModal;
