import React from 'react';
import {useAllHashtags} from 'mushin-redux-store';
import {classWithModifiers} from '../../Helpers/css';
import AddTag from './AddTag';
import {Aggregation} from '../../Helpers/noteHelpers';
import Tag from './Tag';
import MuIcon from '../MuIcon';
import GenericList from '../UserCriteria/GenericList';

type Props = {
    type?: 'hashtag' | 'group'
    tags: (string | Aggregation)[];
    onTagClick?: (tag: string, active: boolean) => void;
    handleRemoveTag?: (tag: string) => void;
    handleAddTag?: (tag: string) => void;
    placeholder?: string;
    selectedTags?: string[];
    className?: string;
    contrast?: boolean;
    inline?: boolean;
    max?: number;
};

export const DOM_DATA_ATT_add_tag = 'add-tag';

const Tags: React.FC<Props> = (
    {
        type = 'hashtag',
        tags,
        onTagClick,
        handleRemoveTag,
        handleAddTag,
        placeholder,
        selectedTags = [],
        className,
        contrast = false,
        inline = false,
        max,
    }
) => {
    const hashtags = useAllHashtags();

    return (
        <GenericList
            className={classWithModifiers('mu-tags', {inline}, className)}
            items={tags}
            max={max || tags.length}
            toggle={(showAll, setShowAll) => (
                <button
                    type="button"
                    className="mu-tags__show-all"
                    onClick={() => setShowAll((prev) => !prev)}
                >
                    <MuIcon svgName={showAll ? 'Minus' : 'Plus'} />
                </button>
            )}
            renderItem={(tag) => {
                const key = typeof tag === 'string' ? tag : tag.key;
                const label = typeof tag === 'string' ? tag : `${tag.key} (${tag.doc_count})`;
                const isSelected = selectedTags.includes(key);
                return (
                    <li key={key} className="mu-tags__tag">
                        <Tag
                            type={type}
                            isSelected={isSelected}
                            label={label}
                            onClick={() => onTagClick && onTagClick(key, isSelected)}
                            onRemove={handleRemoveTag && (() => handleRemoveTag(key))}
                            contrast={contrast}
                        />
                    </li>
                );
            }}
        >
            {handleAddTag && (
                <AddTag placeholder={placeholder} handleAddTag={handleAddTag} hashtags={hashtags || []} />
            )}
        </GenericList>
    );
};

export default Tags;
