export const downloadFile = (url: string, filename: string): void => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

const ImageToPrint = (source: string): string => {
    return '<html lang="en"><head><title>Download image</title><script>'
        + 'function step1(){setTimeout(\'step2()\', 10);}'
        + 'function step2(){window.print();window.close()}'
        + '</script></head><body onload=\'step1()\'>'
        + `<img src='${source}' alt="Image to download" /></body></html>`;
};
export const printImage = (source: string): void => {
    const pageLink = 'about:blank';
    const pwa = window.open(pageLink, '_new');
    if (pwa) {
        pwa.document.open();
        pwa.document.write(ImageToPrint(source));
        pwa.document.close();
    }
};
