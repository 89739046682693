import React from 'react';

import {classWithModifiers} from '../../Helpers/css';

type Props = React.PropsWithChildren<{
  nb: number | boolean | undefined;
  classModifier?: string;
}>;

const CountNotifications: React.FC<Props> = ({
    nb,
    children,
    classModifier,
}) => (
    <div className={classWithModifiers('mu-count-notifs', classModifier)}>
        {children}
        {!!nb && (
            <output className={classWithModifiers('mu-count-notifs__output', {light: nb === true})}>
                {nb > 99 ? 99 : nb}
            </output>
        )}
    </div>
);

export default CountNotifications;
