import React from 'react';
import i18n from 'i18next';
import {useLocation} from 'react-router-dom';
import {
    Project,
    useCurrentOrganizationId,
    useTimelineReadCount,
    useTimelineUnseen
} from 'mushin-redux-store';
import NavigationItem from './NavigationItem';
import CountNotifications from '../../../CountNotifications/CountNotifications';
import MuIcon from '../../../MuIcon';
import {isContributor, isProjectContributor} from '../../../../Helpers/users';

type Props = {
    project: Project | null | undefined
}

const NavigationItems: React.FC<Props> = ({ project }) => {
    const [currentOrganizationId] = useCurrentOrganizationId();
    const unseenCountInCurrentProject = useTimelineUnseen({}, (unseenData) => {
        const currentUnseen = unseenData.find((unseen) => unseen._id.project_id === project?.id);
        if (!currentUnseen) return null;
        return currentUnseen.count;
    });
    const missionsUnreadCount = useTimelineReadCount({ is_contributor: true }, {projectId: project?.id, questionnaireId: 'all'});
    const synthesisUnreadCount = useTimelineReadCount({ is_contributor: true }, {projectId: project?.id, albumId: 'all'});
    const location = useLocation();

    const currentProjectId = project?.id;
    const currentOrganizationUrl = `/organizations/${currentOrganizationId}`;
    const currentProjectUrl = `/projects/${currentProjectId}`;

    const coCreationProject = project?.type === 'contributors';

    const missionsActive = location.pathname.includes('/missions') || location.pathname.includes('/questionnaires');
    const activitiesActive = location.pathname.includes('/activities');
    const inspirationsActive = location.pathname.includes('/galleries');
    const albumsActive = coCreationProject && location.pathname.includes('/synthesis');
    const projectsActive = (
        !missionsActive
        && !activitiesActive
        && !inspirationsActive
        && !albumsActive
        && location.pathname.includes('/project')
    );

    return (
        <ul>
            {currentProjectId && (
                <NavigationItem
                    href={currentProjectUrl}
                    title={i18n.t('global.dashboard')}
                    icon="Dashboard"
                    isActive={projectsActive}
                />
            )}
            {currentProjectId && (
                <NavigationItem
                    href={`${currentProjectUrl}/activities`}
                    title={i18n.t('global.activities')}
                    icon={(
                        <CountNotifications nb={unseenCountInCurrentProject}>
                            <MuIcon className="mu-main-menu-nav__icon" svgName="Bell" />
                        </CountNotifications>
                    )}
                    isActive={activitiesActive}
                />
            )}
            {currentProjectId && coCreationProject && (
                <NavigationItem
                    href={`${currentProjectUrl}/missions`}
                    title={i18n.t('global.missions')}
                    icon="Survey"
                    isActive={missionsActive}
                    countNotifications={!!missionsUnreadCount}
                />
            )}
            {project && coCreationProject && !isProjectContributor(project) && (
                <NavigationItem
                    href={`${currentProjectUrl}/synthesis`}
                    title={i18n.t('global.synthesis')}
                    icon="Brainstorming"
                    isActive={albumsActive}
                    countNotifications={!!synthesisUnreadCount}
                />
            )}
            {project && !isContributor(project) && (
                <NavigationItem
                    href={`${currentProjectId ? currentProjectUrl : currentOrganizationUrl}/galleries/all`}
                    title={i18n.t('global.search')}
                    icon="Magnifier"
                    isActive={inspirationsActive}
                />
            )}
        </ul>
    );
};

export default NavigationItems;
