import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, useCurrentProject, useMe, useTimelineSocket } from 'mushin-redux-store';
import i18n from 'i18next';
import UserMenu from './Components/UserMenu/UserMenu';
import ChatNotifications from './Components/Notifications/ChatNotifications';
import MuIcon from '../MuIcon';
import { isProjectContributor } from '../../Helpers/users';
import NavigationItems from './Components/Navigation/NavigationItems';

const MainMenu: React.FC = () => {
    const me = useMe();
    const currentProject = useCurrentProject();

    useTimelineSocket(me?.id || '');

    const dispatch = useDispatch();

    if (!me) return null;

    return (
        <section className="mu-main-menu">
            <Link className="mu-main-menu__logo" to="/">
                <img className="mu-main-menu__logo-img" src="/assets/images/logo-full.png" alt="Mushin logo" />
                <img className="mu-main-menu__logo-img-small" src="/assets/images/logo.png" alt="Mushin logo" />
            </Link>
            <div className="mu-main-menu__header">
                <UserMenu loggedUser={me} logout={() => dispatch(logout())} />
            </div>
            <nav className="mu-main-menu-nav">
                <NavigationItems project={currentProject} />
            </nav>
            <button id="intercom_custom_launcher" className="mu-btn mu-btn--outline mu-main-menu__help" type="button">
                <MuIcon className="mu-main-menu__help-icon" svgName="Help" />
                <div className="mu-main-menu__help-text">{i18n.t('components.navbar.dropdown.help')}</div>
            </button>
        </section>
    );
};

export default MainMenu;
