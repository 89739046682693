import React, {useEffect, useRef, useState} from 'react';
import {classWithModifiers} from '../../../Helpers/css';

const CODE_LENGTH = 6;
const CODE_ARRAY = Array.from(Array(CODE_LENGTH));

type Props = {
    info: string;
    onCodeFulfilled: (code: string) => Promise<void>;
}

const CodeInput: React.FC<Props> = ({ info, onCodeFulfilled }) => {
    const [code, setCode] = useState('');
    const [containerIsFocused, setContainerIsFocused] = useState(false);

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setContainerIsFocused(true);
        ref?.current?.focus();
    }, []);

    const handleOnPress = () => {
        setContainerIsFocused(true);
        if (ref.current) {
            ref.current.focus();
            ref.current.value = `${ref.current.value}`;
        }
    };

    const toDigitInput = (_value: number, idx: number) => {
        const emptyInputChar = ' ';
        const digit = code[idx] || emptyInputChar;

        const isCurrentDigit = idx === code.length;
        const isLastDigit = idx === CODE_LENGTH - 1;
        const isCodeFull = code.length === CODE_LENGTH;

        const isFocused = isCurrentDigit || (isLastDigit && isCodeFull);

        return (
            <div
                key={idx}
                className={
                    classWithModifiers(
                        'mu-code-input__digit',
                        { focused: containerIsFocused && isFocused },
                    )
                }
            >
                {digit}
            </div>
        );
    };

    return (
        <div className="mu-code-input">
            <div className="mu-code-input__info">
                {info}
            </div>
            <div className="mu-code-input__digit-container" onClick={handleOnPress}>
                {CODE_ARRAY.map(toDigitInput)}
            </div>
            <input
                className="mu-code-input__hidden-input"
                type="number" // Weird bug : if this type is not present, on Chrome mobile the re-renders cause input to be filled backwards
                ref={ref}
                value={code}
                onChange={async (event) => {
                    const newCode = event.currentTarget.value;
                    setCode(newCode);
                    if (newCode.length === CODE_LENGTH) {
                        await onCodeFulfilled(newCode);
                        setCode('');
                    }
                }}
                maxLength={CODE_LENGTH}
            />
        </div>
    );
};

export default CodeInput;
