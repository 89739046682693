/* eslint-disable no-param-reassign */
import * as ExcelJS from 'exceljs';
import {Criterion, getCriterion, getProfile, Profile} from 'mushin-redux-store';
import i18n from 'i18next';
import Delta from 'quill-delta';
import { downloadFile } from '../download';
import {getNameToDisplay} from '../users';
import {AsyncAppThunk} from '../../Redux/reducers';
import { t_prop } from '../translate';

export type ExcelValue = number | string | Date | ExcelJS.CellRichTextValue | null;

export const formatAsBool = (value: unknown): number => (value ? 1 : 0);

export const getCriteria = (
    profileIds: string[],
    eachUserCallback?: () => void,
): AsyncAppThunk<Criterion[]> => async (dispatch): Promise<Criterion[]> => {
    const criterionIds = new Set<string>();
    for (const profileId of profileIds) {
        // eslint-disable-next-line no-await-in-loop
        const profile = await dispatch(getProfile(profileId));
        profile?.criteria?.forEach((item) => criterionIds.add(item.id));
        if (eachUserCallback) eachUserCallback();
    }
    const criteria: Promise<Criterion | null>[] = [];
    criterionIds.forEach((id) => {
        criteria.push(dispatch(getCriterion(id)));
    });
    return (
        (await Promise.all(criteria)).filter(Boolean) as Criterion[]
    ).sort((c1, c2) => c1.members.length - c2.members.length);
};

export const usersHeaders = (criteria: Criterion[], withAdditionalStatus = false): string[] => {
    const headers = [
        i18n.t('exportStatistics.headers.username'),
        i18n.t('exportStatistics.headers.email'),
        i18n.t('global.status.status'),
        i18n.t('Date'),
    ] as string[];
    if (withAdditionalStatus) headers.push(i18n.t('global.status.status'));
    for (const criterion of criteria) {
        if (criterion.multiple) {
            for (const value of criterion.values) {
                headers.push(`${t_prop(criterion.titles, criterion.name)} : ${value.label}`);
            }
        } else {
            headers.push(`${t_prop(criterion.titles, criterion.name)}`);
        }
    }
    return headers;
};

export const formatUserFields = (
    profileId: string,
    criteria: Criterion[],
    getStatus: (user: Profile) => {mod: string; label: string; date?: string},
    getAdditionalStatus?: (user: Profile) => {mod: string; label: string; date?: string},
): AsyncAppThunk<ExcelValue[]> => async (dispatch): Promise<ExcelValue[]> => {
    const profile = await dispatch(getProfile(profileId));
    const status = profile ? getStatus(profile) : undefined;
    const additionalStatus = getAdditionalStatus && profile ? getAdditionalStatus(profile) : undefined;
    const fields: ExcelValue[] = profile ? [
        getNameToDisplay(profile),
        profile.user?.email || { richText: [{ text: profile.user?.anonymousEmail || '', font: {italic: true} }] },
        status ? i18n.t(status.label) : 'N/A',
        status?.date ? new Date(status.date) : null,
    ] : ['N/A', 'N/A', 'N/A', 'N/A'];
    if (getAdditionalStatus) fields.push(additionalStatus ? i18n.t(additionalStatus?.label) : 'N/A');
    for (const criterion of criteria) {
        const userValue = profile?.criteria?.find((item) => item.id === criterion.id);
        if (criterion.multiple) {
            for (const value of criterion.values) {
                fields.push(formatAsBool(userValue?.value.includes(value.slug)));
            }
        } else {
            fields.push(`${userValue?.value || ''}`);
        }
    }
    return fields;
};

export const formatQuillDeltaToCellRichText = (content: Delta): ExcelJS.CellRichTextValue => {
    try {
        const result: ExcelJS.CellRichTextValue = { richText: [] };
        content?.ops?.forEach((op) => {
            if (typeof op.insert === 'string') {
                if (op.insert.includes('\n') && !op.attributes?.header && !op.attributes?.list) {
                    op.insert.split('\n').forEach((it, index, array) => {
                        if ((index !== array.length - 1) || it) {
                            result.richText.push({
                                text: index === array.length - 1 ? it : `${it}\n`,
                                font: op.attributes || {},
                            });
                        }
                    });
                    return;
                }
                const lastIndex = result.richText.length - 1;
                if (op.attributes?.header && op.insert === '\n') {
                    let fontSize = 12;
                    switch (op.attributes.header) {
                        case 2:
                            fontSize = 16; break;
                        case 3:
                            fontSize = 14; break;
                        default: break;
                    }
                    result.richText[lastIndex] = {
                        text: `${result.richText[lastIndex].text}\n`,
                        font: {
                            ...result.richText[lastIndex].font,
                            size: fontSize,
                        },
                    };
                    return;
                }
                if (op.attributes?.list && op.insert === '\n') {
                    return;
                }

                result.richText.push({ text: op.insert, font: op.attributes || {} });
            }
        });
        
        // remove the last /n character - which is automatically added by quill
        const lastIndex = result.richText.length - 1;
        if (result.richText[lastIndex].text.endsWith('\n')) {
            result.richText[lastIndex] = { 
                ...result.richText[lastIndex],
                text: result.richText[lastIndex].text.slice(0, -1),
            };
        }

        return result;
    } catch (error) {
        console.error(error);
        return { richText: [] };
    }
};

export class ExcelBuilder {
    private readonly title: string;

    private wb: ExcelJS.Workbook;

    ws: ExcelJS.Worksheet | null = null;

    constructor(title: string, firstWorksheetTitle: string | null = null) {
        this.title = title;
        this.wb = new ExcelJS.Workbook();
        if (firstWorksheetTitle) {
            this.createWorksheet(firstWorksheetTitle);
        }
    }

    createWorksheet(title: string): void {
        this.ws = this.wb.addWorksheet(title);
    }

    setHeaders(headers: string[]): void {
        if (!this.ws) return;

        this.ws.columns = headers.map((headerItem) => ({
            header: headerItem,
            width: 20,
        }));

        this.ws.getRow(1).eachCell((cell) => {
            // eslint-disable-next-line no-param-reassign
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'EEEEEE'},
            };
            // eslint-disable-next-line no-param-reassign
            cell.border = {
                top: {style: 'double', color: {argb: '879399'}},
                left: {style: 'double', color: {argb: '879399'}},
                bottom: {style: 'double', color: {argb: '879399'}},
                right: {style: 'double', color: {argb: '879399'}},
            };
        });
        this.ws.getRow(1).alignment = { horizontal: 'center', wrapText: true };
    }

    addLine(cells: ExcelValue[]): void {
        if (!this.ws) return;
        const row = this.ws.addRow(cells);
        this.ws.getRow(row.number).alignment = { horizontal: 'center', wrapText: true };
    }

    async export(): Promise<void> {
        /* generate xlsx file and send to client */
        const buffer = await this.wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });

        const now = new Date();
        const dateExport = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}`;
        downloadFile(
            URL.createObjectURL(blob),
            `export_${this.title}_${dateExport}.xlsx`,
        );
    }
}
