import React, {useState} from 'react';
import i18n from 'i18next';
import MuIcon from '../MuIcon';

export type FilterConfig = {
    icon: string;
    label: React.ReactNode;
    value: React.ReactNode;
    dropdownPanel: React.ReactNode;
    active: boolean;
    reset?: () => void;
}

type Props = {
    filter: FilterConfig;
    requestClose: () => void;
}

const FilterPanelContent: React.FC<Props> = ({filter, requestClose}) => {
    const [currentValue, setValue] = useState(filter.value);

    if (currentValue !== filter.value) {
        setValue(filter.value);
        requestClose();
    }

    return (
        <>
            <div className="mu-filters-toolbar__panel-header">
                <button
                    className="mu-filters-toolbar__panel-header-button"
                    type="button"
                    onClick={requestClose}
                >
                    <MuIcon svgName="ArrowLeft" />
                </button>
                <div className="mu-filters-toolbar__panel-header-title">{filter.label}</div>
                <button
                    className="mu-link mu-filters-toolbar__panel-header-link"
                    type="button"
                    onClick={() => filter.reset?.()}
                >
                    {i18n.t('global.reset')}
                </button>
            </div>
            {filter.dropdownPanel}
        </>
    );
};

export default FilterPanelContent;
