import React from 'react';
import MuIcon from '../../Components/MuIcon';
import MuButton from '../../Components/MuButton';

type Props = {
    activeMode?: 'Cluster' | 'Insights' | 'Opportunities' | 'Solutions';
    text?: string;
    span?: string;
    onClick?: () => void;
    textButton?: string;
};

const HeaderSynthesis : React.FC<Props> = ({ activeMode, text, span, onClick, textButton }: Props) => {

    return (
        <div className="mu-synthesis-header ">
            <section className="mu-synthesis-header__section">
                <div className="mu-synthesis-header__header">
                    <MuIcon className="mu-synthesis-header__header--icon" svgName={activeMode} />
                    <p className="mu-synthesis-header__header--text">
                        {text}
                        <span className={
                            `mu-synthesis-header__header--span mu-synthesis-header__header--span--${activeMode}`
                        }
                        >
                            {span}
                        </span>
                    </p>
                </div>
            </section>
            <MuButton type="button" className="mu-synthesis-header__header--button" icon="IconIa" label={textButton} handleClick={onClick} />
        </div>
    );
};
export default HeaderSynthesis;
