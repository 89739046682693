import React from 'react';
import {useNote} from 'mushin-redux-store';
import MuIcon from '../../MuIcon';
import NoteThumbnail from '../../NoteThumbnail/NoteThumbnail';
import {useAppDispatch} from '../../../Helpers/hooks';
import {
    deselectNoteInBucket,
} from '../../../Redux/reducers/noteBucketSlice';

type Props = {
    id: string;
    onDragStart?: React.DragEventHandler;
    onDragOver?: React.DragEventHandler;
}

const DraggableNoteListItem: React.FC<Props> = ({ id, onDragStart, onDragOver }) => {
    const note = useNote(id);

    const dispatch = useAppDispatch();

    if (!note) return null;

    return (
        <article
            className="mu-note-bucket__wrapper"
            draggable
            onDragStart={onDragStart}
            onDragOver={onDragOver}
        >
            <NoteThumbnail note={note} small />
            <button
                type="button"
                className="mu-note-bucket__notes-item-btn"
                onClick={() => dispatch(deselectNoteInBucket(id))}
            >
                <MuIcon className="mu-note-bucket__notes-item-icon" svgName="CloseCircleFill" />
            </button>
        </article>
    );
};

export default DraggableNoteListItem;
