import mushinReduxStore from 'mushin-redux-store';
import {APP_CONFIG} from '../../config';
import {customSlices} from '../reducers';

const getItem = async (key: string) => {
    return window.localStorage.getItem(key);
};

const setItem = async (key: string, value: string) => {
    window.localStorage.setItem(key, value);
};

const removeItem = async (key: string) => {
    window.localStorage.removeItem(key);
};

mushinReduxStore.init({
    app: {
        apiUrl: APP_CONFIG.API_URL_V3,
        sockets: {
            url: APP_CONFIG.SOCKET_NOTIFICATIONS_URL,
        },
        azure: {
            uri: 'https://mymushinpics.blob.core.windows.net',
            // eslint-disable-next-line max-len
            token: '?sv=2017-11-09&ss=b&srt=sco&sp=rwlac&se=2050-08-07T18:26:15Z&st=2018-08-07T10:26:15Z&spr=https,http&sig=nD%2BLqSiZh6MHXwQcqFoEgU4fU1frSVCofBJ%2FWahIpK8%3D',
            container: BUILD_NODE_ENV !== 'production' ? 'dev-pics' : 'pics',
        },
    },
    storage: {
        getItem,
        setItem,
        removeItem,
    },
});

export const store = mushinReduxStore.createStore(customSlices);
