import ReactPlayer from 'react-player';
import i18n from 'i18next';

export type Validator<T> = (value: T) => { valid: boolean, message: string | null }

export const multipleValidate = (validateFunctions: (() => boolean)[]): boolean => {
    let valid = true;
    for (const validate of validateFunctions) {
        if (!validate()) {
            valid = false;
        }
    }
    return valid;
};

export const compositeValidator = <T>(validators: (Validator<T> | false | null)[]): Validator<T> => (value) => {
    let firstMessage = null;
    for (const validator of validators) {
        if (validator) {
            const {valid, message} = validator(value);
            if (!valid) {
                return { valid, message };
            }
            if (message && !firstMessage) {
                firstMessage = message;
            }
        }
    }
    return { valid: true, message: firstMessage };
};

export const requiredValidator: Validator<unknown> = (value) => {
    if (!value) {
        return { valid: false, message: i18n.t<string>('global.errors.required')};
    }
    return { valid: true, message: null };
};

export const minLengthValidator = (minLength: number): Validator<string> => (value) => {
    if (value.replaceAll(' ', '').length < minLength) {
        return { valid: false, message: i18n.t<string>('global.errors.minLength', {count: minLength})};
    }
    return { valid: true, message: null };
};

export const videoUrlValidator: Validator<string> = (value) => {
    if (value && !ReactPlayer.canPlay(value)) {
        return { valid: false, message: i18n.t<string>('global.errors.videoUrl')};
    }
    return { valid: true, message: null };
};

const regexValidator = (regex: RegExp, messageKey: string): Validator<string> => (value) => {
    if (!regex.test(value)) {
        return { valid: false, message: i18n.t(messageKey) };
    }
    return { valid: true, message: null };
};

export const emailValidator = regexValidator(
    // eslint-disable-next-line max-len
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'global.errors.wrongEmailFormat'
);

export const passwordValidator = regexValidator(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{10,}$/, 'global.errors.wrong_password_format'
);

export const equalsFieldValidator = <T>(compareValue: T, message: string): Validator<T> => (value) => {
    if (compareValue !== value) {
        return { valid: false, message };
    }
    return { valid: true, message: null };
};
