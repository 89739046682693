import React, {useState} from 'react';

import {useNavigate} from 'react-router-dom';
import i18n from 'i18next';
import DownloadDropdown from './DownloadDropdown';
import Spinner from '../../../Components/Spinner/Spinner';
import EditableTitle from '../../../Components/EditableTitle/EditableTitle';
import MuButton from '../../../Components/MuButton/MuButton';
import {useAppDispatch, useAppSelector} from '../../../Helpers/hooks';
import {HistoryActionCreators, saveMoodboard} from '../../../Redux/reducers/moodboardEditorSlice';
import BoardHeaderButton from './BoardHeaderButton';
import {openQuitWithoutSavingAlert} from '../../../Redux/reducers/modalsSlice';

type Props = {
    noteTitle: string;
};

const Header: React.FC<Props> = ({
    noteTitle,
}) => {
    const canUndo = useAppSelector((state) => !!state.moodboardEditor.past.length);
    const canRedo = useAppSelector((state) => !!state.moodboardEditor.future.length);
    const loading = useAppSelector((state) => !!state.moodboardEditor.present.loading);
    const saved = useAppSelector((state) => !!state.moodboardEditor.present.saved);
    const [title, setTitle] = useState(noteTitle);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="beHeader">
                <div className="beHeader__logo-actions">
                    <EditableTitle
                        defaultValue={title}
                        contentEditable
                        className="beHeader-title"
                        onBlur={(e) => setTitle(e.target.textContent?.replace(/\n/g, ' ') || '')}
                    />
                </div>

                <div className="beHeader-left beHeader-left--ellipsis">
                    <div className="beHeader-actions">
                        <DownloadDropdown title={title} />
                        <BoardHeaderButton
                            label={i18n.t('components.boardEditor.undo')}
                            onClick={() => dispatch(HistoryActionCreators.undo())}
                            icon="Undo"
                            disabled={!canUndo}
                        />
                        <BoardHeaderButton
                            label={i18n.t('components.boardEditor.redo')}
                            onClick={() => dispatch(HistoryActionCreators.redo())}
                            icon="Redo"
                            disabled={!canRedo}
                        />
                    </div>

                    <div className="beHeader__moodboard-actions">
                        <div className="beHeader-transferManager">
                            {loading && <Spinner size="20px" />}
                        </div>
                        <MuButton
                            classModifiers={['default']}
                            className="beHeader-btn"
                            label={i18n.t('global.back')}
                            handleClick={() => {
                                dispatch(openQuitWithoutSavingAlert(
                                    !saved || (!saved && loading),
                                    () => navigate(-1),
                                ));
                            }}
                        />
                        <MuButton
                            icon="Save"
                            className="beHeader-btn"
                            classModifiers={['shape', 'shape-valid']}
                            label={i18n.t('components.boardEditor.save')}
                            handleClick={() => dispatch(saveMoodboard(title))}
                            disabled={loading}
                        />
                    </div>

                </div>
            </div>
            {
                /* Didn't find a better solution to preload fonts */
            }
            <div style={{ display: 'none', fontWeight: 'normal', fontStyle: 'italic', color: '#FCFCFC' }}>
                <div style={{ fontFamily: 'Amatic SC', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Faune One', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Fjalla One', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Josefin Sans', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Libre Baskerville', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Nixie One', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Nunito', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Oswald', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Playfair Display', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Raleway', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Roboto', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Space Mono', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Volkhov', display: 'absolute', marginLeft: -9999 }}>.</div>
            </div>
            <div style={{ display: 'none', fontWeight: 'bold', color: '#FCFCFC' }}>
                <div style={{ fontFamily: 'Amatic SC', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Faune One', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Fjalla One', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Josefin Sans', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Libre Baskerville', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Nixie One', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Nunito', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Oswald', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Playfair Display', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Raleway', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Roboto', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Space Mono', display: 'absolute', marginLeft: -9999 }}>.</div>
                <div style={{ fontFamily: 'Volkhov', display: 'absolute', marginLeft: -9999 }}>.</div>
            </div>
        </>
    );
};

export default Header;
