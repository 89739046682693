import Swal from 'sweetalert2';
import i18n from 'i18next';

export class AccountAlerts {
    static accountUpdateError = () => {
        Swal.fire({
            title: i18n.t<string>('accounts.update.alert.error.title'),
            text: i18n.t<string>('accounts.update.alert.error.description'),
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: i18n.t<string>('accounts.update.alert.error.button'),
        }).then();
    };

    static accountUpdateSuccess = () => {
        Swal.fire({
            title: i18n.t<string>('accounts.update.alert.success.title'),
            text: i18n.t<string>('accounts.update.alert.success.description'),
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: i18n.t<string>('accounts.update.alert.success.button'),
        }).then();
    };
}
