import * as React from 'react';
import { useDispatch } from 'react-redux';
import { deleteProject, updateProject, useProjects } from 'mushin-redux-store';
import i18n from 'i18next';
import SortBySelect from '../../Components/FiltersToolbar/Elements/SortBySelect';
import FiltersToolbar from '../../Components/FiltersToolbar/FiltersToolbar';
import { categoryFilter, createdAtFilter, ownersFilter, statusFilter } from '../../Components/FiltersToolbar/helpers';
import InputText from '../../Components/InputText/InputText';
import MuButton from '../../Components/MuButton';
import ActionBar from '../../Components/ActionBar/ActionBar';
import Client from '../../Services/Client';
import { openAlert, openModal } from '../../Redux/reducers/modalsSlice';

export type ProjectsSearchParams = {
    sort?: string;
    name_search?: string;
    category?: string | null;
    min_created_at?: string;
    max_created_at?: string;
    owners?: string[];
    status?: string;
};

type Props = {
    searchParams: ProjectsSearchParams;
    setSearchParams: (searchParams: ProjectsSearchParams) => void;
    categories: { key: string | null; count: number }[];
    selectedIds?: string[];
};

const ProjectsToolbar: React.FC<Props> = ({ searchParams, setSearchParams, selectedIds, categories }) => {
    const dispatch = useDispatch();
    const selectedItems = useProjects(selectedIds);

    const moreArchivedSelected =
        selectedItems && selectedItems.filter((item) => item?.archived_at).length >= selectedItems.length / 2;
    const toggleArchived = async () => {
        if (selectedItems) {
            await Promise.all(
                selectedItems.map((item) => {
                    if (item) dispatch(updateProject(item.id, { archived: !moreArchivedSelected }));
                }),
            );
        }
    };

    return (
        <section className="mu-toolbar mu-toolbar--standalone">
            <h1 className="mu-toolbar__title mu-toolbar__title--main">{i18n.t('projects.title')}</h1>
            <SortBySelect
                sort={searchParams.sort}
                setSort={(sort) => setSearchParams({ ...searchParams, sort })}
                options={[
                    { key: '-created_at', icon: 'Calendar', label: i18n.t('components.sort.mostRecent') },
                    { key: 'created_at', icon: 'Calendar', label: i18n.t('components.sort.oldest') },
                    { key: 'name', icon: 'ArrowDown', label: i18n.t('components.sort.alphabetic') },
                    { key: '-name', icon: 'ArrowUp', label: i18n.t('components.sort.reverseAlphabetic') },
                ]}
                defaultSort="-created_at"
            />
            <FiltersToolbar
                filters={{
                    status: statusFilter(searchParams.status, (status) => setSearchParams({ ...searchParams, status })),
                    category: categoryFilter(
                        searchParams.category,
                        (category) => setSearchParams({ ...searchParams, category }),
                        categories,
                    ),
                    authors: ownersFilter(searchParams.owners, (owners) =>
                        setSearchParams({ ...searchParams, owners }),
                    ),
                    creationDate: createdAtFilter(
                        searchParams.min_created_at,
                        searchParams.max_created_at,
                        (min_created_at, max_created_at) =>
                            setSearchParams({ ...searchParams, min_created_at, max_created_at }),
                    ),
                }}
                resetAll={() => setSearchParams({})}
            />
            <div className="mu-toolbar__search">
                <InputText
                    value={searchParams.name_search}
                    onChange={(e) => setSearchParams({ ...searchParams, name_search: e.target.value })}
                    placeholder={i18n.t('components.filters.search')}
                />
            </div>
            {!!selectedIds?.length && (
                <ActionBar
                    items={[
                        {
                            icon: 'Write',
                            label: i18n.t('global.edit'),
                            handler: () => {
                                if (selectedItems?.[0]) {
                                    dispatch(openModal('EditProject', { project: selectedItems[0] }));
                                }
                            },
                            disabled: selectedItems?.length !== 1 || !Client.isAllowedToEditOrganization(),
                        },
                        {
                            icon: 'Archive',
                            label: moreArchivedSelected ? i18n.t('global.unarchive') : i18n.t('global.archive'),
                            handler: toggleArchived,
                            disabled: !Client.isAllowedToEditOrganization(),
                        },
                        {
                            label: i18n.t('global.delete'),
                            icon: 'Delete',
                            handler: () => {
                                const I18nKey = selectedIds.length > 1 ? 'deleteProjects' : 'deleteProject';
                                const i18nProjectNameOptions = {
                                    name: selectedItems?.[0]?.name || '',
                                };
                                dispatch(
                                    openAlert({
                                        type: 'danger',
                                        title: i18n.t(`modals.${I18nKey}.title`, i18nProjectNameOptions),
                                        icon: 'Delete',
                                        confirmLabel: i18n.t(`modals.${I18nKey}.confirmButton`, i18nProjectNameOptions),
                                        body: i18n.t(`modals.${I18nKey}.catchPhrase`, i18nProjectNameOptions),
                                        confirmAction: async () =>
                                            selectedItems?.forEach(
                                                (project) => project && dispatch(deleteProject(project.id)),
                                            ),
                                    }),
                                );
                            },
                            disabled: !Client.isAllowedToEditOrganization(),
                        },
                    ]}
                />
            )}
            {Client.isAllowedToCreateProject() && (
                <div className="mu-toolbar__import">
                    <MuButton
                        icon="Plus"
                        handleClick={() => dispatch(openModal('CreateProject'))}
                        label={i18n.t('projects.create')}
                    />
                </div>
            )}
        </section>
    );
};

export default ProjectsToolbar;
