import ReactGA from 'react-ga';
import { APP_CONFIG } from '../../config';

class GoogleAnalytics {
    static init() {
        if (APP_CONFIG.GA.app_id) {
            ReactGA.initialize(APP_CONFIG.GA.app_id, APP_CONFIG.GA.conf);
        }
    }

    static logPageView() {
        if (APP_CONFIG.GA.app_id) {
            ReactGA.set({ page: window.location.pathname + window.location.search });
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    static setUserId(uid) {
        if (APP_CONFIG.GA.app_id) {
            ReactGA.set({ userId: uid });
            GoogleAnalytics.sendConnection(uid);
        }
    }

    static sendConnection(uid) {
        if (APP_CONFIG.GA.app_id) {
            ReactGA.event({
                category: 'User',
                action: 'Connected',
                value: uid,
            });
        }
    }
}

export default GoogleAnalytics;
