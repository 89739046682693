import React, {useEffect, useState} from 'react';
import {ApiV3, AvailableLocale} from 'mushin-redux-store';
import i18n from 'i18next';
import SessionsLayout from '../Components/SessionsLayout/SessionsLayout';
import Document, {DocumentContent} from '../../../Components/Document/Document';

const GdprPage: React.FC = () => {
    const [gdpr, setGdpr] = useState<DocumentContent | null>(null);

    useEffect(() => {
        ApiV3.getInstance().get(
            'users/gdpr/public', { params: {locale: i18n.resolvedLanguage as AvailableLocale}}
        ).then((response) => setGdpr(response.data));
    }, []);

    return (
        <div id="mushin-app">
            <SessionsLayout>
                <h1 className="mu-terms__title">{i18n.t('accounts.signUp.gdpr.title')}</h1>
                {gdpr && (<Document desc={gdpr} />)}
            </SessionsLayout>
        </div>
    );
};

export default GdprPage;
