import React from 'react';
import moment from 'moment';

import {
    useChatMessage, ProfilesQueryParams,
} from 'mushin-redux-store';
import i18n from 'i18next';
import MuIcon from '../MuIcon';
import ProfileDetailsPopover from '../UserDetailsPopover/ProfileDetailsPopover';
import {getProfileAvatar, useProfileWithDefault} from '../../Helpers/users';
import Popover from '../Popover/Popover';
import MessageContent from '../Message/MessageContent';
import MessageReaction from '../Reaction/MessageReaction';

type Props = {
    id: string;
    userMentionsQueryParams?: ProfilesQueryParams;
}

const ChatMessage: React.FC<Props> = ({
    id,
    userMentionsQueryParams,
}) => {
    const chatMessage = useChatMessage(id);
    const profile = useProfileWithDefault(chatMessage?.profile_id);

    if (!profile || !chatMessage) return null;

    return (
        <article id={id} className="mu-chat-message">
            <ProfileDetailsPopover
                profile={profile}
                hoveredElement={(
                    <div className="mu-entity-row__infos">
                        <div className="mu-chat-message__avatar">
                            <img
                                className="mu-chat-message__avatar-img"
                                alt={profile.name}
                                src={getProfileAvatar(profile)}
                            />
                        </div>
                    </div>
                )}
            />
            <MessageContent message={chatMessage} userMentionsQueryParams={userMentionsQueryParams}>
                <h2 className="mu-chat-message__author">
                    <ProfileDetailsPopover
                        profile={profile}
                        hoveredElement={profile.name}
                    />
                    {chatMessage.created_at !== chatMessage.updated_at ? (
                        <Popover
                            position="top"
                            hoveredElement={(
                                <div className="mu-chat-message__date">
                                    {moment(chatMessage.created_at).format('LT')}
                                    <MuIcon svgName="Edit" />
                                </div>
                            )}
                        >
                            {`${i18n.t('global.modified')} ${moment(chatMessage.updated_at).fromNow()}`}
                        </Popover>
                    ) : (
                        <div className="mu-chat-message__date">
                            {moment(chatMessage.created_at).format('LT')}
                        </div>
                    )}
                    <MessageReaction
                        className="mu-chat-message__favorite"
                        name="favorite"
                        message={chatMessage}
                        horizontal
                        size="small"
                    />
                </h2>
            </MessageContent>
        </article>
    );
};

export default ChatMessage;
