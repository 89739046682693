import React, { useCallback, useRef, useState } from 'react';
import Select from 'react-select';
import { TwitterPicker } from 'react-color';
import {MoodboardDrawer, Cell, Content} from 'mushin-node-moodboard';
import MuIcon from '../../../../../MuIcon';
import { classWithModifiers } from '../../../../../../Helpers/css';
import { useClickOutside } from '../../../../../../Helpers/effects';

export type ActionMode = 'cell' | 'crop' | 'textEdit'

export type ActionProps = {
    drawer: MoodboardDrawer;
    mode: ActionMode;
    cell: Cell;
    content: Content;
    setMode: (mode: ActionMode) => void;
    setContent: (content: Content) => void;
    moveForward: () => void;
    moveBackward: () => void;
    remove: () => void;
};

export const ActionBarButton = ({
    children,
    onClick,
    disabled = false,
}: {
  children: any;onClick: () => void;disabled?: boolean;
}) => (
    <button type="button" className={classWithModifiers('blActionBar-button', { disabled })} onClick={onClick} disabled={disabled}>
        {children}
    </button>
);

export const ActionBarMuIconButton = ({
    svgName,
    onClick,
    active = false,
    disabled = false,
}: {
  svgName: string;onClick: () => void;active?: boolean;disabled?: boolean;
}) => (
    <ActionBarButton onClick={onClick} disabled={disabled}>
        <MuIcon className={classWithModifiers('blActionBar-button-icon', { active })} svgName={svgName} />
    </ActionBarButton>
);

export const ActionBarSeparator = () => <div className="blActionBar-separator" />;

export const ActionBarSelect = ({
    styles = {},
    classModifiers,
    formatSingleValue = () => ({}),
    formatOption = () => ({}),
    ...props
}) => (
    <Select
        {...props}
        className={classWithModifiers('blActionBar-select', classModifiers)}
        styles={{
            control: (provided, state) => ({
                ...provided,
                backgroundColor: '',
                minHeight: '',
                margin: '.4rem',
                padding: '.4rem 0',
                borderRadius: '.4rem',
                borderColor: state.isFocused ? 'white' : provided.borderColor,
                boxShadow: '',
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                padding: '0 .4rem',
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                marginTop: '.4rem',
                marginBottom: '.4rem',
            }),
            singleValue: (provided, state) => {
                return {
                    ...provided,
                    ...formatSingleValue(state),
                    color: 'white',
                };
            },
            placeholder: (provided) => ({
                ...provided,
                color: 'white',
            }),
            option: (provided, state) => ({
                ...provided,
                ...formatOption(state),
                color: 'white',
            }),
            ...styles,
        }}
        theme={(theme) => ({
            ...theme,
            colors: {
                ...theme.colors,

                primary: '#f57c00',
                primary25: '#e67400',

                neutral0: 'hsla(0, 0%, 5%, 80%)',
                neutral5: 'hsla(0, 0%, 10%, 80%)',
                neutral10: 'hsla(0, 0%, 20%, 80%)',
                neutral20: 'hsla(0, 0%, 80%, 100%)',
                neutral30: 'hsla(0, 0%, 90%, 100%)',
                neutral40: 'hsla(0, 0%, 95%, 100%)',
                neutral50: 'hsla(0, 0%, 97%, 100%)',
                neutral60: 'hsla(0, 0%, 100%, 100%)',
                neutral70: 'hsla(0, 0%, 80%, 80%)',
                neutral80: 'hsla(0, 0%, 90%, 80%)',
                neutral90: 'hsla(0, 0%, 100%, 80%)',
            },
        })}
    />
);

export const ActionBarSimpleSelect = (props) => (
    <ActionBarSelect
        {...props}
        styles={{
            indicatorsContainer: () => ({ display: 'none' }),
        }}
    />
);

export const ActionBarColorPicker = ({
    children,
    buttonStyle,
    color,
    ...props
}) => {
    const [displayPicker, setDisplayPicker] = useState(false);
    const picker = useRef();

    const handleClick = useCallback(() => setDisplayPicker(!displayPicker), [displayPicker]);

    useClickOutside(picker, displayPicker, handleClick);

    return (
        <div className="mu-color-picker blActionBar-color-picker" ref={picker}>
            <button type="button" className="mu-color-picker__button" onClick={handleClick} style={buttonStyle}>
                {children}
            </button>
            {displayPicker && (
                <div className="mu-color-picker__popover">
                    <TwitterPicker {...props} color={color} />
                </div>
            )}
        </div>
    );
};

export const ReturnToCellModeButton = ({
    setMode,
}: ActionProps) => <ActionBarMuIconButton key="return" svgName="OpenPanel" onClick={() => setMode('cell')} />;

const colors = ['transparent', '#FF9292', '#FFB086', '#FCE285', '#84B9DE', '#62C299', '#8ED3E1', '#8E9EE1', '#B68EE1', '#FDAFD8'];

export const BackgroundPickerButton = ({
    content,
    setContent,
}: ActionProps) => <ActionBarColorPicker buttonStyle={{ background: content.background }} colors={colors} color={content.background} onChangeComplete={(color) => setContent({ ...content, background: color.hex })} />;
const lineColors = ['transparent', '#FFFFFF', '#000000', '#FF9292', '#FFB086', '#FCE285', '#84B9DE', '#62C299', '#8ED3E1', '#8E9EE1'];

export const LineColorPickerButton = ({
    content,
    setContent,
}: ActionProps) => (
    <ActionBarColorPicker buttonStyle={{ backgroundColor: 'gray', fill: content.color, fontWeight: 1000 }} colors={lineColors} color={content.color || '#000000'} onChangeComplete={(color) => setContent({ ...content, color: color.hex })}>
        <MuIcon svgName="Line" />
    </ActionBarColorPicker>
);

export const MoveForwardButton = ({
    moveForward,
}: ActionProps) => <ActionBarMuIconButton onClick={moveForward} svgName="MoveForward" disabled={!moveForward} />;

export const MoveBackwardButton = ({
    moveBackward,
}: ActionProps) => <ActionBarMuIconButton onClick={moveBackward} svgName="MoveBackward" disabled={!moveBackward} />;

export const DeleteButton = ({
    setMode,
    remove,
}: ActionProps) => (
    <ActionBarMuIconButton
        onClick={() => {
            remove();
            setMode('layout');
        }}
        svgName="Delete"
    />
);

export const commonButtons = (props: ActionProps) => [<MoveForwardButton key="MoveForwardButton" {...props} />, <MoveBackwardButton key="MoveBackwardButton" {...props} />, <ActionBarSeparator key="ActionBarSeparator" />, <DeleteButton key="DeleteButton" {...props} />];
