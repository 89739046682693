import type { FormatRelativeFn } from '../../../types';

const formatRelativeLocale = {
    lastWeek: '\'el\' eeee \'pasado a la\' p',
    yesterday: '\'ayer a la\' p',
    today: '\'hoy a la\' p',
    tomorrow: '\'mañana a la\' p',
    nextWeek: 'eeee \'a la\' p',
    other: 'P',
};

const formatRelativeLocalePlural = {
    lastWeek: '\'el\' eeee \'pasado a las\' p',
    yesterday: '\'ayer a las\' p',
    today: '\'hoy a las\' p',
    tomorrow: '\'mañana a las\' p',
    nextWeek: 'eeee \'a las\' p',
    other: 'P',
};

const formatRelative: FormatRelativeFn = (token, date, _baseDate, _options) => {
    if (date.getUTCHours() !== 1) {
        return formatRelativeLocalePlural[token];
    } 
    return formatRelativeLocale[token];
};

export default formatRelative;
