import React from 'react';
import {DataGridColumn} from './types';
import {classWithModifiers} from '../../Helpers/css';

type Props<T> = {
    columns: DataGridColumn<T>[];
    id: string;
    getItem: (id: string) => T | null | undefined;
}

type DataGridItem<T> = React.FC<Props<T>>

const DataGridItem = <T, >({columns, id, getItem}: Parameters<DataGridItem<T>>[0]): ReturnType<DataGridItem<T>> => {
    const item = getItem(id);

    if (!item) return null;

    return (
        <>
            {columns.map(
                (column) => (
                    <div
                        key={column.key}
                        className={classWithModifiers('mu-data-grid__cell', column.classModifiers)}
                        onClick={() => column.onClick && column.onClick(item)}
                    >
                        <column.ValueComponent item={item} />
                    </div>
                )
            )}
        </>
    );
};

export default DataGridItem;
