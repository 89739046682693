import React from 'react';

import {MoodboardDrawer, Board, Box, Point, TemplateContent} from 'mushin-node-moodboard';
import { ZoomBar } from '../ZoomBar';
import { SelectedCellOverlay } from './SelectedCellOverlay';
import { HoveredCellOverlay } from './HoveredCellOverlay';
import { SnapRulesOverlay } from './SnapRulesOverlay';
import {Camera} from '../../type';

type Props = {
    drawer: MoodboardDrawer;
    containerDimensions: {
        width: number;
        height: number;
    };
    templateContents: TemplateContent;
    board: Board;
    camera: Camera;
    selectedCell: string;
    hoveredCell: string | null;
    snapRules: any;
    drag: {
        scalingKernel: Point;
        originPoint: Point;
        originCell: Box;
        cellIndex: number;
        cellId: string;
    } | null | undefined;
    setBoard: (board: Board) => void;
    setCamera: (camera: Camera) => void;
    ratio: number;
}

export const ActionLayer: React.FC<Props> = (props) => {
    // TODO props.selectedCell should be called props.selectedCellId (careful this prop is passed through children)
    const selectedCell = props.board.cells[props.selectedCell];
    const selectedContent = props.board.contents[props.selectedCell];

    return (
        <>
            <div className="boardLayout-zoomBar">
                <ZoomBar camera={props.camera} setCamera={props.setCamera} />
            </div>

            {props.board.ids.map(
                (id) => (
                    id !== props.selectedCell
                        && props.board.cells[id]
                        && (props.board.contents[id] || props.templateContents[id].type !== 'whiteCol')
                ) && (
                    <HoveredCellOverlay {...props} idCell={id} key={id} />
                )
            ).reverse()}

            {selectedCell && (
                <SelectedCellOverlay // Set key to reset component if selectedCell, mode or type changes (mainly for actionBar position refresh)
                    key={`cell-${props.selectedCell}-${props.mode}-${selectedContent?.type}`}
                    {...props}
                />
            )}

            <SnapRulesOverlay {...props} />
        </>
    );
};
