import React from 'react';
import {classWithModifiers} from '../../Helpers/css';
import MuIcon from '../MuIcon';

type Props = {
    activeMode?: 'Cluster' | 'Insights' | 'Opportunities' | 'Solutions';
    label?: string;
    label_number?: number;
    withPictures?: boolean;
    onClick?: () => void;
    create?: boolean;
    createText?: string;
};

const ActionCard: React.FC<Props> = ({
    activeMode,
    label,
    label_number,
    withPictures,
    onClick,
    create,
    createText,
}: Props) => {
    const createClass = create ? `create${activeMode}` : '';

    return (
        <article className="mu-action-card">
            <div className={classWithModifiers('mu-action-card__pictures', {
                'no-pictures': !withPictures,
                [createClass]: create,
            })}
            >
                <div className="mu-action-card-preview__overlay">
                    <button
                        type="button"
                        className="mu-action-card-button"
                        onClick={onClick}
                    >
                        {activeMode && (
                            <MuIcon className="mu-action-card-button__icon" svgName={activeMode} />
                        )}
                        {!create && label_number && (
                            <span
                                className={`mu-action-card-button__label_number mu-action-card-button__label_number--${
                                    activeMode}`}
                            >
                                {label_number}
                            </span>
                        )}
                        {create ? (
                            <p className={`mu-action-card-button__label mu-action-card-button__label--${activeMode}`}>
                                {createText}
                            </p>
                        ) : (
                            label ? (
                                <span
                                    className={`mu-action-card-button__label ${
                                        create ? `mu-action-card-button__label--${activeMode}` : ''}`}
                                >
                                    {label}
                                </span>
                            ) : (
                                activeMode && <span className="mu-action-card-button__label">{activeMode}</span>
                            )
                        )}
                    </button>
                </div>
            </div>
        </article>
    );
};

export default ActionCard;
