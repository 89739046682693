import React from 'react';
import { Point } from 'mushin-node-moodboard';
import { Camera } from '../type';

export const project = ({
    a,
    b,
}: Camera) => ({
    x,
    y,
}: Point) => ({
    x: a * x + b.x,
    y: a * y + b.y,
});
export const unproject = ({
    a,
    b,
}: Camera) => ({
    x,
    y,
}: Point) => ({
    x: (x - b.x) / a,
    y: (y - b.y) / a,
});

export const translate = (camera: Camera, b: Point) => ({
    ...camera,
    b,
});

export const scale = ({
    a,
    b,
}: Camera, a2: number, focusPoint: Point) => ({
    b: {
        x: focusPoint.x * a + b.x - focusPoint.x * a2,
        y: focusPoint.y * a + b.y - focusPoint.y * a2,
    },
    a: a2,
});

const zoomLevel = [33, 41, 50, 64, 80, 100, 125, 150, 200, 250, 300].map((x) => x / 100);

/**
 * given a number x in the range [0,n] where n is the length of the array,
 * return the interpolation of X in the array,
 *
 * if x is an Int, return the value of arr[x]
 * else interpolate between arr[x] and arr[x+1]
 */
const interpolate = (arr, k) => {
    if (k < 0) return arr[0];
    if (k >= arr.length - 1) return arr[arr.length - 1];

    const r = Math.floor(k);

    return arr[r] + (arr[r + 1] - arr[r]) * (k - r);
};

/**
 * inverse of interpolate
 */
const interpolateInv = (arr, value) => {
    let a;
    for (a = 0; a < arr.length && arr[a] < value; a++);

    if (a === 0) return 0;
    if (a >= arr.length) return arr.length - 1;

    return a - 1 + (value - arr[a - 1]) / (arr[a] - arr[a - 1]);
};

export const nextZoom = (a: number, delta: number, round = false) => {
    let k = interpolateInv(zoomLevel, a) + delta;

    k = round ? Math.round(k) : k;

    return interpolate(zoomLevel, k);
};

export const injectCamera = (C) => {
    class CameraInjector extends React.Component {
    state: Camera = { a: 1, b: { x: 0, y: 0 } };

    setCamera = (camera: Camera) => this.setState(camera);

    render() {
        return <C {...this.props} camera={this.state} setCamera={this.setCamera} />;
    }
    }
    return CameraInjector;
};
