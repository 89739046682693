import React from 'react';
import {updateNews, useNews} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import { ModalBody } from '../../../../Components/Modal/ModalBody';
import { ModalHeader } from '../../../../Components/Modal/ModalHeader';
import MuButton from '../../../../Components/MuButton';
import { ModalFooter } from '../../../../Components/Modal/ModalFooter';
import {useAppDispatch} from '../../../../Helpers/hooks';
import TextTranslation from '../../../../Components/Translation/TextTranslation';
import QuillTranslation from '../../../../Components/Translation/QuillTranslation';

type Props = {
    onClose: () => void;
    id: string;
};

const NewsModal: React.FC<Props> = (
    {
        onClose,
        id,
    }
) => {
    const news = useNews(id);
    const dispatch = useAppDispatch();

    if (!news) return null;

    return (
        <Modal handleRemoveModal={onClose}>
            <ModalHeader
                title={<TextTranslation translationProp={news.titles} fallback={news.title} />}
                icon="Bell"
            />
            <ModalBody className="mu-view-news-modal__body">
                <QuillTranslation
                    translationProp={news.descriptions}
                    fallback={news.content}
                    className="mu-rich-input"
                    theme=""
                />
            </ModalBody>
            <ModalFooter>
                <MuButton
                    handleClick={async () => {
                        await dispatch(updateNews(id, {
                            read: true,
                        }));
                        onClose();
                    }}
                    label={i18n.t('tutorial.understood')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default NewsModal;
