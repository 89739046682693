import React, {ReactNode, SVGAttributes, useState} from 'react';
import {classWithModifiers, Modifiers} from '../../Helpers/css';
import { MuSvg } from '../MuSvg';
import MuIcon from '../MuIcon';
import Spinner from '../Spinner';

type Props = {
  classModifiers?: Modifiers;
  className?: string;
  getRef?: (ref: Object) => void;
  label?: ReactNode | string;
  icon?: string | React.FC<SVGAttributes<SVGElement>>;
  disabled?: boolean;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  type?: 'button' | 'submit' | 'reset';
};

const MuButtonWithLoader: React.FC<Props> = ({
    classModifiers = [],
    className,
    label,
    icon,
    disabled = false,
    handleClick,
    type = 'button',
    getRef,
}) => {
    const [loading, setLoading] = useState(false);

    const iconOnly = !!icon && !label;

    let fullClassName = classWithModifiers('mu-btn', classModifiers, className);

    if (iconOnly) {
        fullClassName += ` ${classWithModifiers('mu-icon-btn', classModifiers)}`;
    }

    return (// eslint-disable-next-line react/button-has-type
        <button
            ref={getRef}
            type={type}
            className={fullClassName}
            disabled={disabled}
            onClick={async (event) => {
                if (handleClick && !loading) {
                    setLoading(true);
                    await handleClick(event);
                    setLoading(false);
                }
            }}
        >
            {loading ? (
                <Spinner className="mu-btn__left-icon" size="16px" />
            ) : icon && (
                <>
                    {typeof icon === 'string' ? (
                        <MuIcon className="mu-btn__left-icon" svgName={icon} />
                    ) : (
                        <MuSvg className="mu-btn__left-icon" SvgComp={icon} />
                    )}
                </>
            )}

            {label && <span className="mu-btn__text">{label}</span>}
        </button>
    );
};

export default MuButtonWithLoader;
