import React from 'react';

import { CropBar, DefaultBar, ImageBar, MixedBar, TextBar, TextEditBar } from './actionBars';
import { ActionProps } from './actionButtons/common';
import { ActionBarProps } from './actionBars/common';
import ShapeBar from './actionBars/ShapeBar';

export const ContextualActionBar = (props: ActionProps & ActionBarProps) => {
    if (props.mode === 'textEdit') {
        return <TextEditBar {...props} />;
    }
    if (props.mode === 'crop') {
        return <CropBar {...props} />;
    }
    if (props.mode === 'cell' && props.content) {
        if (props.content.type === 'image') {
            return <ImageBar {...props} />;
        }
        if (props.content.type === 'mixed') {
            return <MixedBar {...props} />;
        }
        if (props.content.type === 'text') {
            return <TextBar {...props} />;
        }
        if (props.content.type === 'shape') {
            return <ShapeBar {...props} />;
        }
    }

    return <DefaultBar {...props} />;
};
