import {getProfile, getProfilesQuery, useCurrentOrganizationId, ProfilesQueryParams} from 'mushin-redux-store';
import {useCallback} from 'react';
import i18n from 'i18next';
import {useAppDispatch} from '../../../Helpers/hooks';

export type MentionOption = {
    type: 'user';
    id: string;
    value: string;
    avatarUrl: string;
} | {
    type: 'hashtag';
    id: string;
    value: string;
}

type RenderList = (membersValues: MentionOption[], searchTerm: string) => void

export const useMentionsSource = (
    hashtags: string[] | null, userMentionsQueryParams?: ProfilesQueryParams,
): (searchTerm: string, renderList: RenderList, mentionChar: string) => Promise<void> => {
    const dispatch = useAppDispatch();
    const [organizationId] = useCurrentOrganizationId();

    return useCallback(async (searchTerm: string, renderList: RenderList, mentionChar: string) => {
        const membersValues: MentionOption[] = [];
        if (mentionChar === '@') {
            const membersQuery = await dispatch(getProfilesQuery(
                {
                    include_groups: true,
                    organization_id: organizationId,
                    name_search: searchTerm || undefined,
                    ...userMentionsQueryParams,
                },
                false,
            ));
            if (membersQuery) {
                for await (const memberId of membersQuery.data) {
                    const member = await dispatch(getProfile(memberId));
                    if (member) {
                        membersValues.push({
                            type: 'user',
                            id: member.id,
                            value: member.name,
                            avatarUrl: member.avatar_url,
                        });
                    }
                }
            }
            if (
                searchTerm.match(/^a?l?l?$/i)
                || searchTerm.match(/^t?o?u?s?$/i)
            ) {
                membersValues.push({
                    type: 'user',
                    id: 'all',
                    value: i18n.t<string>('tooltips.all'),
                    avatarUrl: '',
                });
            }
        } else if (mentionChar === '#') {
            hashtags?.forEach((tag: string) => {
                if (tag.match(new RegExp(searchTerm, 'i'))) {
                    membersValues.push({type: 'hashtag', id: tag, value: tag});
                }
            });
        }
        renderList(membersValues, searchTerm);
    }, [dispatch, hashtags, organizationId, userMentionsQueryParams]);
};
