import React from 'react';
import { ActionBar, ActionBarGroup } from './common';
import { ActionProps, ActionBarSeparator, commonButtons } from '../actionButtons/common';
import { textButtons } from '../actionButtons/text';
import { imageButtons } from '../actionButtons/image';
import { TextBottomLayoutButton, TextLeftLayoutButton, TextRightLayoutButton, TextTopLayoutButton } from '../actionButtons/mixed';

export default ((props: ActionProps) => (
    <ActionBar direction="vertical">
        <ActionBarGroup>
            {textButtons(props)}
        </ActionBarGroup>
        <ActionBarGroup>
            {imageButtons(props)}
            <ActionBarSeparator />
            <TextTopLayoutButton {...props} />
            <TextLeftLayoutButton {...props} />
            <TextBottomLayoutButton {...props} />
            <TextRightLayoutButton {...props} />
            <ActionBarSeparator />
            {commonButtons(props)}
        </ActionBarGroup>
    </ActionBar>
));
