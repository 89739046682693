import React from 'react';

/**
 * display snapping guide
 *
 * for each guide:
 *  - display the guide as horizontal / vertical line
 *  - highligh the element from which the guide derivate
 *  - highligh the element side from which the guide derivate
 *
 */
export const SnapRulesOverlay = ({
    camera,
    dragging,
    ratio,
    board,
    snapRules,
    drag,
}) => (
    <>
        {removeDuplicatedGuide(snapRules).map(({
            axe,
            value,
        }) => <div key={axe + value} className="blSnapRule-guide" style={getGuideStyle(camera, ratio, axe, value)} />)}
        {removeDuplicatedCell(snapRules).map(({
            parent,
        }, i) => <div key={i} className="blSnapRule-cell" style={getCellHighlightStyle(camera, ratio, parent.cell === 'container' ? boardToCell(board) : parent.cell)} />)}
        {removeDuplicatedCellSide(snapRules).map(({
            axe,
            parent,
        }, i) => <div key={i} className="blSnapRule-cellSide" style={getSideHighlightStyle(camera, ratio, parent.cell === 'container' ? boardToCell(board) : parent.cell, axe, parent.side)} />)}
        {drag && board.cells[drag.cellId] && removeDuplicatedSelfSide(snapRules).map(({
            axe,
            side,
        }, i) => <div key={i} className="blSnapRule-cellSide" style={getSideHighlightStyle(camera, ratio, board.cells[drag.cellId], axe, side)} />)}
    </>
);

/**
 * util functions to prevent from redering duplicated hints
 */
// const removeDuplicated = (equalFn) => (arr) => arr
const removeDuplicated = (equalFn) => (arr) => arr.filter((a, i) => i === arr.findIndex((b) => equalFn(a, b)));

const removeDuplicatedGuide = removeDuplicated((a, b) => a.axe === b.axe && a.value === b.value);
const removeDuplicatedCell = removeDuplicated((a, b) => a.parent.cell === b.parent.cell);
const removeDuplicatedCellSide = removeDuplicated((a, b) => a.parent.cell === b.parent.cell && a.parent.side === b.parent.side && a.axe === b.axe);
const removeDuplicatedSelfSide = removeDuplicated((a, b) => a.axe === b.axe && a.side === b.side);

/**
 * util functions to position elements
 *
 * border are kind of a mess to position
 * ( sorry )
 *
 * guide border width is set to half cell border
 * to have a nice cover on overflows
 *
 */
const BORDER_WIDTH = 2;

const getGuideStyle = (camera, ratio, axe, value) => (axe === 'x' ? {
    height: '100%',
    transform: `translateX(${(value * camera.a + camera.b.x) * ratio - BORDER_WIDTH / 4}px)`,
    borderRightColor: 'transparent',
    borderWidth: `${BORDER_WIDTH / 2}px`,
} : {
    width: '100%',
    transform: `translateY(${(value * camera.a + camera.b.y) * ratio - BORDER_WIDTH / 4}px)`,
    borderBottomColor: 'transparent',
    borderWidth: `${BORDER_WIDTH / 2}px`,
});

const getCellHighlightStyle = (camera, ratio, cell) => ({
    top: `${(cell.min.y * camera.a + camera.b.y) * ratio - BORDER_WIDTH / 2}px`,
    left: `${(cell.min.x * camera.a + camera.b.x) * ratio - BORDER_WIDTH / 2}px`,
    width: `${(cell.max.x - cell.min.x) * camera.a * ratio + BORDER_WIDTH}px`,
    height: `${(cell.max.y - cell.min.y) * camera.a * ratio + BORDER_WIDTH}px`,
    borderWidth: `${BORDER_WIDTH}px`,
});

const getSideHighlightStyle = (camera, ratio, cell, axe, side) => {
    const box = getCellHighlightStyle(camera, ratio, cell);

    if (axe === 'x') {
        box.borderTopColor = 'transparent';
        box.borderBottomColor = 'transparent';
        if (side === -1) box.borderLeftColor = 'transparent';
        if (side === 1) box.borderRightColor = 'transparent';
        box.width = 0;
        box.left = side === 1 ? `${(cell.max.x * camera.a + camera.b.x) * ratio - BORDER_WIDTH * 0.5}px` : `${(cell.min.x * camera.a + camera.b.x) * ratio - BORDER_WIDTH * 1.5}px`;
    } else {
        box.borderLeftColor = 'transparent';
        box.borderRightColor = 'transparent';
        if (side === -1) box.borderTopColor = 'transparent';
        if (side === 1) box.borderBottomColor = 'transparent';
        box.height = 0;
        box.top = side === 1 ? `${(cell.max.y * camera.a + camera.b.y) * ratio - BORDER_WIDTH * 0.5}px` : `${(cell.min.y * camera.a + camera.b.y) * ratio - BORDER_WIDTH * 1.5}px`;
    }

    return box;
};

const boardToCell = ({
    width,
    height,
}) => ({
    min: { x: 0, y: 0 },
    max: { x: width, y: height },
});
