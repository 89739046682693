import React, {ReactNode, useEffect} from 'react';
import InputText from '../InputText/InputText';
import {UseFieldState, withOptionalMention} from './common';
import {
    compositeValidator,
    emailValidator,
    minLengthValidator,
    requiredValidator,
    videoUrlValidator
} from './validation';

type Props = {
    field: UseFieldState<string>;
    placeholder?: ReactNode | string;
    type?: string;
    label?: React.ReactNode;
    className?: string;
    onChange?: (value: string) => void;
    required?: boolean;
    video?: boolean;
    email?: boolean;
    contrast?: boolean;
    min?: number;
    autoCapitalize?: string;
    message?: string;
    disabled?: boolean;
}

export const FormTextInput: React.FC<Props> = (
    {
        field,
        placeholder,
        type,
        label,
        className,
        onChange,
        required = false,
        video = false,
        email = false,
        contrast = false,
        min,
        autoCapitalize,
        message,
        disabled,
    }
) => {
    const {setValidator} = field;
    useEffect(() => {
        setValidator(
            compositeValidator([
                required && requiredValidator,
                video && videoUrlValidator,
                email && emailValidator,
                !!min && minLengthValidator(min),
            ])
        );
    }, [email, min, required, setValidator, video]);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        field.resetStatus();
        if (onChange) onChange(e.currentTarget.value);
        field.setValue(e.currentTarget.value);
    };

    return (
        <InputText
            className={className}
            label={withOptionalMention(label, required)}
            classModifiers={['full-width']}
            type={type}
            error={!field.status.valid}
            errorMessage={(!field.status.valid && field.status.message) || undefined}
            onBlur={field.validate}
            onChange={onChangeHandler}
            value={field.value}
            placeholder={withOptionalMention(placeholder, required)}
            message={message}
            contrast={contrast}
            autoCapitalize={autoCapitalize}
            disabled={disabled}
        />
    );
};

export default FormTextInput;
