import React, { useEffect } from 'react';
import {useQuestionnairesQuery} from 'mushin-redux-store';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import {Link} from 'react-router-dom';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import MissionsRatio from './MissionsRatio';

type Props = {
    projectId: string;
}

const MissionsPanel: React.FC<Props> = ({ projectId }: Props) => {
    const questionnairesQuery = useQuestionnairesQuery({ project_id: projectId }, true);

    const { setParams } = questionnairesQuery;
    useEffect(() => {
        setParams({ project_id: projectId });
    }, [projectId, setParams]);

    const total = questionnairesQuery.pager?.total_items || 0;
    const totalDone = questionnairesQuery.total_done;

    return (
        <div className="mu-panel mu-missions-panel">
            <div className="mu-missions-panel__content">
                <div>
                    {i18n.t('missions.youHaveCompleted.0')}
                    <div className="mu-missions-panel__count-line">
                        <MissionsRatio totalItems={total} totalDone={totalDone} />
                        <Trans i18nKey="missions.youHaveCompleted.1._" count={total} />
                    </div>
                </div>
                <div className="mu-missions-panel__progress">
                    <CircularProgressbar
                        text={total === totalDone ? '\uD83C\uDF89' : '\ud83d\ude80'}
                        value={totalDone}
                        maxValue={total}
                        strokeWidth={10}
                        background
                        styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0.3,

                            // Text size
                            textSize: '24px',

                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,

                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',

                            // Colors
                            pathColor: '#85B8B2',
                            trailColor: '#EEEEEE',
                            backgroundColor: 'white',
                        })}
                    />
                </div>
            </div>
            <Link className="mu-btn mu-btn--important" to={`/projects/${projectId}/missions`}>
                {i18n.t('missions.seeYours')}
            </Link>
        </div>
    );
};

export default MissionsPanel;
