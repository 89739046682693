import React, { useState } from 'react';

type Props<T> = React.PropsWithChildren<{
    items: T[];
    max: number;
    toggle: (showAll: boolean, setShowAll: React.Dispatch<React.SetStateAction<boolean>>) => React.ReactNode;
    renderItem: (item: T) => React.ReactNode;
    className?: string;
}>;

/**
 * GenericList component
 *
 * @param items - Array of items to be displayed in the list.
 * @param max - Maximum number of items to display before showing the toggle.
 * @param toggle - Optional function to render a custom toggle button.
 * @param renderItem - Function to render each item in the list.
 * @param className - Optional CSS class for the component.
 * @param children additional content
 * @returns The rendered list component.
 */
const GenericList = <T, >({ items, max, toggle, renderItem, className, children }: Props<T>) => {
    const [showAll, setShowAll] = useState(!max);

    return (
        <ul className={className}>
            {items.slice(0, showAll ? items.length : max).map((item) => (
                renderItem(item)
            ))}
            {!!max && items.length > max && toggle(showAll, setShowAll)}
            {children}
        </ul>
    );
};

export default GenericList;
