import React from 'react';
import {QuestionRating} from 'mushin-redux-store';
import {AnswerProps} from '../types';
import MuIcon from '../../../Components/MuIcon';
import { classWithModifiers } from '../../../Helpers/css';

type Props = AnswerProps<QuestionRating>

const RatingAnswer: React.FC<Props> = ({ question, value, setValue, disabled }) => {
    if (!question.maxRatingValue) return null;
    const buttons = [];
    for (let i = 1; i < question.maxRatingValue + 1; i++) {
        buttons.push(
            <button
                className={classWithModifiers('mu-rating-answer__btn', { 'has-value': (value as number) >= i })}
                disabled={disabled}
                key={i}
                type="button"
                onClick={() => {
                    setValue({value: i});
                }}
            >
                {(value && value >= i) ? (
                    <MuIcon className="mu-rating-answer__icon mu-rating-answer__icon--fill" svgName="Star" />
                ) : (
                    <MuIcon className="mu-rating-answer__icon" svgName="StarStroke" />
                )}
            </button>
        );
    }

    return (
        <div className="mu-rating-answer">
            <div className="mu-rating-answer__buttons">
                {buttons}
            </div>
            <div className="mu-rating-answer__labels">
                <div>{question.minRatingLabel}</div>
                <div>{question.maxRatingLabel}</div>
            </div>
        </div>
    );
};

export default RatingAnswer;
