import React from 'react';
import {useCriterion, deleteCriterion} from 'mushin-redux-store';
import i18n from 'i18next';
import { Modifiers, classWithModifiers } from '../../../Helpers/css';
import MuIcon from '../../../Components/MuIcon';
import Client from '../../../Services/Client';
import {openModal, openAlert} from '../../../Redux/reducers/modalsSlice';
import {useAppDispatch} from '../../../Helpers/hooks';
import CriteriaTranslation from '../../../Components/Translation/CriteriaTranslation';

type Props = {
    criterionId: string;
    classModifiers?: Modifiers;
};

const CriterionRow: React.FC<Props> = ({
    criterionId,
    classModifiers,
}: Props) => {
    const dispatch = useAppDispatch();
    const criterion = useCriterion(criterionId);

    if (!criterion) return null;

    const admin = Client.isAllowedToEditOrganization();

    const openCriteriaModal = () => {
        if (admin) {
            dispatch(openModal('EditCriterion', {criterion}));
        }
    };

    return (
        <div key={`${criterionId}-${criterion.name}`} className={classWithModifiers('mu-admin-row', classModifiers)}>
            <button
                className="mu-admin-row__infos"
                type="button"
                onClick={openCriteriaModal}
            >
                <div className="mu-admin-row__name">
                    <CriteriaTranslation criterion={criterion} />
                </div>
                <div className="mu-admin-row__count">
                    <MuIcon className="mu-admin-row__count-icon" svgName="Criterion" />
                    {i18n.t('criteria.valuesCount', {count: criterion.values.length })}
                </div>
            </button>
            {admin && (
                <button
                    className="mu-admin-row__delete-btn"
                    type="button"
                    onClick={() => {
                        dispatch(
                            openAlert({
                                type: 'danger',
                                title: i18n.t('modals.deleteCriterion.title', { name: criterion.name }),
                                icon: 'Delete',
                                confirmLabel: i18n.t('modals.deleteCriterion.confirmButton'),
                                body: i18n.t('modals.deleteCriterion.catchPhrase', { name: criterion.name }),
                                confirmAction: () => dispatch(deleteCriterion(criterionId)),
                            })
                        );
                    }}
                >
                    <MuIcon svgName="Delete" />
                </button>
            )}
        </div>
    );
};

export default CriterionRow;
