import React from 'react';
import {classWithModifiers} from '../../Helpers/css';

type Props = {
    imgUrl: string;
    displayAsSquare?: boolean;
};

const ImageThumbnail: React.FC<Props> = ({
    imgUrl,
    displayAsSquare = true,
}) => {
    return (
        <article className="mu-note mu-note--no-footer">
            <div className={classWithModifiers('mu-note__content', ['picture', {square: displayAsSquare}])}>
                <img
                    className={displayAsSquare ? 'mu-note__content-picture--square' : 'mu-note__content-picture'}
                    src={imgUrl}
                    alt=""
                />
            </div>
        </article>
    );
};

export default ImageThumbnail;
