import React, {useState} from 'react';
import {classWithModifiers} from '../../../Helpers/css';
import {useAppSelector} from '../../../Helpers/hooks';
import {
    moveNoteInBucket,
} from '../../../Redux/reducers/noteBucketSlice';
import DraggableNoteListItem from './DraggableNoteListItem';

const DraggableNoteList: React.FC = () => {
    const [draggedIndex, setDraggedIndex] = useState(-1);
    const [dropIndex, setDropIndex] = useState(-1);

    const noteIds = useAppSelector((state) => state.noteBucket.noteIds);

    const onDragStart = (index: number, event: React.DragEvent) => {
        // set event.dataTransfer, otherwise firefox will not drag
        event.dataTransfer.setData('text', 'whatever');
        setTimeout(() => {
            setDropIndex(index);
            setDraggedIndex(index);
        }, 10);
    };

    const onDragOver = (index: number, event: React.DragEvent) => {
        const {
            left,
            right,
        } = event.currentTarget.getBoundingClientRect();
        const distanceFromLeft = event.clientX - left;
        const distanceFromRight = right - event.clientX;
        let newDropIndex = distanceFromLeft < distanceFromRight ? index : index + 1;

        if (newDropIndex === draggedIndex + 1) {
            newDropIndex = draggedIndex;
        }

        if (dropIndex !== newDropIndex) {
            setDropIndex(newDropIndex);
        }

        event.preventDefault();
    };

    const onDragEnd = (event: React.DragEvent) => {
        moveNoteInBucket(draggedIndex, draggedIndex < dropIndex ? dropIndex - 1 : dropIndex);
        setDraggedIndex(-1);
        setDropIndex(-1);
        event.preventDefault();
    };

    const reorganizedPages = draggedIndex > -1 && draggedIndex !== dropIndex ? [] : noteIds;

    if (!reorganizedPages.length) {
        for (let i = 0; i < noteIds.length; i += 1) {
            if (i === dropIndex) reorganizedPages.push(noteIds[draggedIndex]);
            else if (i > dropIndex && i <= draggedIndex) reorganizedPages.push(noteIds[i - 1]);
            else reorganizedPages.push(noteIds[i]);
        }
    }

    return (
        <div
            className={classWithModifiers('mu-note-bucket__note-list', {dragHover: draggedIndex > -1})}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={onDragEnd}
            onDrop={(e) => e.preventDefault()}
        >
            {noteIds.map((noteId, index) => (
                <DraggableNoteListItem
                    key={noteId}
                    id={noteId}
                    onDragStart={(event) => onDragStart(index, event)}
                    onDragOver={(event) => onDragOver(index, event)}
                />
            ))}
        </div>
    );
};

export default DraggableNoteList;
