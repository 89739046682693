import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type State = {
    imageUrls: string[];
    index: number;
}

export const initialState = {
    imageUrls: [],
    index: 0,
} as State;

const lightboxSlice = createSlice({
    name: 'lightbox',
    initialState,
    reducers: {
        open(state: State, action: PayloadAction<{urls: string[]; index?: number}>) {
            state.imageUrls = action.payload.urls;
            state.index = action.payload.index || 0;
        },
        close(state) {
            state.imageUrls = [];
            state.index = 0;
        },
    },
});

const {
    open,
    close,
} = lightboxSlice.actions;

export const openLightbox = open;

export const closeLightbox = close;

export default lightboxSlice.reducer;
