/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import {updateMe, useMe, useOrganization} from 'mushin-redux-store';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../../../Components/Modal';
import { ModalBody } from '../../../../Components/Modal/ModalBody';
import MuButton from '../../../../Components/MuButton';
import { ModalFooter } from '../../../../Components/Modal/ModalFooter';
import {useAppDispatch} from '../../../../Helpers/hooks';
import MuIcon from '../../../../Components/MuIcon';

type Props = {
    onClose: () => void;
};

const OnboardingModal: React.FC<Props> = ({ onClose }) => {
    const me = useMe();
    const { t } = useTranslation();
    const organization = useOrganization(me?.contributorOf?.[0] || me?.organizations[0]?.id);

    const [currentPage, setCurrentPage] = useState(0);

    const dispatch = useAppDispatch();

    const pages = [
        <>
            <ModalBody className="mu-onboarding-modal__body">
                <h2 className="mu-onboarding-modal__body--title">
                    <Trans
                        i18nKey="modals.onboarding.1.title"
                        values={{
                            name: me?.name,
                        }}
                        components={{
                            1: <span className="mu-onboarding-modal__body--name" />,
                        }}
                    />
                </h2>
                <p>
                    <Trans
                        i18nKey="modals.onboarding.1.desc"
                        components={{
                            1: <span className="mu-onboarding-modal__body--bold" />,
                        }}
                    />
                </p>
                <div className="mu-onboarding-modal__body--message-container">
                    <img
                        className="mu-onboarding-modal__body--bordered-image"
                        alt="bordered-image"
                        src="https://mymushinpics.blob.core.windows.net/pics/users/marinemushin_x_leroy_merlin/avatar/1644241095202_marinemushin_x_leroy_merlin_fd95e222-5f13-417a-ab06-5c73f2bb0ceb.png"
                    />
                    <p className="mu-onboarding-modal__body--message-title">{t('modals.onboarding.1.messageTitle')}</p>
                    <p className="mu-onboarding-modal__body--message-desc">{t('modals.onboarding.1.messageDesc')}</p>
                    {organization?.logo_src && (
                        <img
                            className="mu-onboarding-modal__body--organization-image"
                            alt="organization-logo"
                            src={organization?.logo_src}
                        />
                    )}
                </div>
            </ModalBody>
        </>,
        <>
            <ModalBody className="mu-onboarding-modal__body">
                <img
                    className="mu-onboarding-modal__body--onboarding-image"
                    alt="onboarding-image"
                    src="/assets/images/onboarding2.png"
                />
                <span className="mu-onboarding-modal__body--idea-icon">
                    <MuIcon svgName="Idea" />
                </span>
                <p className="mu-onboarding-modal__body--message-2">
                    <Trans
                        i18nKey="modals.onboarding.2"
                        components={{
                            1: <span className="mu-onboarding-modal__body--bold" />,
                        }}
                    />
                </p>
            </ModalBody>
        </>
    ];

    const isLastPage = currentPage === pages.length - 1;

    const leaveOnboarding = () => {
        if (!me) {
            onClose();
            return;
        }
        dispatch(updateMe({
            tutorial: {
                ...me?.tutorial,
                onboarding: true,
            },
        }));
        onClose();
    };
    
    return (
        <Modal>
            {pages[currentPage]}
            <ModalFooter className="mu-onboarding-modal__footer">
                <MuButton
                    handleClick={async () => {
                        if (isLastPage) {
                            leaveOnboarding();
                        } else {
                            setCurrentPage((prevPage) => prevPage + 1);
                        }
                    }}
                    label={isLastPage ? `${t('global.start')} \uD83D\uDE80` : t('global.continue')}
                    classModifiers={isLastPage ? 'important' : ''}
                />
            </ModalFooter>
        </Modal>

    );
};

export default OnboardingModal;
