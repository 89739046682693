import React, {useEffect, useState} from 'react';
import {
    ProjectsQueryParams, useCurrentOrganizationId,
    useMe,
    useProjectsQuery,
} from 'mushin-redux-store';
import {useSearchParams} from '../../Helpers/queryParser';
import ProjectsToolbar, {ProjectsSearchParams} from './ProjectsToolbar';
import ProjectsList from './ProjectsList';
import {statusToParams} from '../../Helpers/status';

const searchToQueryParams = (
    searchParams: ProjectsSearchParams, organizationId: string | undefined,
): ProjectsQueryParams => {
    const {
        status,
        ...queryParams
    } = searchParams;

    return {
        organization_id: organizationId,
        ...queryParams,
        ...statusToParams(status),
    };
};

const Projects: React.FC = () => {
    const me = useMe();
    const [currentOrganizationId] = useCurrentOrganizationId();
    const [searchParams, setSearchParams] = useSearchParams({ owners: 'array' });
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const projectsQuery = useProjectsQuery({
        ...searchToQueryParams(searchParams, currentOrganizationId),
    }, !Object.keys(searchParams).length);

    const {setParams} = projectsQuery;
    useEffect(() => {
        setParams(searchToQueryParams(searchParams, currentOrganizationId), !Object.keys(searchParams).length);
    }, [currentOrganizationId, searchParams, setParams]);

    if (!me) return null;

    return (
        <>
            <ProjectsToolbar
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                categories={projectsQuery.aggregations?.categories || []}
                selectedIds={selectedIds}
            />
            <ProjectsList
                results={projectsQuery.results}
                getMoreResults={projectsQuery.getMoreResults}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                totalItems={projectsQuery.pager?.total_items}
            />
        </>
    );
};

export default Projects;
