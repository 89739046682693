import React from 'react';
import ShimmerLine from './ShimmerLine';

type Props = {
    rows?: number;
    lastSize?: 'full' | '80' | '60' | '40' | '20' | '10';
};

const ShimmerParagraph: React.FC<Props> = ({ rows = 4, lastSize = '80' }) => (
    <div className="shimmer-paragraph">
        {Array.from({ length: rows - 1 }, (_, i) => (
            <ShimmerLine key={i} />
        ))}
        <ShimmerLine width={lastSize} />
    </div>
);

export default ShimmerParagraph;
