import React from 'react';
import i18n from 'i18next';
import {classWithModifiers} from '../../../../Helpers/css';

type Props = {
    type: string | undefined;
    setType: (type: string | undefined) => void;
}

const NoteTypeDropdownPanel: React.FC<Props> = (
    {type, setType}
) => (
    <>
        <button
            className={classWithModifiers('mu-dropdown-menu__button-item', {selected: !type})}
            type="button"
            onClick={() => setType(undefined)}
        >
            {i18n.t('tooltips.all')}
        </button>
        <button
            className={classWithModifiers(
                'mu-dropdown-menu__button-item', {selected: type === 'MOODBOARD'}
            )}
            type="button"
            onClick={() => setType('MOODBOARD')}
        >
            {i18n.t('components.filters.moodboard')}
        </button>
        <button
            className={classWithModifiers(
                'mu-dropdown-menu__button-item', {selected: type === 'PHOTO'}
            )}
            type="button"
            onClick={() => setType('PHOTO')}
        >
            {i18n.t('components.filters.picture')}
        </button>
        <button
            className={classWithModifiers(
                'mu-dropdown-menu__button-item', {selected: type === 'TEXT'}
            )}
            type="button"
            onClick={() => setType('TEXT')}
        >
            {i18n.t('global.text')}
        </button>
    </>
);

export default NoteTypeDropdownPanel;
