import React from 'react';
import {Note, useTimelineReadCount} from 'mushin-redux-store';
import NoteThumbnail from '../NoteThumbnail/NoteThumbnail';
import NoteReaction from '../Reaction/NoteReaction';
import NotesJustifiedGridItemActions from './NotesJustifiedGridItemActions';
import LinkOrButton from '../LinkOrButton/LinkOrButton';
import CountNotifications from '../CountNotifications/CountNotifications';
import MuIcon from '../MuIcon';
import {useParams} from 'react-router-dom';
import {GalleryParams} from '../../Scenes/Gallery/types';

type Props = {
    note: Note;
    isSelected?: boolean;
    onSelect?: (e?: React.MouseEvent<HTMLDivElement>) => void;
    onClick?: string | (() => void);
    showItemActions?: boolean;
    showItemInteractions?: boolean;
    showItemInfos?: boolean;
    showItemFavorite?: boolean;
    noteIdsUnread?: string[];
}

const NotesJustifiedGridItem: React.FC<Props> = (
    {
        note,
        isSelected,
        onSelect,
        onClick,
        showItemActions = false,
        showItemInteractions = false,
        showItemInfos = false,
        showItemFavorite = false,
        noteIdsUnread,
    }
) => {
    const params = useParams<GalleryParams>();
    const unreadCount = useTimelineReadCount({ is_contributor: true }, {
        projectId: note.project_id,
        albumId: params.album_id,
        questionnaireId: note.questionnaire_id,
        chatId: note.chat ? note.chat.id : 'unknown',
    });

    return (
        <NoteThumbnail
            note={note}
            displayAsSquare={false}
            isNew={noteIdsUnread?.includes(note.id) || !!unreadCount}
            isSelected={isSelected}
            onSelect={onSelect}
            onClick={onClick}
            footerComponents={showItemInteractions && [(
                <div key="reactions" className="mu-note__reactions">
                    <NoteReaction
                        name="all"
                        note={note}
                        horizontal
                    />
                </div>
            ), (
                <LinkOrButton
                    key="comments"
                    onClick={onClick}
                    className="mu-note__comments"
                >
                    <CountNotifications nb={!!unreadCount}>
                        <MuIcon
                            className="mu-note__comments-icon"
                            svgName="Comment"
                        />
                    </CountNotifications>
                    <span className="mu-note__comments-total">
                        {note.chat?.nb_messages || 0}
                    </span>
                </LinkOrButton>
            )]}
            MoreOptionsDropDown={showItemActions && (
                <NotesJustifiedGridItemActions
                    note={note}
                />
            )}
            showInfos={showItemInfos}
            showFavorite={showItemFavorite}
        />
    );
};

export default NotesJustifiedGridItem;
