import React from 'react';
import ShimmerParagraph from './ShimmerParagraph';

type Props = {
    paragraphs?: number;
};

const lineSizes = ['80', '60', '40', '20', '10'] as const;
const minLines = 3;
const maxLines = 5;

const ShimmerChapter: React.FC<Props> = ({ paragraphs = 3 }) => (
    <>
        {Array.from({ length: paragraphs }, (_, i) => (
            <ShimmerParagraph
                key={i}
                rows={Math.floor(Math.random() * (maxLines - minLines + 1) + minLines)}
                lastSize={lineSizes[Math.floor(Math.random() * lineSizes.length)]}
            />
        ))}
    </>
);

export default ShimmerChapter;
