import React from 'react';
import {useMember} from 'mushin-redux-store';
import {classWithModifiers} from '../../Helpers/css';
import MuIcon from '../MuIcon';

type UserAvatarProps = {
    userId: string;
    size?: 'small' | 'large';
    className?: string;
};
const UserAvatar: React.FC<UserAvatarProps> = ({
    userId,
    size,
    className,
}: UserAvatarProps) => {
    const user = useMember(userId);

    if (!user) return null;
    return (
        <div className={classWithModifiers('mu-user-avatar', null, className)}>
            <img
                src={user.avatar_url || '/assets/images/defaultAvatar.png'}
                alt={user.mention_slug}
                className={`${classWithModifiers('mu-user-avatar__img', [size])}`}
            />
            {user.type === 'GROUP' && (
                <MuIcon
                    className={`${classWithModifiers('mu-user-avatar__group', [size])}`}
                    svgName="Group"
                />
            )}
        </div>
    );
};

export default UserAvatar;
