import React from 'react';
import {useQuestionnaire} from 'mushin-redux-store';
import {Link} from 'react-router-dom';
import moment from 'moment';
import i18n from 'i18next';
import MuIcon from '../MuIcon';
import {
    getQuestionnaireBaseUrl,
    getQuestionnaireDuration,
    hasStartedQuestionnaire,
} from '../../Helpers/questionnaires';
import QuestionnaireItemDropdown from './QuestionnaireItemDropdown';
import {BadgeComponent} from '../Badge/BadgeComponent';
import {getStatus} from '../../Helpers/date';
import TextTranslation from '../Translation/TextTranslation';

type Props = {
    id: string;
    isContributor?: boolean;
}

const SurveyItem: React.FC<Props> = ({id, isContributor = false}) => {
    const questionnaire = useQuestionnaire(id);

    if (!questionnaire) return null;

    const getUrl = () => {
        const questionnaireUrl = getQuestionnaireBaseUrl(questionnaire, questionnaire.project_id);
        if (isContributor) {
            if (!questionnaire.answered) {
                return questionnaireUrl;
            }
        } else if (!hasStartedQuestionnaire(questionnaire)) {
            return `${questionnaireUrl}/edit`;
        }

        return `${questionnaireUrl}/respondents`;
    };

    const answerCount = questionnaire.respondent_count;

    return (
        <Link to={getUrl()} className="mu-survey-item">
            <h3 className="mu-survey-item__col mu-survey-item__col--large">
                <span className="mu-survey-item__label mu-survey-item__title">
                    <TextTranslation translationProp={questionnaire.titles} fallback={questionnaire.title} />
                </span>
            </h3>
            <div className="mu-survey-item__col">
                <MuIcon svgName="Survey" className="mu-survey-item__icon" />
                <span className="mu-survey-item__label">
                    {i18n.t('questionnairesList.question', {count: questionnaire.questions.length})}
                </span>
            </div>
            {isContributor ? (
                <div className="mu-survey-item__col">
                    <MuIcon svgName="Stopwatch" className="mu-survey-item__icon" />
                    <span className="mu-survey-item__label">
                        {moment.duration(getQuestionnaireDuration(questionnaire), 'seconds').humanize()}
                    </span>
                </div>
            ) : (
                <div className="mu-survey-item__col">
                    <MuIcon svgName="Group" className="mu-survey-item__icon" />
                    <span className="mu-survey-item__label">
                        {i18n.t('global.participant', {count: answerCount})}
                    </span>
                </div>
            )}
            <div className="mu-survey-item__col mu-survey-item__col--small">
                <BadgeComponent
                    className="mu-survey-item__status"
                    status={getStatus(questionnaire.start_date, questionnaire.end_date, undefined)}
                />
            </div>
            <QuestionnaireItemDropdown 
                className="mu-survey-item__menu"
                questionnaire={questionnaire}
                hasSeeAnswersOption
            />
        </Link>
    );
};

export default SurveyItem;
