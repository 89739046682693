import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useCurrentOrganizationId, useCurrentProjectId, useMe } from 'mushin-redux-store';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import GalleryNotes from './Components/GalleryNotes/GalleryNotes';
import { openModal } from '../../Redux/reducers/modalsSlice';
import { InspirationsParams } from './types';
import GalleryMessages from './Components/GalleryMessages/GalleryMessages';
import AppLayout from '../../Components/Layouts/AppLayout';
import Select from '../../Components/Select/Select';
import useNotesQueryWithSearchParams from './Components/GalleryNotes/useNotesQueryWithSearchParams';

const InspirationsGallery: React.FC = () => {
    const me = useMe();
    const [currentOrganizationId] = useCurrentOrganizationId();
    const [currentProjectId] = useCurrentProjectId();

    const { inspirationType, organizationId, projectId } = useParams<InspirationsParams>();

    const [query, searchParams, setSearchParams] = useNotesQueryWithSearchParams();

    const dispatch = useDispatch();

    if (!me) return null;

    if (inspirationType === 'mine' && !me.tutorial.privateGallery) {
        dispatch(openModal('Tutorial', { name: 'privateGallery' }));
    }

    const currentOrganizationUrl = `/organizations/${currentOrganizationId}/galleries/${inspirationType}`;
    const currentProjectUrl = `/projects/${currentProjectId}/galleries/${inspirationType}`;

    const getUrl = (type: InspirationsParams['inspirationType']) => {
        if (projectId) return `/projects/${projectId}/galleries/${type}`;
        return `/organizations/${organizationId}/galleries/${type}`;
    };

    return (
        <AppLayout
            title={
                <>
                    {i18n.t('global.inspirations')}
                    {currentProjectId && (
                        <Select
                            className="mu-gallery__title-select"
                            value={projectId ? i18n.t('projects.current') : i18n.t('projects.all')}
                        >
                            <Link to={currentProjectUrl} className="mu-dropdown-menu__button-item">
                                {i18n.t('projects.current')}
                            </Link>
                            <Link to={currentOrganizationUrl} className="mu-dropdown-menu__button-item">
                                {i18n.t('projects.all')}
                            </Link>
                        </Select>
                    )}
                </>
            }
            tabs={[
                {
                    key: 'all',
                    to: getUrl('all'),
                    label: i18n.t('album.ideas.0'),
                    active: inspirationType === 'all',
                },
                {
                    key: 'messages',
                    to: getUrl('messages'),
                    label: i18n.t('global.messages'),
                    active: inspirationType === 'messages',
                },
                {
                    key: 'mine',
                    to: getUrl('mine'),
                    label: i18n.t('global.gallery'),
                    active: inspirationType === 'mine',
                },
            ]}
        >
            {inspirationType === 'messages' ? (
                <GalleryMessages />
            ) : (
                <GalleryNotes query={query} searchParams={searchParams} setSearchParams={setSearchParams} />
            )}
        </AppLayout>
    );
};

export default InspirationsGallery;
