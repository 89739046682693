import React from 'react';
import { ActionProps, ActionBarMuIconButton, ActionBarSelect, ActionBarColorPicker, ActionBarSimpleSelect, BackgroundPickerButton } from './common';
import Icon from '../../../../../_Old_Components/Icon';

export const ToggleTextButton = ({
    content,
    setContent,
}: ActionProps) => (
    <ActionBarMuIconButton
        onClick={() => {
            setContent({
                ...content,
                type: content.type === 'image' ? 'mixed' : 'image',
                split: 0.6,
                position: 'bottom',
            });
        }}
        svgName={content.type === 'image' ? 'TextLetter' : 'TextLetterOverlined'}
    />
);

const fontOptions = ['Arial', 'Helvetica', 'Monospace', 'Proxima Nova', 'Amatic SC', 'Fauna One', 'Fjalla One', 'Josefin Sans', 'Libre Baskerville', 'Nixie One', 'Nunito', 'Oswald', 'Playfair Display', 'Raleway', 'Roboto', 'Space Mono', 'Volkhov'].map((font) => ({ value: font, label: font }));

export const FontSelectButton = ({
    content,
    setContent,
}: ActionProps) => <ActionBarSelect classModifiers={['font']} formatSingleValue={(state) => (state.hasValue ? { fontFamily: state.getValue()[0].value } : {})} formatOption={(state) => ({ fontFamily: state.value })} onChange={(opt) => setContent({ ...content, text_font: opt.value })} value={fontOptions.find((o) => o.value === content.text_font)} options={fontOptions} isSearchable={false} />;

const fontSizeOptions = ['2', '3', '5', '8', '12', '20', '32'].map((font) => ({ value: font, label: font }));

export const FontSizeSelectButton = ({
    content,
    setContent,
}: ActionProps) => (
    <ActionBarSelect
        classModifiers={['fontSize']}
        onChange={(opt) => setContent({ ...content, text_size: opt.value })}
        value={fontSizeOptions.find((o) => o.value === content.text_size)}
        options={fontSizeOptions}
        isSearchable={false}
    />
);

const hAlignOptions = [{ value: 'left', label: <Icon color="white" icon="text-align-left" /> }, { value: 'center', label: <Icon color="white" icon="text-align-center" /> }, { value: 'right', label: <Icon color="white" icon="text-align-right" /> }, { value: 'justify', label: <Icon color="white" icon="text-align-justify" /> }];

export const HorizontalAlignSelectButton = ({
    content,
    setContent,
}: ActionProps) => <ActionBarSimpleSelect classModifiers={['hAlign']} onChange={(opt) => setContent({ ...content, text_align: opt.value })} value={hAlignOptions.find((o) => o.value === (content.text_align || 'justify'))} options={hAlignOptions} isSearchable={false} />;

const vAlignOptions = [{ value: 'top', label: <Icon color="white" icon="align-top" /> }, { value: 'vcenter', label: <Icon color="white" icon="align-vcenter" /> }, { value: 'bottom', label: <Icon color="white" icon="align-bottom" /> }];

export const VerticalAlignSelectButton = ({
    content,
    setContent,
}: ActionProps) => <ActionBarSimpleSelect classModifiers={['vAlign']} onChange={(opt) => setContent({ ...content, vertical_align: opt.value })} value={vAlignOptions.find((o) => o.value === (content.vertical_align || 'top'))} options={vAlignOptions} isSearchable={false} />;

const fontColors = ['#FFFFFF', '#000000', '#FF9292', '#FFB086', '#FCE285', '#84B9DE', '#62C299', '#8ED3E1', '#8E9EE1', '#B68EE1'];

export const FontColorPickerButton = ({
    content,
    setContent,
}: ActionProps) => (
    <ActionBarColorPicker buttonStyle={{ backgroundColor: 'gray', color: content.text_color, fontWeight: 1000 }} colors={fontColors} color={content.text_color} onChangeComplete={(color) => setContent({ ...content, text_color: color.hex })}>
        Abc
    </ActionBarColorPicker>
);

export const EditTextButton = ({
    setMode,
}: ActionProps) => <ActionBarMuIconButton onClick={() => setMode('textEdit')} svgName="Edit" />;

export const textButtons = (props: ActionProps) => [<FontSelectButton key="FontSelectButton" {...props} />, <FontSizeSelectButton key="FontSizeSelectButton" {...props} />, <HorizontalAlignSelectButton key="HorizontalAlignSelectButton" {...props} />, <VerticalAlignSelectButton key="VerticalAlignSelectButton" {...props} />, <FontColorPickerButton key="FontColorPickerButton" {...props} />, <BackgroundPickerButton key="BackgroundPickerButton" {...props} />, <EditTextButton key="EditTextButton" {...props} />];
