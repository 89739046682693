import React, {useState} from 'react';
import moment from 'moment';
import i18n from 'i18next';
import ChooseTimeSlot from './Components/ChooseTimeSlot';
import MuButton from '../../../MuButton';

type Props = {
    minDate: string | undefined;
    maxDate: string | undefined;
    setDateRange: (minDate: string | undefined, maxDate: string | undefined) => void;
}

const DateRangeDropdownPanel: React.FC<Props> = ({minDate, maxDate, setDateRange}) => {
    const [selectedMinDate, setSelectedMinDate] = useState(minDate ? moment.unix(Number(minDate)) : null);
    const [selectedMaxDate, setSelectedMaxDate] = useState(maxDate ? moment.unix(Number(maxDate)) : null);

    return (
        <div className="mu-filters-toolbar__panel-body">
            <ChooseTimeSlot
                beginDate={selectedMinDate}
                handleChangeBeginDate={setSelectedMinDate}
                endingDate={selectedMaxDate}
                handleChangeEndingDate={setSelectedMaxDate}
                contrast
            />
            <MuButton
                className="mu-filters-toolbar__panel-submit"
                handleClick={
                    () => setDateRange(
                        selectedMinDate ? `${selectedMinDate.unix()}` : undefined,
                        selectedMaxDate ? `${selectedMaxDate.unix()}` : undefined,
                    )
                }
                classModifiers={['full-width']}
                label={i18n.t('components.filters.dropdown.apply')}
            />
        </div>
    );
};

export default DateRangeDropdownPanel;
