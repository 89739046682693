import React from 'react';
import { useOrganization } from 'mushin-redux-store';
import { classWithModifiers } from '../../Helpers/css';
import Popover from '../Popover/Popover';

type Props = {
    organizationId: string;
    isSelected?: boolean;
};
export const OrganizationAvatar: React.FC<Props> = ({
    organizationId,
    isSelected = false,
}: Props) => {
    const organization = useOrganization(organizationId);

    if (!organization) return null;
    return (
        <Popover
            position="bottom"
            hoveredElement={(
                <div
                    className={classWithModifiers(
                        'mu-bordered-rounded-picture',
                        {'is-selected': isSelected, placeholder: !organization.logo_src, large: true}
                    )}
                >
                    {organization.logo_src ? (
                        <img
                            src={organization.logo_src}
                            alt={organization.name}
                            className="mu-bordered-rounded-picture__img"
                        />
                    ) : organization.name}
                </div>
            )}
        >
            {organization.name}
        </Popover>
    );
};
