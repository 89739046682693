import {Note} from 'mushin-redux-store';
import {Board, Content, TemplateContent, TextBlock} from 'mushin-node-moodboard';
import {BoardContents, Template} from '../Components/BoardComponents/BoardLayout/type';

export const noteToContent = (note: Note, templateContent: TemplateContent | null, content?: Content | null): Content => {
    if (note.type === 'TEXT') {
        const contentText = content?.type === 'text' ? content : undefined;
        return {
            type: 'text',
            note_id: note.id,
            text: [{ style: 'bold', text: `${note.label}` }],
            background: '#fe6f63',
            text_font: contentText?.text_font || 'Helvetica',
            text_size: contentText?.text_size || '6',
            text_align: contentText?.text_align || 'center',
            text_color: contentText?.text_color || '#ffffff',
            vertical_align: contentText?.vertical_align || 'vcenter',
        };
    }

    const contentMixed = content?.type === 'mixed' ? content : undefined;
    return {
        type: content?.type || templateContent?.type || 'image',
        split: contentMixed?.split || templateContent?.split || 0,
        position: contentMixed?.position || templateContent?.position || 'bottom',
        note_id: note.id,
        image_url: note.image_src.full,
        image_crop: {
            scale: 1,
            focusPoint: { x: 0.5, y: 0.5 },
            filling: 'cover',
        },
        image_rotation: 0,
        background: content?.background || 'transparent',
        text_font: contentMixed?.text_font || 'Helvetica',
        text_size: contentMixed?.text_size || '4',
        text_align: contentMixed?.text_align || 'left',
        text_color: contentMixed?.text_color || '#000000',
        text: [{ style: 'bold', text: `${note.label}` }, '\n', note.content_string],
    } as Content;
};

const fillWithPrevious = (template: Template, board: Board, initialContents: BoardContents = {}) => {
    const availableContents = board.ids.map((id) => board.contents[id]);
    const contents = { ...initialContents };

    template.ids.forEach((id) => {
        if (!contents[id]) {
            const templateContent = template.contents[id];

            if (templateContent) {
                // find an old content which match
                let index = 0;
                if (templateContent.type === 'mixed') {
                    for (const [idx, content] of availableContents.entries()) {
                        if (content?.type === 'mixed') {
                            if (content.position === templateContent.position) {
                                index = idx;
                                break;
                            }
                            if (index === 0) index = idx;
                        }
                    }
                }

                const oldContent = availableContents.splice(index, 1)[0];

                if (oldContent) {
                    contents[id] = {
                        ...oldContent,
                        ...templateContent,
                        type: oldContent.type === 'text' ? 'text' : templateContent.type,
                    };
                }
            }
        }
    });

    return contents;
};

const fillWithNote = (template: Template, notes: Note[], initialContents: BoardContents) => {
    const contents = { ...initialContents };

    const availableNotes = notes.filter((note) => !Object.keys(contents).some((id) => {
        const content = contents[id];
        if (content && (content.type === 'text' || content.type === 'image')) return content.note_id === note.id;
        return false;
    }));

    template.ids.filter((id) => !contents[id]).forEach((id) => {
        const note = availableNotes.shift();

        if (!note) return;

        contents[id] = noteToContent(note, template.contents[id]);
    });

    return contents;
};

const isTitle = ({contents}: Board, id: string) => contents[id]?.type === 'text' && id === 'title';

// given a board and a template, generate the best content for the template
export const adaptContentForTemplate = (
    template: Template,
    notePool: Note[] | null,
    previousBoard: Board | null | undefined,
    title?: string | null | undefined,
): Board => {
    const newTitle = title || (previousBoard && previousBoard.title) || '';

    let contents: BoardContents = {};
    const cells = { ...template.cells };
    let titleTextBlocks: TextBlock[] | null = [{ text: newTitle }];

    if (previousBoard) {
        // Here we first retrieve the content of type text, then ids, then cells, to put it back in board
        // Unlike other data, we want to keep it at the same place when template is changed

        previousBoard.ids
            .filter((id) => { // keep only content with text. Exclude the title ( to avoid duplication )
                const content = previousBoard.contents[id];
                if (!content) return false;

                return (content.type === 'text' && !content.note_id) || content.type === 'shape';
            }) // relay the cells to the new content. Rename id to prevent conflict
            .forEach((previousId) => {
                const id = previousId === 'title' ? previousId : `x${previousId}`;
                cells[id] = previousBoard.cells[previousId];
                contents[id] = previousBoard.contents[previousId];
            });

        contents = {
            ...contents,
            ...fillWithPrevious(template, previousBoard, contents),
        };

        // prepare the title

        const haveTitle = previousBoard.ids.some((id) => isTitle(previousBoard, id));

        // if the title is still here, don't regenerate it
        if (haveTitle) titleTextBlocks = null;
    }

    if (notePool) {
        contents = {
            ...contents,
            ...fillWithNote(template, notePool, contents),
        };
    }

    // add the title as text cell
    if (titleTextBlocks) {
        const id = 'title';
        cells[id] = {
            min: { x: template.margin, y: 4 },
            max: { x: template.width - template.margin, y: 16 },
        };
        contents[id] = {
            type: 'text',
            text: titleTextBlocks,
            text_font: 'Proxima Nova',
            text_size: '10',
            text_align: 'center',
            text_color: '#333',
            background: 'transparent',
        };
    }

    return {
        width: template.width,
        height: template.height,
        margin: template.margin,
        cells,
        ids: Object.keys(cells),
        title: newTitle,
        contents,
    };
};
