import { Handler } from './type';

import { hitExpandedBoxes } from '../layoutUtils';

import { MARGIN } from './scale/index';

export const onTouchMove: Handler = ({
    state,
    board,
    mode,
    selectedCell,
}, pointers) => {
    let hit = (!state.dragging || state.dragging === 'scale') // && mode === 'layout'
  && pointers[0] && hitExpandedBoxes(board.ids.map((id) => ({ id, ...board.cells[id] })), MARGIN / 2, pointers[0]);

    if (hit && hit.id && hit.id === selectedCell) {
        hit = false;
    }

    return {
        state: {
            ...state,
            hoveredCell: hit && hit.id,
        },
    };
};
