import React, {Dispatch, SetStateAction, useState} from 'react';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import TextIcon from '../TextIcon/TextIcon';

type Props = {
    icon: string;
    label: React.ReactNode;
    handler: (setProgress: Dispatch<SetStateAction<number>>) => Promise<void>;
}

const MenuProgressAction: React.FC<Props> = ({ icon, label, handler }) => {
    const [progress, setProgress] = useState(-1);

    const handleExport = async () => {
        await handler(setProgress);
        setProgress(-1);
    };

    return (
        <li className="mu-dropdown-menu__button-item">
            <button onClick={progress < 0 ? handleExport : undefined} type="button" disabled={progress > -1}>
                <TextIcon icon={icon} label={label} />
            </button>
            {progress > -1 && (
                <CircularProgressbar
                    className="mu-dropdown-menu__progress"
                    value={progress}
                    maxValue={100}
                    strokeWidth={12}
                    styles={buildStyles({
                        // Rotation of path and trail, in number of turns (0-1)
                        rotation: 0.3,

                        // Colors
                        pathColor: '#62A1FF',
                        trailColor: '#dadada',
                        backgroundColor: '#3B434F',
                    })}
                />
            )}
        </li>
    );
};

export default MenuProgressAction;
