import React, {useEffect, useState} from 'react';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import { classWithModifiers } from '../../Helpers/css';
import MuIcon from '../MuIcon';
import Spinner from '../Spinner';

type Props = {
    message?: string;
    progress?: number;
    close?: () => void;
    delayBeforeClose?: number;
    title?: string;
    options?: {
        type?: 'success' | 'error' | 'info' | 'action';
        icon?: string;
    };
    handleAction?: null | (() => void);
    labelAction?: string;
};

export const opts = {
    position: 'bottom right',
    timeout: 5000,
    transition: 'scale',
};

const icons = {
    success: 'Check',
    error: 'Warning',
    info: 'NotificationLine',
};

const NotifBackgroundTask: React.FC<Props> = ({
    title,
    message,
    progress,
    close,
    delayBeforeClose,
    options,
    handleAction,
    labelAction,
}) => {
    const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (close && delayBeforeClose) {
            setCloseTimeout(setTimeout(close, delayBeforeClose));
        }

        return () => setCloseTimeout((prevTimeout) => {
            if (prevTimeout) clearTimeout(prevTimeout);
            return null;
        });
    }, [close, delayBeforeClose]);

    let icon: string | undefined;
    if (options) {
        if (options.icon) {
            icon = options.icon;
        } else if (options.type && options.type !== 'action') {
            icon = icons[options.type];
        }
    }

    return (
        <article
            className={classWithModifiers('mu-notif-bckg-task', [options?.type])}
            onMouseOver={() => {
                if (closeTimeout) clearTimeout(closeTimeout);
                if (close && delayBeforeClose) {
                    setCloseTimeout(setTimeout(close, delayBeforeClose));
                }
            }}
        >
            <header className="mu-notif-bckg-task__header">
                {progress !== undefined && (
                    progress === 0 ? (
                        <Spinner size="3rem" />
                    ) : (
                        <CircularProgressbar
                            className="mu-notif-bckg-task__progress"
                            value={progress}
                            maxValue={1}
                            strokeWidth={7}
                            background
                            styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                rotation: 0.3,

                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,

                                // Can specify path transition in more detail, or remove it entirely
                                // pathTransition: 'none',

                                // Colors
                                pathColor: '#fe6f63',
                                trailColor: '#ffffff',
                                backgroundColor: 'transparent',
                            })}
                        />
                    )
                )}
                {icon && (<MuIcon className="mu-notif-bckg-task__icon" svgName={icon} />)}
                <div className="mu-notif-bckg-task__infos">
                    <h2 className="mu-notif-bckg-task__title">{title}</h2>
                    <p className="mu-notif-bckg-task__desc">{message}</p>
                </div>
                {handleAction && (
                    <button type="button" onClick={handleAction} className="mu-notif-bckg-task__action">
                        {labelAction}
                    </button>
                )}

                {close && (
                    <button type="button" onClick={close} className="mu-notif-bckg-task__action">
                        <MuIcon svgName="Close" />
                    </button>
                )}
            </header>
        </article>
    );
};

export default NotifBackgroundTask;
