import React, {useEffect} from 'react';
import {
    markTimelineAsSeen, useCurrentProject,
    User,
    useTimelineQuery
} from 'mushin-redux-store';
import {useDispatch} from 'react-redux';
import i18n from 'i18next';
import {useLocation, useParams} from 'react-router-dom';
import Activity from '../../Components/Activity/Activity';
import Client from '../../Services/Client';
import {useScrollThreshold} from '../../Helpers/effects';
import AppLayout from '../../Components/Layouts/AppLayout';
import {isContributor} from '../../Helpers/users';
import Spinner from '../../Components/Spinner';
import EmptyZone from '../../Components/EmptyZone/EmptyZone';

const Activities: React.FC = () => {
    const { projectId } = useParams<{projectId: string}>();
    const project = useCurrentProject();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const collective = !!params.get('collective');
    const filterContributors = !!params.get('is_contributor');
    const userId = (Client.getUser() as User).id;

    const timelineQuery = useTimelineQuery({
        userId,
        project_id: projectId,
        collective,
        is_contributor: filterContributors,
    }, true);

    if (timelineQuery.params.collective !== collective || timelineQuery.params.is_contributor !== filterContributors) {
        timelineQuery.setParams({
            userId,
            project_id: projectId,
            collective,
            is_contributor: filterContributors,
        }, true);
    }

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            if (!timelineQuery.loading) {
                dispatch(markTimelineAsSeen({ project_id: projectId }));
            }
        };
    }, [dispatch, projectId, timelineQuery.loading]);

    const ref = useScrollThreshold<HTMLDivElement>(
        timelineQuery.getMoreResults, { deps: [timelineQuery.results?.length] }
    );

    const userIsContributor = isContributor(project);

    return (
        <AppLayout
            title={i18n.t('global.activities')}
            tabs={userIsContributor ? undefined : [{
                key: 'mine',
                to: `/projects/${projectId}/activities`,
                label: i18n.t<string>('global.myActivities'),
                active: !collective && !filterContributors,
            }, {
                key: 'contributors',
                to: `/projects/${projectId}/activities?is_contributor=1`,
                label: i18n.t<string>('global.contributorsActivities'),
                active: filterContributors,
            }, {
                key: 'other',
                to: `/projects/${projectId}/activities?collective=1`,
                label: i18n.t<string>('global.allActivities'),
                active: collective && !filterContributors,
            }]}
        >
            <div ref={ref} className="mu-app-layout__scroll mu-activities">
                <div className="mu-activities__list">
                    {timelineQuery.results?.length ? (
                        timelineQuery.results?.map((id) => (
                            <Activity
                                key={id}
                                id={id}
                            />
                        ))
                    ) : !timelineQuery.loading && (
                        <EmptyZone
                            urlImg="/assets/images/add-files.svg"
                            title={i18n.t('notifications.empty')}
                        />
                    )}
                    {timelineQuery.loading && (
                        <Spinner className="mu-activities__loading" size="40px" />
                    )}
                </div>
            </div>
        </AppLayout>
    );
};

export default Activities;
