import React, {ReactNode} from 'react';
import { classWithModifiers } from '../../Helpers/css';

type Props = {
  handleClick?: () => void;
  title?: ReactNode | string;
  subTitle?: ReactNode | string;
  urlImg?: string;
};

const EmptyZone: React.FC<Props> = ({
    handleClick,
    title,
    subTitle,
    urlImg = '/assets/images/add-files.svg',
}) => (
    <div className="mu-empty-zone">
        <div
            onClick={handleClick}
            className={classWithModifiers('mu-empty-zone__content', { clickable: !!handleClick })}
        >
            <img src={urlImg} alt="empty zone" className="mu-empty-zone__img" />
            {title && (
                <h1 className="mu-empty-zone__title">
                    {title}
                </h1>
            )}
            {subTitle && (
                <p className="mu-empty-zone__desc">
                    {subTitle}
                </p>
            )}
        </div>
    </div>
);

export default EmptyZone;
