import React from 'react';
import {Board, Cell} from 'mushin-node-moodboard';
import {Camera} from '../../type';

const computePosition = (camera: Camera, cell: Cell, ratio: number) => ({
    position: 'absolute' as const,
    top: `${(cell.min.y * camera.a + camera.b.y) * ratio}px`,
    left: `${(cell.min.x * camera.a + camera.b.x) * ratio}px`,
    width: `${(cell.max.x - cell.min.x) * camera.a * ratio}px`,
    height: `${(cell.max.y - cell.min.y) * camera.a * ratio}px`,
});

type Props = {
    camera: Camera;
    board: Board;
    idCell: string;
    ratio: number;
}

export const HoveredCellOverlay: React.FC<Props> = (props) => (
    <div
        className="blHoverCellOverlay"
        style={{
            ...computePosition(props.camera, props.board.cells[props.idCell], props.ratio),
        }}
    >
        <div>
            <div className="blCellOverlay-corner blCellOverlay-corner--hover" />
            <div className="blCellOverlay-corner blCellOverlay-corner--hover" />
            <div className="blCellOverlay-corner blCellOverlay-corner--hover" />
            <div className="blCellOverlay-corner blCellOverlay-corner--hover" />
        </div>
        <div>
            <div className="blCellOverlay-border" />
            <div className="blCellOverlay-border" />
            <div className="blCellOverlay-border" />
            <div className="blCellOverlay-border" />
        </div>
    </div>
);
