import Delta from 'quill-delta';

export const deltaToText = (delta: Delta): string => {
    return delta.ops?.reduce((result, op: any) => {
        if (typeof op.insert === 'string') return result + op.insert;
        if (op.insert?.mention) {
            return (
                result + op.insert.mention.denotationChar + op.insert.mention.value
            ); 
        }
        return result;
    }, '') || '';
};
