import React, { useState } from 'react';
import {
    updateMyNotificationSettings,
    useMe,
    UserNotificationsSettings,
    UserNotificationTypeSettings
} from 'mushin-redux-store';
import {useDispatch} from 'react-redux';
import i18n from 'i18next';
import { NotificationSettings } from './components/NotificationSettings';
import AccountSettingsLayout from '../../../Components/SettingsComponents/AccountSettingsLayout';
import {isMobile} from '../../../Helpers/navigator';

const NotificationsSettings: React.FC = () => {
    const me = useMe();
    const [settings, setSettings] = useState(me?.notification);

    const dispatch = useDispatch();

    if (!settings && me?.notification) setSettings(me.notification);

    if (!me) return null;

    const updateSettings = (key: keyof UserNotificationsSettings, value: UserNotificationTypeSettings): void => {
        setSettings((prevSettings) => (
            prevSettings && {
                ...prevSettings,
                [key]: value,
            }
        ));
        dispatch(updateMyNotificationSettings(key, value));
    };

    const content = !settings ? <h2 className="loading">{i18n.t('global.loading')}</h2> : (
        <table className="settings-table">
            <tbody className="settings-table__tbody">
                <tr>
                    <th className="text-align-left settings-table__th">
                        {i18n.t('accounts.notifications.subtitle_notes')}
                    </th>
                    <th className="settings-table__th">
                        {i18n.t('accounts.notifications.mail')}
                    </th>
                    <th className="settings-table__th">
                        {i18n.t('accounts.notifications.push')}
                    </th>
                </tr>
                <NotificationSettings
                    label={i18n.t('accounts.notifications.params.note_updated')}
                    settingKey="note_updated"
                    value={settings.note_updated}
                    setValue={(value) => updateSettings('note_updated', value)}
                />
                <NotificationSettings
                    label={i18n.t('accounts.notifications.params.note_deleted')}
                    settingKey="note_deleted"
                    value={settings.note_deleted}
                    setValue={(value) => updateSettings('note_deleted', value)}
                />
                <NotificationSettings
                    label={i18n.t('accounts.notifications.params.note_moved')}
                    settingKey="note_moved"
                    value={settings.note_moved}
                    setValue={(value) => updateSettings('note_moved', value)}
                />
            </tbody>
            <tbody className="settings-table__tbody">
                <tr>
                    <th className="text-align-left settings-table__th">
                        {i18n.t('accounts.notifications.subtitle_invitations')}
                    </th>
                    <th className="settings-table__th">
                        {i18n.t('accounts.notifications.mail')}
                    </th>
                    <th className="settings-table__th">
                        {i18n.t('accounts.notifications.push')}
                    </th>
                </tr>
                <NotificationSettings
                    label={i18n.t('accounts.notifications.params.project_shared')}
                    settingKey="project_shared"
                    value={settings.album_shared}
                    setValue={(value) => updateSettings('project_shared', value)}
                />
                <NotificationSettings
                    label={i18n.t('accounts.notifications.params.album_shared')}
                    settingKey="album_shared"
                    value={settings.album_shared}
                    setValue={(value) => updateSettings('album_shared', value)}
                />
                <NotificationSettings
                    label={i18n.t('accounts.notifications.params.mission_reminder')}
                    settingKey="mission_reminder"
                    value={settings.mission_reminder}
                    setValue={(value) => updateSettings('mission_reminder', value)}
                />
            </tbody>
            <tbody className="settings-table__tbody">
                <tr>
                    <th className="text-align-left settings-table__th">
                        {i18n.t('accounts.notifications.subtitle_chats')}
                    </th>
                    <th className="settings-table__th">
                        {i18n.t('accounts.notifications.mail')}
                    </th>
                    <th className="settings-table__th">
                        {i18n.t('accounts.notifications.push')}
                    </th>
                </tr>
                <NotificationSettings
                    label={i18n.t('accounts.notifications.params.user_mentioned')}
                    settingKey="user_mentioned"
                    value={settings.user_mentioned}
                    setValue={(value) => updateSettings('user_mentioned', value)}
                />
                <NotificationSettings
                    label={i18n.t('accounts.notifications.params.new_chat_message')}
                    settingKey="new_chat_message"
                    value={settings.new_chat_message}
                    setValue={(value) => updateSettings('new_chat_message', value)}
                />
                <NotificationSettings
                    label={i18n.t('accounts.notifications.params.new_comment')}
                    settingKey="new_comment"
                    value={settings.new_comment}
                    setValue={(value) => updateSettings('new_comment', value)}
                />
            </tbody>
        </table>
    );

    return isMobile() ? (
        <div className="mu-app-layout__scroll">
            <div className="settings-layout__container">
                {content}
            </div>
        </div>
    ) : (
        <AccountSettingsLayout
            imgUrl={me.avatar_url}
            imgAlt={`${me.name}`}
            subTitle={`${me.name}`}
        >
            <div className="settings-layout__container settings-layout__notifications-settings">
                {content}
            </div>
        </AccountSettingsLayout>
    );
};

export default NotificationsSettings;
