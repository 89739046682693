import React, {
    useMemo,
    CSSProperties,
} from 'react';
import useSize from '@react-hook/size';

const DEFAULT_COLUMNS_COUNT = 1;

type Props = React.PropsWithChildren<{
    /**
     * A Object containing Keys as breakpoints in px and values as the columns count
     *
     * Default Value = { 350: 1, 750: 2, 900: 3 }
     */
    columnsCountBreakPoints?: [number, number][];
    className?: string;
    style?: CSSProperties;
}>

const ResponsiveMasonry: React.FC<Props> = ({
    columnsCountBreakPoints = [[350, 1], [750, 2], [900, 3]],
    children,
    className,
    style,
}) => {
    const target = React.useRef(null);
    const [width] = useSize(target);
    const columnsCount = useMemo(() => {
        const breakPoints = columnsCountBreakPoints.sort(
            (a, b) => a[0] - b[0]
        );
        let count = breakPoints.length > 0
            ? breakPoints[0][1]
            : DEFAULT_COLUMNS_COUNT;

        breakPoints.forEach(([breakPoint, val]) => {
            if (breakPoint < width) {
                count = val;
            }
        });

        return count;
    }, [width, columnsCountBreakPoints]);

    return (
        <div ref={target} className={className} style={style}>
            {React.Children.map(children, (child, index) => React.cloneElement(child, {
                key: index,
                columnsCount,
            }))}
        </div>
    );
};

export default ResponsiveMasonry;
