import React from 'react';

import i18n from 'i18next';

const parseRatio = (ratio) => (Math.abs(ratio - Math.SQRT2) < 0.01 ? 'portrait' : 'landscape');

const formatRatio = (value) => (value === 'portrait' ? Math.SQRT2 : Math.SQRT1_2);

export const RatioSelector = ({
    setRatio,
    ratio,
}) => (
    <div className="beRadioSelector">
        <form onChange={(e) => setRatio(formatRatio(e.target.value))}>
            <div>
                <label>
                    <input type="radio" name="ratio" value="landscape" checked={parseRatio(ratio) === 'landscape'} readOnly />
                    <span>{i18n.t('components.boardEditor.landscape')}</span>
                </label>
            </div>
            <div>
                <label>
                    <input type="radio" name="ratio" value="portrait" checked={parseRatio(ratio) === 'portrait'} readOnly />
                    <span>{i18n.t('components.boardEditor.portrait')}</span>
                </label>
            </div>
        </form>
    </div>
);
