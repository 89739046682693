import React from 'react';

import {ChatMessage, toggleMessageFavorite} from 'mushin-redux-store';
import {useAppDispatch} from '../../Helpers/hooks';
import Reaction, {CommonReactionProps} from './Reaction';

type Props = CommonReactionProps & {
    message: ChatMessage;
}

const MessageReaction: React.FC<Props> = (
    {
        name,
        message,
        className,
        horizontal,
        contrast,
        size,
    }
) => {
    const dispatch = useAppDispatch();

    return (
        <Reaction
            name={name}
            reactions={undefined}
            favorites={message.favorites}
            toggleReaction={() => { /* */ }}
            toggleFavorite={() => dispatch(toggleMessageFavorite(message))}
            className={className}
            horizontal={horizontal}
            contrast={contrast}
            size={size}
        />
    );
};

export default MessageReaction;
