/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import { Note, shareNote } from 'mushin-redux-store';
import {useDispatch} from 'react-redux';
import i18n from 'i18next';
import MuButton from '../../../../Components/MuButton/MuButton';
import Modal from '../../../../Components/Modal/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import SelectUsersDropdown from '../../../../Components/UsersListComponents/SelectUsersDropdown/SelectUsersDropdown';
import EntityRow from '../../../../Components/EntityRow/EntityRow';

type HandlerFunction = (...params: any[]) => void;

type Props = {
    onClose: () => void;
    notes: Note[];
    validationWrapper?: (handler: HandlerFunction) => HandlerFunction;
    canRemoveUser?: boolean;
};

const ShareFilesModal: React.FC<Props> = (
    {
        onClose,
        notes,
        validationWrapper = (handler) => handler,
        canRemoveUser = true,
    }
) => {
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (notes.length === 1) {
            setSelectedUsers([...notes[0].shared_with.map((usr) => usr.id)]);
        }
    }, [notes]);

    const shareNotes = (): void => {
        const promises = [];

        notes.forEach((note) => {
            promises.push(dispatch(shareNote(note.id, false, { user_ids: selectedUsers })));
        });

        if (notes.length === 1) {
            const usersRemoved = notes[0].shared_with.filter((u1) => !selectedUsers.includes(u1.id));

            if (usersRemoved.length > 0) {
                promises.push(dispatch(shareNote(notes[0].id, true, { shared_with: usersRemoved })));
            }
        }

        Promise.all(promises).then(() => {
            onClose();
            return true;
        }).catch((err) => {
            return Promise.reject(err);
        });
    };

    return (
        <Modal handleRemoveModal={onClose} classModifiers={['large']}>
            <ModalHeader title={i18n.t('modals.shareFiles.title')} icon="Share" />
            <p className="mu-modal__catchphrase">
                {i18n.t('modals.shareFiles.catchPhrase')}
            </p>
            <SelectUsersDropdown
                selectUser={(userId) => setSelectedUsers([...selectedUsers, userId])}
                excludedUsersIds={selectedUsers}
                withGroups
            />
            <ul>
                {selectedUsers.map((userId) => (
                    <li key={userId} className="mu-select-users">
                        <EntityRow
                            id={userId}
                            removeUser={canRemoveUser && ((usrId) => setSelectedUsers(selectedUsers.filter((id) => id !== usrId)))}
                        />
                    </li>
                ))}
            </ul>
            <ModalFooter>
                <MuButton
                    label={i18n.t('global.cancel')}
                    handleClick={onClose}
                    classModifiers={['large', 'modal', 'default']}
                />
                <MuButton
                    label={i18n.t('global.validate')}
                    handleClick={validationWrapper(shareNotes)}
                    classModifiers={['large', 'modal']}
                />
            </ModalFooter>
        </Modal>
    );
};

export default ShareFilesModal;
