import React from 'react';
import {Board} from 'mushin-node-moodboard';
import {classWithModifiers, Modifiers} from '../../Helpers/css';
import { BoardLayout } from '../BoardComponents/BoardLayout/component/BoardLayout';
import MuIcon from '../MuIcon';
import {TemplateContents} from '../BoardComponents/BoardLayout/type';

type Props = {
    board: Board;
    size: number;
    templateContents?: TemplateContents;
    classModifiers?: Modifiers;
    onClick?: React.MouseEventHandler;
    onRemove?: () => void;
    onDragStart?: React.DragEventHandler;
    onDragOver?: React.DragEventHandler;
    removable?: boolean;
}

const MoodboardCarouselPage: React.FC<Props> = ({
    board,
    templateContents,
    size,
    classModifiers = {},
    onClick,
    onRemove,
    onDragStart,
    onDragOver,
    removable = false,
}) => {
    const ratio = size / board.width;
    return (
        <article
            className={classWithModifiers('mu-moodboard-carousel__page', classModifiers)}
            style={{ // Height is managed here because we have to know the exact height for the CSS transition to work.
                height: board.height * ratio,
            }}
            draggable
            onDragStart={onDragStart}
            onDragOver={onDragOver}
        >
            {removable && (
                <button onClick={onRemove} className="mu-moodboard-carousel__page-icon" type="button">
                    <MuIcon svgName="CloseCircleFill" />
                </button>
            )}
            <BoardLayout
                width={board.width * ratio}
                height={board.height * ratio}
                board={board}
                templateContents={templateContents}
                ratio={ratio}
                onClick={onClick}
            />
        </article>
    );
};

export default MoodboardCarouselPage;
