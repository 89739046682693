import React, {useEffect} from 'react';
import {useProject, useQuestionnairesQuery} from 'mushin-redux-store';
import {useParams} from 'react-router-dom';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import i18n from 'i18next';
import AppLayout from '../../Components/Layouts/AppLayout';
import {isProjectAdmin, isProjectContributor} from '../../Helpers/users';
import QuestionnaireItem from './QuestionnaireItem';
import MuButton from '../../Components/MuButton';
import {useAppDispatch} from '../../Helpers/hooks';
import {useScrollThreshold} from '../../Helpers/effects';
import {openModal} from '../../Redux/reducers/modalsSlice';
import MuIcon from '../../Components/MuIcon';

const Missions: React.FC = () => {
    const {projectId} = useParams<{ [key: string]: string}>();
    const project = useProject(projectId);
    const questionnairesQuery = useQuestionnairesQuery({ project_id: projectId }, true);

    const ref = useScrollThreshold(questionnairesQuery.getMoreResults, {deps: [questionnairesQuery.results?.length]});

    const { setParams } = questionnairesQuery;
    useEffect(() => {
        setParams({ project_id: projectId });
    }, [projectId, setParams]);

    const dispatch = useAppDispatch();

    if (!project) return null;

    const isContributor = isProjectContributor(project);
    const total = questionnairesQuery.pager?.total_items || 0;
    const totalDone = questionnairesQuery.total_done;

    return (
        <AppLayout
            title={i18n.t('missions.list')}
            actionsComponent={isProjectAdmin(project) && (
                <MuButton
                    rightIcon="Plus"
                    label={i18n.t('missions.add')}
                    handleClick={() => dispatch(openModal('CreateQuestionnaire', {
                        projectId: project.id,
                    }))}
                />
            )}
        >
            <div ref={ref} className="mu-app-layout__scroll">
                <section className="mu-missions-list">
                    {isContributor && (
                        <div className="mu-mission-item mu-mission-item--progress">
                            <CircularProgressbar
                                text={total === totalDone ? '\uD83C\uDF89' : '\ud83d\ude80'}
                                value={totalDone}
                                maxValue={total || 1}
                                strokeWidth={10}
                                background
                                styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                    rotation: 0.3,

                                    // Text size
                                    textSize: '24px',

                                    // How long animation takes to go from one percentage to another, in seconds
                                    pathTransitionDuration: 0.5,

                                    // Can specify path transition in more detail, or remove it entirely
                                    // pathTransition: 'none',

                                    // Colors
                                    pathColor: '#62A1FF',
                                    trailColor: '#EEEEEE',
                                    backgroundColor: 'white',
                                })}
                            />
                            <div className="mu-mission-item__progress">
                                {total && Math.floor((totalDone * 100) / total)}
                                %
                            </div>
                        </div>
                    )}
                    {questionnairesQuery.results && questionnairesQuery.results.length > 0 ? (
                        questionnairesQuery.results.map((missionId) => (
                            <QuestionnaireItem key={missionId} id={missionId} isContributor={isContributor} />
                        ))
                    ) : !questionnairesQuery.loading && (
                        <div className="mu-mission-item">
                            <div className="mu-mission-item__thumbnail-placeholder">
                                <MuIcon svgName="Mission" />
                            </div>
                            <h4 className="mu-mission-item__title">{i18n.t('missions.noMissions')}</h4>
                            {isProjectAdmin(project) && (
                                <MuButton
                                    icon="Plus"
                                    label={i18n.t('missions.add')}
                                    handleClick={() => dispatch(openModal('CreateQuestionnaire', {
                                        projectId: project.id,
                                    }))}
                                />
                            )}
                        </div>
                    )}
                </section>
            </div>
        </AppLayout>
    );
};

export default Missions;
