import React from 'react';
import {
    useCurrentProject,
    useMe,
    useTimelineSocket,
} from 'mushin-redux-store';
import NavigationItems from './Components/Navigation/NavigationItems';

const MainMenuMobile: React.FC = () => {
    const me = useMe();
    const currentProject = useCurrentProject();
    useTimelineSocket(me?.id || '');

    if (!me) return null;

    return (
        <section className="mu-main-menu-mobile">
            <NavigationItems project={currentProject} />
        </section>
    );
};

export default MainMenuMobile;
