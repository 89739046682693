import React from 'react';
import {NavLink} from 'react-router-dom';
import MuIcon from '../../../MuIcon';
import {classWithModifiers} from '../../../../Helpers/css';
import CountNotifications from '../../../CountNotifications/CountNotifications';

type Props = {
    className?: string;
    icon?: React.ReactNode;
    title: React.ReactNode;
    href: string;
    isActive?: boolean;
    exact?: boolean;
    countNotifications?: number | boolean;
};

const NavigationItem: React.FC<Props> = ({
    className,
    icon,
    title,
    href,
    isActive,
    exact,
    countNotifications,
}) => (
    <li className="mu-main-menu-nav__item">
        <NavLink
            to={href}
            className={({ isActive: defaultIsActive }) => (
                classWithModifiers(
                    'mu-main-menu-nav__link',
                    {active: isActive === undefined ? defaultIsActive : isActive},
                    className,
                )
            )}
            end={exact}
        >
            <div className="mu-main-menu-nav__icon-wrapper">
                {typeof icon === 'string' ? (
                    countNotifications ? (
                        <CountNotifications nb={countNotifications}>
                            <MuIcon className="mu-main-menu-nav__icon" svgName={icon} />
                        </CountNotifications>
                    ) : (
                        <MuIcon className="mu-main-menu-nav__icon" svgName={icon} />
                    )
                ) : icon}
            </div>
            <div className="mu-main-menu-nav__title">{title}</div>
        </NavLink>
    </li>
);

export default NavigationItem;
