export const confirmUrl = (landing: string | undefined): string => (
    landing ? `/confirm_account?landing=${landing}` : '/confirm_account'
);

export const loginUrl = (landing: string | undefined): string | undefined => (
    landing ? `/login?landing=${landing}` : undefined
);

export const successUrl = (landing: string | undefined): string => {
    if (landing === 'response' || landing === 'candidacy') return `/questionnaires/${landing}/success`;
    const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
    localStorage.removeItem('redirectAfterLogin');
    return redirectAfterLogin || '/';
};

export const getInfoKey = (landing: string | undefined): string | undefined => {
    if (landing) return `questionnaires.haveToValidateEmail.${landing}`;
    return undefined;
};
