import React from 'react';
import {useTranslation} from 'react-i18next';
import SessionsLayout from '../Components/SessionsLayout/SessionsLayout';

const AccountDeletionHelp: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div id="mushin-app">
            <SessionsLayout title={t('deleteAccountPage.title')}>
                <h2>{t('deleteAccountPage.option1.title')}</h2>
                <p>{t('deleteAccountPage.option1.startParagraph')}</p>
                <ol>
                    <li>
                        {t('deleteAccountPage.option1.steps.0.0')}
                        <a
                            href={window.location.origin}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('deleteAccountPage.option1.steps.0.1')}
                        </a>
                        {t('deleteAccountPage.option1.steps.0.2')}
                    </li>
                    <li>{t('deleteAccountPage.option1.steps.1')}</li>
                    <li>{t('deleteAccountPage.option1.steps.2')}</li>
                </ol>

                <h2>{t('deleteAccountPage.option2.title')}</h2>
                <p>{t('deleteAccountPage.option2.startParagraph')}</p>
                <ol>
                    <li>{t('deleteAccountPage.option2.steps.0')}</li>
                    <li>{t('deleteAccountPage.option2.steps.1')}</li>
                    <li>{t('deleteAccountPage.option2.steps.2')}</li>
                </ol>

                <h2>{t('deleteAccountPage.option3.title')}</h2>
                <p>{t('deleteAccountPage.option3.startParagraph')}</p>
                <ol>
                    <li>
                        {t('deleteAccountPage.option3.steps.0.0')}
                        <a href="mailto: support@mymushin.com">{t('deleteAccountPage.option3.steps.0.1')}</a>
                        {t('deleteAccountPage.option3.steps.0.2')}
                    </li>
                    <li>{t('deleteAccountPage.option3.steps.1')}</li>
                </ol>

                <p>{t('deleteAccountPage.contactSupport')}</p>
            </SessionsLayout>
        </div>
    );
};

export default AccountDeletionHelp;
