import React from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import {useCurrentOrganizationId, useCurrentProjectId, useProject} from 'mushin-redux-store';
import PrivateRoute from './PrivateRoute';
import AlbumGallery from '../Scenes/Gallery/AlbumGallery';
import ProjectDetail from '../Scenes/ProjectDetail/ProjectDetail';
import { BoardEditor } from '../Scenes/BoardEditor';
import Missions from '../Scenes/Missions/Missions';
import InspirationsGallery from '../Scenes/Gallery/InspirationsGallery';
import AppLoader from './AppLoader';
import Activities from '../Scenes/Activities/Activities';
import ProjectMembers from '../Scenes/ProjectMembers/ProjectMembers';
import ProjectCriteria from '../Scenes/ProjectCriteria/ProjectCriteria';
import AlbumsPage from '../Scenes/AlbumsPage/AlbumsPage';
import QuestionnaireRoutes from './QuestionnaireRoutes';
import SynthesisClusters from '../Scenes/Synthesis/Components/Clusters/SynthesisClusters';
import SynthesisInsights from '../Scenes/Synthesis/Components/Insights/SynthesisInsights';
import SynthesisOpportunities from '../Scenes/Synthesis/Components/Opportunities/SynthesisOpportunities';
import SynthesisSolutions from '../Scenes/Synthesis/Components/Solutions/SynthesisSolutions';
import Synthesis from '../Scenes/Synthesis/Synthesis';

export const ProjectRoutes: React.FC = () => {
    const { projectId } = useParams<{projectId: string}>();
    const [currentProjectId, setCurrentProjectId] = useCurrentProjectId();
    const [currentOrganizationId, setCurrentOrganizationId] = useCurrentOrganizationId();
    const project = useProject(currentProjectId || undefined);
    if (projectId && projectId !== 'null' && currentProjectId !== projectId) {
        setCurrentProjectId(projectId);
        return <AppLoader />;
    }
    if (project && currentOrganizationId !== project.organization_id) {
        setCurrentOrganizationId(project.organization_id);
        return <AppLoader />;
    }

    if (!project && (currentProjectId === null || (currentProjectId && project === null))) {
        return (
            <Routes>
                <Route path="*" element={<Navigate to="/projectSelector" replace />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="activities" element={<PrivateRoute><Activities /></PrivateRoute>} />
            <Route path="candidacy/*" element={<QuestionnaireRoutes />} />
            <Route path="questionnaires/*" element={<QuestionnaireRoutes />} />
            <Route path="galleries/:inspirationType/note/:note_id" element={<PrivateRoute><InspirationsGallery /></PrivateRoute>} />
            <Route path="galleries/:inspirationType" element={<PrivateRoute><InspirationsGallery /></PrivateRoute>} />
            <Route path="albums/:album_id/note/:note_id" element={<PrivateRoute><AlbumGallery /></PrivateRoute>} />
            <Route path="albums/:album_id/:inspirationType(messages)/note/:note_id" element={<PrivateRoute><AlbumGallery /></PrivateRoute>} />
            <Route path="albums/:album_id/:inspirationType(messages)" element={<PrivateRoute><AlbumGallery /></PrivateRoute>} />
            <Route path="albums/:album_id" element={<PrivateRoute><AlbumGallery /></PrivateRoute>} />
            <Route path="albums" element={<PrivateRoute><AlbumsPage /></PrivateRoute>} />
            <Route path="board/:board_id" element={<PrivateRoute><BoardEditor /></PrivateRoute>} />
            <Route path="missions" element={<PrivateRoute><Missions /></PrivateRoute>} />
            <Route path="members" element={<PrivateRoute><ProjectMembers /></PrivateRoute>} />
            <Route path="criteria" element={<PrivateRoute><ProjectCriteria /></PrivateRoute>} />
            <Route path="*" element={<PrivateRoute><ProjectDetail /></PrivateRoute>} />
            <Route path="synthesis" element={<PrivateRoute><Synthesis /></PrivateRoute>} />
            <Route path="synthesis/clusters" element={<PrivateRoute><SynthesisClusters /></PrivateRoute>} />
            <Route path="synthesis/insights" element={<PrivateRoute><SynthesisInsights /></PrivateRoute>} />
            <Route path="synthesis/opportunities" element={<PrivateRoute><SynthesisOpportunities /></PrivateRoute>} />
            <Route path="synthesis/solutions" element={<PrivateRoute><SynthesisSolutions /></PrivateRoute>} />

        </Routes>
    );
};

export default ProjectRoutes;
