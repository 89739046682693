import React from 'react';
import { useProfile } from 'mushin-redux-store';

import {classWithModifiers, Modifiers} from '../../Helpers/css';
import ProfileCard from '../UserCard/ProfileCard';
import { MuSvg } from '../MuSvg';
import Close from '../../../assets/icons/mu-icons/Close.svg';
import ProfileDetailsPopover from '../UserDetailsPopover/ProfileDetailsPopover';

export type EntityRowRole = {
    key: string,
    icon: string,
    unique?: boolean,
    fallback?: string,
}

type EntityRowProps = {
    className?: string;
    classModifiers?: Modifiers;
    id: string;
    role?: string;
    removeUser?: ((id: string) => void) | false;
    rightChildren?: React.ReactNode;
    urlAvatar?: string;
    useRoles?: boolean | 'read-only';
    roleOptions?: EntityRowRole[];
};

const EntityRow: React.FC<EntityRowProps> = ({
    className,
    classModifiers,
    id,
    role,
    removeUser,
    rightChildren,
    useRoles = false,
}) => {
    const profile = useProfile(id);
    if (!profile) return null;

    return (
        <article className={classWithModifiers('mu-entity-row', classModifiers, className)}>
            <div className="mu-entity-row__infos">
                <ProfileDetailsPopover
                    position="top"
                    profile={profile}
                    hoveredElement={<ProfileCard id={id} />}
                />
            </div>

            <div className="mu-entity-row__status-or-actions">
                {rightChildren}
            </div>
            {removeUser && (
                <button
                    disabled={useRoles && role === 'owner'}
                    type="button"
                    className="mu-entity-row__remove-user"
                    onClick={() => removeUser(id)}
                >
                    <MuSvg SvgComp={Close} />
                </button>
            )}
        </article>
    );
};

export default EntityRow;
