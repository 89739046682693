import {Dispatch, SetStateAction} from 'react';
import {getGroup, Profile} from 'mushin-redux-store';
import {ExcelBuilder, formatUserFields, getCriteria, usersHeaders} from './excelExports';
import {AsyncAppThunk} from '../../Redux/reducers';

type Params = {
    project_id?: string;
    name_search?: string;
    groups?: string[];
    status?: string;
}
export const usersExport = (
    userIds: string[],
    params: Params,
    setProgress: Dispatch<SetStateAction<number>> = () => { /* */ },
    getStatus: (user: Profile) => {mod: string; label: string; date?: string},
): AsyncAppThunk => async (dispatch): Promise<void> => {
    let name = 'mushin_user_exports';
    if (params.name_search) name += `_search-${params.name_search}`;
    if (params.status) name += `_status-${params.status}`;
    if (params.groups?.length) {
        name += '_group';
        for (const groupId of params.groups) {
            // eslint-disable-next-line no-await-in-loop
            const group = await dispatch(getGroup(groupId));
            name += `-${group?.name}`;
        }
    }

    const excelBuilder = new ExcelBuilder(
        name,
        'All users',
    );

    setProgress(0);
    const progressInterval = 100 / (userIds.length * 3);
    const incrementProgress = () => setProgress((progress) => progress + progressInterval);

    const criteria = await dispatch(getCriteria(userIds, incrementProgress));
    const headers = [...usersHeaders(criteria)];

    excelBuilder.setHeaders(headers);

    for await (const userId of userIds) {
        excelBuilder.addLine([
            ...await dispatch(formatUserFields(
                userId,
                criteria,
                getStatus,
            )),
        ]);
        incrementProgress();
    }

    setProgress(100);

    await excelBuilder.export();
};
