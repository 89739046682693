import React, { useState } from 'react';
import {useDispatch} from 'react-redux';
import {Project, EntityMember, updateProject} from 'mushin-redux-store';
import i18n from 'i18next';
import Modal from '../../../../Components/Modal';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import MuButton from '../../../../Components/MuButton';
import AddUsersStep from '../CreationEditionModal/AddUsersStep';
import {isProjectAdmin} from '../../../../Helpers/users';

type Props = {
    onClose: () => void;
    project: Project;
};

const ProjectUsersModal: React.FC<Props> = ({
    onClose,
    project,
}) => {
    const membersState = useState<EntityMember[]>(project.selectedMembers);
    const dispatch = useDispatch();

    const isAdmin = isProjectAdmin(project);

    return (
        <Modal classModifiers="content-margin" handleRemoveModal={onClose}>
            <ModalHeader
                subtitle={i18n.t('modals.EditProjectUsers.subtitle')}
                title={i18n.t('modals.EditProjectUsers.title')}
                icon="Share"
            />
            <AddUsersStep
                membersState={membersState}
                canRemoveUser={isAdmin}
                canUpdateRoles={isAdmin || 'read-only'}
            />
            <ModalFooter>
                <MuButton
                    handleClick={() => {
                        dispatch(updateProject(
                            project.id,
                            {
                                selectedMembers: membersState[0],
                            }
                        ));
                        onClose();
                    }}
                    label={i18n.t('global.validate')}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>

    );
};

export default ProjectUsersModal;
