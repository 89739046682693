import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuestionnaire } from 'mushin-redux-store';
import QuestionResult from './QuestionResult';
import QuestionnaireAppLayout from '../QuestionnaireEditor/Components/QuestionnaireAppLayout';
import QuestionResultFree from './QuestionResultFree';

type Params = {
    questionnaireId: string;
    questionId?: string;
};

const QuestionnaireResult: React.FC = () => {
    const { questionnaireId, questionId } = useParams<Params>();
    const questionnaire = useQuestionnaire(questionnaireId);

    const selectedQuestionIndex =
        questionnaire &&
        (questionId
            ? questionnaire.questions.findIndex((question) => question.id === questionId)
            : questionnaire.questions.findIndex((question) => question.type !== 'description'));
    const selectedQuestion =
        typeof selectedQuestionIndex === 'number' && questionnaire?.questions[selectedQuestionIndex];

    return (
        <QuestionnaireAppLayout questionnaire={questionnaire}>
            {questionnaire &&
                selectedQuestion &&
                (selectedQuestion.type === 'free' ? (
                    <QuestionResultFree key={questionId} questionnaire={questionnaire} question={selectedQuestion} />
                ) : (
                    <div className="mu-app-layout__scroll mu-questionnaire-result">
                        <QuestionResult key={questionId} questionnaire={questionnaire} question={selectedQuestion} />
                    </div>
                ))}
        </QuestionnaireAppLayout>
    );
};

export default QuestionnaireResult;
