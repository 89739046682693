import React, {useCallback} from 'react';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {ProfilesQueryParams} from 'mushin-redux-store';
import ImageViewModal from '../../../../Components/ImageViewModal/ImageViewModal';
import {useAppSelector} from '../../../../Helpers/hooks';

type Props = {
    userMentionsQueryParams?: ProfilesQueryParams;
    noteIds: string[];
    loadMoreNotes?: () => void;
}

const GalleryImageViewModal: React.FC<Props> = (
    {
        userMentionsQueryParams,
        noteIds,
        loadMoreNotes,
    }
) => {
    const noModalOpen = useAppSelector(
        (state) => !state.modals.modalTypes.length
    );
    const { note_id } = useParams<{[key: string]: string}>();

    const location = useLocation();
    const navigate = useNavigate();

    // Carousel
    const getNoteViewUrl = useCallback((_noteId?: string) => {
        const baseLocation = location.pathname.replace(/\/note\/.*/, '');
        const search = new URLSearchParams(location.search);
        search.delete('comment');
        if (_noteId) {
            return `${baseLocation}/note/${_noteId}?${search.toString()}`;
        }
        return `${baseLocation}?${search.toString()}`;
    }, [location.pathname, location.search]);

    const setNoteId = useCallback((noteId: string | undefined): void => {
        if (noteId) {
            navigate(getNoteViewUrl(noteId), { replace: true });
        } else {
            navigate(getNoteViewUrl());
        }
    }, [getNoteViewUrl, navigate]);

    return (
        <ImageViewModal
            noteId={note_id}
            setNoteId={setNoteId}
            noteIds={noteIds}
            loadMoreNotes={loadMoreNotes}
            userMentionsQueryParams={userMentionsQueryParams}
            active={noModalOpen}
        />
    );
};

export default GalleryImageViewModal;
