import React from 'react';

import {syncUploadInfos, useUploads} from 'mushin-redux-store';
import i18n from 'i18next';
import MuButton from '../../MuButton/MuButton';
import Modal from '../../Modal/Modal';
import { blurIfHasFocus } from '../../../Helpers/manipulateDom';
import { DOM_DATA_ATT_add_tag } from '../../Tags/Tags';
import {ModalHeader} from '../../Modal/ModalHeader';
import {ModalFooter} from '../../Modal/ModalFooter';
import {ModalBody} from '../../Modal/ModalBody';
import TransferManager from '../../../Scenes/NoteUploader/Components/TransferManager/TransferManager';
import {useAppDispatch} from '../../../Helpers/hooks';

type Props = {
  onClose: () => void;
};

const UploadFilesModal: React.FC<Props> = ({
    onClose,
}) => {
    const uploads = useUploads();
    const dispatch = useAppDispatch();

    const closeAndRemoveUploadedNotes = () => {
        // If user click outside during editing tag, trigger blur event to saving modification
        blurIfHasFocus(DOM_DATA_ATT_add_tag);
        uploads.forEach((upload) => dispatch(syncUploadInfos(upload)));
        onClose();
    };

    return (
        <Modal id="UploadFilesModal" classModifiers={['extra-large']}>
            <ModalHeader
                title={i18n.t('modals.uploadFilesModal.title')}
                subtitle={i18n.t('modals.uploadFilesModal.catchPhrase')}
                icon="Cloud"
            />
            <ModalBody>
                <section>
                    <TransferManager />
                </section>
            </ModalBody>
            <ModalFooter>
                <div className="mu-modal-footer__message">{i18n.t('modals.uploadFilesModal.youCanClose')}</div>
                <section className="mu-modal-footer__buttons">
                    <MuButton
                        label={i18n.t('global.close')}
                        handleClick={closeAndRemoveUploadedNotes}
                        classModifiers={['large']}
                    />
                </section>
            </ModalFooter>
        </Modal>
    );
};

export default UploadFilesModal;
