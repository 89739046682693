import React from 'react';
import { ActionBar } from './common';
import { ActionProps, ActionBarSeparator, commonButtons } from '../actionButtons/common';
import { textButtons } from '../actionButtons/text';

export default ((props: ActionProps) => (
    <ActionBar>
        {textButtons(props)}
        <ActionBarSeparator />
        {commonButtons(props)}
    </ActionBar>
));
