import React from 'react';
import {UserNotificationTypeSettings} from 'mushin-redux-store';
import CheckboxRadio from '../../../../Components/Form/CheckboxRadio';

type Props = {
    label: React.ReactNode;
    settingKey: string;
    value: UserNotificationTypeSettings;
    setValue: (value: UserNotificationTypeSettings) => void;
    disableMail?: boolean;
    disablePush?: boolean;
}

export const NotificationSettings: React.FC<Props> = ({
    label,
    settingKey,
    value,
    setValue,
    disableMail,
    disablePush,
}) => {
    return (
        <tr className="notification_settings" key={settingKey}>
            <td className="settings-table__td">{label}</td>
            <td className="settings-table__td">
                <CheckboxRadio
                    key={`${settingKey}_mail`}
                    setChecked={(mailValue) => setValue({
                        push: value ? value.push : false,
                        mail: mailValue,
                    })}
                    checked={value ? value.mail : false}
                    disabled={disableMail}
                />
            </td>
            <td className="settings-table__td">
                <CheckboxRadio
                    key={`${settingKey}_push`}
                    setChecked={(pushValue) => setValue({
                        mail: value ? value.mail : false,
                        push: pushValue,
                    })}
                    checked={value ? value.push : false}
                    disabled={disablePush}
                />
            </td>
        </tr>
    );
};
