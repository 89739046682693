import React from 'react';
import {QuestionAnswersQueryResult, QuestionRanking, QuestionRankingStat} from 'mushin-redux-store';
import {roundWithPrecision} from '../../../Helpers/math';
import CheckboxRadio from '../../../Components/Form/CheckboxRadio';
import { useAppDispatch } from '../../../Helpers/hooks';
import { openLightbox } from '../../../Redux/reducers/lightboxSlice';
import TextTranslation from '../../../Components/Translation/TextTranslation';

type Props = {
    question: QuestionRanking;
    answers: QuestionAnswersQueryResult<QuestionRanking>;
}

const QuestionResultRanking: React.FC<Props> = ({question, answers}) => {
    const items = question.items ? [...question.items] : [];
    const dispatch = useAppDispatch();

    let absoluteRank = 0;
    let lastAverageRank = 0;

    const renderItem = (
        item: QuestionRanking['items'][number],
        stat: Omit<QuestionRankingStat, 'value'>,
    ) => {
        if (stat.average !== lastAverageRank) absoluteRank += 1;
        lastAverageRank = stat.average;
        return (
            <React.Fragment key={item.slug}>
                <CheckboxRadio
                    className="mu-question-result__item-value"
                    checked={!!stat.ranks.length}
                    setChecked={() => { /* */ }}
                    label={(
                        <>
                            {item.image_url && (
                                <img
                                    src={item.image_url}
                                    alt=""
                                    className="mu-question-result__item-image"
                                    onClick={() => dispatch(openLightbox({ urls: [item.image_url as string] }))}
                                />
                            )}
                            <TextTranslation translationProp={item.labels} fallback={item.label} />
                        </>
                    )}
                    styleKey="choice"
                />
                <div className="mu-question-result__average">
                    {`#${absoluteRank} (~${roundWithPrecision(stat.average, 2)})`}
                </div>
                <div className="mu-question-result__ranks">
                    {stat.ranks.map((rank) => (
                        <div key={rank.chosenRank} className="mu-question-result__rank">
                            {`#${rank.chosenRank} : ${roundWithPrecision(rank.ratio * 100, 1)}% (${rank.count})`}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="mu-question-result__items mu-question-result__items--rank">
            {answers.stats?.map((stat) => {
                const index = items.findIndex((item) => item.slug === stat.value);
                if (index < 0) {
                    return renderItem({slug: stat.value, label: stat.value} as QuestionRanking['items'][number], stat);
                }
                const item = items.splice(index, 1)[0];
                return renderItem(item, stat);
            })}
            {items.map((item) => renderItem(item, {average: question.items.length + 1, ranks: []}))}
        </div>
    );
};

export default QuestionResultRanking;
