import React, {useState} from 'react';
import i18n from 'i18next';
import DropdownMenu from '../../../Components/DropdownMenu/DropdownMenu';
import BoardHeaderButton from './BoardHeaderButton';
import DownloadDropdownItem from './DownloadDropdownItem';
import DownloadDropdownItemAll from './DownloadDropdownItemAll';

type Props = {
    title: string;
}

const DownloadDropdown: React.FC<Props> = ({title}) => {
    const [isOpen, setOpen] = useState(false);
    const [inProgress, setInProgress] = useState<('jpg' | 'pdf' | 'all')[]>([]);

    const toggleInProgress = (type: 'jpg' | 'pdf' | 'all', value: boolean) => {
        if (value) {
            setInProgress((prevValue) => (
                !prevValue.includes(type) ? [...prevValue, type] : prevValue
            ));
        } else {
            setInProgress((prevValue) => (
                prevValue.filter((_type) => _type !== type)
            ));
        }
    };

    return (
        <DropdownMenu
            className="mu-dropdown"
            isOpen={isOpen}
            setIsOpen={setOpen}
            toggle={(
                <BoardHeaderButton
                    icon="Export"
                    label={i18n.t('global.export')}
                    onClick={() => setOpen((prevOpen) => !prevOpen)}
                />
            )}
        >
            {(['jpg', 'pdf'] as ('jpg' | 'pdf')[]).map((ext) => (
                <li key={ext} className="mu-dropdown-menu__button-item" onMouseDown={(e) => e.stopPropagation()}>
                    <DownloadDropdownItem
                        format={ext}
                        inProgress={inProgress.includes(ext)}
                        setInProgress={(value) => toggleInProgress(ext, value)}
                        title={title}
                    />
                </li>
            ))}
            <li className="mu-dropdown-menu__button-item" onMouseDown={(e) => e.stopPropagation()}>
                <DownloadDropdownItemAll
                    inProgress={inProgress.includes('all')}
                    setInProgress={(value) => toggleInProgress('all', value)}
                    title={title}
                />
            </li>
        </DropdownMenu>
    );
};

export default DownloadDropdown;
