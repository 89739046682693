import React from 'react';
import { useNote, Note } from 'mushin-redux-store';
import NoteThumbnail from '../../../../Components/NoteThumbnail/NoteThumbnail';

type Props = {
    noteId: string;
    isSelected: boolean;
    handleSelectNote: (item: Note) => void;
};

const AddIdeaModalNote: React.FC<Props> = ({
    noteId,
    isSelected,
    handleSelectNote,
}) => {
    const note = useNote(noteId);
    if (!note) return null;
    return (
        <NoteThumbnail
            isSelected={isSelected}
            onSelect={() => handleSelectNote(note)}
            note={note}
        />
    );
};

export default AddIdeaModalNote;
