import { Handler } from './type';

import { hitBoxes } from '../layoutUtils';
import { extractWhiteColCells } from './dropContentInCell';

export const onDrop: Handler = ({
    state,
    board,
    draggedElement,
    actions,
    templateContents,
}, pointers, event) => {
    const whiteCols = extractWhiteColCells(templateContents);

    const hit = pointers[0] && hitBoxes(board.ids.filter((id) => !whiteCols.includes(id)).map((id) => board.cells[id]), pointers[0]);

    if (!hit && draggedElement) {
        const contents = { ...board.contents };
        const cells = { ...board.cells };
        const ids = board.ids.slice();

        const width = 50;
        const height = 50;

        const cell = {
            max: {
                x: pointers[0].x + width / 2,
                y: pointers[0].y + height / 2,
            },
            min: {
                x: pointers[0].x - width / 2,
                y: pointers[0].y - height / 2,
            },
        };

        actions.addElementInNewCell(draggedElement.note, cell);
        event && event.preventDefault();
    }
};

export const onDragOver: Handler = ({
    state,
    board,
    draggedElement,
}, pointers, event) => {
    if (!draggedElement) return;

    const hit = pointers[0] && hitBoxes(board.ids.map((id) => board.cells[id]), pointers[0]);

    if (!hit && event) event.preventDefault();
};
