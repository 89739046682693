import React from 'react';
import {useQuestionnaire} from 'mushin-redux-store';
import i18n from 'i18next';
import DeltaTruncated from '../DeltaTruncated/DeltaTruncated';
import { useAppDispatch } from '../../Helpers/hooks';
import { openLightbox } from '../../Redux/reducers/lightboxSlice';
import CustomSwiper from '../CustomSwiper/CustomSwiper';

type Props = {
    questionnaireId: string;
    questionId: string;
}

const ImageViewQuestion: React.FC<Props> = ({ questionnaireId, questionId }) => {
    const dispatch = useAppDispatch();
    const questionnaire = useQuestionnaire(questionnaireId);
    const selectedQuestion = (
        questionnaire?.questions.find((question) => question.id === questionId)
    );
    const imageUrls = selectedQuestion?.image_urls || (questionnaire?.image_url ? [questionnaire?.image_url] : []);

    return (
        <div className="mu-image-view__question">
            <div className="mu-image-view__question-label">{i18n.t('questionnaires.question')}</div>
            {!!imageUrls.length && (
                <CustomSwiper>
                    {imageUrls.map((img, index) => (
                        <img
                            key={index}
                            src={img}
                            alt=""
                            className="mu-image-view__image"
                            onClick={() => dispatch(openLightbox({ urls: [img] }))}
                        />
                    ))}
                </CustomSwiper>
            )}
            {selectedQuestion && (
                <DeltaTruncated
                    translationContent={{
                        translationProp: selectedQuestion.labels,
                        fallback: selectedQuestion.label,
                    }}
                />
            )}
        </div>
    );
};

export default ImageViewQuestion;
