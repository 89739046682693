import React, { useRef, useState } from 'react';

import { MoodboardDrawer, Board } from 'mushin-node-moodboard';
import { applyTransformFromCamera } from './renderer/canvas';

import Spinner from '../../../Spinner/index';

import {Camera, TemplateContents} from '../type';

type Props = {
    board: Board;
    width: number;
    height: number;
    templateContents?: TemplateContents;
    cellBackground?: boolean;
    cropVisible?: boolean;
    antialiasing?: boolean;
    selectedCell?: string | null;
    ratio?: number;
    camera?: Camera;
    onClick?: () => void;
    drawer?: MoodboardDrawer;
}

export const BoardLayout: React.FC<Props> = ({
    board,
    width,
    height,
    templateContents,
    antialiasing = false,
    cellBackground = true,
    cropVisible = false,
    selectedCell = null,
    ratio = 1,
    camera = { a: 1, b: { x: 0, y: 0 } },
    onClick = () => { /* */ },
    drawer,
}) => {
    const [_drawer] = useState(drawer || new MoodboardDrawer());
    const [loading, setLoading] = useState(false);
    const canvas = useRef<HTMLCanvasElement>(null);

    const scale = camera.a * ratio;

    _drawer.setBoard(board);
    if (!loading && !_drawer.areAllImagesLoaded()) {
        setLoading(true);
        _drawer.prepare({ scale }).then(() => setLoading(false));
    }

    if (canvas.current) {
        canvas.current.width = width;
        canvas.current.height = height;

        const ctx = canvas.current.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, width, height);
            ctx.save();
            applyTransformFromCamera(ctx, camera, ratio);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            _drawer.draw(ctx, {
                templateContents,
                selectedCell,
                cropVisible,
                antialiasing,
                cellBackground,
                scale,
            });
            ctx.restore();
        }
    }

    return (
        <div
            onClick={onClick}
            className="simpleBoardComponent"
            style={{
                width,
                height,
            }}
        >
            <canvas className="simpleBoardComponent-canvas" ref={canvas} />

            {loading && (
                <div className="simpleBoardComponent-spinner">
                    <Spinner size={`${Math.min(60, width * 0.5, height * 0.5)}px`} />
                </div>
            )}
        </div>
    );
};
