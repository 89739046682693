import React from 'react';
import {Link, useParams} from 'react-router-dom';
import i18n from 'i18next';
import {useMe} from 'mushin-redux-store';
import SessionsLayout from '../Sessions/Components/SessionsLayout/SessionsLayout';

const QuestionnaireAnswerPublicSuccess: React.FC = () => {
    const { questionnaireType } = useParams<{questionnaireType: string}>();
    const me = useMe();

    return (
        <SessionsLayout
            title={i18n.t(`questionnaires.success.${questionnaireType}.title`)}
            catchphrase={i18n.t(`questionnaires.success.${questionnaireType}.description`)}
        >
            {me && (
                <Link className="mu-sessions__submit-button mu-btn mu-btn--large" to="/">
                    {i18n.t('accounts.login.submit')}
                </Link>
            )}
        </SessionsLayout>
    );
};

export default QuestionnaireAnswerPublicSuccess;
