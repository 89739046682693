import React from 'react';
import {
    useCurrentOrganizationId,
    useProfile
} from 'mushin-redux-store';
import Client from '../../../../Services/Client';
import UserRoleSelect from '../../../../Components/EntityRow/UserRoleSelect';
import EntityRow from '../../../../Components/EntityRow/EntityRow';

type Props = {
    memberId: string;
};

const CompanyMember: React.FC<Props> = ({
    memberId,
}: Props) => {
    const member = useProfile(memberId);
    const [organizationId] = useCurrentOrganizationId();

    if (!member || !organizationId) {
        return null;
    }

    return (
        <EntityRow
            className="mu-admin-row"
            key={member.id}
            id={member.id}
            role={role}
            useRoles={Client.isAllowedToEditOrganization() || 'read-only'}
            rightChildren={(
                <UserRoleSelect
                    useRoles="read-only"
                    roleOptions={[{
                        key: 'owner',
                        icon: 'LockLine',
                        unique: true,
                        fallback: 'admin',
                    }, {
                        key: 'admin',
                        icon: 'Write',
                    }, {
                        key: 'regular',
                        icon: 'UserLine',
                    }]}
                    role={role}
                />
            )}
        />
    );
};

export default CompanyMember;
