import React, { useState } from 'react';
import {createOrganization, updateOrganization, ApiV3} from 'mushin-redux-store';
import i18n from 'i18next';
import axios from 'axios';
import Modal from '../../../../Components/Modal';
import MuButton from '../../../../Components/MuButton';
import { classWithModifiers } from '../../../../Helpers/css';
import {ModalHeader} from '../../../../Components/Modal/ModalHeader';
import {ModalFooter} from '../../../../Components/Modal/ModalFooter';
import {ModalBody} from '../../../../Components/Modal/ModalBody';
import {useAppDispatch} from '../../../../Helpers/hooks';
import InputText from '../../../../Components/InputText/InputText';
import MuIcon from '../../../../Components/MuIcon';
import {openModal} from '../../../../Redux/reducers/modalsSlice';
import MuButtonWithLoader from '../../../../Components/MuButtonWithLoader';

type Props = {
  onClose: () => void;
};

const CreateOrganizationModal: React.FC<Props> = ({
    onClose,
}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [logo, setLogo] = useState<Blob | null>(null);
    const [error, setError] = useState('');

    const dispatch = useAppDispatch();

    const updateCompanyLogo = (fileToCrop: File | undefined) => {
        if (fileToCrop) {
            dispatch(openModal('CropImageModal', {
                submitAction: async (file: Blob) => {
                    setLogo(file);
                    return true;
                },
                fileToCrop,
                title: i18n.t<string>('administration.organization.label'),
                icon: 'Crop',
            }));
        }
    };

    const handleSubmit = async () => {
        try {
            const organization = await dispatch(createOrganization({ name, description }));
            if (logo) {
                const data = new FormData();
                data.append('file', logo, `${name}_${organization.id}.png`);
                data.append('organization_id', organization.id);
                const response = await ApiV3.getInstance().postForm('/upload_tmp_files', data);
                const organizationLogo = response.data;
                await dispatch(updateOrganization(organization.id, {
                    logo_src: organizationLogo,
                }));
            }
            onClose();
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.status === 400) {
                const message = e?.response?.data?.message;
                setError(message ? `global.errors.${message}` : 'accounts.reset_password.errors.unknown');
            }
        }
    };

    return (
        <Modal classModifiers={['large']} handleRemoveModal={onClose}>
            <ModalHeader title={i18n.t('modals.CreateAnOrganization.title')} icon="Plus" />
            <ModalBody>
                <div className="settings-layout-header__avatar settings-layout-header__avatar--modal">
                    <input
                        id="company-logo"
                        type="file"
                        className="logo-settings__input-file"
                        onChange={(e) => updateCompanyLogo(e.target.files?.[0])}
                        accept="image/*"
                    />
                    <label htmlFor="company-logo" className="settings-layout-header__company-logo-label">
                        {logo ? (
                            <img
                                className={classWithModifiers('settings-layout-header__company-logo', ['img'])}
                                src={logo ? URL.createObjectURL(logo) : undefined}
                                alt="Logo"
                            />
                        ) : (
                            <div
                                className={classWithModifiers('settings-layout-header__company-logo', ['placeholder'])}
                            >
                                <MuIcon svgName="Image" />
                                Logo
                            </div>
                        )}
                        <div className="settings-layout-header__company-logo settings-layout-header__edit-avatar">
                            <MuIcon svgName="Edit" />
                        </div>
                    </label>
                </div>
                <div className="mu-form-row">
                    <div className="mu-form-row__group mu-form-row__group--cols2">
                        <InputText
                            classModifiers={['full-width']}
                            onChange={(e) => {
                                setName(e.target.value);
                                setError('');
                            }}
                            placeholder={i18n.t('administration.organization.name')}
                            type="text"
                            value={name}
                            contrast
                        />
                    </div>
                    {error && (
                        <div className="mu-form-row__group mu-form-row__group--cols2">
                            <span className="mu-form-error">{i18n.t(error)}</span>
                        </div>
                    )}
                </div>
                <div className="mu-form-row">
                    <InputText
                        classModifiers={['full-width']}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={i18n.t('administration.organization.description')}
                        type="text"
                        maxLength={500}
                        value={description}
                        contrast
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <MuButton
                    label={i18n.t('global.cancel')}
                    handleClick={onClose}
                    classModifiers={['large', 'default']}
                />
                <MuButtonWithLoader
                    disabled={!name}
                    label={i18n.t('global.confirm')}
                    handleClick={handleSubmit}
                    classModifiers={['large']}
                />
            </ModalFooter>
        </Modal>
    );
};

export default CreateOrganizationModal;
