import React from 'react';
import { classWithModifiers } from '../../Helpers/css';

type Props = {
    value: number;
    maxValue: number;
    archived?: boolean;
    className?: string;
};

const ProgressBar: React.FC<Props> = ({
    value,
    maxValue,
    archived = false,
    className = '',
}: Props) => (
    <progress
        className={classWithModifiers('mu-progress-bar', {
            complete: maxValue - value === 0,
            archived,
        }, className)}
        value={value}
        max={maxValue}
    />
);

export default ProgressBar;
