import React from 'react';
import {Profile, useQuestionnaire} from 'mushin-redux-store';
import i18n from 'i18next';
import {BadgeComponent} from '../../../../Components/Badge/BadgeComponent';
import {formatPastDateTime, getStatus} from '../../../../Helpers/date';
import Badge from '../../../../Components/Badge/Badge';
import MuIcon from '../../../../Components/MuIcon';
import {openModal} from '../../../../Redux/reducers/modalsSlice';
import {useAppDispatch} from '../../../../Helpers/hooks';
import TextTranslation from '../../../../Components/Translation/TextTranslation';

type Props = {
    id: string;
    profile: Profile;
}

const ContributorDetailsMissionItem: React.FC<Props> = ({ id, profile }) => {
    const questionnaire = useQuestionnaire(id);

    const dispatch = useAppDispatch();

    if (!questionnaire) return null;

    const status = getStatus(questionnaire.start_date, questionnaire.end_date, undefined);
    const participant = questionnaire.participants.find((p) => p.id === profile.user?.id);

    // Participant status
    let date = participant?.invited_at;
    let mod = 'warning';
    let label = 'global.invited';

    if (participant?.completed_at) {
        date = participant.completed_at;
        mod = 'valid';
        label = 'global.answered';
    } else if (participant?.reminded?.last) {
        date = participant.reminded.last;
        mod = 'info';
        label = 'global.reminded';
    }

    return (
        <>
            <BadgeComponent status={status} />
            <div className="mu-contributor-modal-missions__item-title">
                <TextTranslation translationProp={questionnaire.titles} fallback={questionnaire.title} />
            </div>
            <div>
                <Badge
                    classModifiers={mod}
                >
                    {i18n.t(label)}
                </Badge>
                {date && (
                    <span className="mu-users-grid__datetime">
                        {formatPastDateTime(date)}
                    </span>
                )}
                {profile.user?.id && participant && !participant?.completed_at && status === 'inProgress' && (
                    <button
                        className="mu-contributor-modal-missions__item-action"
                        type="button"
                        onClick={() => dispatch(openModal('CreateReminder', {profileId: profile.id, missionId: id}))}
                    >
                        <MuIcon svgName="Bell" />
                    </button>
                )}
            </div>
        </>
    );
};

export default ContributorDetailsMissionItem;
