import { Notification, User } from 'mushin-redux-store';

export const getMessageKey = (notification: Notification, me: User | undefined): string | null => {
    switch (notification.event_type) {
        case 'note.moved':
            if (notification.target) {
                if (notification.target.album) {
                    return 'note_moved_to_album';
                }
                if (notification.target.questionnaire) {
                    return 'note_moved_to_questionnaire';
                }
                return null;
            }
            return null;
        case 'groups.note.created':
            return 'group_note';
        case 'groups.note.updated':
            return 'group_note_updated';
        case 'groups.note.destroyed':
            return 'group_note_destroyed';
        case 'groups.note.shared':
            return 'group_note_shared';
        case 'note.created':
            return 'create_note';
        case 'note.shared':
            return 'share_note';
        case 'note.updated':
            return notification.objects.length > 1 ? 'group_note_updated' : 'update_note';
        case 'note.destroyed':
            return 'destroy_note';
        case 'note.commented':
            return 'note_commented';
        case 'note.added_to_folders':
            return 'note_added_folder';
        case 'note.removed_from_folders':
            return 'note_removed_folder';
        case 'note.user_mentioned':
            return 'note_user_mentionned';
        case 'project.shared':
            return 'share_project';
        case 'project.started':
            return 'start_project';
        case 'project.unshared':
            return 'unshare_project';
        case 'album.shared':
            return 'share_album';
        case 'album.started':
            return 'start_album';
        case 'album.unshared':
            return 'unshare_album';
        case 'mission.completed':
            return `mission.${notification.target.mission.type}`;
        case 'missions.remind':
            return 'mission.remind';
        case 'missions.remind.new':
            return 'mission.remind_new';
        case 'questionnaire.started':
            return 'start_questionnaire';
        case 'comment.user_mentioned':
            return 'comment_user_mentionned';
        case 'comment.created':
            return `note_commented.${
                notification.actors.length > 1 ? 'plural' : 'singular'
            }.${notification.target.note.owner_id === me?.id ? 'mine' : 'other'}`;
        case 'user.mentioned':
            if (notification.target) {
                if (notification.target.chat) {
                    switch (notification.target.chat.relative_content.type) {
                        case 'ALBUM':
                            return 'user_mentioned_in_album_chat';
                        case 'NOTE':
                            return 'user_mentioned_in_note_chat';
                        default:
                            return null;
                    }
                }
                return null;
            }
            return null;
        case 'transaction.export_notes.successful':
            return 'export_notes';
        case 'transaction.export_notes.failed':
            return 'export_notes_failed';
        case 'transaction.moodboard.successful':
            return 'moodboard_created';
        case 'custom':
            return 'custom';
        default:
            return null;
    }
};

export default getMessageKey;
