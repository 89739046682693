import React from 'react';
import { split } from 'mushin-node-moodboard/lib/common/mixedContent';
import {MoodboardDrawer, Cell, Content} from 'mushin-node-moodboard';
import {ActionProps, ActionBarMuIconButton} from './common';
import { computeMinScale, getNextScale } from '../../cropUtils';

const createCropScaleHandler = (
    drawer: MoodboardDrawer,
    content: Content,
    cell: Cell,
    setContent: (_content: Content) => void,
    direction: 1 | -1,
) => () => {
    let imageDimension = drawer.cache.getDimensions(content.image_url);

    // image not loaded
    if (!imageDimension) return;

    // reflect image rotation
    if (content.image_rotation === 90 || content.image_rotation === 270) {
    // noinspection JSSuspiciousNameCombination
        imageDimension = {
            x: imageDimension.y,
            y: imageDimension.x,
        };
    }

    const minScale = computeMinScale(imageDimension, content.type === 'mixed' ? split(cell, content)[0].cell : cell);
    const scale = Math.min(minScale, getNextScale(Math.min(minScale, content.image_crop.scale), direction));
    const newContent = {
        ...content,
        image_crop: {
            ...content.image_crop,
            scale,
        },
    };

    setContent(newContent);
};

export const GoToCropModeButton = ({
    setMode,
}: ActionProps) => <ActionBarMuIconButton onClick={() => setMode('crop')} svgName="Crop" />;

export const ZoomInButton = ({
    drawer,
    content,
    setContent,
    cell,
}: ActionProps) => (
    <ActionBarMuIconButton
        onClick={createCropScaleHandler(drawer, content, cell, setContent, -1)}
        svgName="Plus"
    />
);

export const ZoomOutButton = ({
    drawer,
    content,
    setContent,
    cell,
}: ActionProps) => (
    <ActionBarMuIconButton
        onClick={createCropScaleHandler(drawer, content, cell, setContent, 1)}
        svgName="Minus"
    />
);

export const ExpandButton = ({
    content,
    setContent,
}: ActionProps) => (
    <ActionBarMuIconButton
        onClick={() => {
            const newContent = {
                ...content,
                image_crop: {
                    focusPoint: { x: 0.5, y: 0.5 },
                    scale: 1,
                    filling: 'cover',
                },
            };
            setContent(newContent);
        }}
        svgName="Expand"
    />
);

export const ContainButton = ({
    content,
    setContent,
}: ActionProps) => (
    <ActionBarMuIconButton
        onClick={() => {
            const newContent = {
                ...content,
                image_crop: {
                    ...content.image_crop,
                    filling: 'contain',
                },
            };
            setContent(newContent);
        }}
        svgName="Contain"
    />
);
