import React, {useEffect, useState} from 'react';
import {ResizeSensor} from 'css-element-queries';

const MARGIN = 8;

const centering = (
    referentElementPos: number,
    referentElementSize: number,
    targetSize: number,
    windowSize: number,
): number => {
    const pos = referentElementPos - (targetSize - referentElementSize) / 2;
    if (pos < MARGIN) return MARGIN;
    if (pos + targetSize + MARGIN > windowSize) return windowSize - targetSize - MARGIN;
    return pos;
};

type Size = {
    width: number;
    height: number;
}

export type Rect = Size & {
    x: number;
    y: number;
}

export type Position = 'left' | 'right' | 'top' | 'bottom'
export type Align = 'start' | 'center' | 'justify' | 'end'

export type ComputedPosition = {
    position: Position;
    align?: Align;
    overflow?: boolean;
    style: React.CSSProperties;
}

export const verticalCentering = (
    referentRect: DOMRect,
    targetSize: Size
): number => centering(
    referentRect.y + window.scrollY,
    referentRect.height,
    targetSize.height,
    window.innerHeight,
);

export const horizontalCentering = (
    referentRect: DOMRect,
    targetSize: Size,
): number => centering(
    referentRect.x + window.scrollX,
    referentRect.width,
    targetSize.width,
    window.innerWidth,
);

const leftPosition = (referentRect: DOMRect, targetSize: Size) => {
    const maxWidth = referentRect.x - MARGIN * 2;
    const overflow = targetSize.width + 1 >= maxWidth;
    const left = overflow ? MARGIN : referentRect.x - targetSize.width - MARGIN;
    return {position: 'left', overflow, style: {left, maxWidth}} as const;
};
const rightPosition = (referentRect: DOMRect, targetSize: Size) => {
    const left = referentRect.x + referentRect.width + MARGIN;
    const maxWidth = window.innerWidth - MARGIN - left;
    return {
        position: 'right',
        overflow: targetSize.width + 1 >= maxWidth,
        style: {left, maxWidth},
    } as const;
};
const topPosition = (referentRect: DOMRect, targetSize: Size) => {
    const maxHeight = referentRect.y - MARGIN * 2;
    const overflow = targetSize.height + 1 >= maxHeight;
    const top = overflow ? MARGIN : referentRect.y - targetSize.height - MARGIN;
    return {position: 'top', overflow, style: {top, maxHeight}} as const;
};
const bottomPosition = (referentRect: DOMRect, targetSize: Size) => {
    const top = referentRect.y + referentRect.height + MARGIN;
    const maxHeight = window.innerHeight - MARGIN - top;
    return {
        position: 'bottom',
        overflow: targetSize.height + 1 >= maxHeight,
        style: {top, maxHeight},
    } as const;
};

const MIN_SIZE = 350;

export const computePosition = (
    position: Position,
    referentRect: DOMRect,
    targetRect: Size,
    align: Align = 'center',
): ComputedPosition => {
    let coords: ComputedPosition;
    let isVerticalAlign = false;

    if (position === 'left' || position === 'right') {
        isVerticalAlign = true;
        const left = leftPosition(referentRect, targetRect);
        const right = rightPosition(referentRect, targetRect);
        const wanted = position === 'left' ? left : right;
        const other = position === 'right' ? left : right;
        coords = (
            wanted.overflow && wanted.style.maxWidth < MIN_SIZE && other.style.maxWidth > MIN_SIZE ? other : wanted
        );
    } else { // position === 'top' || position === 'bottom'
        const top = topPosition(referentRect, targetRect);
        const bottom = bottomPosition(referentRect, targetRect);
        const wanted = position === 'top' ? top : bottom;
        const other = position === 'bottom' ? top : bottom;
        coords = (
            wanted.overflow && wanted.style.maxHeight < MIN_SIZE && other.style.maxHeight > MIN_SIZE ? other : wanted
        );
    }

    if (isVerticalAlign) {
        coords.style.maxHeight = window.innerHeight - 2 * MARGIN;
        if (align === 'start') {
            coords.style.top = referentRect.y + window.scrollY - targetRect.height + referentRect.height;
        } else if (align === 'end' || align === 'justify') {
            coords.style.top = referentRect.y + window.scrollY;
            if (align === 'justify') {
                coords.style.height = referentRect.height;
            }
        } else {
            coords.style.top = verticalCentering(referentRect, targetRect);
        }
    } else { // Horizontal
        coords.style.maxWidth = window.innerWidth - 2 * MARGIN;
        if (align === 'start') {
            coords.style.left = referentRect.x + window.scrollX - targetRect.width + referentRect.width;
        } else if (align === 'end' || align === 'justify') {
            coords.style.left = referentRect.x + window.scrollX;
            if (align === 'justify') {
                coords.style.width = referentRect.width;
            } else if (coords.style.left + targetRect.width > window.innerWidth) {
                coords.style.left = window.innerWidth - targetRect.width;
            }
        } else {
            coords.style.left = horizontalCentering(referentRect, targetRect);
        }
    }

    return coords;
};

export const useComputedPosition = (
    position: Position,
    relativeElement: HTMLElement | null,
    contentElement: HTMLElement | null,
    active = true,
    align: Align = 'center',
): ComputedPosition | null => {
    const [coords, setCoords] = useState<ComputedPosition | null>(null);

    useEffect(() => {
        if (active) {
            if (contentElement) {
                const refresh = () => {
                    const relativeRect = relativeElement?.getBoundingClientRect();
                    if (relativeRect) {
                        const popoverSize = {
                            height: contentElement.offsetHeight,
                            width: contentElement.offsetWidth,
                        };

                        setCoords(computePosition(
                            position,
                            relativeRect,
                            popoverSize,
                            align,
                        ));
                    }
                };
                // First call of refresh
                refresh();

                // Refresh three times with delay, useful for handling position after scroll or key events
                const refreshLoop = (e: Event | null, times = 100) => {
                    refresh();
                    if (times > 0) {
                        setTimeout(() => {
                            refreshLoop(null, times - 1);
                        }, 10);
                    }
                };

                const sensor = new ResizeSensor(contentElement, refresh);
                window.addEventListener('resize', refresh);
                window.addEventListener('wheel', refreshLoop);
                window.addEventListener('keyup', refreshLoop);

                // Indicates how to clean the effect
                return function cleanup() {
                    sensor.detach();
                    window.removeEventListener('resize', refresh);
                    window.removeEventListener('wheel', refreshLoop);
                    window.removeEventListener('keyup', refreshLoop);
                };
            }
        } else {
            setCoords(null);
        }
        return () => { /* */ };
    }, [position, active, contentElement, align, relativeElement]);

    return coords;
};

export const isCursorOutsideRect = (cursor: { clientX: number, clientY: number }, rect: Rect): boolean => (
    cursor.clientX < rect.x
    || cursor.clientX > (rect.x + rect.width)
    || cursor.clientY < rect.y
    || cursor.clientY > (rect.y + rect.height)
);
