import React from 'react';
import {useProject} from 'mushin-redux-store';
import {useParams} from 'react-router-dom';
import SynthesisLayout from './SynthesisLayout';
import SynthesisDashboard from './Components/Dashboard/SynthesisDashboard';

const Synthesis: React.FC = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const project = useProject(projectId);

    if (!project) return null;

    return (
        <SynthesisLayout project={project} activeTab="dashboard">
            <SynthesisDashboard />
        </SynthesisLayout>
    );
};
export default Synthesis;
