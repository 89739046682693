import {useDrag, useDrop, XYCoord} from 'react-dnd';
import React, {useRef, useState} from 'react';

export type Identifier = string | symbol;

export const DnDItemTypes = {
    NOTE: 'note',
    ANSWER: 'answer',
    QUESTIONNAIRE: 'questionnaire',
    QUESTION: 'question',
};

export interface ReorderDragItem {
    index: number;
    dropIndex: number;
    id: string;
    type?: string;
}

export const useReorderedList = <T>(list: T[]): [T[], (from: number, to: number) => boolean] => {
    const [move, setMove] = useState({
        from: -1, to: -1,
    });

    const handleMove = (from: number, to: number) => {
        if (move.from !== from || move.to !== to) {
            setMove({from, to});
        }
        return false;
    };

    const hasMoved = move.from > -1 && move.from !== move.to;
    const reorganizedList = hasMoved ? [] : list;

    if (hasMoved) {
        for (let i = 0; i < list.length; i += 1) {
            if (i === move.to) reorganizedList.push(list[move.from]);
            else if (i > move.to && i <= move.from) reorganizedList.push(list[i - 1]);
            else if (i >= move.from && i < move.to) reorganizedList.push(list[i + 1]);
            else reorganizedList.push(list[i]);
        }
    }

    return [reorganizedList, handleMove];
};

export const useReorder = (
    type: string,
    id: string,
    index: number,
    handlers: {
        move: (from: number, to: number) => boolean,
        end?: (item: ReorderDragItem) => void,
    },
    horizontal = false,
    disabled = false,
): [{ handlerId: Identifier | null; isDragging: boolean }, React.RefObject<HTMLDivElement>] => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<
        ReorderDragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: type,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: ReorderDragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddlePosition = (
                horizontal
                    ? (hoverBoundingRect.right - hoverBoundingRect.left) / 2
                    : (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            );

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientPosition = (
                horizontal
                    ? (clientOffset as XYCoord).x - hoverBoundingRect.left
                    : (clientOffset as XYCoord).y - hoverBoundingRect.top
            );

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientPosition < hoverMiddlePosition) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientPosition > hoverMiddlePosition) {
                return;
            }

            // Time to actually perform the action
            if (handlers.move(dragIndex, hoverIndex)) {
                // eslint-disable-next-line no-param-reassign
                item.index = hoverIndex;
            } else {
                // eslint-disable-next-line no-param-reassign
                item.dropIndex = hoverIndex;
            }
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type,
        item: () => {
            return { id, index, dropIndex: index };
        },
        canDrag: () => !disabled,
        isDragging: (monitor) => {
            return id === monitor.getItem().id;
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (draggedItem) => {
            handlers.end?.(draggedItem);
        },
    });

    drag(drop(ref));

    return [{handlerId, isDragging}, ref];
};
